import * as React from "react";
import {BaseProfile, SAnalysisTypeDTO, SPointsFilter} from "../../decl";
import {Button, Checkbox, Col, Form, Row, Select, Tooltip, Space} from "antd";
import anStyles from "../../resources/Analysis.module.less";
import Groups from "../Groups";
import Resources from "../Resources";
import DateSelect from "../Admin/DateSelect";
import {BUTTON_START_ANALIZE_TEXT} from "./AnalysisHeaderForm";
// import * as PageStore from "../../store/Page";
import * as UserProfileStore from "../../store/UserProfile";
// import * as TablesStore from "../../store/Tables";
import * as Common from "../../utils/Common";
import * as Const from "../../utils/Const";
import {AppThunkAction} from "../../store";
import {AnalysisCondition} from "../../pages/Analysis";
import {ReconciliationOutlined, SyncOutlined} from '@ant-design/icons';

const {Option} = Select;

// const actionCreators = {...PageStore.actionCreators, ...UserProfileStore.actionCreators, ...TablesStore.actionCreators};

type IAnalysisForm = {
  // profile: BaseProfile;
	userProfile: UserProfileStore.UserProfileState;
	// setFormState(param: string, value: any) : void;
  	setFormState(params: object) : void;
	defaultPeriod?: string;
	sendForm() : void;
  	requestGroupPoints?(iGroupId: string, resolve: any, reset: Boolean): AppThunkAction<any>,
  	setIsLoading?(status: boolean, waitInterval?: number | undefined): AppThunkAction<any>,
  	conditions: SAnalysisTypeDTO[]
	}
	// &
	// typeof actionCreators;

interface IAnalysisState extends SPointsFilter {

}

class AnalysisForm extends React.PureComponent<IAnalysisForm, IAnalysisState> {
  constructor(props: IAnalysisForm) {
	super(props);
	this.state = {
	  groups: (props?.userProfile?.profile?.Common?.Groups) ?? null,
	  points: (props?.userProfile?.profile?.Common?.Points) ?? '',
	  resources: (props?.userProfile?.profile?.Common?.Resources) ?? null,
	  systemNumbers: null,//есть в SPointsFilter
	  text: '',//есть в SPointsFilter
	}

	// console.dir(props?.userProfile);
	// console.log(props?.profile?.Common);

	this.onGroupsChange = this.onGroupsChange.bind(this);
	this.onResourcesChange = this.onResourcesChange.bind(this);
	// this.onSelectPoint = this.onSelectPoint.bind(this);

	// this.onRefresh = this.onRefresh.bind(this);
	// this.onAccept = this.onAccept.bind(this);
	//this.onDateRangeChange = this.onDateRangeChange.bind(this);
  }

  // componentDidMount() {
  //
  // }

  /**
   *
   */
  onGroupsChange = (groups: any, points: any) => {
	//console.clear();
	//console.log(groups, points);

	let groupsStr = groups.includes(Const.ALL_GROUP_ID)  ? null : Common.arrayToString(groups);
	let pointsStr = Common.arrayToString(points);

	this.setState({
	  ...this.state,
	  groups: groupsStr,
	  points: pointsStr
	});
	// this.props.setFormState('points', pointsStr);
  };
  onResourcesChange = (value: any) => {
	let resources = (value.indexOf(Const.ALL_RESOURCE_ID) >= 0)
	  ? null
	  : Common.arrayToString(value);
	this.setState({
	  ...this.state,
	  resources: resources
	});
  }

  onRefresh = () => {
	this.props.setFormState({
		groups: this.state.groups,
		points: this.state.points,
		resources: this.state.resources
	});
	}

  render() {
	// console.log(this.props.conditions);

	const selectedGroupIds = (this.state.groups != null) ?
	  Common.stringToArray(this.state.groups) :
	  [Const.ALL_GROUP_ID];
	const selectedPointIds = (this.state.points != null) ?
	  Common.stringToArray(this.state.points) :
	  [];
	const selectedResourceIds = (this.state.resources != null) ?
	  Common.stringToArray(this.state.resources) :
	  [Const.ALL_RESOURCE_ID];

	const GroupsProps = {
	  groups: (this.props.userProfile) ? this.props.userProfile.userGroups : [],
	  groupPoints: (this.props.userProfile) ? this.props.userProfile.groupPoints : {},
	  selectedGroupIds: selectedGroupIds,
	  selectedPointIds: selectedPointIds,
	  getPoints: this.props.requestGroupPoints,
	  getPointsParentGroups: null,
	  onChange: this.onGroupsChange,
	  setIsLoading: this.props.setIsLoading,
	  className: anStyles.analysisGroups,//'analysis-groups'
	  onHideDropDown: this.onRefresh
	};

	const ResourcesProps = {
	  resources: (this.props.userProfile) ? this.props.userProfile.resources : [],
	  selectedResourceIds: selectedResourceIds,
	  onChange: this.onResourcesChange,
	  className: anStyles.analysisGroups,
	  onHideDropDown: this.onRefresh
	};

	const columnSizes = {
	  groups: 5,
	  resources: 4,
	  condition: 6,
	  dates: 9
	};


	const form = <Form
	  layout="vertical"
	  className={anStyles.analysisForm}
	>
	  <Row justify={"space-between"}>
		<Col
		  sm={{span: 24}}
		  md={{span: 20}}
		>
		  <Row
			gutter={8}
		  >
			<Col span={columnSizes.groups}>
			  <Groups {...GroupsProps} />
			</Col>
			<Col span={columnSizes.resources}>
			  <Resources {...ResourcesProps} />
			</Col>
			<Col span={columnSizes.condition}>
			  <ConditionSelect
				conditions={this.props.conditions}
				onSelect={(value: number) => {
				  // console.log('выбрано условие', value);
				  this.props.setFormState({condition: value});
				}}
			  />
			</Col>
			<Col span={columnSizes.dates}>
			  <DateSelect
				defaultPeriod={this.props?.defaultPeriod ?? ''}
				gutter={8}
				placeholder={"Период анализа"}
				onChange = {(value: any) => {
				  this.props.setFormState({dates: value});
				}}
			  />
			</Col>
		  </Row>
		</Col>
		<Col
		  sm={{span: 24}}
		  md={{span: 4}}
		  style={{textAlign: 'center'}}

		>
		  <Button
			type="primary"
			shape="round"
			onClick={() => this.props.sendForm()}
			icon={<ReconciliationOutlined />}
		  >
			{BUTTON_START_ANALIZE_TEXT}
		  </Button>
		</Col>
	  </Row>
	  <Row className={anStyles.analysisShowTuNamesRow}>
		<Col
		  span={24}
		>
		  <Checkbox onChange={e => {
			this.props.setFormState({showTuNames: e.target.checked});
		  }}>
			Показывать наименование точек учёта
		  </Checkbox>
		</Col>
	  </Row>

	</Form>;

	return form;
  }
}



export default AnalysisForm;


// export interface AnalysisCondition {
//   code: number;
//   name: string,
//   valueHeader?: string
// }

// let analysisConditions = new Set<AnalysisCondition>();
// analysisConditions.add({
//   code: 0,
//   name: 'Потребление тепла за период'
// });
// analysisConditions.add({
//   code: 1,
//   name: 'Время t1-t2<3 град С за период'
// });
// analysisConditions.add({
//   code: 2,
//   name: 'Разница температур t1-t2 средняя за период'
// });
// analysisConditions.add({
//   code: 3,
//   name: 'Температура, подающая t1 за сутки'
// });
// analysisConditions.add({
//   code: 4,
//   name: 'Температура обратная t2 за сутки'
// });
// analysisConditions.add({
//   id: 5,
//   name: 'Утечка M1-M2 за период'
// });
// analysisConditions.add({
//   id: 6,
//   name: 'Утечка относительная (M1-M2)/M1*100 за период'
// });
// analysisConditions.add({
//   id: 7,
//   name: 'Подмес относительный (M2-M1)/M2*100 за период'
// });
// analysisConditions.add({
//   id: 8,
//   name: 'Время наработки за период'
// });
// analysisConditions.add({
//   id: 9,
//   name: 'Время отказа за период'
// });

const ConditionSelect = (props: any) => {
  let options = [];

  for (let cond of props.conditions) {
	options.push(
	  <Option
		key={cond.code}
		value={cond.code}
	  >{cond.name}</Option>
	)
  }
  return (
	<Select
	  //defaultValue={defaultValue}
	  style={{ width: '100%' }}
	  placeholder="Условия анализа"
	  optionFilterProp="children"
	  filterOption={(input, option:any) =>
		option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
	  }
	  filterSort={(optionA, optionB) =>
		optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
	  }
	  onSelect={(value: any) =>
		props.onSelect(value)
	  }
	  dropdownMatchSelectWidth={false}
	  listHeight={350}
	>
	  {options}
	</Select>
  );
};
