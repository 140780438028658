import { Route } from 'react-router';
import Layout from './components/Page';
import Kadr from './pages/Kadr';
import Reports from './pages/Reports';
import Sensors from './pages/Sensors';
import Maps from './pages/Maps';
import Settings from './pages/Settings';
import Support from './pages/Support';
import Admin from './pages/Admin';
import Control from './pages/Control';
import Analysis from './pages/Analysis';
import Operations from './pages/Operations';
import Charts from './pages/Charts';
import MonthReports from './pages/MonthReports';
import Mnemoschema from './pages/Mnemoschema';
import Nsi from './pages/Nsi';
import SiteLog from './pages/SiteLog';
import Weather from './pages/Weather';
import Billing from './pages/Billing';

import './custom.css';

export default () => (
    <Layout>
        <Route exact path='/' component={Support} />
        <Route path='/reports' component={Reports} />
        <Route path='/kadr' component={Kadr} />
        <Route path='/sensors' component={Sensors} />
        <Route path='/maps' component={Maps} />
        <Route path='/control' component={Control} />
        <Route path='/analysis' component={Analysis} />
        <Route path='/operations' component={Operations} />
        <Route path='/charts' component={Charts} />
        <Route path='/monthreports' component={MonthReports} />
        <Route path='/mnemoschema' component={Mnemoschema} />
        <Route path='/nsi' component={Nsi} />
        <Route path='/weather' component={Weather} />
        <Route path='/sitelog' component={SiteLog} />
        <Route path='/settings' component={Settings} />
        <Route path='/support' component={Support} />
        <Route path='/admin' component={Admin} />
        <Route path='/billing' component={Billing} />
    </Layout>
);
