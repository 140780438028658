import * as React from 'react';

import { Button, ButtonProps, Tooltip } from 'antd';
import styles from '../../resources/Page.module.less';
import { ButtonShape } from 'antd/lib/button';
import { TooltipPlacement } from 'antd/es/tooltip';

type Props = ButtonProps & { isCollapsed?: boolean, popup?: string, tooltipPlacement?: TooltipPlacement }

class CollapsedButton extends React.Component<Props> {
    state = {
    };

    render() {
        const className = this.props.isCollapsed != undefined && this.props.isCollapsed === true ? styles.iconflexitem : styles.flexitem;
        const popup: string = this.props.popup ?? this.props.children?.toString() ?? '';
        let p = {
            shape: this.props.isCollapsed != undefined && this.props.isCollapsed === true ? "circle" as ButtonShape : "round" as ButtonShape,
            ...this.props,
        }
        delete p.isCollapsed;
        delete p.popup;
        delete p.tooltipPlacement;

        return (
            <div className={className}>
                <Tooltip placement={this.props.tooltipPlacement ?? "bottomRight"} title={popup}>
                    <Button {...p} >
                        {this.props.isCollapsed != undefined && this.props.isCollapsed === true ? null : this.props.children}
                    </Button>
                </Tooltip>
            </div>
        );
    }
}

export default CollapsedButton;