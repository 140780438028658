import * as React from 'react';
import {Button, Checkbox, Col, DatePicker, Form, Row, Tooltip} from 'antd';
import {FullscreenExitOutlined, FullscreenOutlined} from "@ant-design/icons";
import moment from 'moment';
import {PeriodsEnum} from "../../decl";
import chartsStyles from '../../resources/Charts.module.less';
import {ShowParams, deleteInArray} from "./ChartsUtils";

import {
  SyncOutlined
} from '@ant-design/icons';
const {RangePicker} = DatePicker;

interface IChartsHeaderProps {
  fullscreen: boolean;
  changeFullscreen: () => void;
  dates: any[];
  setDates: (dates: any, fromSelect?: boolean) => void;
  showParams: ShowParams[];
  disabledShowParams: ShowParams[];
  setShowParams: (value: ShowParams[], clickedParamId: ShowParams | null) => void;
  period: PeriodsEnum;
  doRefresh?: any;
}

interface IChartsHeaderState {
  // dates: [any, any],
  // showParams: ShowParams[];
}

interface IChartsShowParam {
  id: any;
  name: string;
  tip: string;
}

const ChartsShowParams: IChartsShowParam[] = [
  {
	id: ShowParams.PDZ,
	name: 'ПДЗ',
	tip: 'Предельно допустимые значения'
  },
  {
	id: ShowParams.TRENDS,
	name: 'Тренды',
	tip: ''
  },
  {
	id: ShowParams.TG,
	name: 'Темп. г-ки',
	tip: 'Температурные графики'
  },
  {
	id: ShowParams.ALL,
	name: 'Общий график',
	tip: ''
  }
];

class ChartsHeader extends React.Component <IChartsHeaderProps, IChartsHeaderState> {

  constructor(props: IChartsHeaderProps) {
	super(props);

	// console.dir(this.props);
	// console.dir('charts ChartsHeader constructor');

	// if (this.props.dates[0] !== undefined)
	//   console.log('CONS', this.props.dates[0].format('DD.MM.YYYY HH:mm'));
	// this.state = {
	//   dates: [null, null],
	//   // showParams: [ShowParams.ALL]
	// }
  }

  // componentDidUpdate (prevProps: Readonly<IChartsHeaderProps>, prevState: Readonly<IChartsHeaderState>, snapshot?: any) {
  // console.log('charts ChartsHeader componentDidUpdate');
  // 	if (this.props.dates[0] !== undefined) {
	//   console.log(this.props.dates[0].format('DD.MM.YYYY HH:mm'));
	// }
  // 	if (prevProps.dates[0] !== undefined)
	//   console.log(prevProps.dates[0].format('DD.MM.YYYY HH:mm'));
  // }

  render() {
	// console.dir('charts ChartsHeader render');

	let {dates = [null, null], disabledShowParams = []} = this.props;


	let ranges: any = null;
	if (this.props.period === PeriodsEnum.day)
		ranges = {
		  "Сегодня": [
		    moment(),
			moment()
		  ],
		  "Этот месяц": [
		    moment().startOf('month'),
			moment()
			// moment().endOf('month')
		  ],
		};
	return (
	  <Form
		layout={"vertical"}
		className={chartsStyles.chartsHeaderForm}
	  >
		<Row
		  className={chartsStyles.header}
		  gutter={16}
		>
		  {/*
		  	ДАТЫ
		  */}
		  <Col span={10}>

			<Row gutter={8}>
			  <Col span={18} className={chartsStyles.datesSelect}>
				<Form.Item
				  label={"Диапазон дат"}
				  className={chartsStyles.hdrLabel}
				>
				  <RangePicker
					style={{width: '100%'}}
					ranges={ranges}
					showTime={this.props.period != PeriodsEnum.day}
					format={
					  this.props.period == PeriodsEnum.day
						? "DD.MM.YYYY"
						: "DD.MM.YYYY HH:mm"
					}
					value={
					  (dates[0] !== null && dates[1] !== null)
						? [
						  dates[0],
						  dates[1]
						]
						: null
					}
					// defaultValue = {null}
					onChange={(time:any) => {
					  // console.log(time);
					  if (time)
						this.props.setDates(time, true);

					}}
					disabled={
					  this.props.showParams.includes(ShowParams.TRENDS)
					  &&
					  this.props.period !== PeriodsEnum.day
					}
				  />
				</Form.Item>
			  </Col>
			  <Col span={4}>
				<Form.Item
				  label={" "}
				>
				  <Button
					type="primary"
					shape="round"
					icon={<SyncOutlined/>}
					style={{marginRight: '15px'}}
					onClick={() => this.props.doRefresh(Date.now())}
				  >
					Обновить
				  </Button>
				</Form.Item>
			  </Col>
			</Row>
		  </Col>

		  {/*
				Чекбоксы - что отображать
			*/}
		  <Col span={12}>

			<Form.Item
			  label={"Отображать"}
			  className={chartsStyles.hdrLabel}
			>
			  <Row
				gutter={8}
				// justify={"center"}
			  >
				{
				  ChartsShowParams.map((item: IChartsShowParam, i: number) => {

					let disabled: boolean = false;

					if (item.id == ShowParams.TRENDS && this.props.period == PeriodsEnum.day)
					  disabled = true;
					// else if (item.id == ShowParams.PDZ && this.props.showParams.includes(ShowParams.ALL))
					//   disabled = true;

					if (disabledShowParams.includes(item.id))
					  disabled = true;


					const checkbox = <Checkbox
					  onChange={e => {

						let params: ShowParams[] = [
						  ...this.props.showParams
						];

						if (e.target.checked) {
						  if (!params.includes(item.id))
							params.push(item.id);

						} else {
						  params = deleteInArray(params, item.id);
						}

						// if (item.id === ShowParams.PDZ)
						//   params = deleteInArray(params, ShowParams.ALL);
						// if (item.id === ShowParams.ALL)
						//   params = deleteInArray(params, ShowParams.PDZ);

						this.props.setShowParams(params, item.id);
					  }}
					  checked={!disabled && this.props.showParams.includes(item.id)}
					  disabled={disabled}
					>
					  {item.name}
					</Checkbox>;

					return <Col
					  key={"charts-hdr-sd-" + i}
					  // flex={1}
					>
					  {
						item.tip !== ''
						  ? <Tooltip placement="top" title={item.tip}>{checkbox}</Tooltip>
						  : checkbox
					  }
					</Col>
				  })
				}
			  </Row>

			</Form.Item>
		  </Col>
		  <Col span={2} className={chartsStyles.hdrFsWrap}>

			<Tooltip placement="top" title={'На весь экран'}>
			  <Button
				shape="circle"
				icon={!this.props.fullscreen
				  ? <FullscreenOutlined/>
				  : <FullscreenExitOutlined/>
				}
				onClick={() => this.props.changeFullscreen()}
			  />
			</Tooltip>

		  </Col>
		</Row>
	  </Form>
	)
  }
}

export default ChartsHeader;
