import { message } from '../utils/Common';
import internal from 'assert';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { PeriodsEnum, Point, Resource, ResponseStatusesEnum, SPointsFilter, SResponseDTO } from '../decl';
import { BACKEND_URL, getHeaders } from '../utils/AuthUtils';
import { CloseSessionAction } from './Page';

//Состояние хранилища для страницы Reports.
export interface IMnemoschemasState {
    isLoading: boolean,
    isLoadingData: boolean,
    mnemoschemas: MnemoschemaDTO[] | undefined,
    picture: string | undefined,
    styledFields: SMnemoschemaStyledFieldDTO[] | undefined,
    fields: SMnemoschemaField[] | undefined,
    isLoadingDataError: boolean,
    lastUpdateData: Date | null,
    isGeneric: boolean | undefined,
    currentMnenoschemaId: number | undefined,
    abonents: {[id: string]: string} | undefined,
}

export interface SMnemoschemaStyledFieldDTO
{
    displayName: boolean,
    fieldName: string,
    fieldValue: string,
    xval: number,
    yval: number,
    xname: number,
    yname: number,
    widthVal: number,
    heightVal: number,
    widthName: number,
    heightName: number,
    fontSizeVal: number,
    fontSizeName: number,
    fontFamilyVal: string,
    fontFamilyName: string,
    fontBoldVal: boolean,
    fontBoldName: boolean,
    fontItalicVal: boolean,
    fontItalicName: boolean,
    fontDecoration: string,
    fontColorVal: string[],
    fontColorName: string[],
    backGroundColor: string[],
    borderStyle: string[],
}

export interface SMnemoschemaField
{
    id: number,
    mnemoschemaId: number,
    condition: string,
    abonentNumber: string,
    abonentId: string,
    itemName: string,
    left: number,
    top: number,
    width: number,
    height: number,
    sequenceIndex: number,
    valueColor: number,
    backgroundColor: number,
    borderColor: number,
    formulaId: number,
    formula: string,
    statePictures: {[stateKey: number]: SStatePicture};
}

export interface SStatePicture
{
    state: number,
    caption: string,
    image: string,
    defaultWidth: number,
    defaultHeight: number,
}

export interface MnemoschemaDTO
{
    id: number;
    groupId: string;
    caption: string;
    isGeneric: boolean;
    defaultWidth: number;
    defaultHeight: number;
    fileName: string
    nameColor: number;
    valueColor: number;
    backgroundColor: number;
    borderColor: number;
}

export interface MnemoschemaPictureRequestDTO
{
    id: number,
    period: PeriodsEnum,
    AbonentNumber: string | undefined,
    dtWork: Date | undefined,
}

interface SetState { type: 'SET_MNENO_STATE'; value: Partial<IMnemoschemasState> };
interface ReceiveMnemoschemas { type: 'RECEIVE_MNEMOSCHEMAS', Mnemoschemas: MnemoschemaDTO[], IsGeneric: boolean };
interface ReceiveMnemoschemaPicture { type: 'RECEIVE_MNEMOSCHEMA_DATA', MnemoschemaPicture: string, MnemoschemaFields: SMnemoschemaField[] };
interface ReceiveMnemoschemaFields { type: 'RECEIVE_MNEMOSCHEMA_VALUES', Fields: SMnemoschemaStyledFieldDTO[] };

export type KnownAction = CloseSessionAction | SetState | ReceiveMnemoschemas | ReceiveMnemoschemaPicture | ReceiveMnemoschemaFields;

export const actionCreators = {
    requestMnemoschemas: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({type: 'SET_MNENO_STATE', value: {isLoading: true, mnemoschemas: undefined}});
        
        if (appState) {
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
                //body: JSON.stringify(),
            };
            fetch(BACKEND_URL + 'Mnemoschemas/mnemoschemas', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        const mnemoschemas: MnemoschemaDTO[] = data.body;
                        const generic: MnemoschemaDTO | undefined = mnemoschemas.find(item => item.isGeneric === true);
                        const isGeneric = appState.mnemoschemas?.isGeneric ?? (generic  !== undefined ?? false);

                        dispatch({type: 'RECEIVE_MNEMOSCHEMAS', Mnemoschemas: mnemoschemas, IsGeneric: isGeneric})
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                    }
                    else {
                        message.error('Ответ не получен.');
                    }
                    dispatch({type: 'SET_MNENO_STATE', value: {isLoading: false}});
                })
                .catch(error => {
                    message.error('Ошибка:' + error);
                    console.log(error);
                    dispatch({type: 'SET_MNENO_STATE', value: {isLoading: false}});
                });
            
        }
    },

    requestMnemoschemaData: (mnemoschemaId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({type: 'SET_MNENO_STATE', value: {isLoading: true, picture: undefined, fields: undefined, styledFields: undefined}});
        
        if (appState) {
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify(mnemoschemaId)
            };
            fetch(BACKEND_URL + 'Mnemoschemas/mnemoschemadata', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        dispatch({type: 'RECEIVE_MNEMOSCHEMA_DATA', MnemoschemaPicture: data.body.picture, MnemoschemaFields: data.body.fields })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                    }
                    else {
                        message.error('Ответ не получен.');
                    }
                    dispatch({type: 'SET_MNENO_STATE', value: {isLoading: false}});
                })
                .catch(error => {
                    message.error('Ошибка:' + error);
                    console.log(error);
                    dispatch({type: 'SET_MNENO_STATE', value: {isLoading: false}});
                });
            
        }
    },

    requestMnemoschemaValues: (mnemoschemaId: number, period: PeriodsEnum, depth: number, abonentId: string | undefined): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({type: 'SET_MNENO_STATE', value: {isLoadingData: true}});
        //console.log('SET_MNENO_STATE', JSON.stringify({mnemoschemaId, period, abonentId}));

        if (appState) {
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify({mnemoschemaId, period, abonentId, depth})
            };
            fetch(BACKEND_URL + 'Mnemoschemas/mnemoschemastyledfields', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    let err = true;
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        dispatch({type: 'RECEIVE_MNEMOSCHEMA_VALUES', Fields: data.body})
                        err = false;
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                    }
                    else {
                        message.error('Ответ не получен.');
                    }
                    dispatch({type: 'SET_MNENO_STATE', value: {isLoadingData: false, isLoadingDataError: err}});
                })
                .catch(error => {
                    message.error('Ошибка:' + error);
                    console.log(error);
                    dispatch({type: 'SET_MNENO_STATE', value: {isLoading: false}});
                });
            
        }
    },

    setCurrentMnemoschemaId: (id: number | undefined) => ({ type: 'SET_MNENO_STATE', value: {currentMnenoschemaId: id} } as SetState),
    setMnenoschemaAbonents: (abonents: {[id: string]: string} | undefined) => ({ type: 'SET_MNENO_STATE', value: {abonents: abonents} } as SetState),
    setIsGeneric: (isGeneric: boolean) => ({ type: 'SET_MNENO_STATE', value:{isGeneric: isGeneric} } as SetState),
}

export const reducer: Reducer<IMnemoschemasState> = (state: IMnemoschemasState | undefined, incomingAction: Action): IMnemoschemasState => {
    if (state === undefined) {
        return { 
            isLoading: false, mnemoschemas: undefined, picture: undefined, styledFields: undefined, isLoadingDataError: false, 
            isLoadingData: false, lastUpdateData: null, fields: undefined, isGeneric: undefined, currentMnenoschemaId: undefined, abonents: undefined,
        };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'RECEIVE_MNEMOSCHEMAS':
            return {...state,  mnemoschemas: action.Mnemoschemas, isGeneric: action.IsGeneric};
        case 'RECEIVE_MNEMOSCHEMA_DATA':
            return {...state, picture: action.MnemoschemaPicture, fields: action.MnemoschemaFields};
        case 'RECEIVE_MNEMOSCHEMA_VALUES':
            return {...state, styledFields: action.Fields, lastUpdateData: new Date()};
        case "SET_MNENO_STATE":
            return {
                ...state,
                ...action.value
            };
        default:
            return state;
    }
};
