import * as React from 'react';
import { connect } from 'react-redux';
import * as UserProfile from '../../store/UserProfile';
import * as MapDataStore from '../../store/MapDataStore';
import { Tag } from 'antd';

import { ApplicationState } from '../../store';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import { SPointsFilter, PeriodsEnum } from '../../decl';
import * as PageStore from '../../store/Page';

type PropsType = 
    {page: PageStore.PageState, userProfile: UserProfile.UserProfileState, mapDataStore: MapDataStore.IMapDataState } &
    typeof UserProfile.actionCreators &
    typeof MapDataStore.actionCreators &
    typeof PageStore.actionCreators

type StateType = {
    timerId: any;
    timerPeriodSec: number | undefined;
}

class DataUpdater extends React.Component<PropsType, StateType> {
    state = {
        timerId: undefined,
        timerPeriodSec: undefined
    }

    componentDidMount() {
        this.update();

        this.props.requestQuantity();

        this.updateTimer();
    }

    componentWillUnmount() {
        this.stopTimer();
    }

    componentDidUpdate(prevProps: PropsType) {
        if(this.props.page.isLoading &&
             !this.props.mapDataStore.isPointsLoading &&
              !this.props.mapDataStore.isPointsValuesLoading &&
              !this.props.mapDataStore.isLayersLoading &&
              (prevProps.mapDataStore.isPointsLoading || prevProps.mapDataStore.isPointsValuesLoading || prevProps.mapDataStore.isLayersLoading)){
                  this.props.setIsLoading(false);

        }
        if (this.props.mapDataStore.points == undefined && this.props.mapDataStore.isPointsLoading === false) {
            this.props.requestMapPoints(this.fillFilter(), true);
        }

        if (prevProps.mapDataStore.needToStartToUpdateAllDataFlag === false && this.props.mapDataStore.needToStartToUpdateAllDataFlag === true){
            this.update();
        }
        
        if (prevProps.mapDataStore.isQuantityLoading === true && this.props.mapDataStore.isQuantityLoading === false && this.props.mapDataStore.quantity == undefined) {
            this.props.requestQuantity();
        }
        if (prevProps.mapDataStore.isLayersLoading === true && this.props.mapDataStore.isLayersLoading === false && this.props.mapDataStore.layers == undefined) {
            this.props.requestLayers(this.fillFilter());
        }

        this.updateTimer();
    }

    update = () => {
        this.props.setIsLoading(true);
        this.props.requestMapPoints(this.fillFilter(), true);
        this.requestPointsValues(true);

        this.props.requestLayers(this.fillFilter());
    }

    updateTimer = () => {
        let timerPeriodSec: number | undefined;
        if (this.props.userProfile.profile.Common?.ArchivePeriod === PeriodsEnum.current)
            timerPeriodSec = this.props.userProfile.profile.Map?.CurrentRefreshPeriod;
        else if (this.props.userProfile.profile.Common?.ArchivePeriod === PeriodsEnum.hour)
            timerPeriodSec = this.props.userProfile.profile.Map?.HourRefreshPeriod;
        else if (this.props.userProfile.profile.Common?.ArchivePeriod === PeriodsEnum.day)
            timerPeriodSec = this.props.userProfile.profile.Map?.DayRefreshPeriod;

        if (this.props.userProfile.profile.Map?.AutoRefresh === true && this.state.timerId === undefined) {
            this.startTimer(timerPeriodSec ?? 60);
        }

        if (this.props.userProfile.profile.Map?.AutoRefresh === false && this.state.timerId !== undefined) {
            this.stopTimer();
        }

        if (this.state.timerPeriodSec !== timerPeriodSec) {
            this.restartTimer(timerPeriodSec);
        }
    }

    requestPointsValues = (clear?: boolean) => {
        let dataDepth;

        if (this.props.userProfile.profile.Common?.ArchivePeriod === PeriodsEnum.hour)
            dataDepth = this.props.userProfile.profile.Map?.HourDataDepth
        if (this.props.userProfile.profile.Common?.ArchivePeriod === PeriodsEnum.current)
            dataDepth = this.props.userProfile.profile.Map?.CurrentDataDepth
        else
            dataDepth = this.props.userProfile.profile.Map?.DayDataDepth

        this.props.requestPointsValues({
            activeLayers: this.props.userProfile.profile.Map?.SelectedLayers ?? '',
            lastTime: this.props.mapDataStore.searchDataFromTime,
            dataDepth: dataDepth ?? 60,
            period: this.props.userProfile.profile.Common?.ArchivePeriod ?? PeriodsEnum.day,
            filter: this.fillFilter()
        }, clear);
    };

    fillFilter = (): SPointsFilter => {
        const filter: SPointsFilter = {
            groups: (this.props?.userProfile?.profile?.Common?.Groups) ?? null,
            points: (this.props?.userProfile?.profile?.Common?.Points) ?? '',
            resources: (this.props?.userProfile?.profile?.Common?.Resources) ?? null,
            systemNumbers: (this.props?.userProfile?.profile?.Common?.SystemNumbers) ?? null,
            text: this.props.mapDataStore.textFilter
        };

        return filter;
    }

    restartTimer = (newVal: number) => {
        if (this.state.timerId !== undefined) {
            clearInterval(this.state.timerId!);
            let id = setInterval(this.timerTick, newVal * 1000);
            this.setState({timerId: id, timerPeriodSec: newVal});
        }
    }

    startTimer = (val: number) => {
        if (this.state.timerId === undefined) {
            let id = setInterval(this.timerTick, val * 1000);
            this.setState({timerId: id});
        }
    }

    stopTimer = () => {
        if (this.state.timerId !== undefined) {
            clearInterval(this.state.timerId!);
            this.setState({timerId: undefined})
        }
    }

    timerTick = () => {
        if (this.props.mapDataStore.isPointsLoading !== true && this.props.mapDataStore.isPointsValuesLoading !== true) {
            this.requestPointsValues();
        }
    }

    render() {
        const showDataLOading: boolean = this.props.userProfile.profile.Common?.ShowDataLoading ?? true;
        let res: string | JSX.Element = '-';
        if (this.props.mapDataStore.isPointsValuesLoadingError === true) {
            if (this.props.mapDataStore.lastValuesUpdateTime !== null) {
                res = <span style={{color: 'red'}}>{moment(this.props.mapDataStore.lastValuesUpdateTime).format('YYYY.MM.DD HH:mm:ss')} </span>
            }
        } else if (this.props.mapDataStore.isPointsValuesLoading === true || this.props.mapDataStore.isPointsLoading === true) {
            res = <>{this.props.mapDataStore.lastValuesUpdateTime !== null ?
                 moment(this.props.mapDataStore.lastValuesUpdateTime).format('YYYY.MM.DD HH:mm:ss') : null} {showDataLOading && <LoadingOutlined />} </>;
        } else if (this.props.mapDataStore.lastValuesUpdateTime !== null) {
            res = moment(this.props.mapDataStore.lastValuesUpdateTime).format('DD.MM.YYYY HH:mm:ss');
        }
        
        return (
            <Tag>
                <span>Последнее обновление: </span> {res} 
            </Tag>
        )
    };
}

export default connect(
    (state: ApplicationState) => {
        return {
            page: state.page,
            userProfile: state.userProfile,
            mapDataStore: state.mapDataStore,
        }
    },
    {...UserProfile.actionCreators, ...MapDataStore.actionCreators, ...PageStore.actionCreators}
)(DataUpdater as any);