import * as React from 'react';
import styles from '../resources/Page.module.less';


interface IWaitBlockProps {
    message: string,
    mode: string,
    isActive: boolean
}

export default class WaitBlock extends React.PureComponent<IWaitBlockProps> {
    constructor(props: IWaitBlockProps) {
        super(props);
    }
    

    render() {
        const className = this.props.mode == 'small' ? 'pyramid3' : 'pyramid'
        const displayStyle = this.props.isActive ?
            {
                    opacity: "0.5",
                    zIndex: "3000"
            }:
            {
                opacity: "0",
                zIndex: "0"
            };
            const textDisplayStyle = this.props.isActive ?
            {
                    opacity: "1",
                    zIndex: "3001"
            }:
            {
                opacity: "0",
                zIndex: "0"
            };
    
        return (
            <>
            <div className={styles.blockUIText} style ={textDisplayStyle}>
                <p dangerouslySetInnerHTML={{ __html: this.props.message }}/>
            </div>
            <div className={styles.waitBlock} style = {displayStyle}>
                <article>
                    <section className={className}>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </section>
                </article>
            </div>
            </>);
    }
}