import * as React from 'react';
import { Select } from 'antd';

import * as UserProfile from '../../store/UserProfile';
import * as SettingsStore from '../../store/SettingsStore';
import * as PageStore from '../../store/Page';

import SelectForm from '../Admin/SelectForm';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { Group, Point } from '../../decl';
const { Option } = Select;

interface AdditionalProps {
    visible: boolean,
    onlyGroupsSelect: boolean,
    zIndex: number,
    clientHeight?: number,
    onHide(): void,
    onlyTree?: boolean,

    onPointsSelect?(points: Point[]): void,
    onGroupsSelect?: (groups: Group[]) => void,
}

type Props = 
AdditionalProps
& {userProfile: UserProfile.UserProfileState, page: PageStore.PageState}
& typeof UserProfile.actionCreators
& typeof SettingsStore.actionCreators
& typeof PageStore.actionCreators;

type State = {
    selectedGroupsIds: string[], 
    selectedPointsIds: string[],
}

class SelectFormSimple extends React.Component<Props, State> {
    constructor(props: Props, state: State) {
        super(props);

        this.state = {
            selectedGroupsIds: [],
            selectedPointsIds: [],
        }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps.visible == false && this.props.visible == true) {
            this.setState({selectedGroupsIds: [], selectedPointsIds: []});
        }
    }

    //=============================================================================
    render() {
        return (
            <SelectForm
                title={this.props.onlyGroupsSelect ? "Выбор групп" : "Выбор точек учёта"}
                visible={this.props.visible}
                zIndex={this.props.zIndex}
                clientHeight={this.props.clientHeight}
                groups={this.props.userProfile.userGroups}
                groupPoints={this.props.userProfile.groupPoints}
                selectedGroupIds={this.state.selectedGroupsIds}
                selectedPointIds={this.state.selectedPointsIds}
                getPoints={this.props.requestGroupPoints}
                getMultipleGroupPoints={this.props.requestMultipleGroupPoints}
                getPointsParentGroups={null}
                onChange={  (groups: string[], points: string[]) => this.setState(
                    {selectedGroupsIds: groups, selectedPointsIds: points}
                )}
                onChangePoints={this.props.onPointsSelect}
                onChangeGroups={this.props.onGroupsSelect}
                setIsLoading={this.props.setIsLoading}
                onlyGroupsSelect={this.props.onlyGroupsSelect}
                onClose={this.props.onHide}
                resources= {this.props.userProfile.resources}
                onlyTree={this.props.onlyTree ?? true}
            />
        );
    }
}

export default connect(
    (state: ApplicationState, ownProps: AdditionalProps) => {
        return {
            userProfile: state.userProfile,
            settingsStore: state.settingsStore,
            page: state.page,
            ...ownProps
        }
    },
    {...UserProfile.actionCreators, ...SettingsStore.actionCreators, ...PageStore.actionCreators}
)(SelectFormSimple as any);