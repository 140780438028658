import * as React from 'react';

import { Button, message, Input, Tree, Menu, Dropdown } from 'antd';

import { getModalZIndex } from '../../utils/Common';

import { SRoleDTO, SUserRightDTO } from '../../decl';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import modal from 'antd/lib/modal';
import { sendRequestToBackend } from '../../utils/AuthUtils';
import { MenuItem } from 'rc-menu';
import { MODAL_WRAP_CLASSNAME } from '../../utils/Const';
import EditTextForm from '../Helper/EditTextForm';


type Props = {
    setIsLoading: any,
    windowHeight: number,

    setBeforeLeavePage:(beforeLeavePage: (nextPage: string, go: ()=>void) => boolean) => void,
    removeBeforeLeavePage: (beforeLeavePage: (nextPage: string, go: ()=>void) => boolean, afterRemove: ()=>void | undefined) => void,
    history: any,
}

type State = {
    rightsTypes: {[userRightTypeId: number]: {userRightTypeName: string, userRights: SUserRightDTO[]}} | undefined,
    roles: SRoleDTO[] | undefined,
    rolesRights: {[roleId: string]: string[]} | undefined,

    selectedNode: any,
    newRole: string | null,
    selectedRightIds: string[] | undefined,
    currentRightsChange: boolean,
    modalZIndex: number,

    filterRoles: string,
    filterRights: string,

    userrolesErrFlag: boolean,
    userrightstypesErrFlag: boolean,
    userrolesrightsErrFlag: boolean,
}

interface MenuItem {
    label: string, 
    key: string, 
    menuAction: () => void
}

class RolesAndRights extends React.Component<Props, State> {
    constructor(props: Props, state: State) {
        super(props);
        this.state = {
            rightsTypes: undefined,
            selectedNode: undefined,
            roles: undefined,
            rolesRights: undefined,
            newRole: null,
            modalZIndex: 0,
            userrightstypesErrFlag: false,
            userrolesErrFlag: false,
            userrolesrightsErrFlag: false,

            selectedRightIds: undefined,
            currentRightsChange: false,
            filterRights: '',
            filterRoles: '',
        }

    }

    componentDidMount() {
        this.userroles();
        this.userrightstypes();
        this.userrolesrights();

        this.props.setBeforeLeavePage(this.leavePage)
    }

    leavePage = (newPage: string, go: ()=>void): boolean => {
        let res = true;
        if (this.state?.selectedNode != undefined && this.state.selectedNode.nodeType === 'role' && this.state.currentRightsChange === true) {
            res = false;
            modal.confirm({
                title: 'Внимание',
                icon: <ExclamationCircleOutlined />,
                width: 'fit-content',
                content: 'Сохранить изменения для роли "' + this.state.selectedNode.title + '"',
                okText: 'Да',
                onOk: () => {
                    this.saveCurrentRights(() => {this.setState({currentRightsChange: false}); go();});
                },
                cancelText: 'Нет',
                onCancel: () => {this.setState({currentRightsChange: false}); go();},
                wrapClassName: MODAL_WRAP_CLASSNAME,
                zIndex: getModalZIndex(),
            });
        }
        
        return res;
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (this.state != undefined) {
            if (this.state.userrightstypesErrFlag == true) {
                this.setState({userrightstypesErrFlag: false});
                this.userrightstypes();
            }
            if (this.state.userrolesErrFlag == true) {
                this.setState({userrolesErrFlag: false});
                this.userroles();
            }
            if (this.state.userrolesrightsErrFlag == true) {
                this.setState({userrolesrightsErrFlag: false});
                this.userrolesrights();
            }

            if (prevState != undefined && prevState.selectedNode !== this.state.selectedNode) {
                if (this.state.rolesRights != undefined) {
                    if (this.state.selectedNode.nodeType === 'role') {
                        this.setState({selectedRightIds: this.state.rolesRights[this.state.selectedNode.key]})
                    } else {
                        this.setState({selectedRightIds: undefined})
                    }
                }
            }
        }
    }

    userroles = () => {
        sendRequestToBackend(
            null,
            'admin/userroles',
            (response: any) => {
                this.setState({roles: response})
            },
            this.props.setIsLoading,
            (error: any) => {
                message.warning("Ошибка при запросе ролей");
                console.log(error);
                message.error(error);
                this.setState({userrolesErrFlag: true});
            }
        );
    }

    userrightstypes = () => {
        sendRequestToBackend(
            null,
            'admin/userrightstypes',
            (response: any) => {
                this.setState({rightsTypes: response})
            },
            this.props.setIsLoading,
            (error: any) => {
                message.warning("Ошибка при запросе прав пользователей");
                console.log(error);
                message.error(error);
                this.setState({userrightstypesErrFlag: true});
            }
        );
    }

    userrolesrights = () => {
        sendRequestToBackend(
            null,
            'admin/userrolesrights',
            (response: any) => {
                this.setState({rolesRights: response})
            },
            this.props.setIsLoading,
            (error: any) => {
                message.warning("Ошибка при запросе дерева ролей и прав");
                console.log(error);
                message.error(error);
                this.setState({userrolesrightsErrFlag: true});
            }
        );
    }

    onMenuClickRights = (node: any) => {
        if (this.state?.selectedNode?.contextMenu != undefined) {
            const tmp: MenuItem = this.state.selectedNode.contextMenu.find((x: MenuItem) => x.key == node.key);
            if (tmp?.menuAction != undefined) {
                tmp?.menuAction();
            }
        }
    }

    getRightsContextMenu = () => {
        let res = <Menu />
        if (this.state?.selectedNode?.contextMenu != undefined)
        {
            res = <Menu items = {this.state.selectedNode.contextMenu.map((x: MenuItem) => ({label: x.label, key: x.key}))} onClick = {this.onMenuClickRights} />
        }
        return res;
    }

    findRight = (rightId: string) => {
        let res: SUserRightDTO | undefined = undefined;
        for (const id in this.state.rightsTypes) {
            res = this.state.rightsTypes[Number(id)].userRights.find(x => x.userRightId === rightId);
            if (res != undefined) {
                break;
            }
        }
        return res;
    }

    onCheckedRightsTree = (checkedKeys: React.Key[]) => {
        if (this.state?.rightsTypes != undefined) {
            const allRights: string[] = [];
            for (const i in this.state.rightsTypes) {
                allRights.push(...this.state.rightsTypes[i].userRights.map(x => x.userRightId));
            }
            const selectedRights = checkedKeys.map(x => String(x)).filter(y => allRights.includes(y));
            this.setState({selectedRightIds: selectedRights});
            //Проверяем совпадает ли список отмеченных прав с теми что сейчас есть у роли
            if (this.state?.rolesRights?.[this.state.selectedNode.key].length === selectedRights.length)
            {
                let flag = false;
                for(const i of this.state?.rolesRights?.[this.state.selectedNode.key] ?? []) {
                    if (!selectedRights.includes(i)) {
                        this.setState({currentRightsChange: true});
                        flag = true;
                        break;
                    }
                }
                if (flag === false) {
                    this.setState({currentRightsChange: false});
                }
            } else {
                this.setState({currentRightsChange: true});
            }
        }
    }

    saveCurrentRights = (complete: () => void) => {
        let addRightIds: string[] = [];
        let removeRightIds: string[] = [];

        const role = this.state.roles?.find(x => x.id === this.state.selectedNode.key);

        if (this.state?.selectedRightIds != undefined && this.state.rolesRights != undefined) {
            for (const i of this.state?.selectedRightIds) {
                if (!this.state.rolesRights[this.state.selectedNode.key].includes(i)) {
                    addRightIds.push(i);
                }
            }

            for (const i of this.state.rolesRights[this.state.selectedNode.key]) {
                if (!this.state?.selectedRightIds.includes(i)) {
                    removeRightIds.push(i);
                }
            }
            this.saveRoleRights(role as any, addRightIds, removeRightIds, complete);
        }
    }

    saveRoleRights = (role: SRoleDTO, addRightIds: string[], removeRightIds: string[], complete: () => void) => {
        sendRequestToBackend(
            {role, addRights: addRightIds.map(x => this.findRight(x)), removeRights: removeRightIds.map(x => this.findRight(x))},
            'admin/userrolesave',
            (response: any) => {
                if (this.state?.rolesRights != undefined && role != undefined) {
                    let r = {...this.state?.rolesRights};
                    r[role.id] = r[role.id].filter(x => !removeRightIds.includes(x));
                    for (let t of addRightIds) {
                        r[role.id].push(t)
                    }
                    this.setState({rolesRights: r});
                }
                complete();
            },
            this.props.setIsLoading,
            (error: any) => {
                message.warning("Ошибка записи прав");
                console.log(error);
                message.error(error);
            }
        );
    }

    createRole = () => {
        sendRequestToBackend(
            this.state.newRole,
            'admin/userrolecreate',
            (response: any) => {
                if (this.state?.roles != undefined && this.state.rolesRights != undefined) {
                    this.setState({
                        roles: [...this.state?.roles, response], 
                        rolesRights: {...this.state.rolesRights, [response.id]: [] as string[]},
                        newRole: null,
                        modalZIndex: getModalZIndex()
                    });
                }
            },
            this.props.setIsLoading,
            (error: any) => {
                message.warning("Ошибка при сохранинеии роли");
                console.log(error);
                message.error(error);
            }
        );
    }

    deleteRole = (role: SRoleDTO) => {
        modal.confirm({
            title: 'Внимание',
            icon: <ExclamationCircleOutlined />,
            width: 'fit-content',
            content: 'Удалить роль ' + role.name + '?',
            okText: 'Да',
            onOk: () => {
                sendRequestToBackend(
                    role,
                    'admin/userroledelete',
                    (response: any) => {
                        if (this.state?.roles != undefined && this.state.rolesRights != undefined) {
                            let t = {...this.state.rolesRights};
                            delete t[role.id];
                            this.setState({roles: this.state.roles.filter(x => x.id !== role.id), rolesRights: t})
                        }
                    },
                    this.props.setIsLoading,
                    (error: any) => {
                        message.warning("Ошибка при удалении роли");
                        console.log(error);
                        message.error(error);
                    }
                );     
            },
            cancelText: 'Нет',
            onCancel: () => {},
            wrapClassName: MODAL_WRAP_CLASSNAME,
            zIndex: getModalZIndex(),
        }); 
    }

    onChangeRoleNode = (e: any) => {
        if (e?.node != undefined) {
            if (this.state?.selectedNode != undefined && this.state.selectedNode.nodeType === 'role' && this.state.currentRightsChange === true) {
                modal.confirm({
                    title: 'Внимание',
                    icon: <ExclamationCircleOutlined />,
                    width: 'fit-content',
                    content: 'Сохранить изменения для роли "' + this.state.selectedNode.title + '"',
                    okText: 'Да',
                    onOk: () => {
                        this.saveCurrentRights(() => this.setState({selectedNode: e.node, currentRightsChange: false}));
                    },
                    cancelText: 'Нет',
                    onCancel: () => this.setState({selectedNode: e.node, currentRightsChange: false}),
                    wrapClassName: MODAL_WRAP_CLASSNAME,
                    zIndex: getModalZIndex(),
                });
            } else {
                this.setState({selectedNode: e.node, currentRightsChange: false});
            }
        }
    }

    cancelEditRights = () => {
        if (this.state?.selectedNode != undefined && this.state.selectedNode.nodeType === 'role' && this.state.rolesRights != undefined) {
            const arr = this.state.rolesRights[this.state.selectedNode.key];
            this.setState({currentRightsChange: false, selectedRightIds: arr});
        }
    }

    removeRight = (role: SRoleDTO, rightId: string) => {
        modal.confirm({
            title: 'Внимание',
            icon: <ExclamationCircleOutlined />,
            width: 'fit-content',
            content: 'Убрать право "' + this.findRight(rightId)?.userRightName + '" из роли' + role.name + '?',
            okText: 'Да',
            onOk: () => {
                this.saveRoleRights(role, [], [rightId], () => {

                    let rolesRights = {...this.state.rolesRights};
                    rolesRights[role.id] = rolesRights[role.id].filter(x => x !== rightId);
        
                    this.setState({rolesRights})
                })
            },
            cancelText: 'Нет',
            onCancel: () => {},
            wrapClassName: MODAL_WRAP_CLASSNAME,
            zIndex: getModalZIndex(),
        });
    }

    //=============================================================================
    render() {
        //console.log('render', this.state);
        //Формирование дерева ролей
        let rolesTreeData: any = undefined;
        if (this.state?.roles != undefined && this.state?.rolesRights != undefined) {
            rolesTreeData = [{
                title: 'Роли',
                key: 'root',
            }];

            rolesTreeData[0]['children'] = this.state.roles
                .filter(x => x.name.toLowerCase().includes(this.state.filterRoles.toLowerCase()) || this.state.filterRoles === '')
                .map(x => ({
                    title: x.name,
                    key: x.id,
                    nodeType: 'role',
                    contextMenu: [
                        { label: 'Удалить', key: x + 'del', menuAction: () => this.deleteRole(x) } as MenuItem,
                    ],
                    children: (this.state.rolesRights?.[x.id]?.length ?? 0) < 1 ? undefined :
                        (this.state.rolesRights?.[x.id] ?? [])
                        .map(y => ({
                            title: this.findRight(y)?.userRightName,
                            key: x.name + this.findRight(y)?.userRightId,
                            data_key: this.findRight(y)?.userRightId,
                            contextMenu: [
                                { label: 'Убрать право из роли', key: x.name + this.findRight(y)?.userRightId + 'del', menuAction: () => this.removeRight(x, y) } as MenuItem,
                            ],
                        })),
                }));
            if (rolesTreeData[0]['children'].length < 1) {
                rolesTreeData[0]['children'] = undefined;
            }
        }

        //Формирование дерева прав
        let rightsTypesTreeData: any = undefined
        if (this.state?.rightsTypes != undefined) {
            rightsTypesTreeData = [{
                title: 'Права пользователей',
                key: 'root',
            }];

            if (Object.keys(this.state.rightsTypes).length > 0) {
                rightsTypesTreeData[0]['children'] = Object.keys(this.state.rightsTypes)
                .map(x => ({
                    title: this.state.rightsTypes?.[Number(x)].userRightTypeName,
                    key: x,
                    children: (this.state.rightsTypes?.[Number(x)].userRights?.length ?? 0) < 1 ? undefined :
                        this.state.rightsTypes?.[Number(x)].userRights
                        .filter(x => x.userRightName.toLowerCase().includes(this.state.filterRights.toLowerCase()) || this.state.filterRights === '')
                        .map(y => ({
                            title: y.userRightName,
                            key: y.userRightId,
                        }))
                }))
            }
        }

        const clientHeight = this.props.windowHeight - 230; 

        return (
            this.state.rightsTypes == undefined || this.state == undefined || rolesTreeData == undefined || rightsTypesTreeData == undefined ? null :
            <div style={{display: 'flex', height: clientHeight, }}>
                <div style={{minWidth: '500px', backgroundColor: '#cfcfcf', padding: '2px', margin: '0px 20px 0px 0px'}}>
                    <div style={{display: 'flex', justifyContent: 'space-between', margin: '4px', alignItems: 'center'}}>
                        <Input value={this.state?.filterRoles} onChange={(val) => this.setState({filterRoles: val.target.value})} style={{width:'250px'}} placeholder='Фильтр ролей' />
                        <Button shape='round' type='primary' onClick={() => this.setState({newRole: '', modalZIndex: getModalZIndex()})}>Добавить роль</Button>
                    </div>
                    <div style={{overflow: 'auto', height: clientHeight - 44, backgroundColor: 'white'}}>
                        <Dropdown overlay={this.getRightsContextMenu()} trigger={['contextMenu']}>
                            <div style={{cursor:'context-menu'}}>
                                <Tree
                                    defaultExpandedKeys={['root']}
                                    style={{minWidth: '20vw'}}
                                    treeData={rolesTreeData}
                                    selectedKeys={this.state?.selectedNode?.key != undefined ? [this.state.selectedNode.key] : []}
                                    showLine={true}
                                    onRightClick={this.onChangeRoleNode}//(e) => this.setState({selectedNode: e.node})}
                                    onSelect={(selectedKeys, e) => this.onChangeRoleNode(e)}
                                />
                            </div>
                        </Dropdown>
                    </div>
                </div>
                <div style={{minWidth: '500px', backgroundColor: '#cfcfcf', padding: '2px'}}>
                    <div style={{display: 'flex', justifyContent: 'space-between', margin: '4px', alignItems: 'center'}}>
                        <Input style={{width:'250px'}} value={this.state?.filterRights} onChange={(val) => this.setState({filterRights: val.target.value})} placeholder='Фильтр прав' />
                        {this.state?.currentRightsChange === true ? <Button shape='round' type='primary' onClick={this.cancelEditRights}>Отмена</Button> : null}
                        {this.state?.currentRightsChange === true ? <Button shape='round' type='primary' onClick={() => this.saveCurrentRights(() => this.setState({currentRightsChange: false}))}>Сохранить</Button> : null}
                    </div>
                    <div style={{overflow: 'auto', height: clientHeight - 44, backgroundColor: 'white'}}>
                        <Tree
                            checkable={this.state?.selectedRightIds != undefined ? true : undefined}
                            checkedKeys={this.state?.selectedRightIds}
                            defaultExpandedKeys={['root']}
                            style={{minWidth: '20vw'}}
                            treeData={rightsTypesTreeData}
                            showLine={true}
                            onCheck={this.onCheckedRightsTree as any}
                        />
                    </div>
                </div>

                <EditTextForm 
                    onHide={() => this.setState({newRole: null})}
                    onOk={this.createRole}
                    text={this.state?.newRole != undefined ? this.state?.newRole : null}
                    onChangeText={(val) => this.setState({newRole: val})}
                    title={'Создание новой роли'}
                    caption={'Название роли'}
                    width={400}
                    height={200}
                    zIndex={this.state.modalZIndex}
                />
            </div>
        );
    }
}

export default RolesAndRights;