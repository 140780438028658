import * as React from 'react';
import { TreeSelect, Tooltip } from 'antd';

import styles from '../../resources/Page.module.less';

const { SHOW_PARENT } = TreeSelect;

interface IProps {
    items: SimpleTreeSelectItem[];
    selectedIds: string[] | undefined;
    onChange: (val: string[]) => void;
    constAllSelected: string,
    rootItemTitle: string,
    rootItemShortTitle: string,
    placeholder?: string,
    onHideDropDown?: () => void;
    size?: 'small' | 'middle' | 'large' | undefined;
    className?: string;
    disabled?: boolean;
    style?: React.CSSProperties;
    onClick?: () => void,
}

export interface SimpleTreeSelectItem {
    name: string,
    shortName: string,
    id: string,
}

export default class SimpleTreeSelect extends React.PureComponent<IProps> {
    _changed: boolean;
    constructor(props: IProps) {
        super(props);
        this._changed = false;
    }

    createTree = (resources: SimpleTreeSelectItem[]): any => {
        const result: any = [];
        result.push(
            {
                title: this.props.rootItemTitle,
                shortTitle: this.props.rootItemShortTitle,
                value: this.props.constAllSelected,
                key: this.props.constAllSelected,
                children: resources.map(r => {
                            const strId = r.id;
                            return {
                                title: r.name,
                                shortTitle: r.shortName,
                                value: strId,
                                key: strId
                            }
                        })
            }
        );
        return result;
    }

    onChange = (value: any) => {
        this.props.onChange(value);
        this._changed = true;
    };
    onDropDownOpen = (open: boolean) => {
        if(open){
            this._changed = false;
        } else if(this._changed && this.props.onHideDropDown) {
            this.props.onHideDropDown();
            this._changed = false;
        }
    };

    render() {
        const treeData = this.createTree(this.props.items);
        const maxTagCount = 1;
        const tProps = {
            treeData,
            value: this.props.selectedIds,
            treeDefaultExpandedKeys: [this.props.constAllSelected],
            onChange: this.onChange,
            treeCheckable: true,
            showCheckedStrategy: SHOW_PARENT,
            placeholder: this.props.placeholder ?? '',
            size: this.props.size,
            maxTagCount: maxTagCount,
            //maxTagPlaceholder: '...',
            treeNodeLabelProp: 'shortTitle',
            dropdownMatchSelectWidth: false,
            onDropdownVisibleChange: this.onDropDownOpen,
            disabled: this.props.disabled,
            style: this.props.style,
        };
        return <TreeSelect onClick={this.props.onClick} className={this.props.className == undefined ? styles.resourcesTree : this.props.className} {...tProps} />;
    }
}


