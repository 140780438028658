import * as React from 'react';

import { Card, Input, Button, Tooltip } from 'antd';
import { CloseCircleOutlined, StopOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import Draggable from 'react-draggable';

import { SQueryItemDTO, QueryItemTypes } from '../../store/AdminStore';
import { Point, SPointTaskDTO } from '../../decl';
import { sendRequestToBackend } from '../../utils/AuthUtils';
import { message } from '../../utils/Common';

import { TaskStatusesEnum } from "./TasksForm";
import { startQueryItem, stopQueryItem, loadQueryItems, getQueryTitle } from './QueryItemsForm'
//import { Tooltip } from 'reactstrap';

//Периодичность обращения к серверу.
const TIMER_INTERVAL: number = 500;

type SPollerInfoDTO = {
    serverName: string,
    serverIP: string,
    serverPort: number,
    threadId: number,
    taskId: number,
    taskStatus: TaskStatusesEnum,
    lastRetryTime: Date,
    queryId: string
}

type STaskLogDTO = {
    taskId: number,
    taskStatus: TaskStatusesEnum,
    log: string
}

type Props = {
    point: Point | undefined,
    zIndex: number,
    queryItemTypes: QueryItemTypes | undefined,
    activeTasks: SPointTaskDTO[],
    getActiveTasks: any,
    onHide: (point: Point | undefined) => void,
}

type State = {
    queryItems: SQueryItemDTO[],
    text: string,
    currentLog: string,
    height: number,
    isCollapsed: boolean,
    activeTask: SPointTaskDTO | undefined,
    pollerInfo: SPollerInfoDTO | null,
    history: SPollerInfoDTO[],
    connected: boolean,
    waitServerAnswer: boolean
}

class PointPollingLogForm extends React.Component<Props, State> {
    _timer: any;
    _textareaRef: any;

    constructor(props: Props, state: State) {
        super(props);
        this._textareaRef = React.createRef();
        this.state = {
            queryItems: [],
            text: '',
            currentLog: '',
            height: 500,
            isCollapsed: false,
            activeTask: undefined,
            pollerInfo: null,
            history: [],
            connected: false,
            waitServerAnswer: false
        };

        this._timer = undefined;
    }

    componentDidMount() {
        this.setState({ pollerInfo: null, connected: false, history: [] });
        if (this.props.point) {
            loadQueryItems(this.props.point.id, null, (queryItems: SQueryItemDTO[]) => this.setState({ queryItems: queryItems }));
        }
        if (!this._timer) {
            this._timer = setInterval(this.onTimer, TIMER_INTERVAL);
        }
    }

    componentWillUnmount() {
        if (this._timer) {
            clearInterval(this._timer);
            this._timer = undefined;
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (this.props.point !== undefined && prevProps.point === undefined) {
            loadQueryItems(this.props.point.id, null, (queryItems: SQueryItemDTO[]) => this.setState({ queryItems: queryItems }));
            this.setState({ pollerInfo: null, connected: false, history: [] });
        }

        if (this.state.text !== prevState.text || this.state.currentLog !== prevState.currentLog) {
            this.scrollToBottom();
        }
    }

    onTimer = () => {
        if (!this.state.waitServerAnswer) {
            if (this.state.connected) {
                this.updateLog();
            }
            else if (this.state.pollerInfo === null) {// ||
                //this.state.pollerInfo.taskStatus === TaskStatusesEnum.Processing) {
                this.connectToPoller();
            }
            else if (this.state.pollerInfo !== null && this.state.pollerInfo.taskId !== 0) {
                //this.getTaskLog(this.state.pollerInfo.taskId, new Date(this.state.pollerInfo.lastRetryTime));
            }
        }
    }

    connectToPoller = () => {
        if (!this.state.connected && this.props.point !== undefined) {
            const task: SPointTaskDTO | undefined = this.props.activeTasks.find(t => t.pointId === this.props.point?.id);
            if (task) {
                sendRequestToBackend(
                    {
                        pointId: this.props.point.id,
                        queryId: task.queryId,
                        taskId: task.taskId
                    },
                    'admin/connecttopoller',
                    (pollingInfo: SPollerInfoDTO | null) => {
                        const connected: boolean = pollingInfo !== null && pollingInfo.threadId !== 0;
                        //const log = connected ? "Подключен." : pollingInfo !== null ? "Задание выполнено." : "Ожидание начала выполнения...";
                        this.setState({
                            pollerInfo: pollingInfo,
                            connected: connected,
                            text: this.state.text + (connected && pollingInfo != null ?
                                "===================================" + "\n" +
                                getQueryTitle(this.state.queryItems.find(q => q.id === pollingInfo.queryId), this.props.queryItemTypes) + "\n" +
                                "===================================" + "\n" : ''),
                            currentLog: '',
                            activeTask: task
                        });
                    },
                    (isLoading: boolean) => {
                        this.setState({ waitServerAnswer: isLoading });
                    },
                    () => {
                    },
                    () => {

                    },
                    500
                );
            }
            //else if (this.state.activeTask) {
            //    const lastRetryTime = moment(new Date()).add(-2, 's').toDate();
            //    this.getTaskLog(this.state.activeTask.taskId, lastRetryTime);
            //}
        }
    }

    updateLog = () => {
        if (this.state.pollerInfo !== null && this.state.pollerInfo.threadId !== 0) {
            sendRequestToBackend(
                this.state.pollerInfo,
                'admin/onlinetasklogget',
                (taskLog: STaskLogDTO) => {
                    if (taskLog.taskStatus !== TaskStatusesEnum.Processing) {
                        //Поток отключился от задачи.
                        if (this.state.pollerInfo !== null) {
                            this.setState({
                                connected: false,
                                pollerInfo: null,// { ...this.state.pollerInfo, taskStatus: taskLog.taskStatus },
                                text: taskLog.log === null ? this.state.text + this.state.currentLog : this.state.text + taskLog.log,
                                currentLog: ''
                            })
                        }
                    }
                    else {
                        this.setState({ currentLog: this.state.currentLog + taskLog.log, });
                    }
                },
                (isLoading: boolean) => {
                    this.setState({ waitServerAnswer: isLoading });
                },
                () => {
                },
                () => {

                },
                500
            );
        }
    }

    disconnectFromPoller = () => {
        //console.log('disconnect: ', this.state);
        if (this.state.pollerInfo !== null && this.state.pollerInfo.threadId !== 0 && this.state.connected) {
            sendRequestToBackend(
                this.state.pollerInfo,
                'admin/disconnectfrompoller',
                (pollingInfo: SPollerInfoDTO | null) => {
                    this.setState({ pollerInfo: pollingInfo, connected: false });
                    if (pollingInfo !== null &&
                        pollingInfo.taskId !== 0 &&
                        pollingInfo.taskStatus !== TaskStatusesEnum.None &&
                        pollingInfo.taskStatus !== TaskStatusesEnum.NotDefined) {
                        this.getTaskLog(pollingInfo.taskId, new Date(pollingInfo.lastRetryTime));
                    }
                },
                (isLoading: boolean) => {
                    this.setState({ waitServerAnswer: isLoading });
                },
                () => {
                },
                () => {

                },
                500
            );
        }
    }

    stopPoller = () => {
        if (this.state.pollerInfo !== null && this.state.pollerInfo.threadId !== 0 && this.state.connected) {
            sendRequestToBackend(
                this.state.pollerInfo,
                'admin/threadrestart',
                (pollingInfo: SPollerInfoDTO | null) => {
                    this.setState({ pollerInfo: pollingInfo, connected: false });
                },
                (isLoading: boolean) => {
                    this.setState({ waitServerAnswer: isLoading });
                },
                () => {
                },
                () => {

                },
                500
            );
        }
    }

    getTaskLog = (taskId: number, lastRetryTime: Date) => {
        const start: Date = lastRetryTime;
        const end: Date = moment(start).add(5, 'm').toDate();

        sendRequestToBackend(
            { taskId, start, end },
            'admin/pollinglog',
            (response: any) => {
                if (response != null && response !== '') {
                    this.setState({ text: this.state.text + response, currentLog: '', pollerInfo: null, activeTask: undefined });
                } else {
                    message.warning("Не удалось получить лог опроса");
                }
            },
            (isLoading: boolean) => {
                this.setState({ waitServerAnswer: isLoading });
            },
            (error: any) => {
                console.log(error);
                message.error(error);
            }
        );
    }

    scrollToBottom = () => {
        if (this._textareaRef.current) {
            this._textareaRef.current.resizableTextArea.textArea.scrollTop = this._textareaRef.current.resizableTextArea.textArea.scrollHeight;
        }
    }

    render() {
        const task: SPointTaskDTO | undefined = this.props.activeTasks.find(t => t.pointId === this.props.point?.id);
        let title: string = (this.props.point ? this.props.point.number : "");
        if (task && this.state.pollerInfo) {
            const queryItem = this.state.queryItems.find(q => q.id === task.queryId);
            title +=('. ' + getQueryTitle(queryItem, this.props.queryItemTypes));
        }
        else {
            title += '. Нет опроса.';
        }

        const tooltipZIndex = 3001;
        return (
            <Draggable handle={".ant-card-head"}>
                <Card
                    bodyStyle={this.state.isCollapsed ? { height: 0 } : { height: '75%' }}
                    style={{ zIndex: this.props.zIndex, position: 'absolute', top: '10%', left: '40%', maxHeight: this.state.isCollapsed ? 200 : 900, height: 500, width: 500, resize: 'both', overflow: 'auto' }}

                    title={
                        (<div style={{ cursor: 'move' }}>
                            {title}
                        </div>)
                    }
                    actions={this.state.isCollapsed ?
                        [
                            <Tooltip title="Закрыть окно" zIndex={tooltipZIndex}>
                                <CloseCircleOutlined key="close" onClick={() => { this.props.onHide(this.props.point) }} />,
                            </Tooltip>,
                            <Tooltip title="Развернуть окно" zIndex={tooltipZIndex}>
                                <PlusCircleOutlined key="expand" onClick={() => { this.setState({ isCollapsed: false }) }} />,
                            </Tooltip>,
                        ] :
                        [
                            <Tooltip title="Закрыть окно" zIndex={tooltipZIndex}>
                                <CloseCircleOutlined key="close" onClick={() => { this.props.onHide(this.props.point) }} />,
                            </Tooltip>,
                            <Tooltip title="Свернуть окно" zIndex={tooltipZIndex}>
                                <MinusCircleOutlined key="collapse" onClick={() => { this.setState({ isCollapsed: true }) }} />,
                            </Tooltip>,
                            <Tooltip title="Остановить опрос на сервере" zIndex={tooltipZIndex}>
                                <StopOutlined key="stop" onClick={this.stopPoller} />,
                            </Tooltip>,
                        ]
                    }
                >
                    {
                        !this.state.isCollapsed &&
                        <Input.TextArea readOnly={true} value={this.state.text + this.state.currentLog} ref={this._textareaRef} style={{ resize: 'none', height: '100%', width: '100%' }} />
                    }
                </Card>
            </Draggable>
        );
    }
}

export default PointPollingLogForm;