import {UserContact} from './store/SettingsStore';

declare global {
    interface Window {
        call_contentget(id: any, c: any, e: any): void;
        call_contentchange(context:any, id: any, c: any, e: any): void;
        call_controlcreate(el: any, s: any, c: any): any;
        call_controlresize(parent: any, control: any): void;
        call_refreshgrid(control: any, filter: any, commandName: string, callback: any): void;
        call_controldispose(control: any): void;
        call_execute(command: any): any;
        call_page_mount(page: string): any;
        call_page_unmount(page: string): any;
        call_window_resize(w: number, h: number): void;
        call_execute_withcreate(iSource: any, iName: string, iParams: any, iCallback: any, iExceptionCallback: any): void;
        call_is_loading_register(fSetIsLoading: any): void;
        call_callback_register(fCallback: any): void;
        call_wait_neti_ready(fOnReady: any): any;
        call_set_appstore(store: any): void;
        call_get_top_zindex(): any;
        NetiSource(iCallback: any): void;
        configTI: any;
        sc_reportcreaterequest(iPointID: any, iPointIDs: any, iReportCode: any, iArchiveType: any, iPeriodFrom: any, iPeriodTo: any): any;
        startSiteError: string | undefined;
    }
}

export interface ICommand{
    Source: any;
    Name: string;
    Params: any;
    Callback: any;
    ExceptionCallback: any;
};


//Ответ от сервера.
export interface SResponseDTO{
    // Статус.
    bodyStatus: ResponseStatusesEnum;
    // Сообщение.
    message: string | null;
    // Данные.
    body: any | null;
};

export enum ResponseStatusesEnum {
    None = 0,
    Ok = 1,
    No = 2,
    Error = 3,
    SessionClosed = 4
};

export interface MenuItemDesc {
    id: number;
    path: string;
    iconName: string;
    name: string;
    editable: boolean;
    right: string | null;
};

export interface  NotifyType  {
    // Идентификатор.
    id: number,
    // Маска.
    typeMask: number,
    // Название.
    name: string,
    // Короткое название.
    shortName: string
};

export enum PeriodsEnum {
    current = 0, hour = 1, day = 2, month = 3
}

export enum CommunicationMissedTimesEnum {
    All = 0,
    NormalLess1day = 1,
    MissedMore1Day = 2,
    MissedMore1Week = 3,
    MissedAll = 4
}

//Уровень доступа пользователя к точке учёта.
export enum AccessLevelsEnum{
    Simple = 0,     // Простой.
    Base = 1,       // Базовый - есть возможность читать данные по запросу.
    Control = 2     // Контроль - отправка сообщений.
}

//Операция перемещения.
export enum MoveOperationsEnum {
    None = 0,
    Move = 1,
    Copy = 2,
    Remove = 3
}

export interface Resource  {
    id: number,
    name: string,
    shortName: string,
    enabled: string,
    physicalQuantityIds: string
};

export interface Group  {
    id: string,
    treeId: number,
    name: string,
    numberOfPoints: number,
    childGroups: Group[],
    clonesTreeIds: number[]
};

export interface Point {
    // Идентификатор точки учёта.
    id: string,

    // Номер точки учёта.
    number: string,

    // Идентификатор ресурса.
    resourceTypeId: number,

    // Флаг блокировки.
    isLocked: boolean,

    // Дней до блокировки точки учёта (0 - много).
    daysToLock: number,

    /// Уроверь доступа.
    accessLevel: AccessLevelsEnum,

    // Параметры точки.
    //     Ключ: Название параметра (GeneralDeclarations.CGeneralConst.AbonentFields).
    //     Значение: Значение параметра.
    persistProperties: {[key: string]: string};
}

//Ключи значений, которые могут быть в Point.persistProperties (не обязательно).
export const POINT_NAME = "name";
export const POINT_NUMBER = "number";
export const POINT_ADDRESS = "address";
export const POINT_KT = "kt";
export const POINT_QTV = "qtv";
export const POINT_COMMENT = "comment";
export const POINT_LATITUDE = "latitude";
export const POINT_LONGITUDE = "longitude";
export const POINT_RESOURCENAME = "resourcename";
export const POINT_RESOURCESHORTNAME = "resourceshortname";
export const POINT_CONNECTIONTYPEID = "connectiontypeid";
export const POINT_CONNECTIONTYPE = "connectiontype";
export const POINT_IPADDRESS = "ipaddress";
export const POINT_TCPPORT = "tcpport";
export const POINT_DEVICETYPE = "devicetype";
export const POINT_DEVICETYPEID = "devicetypeid";
export const POINT_SERIALNUMBER = "serialnumber";
export const POINT_SIGNALLEVEL = "signallevel";
export const POINT_COMMDEVTYPEID = "commdevtypeid";
export const POINT_REMOTEACCESSDATE = "remoteaccessdate";
//Колонка таблицы.
export interface ITableColumn {
    name: string,
    title: string
}

export interface ITableColumns extends Array<ITableColumn> { }
export interface ITableRows extends Array<any> { }

//Фильтр выбора точек учёта.
export interface SPointsFilter {
    //Группы - клиентские идентификаторы через запятую или null.
    //    Пустая строка - не выбрано ни одной группы, null - выбраны все группы, undefined - фильтр нельзя использовать.
    groups: string | null;

    //Точки учёта - клиентские идентификаторы через запятую. Если ни одной точки не указано, не используется.
    points: string;

    //Ресурсы - идентификаторы через запятую  или null.
    //    Пустая строка - не выбрано ни одного ресурса, null - выбраны все ресурсы, undefined - фильтр нельзя использовать.
    resources: string | null;

    //Теплосистемы - номера теплосистем через запятую  или null.
    //    Пустая строка - выбираются точки учёта, представляющие прибор в целом (номер теплосистемы 0), null - выбраны все теплосистемы,
    systemNumbers: string | null;

    //Текстовый фильтр для выбора точек учёта.
    text: string;
}

export interface IActiveQuery{
    PointID: string;
    Period: number;
    Status: number;
}

//Ответ сервера на запрос профиля пользователя.
export type SUserProfileDTO =
{
    token: any,
    user: SUserDTO,
    email: string,
    passwordDate: string,
    menu: MenuItemDesc[],
    resources: Resource[]
    notifyTypes: NotifyType[],
    rights: string[],
    userGroups: Group[],
    groupPoints: {[groupId: string] : Point[]},
    allPoints: {[pointId: string] : Point},
    defaultPoint: Point | null,
    profile: BaseProfile;
}

//Профиль пользователя.
export interface BaseProfile {
    //Общие настройки пользователя.
    Common: CommonProfile | undefined,
    //Настройки страницы для страницы Карта.
    Map: MapProfile | undefined,
    //Настройки для страницы Кадр.
    Kadr: KadrProfile | undefined,
    //Настройки для страницы Мнемосхемы.
    Mnemoschema: MnemoschemaProfile | undefined,
    //Настройки для страницы Ведомости.
    Reports: ReportsProfile | undefined,
    //Настройки для страницы Графики.
    Chart: ChartProfile | undefined,
    //Настройки для страницы Журнал событий.
    DeviceLog: DeviceLogProfile | undefined,
    //Настройки для страницы Датчики.
    Sensors: SensorsProfile | undefined,
    //Настройки для страницы Месячные отчёты.
    MonthlyReports: MonthlyReportsProfile | undefined,
    //Настройки для страницы Администрирование.
    Admin: AdminProfile | undefined
}

//Общие настройки.
export interface CommonProfile {
    //Показывать непрочитанные события.
    ShowUnreadMessages: boolean,
    //Показывать групповую статистику.
    ShowGroupStatistics: boolean,
    //Выполнять периодическую проверку новых событий.
    ConditionEventsCheckEnabled: boolean,
    //Период проверки наличия новых событий.
    ConditionEventsReadPeriod: number,
    //Страница открываемая при входе пользователя.
    EnterPage: string,
    //Последняя просматриваемая страница.
    LastPage: string,
    //Идентификатор срочной новости.
    BreakingNewsId: number
    //Выбранные ресурсы.
    Resources: string  | null,
    //Выбранные группы.
    Groups: string  | null,
    //Выбранные точки учёта.
    Points: string,
    //Выбранные теплосистемы.
    SystemNumbers: string  | null,
    //Отображаемые пункты меню.
    DisplayMenuItems: string | null,
    //Идентификатор текущей точки учёта.
    CurrentPointId: string | null
    //Период архивирования.
    ArchivePeriod: PeriodsEnum,
    //Игнорировать предупреждение о блокировки точек учёта.
    IgnoreLockWarning: boolean,
    //Показывать погоду.
    ShowWeather: boolean,
    //Колонки для текущих данных для выбранной точки учёта.
    CurrentArchiveColumnsLayout: string,
    //Колонки для текущих данных для выбранной точки учёта.
    HourArchiveColumnsLayout: string,
    //Колонки для текущих данных для выбранной точки учёта.
    DayArchiveColumnsLayout: string,
    //Показывать прогресс получения данных (на карте).
    ShowDataLoading: boolean,
    //Права пользователя.
    LastUserRights: string | null,
    //Период получения активных задач опроса.
    PollingCheckPeriod: number,
}

//Профиль для Карты.
export interface MapProfile {
    //Базовый слой.
    BaseLayer: string,
    //Выбранные ресурсы.
    Resources: string,
    //Выбранные группы.
    Groups: string,

    //Период архивирования.
    ArchivePeriod: PeriodsEnum,
    //Тепловые вводы/потребители.
    SystemNumbers: string,
    //Каналы/трубы.
    Channels: string,
    //Физичские величины.
    PhysicalQuantities: string,

    //Флаг автоматического обновления.
    AutoRefresh: boolean,
    //Флаг автоматического переключения слоёв.
    AutoSwitchLayers: boolean,
    //Частота переключения слоёв в секундах.
    AutoSwitchLayersTime: number,
    //Частота обновления в секундах для текущих значений.
    CurrentRefreshPeriod: number,
    //Частота обновления в секундах для часовых значений.
    HourRefreshPeriod: number,
    //Частота обновления в секундах для суточных значений.
    DayRefreshPeriod: number,
    //Глубина получения последних текущих данных в минутах.
    CurrentDataDepth: number,
    //Глубина получения последних часовых данных в часах.
    HourDataDepth: number,
    //Глубина получения последних суточных данных в сутках.
    DayDataDepth: number,
    //Нормальная глубина текущих данных в минутах.
    CurrentNormalDataDepth: number,
    //Нормальная глубина часовых данных в часах.
    HourNormalDataDepth: number,
    //Нормальная глубина суточных данных в сутках.
    DayNormalDataDepth: number,
    //Выбраннвые слои.
    SelectedLayers: string,
    //Цвета слоёв.
    LayerColors: any,
    //Функция для группировки значений.
    ClusteringFunctionType: string
}

export interface KadrProfile {
    //Флаг автоматического обновления.
    AutoRefresh: boolean,
    //Частота обновления в секундах.
    RefreshPeriod: number,
    //Частота обновления в секундах для текущих значений.
    CurrentRefreshPeriod: number,
    //Частота обновления в секундах для часовых значений.
    HourRefreshPeriod: number,
    //Частота обновления в секундах для суточных значений.
    DayRefreshPeriod: number,
    //Период архивирования.
    ArchivePeriod: PeriodsEnum,
    //Тепловые вводы/потребители.
    SystemNumbers: string,
    //Каналы/трубы.
    Channels: string,
    //Физичские величины.
    PhysicalQuantities: string,
    //Глубина получения последних текущих данных в минутах.
    CurrentDataDepth: number,
    //Глубина получения последних часовых данных в часах.
    HourDataDepth: number,
    //Глубина получения последних суточных данных в сутках.
    DayDataDepth: number,
    //Нормальная глубина текущих данных в минутах.
    CurrentNormalDataDepth: number,
    //Нормальная глубина часовых данных в часах.
    HourNormalDataDepth: number,
    //Нормальная глубина суточных данных в сутках.
    DayNormalDataDepth: number,
    //Распределение колонок текущих данных.
    CurrentColumnsLayout: string,
    //Распределение колонок часовых данных.
    HourColumnsLayout: string,
    //Распределение колонок суточных данных.
    DayColumnsLayout: string
}

export interface MnemoschemaProfile {
    //Флаг автоматического обновления.
    AutoRefresh: boolean,
    //Частота обновления в секундах (устарело).
    RefreshPeriod: number,
    //Отображаемые данные (текущие, часовые или суточные).
    ArchivePeriod: PeriodsEnum,
    //Тип отображаемых мнемосхем.
    MnemoschemasType: number
    //Частота обновления в секундах для текущих значений.
    CurrentRefreshPeriod: number,
    //Частота обновления в секундах для часовых значений.
    HourRefreshPeriod: number,
    //Частота обновления в секундах для суточных значений.
    DayRefreshPeriod: number,
    //Глубина получения последних текущих данных в минутах.
    CurrentDataDepth: number,
    //Глубина получения последних часовых данных в часах.
    HourDataDepth: number,
    //Глубина получения последних суточных данных в сутках.
    DayDataDepth: number,
    //Нормальная глубина текущих данных в минутах.
    CurrentNormalDataDepth: number,
    //Нормальная глубина часовых данных в часах.
    HourNormalDataDepth: number,
    //Нормальная глубина суточных данных в сутках.
    DayNormalDataDepth: number,
}

export interface ReportsProfile {
    AbonentsAndGroupsSortBy: string,
    ShowAddressInPlaceName: boolean,
    PDF: boolean,
    PacketToOneExcelBook: boolean,
    INN: string,
    OrganizationName: string,
    XMLElectro80020Number: number,
    XMLElectroEnergies: string,
    TimeZone: string
    ShowOnePoint: boolean,
    MultiSheet: boolean,
    XMLElectroCheckData: boolean,
    //Распределение колонок главной таблицы.
    ColumnsLayout: string,
    //Отчёты csv в кодировке Windows 1251 (если не задано UTF-8).
    Windows1251: boolean
}

export interface ChartProfile {
    SelectAllNodes: boolean,
    CurrentInterval: number,
    HourInterval: number,
    IsCustomCharts: boolean,
    CustomChartId: number,
    Period: PeriodsEnum,
    PointSize: number,
    RefreshPeriod: number,
    DynamicTrends: boolean,
    DisplayTemperatureChart: boolean
}

export interface DeviceLogProfile {
    AutoRefresh: boolean,
    RefreshPeriod: number
}
export interface SensorsProfile {
    DefaultMessageViewingDepth: number,
    StateAnalysisDepth: number,
    RefreshPeriod: number
}
export interface MonthlyReportsProfile {
    ShowGroupsStatistics: boolean
}
export interface AdminProfile {
    //Распределение колонок таблицы TCPIP.
    TCPIPColumnsLayout: string,
}

export interface RequestContext{
    LocalPath: string,
    Properties: any
}

export enum EventTypesEnum
{
    // 1 - авария
    Alarm = 1,
    // 2 - предупреждение
    Warning = 2,
    // 3 - Сообщение
    Message = 3,
    // 4 - НС
    Unstate = 4,
    // 5 - Поверка
    Checking = 5,
    // 6 - ТО
    Work = 6
}

// Способ генерации событий
export enum SourceEventTypesEnum
{
    // Групповые условия.
    GroupCondition = 1,

    // Условия прибора.
    PointCondition = 2,

    // Контроль переменных.
    DriverEvents = 3,

    // Срабатывания датчиков.
    SensorEvents = 4,

    // Отсутствие связи с прибором.
    ConnectionEvents = 5,

    // Высылать месячные отчеты.
    MonthReport = 8,

    // Неверное время прибора.
    DeviceTimeIncorrect = 9,

    // Изменение параметров прибора.
    DeviceParametersChanged = 10,

    // Сообщения о поверке.
    DeviceVerificationMessage = 11,

    // ПДЗ.
    MinMaxCheck = 12
}

// Тип анализа.
export interface SAnalysisTypeDTO
{
    // Код типа анализа.
    code: string,

    // Название типа анализа.
    name: string,

    // Заголовок значения.
    valueHeader: string
}


//Точка учёта со значением.
export interface SAnalysisPoint
{
    //Идентификатор точки учёта.
    id: string,
    //Номер точки учёта.
    number: string,
    //Название точки учёта.
    name: string,
    //Значение или null.
    value: number | null
}

// Интервал анализа.
export interface SAnalysisIntervalDTO
{
    // Название.
    caption: string,
    // Точки учёта.
    points: SAnalysisPoint[]
}

//График
export interface SChart {
    // Единицы измерения.
    units: SChartUnit[],

    // Параметры.
    parameters: SChartParameter[]
}

//Параметр графика.
export interface SChartParameter {
    // Идентификатор.
    id: string,

    // Обозначение.
    name: string,

    // Описание.
    description: string,

    // Идентификатор единицы измерения.
    unitId: string,

    // Минимальное значение. Может быть не задано (null).
    min: number | null,

    // Максимальное значение. Может быть не задано (null).
    max: number | null,

    // Цвет линии (строка вида ARGB #XXXXXXXX).
    lineColor: string,

    // Идентификатор связанного параметра, эталоном для которого является параметр.
    // Может быть не задан (null). Например температурный график для температуры.
    etalonForId: string
}

// Единица измерения параметра.
export interface SChartUnit {
    // Идентификатор.
    id: string,

    // Текст.
    text: string,

    // Описание.
    description: string
}

// Данные точки учета.
export interface SDataRecord
{
    /// Время.
    timeStamp: Date,

    // Данные.
    //     Ключ: Обозначение параметра.
    //     Значение: Значение, округлённое до 2-х знаков.
    // Если в словаре нет ключа с заданным параметром, значит на данное время его значение неизвестно.
    // Словарь может быть пустым.
    values: object
}

// Запрос данных для точки учёта.
export interface SPointDataRequest
{
    // Идентификатор точки учёта.
    pointId: string,

    // Период архивирования.
    period: PeriodsEnum,

    // Начало периода (включительно).
    from: Date,

    // Конец периода (меньше).
    to: Date
}

export interface SPointEventDTO
{
    eventId: number,
    pointId: string,
    eventTime: Date,
    pointNumber: string,
    message: string,
    eventType: EventTypesEnum,
    sourceEventType: SourceEventTypesEnum,
}

export interface SPointWithEventsDTO
{
    id: string,
    number: string,
    eventsCount: number,
}
// Запрос информации о параметрах точки учёта.
export interface SPointInfoRequest
{
    // Идентификатор точки учёта.
    pointId: string,

    // Период архивирования.
    period: PeriodsEnum
}

//Запрос на выгрузка файл таблицы с данными.
export interface SGridExportRequestDTO
{
    fileName: string,
    columns: any, //Dictionary<string, string>
    rows: any //List<Dictionary<string, string>>
}

//Названия закладок окна точки учёта.
export const POINT_TABS = {
    INFO: "abonentinfo",           //Общая информация.
    VALUES: "abonentvalues",       //Последние данные.
    ARCHIVE: "abonentarchive",     //Архивные данные.
    PDZ: "abonentpdz",             //ПДЗ.
    EVENTS: "abonentevents",       //НС.
    PHOTO: "abonentphoto"          //Фото.
}

//Проблема при сборе данных.
export interface SConnectionProblemDTO {
    status: number, 
    caption: string,
    description: string,
    link: string | null,
    locallink: string | null,
    points: Point[]
}

//Статистика подключений и сбора данных.
export interface SConnectionStatisticDTO
{
    newEventsCount: number,
    connectionProblems: SConnectionProblemDTO[]
}

export interface SParameterDTO
{
    id: number;
    title: string;
    readOnly: boolean;

    parameterCode: string;
    description: string;

    value: SParameterValTypes;
    valueType: SParameterTypesEnum;
    valueList: SParameterValTypes[] | null;
    valueStatus: SParameterValuesStatusEnum;
}

export type SParameterValTypes = string | number | Date | boolean | null;

export enum SParameterTypesEnum
{
    None = 0,
    String = 1,
    Number = 2,
    Boolean = 3,
    Date = 4,
    DateTime = 5,
    Text = 6,
}

export enum SParameterValuesStatusEnum
{
    Ok = 0,
    Alarm = 1
}

//Права пользователя.
export const USER_RIGHTS = {
    AbonentDetails: "AbonentDetails",                      //	Просмотр параметров ТУ
    Admin: "Admin",                                        //	Административные функции
    CanAddInDeviceLog: "CanAddInDeviceLog",                //	Добавление событий
    CanEditAbonents: "CanEditAbonents",                    //	Редактирование параметров ТУ
    CanAddAbonents: "CanAddAbonents",                      //	Добавление/удаление ТУ
    CanAddPointPictures: "CanAddPointPictures",            //	Добавление картинок для ТУ
    CanEditAnalisysConditions: "CanEditAnalisysConditions",//	Редактирование условий анализа
    CanEditDeviceLog: "CanEditDeviceLog",                  //	Редактирование журнала событий
    CanReadCurrents: "CanReadCurrents",                    //	Чтение текущих данных
    CanReadTodayArchives: "CanReadTodayArchives",          //	Чтение часового и суточного архива
    CanEditUserSettings: "CanEditUserSettings",            //	Изменение настроек ЛК
    CanReadDayArchives: "CanReadDayArchives",              //	Чтение суточного архива
    CanEditDevicePars: "CanEditDevicePars",                //   Разрешение на добавление/удаление параметров прибора
    CanEditDeviceMinMax: "CanEditDeviceMinMax",            //   Изменение ПДЗ
    EditAbonentOperations: "EditAbonentOperations",        //	Редактирование графика отключений
    EditMnemoschema: "EditMnemoschema",                    //	Редактирование мнемосхем
    GeneralMnemoschema: "GeneralMnemoschema",              //	Типовые мнемосхемы
    EditChart: "EditChart",                                //   Настройка графиков
    EditPerformer: "EditPerformer",                        //	Редактирование исполнителей
    EditPictureMap: "EditPictureMap",                      //	Редактирование карт
    NoCabinetBlock: "NoCabinetBlock",                      //	Запрет блокировки абонента
    PollingMonitoring: "PollingMonitoring",                //	Мониторинг опроса
    ShowDetailedConnectionStat: "ShowDetailedConnectionStat",// Расширенная статистика связи
    ShowExtendedAbonentInfo: "ShowExtendedAbonentInfo",    //	Расширенные параметры абонентов
    WeatherEdit: "WeatherEdit",                            //	Редактирование погоды
    Reports_Profile: "Reports_Profile",                    //	Формирование отчёта по профилю
    Teplosbyt: "Teplosbyt",                                //   Теплосбыт.
    EditArchive: "EditArchive",                            //   Редактирование архивных данных
    CanEditRegions: "CanEditRegions",                      //   Редактирование субъектов РФ

    FormMonthlyDoc: "FormMonthlyDoc",                      //	Месячные отчёты
    FormOfficialDocuments: "FormOfficialDocuments",        //	Документы
    ShowAnalysis: "ShowAnalysis",                          //	Анализ
    ShowControl: "ShowControl",                            //	Контроль
    ShowKadr: "ShowKadr",                                  //	Кадр
    ShowChart: "ShowChart",                                //	График
    ViewEditableChart: "ViewEditableChart",                //	Настраевыемый график
    ViewAbonentOperations: "ViewAbonentOperations",        //	График отключений
    ViewDeviceLog: "ViewDeviceLog",                        //	Журнал событий
    ViewMap: "ViewMap",                                    //	Карта
    ViewMnemoschemas: "ViewMnemoschemas",                  //	Мнемосхемы
    ViewPerformers: "ViewPerformers",                      //	Исполнители
    ViewOperativeLog: "ViewOperativeLog",                  //	Оперативный журнал
    ViewReminders: "ViewReminders",                        //	Напоминания
    ViewSiteVisits: "ViewSiteVisits",                      //	Посещения сайта
    ViewSIU: "ViewSIU",                                    //	Датчики
    ViewWeather: "ViewWeather",                            //	Погода
    ViewNsi: "ViewNSI",                                    //	НСИ
    ViewAdmin: "ViewAdmin",                                //	Страница администрирования
    EditNSI: "EditNSI",                                    //	Редактирование поверки НСИ
    EditAllNSI: "EditAllNSI",                              //	Редактирование полей НСИ
    Billing: "Billing",                                    //	Биллинг

    Reports_Graph: "Reports_Graph",                        //	Отчёт-график
    CustomReport: "CustomReport",                          //	Ведомость по шаблону
    SelectReport: "SelectReport",                          //	Выбор ведомости.
    CanViewCommonReports: "CanViewCommonReports",          //   Разрешение на просмотр общих суточных/месячных ведомостей
    ShowReports: "ShowReports",                            //   Ведомости
}

//Пользователь.
export interface SUserDTO
{
    //Название.
    user: string | undefined,
    //Идентификатор.
    userKey: string | undefined,
    //Флаг входа.
    isLogged: boolean
}

//Информация о пользователе.
export interface SUserInfoDTO
{
    //Пользователь.
    user: SUserDTO;
    //Контакты биллинга.
    billing: string;
    //E-mail.
    email: string;
    //Дата создания.
    createDate: Date | null;
    //Последняя дата входа.
    lastLoginDate: Date | null;
    //Последняя дата активности.
    lastActivityDate: Date | null;
    //Дата изменения пароля.
    lastPasswordChangedDate: Date;
    //Флаг блокировки.
    isLocked: boolean;
    //Роли.
    roles: SRoleDTO[];
    contacts: UserContact[];
}

export interface SRoleDTO
{
    //Идентификатор роли.
    id: string,
    //Название роли.
    name: string
}

export interface SUserRightDTO
{
    //Идентификатор права.
    userRightId: string,
    //Название права.
    userRightName: string,
    //Идентификатор типа права.
    userRightTypeId: number,
    //Название типа права.
    userRightTypeName: string
}

export interface SRoleInfoDTO
{
    role: SRoleDTO,
    rights: SUserRightDTO[]
}

//Описание команды для выполнения из контекстного меню.
export interface Command{
    key: string,        //Ключ команды - д.б. уникальным для данного типа узла.
    caption: string,    //Описание команды.
    action: any     //(ob: Point | Group | null, parent: Group | null)=>void
}

//Конкретное подключение клиента к TCP серверу.
export interface STCPConnectDTO
{
    //Идентификатор клиента.
    clientID: number,
    //Идентификатор модема.
    modemId: string,
    //Время подключения.
    connectionTime: Date,
    //IP адрес и порт удалённого сервера.
    remoteIP: string,
    //Время отключения.
    disconnectTime: Date | null
}

//Состояние TCPIP подключения.
export enum ConnectionStatusEnum
{
    //Не определено.
    None = 0,
    //Есть подключение.
    Connected = 1,
    //Нет подключения.
    Disconnected = 2
}

//Информация о состоянии подключения клиента TCPIP.
export interface SConnectionStatusInfoDTO
{
    //Статус.
    status: ConnectionStatusEnum,
    //Описание.
    message: string
}

/// Описание дискретного датчика СИУ.
export interface SSensorDescriptionDTO
{
    //Идентификатор.
    id: number,
    //Идентификатор клиента.
    clientId: number,
    //Идентификатор модема.
    modemId: number,
    //Идентификатор профиля СИУ.
    profileId: number,
    //Stuffing.
    stuffing: number,
    //Название объекта.
    objectName: string,
    //Название датчика.
    name: string,
    //Номер бита.
    bitNum: number,
    //Сообщение при установке бита.
    messageOn: string,
    //Сообщение при сбросе бита.
    messageOff: string,
    //Телефон.
    messagePhone: string,
    //Состояние.
    state: boolean,
    //Время определения состояния.
    stateTime: Date
}


//Описание подключения к TCP серверу. 
export interface STCPIPConnectionDTO
{
    //Точка учёта.
    point: Point,
    //Тип модема.
    modemType: string,
    //Номер телефона модема.
    phoneNumber: string,
    //Идентификатор TCP соединения.
    clientId: number | null,
    //Идентификатор модема.
    modemId: string,
    //Сервер к которому подключен модем (пустая строка подключение отстуствует).
    connectedTo: string,
    //Время первого подключения.
    firstConnection: string | null,
    //Время последнего подключения.
    lastConnection: string | null,
    //Суммарное число подключений.
    connectionsCount: number,
    //Удалённая точка подключения.
    remoteIP: string | null,
    //Массив описаний датчиков.
    sensors: SSensorDescriptionDTO[]
}

//Состояние сенсора СИУ.
export interface SSIUStateDTO
{
    //Идентификатор клиента.
    clientID: number,
    //Состояние.
    state: number,
    //Время подключения.
    timeStamp: Date,
    //Задержка.
    delay: number
}

//Запрос на получение истории подключений клиента TCP.
export interface SClientHistoryRequest
{
    //Идентификатор клиента.
    clientID: number,
    //Время начала.
    from: Date | null,
    //Время конца.
    to: Date | null
}

//Задача для точки учёта.
export type SPointTaskDTO = {
    //Идентификатор задачи.
    taskId: number,
    //Идентификатор точки учёта.
    pointId: string,
    //Идентификатор задания.
    queryId: string
}

