import * as React from 'react';
import { Route } from 'react-router';
import { Row, Col, Select, Tooltip } from 'antd';
import styles from '../resources/Page.module.less';
import {PeriodsEnum, CommunicationMissedTimesEnum} from '../decl';

import KadrHeaderForm from './KadrHeaderForm';
import ReportsHeaderForm from './ReportsHeaderForm';
import MapHeaderForm from './MapHeaderForm';
import HeaderForm from './HeaderForm';
import SettingsHeaderForm from './SettingsHeaderForm';
import SensorsHeaderForm from './SensorsHeaderForm';
import TestHeaderForm from './TestHeaderForm';
import TablesHeaderForm from './Tables/TablesHeaderForm';
import OperationsHeaderForm from './OperationsHeaderForm';
import ChartPageHeader from "./Charts/ChartPageHeader";
import MnemoschemaHeaderForm from './MnemoschemaHeaderForm';
import NsiHeaderForm from './NsiHeaderForm';
import AdminHeaderForm from './Admin/AdminHeaderForm';
import SiteLogHeaderForm from './SiteLogHeaderForm';
import BillingHeaderForm from './BillingHeaderForm';
import WeatherHeaderForm from './WeatherHeaderForm';

import * as Const from "../utils/Const";

const { Option } = Select;

interface PageHeaderProps{
    firstColWidth: string;
    collapsed: boolean | undefined;
}

export default class PageHeader extends React.Component<PageHeaderProps> {
    render() {
        const publicUrl = window.configTI.URL_PUBLIC;
        let logo;
        if (Const.ClientMode === 'PM') {
            if (this.props.collapsed) {
                logo = styles.logoPMsmall;
            }
            else {
                logo = styles.logoPM;
            }
        }
        else{
            if (this.props.collapsed) {
                logo = styles.logoSmall;
            }
            else {
                logo = styles.logo;
            }
        }

        return (
            <Row>
                <Col flex={this.props.firstColWidth}>
                    <a href = {publicUrl}>
                        <div className={ logo} />
                    </a>
                </Col>
                <Col flex="auto" className={styles.controlsBlock}>
                    <Route exact path='/' component={HeaderForm} />
                    <Route path='/reports' component={ReportsHeaderForm} />
                    <Route path='/kadr' component={KadrHeaderForm} />
                    <Route path='/sensors' component={SensorsHeaderForm} />
                    <Route path='/maps' component={MapHeaderForm} />
                    <Route path='/settings' component={SettingsHeaderForm} />
                    <Route path='/control' component={HeaderForm} />
                    <Route path='/analysis' component={HeaderForm} />
                    <Route path='/operations' component={OperationsHeaderForm} />
                    <Route path='/charts' component={ChartPageHeader} />
                    <Route path='/monthreports' component={HeaderForm} />
                    <Route path='/mnemoschema' component={MnemoschemaHeaderForm} />
                    <Route path='/eventslog' component={HeaderForm} />
                    <Route path='/nsi' component={NsiHeaderForm} />
                    <Route path='/weather' component={WeatherHeaderForm} />
                    <Route path='/sitelog' component={SiteLogHeaderForm} />
                    <Route path='/support' component={HeaderForm} />
                    <Route path='/admin' component={AdminHeaderForm} />
                    <Route path='/billing' component={BillingHeaderForm} />
                </Col>
            </Row>
        );
    }
}


export const Periods = (props: {value: PeriodsEnum, onChange: any, disabled?: boolean}) => {
    return (
        <Tooltip placement="right" title={'Выбор суточных, часовых или текущих значений'}>
            <Select disabled={props.disabled} value={ props.value} style={{ width: 120 }} onChange={props.onChange}>
                <Option value={PeriodsEnum.current}> Текущие </Option>
                <Option value={PeriodsEnum.hour}> Часовые </Option>
                <Option value={PeriodsEnum.day}> Суточные </Option>
            </Select>
        </Tooltip>);
}


export const CommunicationMissedTimes = (props: {value: CommunicationMissedTimesEnum, onChange: any, disabled?: boolean}) => {
    return (<Tooltip placement="right" title={'Выбор точек учёта по наличию связи'}>
        <Select dropdownMatchSelectWidth = {false} value={props.value as number} style={{ width: 120 }} onChange={props.onChange} disabled={props.disabled}>
        <Option value={CommunicationMissedTimesEnum.All as number}>Все ТУ</Option>
        <Option value={CommunicationMissedTimesEnum.NormalLess1day as number}> Норма</Option>
        {/* <Option value={CommunicationMissedTimesEnum.MissedMore1Day as number}> Нет связи больше суток</Option>
        <Option value={CommunicationMissedTimesEnum.MissedMore1Week as number}> Нет связи больше недели </Option> */}
        <Option value={CommunicationMissedTimesEnum.MissedAll as number}> Нет данных </Option>
        </Select>
        </Tooltip>);
}

