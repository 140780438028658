import * as React from 'react';

import styles from '../../resources/Page.module.less';
import { Modal, Select, Input, Row, Col, InputNumber, message, Space, Checkbox, Button } from 'antd';

import { deepCopyObj, saveFile, unicodeToWin1251 } from '../../utils/Common';
import { MODAL_WRAP_CLASSNAME } from '../../utils/Const';
import { SBilContactDTO, SBillingTariffDTO } from './BillingDecls';
import { sendRequestToBackend } from '../../utils/AuthUtils';
import moment from 'moment';

type Props = {
    onHide(needRefresh: boolean) : void,
    visible: boolean,
    setIsLoading: any,
}

type State = {
    writeToDb: boolean,
    needRefresh: boolean,
}

export default class ParsePaymentsForm extends React.Component<Props, State> {
    constructor(props: Props, state: State) {
        super(props);

        this.state = {
            writeToDb: false,
            needRefresh: false,
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.visible === false && this.props.visible === true && this.state != null) {
            this.setState({needRefresh: false, writeToDb: false});
        }
    }

    upload: any;
    uploadRestart: number = 0;

    onClick = () => {
        this.upload.click();
        if (this.state?.writeToDb === true) {
            this.setState({needRefresh: true});
        }
    }

    onChangeFile = (event: any) => {
        event.stopPropagation();
        event.preventDefault();
        var file = event.target.files[0];

        var reader = new FileReader();
        reader.onload = () => this.parse(new Uint8Array(reader.result as any), file.fileName);
        //Проблема в Chrome если взять второй раз тот же файл то он не вызывает метод onChangeFile потому что он уже есть в массиве, поэтому пересоздаем объект input
        reader.onloadend = () => {this.uploadRestart += 1; this.forceUpdate()};
        reader.onerror = () => {};
        reader.readAsArrayBuffer(file);
    }

    parse = (array: Uint8Array, fileName: string) => {
        const base64String = Buffer.from(array).toString('base64');
        sendRequestToBackend(
            {writeToDb: this.state.writeToDb, base64data: base64String},
            'admin/addpayments',
            (response: any) => {
                saveFile('Отчет о биллинге за ' + moment().format('DD-MM-YYYY HHmmss') + '.csv', unicodeToWin1251(response));
            },
            this.props.setIsLoading,
            (error: any) => {
                message.warning("Ошибка ");
                console.log(error);
                message.error(error);
            }
        );
    }

    render() {
        return (
            <Modal
                title={ "Загрузить платежи" }
                wrapClassName={MODAL_WRAP_CLASSNAME}
                width={500}
                //zIndex={this.props.zIndex}
                centered
                open={this.props.visible}
                // footer={[
                //     <div style={{ display: 'flex', alignItems: 'baseline' }}>
                //         <Button key="1" type='primary' onClick={this.props.onHide} style={{marginLeft: 'Auto'}}>Отмена</Button>
                //         <Button key="2" type='primary' onClick={this.onOk}>{this.props.operation === undefined ? "Добавить" : "Изменить"}</Button>
                //     </div>
                // ]}
                onCancel={() => { this.props.onHide(false) }}
                okButtonProps={{ style: { display: 'none' } }}
                cancelText={'Закрыть'}
            >
                <div className={styles.FormWrapper}>
                    <Space>
                        <Button type='primary' onClick={this.onClick}>Загрузить платежи</Button>
                        <Checkbox checked={this.state?.writeToDb ?? false} onChange={(val) => this.setState({writeToDb: val.target.checked})}>Записать результат в БД</Checkbox>

                        {
                            //Пересоздаем объект input если нужно
                            <input key={this.uploadRestart} id="myInput"
                                type="file"
                                ref={(ref) => this.upload = ref}
                                style={{display: 'none'}}
                                onChange={this.onChangeFile}
                            />
                        }

                    </Space>
                </div>
            </Modal>
        );
    }
}