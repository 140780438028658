import * as React from "react";
import chartsStyles from "../../resources/Charts.module.less";
import {ShowParams} from "./ChartsUtils";

export interface IChartsAreaProps {
  title: any;
  paramsBar: any;
  content: any;
  showParams: ShowParams[];
  redrawArea: number
}

export interface IChartsAreaState {
  title: any | null;
  paramsBar: any | null;
  content: any | null;
}

class ChartsArea extends React.PureComponent <IChartsAreaProps, IChartsAreaState> {

  constructor(props: IChartsAreaProps) {
	super(props);

	this.state = {
	  title: null,
	  paramsBar: null,
	  content: null
	};
  }

  componentDidUpdate(prevProps: Readonly<IChartsAreaProps>, prevState: Readonly<IChartsAreaState>, snapshot?: any) {

	if (prevProps.redrawArea !== this.props.redrawArea)
	  this.setState({
		...this.state,
		title: this.props.title,
		paramsBar: this.props.paramsBar,
		content: this.props.content
	  });
  }


  render() {

	return (
	  <div className={chartsStyles.chartsWrapper}>

		<div className={chartsStyles.chartsContentTop}>
		  <div className={chartsStyles.chartsTitle}>
			{this.state.title}
		  </div>
		  {this.state.paramsBar}
		</div>

		<div className={chartsStyles.chartsContent}>
		  {this.state.content}
		</div>
	  </div>
	);
  }
}

export default ChartsArea;
