import { message, makeTime } from '../utils/Common';
import internal from 'assert';
import { getPackedSettings } from 'http2';
import moment from 'moment';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { datesActions } from '../components/Charts/ChartsUtils';
import { Resource, ResponseStatusesEnum, SPointsFilter, SResponseDTO } from '../decl';
import { BACKEND_URL, getHeaders } from '../utils/AuthUtils';
import { CloseSessionAction } from './Page';

//Состояние хранилища для страницы Reports.
export interface ISiteLogState {
    isLoading: boolean,
    isLoadingTypes: boolean,
    dateFrom: Date,
    dateTo: Date,
    userLogData: SUserLogDTO | undefined,
    currentUserEventId: number,
    userEventsTypes: SUserEventTypeDTO[] | undefined,
    makeCSV: boolean,
}

export interface SUserLogDTO
{
    columns: {[columnName: string]: number};
    userLogData: (string | number | Date | null)[][];
}

export interface SUserEventTypeDTO
{
    id: number,
    name: string,
}

interface SetState { type: 'SET_SITE_LOG_STATE'; value: Partial<ISiteLogState> };
interface ReceiveUserEventTypes { type: 'RECEIVE_USER_EVENT_TYPES'; value: SUserEventTypeDTO[] | undefined }
interface ReceiveUserEvents { type: 'RECEIVE_USER_EVENTS'; value: SUserLogDTO | undefined }

export type KnownAction = CloseSessionAction | SetState | ReceiveUserEventTypes | ReceiveUserEvents;

export const actionCreators = {
    requestSiteLogTypes: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({type: 'SET_SITE_LOG_STATE', value: {isLoadingTypes: true}});
        
        if (appState) {
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
                //body: JSON.stringify(pointId)
            };
            fetch(BACKEND_URL + 'Log/userlogtypes', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        dispatch({type: 'RECEIVE_USER_EVENT_TYPES', value: data.body})
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                    }
                    else {
                        message.error('Ответ не получен.');
                    }
                    dispatch({type: 'SET_SITE_LOG_STATE', value: {isLoadingTypes: false}});
                })
                .catch(error => {
                    message.error('Ошибка:' + error);
                    console.log(error);
                    dispatch({type: 'SET_SITE_LOG_STATE', value: {isLoadingTypes: false}});
                });
            
        }
    },

    requestSiteLogs: (timeStart: Date, timeEnd: Date, textFilter: string, userEventTypeId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({type: 'SET_SITE_LOG_STATE', value: {isLoading: true}});
        
        if (appState) {
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify({timeStart, timeEnd, textFilter, userEventTypeId})
            };
            fetch(BACKEND_URL + 'Log/userlog', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        dispatch({type: 'RECEIVE_USER_EVENTS', value: data.body})
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                    }
                    else {
                        message.error('Ответ не получен.');
                    }
                    dispatch({type: 'SET_SITE_LOG_STATE', value: {isLoading: false}});
                })
                .catch(error => {
                    message.error('Ошибка:' + error);
                    console.log(error);
                    dispatch({type: 'SET_SITE_LOG_STATE', value: {isLoading: false}});
                });
            
        }
    },

    setSiteLogDates: (dateFrom: Date, dateto: Date) => ({ type: 'SET_SITE_LOG_STATE', value: {dateFrom: dateFrom, dateTo: dateto} } as SetState),
    setCurrentUserEventId: (id: number) => ({ type: 'SET_SITE_LOG_STATE', value: {currentUserEventId: id} } as SetState),
    setSiteLogMakeCSV: (flag: boolean) => ({ type: 'SET_SITE_LOG_STATE', value: {makeCSV: flag} } as SetState),
}

export const reducer: Reducer<ISiteLogState> = (state: ISiteLogState | undefined, incomingAction: Action): ISiteLogState => {
    if (state === undefined) {
        return { isLoading: false, isLoadingTypes: false, dateFrom: moment(makeTime(new Date(), 0, 0, 0)).add(-35, 'days').toDate(), dateTo: makeTime(new Date(), 23, 59, 59),
                userLogData: undefined, currentUserEventId: -1, userEventsTypes: undefined, makeCSV: false};
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "SET_SITE_LOG_STATE":
            return {
                ...state,
                ...action.value
            };
        case "RECEIVE_USER_EVENT_TYPES":
            return {
                ...state,
                userEventsTypes: action.value
            };
        case "RECEIVE_USER_EVENTS":
            return {
                ...state,
                userLogData: action.value
            };
        default:
            return state;
    }
};
