import { Action, Reducer } from 'redux';

//Состояние хранилища для страницы Reports.
export interface IReportsState {
    textFilter: string;
    needRefresh: boolean;
    commandToExecute: any;
    packetMode: boolean;
}

export interface SetTextFilterAction { type: 'SET_REPORT_TEXT_FILTER', textFilter: string };
export interface SetNeedRefreshAction { type: 'SET_NEED_REFRESH', needRefresh: boolean };
export interface SetCommandToExecuteAction { type: 'SET_COMMAND_TO_EXECUTE', commandToExecute: any };
export interface SetPacketModeAction { type: 'SET_PACKET_MODE', packetMode: boolean };

export type KnownAction = SetTextFilterAction | SetNeedRefreshAction | SetCommandToExecuteAction | SetPacketModeAction;


export const actionCreators = {
    setTextFilter: (textFilter: string) => ({ type: 'SET_REPORT_TEXT_FILTER', textFilter: textFilter } as SetTextFilterAction),
    setNeedRefresh: (needRefresh: boolean) => ({ type: 'SET_NEED_REFRESH', needRefresh: needRefresh } as SetNeedRefreshAction),
    setCommandToExecute: (commandToExecute: any) => ({ type: 'SET_COMMAND_TO_EXECUTE', commandToExecute: commandToExecute } as SetCommandToExecuteAction),
    setPacketMode: (packetMode: boolean) => ({ type: 'SET_PACKET_MODE', packetMode: packetMode } as SetPacketModeAction)
};

export const reducer: Reducer<IReportsState> = (state: IReportsState | undefined, incomingAction: Action): IReportsState => {
    if (state === undefined) {
        return { textFilter: '', needRefresh: false, commandToExecute: null, packetMode:false };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SET_REPORT_TEXT_FILTER':
            return {...state, textFilter: action.textFilter };
        case 'SET_NEED_REFRESH':
            return {...state, needRefresh: action.needRefresh };
        case 'SET_COMMAND_TO_EXECUTE':
            return {...state, commandToExecute: action.commandToExecute };
        case 'SET_PACKET_MODE':
            return { ...state, packetMode: action.packetMode };
        default:
            return state;
    }
};
