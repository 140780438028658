
// IconSelector.tsx

import * as React from 'react';
import {
  QuestionOutlined,
  DashboardOutlined,
  SmileOutlined,
  FormOutlined,
  TabletOutlined,
  ProfileOutlined,
  CheckCircleOutlined,
  WarningOutlined,
  UserOutlined,
  HighlightOutlined,
  TableOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  SoundOutlined,
    SnippetsOutlined,
    FileDoneOutlined,
    CalendarOutlined,
    AlertOutlined,
    AreaChartOutlined,
    LineChartOutlined,
    EnvironmentOutlined,
    SettingOutlined,
    PhoneOutlined,
    MessageOutlined,
    RedditOutlined,
    CarryOutOutlined,  
    ApiOutlined, 
    CalculatorOutlined,
    PartitionOutlined,
    ReconciliationOutlined,
    ExceptionOutlined,
    ReadOutlined, 
    CloudOutlined,
    SolutionOutlined, 
    AuditOutlined,
} from '@ant-design/icons';



interface IconSelectorProps {
    type: string,
    style?: any,
    onClick?: any
}

const IconSelector: React.FC<IconSelectorProps> = (props: IconSelectorProps) => {
  const Icons = {
    QuestionOutlined: <QuestionOutlined />,
    DashboardOutlined: <DashboardOutlined />,
    SmileOutlined: <SmileOutlined />,
    FormOutlined: <FormOutlined />,
    TabletOutlined: <TabletOutlined />,
    ProfileOutlined: <ProfileOutlined />,
    CheckCircleOutlined: <CheckCircleOutlined />,
    WarningOutlined: <WarningOutlined />,
    UserOutlined: <UserOutlined />,
    HighlightOutlined: <HighlightOutlined />,
    TableOutlined: <TableOutlined />,
    VideoCameraOutlined: <VideoCameraOutlined />,
    UploadOutlined: <UploadOutlined />,
    SoundOutlined: <SoundOutlined />,
    SnippetsOutlined: <SnippetsOutlined />,
    FileDoneOutlined: <FileDoneOutlined />,
    CalendarOutlined: <CalendarOutlined />,
    AlertOutlined: <AlertOutlined />,
    AreaChartOutlined: <AreaChartOutlined />,
    LineChartOutlined: <LineChartOutlined />,
    EnvironmentOutlined: <EnvironmentOutlined />,
    SettingOutlined: <SettingOutlined />,
    PhoneOutlined: <PhoneOutlined />,
      MessageOutlined: <MessageOutlined />,
      RedditOutlined: <RedditOutlined />,

CarryOutOutlined: <CarryOutOutlined />,  
ApiOutlined: <ApiOutlined />, 
CalculatorOutlined: <CalculatorOutlined />,
PartitionOutlined: <PartitionOutlined />,
ReconciliationOutlined: <ReconciliationOutlined />,
ExceptionOutlined: <ExceptionOutlined />,
ReadOutlined: <ReadOutlined />, 
CloudOutlined: <CloudOutlined />,
SolutionOutlined: <SolutionOutlined />,
AuditOutlined: <AuditOutlined /> 
  };

  const getIcon = (type: string) => {
    // Default Icon when not found
    let comp = <QuestionOutlined />;

    let typeNew = type;

    // Default is Outlined when no theme was appended (ex: 'smile')
    if (!typeNew.match(/.+(Outlined|Filled|TwoTone)$/i)) {
      typeNew += 'Outlined';
    }

    // If found by key then return value which is component
    const found = Object.entries(Icons).find(([k]) => k.toLowerCase() === typeNew.toLowerCase());
    if (found) {
      [, comp] = found;
    }

    return comp;
  };

  return getIcon(props.type);
};

export default IconSelector;
