import * as React from 'react';
import styles from '../resources/PageFooter.module.less';

import teploLogoWhite from "../images/logo_promodem_footer.png";
import mailIconWhite from "../images/mailIconWhite.png";
import phoneIconWhite from "../images/phoneIconWhite.png";

import {
    FilePdfOutlined
} from '@ant-design/icons';

interface PageFooterProps{

}

export default class PageFooterPM extends React.PureComponent<PageFooterProps>{
    render() {
        const year = new Date().getFullYear();
        const copyRight = 'ООО «Аналитик - ТС» 1992-' + year + 'г.';
    return (
      <div className={styles.footer}>
        <div style={{ paddingLeft: "2.5vw" }}>
          <img src={teploLogoWhite} style={{ width: "4vw", height: "2.2vw" }} />
        </div>

        <div style={{ paddingLeft: "1.3vw", color: "#ffffffc7" }}>
                <span>{ copyRight}</span>
          <br />
          <span>125424, г.Москва, Волоколамское шоссе, 73</span>
        </div>
        <div style={{ flex: "1" }} />

        <div style={{ paddingRight: "15vw" }}>
            <FilePdfOutlined/>            
          <a
            href="Documents/Руководство PROMODEM CLOUD и модуль АСКУЭ.pdf?v1"
            target="_blank"
            style={{
              textDecoration: "underline",
              paddingLeft: "0.4em",
              color: "white"
            }}
          >
                Руководство PROMODEM CLOUD + модуль АСКУЭ
        </a>
        </div>

        <div style={{ paddingRight: "5.3vw" }}>
          <img src={mailIconWhite} style={{ width: "1.2vw", height: "1.2vw" }} />
          <a
            href="mailto:support@promodem.ru"
            target="_top"
            style={{
              textDecoration: "underline",
              paddingLeft: "0.4em",
              color: "white"
            }}
          >
            support@promodem.ru
          </a>
        </div>
        <div style={{ paddingRight: "2.5vw" }}>
          <table style={{ margin: "0 auto" }}>
            <tbody>
              <tr>
                <td>
                  <img
                    src={phoneIconWhite}
                    style={{ width: "1.2vw", height: "1.2vw" }}
                    alt="phone icon"
                  />
                </td>
                <td
                  style={{
                    paddingLeft: "0.4em",
                    fontSize: "1vw"
                  }}
                >
                  +7(495)775-60-08
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}