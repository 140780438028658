import * as React from 'react';

//import styles from '../resources/Page.module.less';
import { Button, Modal, message, Select, Input, Row, Col, Table, Tag, DatePicker, Tabs, Space, InputNumber, Checkbox, Switch, Divider } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';

import { MODAL_WRAP_CLASSNAME } from '../../utils/Const';
//import { SPointsFilter } from '../decl';

//import Groups from './Groups';
//import { AbonentOperationDTO, AbonentOperationTypeDTO } from '../store/OperationsStore';
//import RangePickerLimit from './RangePickerLimit';

import pageStyles from '../../resources/Page.module.less';
import SParametersEdit from '../Admin/SParametersEdit';

import * as AdminStore from '../../store/AdminStore';
import { Group, Point, Resource, SParameterValTypes } from '../../decl';
import { DeleteTwoTone, EditTwoTone, ExclamationCircleOutlined, LoadingOutlined, PlayCircleTwoTone, StopTwoTone, SyncOutlined } from '@ant-design/icons';
import { IPointDictionaries, PointAllInfoDTO, QueryItemTypes, SQueryItemDTO } from '../../store/AdminStore';
import { validateLocaleAndSetLanguage } from 'typescript';
import modal from 'antd/lib/modal';
import { sendRequestToBackend } from '../../utils/AuthUtils';
import BaseMap from '../Map/BaseMap';
import SimpleMap from '../Map/SimpleMap';
import { DriveEtaOutlined, Message } from '@material-ui/icons';
import * as Const from '../../utils/Const';
import { DraggableModal } from 'ant-design-draggable-modal';
const { Option } = Select;

type Props = {
    onHide(): void,
    onOk?(): void,
    caption: string,
    text: string | null,
    title: string,
    width: number,
    height: number,
    zIndex: number,
    onChangeText(val: string): void,
}

type State = {

}

class EditTextForm extends React.Component<Props, State> {
    constructor(props: Props, state: State) {
        super(props);

    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps: Props, prevState: State) {

    }

    //=============================================================================
    render() {
        return (
            <DraggableModal
                maskClosable={true}
                bodyStyle={{padding: '5px'}}
                title={this.props.title}
                zIndex={this.props.zIndex}
                wrapClassName={MODAL_WRAP_CLASSNAME}
                open={this.props.text != null}
                onCancel={this.props.onHide}
                initialWidth={this.props.width}
                initialHeight={this.props.height}
                onOk={this.props.onOk}
            >
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    <Row className={pageStyles.FormRowWrapper2_1}>
                        <Col span = {8}>
                            { this.props.caption ?? '' }
                        </Col>
                        <Col span = {16}>
                        <Input
                            placeholder= {"Название роли"} 
                            value={this.props.text ?? ''}  
                            onChange = {(e) => this.props.onChangeText(e.target.value)}
                        />
                        </Col>
                    </Row>
                </Space>
            </DraggableModal>
        );
    }
}

export default EditTextForm;