import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';

import { ApplicationState } from '../store';
import * as PageStore from '../store/Page';
import * as TasksStore from '../store/Tasks';
import * as UserProfileStore from '../store/UserProfile';
import NetiContainer from '../components/NetiContainer'
import { RequestContext } from '../decl';

const actionCreators = { ...PageStore.actionCreators, ...UserProfileStore.actionCreators, ...TasksStore.actionCreators };

type SensorsProps =
    { page: PageStore.PageState, userProfile: UserProfileStore.UserProfileState } &
    typeof actionCreators &
    RouteComponentProps<{}>

interface ISensorsState {

}

class Sensors extends React.PureComponent <SensorsProps, ISensorsState > {
    constructor(props: SensorsProps) {
        super(props);
        this.onReset = this.onReset.bind(this);
    }

    componentDidMount() {
        window.call_page_mount('/sensors');
    }

    componentWillUnmount() {
        window.call_page_unmount('/sensors');
    }

    onReset(control: any) {
        //console.log(control);
        control.Execute({Source: control, Name: 'resetSensorsGrids', Params: {refreshPeriod: this.props.userProfile.profile.Sensors?.RefreshPeriod}});
        // this.props.setIsLoading(true);
        // window.call_refreshgrid(
        //     control,
        //     {refreshPeriod: this.props.userProfile.profile.Sensors?.RefreshPeriod},
        //     'resetSensorsGrids',
        //      ()=>{
        //         this.props.setIsLoading(false, 500);
        //     }
        // );
    }

    onCommand(cmd: any) {
        window.call_execute(cmd);
    }
    onExternalCommand(cmd: any) {
        window.call_execute(cmd);
    }

    render() {
        const controlId: number = 92;
        const context: RequestContext = {LocalPath: '/sensors', Properties: {}}
        return <NetiContainer
            controlId={controlId}
            onReset={this.onReset}
            onCommand={this.onCommand}
            onExternalCommand={this.onExternalCommand}
            context={context}
            userProfile={this.props.userProfile}
            setProfile={this.props.changeProfile}
            setIsLoading = {this.props.setIsLoading}
            setActiveQueries = {this.props.setActiveQueries}
            isNetiReady={this.props.page.isNetiReady}>
        </NetiContainer>
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            page: state.page,
            userProfile: state.userProfile,
        };
    },
    actionCreators
)(Sensors as any);
