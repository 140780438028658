import * as React from 'react';

import {Button, Input, Table, Checkbox, Tooltip } from 'antd';
import { SyncOutlined, LoadingOutlined, SettingTwoTone  } from '@ant-design/icons';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

import { message } from '../../utils/Common';
import styles from '../../resources/Page.module.less';

import * as Const from '../../utils/Const';
import { showColumnsSelector, IColumnDef, TColumn } from '../Page/ColumnsSelector';
import TCPIPTable from './TCPIPTable';

const MIN_REFRESH_INTERVAL: number = 30;
const GET_CONNECTED_INTERVAL: number = 5;

type TCPIPProps =
{
    windowHeight: number,
    showPointInfo: any,
    showPointEditForm: any,
    setIsLoading: any,
    showModal: any,
    changeProfile: any,
    init: boolean,
    initDone: any,
    tcolumns: string | undefined
}

interface State {
    filter: string,
    currentClientId: number | null,
    refreshInterval: number,
    autoRefresh: boolean,
    //isLoading: boolean
    needRefresh: boolean,
    // columnDefs: IColumnDef[],
    columns: TColumn[]
}

export default class TCPIP extends React.PureComponent<TCPIPProps, State> {

    constructor(props: TCPIPProps){
        super(props);

        const columnsDefault: TColumn[] = [
            {Name: 'npp', Caption: '№ пп', IsHidden: false, Width: 50},
            {Name: 'info', Caption: 'Инфо', IsHidden: false, Width: 60},
            {Name: 'connect', Caption: 'Связь', IsHidden: false, Width: 60},
            {Name: 'remoteIP', Caption: 'IP адрес', IsHidden: false, Width: 100},
            {Name: 'number', Caption: 'Точка учёта', IsHidden: false, Width: 150},
            {Name: 'phoneNumber', Caption: 'Номер телефона', IsHidden: false, Width: 100},
            {Name: 'clientId', Caption: 'Идент.', IsHidden: false, Width: 80},
            {Name: 'modemId', Caption: 'Модем или порт', IsHidden: false, Width: 80},
            {Name: 'server', Caption: 'Сервер', IsHidden: false, Width: 100},
            {Name: 'firstConnection', Caption: 'Первое подключение', IsHidden: false, Width: 180},
            {Name: 'lastConnection', Caption: 'Последнее подключение', IsHidden: false, Width: 180},
            {Name: 'connectionsCount', Caption: 'Кол-во', IsHidden: false, Width: 70},
        ]

        const columsFromProfile: TColumn[] | undefined = this.props.tcolumns ? JSON.parse(this.props.tcolumns) : undefined;

        this.state = {
            //connections: [],
            filter: '',
            currentClientId: null,
            refreshInterval: MIN_REFRESH_INTERVAL,
            autoRefresh: false,
            //isLoading: false,
            needRefresh: false,
            columns: columsFromProfile??columnsDefault
        }
    }
    componentDidMount(){

    }

    componentDidUpdate(prevProps: TCPIPProps, prevState: State) {
        if(this.props.init && !prevProps.init){
            this.refresh(null);
            this.props.initDone();
        }
    }
    componentWillUnmount(): void {

    }

    refresh = (ev: any) => {
        if(!this.state.needRefresh){
            this.setState({needRefresh: true}); 
        }
    }

    setIsLoading = (isLoading: boolean) => {
        if(this.props.init){
            this.props.setIsLoading(isLoading);
            if(!isLoading){
                this.props.initDone();
            }
        }
        else{
            this.setState({needRefresh: isLoading});
        }
    }

    onChangeAutoRefresh = (e: CheckboxChangeEvent) => {
        if(e.target.checked && (isNaN(this.state.refreshInterval) || (this.state.refreshInterval < MIN_REFRESH_INTERVAL))){
            message.warning('Для автообновления нужно установить интервал не меньше ' + MIN_REFRESH_INTERVAL + ' секунд.');
        }
        else{
            this.setState({autoRefresh: e.target.checked});
        }
    }

    onColumnsChange = () => {
        const fixedColumns: string[] = ['npp', 'info','number'];
        const columnDefs: IColumnDef[] = this.state.columns.map(c=>{
            return {group: undefined, code: c.Name, caption: c.Caption, checked: !c.IsHidden, disabled: fixedColumns.includes(c.Name)}
        });

        showColumnsSelector(columnDefs, false, 10, this.props.showModal, (visibility: any)=>{
            if(visibility !== null){
                const columns: TColumn[] = this.state.columns.slice();
                columns.forEach((c)=>c.IsHidden = !visibility[c.Name]);
                this.setState({columns: columns});

                const value: string = JSON.stringify(this.state.columns);
                this.props.changeProfile(Const.PROFILE_ADMIN, {TCPIPColumnsLayout: value});
    
                return {ok: true, message: 'Состав колонок изменён.'};
            }
        });
    }

    //===============================================
    render() {
        const clientHeight = this.props.windowHeight - 230; 
  
        return (

            <div style={{height:clientHeight, padding:2, backgroundColor: '#cfcfcf'}}>

            <div className={styles.flexcontainer}>
            <div className={styles.flexselfitem} style={{maxWidth:150}}>
                <Button type="primary" shape="round" disabled={this.state.needRefresh} icon={this.state.needRefresh ? <LoadingOutlined/> : < SyncOutlined/>}  onClick={this.refresh}>Обновить</Button>
            </div>

            <div className={styles.flexselfitem}  style={{maxWidth:150}}>
            <Input 
                  placeholder= {"Фильтр точек учёта"} 
                  value={this.state.filter}  
                  onChange = {(e)=>{
                      this.setState({filter:e.target.value});
                  }} 
              />
            </div>
            <div className={styles.flexselfitem}  style={{maxWidth:200, justifyContent: 'flex-end'}}>
            <Tooltip title={'Автоматическое обновление таблицы. Состояние связи обновляется всегда - каждые ' + GET_CONNECTED_INTERVAL + ' секунд, при наличии связи с TCP сервером.'}>
                <Checkbox checked={this.state.autoRefresh} onChange={this.onChangeAutoRefresh}>Автообновление</Checkbox>
            </Tooltip>
            </div>
            <div className={styles.flexselfitem}   style={{justifyContent: 'flex-start'}}>
                <span> {'Частота обновления (от ' + MIN_REFRESH_INTERVAL + ' сек):'} </span>
                <Input 
                  style={{width:50, marginLeft:20, color: this.state.refreshInterval < MIN_REFRESH_INTERVAL ? 'red' : Const.COLORS.SvgIconColor}}
                  value={isNaN(this.state.refreshInterval) ? '' : this.state.refreshInterval}  
                  onChange = {(e)=>{
                    const value = parseInt(e.target.value);
                    this.setState({refreshInterval: value, autoRefresh: false});
                }} 
              />
            </div>
            <div className={styles.flexselfitem} style={{maxWidth:100, justifyContent: 'flex-end'}}>
                <Tooltip title={'Выбор колонок'}>
                    <Button shape="circle" disabled={this.state.needRefresh} icon={<SettingTwoTone twoToneColor={Const.COLORS.SvgIconColor}/>}  onClick={this.onColumnsChange}></Button>
                </Tooltip>
            </div>
            </div>
            <TCPIPTable
                setIsLoading = {this.setIsLoading}
                showPointInfo = {this.props.showPointInfo}
                showPointEditForm = {this.props.showPointEditForm}
                filter = {this.state.filter}
                refreshInterval = {this.state.refreshInterval}
                getConnectedInterval = {GET_CONNECTED_INTERVAL}
                autoRefresh = {this.state.autoRefresh}
                needRefresh = {this.state.needRefresh}
                clientHeight = {clientHeight}
                tcolumns = {this.state.columns}
            />
            </div>
        );
    }
}


