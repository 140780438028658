import * as React from 'react';

import styles from '../../resources/Page.module.less';
import { Modal, Select, Input, Row, Col, InputNumber, message } from 'antd';

import { deepCopyObj } from '../../utils/Common';
import { MODAL_WRAP_CLASSNAME } from '../../utils/Const';
import { SBilContactDTO, SBillingTariffDTO } from './BillingDecls';
import { sendRequestToBackend } from '../../utils/AuthUtils';

type Props = {
    tariff: SBillingTariffDTO | null,
    onHide() : void,
    onOk(tariff: SBillingTariffDTO) : void,
    zIndex: number,
}

type State = {
    tariff: SBillingTariffDTO | null,
}

export default class TariffEditForm extends React.Component<Props, State> {
    constructor(props: Props, state: State) {
        super(props);

        this.state = {
            tariff: null,
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.tariff === null && this.props.tariff !== null) {
            const t = deepCopyObj(this.props.tariff);
            this.setState({tariff: t});
        }
    }

    private onTariffChange = (field: keyof SBillingTariffDTO, val: any) => {
        if (this.state.tariff != undefined) {
            let res = {...this.state.tariff, [field]: val};
            this.setState({tariff: res});
        }
    }

    render() {
        return (
            <Modal
                title={ (this.props.tariff?.id ?? 0) < 0 ? 'Добавление тарифа' : "Редактирование тарифа" }
                wrapClassName={MODAL_WRAP_CLASSNAME}
                width={300}
                zIndex={this.props.zIndex}
                centered
                open={this.props.tariff != null}
                // footer={[
                //     <div style={{ display: 'flex', alignItems: 'baseline' }}>
                //         <Button key="1" type='primary' onClick={this.props.onHide} style={{marginLeft: 'Auto'}}>Отмена</Button>
                //         <Button key="2" type='primary' onClick={this.onOk}>{this.props.operation === undefined ? "Добавить" : "Изменить"}</Button>
                //     </div>
                // ]}
                onCancel={() => { this.props.onHide() }}
                onOk={() => this.state.tariff != undefined ? this.props.onOk(this.state.tariff) : null}
            >
                <div className={styles.FormWrapper}>
                    <Input.Group>
                        <Row key={'k2'} className={styles.FormRowWrapper}>
                            <Col key='c1' span={8}>
                                Тариф
                            </Col>
                            <Col span={16}>
                                <Input value={this.state.tariff?.name} onChange={val => this.onTariffChange('name', val.target.value)} size='small'/>
                            </Col>
                        </Row>
                        <Row className={styles.FormRowWrapper}>
                            <Col span={8}>
                                Цена
                            </Col>
                            <Col span={16}>
                                <InputNumber className={styles.Width100} value={this.state.tariff?.defaultPrice} onChange={val => this.onTariffChange('defaultPrice', val)} size='small'/>
                            </Col>
                        </Row>
                    </Input.Group>
                </div>
            </Modal>
        );
    }
}