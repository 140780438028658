import * as React from 'react';
import { connect } from 'react-redux';

import { netiExecute, getModalZIndex, message } from '../utils/Common';

import { ApplicationState } from '../store';
import * as PageStore from '../store/Page';
import * as TasksStore from '../store/Tasks';
import * as UserProfileStore from '../store/UserProfile';
import * as SettingsStore from '../store/SettingsStore';
import * as AdminStore from '../store/AdminStore';
import { BaseProfile, Point, USER_RIGHTS, Command } from '../decl';
import * as Const from '../utils/Const';

import PointEditForm from './Admin/PointEditForm';
import PollingForm from './Admin/PollingForm';

const actionCreators = { ...PageStore.actionCreators, ...UserProfileStore.actionCreators, ...AdminStore.actionCreators, ...SettingsStore.actionCreators, ...TasksStore.actionCreators };


type ModalPointEditorProps =
    {
        page: PageStore.PageState,
        tasks: TasksStore.TasksState,
        profile: BaseProfile,
        userProfile: UserProfileStore.UserProfileState,
        pointId: string,
        adminStore: AdminStore.IAdminState,
    } &
    typeof actionCreators;

interface IModalPointEditorState {
    modalZIndex: number,
    pollingAbonents: Point[] | null
}

class ModalPointEditor extends React.PureComponent<ModalPointEditorProps, IModalPointEditorState> {

    constructor(props: ModalPointEditorProps) {
        super(props);
        this.state = {
            modalZIndex: 0,
            pollingAbonents: null,
        }
    }

    componentDidMount() {
        this.props.requestSettingsTypes();
         if (this.props.page.editPoint) {
             this.editPoint(this.props.page.editPoint.id, undefined);
         }
    }

    execute = (cmd: any) => {
        netiExecute(cmd, this.props.userProfile, this.props.changeProfile, this.props.setIsLoading, this.props.setActiveQueries, null, this.props.showModal);
    }

    //Редактирование точки учёта.
    editPoint = (pointId: string, groupId: string | undefined) => {
        this.setState({ modalZIndex: getModalZIndex() });
        this.props.requestPointDicts();
        this.props.requestQueryItemTypes();
        this.props.requestPointAllInfo(pointId, false);
    }

    onPointWrite = (close: boolean, finish: (success: boolean, point: AdminStore.PointAllInfoDTO) => void) => {
        if (this.props.adminStore.pointAllInfo !== undefined) {
            if (this.props.adminStore.pointAllInfo.pointInfo.id === null) {
                this.props.requestPointCreate(this.props.adminStore.pointAllInfo, undefined, false, this.props.adminStore.queryItem, (point: AdminStore.PointAllInfoDTO) => {
                    finish ? finish(true, point) : null;
                    if (close) {
                        this.props.editPoint(undefined);
                        this.props.setPointAllInfo(undefined);
                    }
                });
            } else {
                this.props.requestPointWrite(this.props.adminStore.pointAllInfo, (s: boolean, point: AdminStore.PointAllInfoDTO) => {
                    finish ? finish(s, point) : null;
                    if (s === true && close) {
                        this.props.editPoint(undefined);
                        this.props.setPointAllInfo(undefined);
                    }
                });
            }
        }
    }

    readPointSettings = (pointId: string | undefined | null, success?: () => void) => {
        if (pointId && this.props.page.editPoint) {
            this.props.setIsLoading(true, undefined, "Сбор данных с прибора, это может занять несколько минут.");
            this.props.addPointLog(this.props.page.editPoint);

            this.props.readData(
                0,
                [pointId],
                100,
                true,
                (code: number, ids: any) => {
                    if (code === 0) {
                        this.props.setIsLoading(false);
                        message.success('Запрос на получение данных из приборов отправлен.');
                    }
                    if (code === 2) {
                        //this.props.setIsLoading(false);
                        message.success('Данные из приборов получены.');
                        success ? success() : null;
                    }
                    if (code === 4) {
                        message.warning('Не все данные из приборов удалось получить.');
                        //this.props.setIsLoading(false);
                        success ? success() : null;
                    }
                },
                (error: string) => {
                    //this.props.setIsLoading(false);

                    message.error(error);
                });
        }
        else {
            message.error('Точка учёта для сбора настроек не определена');
        }
    }

    public render() {
        return (
            <>
                <PointEditForm
                    visible={true}
                    onHide={() => {
                        this.props.editPoint(undefined); this.props.setPointAllInfo(undefined)
                    }}
                    onWrite={this.onPointWrite}
                    changeQueryItem={this.props.setQueryItem}

                    isConnParsLoading={this.props.adminStore.isConnParsLoading}
                    isPointAllInfoLoading={this.props.adminStore.isPointAllInfoLoading}
                    isQueryItemTypesLoading={this.props.adminStore.isQueryItemTypesLoading}
                    pointAllInfo={this.props.adminStore.pointAllInfo}
                    pointDicts={this.props.adminStore.pointDicts}
                    queryItemTypes={this.props.adminStore.queryItemTypes}
                    queryItem={this.props.adminStore.queryItem}

                    allResources={this.props.userProfile.resources}
                    changePoint={this.props.setPointAllInfo}
                    requestConnPars={this.props.requestConnPars}
                    setIsLoading={this.props.setIsLoading}
                    baseMapLayer={this.props.profile.Map?.BaseLayer}
                    changeBaseMapLayer={(val: string) => this.props.changeProfileValue(Const.PROFILE_MAP, 'BaseLayer', val)}
                    readSettings={this.readPointSettings}
                    requestPointAllInfo={(success: () => void) => this.props.requestPointAllInfo(this.props.adminStore.pointAllInfo?.pointInfo.id ?? null, false, success)}

                    changeProfile={this.props.changeProfile}
                    setActiveQueries={this.props.setActiveQueries}
                    showModal={this.props.showModal}
                    showPointInfo={this.props.showPointInfo}
                    showPolling={() => {
                        if (this.props.page.editPoint) {
                            this.setState({ pollingAbonents: [this.props.page.editPoint] });
                        }
                    }}
                    userProfile={this.props.userProfile}
                    zIndex={this.state.modalZIndex}
                    windowHeight={this.props.page.windowHeight}
                    activeTasks={this.props.tasks.activeTasks}
                    setActiveLogTask={this.props.setActiveLogTask}
                    getActiveTasks={this.props.getActiveTasks}
                    readData={this.props.readData}
                    showPointLog={
                        (pointId: string) => {
                            if (this.props.page.editPoint)
                                this.props.addPointLog(this.props.page.editPoint);
                        }
                    }
                />
                {
                    <PollingForm
                        points={this.state?.pollingAbonents ?? null}
                        zIndex={this.state.modalZIndex}
                        onHide={() => this.setState({ pollingAbonents: null })}
                        setIsLoading={this.props.setIsLoading}
                        groupName={null}
                        changeProfile={this.props.changeProfile}
                        setActiveQueries={this.props.setActiveQueries}
                        showModal={this.props.showModal}
                        showPointInfo={this.props.showPointInfo}
                        userProfile={this.props.userProfile}
                        windowWidth={this.props.page.windowWidth}
                        setActiveLogTask={this.props.setActiveLogTask}
                    />
                }
            </>
        );
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            adminStore: state.adminStore,
            page: state.page,
            tasks: state.tasks,
            profile: state.userProfile?.profile,
            userProfile: state.userProfile,
            pointId: state.page.pointId,
        }
    },
    actionCreators
)(ModalPointEditor as any);





