import * as React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';

import {Button, Modal, Select, Input, Row, Col, Table, Tag, DatePicker, Tabs, Space, InputNumber, Checkbox, Switch, Divider, Tooltip } from 'antd';
import { EyeTwoTone, EyeInvisibleTwoTone, QuestionCircleTwoTone, ScheduleTwoTone } from '@ant-design/icons';

import { AlignType } from 'rc-table/lib/interface';

import { BACKEND_URL, sendRequestToBackend } from '../../utils/AuthUtils';
import { convertBase64ToBinary, saveFile, unicodeToWin1251, getModalZIndex, message, stringSorter, toFullTimeString } from '../../utils/Common';
import moment from 'moment';
import styles from '../../resources/Page.module.less';
import { TColumn } from '../Page/ColumnsSelector';

import { STCPIPConnectionDTO, SSensorDescriptionDTO, Command} from '../../decl';
import * as Const from '../../utils/Const';
import { TCPLogTable } from './TCPLogTable';

const { Column } = Table;

type Props =
{
    setIsLoading: any,
    showPointInfo: any,
    showPointEditForm: any,
    filter: string,
    refreshInterval: number,
    getConnectedInterval: number,
    autoRefresh: boolean,
    needRefresh: boolean,
    clientHeight: number,
    tcolumns: TColumn[]
}

interface State {
    connections: STCPIPConnectionDTO[],
    connectedIds: number[],
    connectToTCPServer: boolean,
    currentClientId: number | null,
    isLoading: boolean,
    showTCPLogForm: boolean,
    TCPLogZModal: number,

}

export default class TCPIPTable extends React.Component<Props, State> {
    _timer: any;
    _timer2: any;

    constructor(props: Props){
        super(props);

        this._timer = undefined;
      
        this.state = {
            connections: [],
            connectedIds: [],
            connectToTCPServer: false,
            currentClientId: null,
            isLoading: false,
            showTCPLogForm: false,
            TCPLogZModal: 0
        }
    }
    componentDidMount(){
        this.setTimer(this.props.autoRefresh);
        this.refresh(null);

        this._timer2 = setInterval(this.getConnetedClientIds, this.props.getConnectedInterval * 1000);

    }

    shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>, nextContext: any): boolean {
        if(this.props.autoRefresh != nextProps.autoRefresh){
            this.setTimer(nextProps.autoRefresh);
        }
        else if(!this.props.needRefresh && nextProps.needRefresh){
            this.refresh(null);
        }      

        return (this.state.isLoading && !nextState.isLoading) ||
         (nextProps.filter != this.props.filter) ||
          this.props.tcolumns !== nextProps.tcolumns ||
          this.state.connectedIds !== nextState.connectedIds ||
          this.state.connectToTCPServer !== nextState.connectToTCPServer ||
          this.state.showTCPLogForm !== nextState.showTCPLogForm;
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
    }

    componentWillUnmount(): void {
        if (this._timer) {
            clearInterval(this._timer);
            this._timer = undefined;
        }
        if (this._timer2) {
            clearInterval(this._timer2);
            this._timer2 = undefined;
        }
    }

    setTimer = (autoRefresh: boolean) => {
        if(this._timer) clearInterval(this._timer);
        if(autoRefresh){
            this._timer = setInterval(this.refresh, this.props.refreshInterval * 1000);
            this.refresh(null);
        }
        else{
            this._timer = undefined;
        }
    }

    getColumns = () => {
        const columnExt: any = {
            "npp" : {dataIndex: null, sorter: false, align: 'left' as AlignType,
                render: (text: any, record: any, index: number) => 
                    <span>{(index + 1).toString() + '.'}</span>
       },
            "info" : {dataIndex: null, sorter: false, align: 'center' as AlignType,
                 render: (text: any, record: any) => 
                    <Button
                        onClick={() => this.props.showPointInfo(record.pointId, record.number)}
                        size="middle"
                        shape="circle"
                        icon={<ScheduleTwoTone twoToneColor={Const.COLORS.SvgIconColor} />} 
                    />
            },
            "connect" : {dataIndex: null, sorter: true, align: 'center' as AlignType,
                 render: (text: any, record: any) => 
                    <Button
                         onClick={
                            () => this.setState({ TCPLogZModal: getModalZIndex(), showTCPLogForm: true, currentClientId: record.clientId })
                         }
                        size="middle"
                        shape="circle"
                        icon={record.connect ? <EyeTwoTone twoToneColor={'#00FF00'} /> : <EyeInvisibleTwoTone twoToneColor={this.state.connectToTCPServer ? '#FF0000' : '#7F7F7F'} />} 
                    />
            },
            "number" : {dataIndex: "number", sorter: true, align: 'left' as AlignType, render: undefined },
            "remoteIP" : {dataIndex: "remoteIP", sorter: true, align: 'left' as AlignType, render: undefined },
            "phoneNumber" : {dataIndex: "phoneNumber", sorter: true, align: 'left' as AlignType, render: undefined },
            "clientId" : {dataIndex: "clientId", sorter: true, align: 'left' as AlignType, render: undefined },
            "modemId" : {dataIndex: "modemId", sorter: true, align: 'left' as AlignType, render: undefined },
            "server" : {dataIndex: "server", sorter: true, align: 'left' as AlignType, render: undefined },
            "firstConnection" : {dataIndex: "firstConnection", sorter: true, align: 'left' as AlignType, render: undefined },
            "lastConnection" : {dataIndex: "lastConnection", sorter: true, align: 'left' as AlignType, render: undefined },
            "connectionsCount" : {dataIndex: "connectionsCount", sorter: true, align: 'left' as AlignType, render: undefined },
        }
        const columns: any = [];
        this.props.tcolumns.forEach(c=>{
            const ext: any = columnExt[c.Name];
            if(!c.IsHidden){
                columns.push({
                    key: c.Name,
                    title: c.Caption,
                    width: c.Width,
                    align: ext.align,
                    dataIndex: ext.dataIndex,
                    sorter: ext.sorter ? (a: any, b: any) => stringSorter(a[c.Name], b[c.Name]) : false,
                    render: ext.render
                });
            }
        });
        return columns;
    }

    //Загрузка TCPIP клиентов.
    refresh = (ev: any)=>{
        if((ev !== undefined || this.props.autoRefresh) && !this.state.isLoading){
            this.setState({isLoading: true});
            sendRequestToBackend(
                null,
                'admin/gettcpipconnections',
                (response: STCPIPConnectionDTO[]) => {
                    this.setState({connections: response, isLoading: false});
                },
                this.props.setIsLoading,
                (error: any) => {
                    this.setState({isLoading: false});
                    console.log(error);
                });
        }
    }

    //Получение идентификаторов подключенных клиентов.
    getConnetedClientIds = () => {
        sendRequestToBackend(
            null,
            'admin/getconnclients',
            (response: number[]) => {
                this.setState({connectedIds: response, connectToTCPServer: true});
            },
            null,
            (error: any) => {
                this.setState({connectedIds:[], connectToTCPServer: false});
                console.log(error);
                if (this._timer2) {
                    clearInterval(this._timer2);
                    this._timer2 = undefined;
                }
            });
    }
    //===============================================
    render() {
        const filterConnections: STCPIPConnectionDTO[] = this.state.connections.filter(c=>(this.props.filter.length === 0 ||
               c.point.number.toLocaleLowerCase().includes(this.props.filter.toLocaleLowerCase()) ||
               c.modemId.includes(this.props.filter)
               ));
      
        const getConnections = () => {
            return Object.values(filterConnections).map(item =>
              ({
                  key: item.point.id,
                  pointId: item.point.id,
                  connect: item.clientId ? this.state.connectedIds.includes(item.clientId) : false,
                  remoteIP: item.remoteIP ? item.remoteIP.substring(0, item.remoteIP.indexOf(':')) : '',
                  number: item.point.number,
                  modemType: item.modemType,
                  phoneNumber: item.phoneNumber,
                  clientId: item.clientId,
                  modemId: item.modemId,
                  server: item.connectedTo,
                  firstConnection: item.firstConnection ? toFullTimeString(new Date(item.firstConnection)) : '',
                  lastConnection: item.lastConnection ? toFullTimeString(new Date(item.lastConnection)) : '',
                  connectionsCount: item.connectionsCount
              })
          );
            }

        const scrollVisibilityTable = {y:this.props.clientHeight-145};
  
        return (
            <>
            <Table
                columns = {this.getColumns()}
                dataSource={getConnections()}
                pagination={false}
                scroll={scrollVisibilityTable}
                bordered
                onRow={(record, rowIndex) => {
                    return {
                    onClick: (event) => {}, // click row
                    onDoubleClick: (event) => {
                        this.props.showPointEditForm(record.pointId, null);
                    },
                    onContextMenu: (event) => {}, // right button click row
                    onMouseEnter: (event) => {}, // mouse enter row
                    onMouseLeave: (event) => {}, // mouse leave row
                    };
                }}
                rowClassName={(record,index)=>(record.clientId === this.state.currentClientId ? styles.selectedRow : styles.simpleRow)}
            />
            <TCPLogTable
                visible={this.state?.showTCPLogForm ?? false}
                zIndex ={this.state.TCPLogZModal}
                clientId={this.state.currentClientId ? this.state.currentClientId.toString() : null}
                onHide={() => this.setState({showTCPLogForm: false})}
            />
            </>
        );
    }
}
