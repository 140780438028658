import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Form, Input, Button, Checkbox } from 'antd';
import { message } from '../utils/Common';
//import { ApplicationState } from '../store';
//import * as UserStore from '../store/User';
import * as PageStore from '../store/Page';
import * as UserProfileStore from '../store/UserProfile';
import styles from '../resources/LoginForm.module.less';

import { login } from '../utils/AuthUtils';
import { BaseProfile, CommonProfile } from "../decl";


//const actionCreators = { ...PageStore.actionCreators, ...UserProfileStore.actionCreators };

interface LoginProps {
    showModal: any;
    openSession: any;
    setIsLoading: any;
}


interface ResponseType {
    ok: boolean,
    message: string
}

export default class LoginForm extends React.PureComponent<LoginProps>{
    onFinish = (values: any) => {
        const showmodal = this.props.showModal;
        const setIsLoading = this.props.setIsLoading;

        setIsLoading(true);

        var request = { User: values.username, Password: values.password };
        login(request).then(
            result => {
                if (result.ok) {
                    message.success(result.message);
                    this.props.openSession();
                    showmodal(null);
                    setIsLoading(false, 1000);
                }
                else {
                    setIsLoading(false);
                    //message.error("Вход выполнить не удалось, проверьте правильность имени и пароля.");
                    message.error(result.message);
                    console.log(result.message);
                }
            },
            error => {
                setIsLoading(false);
                message.error("Ошибка при попытке выполнить вход в систему.");
                console.log(error);
                showmodal(null);
            }
        );

        //console.log('Success:', values);
    };
    onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    public render(){

        const layout = {
          labelCol: { span: 8 },
          wrapperCol: { span: 16 },
        };
        const tailLayout = {
          wrapperCol: { offset: 8, span: 16 }
        };

        return (
            <Form
                {...layout}
                name="basic"
                initialValues={{
                    remember: true,
                }}
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
            >
                <Form.Item
                    label="Логин"
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: 'Не указан пользователь!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Пароль"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Введите пароль!',
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>


                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">
                        Вход
                    </Button>
                </Form.Item>
            </Form>
        );
    }
};




