import * as React from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import ReactDOM from 'react-dom'

import * as UserProfile from '../../store/UserProfile';
import * as MapDataStore from '../../store/MapDataStore';
import * as PageStore from '../../store/Page';
import LayerView from './LayerView'
import BaseMap from './BaseMap'

import styles from '../../resources/Map.module.less';
import { ApplicationState } from '../../store';
import PopupSettings from "./PopupSettings";
import { Button, Col, Dropdown, Menu, Popover, Select, Space, Tag } from "antd";

import * as Const from '../../utils/Const';
import {netiExecute, filterDictionary} from '../../utils/Common';
import DataLayersPanel from "./DataLayersPanel";
import MarkerHelper from "./MarkerHelper";
import { PeriodsEnum, CommunicationMissedTimesEnum, SPointsFilter } from "../../decl";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import DataUpdater from "./DataUpdater";
import { MapConsumer, Marker, useMap } from "react-leaflet";
import { ThreeSixtyOutlined } from "@material-ui/icons";
import { relative } from "path";
import { bounds, LeafletMouseEvent } from "leaflet";

const { Option } = Select;
// указываем путь к файлам marker
//L.Icon.Default.imagePath = "https://unpkg.com/leaflet@1.5.0/dist/images/";

type Props = {
    baseLayer: string | undefined,
    changeBaseLayer: any,
    changeCoords: any,
    latitude: number | null,
    longitude: number | null,
    disabled?: boolean,
}

type State = {
    center: [number, number],
}

class SimpleMap extends React.Component<Props, State> {
    componentRef: any;
    constructor(props: Props, state: State) {
        super(props);
        this.componentRef = React.createRef();
        this.state = { center: [props.latitude ?? 55.744, props.longitude ?? 37.629] };
    }

    componentDidMount() {
        //console.log('componentDidMount');
        this.initCenter();
    }

    componentDidUpdate(prevProps: Props) {
        //console.log('componentDidUpdate prev', prevProps);
        if (prevProps.latitude !== this.props.latitude || prevProps.longitude !== this.props.longitude) {
            this.initCenter();
        }
    }

    initCenter = () => {
        //console.log('initCenter', this.props);
        if (this.props.latitude != null && this.props.longitude != null) {
            this.setState({center: [this.props.latitude, this.props.longitude]});
        }
    }

    mapDblClick = (e: LeafletMouseEvent) => {
        if (!(this.props.disabled === true))
        {
            const { lat, lng } = e.latlng;
            this.props.changeCoords([lat, lng]);
        }
    }

    render() {
        return (
            <BaseMap zoom={8} center={this.state.center} map={this.props.baseLayer} doubleClickZoom={false} ref={this.componentRef}>
                <div style={{zIndex: 899, position: 'relative', margin: '10px', display: 'flex', justifyContent: 'flex-end'}}>
                    <Select 
                        style={{ width: 150 }} 
                        value={this.props.baseLayer ?? BaseMap.aviableMaps[0].code}
                        onChange={(val: string) => this.props.changeBaseLayer(val)}
                        disabled={this.props.disabled === true}
                    >
                        {BaseMap.aviableMaps.map(item => <Option key={item.code} value={item.code}>{item.name}</Option>)}
                    </Select>
                </div>

                <div style={{display: 'none'}}>
                    {MarkerHelper.getAllImagesUrls().map(item => <img key={item} src={item} />)}
                </div>

                {   
                    this.props.latitude != undefined && this.props.longitude != undefined ?
                    <Marker 
                        icon={MarkerHelper.getMarker('gray')}
                        position={[this.props.latitude, this.props.longitude]}
                        key={'1'}
                    /> : null
                }

                <MapConsumer>
                    {(map) => {
                        map.on("dblclick", this.mapDblClick);
                        return null;
                    }}
                </MapConsumer>
            </BaseMap>
            
        );

    }
};

export default SimpleMap;