import * as React from 'react';

import { Button, Modal, Table, Space, Checkbox } from 'antd';
import moment from 'moment';

import { MODAL_WRAP_CLASSNAME } from '../../utils/Const';

type Props = {
    onHide(): void,
    table: string[][] | null,
    time: Date | undefined,
    zIndex: number
}

type State = {
    
}

class ProtokolDetailedArchive extends React.Component<Props, State> {
    constructor(props: Props, state: State) {
        super(props);
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (this.props.table != null && prevProps.table !== this.props.table) {
            this.itemsDataSource = this.props.table.map(x => ({
                с0: x[0] ?? '',
                с1: x[1] ?? '',
                с2: x[2] ?? '',
                с3: x[3] ?? '',
                с4: x[4] ?? '',
                с5: x[5] ?? '',
            }))
        }
    }

    private columns: any[] = [
        {
            dataIndex: 'с0',
            title: 'N п/п',
            align: 'center',
        },
        {
            dataIndex: 'с1',
            title: 'Переменная',
        },
        {
            dataIndex: 'с2',
            title: 'Ед. изм.',
        },
        {
            dataIndex: 'с3',
            title: 'Наличие',
            align: 'center',
            render: (val: any) => <Checkbox checked={val === 'True'} />
        },
        {
            dataIndex: 'с4',
            title: 'Статистика',
        },
        {
            dataIndex: 'с5',
            title: 'Кол-во',
        },
    ];
    private itemsDataSource: any = undefined;

    //=============================================================================
    render() {
        return (
            <Modal
                maskClosable={false}
                bodyStyle={{paddingTop: '10px', height: '400px !important', overflow: 'auto'}}
                title={'Детализация архивных данных' + (this.props.time ? ' за ' + moment(this.props.time).format('DD.MM.YYYY HH:mm:ss') : '')}
                wrapClassName={MODAL_WRAP_CLASSNAME}
                zIndex={this.props.zIndex}
                open={this.props.table != null}
                footer={[
                    <div key={1} style={{ display: 'flex', alignItems: 'baseline' }}>
                        <Button key="1" type='primary' onClick={() => this.props.onHide()} style={{marginLeft: 'Auto'}}>Закрыть</Button>
                    </div>
                ]}
                onCancel={() => { this.props.onHide() }}
                width={'fit-content'}
                style={{ top: 40 }}
            >
                {
                    //this.state.queryItemTypes === undefined ? <LoadingOutlined/> :
                    <>  
                        <Space style={{paddingBottom: '10px'}} direction='vertical'>
                            <div style={{overflow: 'auto', maxHeight: '500px', width: '1000px'}}>
                                <Table
                                    bordered={true}
                                    size={'small'}
                                    rowKey="id"
                                    columns={this.columns}
                                    dataSource={this.itemsDataSource}
                                    pagination={false}
                                />
                            </div>
                        </Space>
                    </>
                }
            </Modal>
        );
    }
}

export default ProtokolDetailedArchive;