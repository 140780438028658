import * as React from 'react';

import styles from '../../resources/Page.module.less';
import { Modal, Select, Input, Row, Col, DatePicker, Space } from 'antd';

import { deepCopyObj, getModalZIndex, message, toDate } from '../../utils/Common';
import { MODAL_WRAP_CLASSNAME } from '../../utils/Const';
import { SBilBillDTO, SBilBillCompositionDTO } from './BillingDecls';
import moment from 'moment';
import * as Const from '../../utils/Const';
import { sendRequestToBackend } from '../../utils/AuthUtils';
import { debug } from 'console';

const { Option } = Select;

type Props = {
    onHide() : void,
    bill: SBilBillDTO | undefined,
    billComposition: SBilBillCompositionDTO | null,
    onWrite(bill: SBilBillDTO, composition: SBilBillCompositionDTO, allPointsFlag: boolean) : void,
    billStatuses: {[id: number] : string},

    pointsCount: number,
    pointNumber: string | undefined,
    contractNumber: string | undefined,
    setIsLoading: any,
}

type State = {
    bill: SBilBillDTO | undefined,
    billComposition: SBilBillCompositionDTO | undefined,
    zIndex: number,
}

export default class BillEditForm extends React.Component<Props, State> {
    constructor(props: Props, state: State) {
        super(props);

        this.state = {
            bill: undefined,
            billComposition: undefined,
            zIndex: 0,
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.bill == null && this.props.bill != null) {
            let bl = deepCopyObj(this.props.bill);
            let cp = deepCopyObj(this.props.billComposition);
            this.setState({bill: bl, billComposition: cp});
        }
    }

    onWrite = (bill: SBilBillDTO, composition: SBilBillCompositionDTO, allPointsFlag: boolean) : void => {
        if (bill.billStatusId === -1) {
            message.error("Необходимо выбрать статус");
            return;
        }

        this.props.onWrite(bill, composition, allPointsFlag);
    }

    onOk = () => {
        if (this.state?.bill != undefined) {
            const bill: SBilBillDTO = deepCopyObj(this.state.bill);
            bill.billDate = toDate(bill.billDate);
            const billComposition: SBilBillCompositionDTO = deepCopyObj(this.state.billComposition);
            billComposition.beginDate = toDate(billComposition.beginDate);
            billComposition.endDate = toDate(billComposition.endDate);

            if (bill.billId < 0) {
                if (this.props.pointsCount > 1) {
                    Modal.confirm({
                        title: 'Создание счета',
                        wrapClassName: Const.MODAL_WRAP_CLASSNAME,
                        zIndex: getModalZIndex(),
                        content: 'Вы хотите добавить оплаты по счету для всех точек учета из договора ' + this.props.contractNumber,
                        width: 500,
                        okText: 'Да',
                        cancelText: 'Нет',
                        onOk: () => {
                            if (bill != undefined && billComposition != undefined) {
                                this.onWrite(bill, billComposition, true);
                            }
                        },
                        onCancel: () => {
                            if (bill != undefined && billComposition != undefined) {
                                this.onWrite(bill, billComposition, false);
                            }
                        }
                    });
                } else {
                    if (bill != undefined && billComposition != undefined) {
                        this.onWrite(bill, billComposition, false);
                    }
                }
            } else {
                if (this.props.pointsCount > 1) {
                    sendRequestToBackend(
                        {billId: this.props.bill?.billId, beginDate: this.props.billComposition?.beginDate, endDate: this.props.billComposition?.endDate},
                        'billing/findbillcompositionscount',
                        (response: any) => {
                            if (response != null) {
                                if (response > 1)
                                {
                                    Modal.confirm({
                                        title: 'Редактирование счета',
                                        wrapClassName: Const.MODAL_WRAP_CLASSNAME,
                                        zIndex: getModalZIndex(),
                                        content: <>Вы хотите применить изменения для всех подходящих точек учета из договора {this.props.contractNumber} <br />Всего найдено ТУ {response}</>,
                                        width: 500,
                                        okText: 'Да',
                                        cancelText: 'Нет',
                                        onOk: () => {
                                            if (bill != undefined && billComposition != undefined) {
                                                this.onWrite(bill, billComposition, true);
                                            }
                                        },
                                        onCancel: () => {
                                            if (bill != undefined && billComposition != undefined) {
                                                this.onWrite(bill, billComposition, false);
                                            }
                                        }
                                    });
                                } else {
                                    if (bill != undefined && billComposition != undefined) {
                                        this.onWrite(bill, billComposition, false);
                                    }
                                }
                            } else {
                                message.error('Запрос завершился неудачей');
                            }
                        },
                        this.props.setIsLoading,
                        (error: any) => {
                            console.log(error);
                            message.error(error);
                        }
                    );
                } else {
                    if (bill != undefined && billComposition != undefined) {
                        this.onWrite(bill, billComposition, false);
                    }
                }
            }
        }


    };

    private onChangeBill = (field: keyof SBilBillDTO, val: any) => {
        if (this.state.bill != undefined) {
            let res = {...this.state.bill, [field]: val};
            this.setState({bill: res});
        }
    }

    private onChangeComp = (field: keyof SBilBillCompositionDTO, val: any) => {
        if (this.state.billComposition != undefined) {
            let res = {...this.state.billComposition, [field]: val};
            this.setState({billComposition: res});
        }
    }

    render() {
        return (
            <Modal
                title={ this.props !== undefined ? "Редактирование " + "" : 'Добавление' }
                centered
                wrapClassName={MODAL_WRAP_CLASSNAME}
                width={380}
                //zIndex={this.props.zIndex}
                open={this.props.billComposition != null}
                // footer={[
                //     <div style={{ display: 'flex', alignItems: 'baseline' }}>
                //         <Button key="1" type='primary' onClick={this.props.onHide} style={{marginLeft: 'Auto'}}>Отмена</Button>
                //         <Button key="2" type='primary' onClick={this.onOk}>{this.props.operation === undefined ? "Добавить" : "Изменить"}</Button>
                //     </div>
                // ]}
                onCancel={() => { this.props.onHide() }}
                onOk={this.onOk}
            >
                <div className={styles.FormWrapper}>
                    <div style={{ overflow: 'auto', height: '350px', display: 'flex', flexDirection: 'column' }}>
                        <Space direction='vertical'>
                            <span>Договор {this.props.contractNumber} счет для {this.props.pointsCount} ТУ.</span>
                            <Input.Group style={{border: '1px gray solid', padding: '4px'}}>
                                <Row key={'k2'} className={styles.FormRowWrapper}>
                                    <Col key='c1' span={24}>
                                        <Space>Счет №<Input value={this.state.bill?.billNumber} onChange={val => this.onChangeBill('billNumber', val.target.value)} style={{width: 90}} size='small' />от<DatePicker format={['DD.MM.YYYY', 'DDMMYYYY']} value={moment(this.state.bill?.billDate)} onChange={val => this.onChangeBill('billDate', val?.toDate())} size='small' /></Space>
                                    </Col>
                                </Row>
                                <Row className={styles.FormRowWrapper}>
                                    <Col span={12}>
                                        Сумма
                                    </Col>
                                    <Col span={12}>
                                        <Input value={this.state.bill?.billAmmount} onChange={val => this.onChangeBill('billAmmount', val.target.value)} size='small' />
                                    </Col>
                                </Row>
                                <Row className={styles.FormRowWrapper}>
                                    <Col span={12}>
                                        Описание
                                    </Col>
                                    <Col span={12}>
                                        <Input value={this.state.bill?.billDescription} onChange={val => this.onChangeBill('billDescription', val.target.value)} size='small' />
                                    </Col>
                                </Row>
                                <Row className={styles.FormRowWrapper}>
                                    <Col span={12}>
                                        Дата оплаты
                                    </Col>
                                    <Col span={12}>
                                        <DatePicker format={['DD.MM.YYYY', 'DDMMYYYY']} value={this.state.bill?.billPaymentDate != null ? moment(this.state.bill?.billPaymentDate) : undefined} onChange={val => this.onChangeBill('billPaymentDate', val?.toDate())} size='small' />
                                    </Col>
                                </Row>
                                <Row className={styles.FormRowWrapper}>
                                    <Col span={12}>
                                        Статус
                                    </Col>
                                    <Col span={12}>
                                        <Select value={this.state.bill?.billStatusId} onChange={(value) => this.onChangeBill('billStatusId', value)} size="small" className={styles.Width100}>
                                            <>
                                                <Option key={-1} value={-1}> </Option>
                                                {
                                                    Object.keys(this.props.billStatuses).map(
                                                        k => <Option key={k} value={Number(k)}>{this.props.billStatuses?.[Number(k)]}</Option>
                                                    )
                                                }
                                            </>
                                        </Select>
                                    </Col>
                                </Row>
                            </Input.Group>
                            <span>Оплаченные услуги для ТУ {this.props.pointNumber}</span>
                            <Input.Group style={{border: '1px gray solid', padding: '4px'}}>
                                <Row key={'k2'} className={styles.FormRowWrapper}>
                                    <Col key='c1' span={12}>
                                        Нач. дата.
                                    </Col>
                                    <Col span={12}>
                                        <DatePicker format={['DD.MM.YYYY', 'DDMMYYYY']} value={moment(this.state.billComposition?.beginDate)} onChange={val => this.onChangeComp('beginDate', val?.toDate())} size='small' />
                                    </Col>
                                </Row>
                                <Row className={styles.FormRowWrapper}>
                                    <Col span={12}>
                                        Кон. дата.
                                    </Col>
                                    <Col span={12}>
                                        <DatePicker format={['DD.MM.YYYY', 'DDMMYYYY']} value={moment(this.state.billComposition?.endDate)} onChange={val => this.onChangeComp('endDate', val?.toDate())} size='small' />
                                    </Col>
                                </Row>
                            </Input.Group>
                        </Space>
                    </div>
                </div>

            </Modal>
        );
    }
}