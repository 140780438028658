import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';

import NetiContainer from '../components/NetiContainer'
import { ApplicationState } from '../store';
import * as PageStore from '../store/Page';
import * as TasksStore from '../store/Tasks';
import * as KadrStore from '../store/Kadr';
import * as UserProfileStore from '../store/UserProfile';
import { BaseProfile, PeriodsEnum, RequestContext, ICommand } from '../decl';
import * as Const from '../utils/Const';
import { showColumnsSelector } from '../components/Page/ColumnsSelector';
import { netiExecute, isActiveTasksChanged } from '../utils/Common';

const actionCreators = { ...PageStore.actionCreators, ...UserProfileStore.actionCreators, ...KadrStore.actionCreators, ...TasksStore.actionCreators };

type KadrProps =
    { page: PageStore.PageState, tasks: TasksStore.TasksState, userProfile: UserProfileStore.UserProfileState, kadr: KadrStore.IKadrState } &
    typeof actionCreators &
    RouteComponentProps<{}>

interface IKadrState {
    timerId: any;
    timerPeriodSec: number | undefined;
}


class Kadr extends React.PureComponent<KadrProps, IKadrState> {
    constructor(props: KadrProps) {
        super(props);
        this.onGridReset = this.onGridReset.bind(this);
        this.onGridCommand = this.onGridCommand.bind(this);
        this.onExternalCommand = this.onExternalCommand.bind(this);

        this.state = {
            timerId: undefined,
            timerPeriodSec: undefined
        }
    }

    componentDidMount() {
        window.call_page_mount('/kadr');
    }
    componentDidUpdate(prevProps: KadrProps) {
        let timerPeriodSec: number | undefined;
        if (this.props.userProfile.profile.Common?.ArchivePeriod === PeriodsEnum.current)
            timerPeriodSec = this.props.userProfile.profile.Kadr?.CurrentRefreshPeriod;
        if (this.props.userProfile.profile.Common?.ArchivePeriod === PeriodsEnum.hour)
            timerPeriodSec = this.props.userProfile.profile.Kadr?.HourRefreshPeriod;
        if (this.props.userProfile.profile.Common?.ArchivePeriod === PeriodsEnum.day)
            timerPeriodSec = this.props.userProfile.profile.Kadr?.DayRefreshPeriod;

        const autoRefresh = this.props.userProfile.profile.Kadr?.AutoRefresh;
        const prevAutoRefresh = prevProps.userProfile.profile.Kadr?.AutoRefresh;

        if(timerPeriodSec !== this.state.timerPeriodSec || autoRefresh !== prevAutoRefresh)
        {
            if (autoRefresh === true && this.state.timerId === undefined) {
                this.startTimer(timerPeriodSec ?? 60);
            }
            else if (autoRefresh === false && this.state.timerId !== undefined) {
                this.stopTimer();
            }
            else if (this.state.timerPeriodSec !== timerPeriodSec) {
                this.restartTimer(timerPeriodSec);
            }
        }
        if (isActiveTasksChanged(this.props.tasks.activeTasks, prevProps.tasks.activeTasks)) {
            this.invalidateGrid();
        } 
    }

    componentWillUnmount() {
        this.stopTimer();
        window.call_page_unmount('/kadr');
    }

    restartTimer = (newVal: number | undefined) => {
        if (this.state.timerId !== undefined && newVal !== undefined) {
            clearInterval(this.state.timerId!);
            let id = setInterval(this.timerTick, newVal * 1000);
            this.setState({timerId: id, timerPeriodSec: newVal});
        }
    }

    startTimer = (val: number) => {
        if (this.state.timerId === undefined) {
            let id = setInterval(this.timerTick, val * 1000);
            this.setState({timerId: id, timerPeriodSec: val});
        }
    }

    stopTimer = () => {
        if (this.state.timerId !== undefined) {
            clearInterval(this.state.timerId!);
            this.setState({timerId: undefined})
        }
    }

    timerTick = () => {
            this.props.setNeedRefresh(true);
    }

    getNormalDataDepth(iPeriod: PeriodsEnum){
        let result: number = 0;
        switch(iPeriod){
            case PeriodsEnum.current:
                result = (this.props?.userProfile?.profile.Kadr?.CurrentNormalDataDepth) ?? 0;
                break;
            case PeriodsEnum.hour:
                result = (this.props?.userProfile?.profile.Kadr?.HourNormalDataDepth) ?? 0;
                break;
            case PeriodsEnum.day:
                result = (this.props?.userProfile?.profile.Kadr?.DayNormalDataDepth) ?? 0;
                break;
        }
        return result;
    }

    getDataDepth = (iPeriod: PeriodsEnum) => {
        let result: number = 0;
        switch (iPeriod) {
            case PeriodsEnum.current:
                result = (this.props?.userProfile?.profile.Kadr?.CurrentDataDepth) ?? 0;
                break;
            case PeriodsEnum.hour:
                result = (this.props?.userProfile?.profile.Kadr?.HourDataDepth) ?? 0;
                break;
            case PeriodsEnum.day:
                result = (this.props?.userProfile?.profile.Kadr?.DayDataDepth) ?? 0;
                break;
        }
        return result;
    }

    getColumnsLayout(iPeriod: PeriodsEnum){
        let result: string = '';
        switch(iPeriod){
            case PeriodsEnum.current:
                result = (this.props?.userProfile?.profile.Kadr?.CurrentColumnsLayout) ?? '';
                break;
            case PeriodsEnum.hour:
                result = (this.props?.userProfile?.profile.Kadr?.HourColumnsLayout) ?? '';
                break;
            case PeriodsEnum.day:
                result = (this.props?.userProfile?.profile.Kadr?.DayColumnsLayout) ?? '';
                break;
        }
        return result;
    }


    onGridReset(control: any){
        if(control != null){
            const period = (this.props?.userProfile?.profile.Common?.ArchivePeriod) ?? 0;
            const normalDataDepth = this.getNormalDataDepth(period);
            const dataDepth = this.getDataDepth(period);
            const columnsLayout = this.getColumnsLayout(period);
            const filter = {
                __type: 'SKadrFilterSC:#SCTeploinformSite3',
                Groups: (this.props?.userProfile?.profile?.Common?.Groups) ?? null,
                Points: this.props?.userProfile?.profile?.Common?.Points,
                Resources: (this.props?.userProfile?.profile?.Common?.Resources) ?? null,
                SystemNumbers: null,
                Text: this.props.kadr.textFilter,
                ArchivePeriod: period,
                NormalDataDepth: normalDataDepth,
                DataDepth: dataDepth,
                ColumnsLayout: columnsLayout
            };
            this.props.setNeedRefresh(false);
            this.props.setIsLoading(true);
            control.proxydata_reset(100019, filter);
        }
    }

    onGridCommand(cmd: any) {
        if (cmd.Name === Const.DATA_RESET_DONE_COMMAND){
            this.props.setIsLoading(false, 100);
            if(cmd.Params !== 4){
                const ids: string[] = cmd.Source.ids_get("check", true);
                this.props.setPoints(ids);
                const pointsCount = cmd.Source.getDataLength();
                this.props.setSelectedPointsCount(pointsCount);
            }
            else if (cmd.Params === 4){
                this.props.closeSession("Сессия закрыта.");
            }
        }
        else if(cmd.Name === Const.ON_COLUMNS_REORDER_COMMAND){
            const newCmd = {...cmd, Name: Const.ON_KADRCOLUMNS_REORDER_COMMAND};
            netiExecute(newCmd, this.props.userProfile, this.props.changeProfile, this.props.setIsLoading, this.props.setActiveQueries, null, this.props.showModal);
        }
        else if (cmd.Name === Const.ON_COLUMNS_SELECT_COMMAND) {
            showColumnsSelector(cmd.Params.columns, cmd.Params.type === 2, 7, this.props.showModal, cmd.Callback);
        }
        else{
            if(cmd.Name === Const.POINT_SELECT_COMMAND){
                this.props.setPoint(cmd.Params.id, cmd.Params.number);
            }
            window.call_execute(cmd);
        }
    }

    //Команда с установленным источником - главный Neti контрол (sc_grid).
    onExternalCommand(cmd: ICommand) {
        this.props.setCommandToExecute(null);
        if (cmd.Name === Const.INVALIDATE_COMMAND) {
            cmd.Callback(cmd);
        }
        else {
            window.call_execute(cmd);
        }
    }
    invalidateGrid = () => {
        const cmd: ICommand = {
            Source: null,
            Name: Const.INVALIDATE_COMMAND,
            Params: null,
            Callback: null,
            ExceptionCallback: null
        };
        this.props.setCommandToExecute(cmd);
    }

    render() {
        const controlId: number = 409;//90;
        const period = (this.props?.userProfile?.profile.Common?.ArchivePeriod) ?? 0;
        const columnsLayout = this.getColumnsLayout(period);        
        const context: RequestContext = {LocalPath: '/kadr', Properties: [{Key: 'ColumnsLayout', Value: columnsLayout}]};
        return <NetiContainer
            controlId={controlId}
            needRefresh={this.props.kadr.needRefresh}
            commandToExecute={this.props.kadr.commandToExecute}
            onReset={this.onGridReset}
            onCommand={this.onGridCommand}
            onExternalCommand={this.onExternalCommand}
            context={context}
            userProfile={this.props.userProfile}
            setProfile={this.props.changeProfile}
            setIsLoading = {this.props.setIsLoading}
            setActiveQueries = {this.props.setActiveQueries}
            isNetiReady={this.props.page.isNetiReady}>
        </NetiContainer>
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            page: state.page,
            tasks: state.tasks,
            userProfile: state.userProfile,
            kadr: state.kadr
        }
    },
    actionCreators
)(Kadr as any);
