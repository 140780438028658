import * as React from 'react';
import { Input, Col, Row, Space, Tree, Button, Modal } from 'antd';

import {message, netiExecute } from '../../utils/Common';
import * as Const from '../../utils/Const';
import TextArea from 'antd/lib/input/TextArea';
import { sendRequestToBackend } from '../../utils/AuthUtils';

export interface Props {
    visible: boolean,
    zIndex: number,
    address: string,
    onHide() : void,
    showPointInfo: any,
}

interface State {
    pingStats: PingStatictics | undefined,
    start: boolean,
    text: string,
}

type PingStatictics = {
    sendCount: number,
    lossCount: number,
    minAnswerTime: number,
    maxAnswerTime: number,
    avgAnswerTime: number,
}

//Дерево точек учёта.
export class PingForm extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            pingStats: undefined,
            start: true,
            text: '',
        }

    }

    componentDidMount() {
        if (this.state?.start === true && this.props.visible === true) {
            this.init();
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps.visible === false && this.props.visible === true) {
            this.init();
        }

        if (prevState != null && prevState.start === true && this.state.start === false) {

        }
    }

    init = () => {
        this.setState({
            start: true,
            text: `Обмен пакетами с ${this.props.address}`,
            pingStats: {
                avgAnswerTime: 0,
                lossCount: 0,
                maxAnswerTime: 0,
                minAnswerTime: 0,
                sendCount: 0,
            }}
        );
        this.ping();
    }

    //Сделано без таймера, как отрабатывает один запрос посылается следующий
    ping = () => {
        setTimeout(() => {//Задержка между запросами
            sendRequestToBackend(
                this.props.address,
                'admin/ping',
                (response: any) => {
                    if (this.props.visible !== false) {
                        if (this.state?.pingStats != undefined) {
                            let stats = {...this.state.pingStats};
                            stats.sendCount += 1;
                            if (response.successfully)
                            {
                                stats.minAnswerTime = stats.minAnswerTime > 0 ? stats.minAnswerTime : response.delay;

                                stats.avgAnswerTime += response.delay;
                                stats.minAnswerTime = stats.minAnswerTime > response.delay ? response.delay : stats.minAnswerTime;
                                stats.maxAnswerTime = stats.maxAnswerTime < response.delay ? response.delay : stats.maxAnswerTime;
                            }
                            else
                            {
                                stats.lossCount += 1;
                            }
                            this.setState({text: response.result + this.state.text, pingStats: stats});
                        }
                        
                        if (this.state?.start === false) {
                            if (this.state?.pingStats != undefined) {
                                let lossPerc = 100;
                                let result = "";
                                if ((this.state.pingStats.sendCount - this.state.pingStats.lossCount) > 0)
                                {
                                    this.state.pingStats.avgAnswerTime = this.state.pingStats.avgAnswerTime / (this.state.pingStats.sendCount - this.state.pingStats.lossCount);
                                    lossPerc = this.state.pingStats.lossCount / this.state.pingStats.sendCount * 100;
                                }

                                result = `\r\nСтатистика Ping для ${this.props.address}:\r\n    Пакетов: Отправлено = ${this.state.pingStats.sendCount}, Получено = ${this.state.pingStats.sendCount - this.state.pingStats.lossCount}, Потеряно = ${this.state.pingStats.lossCount}\r\n    (${lossPerc}% потерь)\r\n`;
                                result += `Приблизительное время приема-передачи в мс:\r\n    Минимальное = ${this.state.pingStats.minAnswerTime} мсек, Максимальное = ${this.state.pingStats.maxAnswerTime} мсек, Среднее = ${this.state.pingStats.avgAnswerTime.toFixed(2)} мсек\r\n\r\n`;
                                this.setState({text: result + this.state.text});
                            }

                            this.setState({pingStats: undefined});
                        } else {
                            this.ping();
                        }
                    }
                },
                null,
                (error: any) => {
                    console.log(error);
                    message.error(error);
                }
            );
        }, 1000);
    }

    onStartClick = () => {
        if (this.state?.start === true) {
            this.setState({start: false});
        } else {
            this.init();
        }
    }

    render() {
        return <Modal
            bodyStyle={{padding: '5px'}}
            title= {"Пингование"}
            open={this.props.visible}
            //onOk={this.onOk}
            onCancel={this.props.onHide}
            wrapClassName ={Const.MODAL_WRAP_CLASSNAME}
            zIndex={this.props.zIndex}
            footer={[
                <div key={1} style={{ display: 'flex', alignItems: 'baseline' }}>
                    <Button key="info" type='primary' style={{marginLeft: 'Auto'}} onClick={this.props.showPointInfo}>Информация</Button>
                    <Button key="cancel" type='primary' 
                        onClick={this.onStartClick} 
                        disabled={this.state?.start === false && this.state?.pingStats !== undefined}//Пинг остановлен но последний запрос еще не пришел
                    >
                        {this.state?.start === true ? "Остановить" : "Запустить"}
                    </Button>
                </div>
            ]}
        >
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                <TextArea rows={12} readOnly={true} value={this.state.text}  />
            </Space>
        </Modal>
    }
}
