import * as React from 'react';
import styles from '../resources/Page.module.less';

type Weather = {
	icon: any,
	country: string,
	city: string,
	localtime: any,
	timezone: any,
	temperature: number,
	visibility: number,
	wind_direction: any,
	wind_speed: number,
	t_localtime: any,
	t_temperature: any,
	t_visibility: any,
	t_wind_direction: any,
	t_wind_speed: any
}


interface WeatherProps {
	town?: string | null,
	latitude?: string,
	longitude?: string,
	shortInfo: boolean
}

interface WeatherState {
	weather: Weather | null;
}

export default class WeatherInformer extends React.PureComponent<WeatherProps, WeatherState>{
	_timer: any;
	_isMounted: boolean;
	constructor(props: WeatherProps) {
		super(props);

		this.state = {
			weather: null
		};
		this.getWeather = this.getWeather.bind(this);
		this._isMounted = false;
	}

	componentDidMount() {
		this._isMounted = true;
		this.getWeather();
		this._timer = setInterval(this.getWeather, 300000);
	}
	ccomponentWillUnmount() {
		this._isMounted = false;
		if (this._timer) {
			clearInterval(this._timer);
		}
	}

	getWeather = async () => {
		if (this._isMounted) {
			const api_key = "b21eb936caf94517b8a83105212307";// "4d9205b539df433eaed72552212304";
			let api_city = null;
			if (this.props.town) {
				api_city = this.props.town;
			}
			else if (this.props.latitude && this.props.longitude) {
				api_city = this.props.latitude + ',' + this.props.longitude;
			}
			if (api_city) {
				const _this = this;
				const api_data = await fetch(
					`https://api.weatherapi.com/v1/forecast.json?key=${api_key}&q=${api_city}&days=2&aqi=no&alerts=no&lang=ru`
				);
				if (api_data.status === 400) {
					console.log("error 400 при получении погоды");
				} else {
					let api_json;
					if (_this._isMounted) {
						api_json = await api_data.json();
					}
					if (_this._isMounted) {

					const weather: Weather = {
						icon: api_json.current.condition.icon,
						country: api_json.location.country,
						city: api_json.location.name,
						localtime: api_json.location.localtime,
						timezone: api_json.location.tz_id,
						temperature: api_json.current.temp_c,
						visibility: api_json.current.vis_km,
						wind_direction: api_json.current.wind_dir,
						wind_speed: api_json.current.wind_kph,
						t_localtime: api_json.forecast.forecastday[1].date,
						t_temperature: api_json.forecast.forecastday[1].day.avgtemp_c,
						t_visibility: api_json.forecast.forecastday[1].day.avgvis_km,
						t_wind_direction: api_json.forecast.forecastday[1].hour[0].wind_dir,
						t_wind_speed: api_json.forecast.forecastday[1].day.maxwind_kph
					};
						_this.setState({
							weather: weather
						});
					}
				}
			}
		}
	};


	render() {
		let info: string = '';
		if (this.state.weather) {
			if(!this.props.shortInfo){
				info += 'г. Москва';// this.state.weather.city;
				info += " ";
			}
			if (this.state.weather.temperature > 0) info += "+";
			info += this.state.weather.temperature;
			info += "°C";
		}
		const icon: string = this?.state?.weather?.icon;
		return (
			<div className={styles.weatherContainer}>
				<div className={styles.weatherTop}>
					<img src={icon} alt="icon" />
				</div>
				<div className={styles.weatherBottom}>
					{info}
				</div>
			</div>
		);
	}
}