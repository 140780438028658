import * as React from 'react';

import { Upload, Modal } from 'antd';
import type { RcFile, UploadFile } from 'antd/es/upload/interface';

import { getModalZIndex, message } from '../../utils/Common';
import { sendRequestToBackend} from '../../utils/AuthUtils';

import { MODAL_WRAP_CLASSNAME } from '../../utils/Const';

type Props = {
    pictures: SPictureDTO[],
    onAdd: any,
    onRemove: any,
    disabled?: boolean,
}

type State = {
    previewOpen: boolean,
    previewTitle: string,
    previewImage: string
}

const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });


type SPointPicturesSaveRequest = {
    pointId: string,
    pictures: SPictureDTO[],
    deletePictureIds: number[]
}

export type SPictureDTO = {
    id: number,
    name: string,
    caption: string,
    body: string | undefined
}

export const getPointPictures = (pointId: string, setIsLoading: any, callback: any) => {
    sendRequestToBackend(
        pointId,
        'points/pictures',
        (response: any) => {
            callback(response);
        },
        setIsLoading,
        (error: any) => {
            console.log(error);
        });
}

export const savePointPictures = (pointId: string, pictures: SPictureDTO[], removeIds: number[], setIsLoading: any, callback: (success: boolean) => void) => {

    //Исключение из отправки тела имеющихся в базе картинок.
    const sendPictures: SPictureDTO[] = pictures.map(p => { return { id: p.id, name: p.name, caption: p.caption, body: p.id === 0 ? p.body : undefined } });

    //Запрос на изменение картинок.
    const request: SPointPicturesSaveRequest = { pointId: pointId, pictures: sendPictures, deletePictureIds: removeIds };

    sendRequestToBackend(
        request,
        'points/picturessave',
        (response: any) => {
            if (callback) callback(true);
        },
        setIsLoading,
        (error: any) => {
            console.log(error);
            if (callback) callback(false);
        });
}

export class ImageLoader extends React.Component<Props, State> {
    constructor(props: Props, state: State) {
        super(props);
        this.state = {
            previewOpen: false,
            previewImage: '',
            previewTitle: ''

        }
    }

    picturesGet = (): Array<UploadFile<any>> => {
        //console.log('pictures: ', this.props.pictures);
        return this.props.pictures.map((p: SPictureDTO) => {
            return { uid: p.name, name: p.name, status: 'done', url: p.body } });
    }

    beforeUpload = (file: RcFile) => {
        //console.log('RcFile', file);

        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('Можно загружать только файлы JPG/PNG!');
        }

        const existPicture: SPictureDTO | undefined = this.props.pictures.find(p => p.name === file.name);
        if (existPicture) {
            message.error("Файл с иенем '" + file.name + "' уже есть!");
        }

        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Размер файлов должен быть меньше 2MB!');
        }

        const result = isJpgOrPng && isLt2M && existPicture === undefined;

        if (result) {
            this.onUpload(file);
        }

        return result;
    }

    onUpload = (file: RcFile) => {
        var fileReader = new FileReader();
        fileReader.onloadend = () => {
            var dataUrl = fileReader.result;
            if (dataUrl != null) {
                const picture: SPictureDTO = {id: 0, name: file.name, caption: file.name, body: dataUrl as string };
                this.props.onAdd(picture);
            }
        };

        if (file) {
            fileReader.readAsDataURL(file);
        }

    }

    onRemove = (file: UploadFile) => {
        //console.log('onRemove: ', file);

        const removedPicture = this.props.pictures.find(p => p.name === file.name);
        if (removedPicture) {
            this.props.onRemove(file.name, removedPicture.id);
        }

        return true;
    }

    handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }
        this.setState({
            previewImage: file.url || (file.preview as string),
            previewOpen: true,
            previewTitle: file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1)
        });
    };

    render() {
        return (
        <>
            <Upload
                listType="picture-card"
                fileList={this.picturesGet()}
                onPreview={this.handlePreview}
                beforeUpload={this.beforeUpload}
                onRemove={this.onRemove}
                className={"customSizedUpload"}
                disabled={this.props.disabled ?? false}
            >
                {'+ Добавить'}
            </Upload>
            <Modal
                wrapClassName={MODAL_WRAP_CLASSNAME}
                zIndex={getModalZIndex()}
                open={this.state.previewOpen}
                title={this.state.previewTitle}
                footer={null}
                onCancel={() => this.setState({ previewOpen: false })}
            >
                <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
            </Modal>
        </>
        );
    }
}
