import * as React from 'react';

import "leaflet/dist/leaflet.css";
import styles from '../resources/Map.module.less';
import Map from '../components/Map/Map';

interface IMapsProps {

}
interface IMapsState {

}

const defaultLatLng: L.LatLngTuple = [48.865572, 2.283523];
const zoom:number = 8;

export default class Maps extends React.PureComponent <IMapsProps, IMapsState > {
    constructor(props: IMapsProps) {
        super(props);
    }
    public render() {
        return (
            <div className={styles.container}>
                <Map />
            </div>
        );
    }    
}



