import * as React from 'react';

import { DatePicker } from 'antd';

import 'moment/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';
import moment from 'moment';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { makeTime as makeTime } from '../../utils/Common';

const { RangePicker } = DatePicker;

interface Props {
    value: [Date, Date] | null,
    onChange: (newVal: [Date, Date] | null) => void,
    limitDays?: number,
    allowClear?: boolean,
    style?: React.CSSProperties,
    size?: SizeType,
    className?: string,
    format?: string,
    disabled?: boolean,
}

interface State {
    hackValue: any,
    dates: any,
}

//При открытии даты не отображаются
class RangePickerLimit extends React.PureComponent<Props, State>{
    state = {
        dates: [] as any,
        hackValue: undefined as any,
    }

    disabledDate = (current: any) => {
        if (!this.state.dates || this.state.dates.length === 0) {
            return false;
        }
        const tooLate = this.state.dates[0] && current.diff(this.state.dates[0], 'days') > (this.props.limitDays ?? 1);
        const tooEarly = this.state.dates[1] && this.state.dates[1].diff(current, 'days') > (this.props.limitDays ?? 1);
        return tooEarly || tooLate;
    };

    onOpenChange = (open: any) => {
        if (open) {
            this.setState({
                hackValue: [],
                dates: []
            })
        } else {
            this.setState({
                hackValue: undefined,
                dates: []
            })
        }
    };

    onChange = (val: any) => {
        if (val != null)
        {
            let start = val[0].toDate();
            let end = val[1].toDate();

            if (this.props.value != null)
            {
                start = makeTime(start, this.props.value[0].getHours(), this.props.value[0].getMinutes(), this.props.value[0].getSeconds());
                end = makeTime(end, this.props.value[1].getHours(), this.props.value[1].getMinutes(), this.props.value[1].getSeconds());
            }
            this.props.onChange([start, end]);
        } else {
            this.props.onChange(null);
        }
    }

    render(){
        let val: any = null;
        if (this.props.value != null) {
            val = [
                moment(this.props.value[0]),
                moment(this.props.value[1]),
            ]
        }

        let props: any = {value: val};
        
        if (this.props.limitDays != undefined) {
            props = {
                value: this.state.hackValue || val,
                disabledDate: this.disabledDate,
                onCalendarChange: (val: any) => this.setState({dates: val}),
                onOpenChange: this.onOpenChange,
            }
        }

        return (
            <RangePicker
                {...props}
                locale={locale}
                onChange={this.onChange}
                allowClear={this.props.allowClear}
                style={this.props.style}
                size={this.props.size}
                className={this.props.className}
                ranges={{
                    'Сегодня': [moment(makeTime(new Date(), 0, 0, 0)), moment(makeTime(new Date(), 0, 0, 0))],
                    'Этот месяц': [moment().startOf('month'), moment().endOf('month')],
                    'Прошлый месяц': [moment().startOf('month').add(-1, 'month'), moment().add(-1, 'month').endOf('month')],
                }}
                format={this.props.format ?? 'DD.MM.YYYY'}
                disabled={this.props.disabled ?? false}
            />
        )
    }
}

export default RangePickerLimit
