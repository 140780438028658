import * as React from 'react';
import { connect } from 'react-redux';

import * as UserProfile from '../store/UserProfile';

import * as Const from '../utils/Const';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { InputNumber, Space, Tabs } from 'antd';
import { Table } from 'antd';
import { Checkbox, Button, Modal, Select } from 'antd';

import styles from '../resources/Page.module.less';

import { ApplicationState } from '../store';
import 'moment/locale/ru' 
import * as UserProfileStore from '../store/UserProfile';


const { TabPane } = Tabs;
const { Column } = Table;
const { confirm } = Modal;
const { Option } = Select;

interface AdditionalProps {
    visible: boolean,
    zIndex: number,
    onHide() : void,
}


type Props = {
    userProfile: UserProfile.UserProfileState 
} & typeof UserProfileStore.actionCreators & AdditionalProps;

const actionCreators = { ...UserProfileStore.actionCreators };

type State = {
}

class MnenoschemaSettingsForm extends React.Component<Props, State> {
    constructor(props: Props, state: State) {
        super(props);
    }

    onCellRender1 = (text: any, row: any, index: any) => {
        if (index === 0)
            return <InputNumber size='small' key='k1' value={this.props.userProfile.profile.Mnemoschema?.CurrentRefreshPeriod} onChange={(val) => this.changeMnemoProfile('CurrentRefreshPeriod', val)} />
        if (index === 1)
            return <InputNumber size='small' key='k2' value={this.props.userProfile.profile.Mnemoschema?.HourRefreshPeriod} onChange={(val) => this.changeMnemoProfile('HourRefreshPeriod', val)} />
        if (index === 2)
            return <InputNumber size='small' key='k3' value={this.props.userProfile.profile.Mnemoschema?.DayRefreshPeriod} onChange={(val) => this.changeMnemoProfile('DayRefreshPeriod', val)} />
    }
    columns1 = [{ title: 'Период обновления экрана (сек)', dataIndex: 'descriotion', key: 'descr' }, {title: '', key: 'x', align: 'right' as 'right', render: this.onCellRender1,}];
    data1 = [{key: '1', descriotion: 'Текущие значения (сек)'},{key: '2', descriotion: 'Часовые значения (сек)'},{key: '3', descriotion: 'Суточные значения (сек)'}];
    
    onCellRender2 = (text: any, row: any, index: any) => {
        if (index === 0)
            return <InputNumber size='small' key='k1' value={this.props.userProfile.profile.Mnemoschema?.CurrentDataDepth} onChange={(val) => this.changeMnemoProfile('CurrentDataDepth', val)} />
        if (index === 1)
            return <InputNumber size='small' key='k2' value={this.props.userProfile.profile.Mnemoschema?.HourDataDepth} onChange={(val) => this.changeMnemoProfile('HourDataDepth', val)} />
        if (index === 2)
            return <InputNumber size='small' key='k3' value={this.props.userProfile.profile.Mnemoschema?.DayDataDepth} onChange={(val) => this.changeMnemoProfile('DayDataDepth', val)} />
    }
    columns2 = [{ title: 'Глубина получения данных', dataIndex: 'descriotion', key: 'descr' }, {key: 'x', render: this.onCellRender2, align: 'right' as 'right'}];
    data2 = [{key: '1', descriotion: 'Текущие значения (мин)'},{key: '2', descriotion: 'Часовые значения (час)'},{key: '3', descriotion: 'Суточные значения (сут)'}];
    
    onCellRender3 = (text: any, row: any, index: any) => {
        if (index === 0)
            return <InputNumber size='small' key='k1' value={this.props.userProfile.profile.Mnemoschema?.CurrentNormalDataDepth} onChange={(val) => this.changeMnemoProfile('CurrentNormalDataDepth', val)} />
        if (index === 1)
            return <InputNumber size='small' key='k2' value={this.props.userProfile.profile.Mnemoschema?.HourNormalDataDepth} onChange={(val) => this.changeMnemoProfile('HourNormalDataDepth', val)} />
        if (index === 2)
            return <InputNumber size='small' key='k3' value={this.props.userProfile.profile.Mnemoschema?.DayNormalDataDepth} onChange={(val) => this.changeMnemoProfile('DayNormalDataDepth', val)} />
    }
    columns3 = [{ title: 'Сигнализация о потери связи', dataIndex: 'descriotion', key: 'descr' }, {key: 'x', render: this.onCellRender3, align: 'right' as 'right'}];
    data3 = [{key: '1', descriotion: 'Текущие нормальные значения (мин)'},{key: '2', descriotion: 'Часовые нормальные значения (час)'},{key: '3', descriotion: 'Суточные нормальные значения (сут)'}];

    changeMnemoProfile = (name: string, val: any) => {
        this.props.changeProfileValue(Const.PROFILE_MNEMOSCHEMA, name, val);
    }

    render() {
        return (
            <Modal
                title={'Настройки мнемосхем'}
                wrapClassName={Const.MODAL_WRAP_CLASSNAME}
                zIndex={this.props.zIndex}
                open={this.props.visible}
                onCancel={this.props.onHide}
                style={{ top: '5%' }}
                footer={null}
            >
                <div className={styles.FormWrapper}>
                    <Space direction='vertical' style={{display: 'flex'}}>
                            <Checkbox
                                key='k1'
                                onChange={(e: CheckboxChangeEvent) => this.changeMnemoProfile('AutoRefresh', e.target.checked)}
                                checked={this.props.userProfile.profile.Mnemoschema?.AutoRefresh}
                            >
                                Автоматическое обновление экрана
                            </Checkbox>
                            <Table key='k2' pagination={false} columns={this.columns1} dataSource={this.data1} size="small" />
                            <Table key='k3' pagination={false} columns={this.columns2} dataSource={this.data2} size="small" />
                            <Table key='k4' pagination={false} columns={this.columns3} dataSource={this.data3} size="small" />
                    </Space>
                </div>
            </Modal>
        )
    }

}

export default connect(
    (state: ApplicationState, ownProps: AdditionalProps) => {
        return {
            userProfile: state.userProfile,
            ...ownProps,
        }
    },
    actionCreators
)(MnenoschemaSettingsForm as any);
