import * as React from 'react';

//import styles from '../resources/Page.module.less';
import { Button, Modal, Select, Input, Row, Col, Table, Tag, DatePicker, Tabs, Space, InputNumber, Checkbox, Switch, Divider, Tooltip } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';

import { arrayToString, convertBase64ToBinary, getModalZIndex, netiExecute, message, saveFile } from '../../utils/Common';
import { MODAL_WRAP_CLASSNAME } from '../../utils/Const';
//import { SPointsFilter } from '../decl';

//import Groups from './Groups';
//import { AbonentOperationDTO, AbonentOperationTypeDTO } from '../store/OperationsStore';
//import RangePickerLimit from './RangePickerLimit';

import pageStyles from '../../resources/Page.module.less';
import SParametersEdit from './SParametersEdit';

import yellowIcon from "../../images/indicator_yellow.png";
import redIcon from "../../images/indicator_red.png";
import greyIcon from "../../images/indicator_grey.png";
import greenIcon from "../../images/indicator_green.png";
import brownIcon from "../../images/indicator_brown.png";

import ipIcon from "../../images/conn_ip.png";
import tcpIcon from "../../images/conn_tcp.png";
import idIcon from "../../images/conn_id.png";
import otherIcon from "../../images/conn_other.png";

import * as AdminStore from '../../store/AdminStore';
import { Group, Point, Resource, SParameterValTypes } from '../../decl';
import { DeleteTwoTone, EditTwoTone, ExclamationCircleOutlined, LoadingOutlined, PlayCircleTwoTone, ScheduleTwoTone, StopTwoTone, SyncOutlined } from '@ant-design/icons';
import { IPointDictionaries, PointAllInfoDTO, QueryItemTypes, SQueryItemDTO } from '../../store/AdminStore';
import { validateLocaleAndSetLanguage } from 'typescript';
import modal from 'antd/lib/modal';
import { sendRequestToBackend } from '../../utils/AuthUtils';
import BaseMap from '../Map/BaseMap';
import SimpleMap from '../Map/SimpleMap';
import { DriveEtaOutlined, Message, PartyMode } from '@material-ui/icons';
import * as Const from '../../utils/Const';
import TasksForm from './TasksForm';
import { point } from 'leaflet';
import ReactDOM from 'react-dom';
import { DefaultOptionType } from 'antd/lib/select';
import * as UserProfileStore from '../../store/UserProfile';
import Resources from '../Resources';
import * as Common from '../../utils/Common';
import SimpleTreeSelect from '../Helper/SimpleTreeSelect';
import  { SimpleTreeSelectItem } from '../Helper/SimpleTreeSelect';
import ResizableTable from '../Helper/ResizableTable';

const { Option } = Select;

type Props = {
    onHide(): void,
    setIsLoading: any,
    points: Point[] | null,
    zIndex: number,
    groupName: string | null,
    userProfile: UserProfileStore.UserProfileState,
    changeProfile: any,
    setActiveQueries: any,
    showPointInfo: any,
    showModal: any,
    windowWidth: number,
    setActiveLogTask: (queryItemId: string|undefined, taskId: number)=>void
}

type State = {
    dicts: AdminStore.IPollingDicts | undefined,
    statistics: AdminStore.SAbonentConnectionStatisticDTO[] | undefined,
    start: Date,
    end: Date,
    selectedDay: Date | undefined,
    tasksByAbonent: Point | null,
    taskCommStatus: string,
    taskTime: Date,
    modalZIndex: number,
    filterConnTypes: string[] | null,
    filterResourceIds: string[] | null,
    filterDevTypes: string[] | null,
}

class PollingForm extends React.Component<Props, State> {
    constructor(props: Props, state: State) {
        super(props);
        this.state = {
            dicts: undefined,
            start: moment().set({hour: 0, minute: 0, second: 0, millisecond: 0}).add(-10, 'day').toDate(),
            end: moment().toDate(),
            selectedDay: undefined,
            statistics: undefined,
            tasksByAbonent: null,
            taskTime: new Date(),
            taskCommStatus: '',
            modalZIndex: 0,
            filterConnTypes: null,
            filterResourceIds: null,
            filterDevTypes: null,
        }

    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps.points == null && this.props.points != null) {
            sendRequestToBackend(
                null,
                'admin/pollingdictionaries',
                (response: any) => {
                    if (response != null) {
                        this.setState({dicts: response});
                        this.connTypes = Object.keys(this.state.dicts?.connectionTypes ?? '').map(x => ({
                            id: x, name: this.state.dicts?.connectionTypes[Number(x)] ?? '', shortName: this.state.dicts?.connectionTypes[Number(x)] ?? ''
                        }));
                        this.devTypes = Object.keys(this.state.dicts?.deviceTypes ?? '').map(x => ({
                            id: x, name: this.state.dicts?.deviceTypes[Number(x)] ?? '', shortName: this.state.dicts?.deviceTypes[Number(x)] ?? ''
                        }));
                        
                    } else {
                        message.warning("Не удалось получить словари");
                    }
                },
                this.props.setIsLoading,
                (error: any) => {
                    message.warning("Ошибка при получении словарей");
                    console.log(error);
                    message.error(error);
                }
            );

            this.requestPolling();
        }

        if (prevState.start !== this.state.start || prevState.end !== this.state.end) {
            this.requestPolling();
        }
    }

    applyFilter = (connTypeIds: string[] | null, resourcesIds: string[] | null, devTypesIds: string[] | null) => {
        if (this.itemsDataSource != undefined) {
            let res = [...this.itemsDataSource];
            if (connTypeIds != null) {
                res = res.filter((x: any) => connTypeIds.includes(String(x.conntype)));
            }

            if (resourcesIds != null) {
                res = res.filter((x: any) => resourcesIds.includes(String(x.resource)));
            }

            if (devTypesIds != null) {
                res = res.filter((x: any) => devTypesIds.includes(String(x.devtype)));
            }
            //console.log('applyFilter', res, devTypesIds);
            this.itemsDataSourceFiltered = res;
        }
    }

    requestPolling = () => {
        sendRequestToBackend(
            {pointIds: this.props.points?.map(x => x.id) ?? [], start: this.state.start, end: this.state.end},
            'admin/abonentconnectionstatistics',
            (response: any) => {
                if (response != null && this.state.dicts != null) {
                    this.initTable(response);
                }
            },
            this.props.setIsLoading,
            (error: any) => {
                message.warning("Ошибка");
                console.log(error);
                message.error(error);
            }
        );
    }

    sorter = (a: any, b: any) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b);

    selectIcon = (val: any): string => {
        let res: string;
        if (val === 0) res = greyIcon
        else if (val === 1) res = redIcon
        else if (val === 6) res = greenIcon
        else res = brownIcon 

        return res;
    }

    selectConnIcon = (val: any): string => {
        let res: string;
        if (val === 23 || val === 24 || val === 26) res = tcpIcon
        else if (val === 16) res = ipIcon
        else if (val === 8) res = idIcon
        else res = otherIcon

        return res;
    }

    execute = (cmd: any) => {
        netiExecute(cmd, this.props.userProfile, this.props.changeProfile, this.props.setIsLoading, this.props.setActiveQueries, null, this.props.showModal);
    }

    showPointInfo = (pointId: string, pointNumber: string) => {
        const source: any = new (window.NetiSource as any)(this.execute);
        const parent: any = ReactDOM?.findDOMNode(this)?.parentNode;
        this.props.showPointInfo(pointId, pointNumber, source, parent);
    }

    initTable = (response: AdminStore.SAbonentConnectionStatisticDTO[]) => {
        if (this.props.points != null) {

            this.columns =
            [
                {
                    dataIndex: 'info',
                    title: 'Инфо',
                    align: 'center',
                    render: (val: any) => <Button onClick={() => this.showPointInfo(val, this.props.points?.find(x => x.id === val)?.number ?? '')} size="middle" shape="circle" icon={<ScheduleTwoTone twoToneColor={Const.COLORS.SvgIconColor} />} />,
                    width: 60,
                    fixed: 'left' as 'left',
                },
                {
                    dataIndex: "number",
                    title: "Номер точки учета",
                    sorter: (a: any, b: any) => this.sorter(a.number, b.number),
                    ellipsis: true,
                    width: 180,
                    fixed: 'left' as 'left',
                    resizable: true,
                },
                {
                    dataIndex: "address",
                    title: "Адрес",
                    sorter: (a: any, b: any) => this.sorter(a.address, b.address),
                    ellipsis: true,
                    width: 120,
                    fixed: 'left' as 'left',
                    resizable: true,
                },
                {
                    dataIndex: "conntype",
                    title: "Тип связи",
                    align: 'center',
                    render: (val: any) => <Tooltip title={this.state.dicts?.connectionTypes[val]}> <img src={this.selectConnIcon(val)} /> </Tooltip>,
                    sorter: (a: any, b: any) => this.sorter(a.conntype, b.conntype),
                    width: 60,
                    fixed: 'left' as 'left',
                },
                {
                    dataIndex: "comm",
                    title: 'Связь',
                    sorter: (a: any, b: any) => this.sorter(a.comm, b.comm),
                    render: (val: any) => <Tooltip title={this.state.dicts?.commonConnectionStatuses[val]}> <img src={this.selectIcon(val)} /> </Tooltip>,
                    align: 'center',
                    width: 60,
                    fixed: 'left' as 'left',
                },
            ];
            
            let dates: Date[] = [];
            for (let d = new Date(this.state.start); d < this.state.end; d = moment(d).add(1, 'day').toDate()) {
                dates.push(d);
            }

            this.columns = this.columns.concat(dates.map(x => ({title: <>{moment(x).format('DD')}<br/>{moment(x).format('MMM')}</>, dataIndex: moment(x).format('YYYY.MM.DD'), align: 'center', width: '60px'})));

            this.itemsDataSource = response.map(x => {
                const p = this.props.points!.find(y => y.id === x.pointId);
                let res: any = undefined;
                if (p != undefined) {
                    res = {
                        number: p?.number,
                        address: p?.persistProperties['address'],
                        conntype: p?.persistProperties['connectiontypeid'],
                        comm: x.commonStatusId,
                        info: p?.id,
                        resource: p?.resourceTypeId,
                        devtype: p?.persistProperties['devicetypeid'],
                    }
                    x.dayStatuses.forEach(d => res[moment(d.dateStatus).format('YYYY.MM.DD')] = <a onClick={() => this.onTaskClick(d.dateStatus, p, d.clarifyStatusDay)}>{d.clarifyStatusDay}</a>);
                }
                return res;
            });
            
            this.applyFilter(this.state.filterConnTypes, this.state.filterResourceIds, this.state.filterDevTypes);
            this.forceUpdate();
        }
    }

    onTaskClick = (time: Date, point: Point, clarifyStatusDay: string) => {
        let time2 = moment(new Date(time)).set({hour: 0, minute: 0, second: 0, millisecond: 0}).toDate();
        const comm = clarifyStatusDay === '-' ? 'Нет' : 'Есть';
        this.setState({ tasksByAbonent: point, selectedDay: time2, taskCommStatus: comm, modalZIndex:getModalZIndex()});
    }

    private columns: any[] = [];

    private itemsDataSource: any = undefined;
    private itemsDataSourceFiltered: any = undefined;

    makeReport = (num: string) => {
        if (num === '1') {
            let parameters: any = {};
            parameters['Report'] = 'Small';
            if (this.props.groupName == null) {
                parameters['GroupName'] = this.props.points?.[0].number;
            } else {
                parameters['GroupName'] = this.props.groupName;
            }
            
            sendRequestToBackend(
                {reportCode: 'r024', fileName: 'Отчета по Связи.xls', pointIds: this.props.points?.map(x => x.id), beginDate: this.state.start, endDate: this.state.end, parameters},
                'reports/makelistreportbase',
                (response: any) => {
                    if (response != null) {
                        saveFile(response.fileName, convertBase64ToBinary(response.base64data));
                    }
                },
                this.props.setIsLoading,
                (error: any) => {
                    message.warning("Ошибка");
                    console.log(error);
                    message.error(error);
                }
            );
        } else if (num === '2') {
            let parameters: any = {};
            
            sendRequestToBackend(
                {reportCode: 'r023', fileName: 'Отчета по Мониторингу опроса.xls', pointIds: this.props.points?.map(x => x.id), beginDate: this.state.start, endDate: this.state.end, parameters},
                'reports/makelistreportbase',
                (response: any) => {
                    if (response != null) {
                        saveFile(response.fileName, convertBase64ToBinary(response.base64data));
                    }
                },
                this.props.setIsLoading,
                (error: any) => {
                    message.warning("Ошибка");
                    console.log(error);
                    message.error(error);
                }
            );
        } else if (num === '3') {
            let parameters: any = {};
            if (this.props.groupName == null) {
                parameters['GroupName'] = this.props.points?.[0].number;
            } else {
                parameters['GroupName'] = this.props.groupName;
            }

            sendRequestToBackend(
                {reportCode: 'r024', fileName: 'Отчет по опросу приборов.xls', pointIds: this.props.points?.map(x => x.id), beginDate: this.state.start, endDate: this.state.end, parameters},
                'reports/makelistreportbase',
                (response: any) => {
                    if (response != null) {
                        saveFile(response.fileName, convertBase64ToBinary(response.base64data));
                    }
                },
                this.props.setIsLoading,
                (error: any) => {
                    message.warning("Ошибка");
                    console.log(error);
                    message.error(error);
                }
            );
        }
    }

    private connTypes: SimpleTreeSelectItem[] = [];
    private devTypes: SimpleTreeSelectItem[] = [];

    //=============================================================================
    render() {
        return (
            <Modal
                maskClosable={false}
                bodyStyle={{paddingTop: '10px', paddingBottom: '10px'}}
                title={this.props.groupName != null ? "Мониторинг опроса группы " + this.props.groupName : "Мониторинг опроса точки учета " + this.props.points?.[0].number}
                wrapClassName={MODAL_WRAP_CLASSNAME}
                zIndex={this.props.zIndex}
                open={this.props.points != null}
                footer={null}
                // footer={[
                //     <div key={1} style={{ display: 'flex', alignItems: 'baseline' }}>
                //         <Button key="1" type='primary' onClick={() => this.props.onHide()} style={{marginLeft: 'Auto'}}>Закрыть</Button>
                //     </div>
                // ]}
                onCancel={() => { this.props.onHide() }}
                width={this.props.windowWidth - 200}
                centered
            >
                {
                    //this.state.queryItemTypes === undefined ? <LoadingOutlined/> :
                    <>  
                        <Space style={{paddingBottom: '10px'}} wrap direction='horizontal'>
                            <DatePicker.RangePicker 
                                value={[moment(this.state.start), moment(this.state.end)]}
                                onChange={val => this.setState({start: val?.[0]?.toDate() ?? new Date(), end: val?.[1]?.toDate() ?? new Date()})}
                                allowClear={false}
                            />
                            <Select
                                key={'s1'}
                                value='Сформировать отчет xls'
                                style={{ width: 210 }}
                                dropdownMatchSelectWidth={false}
                                onChange={(val) => this.makeReport(val)}
                                options={[
                                    { value: '1', label: 'Отчет по связи' },
                                    { value: '2', label: 'Отчет по мониторингу опроса' },
                                    { value: '3', label: 'Отчет по опросу приборов' },
                                ]}
                            />
                            <SimpleTreeSelect
                                key={'st1'}
                                items={this.devTypes ? this.devTypes : []}
                                selectedIds={this.state.filterDevTypes == null ? ['all'] : this.state.filterDevTypes}
                                onChange={(value) => {
                                    const ids = value.indexOf('all') >= 0 ? null : value;
                                    this.applyFilter(this.state.filterConnTypes, this.state.filterResourceIds, ids); 
                                    this.setState({ filterDevTypes: ids });
                                }}
                                constAllSelected={'all'}
                                rootItemShortTitle='Все счётчики'
                                rootItemTitle='Все счётчики'
                            />
                            <SimpleTreeSelect
                                key={'st2'}
                                items={this.connTypes ? this.connTypes : []}
                                selectedIds={this.state.filterConnTypes == null ? ['all'] : this.state.filterConnTypes}
                                onChange={(value) => {
                                    const ids = value.indexOf('all') >= 0 ? null : value;
                                    this.applyFilter(ids, this.state.filterResourceIds, this.state.filterDevTypes); 
                                    this.setState({ filterConnTypes: ids });
                                }}
                                constAllSelected={'all'}
                                rootItemShortTitle='Все типы связи'
                                rootItemTitle='Все типы связи'
                            />
                            <SimpleTreeSelect
                                key={'st3'}
                                items={(this.props.userProfile) ? this.props.userProfile.resources.map(x => ({name: x.name, shortName: x.shortName, id: x.id.toString()})) : []}
                                selectedIds={this.state.filterResourceIds == null ? [Const.ALL_RESOURCE_ID] : this.state.filterResourceIds}
                                onChange={(value) => {
                                    const selected = value.indexOf(Const.ALL_RESOURCE_ID) >= 0 ? null : value;
                                    this.applyFilter(this.state.filterConnTypes, selected, this.state.filterDevTypes); 
                                    this.setState({ filterResourceIds: selected });
                                }}
                                constAllSelected={Const.ALL_RESOURCE_ID}
                                rootItemShortTitle='Все ресурсы'
                                rootItemTitle='Все ресурсы'
                            />
                        </Space>
                        
                        <ResizableTable
                            //loading={this.state.queryItems == undefined}
                            //scroll={{ x: 1100 }}
                            scroll={{y: '60vh'}}
                            bordered={true}
                            size={'small'}
                            rowKey="info"
                            columns={this.columns}
                            dataSource={this.itemsDataSourceFiltered}
                            pagination={false}
                        />
                        
                        <TasksForm
                            taskStartTime={this.state.selectedDay}
                            dicts={this.state.dicts}
                            onHide={() => { this.setState({ tasksByAbonent: null }) }}
                            point={this.state.tasksByAbonent}
                            setIsLoading={this.props.setIsLoading}
                            commStatus={this.state.taskCommStatus}
                            devTypeId={Number(this.state.tasksByAbonent?.persistProperties['devicetypeid']) ?? -1}
                            windowWidth={this.props.windowWidth}
                            zIndex={this.state.modalZIndex}
                            setActiveLogTask={this.props.setActiveLogTask}
                        />
                    </>
                }
            </Modal>
        );
    }
}

export default PollingForm;