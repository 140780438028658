import * as React from 'react';
import { Radio } from 'antd';

import { Space } from 'antd';
import { MeasurementTypesEnum, SMapLayer } from '../../store/MapDataStore';
import { PeriodsEnum } from "../../decl";

type PropsType = {
    layersColors: {[layerCode: string] : string};
    selectedlayers: string[];
    layers: {[code: string]: SMapLayer} | undefined;
    activeLayerCode: string;
    timerWorking: boolean;
    timerPeriodSec: number;
    onChangeCurrentLayer(layer: SMapLayer): void;
    periodType: PeriodsEnum
}

type StateType = {
    timerId: any;
    timerPeriodSec: number;
}

class DataLayersPanel extends React.Component<PropsType, StateType> {
    state = {
        timerId: undefined,
        timerPeriodSec: this.props.timerPeriodSec,
    }

    componentDidUpdate() {
        if (this.props.timerWorking === true && this.state.timerId === undefined) {
            this.startTimer();
        }

        if (this.props.timerWorking === false && this.state.timerId !== undefined) {
            this.stopTimer();
        }

        if (this.state.timerPeriodSec !== this.props.timerPeriodSec) {
            this.restartTimer();
        }
    }

    componentWillUnmount() {
        if (this.state.timerId !== undefined) {
            this.stopTimer();
        }
    }

    restartTimer = () => {
        if (this.state.timerId !== undefined) {
            clearInterval(this.state.timerId!);
            let id = setInterval(this.timerTick, this.props.timerPeriodSec * 1000);
            this.setState({timerId: id, timerPeriodSec: this.props.timerPeriodSec});
        }
    }

    startTimer = () => {
        if (this.state.timerId === undefined) {
            let id = setInterval(this.timerTick, this.props.timerPeriodSec * 1000);
            this.setState({timerId: id});
        }
    }

    stopTimer = () => {
        if (this.state.timerId !== undefined) {
            clearInterval(this.state.timerId!);
            this.setState({timerId: undefined})
        }
    }

    timerTick = () => {
        if (this.props.layers !== undefined) {
            const arr = this.getSelectedLayersWithFilter().map((item) => item.layerCode);

            let i;
            if (this.props.activeLayerCode !== undefined) {
                i = arr.indexOf(this.props.activeLayerCode);
            } else {
                i = -1;
            }
            i += 1;
            if (i >= arr.length)
                i = 0;
            this.props.onChangeCurrentLayer(this.props.layers[arr[i]]);
        }
    }

    handleChange = (e: any) => {
        const inputs = e.target.parentNode.getElementsByTagName("input");
        if(inputs.length > 0){
            const value = inputs[0].value;
            if (this.props.layers !== undefined) {
                this.props.onChangeCurrentLayer(this.props.layers[value]); 
            }
            e.preventDefault();
        }
    };

    getSelectedLayersWithFilter = () => {
        let res: SMapLayer[] = [];
        let arr = this.props.selectedlayers.sort();

        if (this.props.layers !== undefined) { 
            for (const item of arr) {
                if (this.props.layers[item] !== undefined) {
                    if (this.props.layers[item].measurementType === MeasurementTypesEnum.None || this.props.layers[item].measurementType === MeasurementTypesEnum.CurrentAndArchive)
                        res.push(this.props.layers[item])
                    else if (this.props.layers[item].measurementType === MeasurementTypesEnum.Archive && this.props.periodType !== PeriodsEnum.current)
                        res.push(this.props.layers[item])
                    else if (this.props.layers[item].measurementType === MeasurementTypesEnum.Current && this.props.periodType === PeriodsEnum.current)
                        res.push(this.props.layers[item])
                }
            }
        }

        return res;
    }

    func = () => {
        const res = this.getSelectedLayersWithFilter();

        return res.map(item => {
            const style = item.layerCode === this.props.activeLayerCode ? { borderBottomWidth: '3px', borderBottomColor: this.props.layersColors[this.props.activeLayerCode] ?? 'black'} : {};
            return <Space key={item.layerCode}  onClick={this.handleChange}> 
            <Radio.Button style={style} value={item.layerCode}>{item.layerName}</Radio.Button>
            </Space>
        });
    }

    render() {
        let style: {};

        return (
            <Space>
                <Radio.Group value={this.props.activeLayerCode}>
                    {
                        this.func()
                    }
                </Radio.Group>
            </Space>
        )
    };
}

export default DataLayersPanel;