import React from 'react';
import styles from '../../resources/Page.module.less';
import type { ResizeCallbackData } from 'react-resizable';
import { Resizable } from 'react-resizable';
import { Table } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';


interface Props extends TableProps<ColumnsType> { 

}

type State = {
    columns: any[] | null,
}

const ResizableTitle = (
    props: React.HTMLAttributes<any> & {
        onResize: (e: React.SyntheticEvent<Element>, data: ResizeCallbackData) => void;
        width: number;
    },
) => {
    const { onResize, width, ...restProps } = props;

    if (!width) {
        if (width != undefined && isNaN(Number(width))) {
            throw new TypeError("Для компонента ResizableTable при задании ширины колонки необходимо указывать целочисленный тип.");
        }

        return <th {...restProps} />;
    }

    return (
        <Resizable
            width={width}
            height={0}
            handle={
                <span
                    className={styles.reactResizableHandle}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                />
            }
            onResize={onResize}
            draggableOpts={{ enableUserSelectHack: false }}
        >
            <th {...restProps} />
        </Resizable>
    );
};

// Компонент antd Table с добавленым полем в resizable в определение колонок.
//!!Важно, при определении ширины колонок нужно исползовать целочисленный тип а не строку, '60px' - нельзя.
//Также необходимо что-бы переменная columns была определена без частых пересозданий иначе установленная новая ширина колонок будет сбрасываться
//так как внутри компонента хранится копия этой переменной и обновляется из свойств при изменении их.
export default class ResizableTable extends React.Component <Props, State > {

    constructor(props: Props) {
        super(props);
        
        this.state = {
            columns: null,
        }
    }

    componentDidMount(): void {
        this.setState({columns: this.props.columns ?? null});
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (prevProps.columns !== this.props.columns) {
            this.setState({columns: this.props.columns ?? null});
        }
    }

    public render() {
        const handleResize: Function = (index: number) => (_: React.SyntheticEvent<Element>, { size }: ResizeCallbackData) => {
            if (this.state.columns != null) {
                const newColumns = [...this.state.columns];
                newColumns[index] = {
                    ...newColumns[index],
                    width: size.width,
                };
                this.setState({columns: newColumns});
            }
        };

        const mergeColumns: any[] | undefined = this.state.columns == null ? undefined : this.state.columns.map((col, index) => {
            let res = {
                ...col
            };

            if (col.resizable === true)
            {
                res.onHeaderCell = (column: any) => ({
                    width: column.width,
                    onResize: handleResize(index) as React.ReactEventHandler<any>,
                });
            }

            return res;
        });

        let tProps: any = {
            ...this.props,
        }

        if (this.state.columns != null)
        {
            tProps.components = {
                header: {
                    cell: ResizableTitle,
                },
            };

            tProps.columns = mergeColumns;
        }

        return (
            <Table
                {...tProps} showSorterTooltip={false}
            />
        )
    }
}