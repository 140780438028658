import * as React from 'react';

import { Modal, Input } from 'antd';

import { MODAL_WRAP_CLASSNAME } from '../../utils/Const';

type Props = {
    onHide(): void,
    text: string | null,
    title: string,
    width?: number,
    zIndex: number
}

type State = {

}

class ShowTextForm extends React.Component<Props, State> {
    constructor(props: Props, state: State) {
        super(props);

    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps: Props, prevState: State) {

    }

    //=============================================================================
    render() {
        return (
            <Modal
                bodyStyle={{padding: '5px'}}
                title={this.props.title}
                wrapClassName={MODAL_WRAP_CLASSNAME}
                zIndex={this.props.zIndex}
                open={this.props.text != null}
                footer={[ ]}
                onCancel={() => { this.props.onHide() }}
                width={this.props.width ?? 800}
                style={{ top: '4vw' }}
            >
                {
                    <Input.TextArea readOnly={true} value={this.props.text ?? ''} rows={20} />
                }
            </Modal>
        );
    }
}

export default ShowTextForm;