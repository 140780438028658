import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import {message} from '../utils/Common'
import { getHeaders, BACKEND_URL } from '../utils/AuthUtils';
import { ResponseStatusesEnum, SResponseDTO } from '../decl';
import { CloseSessionAction } from './Page';


//Состояние хранилища для страницы Tables.
export interface ITablesState {
    needRefresh: boolean;
    tableType: string;
    newEventIds: number[];
    from: Date;
    to: Date;
}

export interface SetNeedRefreshAction { type: 'SET_NEED_REFRESH', needRefresh: boolean };
export interface SetTableTypeAction { type: 'SET_TABLE_TYPE', tableType: string };
export interface SetDateRangeAction { type: 'SET_DATE_RANGE', from: Date, to: Date };
export interface AddNewEventsAction { type: 'ADD_NEW_EVENTS', ids: number[] };
export interface ClearNewEventsAction { type: 'CLEAR_NEW_EVENTS' };
export interface AcceptEventsAction { type: 'ACCEPT_EVENTS' };

export type KnownAction = SetNeedRefreshAction | SetTableTypeAction | SetDateRangeAction | AddNewEventsAction | ClearNewEventsAction | AcceptEventsAction | CloseSessionAction;

export const actionCreators = {
    setNeedRefresh: (needRefresh: boolean) => ({ type: 'SET_NEED_REFRESH', needRefresh: needRefresh } as SetNeedRefreshAction),
    setTableType: (tableType: string) => ({ type: 'SET_TABLE_TYPE', tableType: tableType } as SetTableTypeAction),
    setDateRange: (from: Date, to: Date) => ({type: 'SET_DATE_RANGE', from: from, to: to } as SetDateRangeAction),
    addNewEvents: (ids: number[]) => ({type: 'ADD_NEW_EVENTS', ids: ids } as AddNewEventsAction),
    clearNewEvents: () => ({type: 'CLEAR_NEW_EVENTS' } as ClearNewEventsAction),
    acceptEvents: (iOnDone: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.tables) {
            const requestOptions = {
                method: 'POST',
                headers:  getHeaders(),
                body: JSON.stringify({eventIds: appState.tables.newEventIds})
            };
            fetch(BACKEND_URL + 'points/neweventsaccept3', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    const ok: boolean = data.bodyStatus !== undefined && data.bodyStatus == ResponseStatusesEnum.Ok;
                    if(iOnDone) iOnDone(ok);
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                })
                .catch(error => message.error('Ошибка:' + error));
            dispatch({ type: 'ACCEPT_EVENTS' });
        }
    }
};

export const reducer: Reducer<ITablesState> = (state: ITablesState | undefined, incomingAction: Action): ITablesState => {
    if (state === undefined) {
        const to: Date = new Date();
        const from: Date = new Date(to);
        from.setDate(from.getDate() - 30);
        return { needRefresh: false, tableType: "0", newEventIds: [], from: from, to: to };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SET_NEED_REFRESH':
            return {...state, needRefresh: action.needRefresh };
        case 'SET_TABLE_TYPE':
            return {...state, tableType: action.tableType };
        case 'SET_DATE_RANGE':
            return {...state, from: action.from, to: action.to };
        case 'ADD_NEW_EVENTS':
            return {...state, newEventIds: state.newEventIds.concat(action.ids) };
        case 'ACCEPT_EVENTS':
            return {...state, newEventIds: [] };
        default:
            return state;
    }
};
