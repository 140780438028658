import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';

import { ApplicationState } from '../store';
import * as PageStore from '../store/Page';
import * as UserProfileStore from '../store/UserProfile';
import { BaseProfile } from '../decl';
import styles from '../resources/Page.module.less';
import * as SiteLogStore from '../store/SiteLogStore';
import { Button, Input, Result, Space, Table, Tooltip } from 'antd';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { MaximizeTwoTone } from '@material-ui/icons';
import moment from 'moment';
import { makeCSVfromTable, unicodeToWin1251 } from '../utils/Common';

const actionCreators = { ...PageStore.actionCreators, ...UserProfileStore.actionCreators, ...SiteLogStore.actionCreators };

type SiteLogProps =
    {
        page: PageStore.PageState,
        profile: BaseProfile,
        siteLog: SiteLogStore.ISiteLogState,
    } &
    typeof actionCreators &
    RouteComponentProps<{}>

interface ISiteLogState {
}

class SiteLog extends React.PureComponent<SiteLogProps, ISiteLogState> {

    constructor(props: SiteLogProps) {
        super(props);

        this.state = {
        }
    }

    sorter = (a: any, b: any) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b);

    searchInput: any;
    getColumnSearchProps = (dataIndex: any) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Найти ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Найти
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Сброс
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            this.setState({
                                searchText: selectedKeys[0],
                                searchedColumn: dataIndex,
                            });
                        }}
                    >
                        Фильтр
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value: any, record: any) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: (visible: any) => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        // render: text =>
        //     this.state.searchedColumn === dataIndex ? (
        //         <Highlighter
        //             highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        //             searchWords={[this.state.searchText]}
        //             autoEscape
        //             textToHighlight={text ? text.toString() : ''}
        //         />
        //     ) : (
        //         text
        //     ),
        render: (text: any) => text,
    });

    handleReset = (clearFilters: any) => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    resultFilter = {
        filters: [
            {
                text: 'OK',
                value: 0,
            },
            {
                text: 'Ошибка',
                value: 1,
            },
        ],
        onFilter: (value: any, record: any) => record.Result === value
    }
    
    componentDidUpdate(prevProps: SiteLogProps) {
        if (this.props.siteLog.userLogData !== prevProps.siteLog.userLogData && this.props.siteLog.userLogData != undefined) {
            this.columns2 = Object.keys(this.columns).map(x => {
                let res: any = {
                    title: this.columns[x].title,
                    dataIndex: x,
                    width: this.columns[x].width,
                    ellipsis: this.columns[x].ellipsis,
                    render: this.columns[x].render
                }

                if (this.columns[x].hasSorter) {
                    res['sorter'] = (a: any, b: any) => this.sorter(a[x], b[x]);
                }

                if (x === 'Result') {
                    res['align'] = 'center' as 'center';
                    res['render'] = (val: any, record: any) => val === 0 ? 'OK' : 'Ошибка';
                } else if (x === 'EventTimeBegin') {
                    res['render'] = (val: any, record: any) => <span style={{ whiteSpace: 'nowrap' }}>{moment(new Date(val)).format('DD.MM.YYYY HH:mm:ss')}</span>;
                }

                // if (x !== 'Message') {
                //     res['width'] = '0px';
                // }

                if (this.columns[x].hasFiler) {
                    if (x === 'Result') {
                        res = {...res, ...this.resultFilter};
                    } else {
                        res = {...this.getColumnSearchProps(x), ...res};
                    }
                }

                return res;
            });
        }

        if (this.props.siteLog.makeCSV === true) {
            const data = makeCSVfromTable(this.makeData(), this.columns2);
            const data2 = unicodeToWin1251(data);

            const blob = new Blob([data2], {
                type: 'text/csv',
            });

            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = 'report.csv';
            a.click();

            this.props.setSiteLogMakeCSV(false);
        }

        if(this.props.siteLog.isLoading != prevProps.siteLog.isLoading){
            this.props.setIsLoading(this.props.siteLog.isLoading);
        }
    }

    private readonly columns: any = {
        servername: { title: 'Сервер', hasFiler: false, hasSorter: false, width: 150, ellipsis: true },
        eventtimebegin: { title: 'Время', hasFiler: false, hasSorter: true, width: 150 },
        ip: { title: 'IP', hasFiler: true, hasSorter: true, width: 150, ellipsis: true,
            render: (value: any, record: any) => (
                <Tooltip title={'IP: ' + value +'. Браузер: ' + (record ? record['useragent'] : 'Неизвестно')}>
                <span>{value}</span>
              </Tooltip>
             )
        },
        name: { title: 'Пользователь', hasFiler: true, hasSorter: true, width: 200, ellipsis: true },
        result: { title: 'Результат', hasFiler: true, hasSorter: false, width: 130 },
        message: { title: 'Запрос', hasFiler: true, hasSorter: false },
    }

    private columns2: any = undefined;

    private makeData: any = () => {
        let result: any = [];
        //console.log("LogData: ", this.props.siteLog.userLogData);
        if (this.props.siteLog.userLogData != undefined) {
            result = this.props.siteLog.userLogData?.userLogData.map(row => {
                let colsObj: any = {};
                Object.keys(this.columns).forEach(col => {
                    const ind = this.props.siteLog.userLogData?.columns[col];
                    if (ind !== undefined) {
                        colsObj[col] = row[ind];
                    } else {
                        colsObj[col] = null;
                    }
                })
                colsObj['key'] = row[this.props.siteLog.userLogData?.columns['userlogeventid'] ?? 0];
                colsObj['useragent'] = row[this.props.siteLog.userLogData?.columns['useragent'] ?? 0];
                return colsObj;
            })
        }
        //console.log("Result: ", result);

        return result;
    }

    public render() {
        return (
            <div className={styles.container}>
                {
                    this.props.siteLog.isLoading === true || this.props.siteLog.isLoadingTypes === true ? null :
                        this.props.siteLog === undefined || this.props.siteLog.userLogData === undefined || this.columns2 === undefined ? null :
                            <div style={{ overflow: 'auto', height: '100%' }}>
                                <Table
                                    columns={this.columns2}
                                    dataSource={this.makeData()}
                                    bordered
                                    pagination={{ showSizeChanger: true, pageSizeOptions: ["5", "10", "20", "50", "100"], defaultPageSize: 10, locale: { items_per_page: " / страницу" } }}
                                />
                            </div>
                }
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            page: state.page,
            profile: state.userProfile?.profile,
            siteLog: state.siteLog,
        }
    },
    actionCreators
)(SiteLog as any);
