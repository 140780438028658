import {PeriodsEnum, Point, SChartParameter} from "../../decl";
import moment from "moment";
import {IChartsState} from "../../store/Charts";


export const CHARTS_DEBUG = /localhost/.test(window.location.href);

export enum ShowParams {
  PDZ = 0,
  TRENDS = 1,
  TG = 2,
  ALL = 3
}

export enum TrendsRefreshTime {
  //для текущих каждые 2 мин
  current = CHARTS_DEBUG ? 15000 : 2 * 60 * 1000,// 2 * 60 * 1000,
  //для часовых каждые 20 мин
  hour = CHARTS_DEBUG ? 16000 : 20 * 60 * 1000,// 20 * 60 * 1000,
  day = CHARTS_DEBUG ? 17000 : 20 * 60 * 1000
}

/**
 * Преобразуем даты, на основе периода
 * @param period
 * @param maxTime
 * @param dateFromDefault
 */
export function datesActions(period: PeriodsEnum, maxTime: any = moment(), dateFromDefault: any = null) {
  let dates: any;


  let from = maxTime.clone();

  switch (period) {
	case PeriodsEnum.current:
	  dates = [
		dateFromDefault
		  ? dateFromDefault
		  : from.subtract(8, 'hours'),
		maxTime
	  ];
	  break;
	case PeriodsEnum.hour:
	  // console.log('datesActions hour');
	  dates = [
		dateFromDefault
		  ? dateFromDefault
		  : from.subtract(24, 'hours'),
		maxTime
	  ];
	  break;
	default:
	  dates = [
		dateFromDefault
		  ? dateFromDefault
		  : from.subtract(30, 'days'),
		maxTime
	  ];
	  break;
  }
  // console.log(
	// 'after',
	// from.format('DD.MM.YYYY HH:mm')
  // );
  // console.log(
	// '%cdatesActions',
	// logStyle.h3,
	// period,
	// maxTime.format('DD.MM.YYYY HH:mm'),
  // );

  return {
	dates: dates,
	dateFrom: 0,
	dateTo: 0
  }
}

export function datesForServer(period: PeriodsEnum, from:any, to:any) {

  let dateFrom = from.clone();
  let dateTo = to.clone();

  switch (period) {
	case PeriodsEnum.current:

	  break;
	case PeriodsEnum.hour:

	  break;
	default:
	  dateFrom.startOf('day');
	  dateTo.add(1, 'day').startOf('day');
	  break;
  }

  return {
	dateFrom: dateFrom,
	dateTo: dateTo
  }
}

export const defaultOptions: any = {
  responsive: true,
  plugins: {
	legend: {
	  display: false,
	  position: 'top',
	  labels: {
		boxWidth: 20,
		boxHeight: 20,
		color: 'rgb(0, 0, 0)',
	  }
	},
	title: {
	  display: false,
	  text: 'Chart.js Line Chart',
	},
	filler: {
	  propagate: false
	}
	// tooltip: {
	//   titleAlign: 'right'
	// }
  },
  maintainAspectRatio: false,
  interaction: {
	mode: 'index',
	intersect: false,
  },
  hover: {
	mode: 'index',
	intersect: false
  },
  title: {
	display: true,
	text: "default chart"
  },
  scales: {
	x: {
	  type: "time",
	  time: {
		// unit: 'minute',
		unit: 'days',
		tooltipFormat:'DD.MM.YYYY',
		stepSize: 1,
		displayFormats: {
		  day: 'DD.MM.YY',
		  hour: 'HH:mm',
		  minute: 'HH:mm'
		}
	  },
	  // как отображать риски по оси Х https://www.chartjs.org/docs/latest/axes/cartesian/time.html#ticks-source
	  ticks: {
		// source: 'data',
		stepSize: 0.5
	  },
	  bounds: 'ticks',
	  offset: false
	}
  }
};

export const deleteInArray = (arr: any[], val: any) => {
  const index = arr.indexOf(val);
  if (index > -1)
	arr.splice(index, 1);

  return arr;
};
export const setScaleXminMax = (opts: any, period: PeriodsEnum, dates: any[]) => {
  if (period == PeriodsEnum.day)
	opts.scales.x.time.unit = 'day';
  else {
	opts.scales.x.time.unit = 'hour';
	// opts.scales.x.time.unit = 'minutes';
	// opts.scales.x.min = dates[0];
	// opts.scales.x.max = dates[1];
  }

  return opts;
};

export const arrayHash = (arr: any[]) => {
  return JSON.stringify(arr);
}

/*
 *
 * Интерфейсы
 *
 */
export interface IChartScaleTitle {
  display: boolean,
  text: string
}
export interface IChartScaleTime {
  unit: string;
  displayFormats: {
	day: string
  }
}
export interface IChartScale {
  axis?: string;
  title?: IChartScaleTitle;
  suggestedMin?: number | string;
  suggestedMax?: number | string;

  type?: string;
  time?: IChartScaleTime
}

export interface IChartScales {
  [index: string]: IChartScale
}
export interface IChartOptions {
  responsive: boolean;
  title: IChartScaleTitle,
  scales: IChartScales
}
export interface IChartDataPoint {
  x: string | number;
  // y: string | number;
  y: any;
}
export interface IDatasetsItem {
  id: string;
  label: string;
  data: IChartDataPoint[];
  borderColor: string;
  yAxisID: string;
  borderDash?: any[];
  hidden?: boolean;
  etalonId?: string;
  etalonForId: string;
}
export interface IDatasetsItemsList extends Array<IDatasetsItem> {
}
export interface ILineData {
  datasets: IDatasetsItemsList
}
export interface IChartsOptions {
  optionsAll: [] | null;
  optionsParams: object | null;
}
export interface IChartParameter extends SChartParameter {
  etalonId: string | null;
  unitName: string;
  borderColor: string;
}

//: AppThunkAction<KnownAction>
export interface IChartsContainerProps extends IChartsState {
  allPoints: {[pointId: string] : Point}
  pointName?: string;
	setIsLoading?: (status: boolean, waitInterval?: number | undefined, waitMessage?: string | undefined) => void;
	closeSession: any,
  setChartDates: any;
}

//данные которые посылаются на сервер
export interface IChartsContainerStateGetDataParams {
  pointId: string;
  period: PeriodsEnum;
  dates:  any[];
}

export interface IChartsContainerState extends IChartsState {
  fullscreen: boolean;
  dates: any[];
  data: ILineData | null;
  showParams: ShowParams[];
  disabledShowParams: ShowParams[];
  mainTitle: string;
  // parameters: [id: string]: ;
  parameters: any;//параметры полученные для ТУ
  selectedParameters: string[];//отмеченные параметры отображаемые на графике
  lastSelectedParameter: string;//посл выбранные параметр, чтобы  когда жмут вкл/выкл ВСЕ, то включался последний
  chartOptions: IChartsOptions | null;
  //теже параметры только будущие, если  данные по графику получены ине пустые, чтобы не обновлять графики, если данных нет
  parametersNext: any;
  selectedParametersNext: string[];

  chartOptionsNext: IChartsOptions | null;
  // periodToGetData: PeriodsEnum
  redrawArea: number;//перерисовать область графиков, число - Date.now()
  trendsIsRunned: boolean;//тренды уже запущены

  /*
  * данные которые посылаются на сервер,
  * в основном для трендов, чтобы не менялись при изменении полей
   */
  getDataParams: IChartsContainerStateGetDataParams;
}
export const logStyle = {
  h1: 'font-size: 18px;background: #000;color: #fff',
  h2: 'font-size: 16px; background: blue;color: #fff',
  h3: 'font-size: 14px; background: green;color: #fff',
  h4: 'font-size: 12px; background: #ccc;color: #000',
}
