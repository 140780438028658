import { Button, Form, FormInstance, Input, Modal } from 'antd';
import * as React from 'react';
import { MODAL_WRAP_CLASSNAME } from '../utils/Const';
import { getModalZIndex } from '../utils/Common';

//форма изменения пароля
const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

type Props = {
    disabled: boolean,
    changePassword: (password: string, newPassword: string) => any,
}

export default class ChangePasswordForm extends React.Component<Props> {
    state = { 
        visible: false,
        zIndex: 0
    };

    formRef = React.createRef<FormInstance>();

    showModal = () => {
        this.formRef.current?.resetFields();
        this.setState({
            visible: true,
            zIndex: getModalZIndex()
        });
    };

    hideModal = () => {
        this.setState({
            visible: false,
        });
    };

    onOk = () => {
        this.formRef.current?.submit();
    };

    onFinish = (values: any) => {
        this.props.changePassword(values['oldPassword'], values['newPassword'])
        this.hideModal();
    };

    render() {
        return (
            <>
                <Button disabled = {this.props.disabled} type="primary" onClick={this.showModal}>
                    Изменить
                </Button>
                <Modal
                    title="Смена старого пароля"
                    wrapClassName={MODAL_WRAP_CLASSNAME}
                    zIndex={this.state.zIndex}
                    open={this.state.visible}
                    onOk={this.onOk}
                    onCancel={this.hideModal}
                    okText="Изменить"
                    cancelText="Отмена"
                >
                    <Form
                        ref={this.formRef}
                        {...layout}
                        onFinish={this.onFinish}
                    >
                        <Form.Item
                            label="Старый пароль"
                            name="oldPassword"
                            rules={[
                                {
                                    required: true,
                                    message: 'Пожалуйста, введите старый пароль',
                                },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item
                            label="Новый пароль"
                            name="newPassword"
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Пожалуйста, введите новый пароль',
                                },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item
                            label="Повторите пароль"
                            name="newPassword2"
                            dependencies={['newPassword']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Повторите пароль!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('newPassword') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Введенный пароль не совпадает!'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                    </Form>

                </Modal>
            </>
        );
    }
}