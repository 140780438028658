import React, { Component } from 'react';
import MarkerClusterGroup from 'react-leaflet-markercluster';

//Из-за проблем в новой версией TypeScript с MarkerClusterGroup была написана эта обертка
class MarkerClusterGroup2 extends Component {
    render() {
        return (
                <MarkerClusterGroup {...this.props}>
                    {this.props.children}
                </MarkerClusterGroup>
            )
        };
 }
export default MarkerClusterGroup2;