import * as React from 'react';

import styles from '../../resources/Page.module.less';
import { Modal, Select, Input, Row, Col } from 'antd';

import { deepCopyObj } from '../../utils/Common';
import { MODAL_WRAP_CLASSNAME } from '../../utils/Const';
import { SBillingCompanyInfoDTO, SBilContactDTO } from './BillingDecls';
import ContactsTable from './ContactsTable';

const { Option } = Select;

type Props = {
    onHide() : void,
    company: SBillingCompanyInfoDTO | null,
    contacts: SBilContactDTO[] | undefined,
    onWrite(company: SBillingCompanyInfoDTO, contacts: SBilContactDTO[], contactsDel: number[]) : void,
}

type State = {
    company: SBillingCompanyInfoDTO | null,
    contacts: SBilContactDTO[] | null,
    contactsDel: number[],
    zIndex: number,
}

export default class CompsnyEditForm extends React.Component<Props, State> {
    constructor(props: Props, state: State) {
        super(props);

        this.state = {
            company: null,
            contacts: null,
            contactsDel: [],
            zIndex: 0,
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.company == null && this.props.company != null) {
            let cp = deepCopyObj(this.props.company);
            let cts = deepCopyObj(this.props.contacts);
            this.setState({company: cp, contacts: cts});
        }
    }

    onOk = () => {
        if (this.state?.company != null) {
            this.props.onWrite(this.state.company, this.state.contacts ?? [], this.state.contactsDel);
        }
    };

    private onCompanyChange = (field: keyof SBillingCompanyInfoDTO, val: any) => {
        if (this.state.company != undefined) {
            let res = {...this.state.company, [field]: val};
            this.setState({company: res});
        }
    }

    render() {
        return (
            <Modal
                title={ this.props !== undefined ? "Редактирование " + "" : 'Добавление записи' }
                centered
                wrapClassName={MODAL_WRAP_CLASSNAME}
                width={950}
                //zIndex={this.props.zIndex}
                open={this.props.company != null}
                // footer={[
                //     <div style={{ display: 'flex', alignItems: 'baseline' }}>
                //         <Button key="1" type='primary' onClick={this.props.onHide} style={{marginLeft: 'Auto'}}>Отмена</Button>
                //         <Button key="2" type='primary' onClick={this.onOk}>{this.props.operation === undefined ? "Добавить" : "Изменить"}</Button>
                //     </div>
                // ]}
                onCancel={() => { this.props.onHide() }}
                onOk={this.onOk}
            >
                <div className={styles.FormWrapper}>
                    <div style={{ overflow: 'auto', height: '70vh', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ padding: '4px'}}>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <Input.Group>
                                    <Row key={'k2'} className={styles.FormRowWrapper}>
                                        <Col key='c1' span={8}>
                                            Наименование
                                        </Col>
                                        <Col span={16}>
                                            <Input value={this.state.company?.name} onChange={val => this.onCompanyChange('name', val.target.value)} size='small'/>
                                        </Col>
                                    </Row>
                                    <Row className={styles.FormRowWrapper}>
                                        <Col span={8}>
                                            Полное наименование
                                        </Col>
                                        <Col span={16}>
                                            <Input value={this.state.company?.fullName} onChange={val => this.onCompanyChange('fullName', val.target.value)} size='small' />
                                        </Col>
                                    </Row>
                                    <Row className={styles.FormRowWrapper}>
                                        <Col span={8}>
                                            ИНН
                                        </Col>
                                        <Col span={16}>
                                            <Input value={this.state.company?.inn} onChange={val => this.onCompanyChange('inn', val.target.value)} size='small' />
                                        </Col>
                                    </Row>
                                    <Row className={styles.FormRowWrapper}>
                                        <Col span={8}>
                                            Почтовый дарес
                                        </Col>
                                        <Col span={16}>
                                            <Input value={this.state.company?.postAddress} onChange={val => this.onCompanyChange('postAddress', val.target.value)} size='small' />
                                        </Col>
                                    </Row>
                                    <Row className={styles.FormRowWrapper}>
                                        <Col span={8}>
                                            Юридический адрес
                                        </Col>
                                        <Col span={16}>
                                            <Input value={this.state.company?.address} onChange={val => this.onCompanyChange('address', val.target.value)} size='small' />
                                        </Col>
                                    </Row>
                                    <Row className={styles.FormRowWrapper}>
                                        <Col span={8}>
                                            Примечание
                                        </Col>
                                        <Col span={16}>
                                            <Input.TextArea rows={5} value={this.state.company?.comment} onChange={val => this.onCompanyChange('comment', val.target.value)} size='small' />
                                        </Col>
                                    </Row>
                                </Input.Group>
                            </div>
                        </div>
                        <div style={{display: 'flex'}}>
                            <div style={{ padding: '4px'}}>
                                <ContactsTable
                                    scrollY='auto'
                                    contacts={this.state.contacts}
                                    onChangeContacts={(contacts) => {this.setState({contacts})}}
                                    companyId={this.state.company?.id ?? -1}
                                    onDeleteContact={(contactId) => {
                                        let i = -1;
                                        if (contactId >= 0) {
                                            let delArr = [...this.state.contactsDel];
                                            i = delArr.findIndex(x => x === contactId);
                                            if (i === -1) {
                                                delArr.push(contactId);
                                                this.setState({contactsDel: delArr});
                                            }
                                        }

                                        let arr = [...this.state?.contacts ?? []];
                                        i = arr.findIndex(x => x.id === contactId);
                                        if (i !== -1) {
                                            arr.splice(i, 1);
                                            this.setState({contacts: arr});
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </Modal>
        );
    }
}