import * as React from 'react';
import { TileLayer, Marker, Popup, Tooltip } from 'react-leaflet';

import MarkerClusterGroup2 from './MarkerClusterGroup2';

import * as L from 'leaflet';

import { Dropdown, Menu, Table } from 'antd';

import MarkerWithId from './MarkerWithId'
import { ClusteringFunctionTypeEnum, SMapLayer, SMapPoint, SPointValues, ValueStatusesEnum } from '../../store/MapDataStore';
import { PeriodsEnum, POINT_TABS } from '../../decl';
import MarkerHelper from './MarkerHelper'
import { posix } from 'path';


// import plugin's css (if present)
// note, that this is only one of possible ways to load css

import moment from 'moment';

// указываем путь к файлам marker
//L.Icon.Default.imagePath = "https://unpkg.com/leaflet@1.5.0/dist/images/";

// At runtime, Redux will merge together...
type DataLayersProps = {
    currentLayerCode: string;
    points: {[pointId: string] : SMapPoint};
    pointsData: {[pointId: string] : SPointValues} | null;
    layersColors: {[layerCode: string] : string};
    clusteringFunctionType: ClusteringFunctionTypeEnum | undefined;
    showContextMenu: (contextMenu: {position: [number, number], menu: Array<{title: string, action: () => void}>}) => void;
    normalDataDepth: number;
    period: PeriodsEnum;
    showPointInfo: (pointId: string, pointNumber: string, tabName?: string) => any;
    showPointsData: (pointIds: [], period: number, normalDataDepth: number) => any;
    showReportForm: (pointId: string, pointNumber: string) => any;
    allLayers: {[code: string]: SMapLayer} | undefined;
    gotoPage: any;
}

type StateType = {
    showKadrAbonentIds: string[];
    showKard: boolean,
    forceUpdateClusterGroups: number,
}

class LayerView extends React.Component<DataLayersProps, StateType> {
    public static readonly HASNS_LAYER_CODE = 'hasns';
    public static readonly STATE_LAYER_CODE = 'state';
    private mclsTimer: NodeJS.Timeout | null = null;
    private readonly mnClickDelay = 300;

    state = {
        showKard: false,
        showKadrAbonentIds: [],
        forceUpdateClusterGroups: Math.random(),
    }

    componentDidUpdate(prevProps: DataLayersProps) {
        if (prevProps.points !== this.props.points
            || prevProps.pointsData !== this.props.pointsData
            || prevProps.currentLayerCode !== this.props.currentLayerCode
            || prevProps.clusteringFunctionType !== this.props.clusteringFunctionType)
        {
            this.setState({forceUpdateClusterGroups: Math.random()});//При изменении key компонента мы обязываем react пресоздать этот компонент.
        }
    }

    private onClusterClick = (e: any) => {

        if (this.mclsTimer !== null) {
            clearTimeout(this.mclsTimer);
            this.mclsTimer = null;
        } else {
            this.mclsTimer = setTimeout(() => {
                this.mclsTimer = null;

                let cluster: L.MarkerCluster = e.layer;

                let ids = cluster.getAllChildMarkers().map(i => String((i.options as any).customId));
                this.props.showPointsData(ids as [], this.props.period, this.props.normalDataDepth);
                //this.setState({ showKadrAbonentIds: ids, showKard: true });

            }, this.mnClickDelay);
        }
    }

    private createCustomIcon = (pointKey: string) : L.Icon | L.DivIcon => {
        if (this.props.points === undefined) 
            return L.divIcon();

        let icon: L.Icon | L.DivIcon = L.divIcon();

        if (this.props.currentLayerCode === LayerView.STATE_LAYER_CODE) {//Связь

            if (this.props.points !== undefined) {
                
                let lastPollingTime: number | null = null;
                if (this.props.pointsData != undefined) {
                    lastPollingTime = this.props.pointsData[pointKey]?.lastPollingTime;
                } else {
                    const point = this.props.points[pointKey];
                    lastPollingTime = point?.lastPollingTime;
                }

                if (lastPollingTime != null) {
                    //Нормальная глубина в часах.
                    let normalDepth: number = this.props.normalDataDepth;
                    if (this.props.period === PeriodsEnum.current)
                        normalDepth /= 60;
                    else if (this.props.period === PeriodsEnum.day)
                        normalDepth *= 24;

                    if (lastPollingTime > normalDepth) {
                        icon = MarkerHelper.getMarker('red');
                    } else {
                        icon = MarkerHelper.getMarker('green'); 
                    }
                } else {
                    icon = MarkerHelper.getMarker('gray');
                }
            }

        } else if (this.props.currentLayerCode === LayerView.HASNS_LAYER_CODE) {
            let text: string = '';
            let isActive: boolean = false;

            if (this.props.pointsData != null){
                const newEventsCount = this.props.pointsData[pointKey]?.newEventsCount;
                if(newEventsCount && newEventsCount > 0){
                    text =  '';//newEventsCount > 99 ? '*' : newEventsCount.toString();
                    isActive = true;
                }
            }
            icon = MarkerHelper.getNsMarker(27, text, isActive);
        } else if (this.props.pointsData != null && this.props.allLayers?.[this.props.currentLayerCode] !== undefined 
            && this.props.allLayers?.[this.props.currentLayerCode].isDataLayer) {

            const value = this.props.pointsData[pointKey].values[this.props.currentLayerCode]?.value;
            const textColor = this.props.layersColors[this.props.currentLayerCode];

            if (this.props.pointsData[pointKey].values[this.props.currentLayerCode]?.status === undefined) {
                icon = MarkerHelper.getDataMarker('gray', value !== undefined ? String(Math.round(value)) : '-', textColor);
            } else if (this.props.pointsData[pointKey].values[this.props.currentLayerCode]?.status === ValueStatusesEnum.None 
                || this.props.pointsData[pointKey].values[this.props.currentLayerCode]?.status === ValueStatusesEnum.Good) {
                icon = MarkerHelper.getDataMarker('green', value !== undefined ? String(Math.round(value)) : '-', textColor);
            } else {
                icon = MarkerHelper.getDataMarker('red', value !== undefined ? String(Math.round(value)) : '-', textColor);
            }
            
        }

        return icon;
    }

    getClusterDataMarkerBorder = (currentLayerCode: string, pointId: string) => {
        let borderColor = '';
        if (this.props.pointsData?.[pointId].values[currentLayerCode].status === ValueStatusesEnum.Good
            || this.props.pointsData?.[pointId].values[currentLayerCode].status === ValueStatusesEnum.None) {
            borderColor = 'green-border'
        } else {
            borderColor = 'red-border'
        }

        return borderColor;
    }

    private getMax = (ids: string[], currentLayerCode: string): {res: string, borderColor: string} => {
        let max = null;
        let borderColor = 'gray-border';
        for (let id of ids) {
            if (this.props.pointsData?.[id] !== undefined && this.props.pointsData[id].values[currentLayerCode] !== undefined) {
                if (max === null || this.props.pointsData[id].values[currentLayerCode].value > max) {
                    max = this.props.pointsData[id].values[currentLayerCode].value;
                }
                
                borderColor = this.getClusterDataMarkerBorder(currentLayerCode, id);
            }
        };

        return {res: max === null ? '-' : String(Math.round(max)), borderColor}
    }

    private getMin = (ids: string[], currentLayerCode: string): {res: string, borderColor: string} => {
        let min = null;
        let borderColor = 'gray-border';
        for (let id of ids) {
            if (this.props.pointsData?.[id] !== undefined && this.props.pointsData[id].values[currentLayerCode] !== undefined) {
                if (min === null || this.props.pointsData[id].values[currentLayerCode].value < min) {
                    min = this.props.pointsData[id].values[currentLayerCode].value;
                }
                
                borderColor = this.getClusterDataMarkerBorder(currentLayerCode, id);
            }
        };

        return {res: min === null ? '-' : String(Math.round(min)), borderColor}
    }

    private getAvg = (ids: string[], currentLayerCode: string): {res: string, borderColor: string} => {
        let avg = null;
        let counter = 0;
        let borderColor = 'gray-border';
        for (let id of ids) {
            if (this.props.pointsData?.[id] !== undefined && this.props.pointsData[id].values[currentLayerCode] !== undefined) {
                if (avg === null) {
                    avg = this.props.pointsData[id].values[currentLayerCode].value;
                    counter += 1;
                } else {
                    avg += this.props.pointsData[id].values[currentLayerCode].value;
                    counter += 1;
                }
                
                borderColor = this.getClusterDataMarkerBorder(currentLayerCode, id);
            }
        };

        return {res: avg === null ? '-' : String(Math.round(avg / counter)), borderColor}
    }

    private createClusterCustomIcon = (cluster: L.MarkerCluster) : L.Icon | L.DivIcon | undefined => {
        if (this.props.currentLayerCode === undefined || this.props.points === undefined)
            return L.divIcon();

        let res = L.divIcon();
        let textColor = this.props.layersColors[this.props.currentLayerCode] ?? 'black';

        let ids = cluster.getAllChildMarkers().map(i => (i.options as any).customId as string);
        //console.log("ids", ids, cluster);
        let value: string = String(ids?.length) ?? '';
        let borderColor: string = 'gray-border';

        let size: number = Math.floor(54 -  (1000 - ids.length) * 27 / 1000);
        if(size < 27) size = 27;
        if(size > 54) size = 54;

        if (this.props.currentLayerCode === LayerView.STATE_LAYER_CODE) {//Связь

            res = MarkerHelper.getClusterMarker(size, value, textColor, borderColor);

        } else if (this.props.currentLayerCode === LayerView.HASNS_LAYER_CODE) {
            let isActive: boolean = false;
            for (let id of ids) {
                if (this.props.pointsData?.[id] !== undefined && this.props.pointsData[id].newEventsCount > 0) {
                    isActive = true;
                    break;
                }
            };

            res = MarkerHelper.getNsMarker(size, value, isActive);

        } else if (this.props.pointsData != null && this.props.allLayers?.[this.props.currentLayerCode] !== undefined 
            && this.props.allLayers?.[this.props.currentLayerCode].isDataLayer) {

            let funcRes;
            if (this.props.clusteringFunctionType === ClusteringFunctionTypeEnum.min) {
                funcRes = this.getMin(ids, this.props.currentLayerCode);
            } else if (this.props.clusteringFunctionType === ClusteringFunctionTypeEnum.avg) {
                funcRes = this.getAvg(ids, this.props.currentLayerCode);
            } else {
                funcRes = this.getMax(ids, this.props.currentLayerCode);
            }
            borderColor = funcRes.borderColor;
            value = funcRes.res;

            res = MarkerHelper.getClusterMarker(size, value, textColor, borderColor);
        }

        return res;
    }

    handleOk = () => {
        this.setState({ showKard: false });
    };

    handleCancel = () => {
        this.setState({ showKard: false });
    };

    render() {
        if (this.props.allLayers == undefined || (this.props.allLayers?.[this.props.currentLayerCode]?.isDataLayer === true && this.props.pointsData === null))
            return null
        else
            return (
                <MarkerClusterGroup2 key={this.state.forceUpdateClusterGroups} maxClusterRadius={50} iconCreateFunction={this.createClusterCustomIcon} showCoverageOnHover={false} zoomToBoundsOnClick={false} onClick={this.onClusterClick}>
                    {
                        Object.keys(this.props.points).map(key => {
                            return (
                                <MarkerWithId 
                                    eventHandlers={{
                                        contextmenu: (event: L.LeafletMouseEvent) => {
                                            this.props.showContextMenu({
                                                position: [event.containerPoint.x, event.containerPoint.y],
                                                menu: [
                                                    //{title: 'График', action: () => {}},
                                                    //{title: 'Мнемосхема', action: () => {}},
                                                    {title: 'Кадр', action: () => {this.props.gotoPage('/kadr')}},
                                                    {
                                                        title: 'Ведомость', action: () => {
                                                            this.props.showReportForm(this.props.points[key].id, this.props.points[key].persistProperties['number']);
                                                        }
                                                    }
                                                ]
                                            })
                                        },
                                        click: (event: L.LeafletMouseEvent) => {
                                            if (this.mclsTimer !== null) {
                                                clearTimeout(this.mclsTimer);
                                                this.mclsTimer = null;
                                            } else {
                                                this.mclsTimer = setTimeout(() => {
                                                    this.mclsTimer = null;
                                                    const point = this.props.points[key];
                                                    const data = this.props.pointsData ?  this.props.pointsData[point.id] : undefined;
                                                    if (this.props.currentLayerCode === LayerView.HASNS_LAYER_CODE && data && data.newEventsCount) {
                                                        this.props.showPointInfo(point.id, point.persistProperties['number'], POINT_TABS.EVENTS);
                                                    } else {
                                                        this.props.showPointInfo(point.id, point.persistProperties['number']);
                                                    }
                                                }, this.mnClickDelay);
                                            }
                                        },
                                        //dblclick: (event: L.LeafletMouseEvent) => { }
                                    }}
                                    
                                    icon={this.createCustomIcon(key)}
                                    customId={this.props.points[key].id} 
                                    key={key} 
                                    position={[this.props.points[key].latitude, this.props.points[key].longitude]}
                                >
                                    <Tooltip>{this.props.points[key].persistProperties['number']}</Tooltip>
                                </MarkerWithId>
                            )
                        })
                    }
                </MarkerClusterGroup2>
            )
    };
}

export default LayerView;