import { Action, Reducer } from 'redux';
import { message } from "../utils/Common";
import { AppThunkAction } from './';
import { PeriodsEnum, ICommand, Point, SPointTaskDTO } from '../decl';
import * as Const from '../utils/Const';
import { ClearMapCacheAction } from './MapDataStore';
import { ClearQueriesCacheAction } from './Tasks';

//import { history } from 'react-router';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export const isLoadingType = 'IS_LOADING';

export interface PageState {
    isLoading: boolean;
    isSessionOpen: boolean;
    closeSessionMessage: string | null;
    loadingRequestCount: number;
    loadingWaitCount: number;
    loadingMessage: string;
    isNetiReady: boolean;
    activeModal: JSX.Element | null;
    editPoint: Point | undefined;
    pointId: string;
    pointNumber: string;
    selectedPointsCount: number;
    windowWidth: number;
    windowHeight: number;
//    activeQueries: IActiveQuery[];
    //Временно выбранные ресурсы.
    resources: string | null;
    //Флаг отображения только точек учёта с контрактом.
    onlyWithContract: boolean;
    //Временно выбранные типы оплаты.
    paymentTypes: string | null;
    //Временно выбранные периоды оплаты.
    paymentPeriods: string | null;
    //Функция ухода со страницы, если возвращает false переход блокируется.
    beforeLeavePageFunctions: ((nextPage: string, go: () => void) => boolean)[];
    ////Выполняющиеся задачи опроса.
    //activeTasks: SPointTaskDTO[];
    //activeLogQueryItemId: string | undefined,
    //activeLogTaskId: number,
    pointLogs: Point[]
}


const InitState: PageState =
{
    isLoading: false,
    isSessionOpen: false,
    closeSessionMessage: null,
    loadingRequestCount: 0,
    loadingWaitCount: 0,
    loadingMessage: '',
    isNetiReady: false,
    activeModal: null,
    editPoint: undefined,
    pointId: '',
    pointNumber: '<Точка учёта не выбрана>',
    selectedPointsCount: 0,
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
//    activeQueries: [],
    resources: null,
    onlyWithContract: false,
    paymentTypes: null,
    paymentPeriods: null,
    beforeLeavePageFunctions: [],
    //activeTasks: [],
    //activeLogQueryItemId: undefined,
    //activeLogTaskId: 0,
    pointLogs: []
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
export interface EditPointAction { type: 'EDIT_POINT', point: Point | undefined }

export interface ShowModalAction { type: 'SHOW_MODAL', modal: JSX.Element | null}
export interface SetWaitIsLoadingAction { type: 'SET_WAIT_IS_LOADING' }
export interface SetIsNetiReadyAction { type: 'SET_IS_NETI_READY' }
export interface RequestIsLoadingAction { type: 'REQUEST_IS_LOADING', status: boolean, waitFlag: boolean, waitMessage: string}
export interface SetPointAction { type: 'SET_POINT', pointId: string, pointNumber: string }
export interface ShowPointsDataAction { type: 'SHOW_POINTS_DATA', pointIds: [] }
export interface OpenSessionAction { type: 'OPEN_SESSION' }
export interface CloseSessionAction { type: 'CLOSE_SESSION', message: string | null }
export interface ChangeWindowSizeAction { type: 'CHANGE_WINDOW_SIZE', width: number, height:number }
//export interface SetActiveQueriesAction { type: 'SET_ACTIVE_QUERIES', activeQueries: IActiveQuery[] }
//export interface ClearCacheAction { type: 'CLEAR_CACHE'}
export interface SetSelectedPointsCount { type: 'SET_SELECTED_POINTS_COUNT', count: number}
export interface FormReportAction { type: 'FORM_REPORT', pointId: string, from: Date, to: Date, period: PeriodsEnum }
//export interface ReadDataAction { type: 'READ_DATA', period: PeriodsEnum, pointIds: [] }
export interface SetResourcesAction { type: 'SET_RESOURCES', resources: string | null }
export interface SetPaymentTypesAction { type: 'SET_PAYMENT_TYPES', onlyWithContract: boolean, paymentTypes: string | null, paymentPeriods: string | null }
export interface SetBeforeLeavePageAction { type: 'SET_BEFORE_LEAVE_PAGE', beforeLeavePage: (nextPage: string, go: ()=>void) => boolean}
export interface RemoveBeforeLeavePageAction { type: 'REMOVE_BEFORE_LEAVE_PAGE', beforeLeavePage: (nextPage: string, go: ()=>void) => boolean}
export interface ClearBeforeLeavePageFunctionsAction { type: 'CLEAR_BEFORE_LEAVE_PAGE_FUNCTIONS' }
//export interface SetActiveTasks { type: 'SET_ACTIVE_TASKS', tasks: SPointTaskDTO[] }
//export interface RequestActiveTasks { type: 'REQUEST_ACTIVE_TASKS', isWait: boolean }
//export interface SetActiveLogTask { type: 'SET_ACTIVE_LOG_TASK', activeLogQueryItemId: string | undefined, activeLogTaskId: number }
export interface AddPointLog { type: 'ADD_POINT_LOG', point: Point }
export interface RemovePointLog { type: 'REMOVE_POINT_LOG', point: Point }


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = EditPointAction | ShowModalAction | SetWaitIsLoadingAction | RequestIsLoadingAction | SetPointAction | ShowPointsDataAction |
    OpenSessionAction | CloseSessionAction | ChangeWindowSizeAction | ClearMapCacheAction | ClearQueriesCacheAction | SetSelectedPointsCount |
    SetIsNetiReadyAction | FormReportAction | SetResourcesAction | SetPaymentTypesAction | SetBeforeLeavePageAction | RemoveBeforeLeavePageAction | ClearBeforeLeavePageFunctionsAction |
    AddPointLog | RemovePointLog;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

let _waitActiveTasks: boolean = false;

export const actionCreators = {
    openSession: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            dispatch({
                type: 'OPEN_SESSION'
            });
        }
    },
    clearCache: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            dispatch({
                type: 'CLEAR_MAP_CACHE'
            });
            dispatch({
                type: 'CLEAR_QUERIES_CACHE'
            });
        }
    },
    closeSession: (message: string | null): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            dispatch({
                type: 'CLOSE_SESSION',
                message: message
            });
        }

        //UserActionCreators.requestAllUserProfile(false, (profile: any) => {
        //    this.props.history.push('/');
        //    message.warning("Сессия завершена.");
        //});
    },
    showModal: (modal: JSX.Element | null): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            dispatch({ type: 'SHOW_MODAL', modal: modal });
        }
    },
    editPoint: (point: Point | undefined): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            dispatch({ type: 'EDIT_POINT', point: point });
        }
    },
    setIsLoading: (status: boolean, waitInterval?: number | undefined, waitMessage?: string | undefined): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            let m = 'Загрузка, подождите, пожалуйста, ...';
            if(waitMessage !== undefined && waitMessage !== null && waitMessage != '') m = waitMessage;
            if(waitInterval && waitInterval > 0){
                dispatch({ type: 'SET_WAIT_IS_LOADING'});
                setTimeout(()=> {dispatch({ type: 'REQUEST_IS_LOADING', status: status, waitFlag: true, waitMessage: m});}, waitInterval);
            }
            else{
                dispatch({ type: 'REQUEST_IS_LOADING', status: status, waitFlag: false, waitMessage: m});
            }
        }
    },
    setIsNetiReady: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            dispatch({ type: 'SET_IS_NETI_READY'});
        }
    },
    setPoint: (pointId: string, pointNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            window.call_execute_withcreate(
                null,
                "setPoint",
                {id: pointId, number: pointNumber},
                ()=>{
                },
                ()=>{
                });
            dispatch({ type: 'SET_POINT', pointId: pointId, pointNumber: pointNumber});
        }
    },
    showPointInfo: (pointId: string, pointNumber: string, source: any, parentNode: any, tabName?: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            if (appState.userProfile?.allPoints[pointId].isLocked) {
                message.warning("Точка учёта заблокирована - нет оплаты.");
            }
            else {
                dispatch({ type: 'REQUEST_IS_LOADING', status: true, waitFlag: false, waitMessage: 'Загрузка, подождите, пожалуйста, ...' });
                window.call_execute({ Source: source, Name: Const.REGISTER_NETIROOT_COMMAND });
                window.call_execute_withcreate(
                    source,
                    "infoShowFromModal",
                    { id: pointId, number: pointNumber, parent: parentNode, tabName: tabName },
                    () => {
                        dispatch({ type: 'REQUEST_IS_LOADING', status: false, waitFlag: false, waitMessage: '' });
                    },
                    () => {
                        dispatch({ type: 'REQUEST_IS_LOADING', status: false, waitFlag: false, waitMessage: '' });
                    });
                dispatch({ type: 'SET_POINT', pointId: pointId, pointNumber: pointNumber });
            }
        }
    },
    showPointsData: (pointIds: [], period: PeriodsEnum, normalDataDepth: number, source: any, parentNode: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            dispatch({ type: 'REQUEST_IS_LOADING', status: true, waitFlag: false, waitMessage: 'Загрузка, подождите, пожалуйста, ...' });
            window.call_execute({ Source: source, Name: Const.REGISTER_NETIROOT_COMMAND });
            let dataDepth;
            let columnsLayout;
            switch(period){
                case PeriodsEnum.current:
                    columnsLayout = appState.userProfile?.profile?.Kadr?.CurrentColumnsLayout;
                    dataDepth = appState.userProfile?.profile?.Kadr?.CurrentDataDepth;
                    break;
                case PeriodsEnum.hour:
                    columnsLayout = appState.userProfile?.profile?.Kadr?.HourColumnsLayout;
                    dataDepth = appState.userProfile?.profile?.Kadr?.HourDataDepth;
                    break;
                case PeriodsEnum.day:
                    columnsLayout = appState.userProfile?.profile?.Kadr?.DayColumnsLayout;
                    dataDepth = appState.userProfile?.profile?.Kadr?.DayDataDepth;
                    break;
            }

            window.call_execute_withcreate(
                source,
                "kadrShow",
                { ids: pointIds, period: period, normalDataDepth: normalDataDepth, dataDepth: dataDepth, parent: parentNode, columnsLayout: columnsLayout },
                () => {
                    dispatch({ type: 'REQUEST_IS_LOADING', status: false, waitFlag: false, waitMessage: 'Загрузка, подождите, пожалуйста, ...' });
                },
                () => {
                    dispatch({ type: 'REQUEST_IS_LOADING', status: false, waitFlag: false, waitMessage: 'Загрузка, подождите, пожалуйста, ...' });
                });
            dispatch({ type: 'SHOW_POINTS_DATA', pointIds: pointIds });
        }
    },
    showReportForm: (pointId: string, pointNumber: string, source: any, parentNode: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            dispatch({ type: 'REQUEST_IS_LOADING', status: true, waitFlag: false, waitMessage: '' });
            window.call_execute({ Source: source, Name: Const.REGISTER_NETIROOT_COMMAND });
            window.call_execute_withcreate(
                source,
                "ReportDialogShow",
                { id: pointId, number: pointNumber, parent: parentNode },
                () => {
                    dispatch({ type: 'REQUEST_IS_LOADING', status: false, waitFlag: false, waitMessage: 'Загрузка, подождите, пожалуйста, ...' });
                },
                () => {
                    dispatch({ type: 'REQUEST_IS_LOADING', status: false, waitFlag: false, waitMessage: 'Загрузка, подождите, пожалуйста, ...' });
                });
            dispatch({ type: 'SET_POINT', pointId: pointId, pointNumber: pointNumber });
        }
    },
    changeWindowSize: (width: number, height: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            dispatch({ type: 'CHANGE_WINDOW_SIZE', width: width, height: height });
        }
    },
    //setActiveQueries: (activeQueries: IActiveQuery[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
    //    const appState = getState();
    //    if (appState) {
    //        dispatch({ type: 'SET_ACTIVE_QUERIES', activeQueries: activeQueries});
    //    }
    //},
    setSelectedPointsCount: (count: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            dispatch({ type: 'SET_SELECTED_POINTS_COUNT', count: count});
        }
    },
    formReport: (pointId: string, from: Date, to: Date, period: PeriodsEnum): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            message.success("Запрос на формирование ведомости отправлен.");

            dispatch({ type: 'REQUEST_IS_LOADING', status: true, waitFlag: false, waitMessage: 'Загрузка, подождите, пожалуйста, ...' });

            //!!!!! Код на сервере для печати отчётов принимает локальную дату (число миллисекунд после 01.01.1970).
            const localFrom = from.getTime() - from.getTimezoneOffset() * 60 * 1000; 
            const localTo = to.getTime() - to.getTimezoneOffset() * 60 * 1000; 
            const request: any = new (window.sc_reportcreaterequest as any)(pointId, null, 0, period, localFrom, localTo);
            window.call_execute_withcreate(
                null,
                'reportdownload',
                request,
                () => {
                    message.success("Ведомость сформирована.");
                    dispatch({ type: 'REQUEST_IS_LOADING', status: false, waitFlag: false, waitMessage: '' });
                },
                () => {
                    message.error("Ошибка при формировании ведомости.");
                    dispatch({ type: 'REQUEST_IS_LOADING', status: false, waitFlag: false, waitMessage: '' });
                });

            dispatch({ type: 'FORM_REPORT', pointId, from, to, period});
        }
    },
    //Отправка запроса на чтение данных.
    //  period   - период (текущие, часовые, суточные).
    //  pointIds - массив идентификаторов точек учёта.
    //  timeout - таймаут в секундах или 0, если не задан.
    //  settingsRead - сбор настроек перед чтением данных.
    //  callback(code, pointIds)  - вызывается и при удачном и при неудачном выполнения операции.
    //      code:
    //          0 - запрос на сбор данных принят.
    //          1 - часть запросов выполнено. PointIds содержит идентификаторы новых выполненых запросов.
    //          2 - все запросы выполнены. PointIds содержит идентификаторы выполненых запросов.
    //          3 - часть запросов выполнить не удалось. PointIds содержит идентификаторы новых невыполненых запросов.
    //          4 - все запросы завершены, часть запросов выполнить не удалось. PointIds содержит идентификаторы невыполненых запросов.
    //  exCallback(message) - вызывается, если возникли системные проблемы.
    //      message: 'Запрос на чтение данных с прибора не принят.'
    //              'Ошибка при отправке запроса на чтение данных с прибора.'
    readData: (period: PeriodsEnum, pointIds: string[], timeout: number, settingsRead: boolean, callback: any, exCallback: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            const cmd: ICommand = {
                Source: null,
                Name: 'ReadData',
                Params: {Period: period, PointIds: pointIds, Timeout: timeout, SettingsRead: settingsRead},
                Callback: callback,
                ExceptionCallback: exCallback
            };
            window.call_execute(cmd);
        }
    },
    setResources: (resources: string | null): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            dispatch({ type: 'SET_RESOURCES', resources });
        }
    },
    setPaymentTypes: (onlyWithContract: boolean, paymentTypes: string | null, paymentPeriods: string | null): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            dispatch({ type: 'SET_PAYMENT_TYPES', onlyWithContract, paymentTypes, paymentPeriods });
        }
    },
    setBeforeLeavePage: (beforeLeavePage: (nextPage: string, go: ()=>void) => boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            dispatch({ type: 'SET_BEFORE_LEAVE_PAGE', beforeLeavePage: beforeLeavePage });
        }
    },
    removeBeforeLeavePage: (beforeLeavePage: (nextPage: string, go: ()=>void) => boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            dispatch({ type: 'REMOVE_BEFORE_LEAVE_PAGE', beforeLeavePage: beforeLeavePage });
        }
    },
    clearBeforeLeavePageFunctions: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            dispatch({ type: 'CLEAR_BEFORE_LEAVE_PAGE_FUNCTIONS'});
        }
    },
    ////Получение активных задач.
    //getActiveTasks: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    //    //console.log('requestActiveTasks');
    //    const appState = getState();
    //    if (appState && !_waitActiveTasks) {
    //        _waitActiveTasks = true;
    //        sendRequestToBackend(
    //            null,
    //            'admin/getactivetasks',
    //            (response: SPointTaskDTO[]) => {
    //                _waitActiveTasks = false;
    //                //if (!compareObj(appState.page.activeTasks, response)) {
    //                    console.log("setActiveTasks");
    //                    dispatch({
    //                        type: 'SET_ACTIVE_TASKS',
    //                        tasks: response
    //                    });
    //                //}
    //            },
    //            null,
    //            () => {
    //                _waitActiveTasks = false;
    //                //dispatch({ type: 'REQUEST_ACTIVE_TASKS', isWait: false });
    //            },
    //            (message: string | null) => {
    //                _waitActiveTasks = false;
    //                //dispatch({ type: 'REQUEST_ACTIVE_TASKS', isWait: false });
    //                dispatch({ type: 'CLOSE_SESSION', message: message })
    //            },
    //            undefined,
    //            true
    //        );
    //        //dispatch({ type: 'REQUEST_ACTIVE_TASKS', isWait: true });
    //    }
    //},
    ////Установка задачи для открытия онлайн лога.
    //setActiveLogTask: (queryItemId: string | undefined, taskId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
    //    const appState = getState();
    //    if (appState) {
    //        dispatch({ type: 'SET_ACTIVE_LOG_TASK', activeLogQueryItemId: queryItemId, activeLogTaskId: taskId });
    //    }
    //},
    addPointLog: (point: Point): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            dispatch({ type: 'ADD_POINT_LOG', point: point });
        }
    },
    removePointLog: (point: Point): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            dispatch({ type: 'REMOVE_POINT_LOG', point: point });
        }
    },

};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<PageState> = (state: PageState | undefined, incomingAction: Action): PageState => {
    if (state === undefined) {
        return InitState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'EDIT_POINT':
            return {
                ...state,
                editPoint: action.point,
            };
        case 'SHOW_MODAL':
            return {
                ...state,
                activeModal: action.modal,
            };
        case 'SET_IS_NETI_READY':
            return {
                ...state,
                isNetiReady: true
            };
            case 'SET_WAIT_IS_LOADING':
                //Увеличить счётчик задерженных запросов.
                return {
                    ...state,
                    loadingWaitCount: state.loadingWaitCount + 1
                };
            case 'REQUEST_IS_LOADING':
            //Если задан флаг ожидания, уменьшить счётчик задержанных запросов. 
            let loadingWaitCount: number =  action.waitFlag ?  state.loadingWaitCount - 1 : state.loadingWaitCount;

            //Изменить счётчик запросов на заставку.
            let loadingRequestCount: number = action.status ? state.loadingRequestCount + 1 : state.loadingRequestCount -1;

            //Флаг загрузки.
            let isLoading: boolean;

            //Если нет задерженных запросов и нет запросов на заставку, то сбросить флаг загрузки и привести в исходное состояние
            //счётчики задержанных запрососв и запросов на заставку.
            if(loadingWaitCount <= 0 && loadingRequestCount <= 0){
                loadingWaitCount = 0;
                loadingRequestCount =0;
                isLoading = false;
            }
            else{
                isLoading = true;
            }

            return {
                ...state,
                loadingWaitCount: loadingWaitCount,
                isLoading: isLoading,
                loadingRequestCount: loadingRequestCount,
                loadingMessage: action.waitMessage
            };
        case 'SET_POINT':
            return {
                ...state,
                pointId: action.pointId,
                pointNumber: action.pointNumber
            };
        case 'SHOW_POINTS_DATA':
            return {
                ...state
            };
        case 'OPEN_SESSION':
            //console.log('Open session');
            return {
                ...state,
                isSessionOpen: true,
                closeSessionMessage: null
            };
        case 'CLOSE_SESSION':
            //console.log('Close session');
            return {
                ...state,
                isSessionOpen: false,
                closeSessionMessage: action.message
            };
        case 'CHANGE_WINDOW_SIZE':
            return {
                ...state,
                windowWidth: action.width,
                windowHeight: action.height
            };
        //case 'SET_ACTIVE_QUERIES':
        //    return {
        //        ...state,
        //        activeQueries: action.activeQueries
        //    };
        //case 'CLEAR_CACHE':
        //    return {
        //        ...state,
        //        activeQueries: []
        //    };
        case 'SET_SELECTED_POINTS_COUNT':
            return {
                ...state,
                selectedPointsCount: action.count
            };
        case 'FORM_REPORT':
            return {
                ...state,
            };
        case 'SET_RESOURCES':
            return {
                ...state,
                resources: action.resources
            };
        case 'SET_PAYMENT_TYPES':
            return {
                ...state,
                onlyWithContract: action.onlyWithContract,
                paymentTypes: action.paymentTypes,
                paymentPeriods: action.paymentPeriods
            };
        case 'SET_BEFORE_LEAVE_PAGE':
        {
            const newArray:  ((nextPage: string, go: ()=>void) => boolean)[] = [...state.beforeLeavePageFunctions];
            const existFunction = newArray.find(f => f=== action.beforeLeavePage);
            if(existFunction === undefined){
                newArray.push(action.beforeLeavePage);
            }

            return {
                ...state,
                beforeLeavePageFunctions: newArray
            };
        }
        case 'REMOVE_BEFORE_LEAVE_PAGE':
            {
                const newArray:  ((nextPage: string, go: ()=>void) => boolean)[] = [];
                state.beforeLeavePageFunctions.forEach(f=>{if(f !== action.beforeLeavePage)newArray.push(f)});
                return {
                    ...state,
                    beforeLeavePageFunctions: newArray
                };
            }
        case 'CLEAR_BEFORE_LEAVE_PAGE_FUNCTIONS':
            {
                const newArray:  ((nextPage: string) => boolean)[] = [];
                return {
                    ...state,
                    beforeLeavePageFunctions: newArray
                };
            }
        case 'ADD_POINT_LOG':
            {
                if (state.pointLogs.find(p => p.id === action.point.id) === undefined) {
                    const pointLogs: Point[] = [ ...state.pointLogs ];
                    pointLogs.push(action.point)
                    return {
                        ...state,
                        pointLogs: pointLogs
                    };
                }
                else {
                    return state;
                }
            }
        case 'REMOVE_POINT_LOG':
            {
                if (state.pointLogs.find(p => p.id === action.point.id) !== undefined) {
                    const pointLogs: Point[] = state.pointLogs.filter(p => p.id !== action.point.id);
                    return {
                        ...state,
                        pointLogs: pointLogs
                    };
                }
                else {
                    return state;
                }
            }
        default:
        return state;

    }
};
