import * as React from 'react';
import { Marker } from 'react-leaflet';

class MarkerWithId extends React.Component{
    render() {
        return (
            <Marker {...this.props}>
                {this.props.children}
            </Marker>
        )
    };
}

export default MarkerWithId;