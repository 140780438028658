import * as React from 'react';
import ReactResizeDetector from 'react-resize-detector';

import styles from '../resources/NetiContainer.module.less';
import * as Const from '../utils/Const';
import {netiExecute} from '../utils/Common';
import { BaseProfile, ICommand, RequestContext } from '../decl';


//interface Window {
//    call_contentget(id: any, c: any, e: any): void;
//}

interface Proxy {
    controlget(c:any): void;
}


interface INetiProps{
    context: RequestContext | null,
    controlId: number,
    needRefresh?: boolean,
    commandToExecute?: ICommand | null,
    onReset(control: any): void,
    onCommand(cmd:any):any,
    onExternalCommand(cmd: any): any,
    userProfile: any,
    setProfile(group: string, values: any): any,
    setIsLoading(status: boolean, waitInterval?: number): void,
    setActiveQueries(activeQueries: []): void,
    isNetiReady: boolean
}

interface INetiState {
    html: string,
    settings: any,
    control:any
}


export default class NetiContainer extends React.Component<INetiProps, INetiState> {
	_isMounted: boolean;
    constructor(props: INetiProps) {
        super(props);
        this.myRef = React.createRef();

        this.state = {
            html: '',
            control: null,
            settings: null 
        }

        this.getContent = this.getContent.bind(this);
        this.receiveContent = this.receiveContent.bind(this);
        this.notReceiveContent = this.notReceiveContent.bind(this);
        this.execute = this.execute.bind(this);
        this.afterChangeHtml = this.afterChangeHtml.bind(this);
        this.onResize = this.onResize.bind(this);
		this._isMounted = false;
    }

    myRef: any;

    componentDidMount() {
		this._isMounted = true;
        //this.props.loadComponent();
        const element = this.myRef.current;
        //element.addEventListener('resize', (event: any) =>{
        //    console.log(event.detail);
        //    this.resize();
        //});
        if(this.props.isNetiReady){
            this.getContent();
        }
    }

    componentWillUnmount() {
		this._isMounted = false;
        const control = this.state.control;
        if(control != null){
            window.call_controldispose(control);
            window.call_execute({Source: null, Name: Const.REGISTER_NETIROOT_COMMAND});
        }
    }
    
    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
        if(this.props.isNetiReady && !prevProps.isNetiReady){
            this.getContent();
        }
        else if(this.props.isNetiReady){
            if(this.state.control && this.props.needRefresh && !prevProps.needRefresh){
                this.props.onReset(this.state.control);
            }
            if(this.state.control && this.props.commandToExecute != null && prevProps.commandToExecute == null){
                const cmd = {...this.props.commandToExecute, Source: this.state.control};
                if(!cmd.Callback){
                    cmd.Callback = this.execute;
                }
                this.props.onExternalCommand(cmd);
            }
        }
    }

    onResize() {
        const control = this.state.control;
        if(control != null){
            const parent = this.myRef.current;
            setTimeout(()=>window.call_controlresize(parent, control), 10);
        }
    }
    getContent() {
        if (this.state.control == null) {
            this.props.setIsLoading(true);
            window.call_contentchange(this.props.context, this.props.controlId, this.receiveContent, this.notReceiveContent);
        }
    }

    receiveContent(response: any) {
        if(this._isMounted){
            this.setState({ ...this.state, html: response.ControlHTML, settings: response.ClientSettings }, () => {
                this.afterChangeHtml();
            });
        }
    }

    afterChangeHtml() {
        const parent = this.myRef.current;
        const el = parent.firstChild;
        const control = window.call_controlcreate(el, this.state.settings, this.execute);
        window.call_execute({Source: control, Name: Const.REGISTER_NETIROOT_COMMAND});
        window.call_controlresize(parent, control);
        this.props.setIsLoading(false, 1000);
        this.props.onReset(control);
        this.setState({ ...this.state, control: control });
    }

    execute(cmd: ICommand) {
        const control = this.state.control;
        if (cmd.Name === Const.INVALIDATE_COMMAND && control && control.invalidate){
            control.invalidate();
        }
        else if (cmd.Name === Const.REFRESH_COMMAND && this.props.onReset) {
            this.props.onReset(control);
        }
        else{
            netiExecute(cmd, this.props.userProfile, this.props.setProfile, this.props.setIsLoading, this.props.setActiveQueries, this.props.onCommand, null);
        }
    }

    notReceiveContent() {
        this.props.setIsLoading(false);
        this.props.onReset(null);
    }

    public render() {
        const html: string = this.state.html;
        //this.resize();
        return (
            <React.Fragment >
                <div className={styles.container} dangerouslySetInnerHTML={{ __html: html }} ref={this.myRef} />
                <ReactResizeDetector handleWidth={true} handleHeight={true} onResize={this.onResize} refreshMode='debounce' refreshRate={500}>
                </ReactResizeDetector>
            </React.Fragment>
        );
    }
};

