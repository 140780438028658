import { Button, Checkbox, Col, Form, FormInstance, Input, Modal, Row, Select, Space } from 'antd';
import * as React from 'react';
import { MODAL_WRAP_CLASSNAME } from '../utils/Const';
import { getModalZIndex } from '../utils/Common';

//форма изменения пароля
const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

type Props = {
    disabled: boolean,
    changeEmail: (email: string) => any,
}

export default class ChangeEmailForm extends React.Component<Props> {
    state = { 
        visible: false,
        zIndex: 0
    };

    formRef = React.createRef<FormInstance>();

    showModal = () => {
        this.formRef.current?.resetFields();
        this.setState({
            visible: true,
            zIndex: getModalZIndex()
        });
    };

    hideModal = () => {
        this.setState({
            visible: false,
        });
    };

    onOk = () => {
        this.formRef.current?.submit();
    };

    onFinish = (values: any) => {
        this.props.changeEmail(values['email'])
        this.hideModal();
    };

    render() {
        return (
            <>
                <Button disabled={this.props.disabled} type="primary" onClick={this.showModal}>
                    Изменить
                </Button>
                <Modal
                    title="Смена электронной почты"
                    wrapClassName={MODAL_WRAP_CLASSNAME}
                    zIndex={this.state.zIndex}
                    open={this.state.visible}
                    onOk={this.onOk}
                    onCancel={this.hideModal}
                    okText="Изменить"
                    cancelText="Отмена"
                >
                    <Form
                        ref={this.formRef}
                        {...layout}
                        onFinish={this.onFinish}
                    >

                        <Form.Item
                            name="email"
                            label="E-mail"
                            rules={[
                                {
                                    type: 'email',
                                    message: 'Неверный адрес электронной почты',
                                },
                                {
                                    required: true,
                                    message: 'Введите адрес электронной почты',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                    </Form>

                </Modal>
            </>
        );
    }
}