import * as React from 'react';

import styles from '../../resources/Page.module.less';
import { Button, Modal, Select, Input, Row, Col, Table, Tag, DatePicker, Tabs, Tooltip, Space, Checkbox, Switch, Divider } from 'antd';

import { arrayToString, convertBase64ToBinary, deepCopyObj, getModalZIndex, message, saveFile, toDate, toDateString } from '../../utils/Common';
import { ALL_GROUP_ID, MODAL_WRAP_CLASSNAME } from '../../utils/Const';
import { SBilContactDTO, SBilContractDTO, SBillingCompanyInfoDTO, SBillingTariffDTO, SContractPointDTO } from './BillingDecls';
import moment from 'moment';
import { CheckCircleTwoTone, DeleteTwoTone, EditTwoTone, ScheduleTwoTone, StopTwoTone } from '@ant-design/icons';
import * as Const from '../../utils/Const';
import Search from 'antd/lib/input/Search';
import CompanyEditForm from './CompanyEditForm';
import { sendRequestToBackend } from '../../utils/AuthUtils';
import TariffEditForm from './TariffEditForm';
import { Point, Resource } from '../../decl';
import SimpleTreeSelect from '../Helper/SimpleTreeSelect';
import { debug } from 'console';
import { Message } from '@material-ui/icons';

const { Option } = Select;

type Props = {
    onHide(): void,
    visible: boolean,
    setIsLoading: any,
    showPointInfo: (pointId: string, pointNumber: string) => void,
    allPoints: {[pointId: string] : Point},
    resources: Resource[],
    onOk: () => void,

    contracts: SBilContractDTO[],
    contractsPoints: SContractPointDTO[],
    tariffs: SBillingTariffDTO[],
    companies: SBillingCompanyInfoDTO[],
}

type State = {
    selectedRowKeys: any,
    filterResourceIds: string[] | null,
    filterTariffds: string[] | null,
    filterActivity: string[] | null,
    filter: string,
    filterPrice: string[] | null,
    //нужно что-бы сортировка не сохранялась между открытиями окна
    tableKey: any,
}

export default class ActiveContractsForm extends React.Component<Props, State> {
    constructor(props: Props, state: State) {
        super(props);

        this.state = {
            selectedRowKeys: undefined,
            filterResourceIds: null,
            filterTariffds: null,
            filterActivity: null,
            filterPrice: null,
            filter: '',
            tableKey: Math.random(),
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps.visible === false && this.props.visible === true) {
            this.init();
        }

        if (this.props.allPoints != undefined && this.props.visible === true && prevProps.contractsPoints !== this.props.contractsPoints) {
            this.makeDatasource(this.props.contracts, this.state.filter, this.state.filterResourceIds, this.state.filterActivity, this.state.filterTariffds, this.state.filterPrice);
        }
    }

    init = () => {
        this.makeDatasource(this.props.contracts, '', null, null, null, null);
        this.setState({
            selectedRowKeys: this.props.contractsPoints.filter(x => x.isActive === true).map(x => x.contractId + '_' + x.pointId),
            filterPrice: null, filter: '', filterActivity: null, filterResourceIds: null, filterTariffds: null,
            tableKey: Math.random(),
        });
    }

    sorter = (a: any, b: any) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b);

    columns = [
        {
            dataIndex: "contractNumber",
            title: "Номер договора",
            sorter: (a: any, b: any) => this.sorter(a.contractNumber, b.contractNumber),
        },
        {
            dataIndex: "contractDate",
            title: "Дата",
            width: 85,
            render: (val: any, record: any, index: any) => val == undefined ? null : (toDateString(new Date(val))),
            sorter: (a: any, b: any) => this.sorter(a.contractDate, b.contractDate),
            defaultSortOrder: 'descend' as 'descend',
        },
        {
            dataIndex: "contractUser",
            width: 140,
            title: "Пользователь",
            sorter: (a: any, b: any) => this.sorter(a.contractUser, b.contractUser),
        },
        {
            dataIndex: "contractCompanyName",
            title: "Контрагент",
            sorter: (a: any, b: any) => this.sorter(a.contractCompanyName, b.contractCompanyName),
        },
        {
            dataIndex: "info",
            title: "Инфо",
            width: 70,
            align: 'center' as 'center',
            render: (text: any, record: any) => 
                text == undefined ? null : <Button onClick={() => this.props.showPointInfo(record.info, record.number)} size="middle" shape="circle" icon={<ScheduleTwoTone twoToneColor={Const.COLORS.SvgIconColor} />} />
        },
        {
            dataIndex: "number",
            sorter: (a: any, b: any) => this.sorter(a.number, b.number),
            title: "Наименование",
        },
        {
            dataIndex: "address",
            sorter: (a: any, b: any) => this.sorter(a.address, b.address),
            title: "Адрес",
        },
        {
            dataIndex: "tariff",
            title: "Тариф",
            width: 90,
            sorter: (a: any, b: any) => this.sorter(a.tariff, b.tariff),
            render: (val: any, record: any, index: any) => val == undefined ? null : this.props.tariffs?.find(x => x.id === val)?.name,
        },
        {
            dataIndex: "price",
            title: "Аб. плата",
            width: 80,
        },
        {
            dataIndex: "isActive",
            title: "Активен",
            width: 80,
            render: (val: any, record: any, index: any) => {
                if (val === true) {
                    return <CheckCircleTwoTone style={{ fontSize: '20px'}} twoToneColor={Const.COLORS.SvgIconColor}/>
                } else {
                    return <StopTwoTone style={{ fontSize: '20px'}} twoToneColor="#8c8c8c" />
                }
            },
            align: 'center' as 'center',
            sorter: (a: any, b: any) => this.sorter(a.isActive, b.isActive),
        },
    ];

    private dataSource: any = undefined;

    makeDatasource = (contracts: SBilContractDTO[], filter: string, filterResourcesIds: string[] | null, filterActivityIds: string[] | null, filterTariffsIds: string[] | null, filterPrice: string[] | null) => {

        let ds: any[] = [];
        contracts.forEach(x => {
            ds = ds.concat(this.makeChildren(x, filter, filterResourcesIds, filterActivityIds, filterTariffsIds, filterPrice))
        })

        this.dataSource = ds;

        this.forceUpdate();
    }

    makeChildren(c: SBilContractDTO, filter: string, filterResourcesIds: string[] | null, filterActivity: string[] | null, filterTariffsIds: string[] | null, filterPrice: string[] | null) {
        let res: any[] = [];

        let cps = [...this.props.contractsPoints];

        if (filterResourcesIds != null) {
            cps = cps.filter((x: any) => {
                const p = this.props.allPoints[x.pointId];
                return filterResourcesIds.includes(String(p.resourceTypeId));
            });
        }

        if (filterActivity != null) {
            cps = cps.filter(x => filterActivity.includes(String(x.isActive === false ? 0 : 1)));
        }

        if (filterTariffsIds != null) {
            cps = cps.filter((x: any) => filterTariffsIds.includes(String(x.tariffId)));
        }

        if (filterPrice != null) {
            cps = cps.filter((x: any) => filterPrice.includes(String(x.price)));
        }

        if (filter != '') {
            cps = cps.filter((x: any) => {
                const p = this.props.allPoints[x.pointId];

                return p.number.toLowerCase().includes(filter.toLowerCase()) || p.persistProperties['address']?.toLowerCase().includes(filter.toLowerCase())
                    || c.number.toLowerCase().includes(filter.toLowerCase()) || c.userName.toLowerCase().includes(filter.toLowerCase()) 
                    || (this.props.companies?.find(y => y.id === c.companyId)?.name.toLowerCase().includes(filter) ?? true)
            });
        }

        res = cps.filter(x => x.contractId === c.id).map(x => {
            return ({
                key: x.contractId + '_' + x.pointId,
                pointId: x.pointId,
                contractId: c.id,
                contractNumber: c.number,
                contractDate: c.date,
                contractUser: c.userName,
                contractCompanyName: this.props.companies?.find(y => y.id === c.companyId)?.name,
                id: x.pointId,
                info: x.pointId,
                number: this.props.allPoints[x.pointId].number,
                address: this.props.allPoints[x.pointId].persistProperties['address'],
                tariff: x.tariffId,
                price: x.price,
                isActive: x.isActive,
            })
        })

        return res;
    }

    onSave = (close: boolean) => {
        let flag = false;
        for (const j of this.dataSource) {
            if (j.isActive === false && this.state.selectedRowKeys.includes(j.contractId + '_' + j.pointId)
                || (j.isActive === true && !this.state.selectedRowKeys.includes(j.contractId + '_' + j.pointId))) 
            {
                flag = true;
                break;
            }
        }

        if (flag === false) {
            if (close === true) {
                this.props.onHide();
            }
        } else {
            Modal.confirm({
                title: 'Запрос',
                wrapClassName: Const.MODAL_WRAP_CLASSNAME,
                zIndex: getModalZIndex(),
                content: 'Сохранить изменения?',
                width: 600,
                okText: 'Да',
                cancelText: 'Нет',
                onOk: () => {
                    this.onSave2();
                    if (close === true) {
                        this.props.onHide();
                    }
                },
                onCancel: () => {
                    this.init();
                    if (close === true) {
                        this.props.onHide();
                    }
                }
            });
        }

    }

    onSave2 = () => {
        let arr: SContractPointDTO[] = [];

        for (const i of this.dataSource) {
            let point = this.props.contractsPoints.find(x => x.pointId === i.id && x.contractId === i.contractId);
            if (point != undefined) {
                if ((point.isActive === false && this.state.selectedRowKeys.includes(point.contractId + '_' + point.pointId))
                    || (point.isActive === true && !this.state.selectedRowKeys.includes(point.contractId + '_' + point.pointId)))
                {
                    let tmp: SContractPointDTO = deepCopyObj(point);
                    tmp.isActive = !tmp.isActive;
                    arr.push(tmp);
                }
            }
        }

        sendRequestToBackend(
            arr,
            'billing/contractspointswrite',
            (response: string) => {
                if (response != null) {
                    message.success('Успешно');
                    this.props.onOk();
                } else {
                    message.error('Запрос завершился неудачей');
                }
            },
            this.props.setIsLoading,
            (error: any) => {
                console.log(error);
                message.error(error);
            }
        );
    }

    render() {
        return (
            <Modal
                title={ 'Активен' }
                wrapClassName={MODAL_WRAP_CLASSNAME}
                width={1300}
                //zIndex={this.props.zIndex}
                open={this.props.visible}
                cancelText='Закрыть'
                okText='OK'
                cancelButtonProps={{ style: { display: 'none' } }}
                style={{top: '80px'}}
                // footer={[
                //     <div style={{ display: 'flex', alignItems: 'baseline' }}>
                //         <Button key="1" type='primary' onClick={this.props.onHide} style={{marginLeft: 'Auto'}}>Отмена</Button>
                //         <Button key="2" type='primary' onClick={this.onOk}>{this.props.operation === undefined ? "Добавить" : "Изменить"}</Button>
                //     </div>
                // ]}
                onOk={() => this.onSave(true)}
                onCancel={() => this.props.onHide()}
            >
                <div className={styles.FormWrapper}>
                    <div style={{ overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ padding: '4px'}}>
                            <Space style={{marginBottom: '6px'}}>
                                <SimpleTreeSelect
                                    key={'st3'}
                                    items={[{name: 'Активные', shortName: 'Активные', id: 1}, {name: 'Не активные', shortName: 'Не активные', id: 0}].map(x => ({name: x.name, shortName: x.shortName, id: x.id.toString()}))}
                                    selectedIds={this.state.filterActivity == null ? ['all'] : this.state.filterActivity}
                                    onChange={(value) => {
                                        const selected = value.indexOf('all') >= 0 ? null : value;
                                        this.makeDatasource(this.props.contracts, this.state.filter, this.state.filterResourceIds, selected, this.state.filterTariffds, this.state.filterPrice);
                                        this.setState({ filterActivity: selected });
                                    }}
                                    constAllSelected={'all'}
                                    rootItemShortTitle='Все'
                                    rootItemTitle='Все'
                                    onClick={() => this.onSave(false)}
                                />
                                <SimpleTreeSelect
                                    key={'st4'}
                                    items={this.props.tariffs.map(x => ({name: x.name, shortName: x.name, id: x.id.toString()}))}
                                    selectedIds={this.state.filterTariffds == null ? ['all'] : this.state.filterTariffds}
                                    onChange={(value) => {
                                        const selected = value.indexOf('all') >= 0 ? null : value;
                                        this.makeDatasource(this.props.contracts, this.state.filter, this.state.filterResourceIds, this.state.filterActivity, selected, this.state.filterPrice);
                                        this.setState({ filterTariffds: selected });
                                    }}
                                    constAllSelected={'all'}
                                    rootItemShortTitle='Все тарифы'
                                    rootItemTitle='Все тарифы'
                                    onClick={() => this.onSave(false)}
                                />
                                <SimpleTreeSelect
                                    key={'st5'}
                                    items={this.props.resources.map(x => ({name: x.name, shortName: x.shortName, id: x.id.toString()}))}
                                    selectedIds={this.state.filterResourceIds == null ? [Const.ALL_RESOURCE_ID] : this.state.filterResourceIds}
                                    onChange={(value) => {
                                        const selected = value.indexOf(Const.ALL_RESOURCE_ID) >= 0 ? null : value;
                                        this.makeDatasource(this.props.contracts, this.state.filter, selected, this.state.filterActivity, this.state.filterTariffds, this.state.filterPrice);
                                        this.setState({ filterResourceIds: selected });
                                    }}
                                    constAllSelected={Const.ALL_RESOURCE_ID}
                                    rootItemShortTitle='Все ресурсы'
                                    rootItemTitle='Все ресурсы'
                                    onClick={() => this.onSave(false)}
                                />
                                <SimpleTreeSelect
                                    key={'st6'}
                                    items={this.props.contractsPoints.map(x => x.price.toString()).filter((value, index, array) => array.indexOf(value) === index).map(x => ({name: x, shortName: x, id: x.toString()}))}
                                    selectedIds={this.state.filterPrice == null ? ['all'] : this.state.filterPrice}
                                    onChange={(value) => {
                                        const selected = value.indexOf('all') >= 0 ? null : value;
                                        this.makeDatasource(this.props.contracts, this.state.filter, this.state.filterResourceIds, this.state.filterActivity, this.state.filterTariffds, selected);
                                        this.setState({ filterPrice: selected });
                                    }}
                                    constAllSelected={'all'}
                                    rootItemShortTitle='Все Аб. платы'
                                    rootItemTitle='Все Аб. платы'
                                    onClick={() => this.onSave(false)}
                                />
                                <Search
                                    value={this.state.filter}
                                    onChange={(val) => {
                                        this.makeDatasource(this.props.contracts, val.target.value, this.state.filterResourceIds, this.state.filterActivity, this.state.filterTariffds, this.state.filterPrice);
                                        this.setState({ filter: val.target.value });
                                    }}
                                />
                            </Space>
                            <Table
                                key={this.state?.tableKey ?? 'tbl1'}
                                columns={this.columns} dataSource={this.dataSource} showSorterTooltip={false}
                                scroll={{ y: 'calc(100vh - 430px)' }} bordered size='small'
                                pagination={{ showSizeChanger: true, pageSizeOptions: ["7", "50", "100", "500", "1000"], defaultPageSize: 7, locale: { items_per_page: " / страницу" }}}
                                rowSelection={{
                                    type: "checkbox",
                                    selectedRowKeys: this.state.selectedRowKeys,
                                    onChange: (selectedRowKeys: any[], selectedRows: any[]) => {
                                        this.setState({selectedRowKeys: selectedRowKeys});
                                    },
                                    checkStrictly: false,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}