import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RouteComponentProps } from 'react-router';
import { Menu, Dropdown, Tooltip, Modal } from 'antd';
import { Link, withRouter } from "react-router-dom";

import { BaseProfile, CommonProfile } from "../decl";

import { ApplicationState } from '../store';
import * as PageStore from '../store/Page';
import * as UserProfileStore from '../store/UserProfile';

import {  UserOutlined,  DownOutlined} from '@ant-design/icons';
import LoginForm from './LoginForm';
import { exit, getOldSiteUrl } from '../utils/AuthUtils';
import {message} from '../utils/Common';
import keyIcon from "../images/key_icon.png";
import styles from '../resources/PageHeader.module.less';

const actionCreators = { ...UserProfileStore.actionCreators, ...PageStore.actionCreators};

interface Router {
    router: any;
}

type LoginProps =
    { userProfile: UserProfileStore.UserProfileState } &
    Router &
    typeof actionCreators &
    RouteComponentProps<{}>;

class Login extends React.PureComponent<LoginProps> {
    constructor(props: LoginProps) {
        super(props);

        this.onClick = this.onClick.bind(this);
        this.onLinkClick = this.onLinkClick.bind(this);
        this.onExternalLinkClick = this.onExternalLinkClick.bind(this);
        this.getLoginModalSettings = this.getLoginModalSettings.bind(this);
    }
    handleClick = (e:any) => {
        //console.log('pathname ', this.props.router.location.pathname);
    };

     getLoginModalSettings = () => {
        return (
            <Modal
                title = "Вход в личный кабинет"
                footer = {null}
                style = {{ maxWidth: 300 }}
                open = {true}
                onCancel = {()=>this.props.showModal(null)}>
             <LoginForm 
                    showModal={this.props.showModal} 
                    openSession={this.props.openSession} 
                    setIsLoading={this.props.setIsLoading}/>
            </Modal>);
    };
    
    onLinkClick(source: any){
        //const settingsPath: string = "/settings";
        this.props.history.push( source.keyPath[0]);
    }
    onExternalLinkClick(){
        //this.props.history.push( source.keyPath[0]);
        this.props.setIsLoading(true);
        const url = getOldSiteUrl();
        window.location.assign(url);
    }

    onClick() {
        const fExit = () => { 
            this.props.setIsLoading(true);
            exit().then(
                result => {
                    if (result.ok) {
                        const url = window.configTI.URL_LOGIN;
                        if(url.length > 0){
		                    window.location.assign(url);
                        }
                        else{
                            this.props.closeSession("Сессия закрыта.");
                        }
                    }
                    else {
                        message.error("Ошибка при попытке выполнить выход из системы.Сообщите администратору.");
                        this.props.setIsLoading(false);
                    }
                },
                error => {
                    this.props.setIsLoading(false);
                    message.error("Ошибка при попытке выполнить выход  из системы. Сообщите администратору.");
                    console.log(error);
                }
            );
        };

        if (this.props.userProfile.user?.isLogged) {
            if(this.props.userProfile.isChanged){
                this.props.saveAllUserProfile(fExit);
            }
            else{
                fExit();
            }
        }
        else {
            const modalSettings = this.getLoginModalSettings();
            this.props.showModal(modalSettings);
        }
    }

    render() {
        let pathname: string = this.props.router.location.pathname;
        const oldUrl:string|null = getOldSiteUrl();
        //console.log(pathname);

        const settingsPath: string = "/settings";
        const menu = (
          <Menu onClick={this.handleClick}>
            <Menu.Item  key={settingsPath} onClick={this.onLinkClick}>
                <span>Настройки</span>
                <Link to={settingsPath} />
            </Menu.Item>
            {oldUrl && oldUrl !== '' &&
                <Menu.Item  onClick={this.onExternalLinkClick}>
                    <span>Старый сайт</span>
                    <Link to={{pathname:oldUrl}} />
                </Menu.Item>
            }
            <Menu.Item onClick={this.onClick}>
                <span>Выход</span>
            </Menu.Item>
          </Menu>
        );

        //!!!!! Зачем ссылка из тултипа.
        //<Tooltip placement="left" title={<a href="">{tooltip}</a>}>

        if(!this.props.userProfile.user?.isLogged){
            return (
                <div className={styles.loginContainer} onClick={this.onClick}>
                    <img
                        src={keyIcon}
                        alt="connect icon"
                    />
                    {this.props.userProfile.user?.isLogged ? 'Выйти' : 'Вход'}
                </div>
            );
        }
        else{
            const userName: string = this.props.userProfile.user?.user ?? '';
            const shortUserName: string = userName.length > 13 ? userName.substring(0,11) + '...' : userName; 
            const tooltip: string = 'Пользователь ' + userName;
            return(
                <Tooltip placement="left" title={tooltip}>
                <Dropdown overlay={menu} trigger={['click']}>
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                            <UserOutlined />
                            <span style={{margin:'5px'}}>{shortUserName}</span>
                        <DownOutlined />
                    </a>
                </Dropdown>
                </Tooltip>);
        }
    }

};

export default connect(
    (state: ApplicationState & Router) => {
        return {
            userProfile: state.userProfile,
            router: state.router
        }
    },
    actionCreators
)(withRouter(Login) as any);


