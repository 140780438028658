import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import CSS from 'csstype';

import { Form, Input, Button, Tooltip } from 'antd';
import { bindActionCreators } from 'redux';

import { ApplicationState } from '../store';
import * as UserProfileStore from '../store/UserProfile';
import * as PageStore from '../store/Page';
import * as HeaderStore from '../store/Header';
import * as Const from "../utils/Const";
import PageTitle from './PageTitle';
import Login from './Login2';

import styles from '../resources/Page.module.less';
import mailIcon from "../images/mail_icon.png";
import telegramIcon from "../images/telegram_icon.png";
import phoneIcon from "../images/phone_icon.png";
import whatsIcon from "../images/whats_icon.png";
import keyIcon from "../images/key_icon.png";

import {  
  UserOutlined,
  FullscreenOutlined,
  MenuOutlined,
  SyncOutlined,
  TableOutlined,
  FileZipOutlined,
  HomeOutlined,
  GroupOutlined,
  PhoneOutlined,
  WhatsAppOutlined,
  SendOutlined,
  MessageOutlined,
  ControlOutlined,
  DownloadOutlined
} from '@ant-design/icons';

const actionCreators = { ...PageStore.actionCreators, ...UserProfileStore.actionCreators };

type HeaderProps =
    { windowWidth: number } &
    typeof actionCreators &
    RouteComponentProps<{}>

const Message = (props: any) => {
    if(props.isSplit){
        return(
            <React.Fragment>
                <div className={styles.messageitem}>
                    <Tooltip placement="bottom" title={"WhatsApp"}>
                        <a href="https://api.whatsapp.com/send?phone=79253655914"
                            target="_blank"
                            rel="nofollow noopener noreferrer"
                        >
                            <img
                                src={whatsIcon}
                                alt="WhatsApp"
                            />
                        </a>
                    </Tooltip>
                    <span className={styles.infocalltext}>  
                        +7(925)365-59-14
                    </span>
                </div>
                <div className={styles.messageitem}>
                    <Tooltip placement="bottom" title={"Telegram"}>
                       <a
                            href="https://t.me/alexstanishevsk"
                            data-messenger-telegram="dinahydojniks"
                            target="_blank"
                            rel="nofollow noopener noreferrer"
                        >
                            <img
                                src={telegramIcon}
                                alt="Telegram"
                            />
                        </a>
                    </Tooltip>  
                    <span className={styles.infocalltext}>  
                        +7(925)365-59-14
                    </span>
                </div>
            </React.Fragment>
        );
    }
    else if(props.iconsOnly){
        return(
            <React.Fragment>
                <div className={styles.messageicon}>
                <Tooltip placement="bottom" title={"WhatsApp"}>
                    <a href="https://api.whatsapp.com/send?phone=79253655914"
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                    >
                        <img
                            src={whatsIcon}
                            alt="WhatsApp"
                        />
                    </a>
                </Tooltip>
                </div>
                    <div className={styles.messageicon}>
                        <Tooltip placement="bottom" title={"Telegram"}>
                            <a
                                href="https://t.me/alexstanishevsk"
                                data-messenger-telegram="dinahydojniks"
                                target="_blank"
                                rel="nofollow noopener noreferrer"
                            >
                                <img
                                    src={telegramIcon}
                                    alt="Telegram"
                                />
                            </a>
                        </Tooltip>  
                </div>
            </React.Fragment>
        );
    }
    else
    {
        return(
            <div className={styles.messageitem}>
                <Tooltip placement="bottom" title={"WhatsApp"}>
                    <a href="https://api.whatsapp.com/send?phone=79253655914"
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                    >
                        <img
                            src={whatsIcon}
                            alt="WhatsApp"
                        />
                    </a>
                </Tooltip>
                <Tooltip placement="bottom" title={"Telegram"}>
                    <a
                        href="https://t.me/TEPLO_INFORM"
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                    >
                        <img
                            src={telegramIcon}
                            alt="Telegram"
                            style={{marginLeft:'5px'}}
                        />
                    </a>
                </Tooltip>  
                <span className={styles.infocalltext}>  
                    +7(925)365-59-14
                </span>
            </div>
        );
    }
}

const Phone = (props: any) => {
    if(!props.isHide){
        return(
            <div className={styles.messageitem}>
                    <a
                        href={Const.email.ref}
                        target="_top"
                        style={{
                            textDecoration: "underline",
                            paddingLeft: "0.4em"
                        }}
                    >
                        <Tooltip placement="bottom" title={"E-mail"}>
                            <img
                                src={mailIcon}
                                alt="E-mail"
                            />
                        </Tooltip>
                        <span className={styles.infocalltext}>  
                            {Const.email.title}
                        </span>
                    </a>
            </div>
        );
    }
    else{
        return (
            <div>
            </div>
        );
    }
}
    
export const MiddleContentMessageBlock = (props: any) => {
    let content: any;
    if(props.windowSize >= props.maxWindowSize){
        if(props.onlyIcon){
            return(
                <div className={styles.messageiconsblock}>
                <div className={styles.messageicon}>
                    <Tooltip placement="bottom" title={"Позвонить " + Const.phone.title}>
                        <a href="tel:+74956727213"
                            target="_top">
                            <img
                                src={phoneIcon}
                                alt="Телефон"
                            />
                        </a>
                    </Tooltip>
                </div>
                {Const.showMessagers && <Message isSplit={props.isSplit} iconsOnly={true}/>}
                <div className={styles.messageicon}>
                    <a
                        href={Const.email.ref}
                        target="_top"
                        style={{
                            textDecoration: "underline",
                        }}
                    >
                    <Tooltip placement="bottom" title={"E-mail " + Const.email.title}>
                    <img
                        src={mailIcon}
                        alt="E-mail"
                    />
                    </Tooltip>
                    </a>
                </div>
                </div>);
        }
        else{
            return(
                <div className={styles.messageblock}>
                <div className={styles.messageitem}>
                    <Tooltip placement="bottom" title={"Позвонить " + Const.phone.title}>
                        <a href={Const.phone.ref}
                            target="_top">
                            <img
                                src={phoneIcon}
                                alt="Телефон"
                            />
                        </a>
                    </Tooltip>
                    <span className={styles.infocalltext}>  
                        {Const.phone.title}
                    </span>
                </div>
                {Const.showMessagers && <Message isSplit={props.isSplit} iconsOnly={false}/>}
                <Phone isHide={props.hideEmail}/>
                </div>);
        }
    }
    else {
        return (
            <div>
            </div>
        );
    }    
}

class HeaderForm extends React.PureComponent<HeaderProps>{
    render() {
        return (
            <div className={styles.flexcontainer}>
                <div className={styles.mbitem}>
                    <MiddleContentMessageBlock windowSize={this.props.windowWidth} maxWindowSize={600} onlyIcon={false} isSplit={true}/>
                </div>
                <div className={styles.flexuseritem}>
                    <Login/>
                </div>
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            windowWidth: state.page.windowWidth
        }
    },
    actionCreators
)(HeaderForm as any);
