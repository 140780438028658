import styles from '../resources/Page.module.less';
import * as React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import {store} from '../index';
import { withRouter } from 'react-router-dom';
import { Modal, Layout, Button } from 'antd';
import { DraggableModalProvider } from 'ant-design-draggable-modal'

import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as SiderMenuStore from '../store/SiderMenu';
import * as PageStore from '../store/Page';
import * as TasksStore from '../store/Tasks';
import * as UserProfileStore from '../store/UserProfile';
import * as AdminStore from '../store/AdminStore';
import * as Const from '../utils/Const';
import { BaseProfile, CommonProfile, MenuItemDesc, Point, SConnectionStatisticDTO } from "../decl";
import { getInitialPage, topNetiExecute, getModalZIndex, message } from '../utils/Common';
import { loginByShareID, refreshTokenQuery, sendRequestToBackend, exitToUrl } from '../utils/AuthUtils';
import ModalPointEditor from './ModalPointEditor';
import SiderMenu from './SiderMenu';
import InfoBlock from './InfoBlock';
import PageHeader from './PageHeader';
import PageSubHeader from './PageSubHeader';
import PageFooter from './PageFooter';
import PageFooterPM from './PageFooterPM';
import WaitBlock from './WaitBlock';
import { ConnectionStatistics, BlockingPointsTable, EventsTable } from './Page/ConnectionStatistics';
import { RouterPrompt } from './Page/RouterPrompt';
import OnlineLogForm from './Admin/OnlineLogForm';
import PointPollingLogForm from './Admin/PointPollingLogForm';
import { getQueryTitle } from './Admin/QueryItemsForm'
import { point } from 'leaflet';

const { Sider, Header, Footer, Content } = Layout;

interface Routers {
    children: any;
}

//Периодичность сохранения профиля (мсек).
const TIMER_INTERVAL: number = 100000;

const actions = { ...SiderMenuStore.actionCreators, ...PageStore.actionCreators, ...UserProfileStore.actionCreators, ...AdminStore.actionCreators, ...TasksStore.actionCreators };


const gotoLogin = (setIsLoading: any) => {
    const url = window.configTI.URL_LOGIN;
    if(url !== window.location.pathname){
        setIsLoading(true);
        window.location.assign(url);
    }
}

function accessDenied(target: string, goBack: any) {
  Modal.warning({
    wrapClassName: Const.MODAL_WRAP_CLASSNAME,
    zIndex: getModalZIndex(),
    title: 'Нет доступа',
      content: "Для получения доступа к странице '" + target +  "' нужно подключиться к другому тарифу.",
    onOk: ()=>{
        if(goBack) goBack();
    } 
  });
}


function warningCloseSession(setIsLoading: any, message: string | null) {
      const content = message ? message : 'Ваш сеанс работы с сайтом завершён из-за отсутствия активности в течении ' + window.configTI.SLIDING_EXPIRATION + ' минут. Для продолжения работы нужно выполнить повторный вход';
      Modal.warning({
        title: 'Сессия завершена',
        wrapClassName: Const.MODAL_WRAP_CLASSNAME,
        zIndex: getModalZIndex(),
        content: content,
        onOk: ()=>{
            gotoLogin(setIsLoading);
        } 
      });
}

function blocking(points: Point[], isBlocking: boolean, setIsLoading: any, closeSession: any, setIgnoreWarning: any) {
    const gotoConracts = () => {
        window.location.assign(window.configTI.URL_CONTACTS);
        //exitToUrl(setIsLoading, closeSession, window.configTI.URL_CONTACTS);
    }
    let ignoreWarning = false;
    const setIgnoreWarningCheck = (checked: boolean) =>{
        ignoreWarning = checked;
    }

    const contacts = window.configTI.URL_CONTACTS;
    Modal.warning({
      title: 'Внимание: блокировка',
      wrapClassName: Const.MODAL_WRAP_CLASSNAME,
      zIndex: getModalZIndex(),
      content: <BlockingPointsTable points={points} gotoConracts ={gotoConracts} isBlocking = {isBlocking} ignoreWarning={ignoreWarning} setIgnoreWarning = {setIgnoreWarningCheck}/>,
        width: 1000,
        onOk: ()=>{
            if(isBlocking){
                exitToUrl(setIsLoading, closeSession, window.configTI.URL_PUBLIC);
            }
            if(ignoreWarning){
                setIgnoreWarning(ignoreWarning);
            }
      } 
    });
  }
  
function pollingStatistics(statistics: SConnectionStatisticDTO, scrollY: number, showPointInfo: any, gotoPage: any, onClose: any, acceptAllUserEvents: (onOk?: any)=>void) {
    if(statistics.newEventsCount > 0 && statistics.connectionProblems.length > 0){
        const width = statistics.connectionProblems.length > 0 ? 1000 : 400;
        Modal.warning({
            centered: true,
            title: 'Мониторинг опроса',
            wrapClassName: Const.MODAL_WRAP_CLASSNAME,
            zIndex: getModalZIndex(),
                  content: <ConnectionStatistics statistics = {statistics} acceptAllUserEvents={acceptAllUserEvents} showPointInfo = {showPointInfo} gotoPage={gotoPage} scrollY={scrollY}/>,
            width: width,
            okText: 'Закрыть',
            onOk: () => {
                onClose();
            }
        });
    }
    else if(statistics.newEventsCount > 0){
        Modal.confirm({
            centered: true,
            title: 'Мониторинг опроса',
            wrapClassName: Const.MODAL_WRAP_CLASSNAME,
            zIndex: getModalZIndex(),
                  content: <ConnectionStatistics statistics = {statistics}  acceptAllUserEvents={acceptAllUserEvents} showPointInfo = {showPointInfo} gotoPage={gotoPage} scrollY={scrollY}/>,
            width: 400,
            okText: 'Квитировать',
            cancelText: 'Закрыть',
            onOk: () => {
                acceptAllUserEvents();
            }
        });
    }
    else if(statistics.connectionProblems.length > 0){
        Modal.warning({
            centered: true,
            title: 'Мониторинг опроса',
            wrapClassName: Const.MODAL_WRAP_CLASSNAME,
            zIndex: getModalZIndex(),
                  content: <ConnectionStatistics statistics = {statistics}  acceptAllUserEvents={acceptAllUserEvents} showPointInfo = {showPointInfo} gotoPage={gotoPage} scrollY={scrollY}/>,
            width: 1000,
            okText: 'Закрыть',
            onOk: () => {
                onClose();
            }
        });
    }
    else{
        onClose();
    }
}

const getPageFooter = (setIsLoading: any) => {
    if(Const.ClientMode === 'PM'){
        return <PageFooterPM/>;
    }
    else{
        return <PageFooter setIsLoading = {setIsLoading}/>;
    }
}

type PageProps =
    //PageStore.PageState &
    typeof actions &
    Routers &
    ApplicationState &
    RouteComponentProps<{}>;

interface IPageState {
    timerIsOn: boolean,
    showPrompt: boolean,
    onlineLogFormZIndex: number
}

class Page extends React.PureComponent<PageProps, IPageState> {
    _timer: any;
    _eventsTimer: any;
    _pollingTimer: any;
    _cycletimer: any;
    _refreshtimer: any;
    _isReady: boolean;
    _isInitLogged: boolean;
    _waitEventsAccept:boolean;
    _ignoreBlockFunctions: ((nextPage: string, go: ()=>void) => boolean)[];
    constructor(props: PageProps) {
        super(props);
        this.state = {
            timerIsOn: false,
            showPrompt: false,
            onlineLogFormZIndex: 0
        };
        this._timer = undefined;
        this._eventsTimer = undefined;
        this._pollingTimer = undefined;

        this._cycletimer = null;
        this._refreshtimer = null;
        this._isReady = false;
        this._isInitLogged = false;
        this._waitEventsAccept = false;
        this._ignoreBlockFunctions = [];
    }
//    state = {
//        collapsed: false
//    };

    componentDidMount() {
        this._isReady = false;
        this.props.setIsLoading(true);

        const closeSession = () => {
            if (this.props.page.isSessionOpen) {
                //console.log('neti close');
                this.props.closeSession("Сессия закрыта.");
            }
        };

        //Ожидание готовности NETI.
        if (!window.startSiteError) {
            window.call_wait_neti_ready((requestManager: any) => {
                if (requestManager) {
                    requestManager.SetSessionClose(closeSession);
                    window.call_callback_register(this.execute);
                    window.call_set_appstore(store);
                    this.props.setIsNetiReady();
                    loginByShareID(this.initPage);
                }
                else {
                    this.props.setIsLoading(false);
                }
            });
        }
    }

    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
        if (this.props.page.isSessionOpen && !prevProps.page.isSessionOpen && !this._isInitLogged) {
            this.props.setIsLoading(true);
            this.props.clearCache();
            //console.log('UPDATE PAGE OPEN');
            this.props.requestAllUserProfile(false, (isLogged: boolean, profile: BaseProfile, menu: MenuItemDesc[], defaultPoint: Point)=>{
                if(defaultPoint){
                    this.props.setPoint(defaultPoint.id, defaultPoint.number);
                }
                const page = getInitialPage(profile, menu);
                this.props.history.push(page);
                this.props.setIsLoading(false, 1000);

                if(!this.checkIsBlocking()){
                    this.refreshToken();
                    this.getConnectionStatistics();
                }
                this.props.requestQueryItemTypes();
            },
            ()=>{
                this.props.setIsLoading(false);
                this.props.closeSession("Сессия закрыта.");
                message.error("Системная ошибка. Обратитесь к администратору.");
            });
        }
        else if (!this.props.page.isSessionOpen && prevProps.page.isSessionOpen) {
            warningCloseSession(this.props.setIsLoading, this.props.page.closeSessionMessage);
            this.props.setNotIsLogged();
        }

        //Проверить необходимость управления таймером для получения новых событий.
        if ((this._isReady &&
             (this.props.userProfile?.profile.Common?.ConditionEventsCheckEnabled !== prevProps.userProfile?.profile.Common?.ConditionEventsCheckEnabled) ||
            (this.props.userProfile?.profile.Common?.ConditionEventsReadPeriod !== prevProps.userProfile?.profile.Common?.ConditionEventsReadPeriod))) {
            this.controlEventsTimer();
        }

        //Проверить необходимость управления таймером для контроля опроса.
        if (this._isReady &&
            this.props.userProfile?.profile.Common?.PollingCheckPeriod !== prevProps.userProfile?.profile.Common?.PlollingCheckPeriod) {
            this.pollingCheckTimer();
        }

        if ((this.props.tasks.activeLogQueryItemId !== prevProps.tasks.activeLogQueryItemId && this.props.tasks.activeLogQueryItemId !== undefined) ||
            (this.props.tasks.activeLogTaskId !== prevProps.tasks.activeLogTaskId && this.props.tasks.activeLogTaskId > 0)) {
            this.setState({ onlineLogFormZIndex: getModalZIndex() })
        }

        this._isInitLogged = false;
    }

    componentWillUnmount() {
        if (this._timer) {
            clearInterval(this._timer);
            this._timer = undefined;
        }
        if (this._eventsTimer) {
            clearInterval(this._eventsTimer);
            this._eventsTimer = undefined;
        }
        if (this._refreshtimer) {
            clearTimeout(this._refreshtimer);
            this._refreshtimer = null;
        }
        if (this._cycletimer) {
            clearInterval(this._cycletimer);
            this._cycletimer = null;
        }
        if (this._pollingTimer) {
            clearInterval(this._pollingTimer);
            this._pollingTimer = null;
        }

        window.removeEventListener("resize", this.handleResize);
    };

    initPage = (isSharedLogin: boolean) => {
        if(isSharedLogin){
            //this.props.history.replace(configTI.URL_PUBLIC);
        }
        Const.setClientMode();
        this.props.clearCache();
        this.props.requestAllUserProfile(false, (isLogged: boolean, profile: BaseProfile, menu: MenuItemDesc[], defaultPoint: Point) => {
            if(defaultPoint){
                this.props.setPoint(defaultPoint.id, defaultPoint.number);
            }
            
            this._isInitLogged = isLogged;

            if(isLogged){
                const page = getInitialPage(profile, menu);
                this.props.openSession();
                this.props.history.push(page);
            }
            else {
                gotoLogin(this.props.setIsLoading);
            }

            this.props.setIsLoading(false, 1000);
            this._isReady = true;

            if(isSharedLogin && isLogged){
                if(!this.checkIsBlocking()){
                    this.refreshToken();
                    this.getConnectionStatistics();
                }
            }
            //this.refreshToken();
        },
        ()=>{
            this.props.setIsLoading(false);
            message.error("Системная ошибка. Обратитесь к администратору.");
        });

        if (!this._timer) {
            let interval = this.props.userProfile?.profile.Common?.ConditionEventsReadPeriod;
            if(!interval || interval === 0)  interval = TIMER_INTERVAL;
            this._timer = setInterval(this.onProfileTimer, TIMER_INTERVAL);
        }

        //Установка таймера для периодического получения новых событий.
        this.controlEventsTimer();

        this.props.history.listen(this.onChangePage);
        this.props.history.block(this.checkBlockPage);

        window.addEventListener("resize", this.handleResize);

        if (window.screen && window.screen.orientation) {
            window.screen.orientation
                .lock("landscape")
                .then(
                    success => { },//console.log(success),
                    failure => { }//console.log(failure)
                )
        }
    }
    
    controlEventsTimer = () => {
        if (this._eventsTimer) {
            clearInterval(this._eventsTimer);
            this._eventsTimer = undefined;
        }
        if (this.props.userProfile?.profile.Common?.ConditionEventsCheckEnabled &&
            (this.props.userProfile?.profile.Common?.ConditionEventsReadPeriod ?? 0) > 0) {
           let interval = this.props.userProfile?.profile.Common?.ConditionEventsReadPeriod ?? 120;
           this._eventsTimer = setInterval(this.getNewEvents, interval*1000);
        }
    }
    pollingCheckTimer = () => {
        if (this._pollingTimer) {
            clearInterval(this._pollingTimer);
            this._pollingTimer = undefined;
        }
        if ((this.props.userProfile?.profile.Common?.PollingCheckPeriod ?? 0) > 0) {
            let interval = this.props.userProfile?.profile.Common?.PollingCheckPeriod ?? 2;
            this._pollingTimer = setInterval(this.props.getActiveTasks, interval * 1000);
        }
    }

    getConnectionStatistics = () => {
        if (this.props.userProfile?.profile.Common?.ShowUnreadMessages) {
            const scrollY: number = this.props.page.windowHeight - 350;
            sendRequestToBackend(
                {},
                'points/connectionstatistic',
                (response: any) => {
                    //console.log(response);
                    const _this = this;
                    _this._waitEventsAccept = true;
                    pollingStatistics(
                        response,
                        scrollY,
                        this.props.showPointInfo,
                        this.gotoPage,
                        () => {
                            _this._waitEventsAccept = false;
                        },
                        (onOk: any) => {
                            const messageText: string = (response.newEventsCount === 1) ?
                                "Отметить 1 сообщение просмотренным?" :
                                'Отметить все ' + response.newEventsCount.toString() + ' сообщения просмотренными?';
                            Modal.confirm({
                                title: 'Квитирование всех сообщений',
                                wrapClassName: Const.MODAL_WRAP_CLASSNAME,
                                zIndex: getModalZIndex(),
                                content: messageText,
                                width: 400,
                                okText: 'Квитировать',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    sendRequestToBackend(
                                        { eventIds: null },
                                        'points/neweventsaccept3',
                                        (response: any) => {
                                            message.success('События квитированы.');
                                            if (onOk) onOk();
                                        },
                                        this.props.setIsLoading,
                                        (error: any) => {
                                            console.log(error);
                                        },
                                        this.props.closeSession
                                    );
                                },
                                onCancel: () => {
                                }
                            });

                        }
                    );
                },
                this.props.setIsLoading,
                (error: any) => {
                    console.log(error);
                },
                this.props.closeSession
            )
        }
    }

    checkIsBlocking = () =>{
        const allPoints = this.props.userProfile?.allPoints;
        const blockingPoints: Point[] = [];
        let isBlocking: boolean = true;
        let isWarning: boolean = false;
        if(allPoints){
            const ids = Object.getOwnPropertyNames(this.props.userProfile?.allPoints);
            if(ids.length === 0 ){
                isBlocking = false;
            }
            else{
                for(let id of ids){
                    const point: Point = allPoints[id];
                    if(!point.isLocked){
                        isBlocking = false;
                        if(point.daysToLock > 0 && point.daysToLock <= 7){
                            isWarning = true;
                            blockingPoints.push(point);
                        }                    
                    }
                    else{
                        blockingPoints.push(point);
                    }
                }  
            }
        }
        const ignoreWarning = this.props.userProfile?.profile?.Common?.IgnoreLockWarning;

        if (isBlocking || (isWarning && !ignoreWarning)) {
            //console.log("Блокировка");
            blocking(blockingPoints, isBlocking, this.props.setIsLoading, this.props.closeSession,
                 ()=>{
                    this.props.changeProfileValue(Const.PROFILE_COMMON, "IgnoreLockWarning", true);
                });
        }
        else if(ignoreWarning && isBlocking){
            this.props.changeProfileValue(Const.PROFILE_COMMON, "IgnoreLockWarning", false);
        }

        return isBlocking;
    }

    getNewEvents = () => {
        //console.log('EventsTimer STEP');
        if(!this._waitEventsAccept){
            //console.log('getNewEvents');
            this._waitEventsAccept = true;
            sendRequestToBackend(
                {},
                'points/newuserevents',
                (response: any) => {
                    //console.log(response);
                    this.newEvents(response);
                },
                null,
                (error: any) => {
                    console.log(error);
                    this._waitEventsAccept = false;
                },
                this.props.closeSession,
                undefined,
                true
            );
        }
    }

    newEvents = (events: any) => {
        if (events.length > 0) {
            const _this = this;
            const accept = (events: any) => {
                const eventIds: number[] = events.map((ev:any)=>{return ev.eventId;});
                sendRequestToBackend(
                    {eventIds},
                    'points/neweventsaccept3',
                    (response: any) => {
                        message.success('События квитированы.');
                    },
                    this.props.setIsLoading,
                    (error: any) => {
                        console.log(error);
                    },
                    this.props.closeSession,
                    undefined,
                    true
                );
            }

            Modal.confirm({
                title: 'Мониторинг опроса. Новые сообщения контроля',
                wrapClassName: Const.MODAL_WRAP_CLASSNAME,
                zIndex: getModalZIndex(),
                    content: <EventsTable events={events} formReport={this.props.formReport} showPointInfo = {this.props.showPointInfo}/>,
                width: 1000,
                okText: 'Квитировать',
                cancelText: 'Закрыть',
                onOk: () => {
                    accept(events);
                    _this._waitEventsAccept = false;
                },
                onCancel: () => {
                    _this._waitEventsAccept = false;
                }
            });
        }
        else{
            this._waitEventsAccept = false;
        }
    }

    execute = (cmd: any) => {
        if (cmd.Name === Const.SHOW_TASK_LOG_COMMAND && this.props.userProfile) {
            const queryId = cmd.Params.queryId;
            const taskId = cmd.Params.taskId;
            this.props.setActiveLogTask(queryId, taskId);
        }
        else if (cmd.Name === Const.GOTO_BILLING_COMMAND && this.props.userProfile) {
            this.gotoPage('/billing');
        }
        else {
            topNetiExecute(cmd, this.props.userProfile, this.props.changeProfile, this.props.setIsLoading, this.props.setActiveQueries, null, this.props.showModal, true);
        }
    }
    
    refreshToken = () => {
        const _this = this;
        const refreshInterval = window.configTI.REFRESH_TOKEN_SECONDS * 1000;
        const waitSessionInterval = 1000;
        const fOk = (user: string, lifeMinutes: number) => {
            //console.log('Refresh OK:' + lifeMinutes + _this.props.page.isSessionOpen);
            if (_this._cycletimer) {
                clearInterval(_this._cycletimer);
                _this._cycletimer = null;
            }
            if (!_this.props.page.isSessionOpen) {
                _this.props.openSession();
            }
            _this._refreshtimer = setTimeout(
                () => {
                    refreshTokenQuery(fOk, fClose);
                }, refreshInterval);
        };

        const fClose = (message: string) => {
            if (_this.props.page.isSessionOpen) {
                //console.log('refresh_token close');
                _this.props.closeSession(message);
            }
            if (_this._cycletimer == null) {
                _this._cycletimer = setInterval(
                    () => {
                        refreshTokenQuery(fOk, fClose);
                    }, waitSessionInterval);
            }
            if (_this._pollingTimer) {
                clearInterval(_this._pollingTimer);
                _this._pollingTimer = undefined;
            }
        };

        refreshTokenQuery(fOk, fClose);
    }

    handleResize = (e: any) => {
        //console.log('WindowResize: ', window.innerWidth, window.innerHeight);
        this.props.changeWindowSize(window.innerWidth, window.innerHeight);
        window.call_window_resize(window.innerWidth, window.innerHeight);
    };

    //Обработка события ухода со страницы
    onBeforeUnload = () => {
        //console.log('BeforeUnload');
        //!!!!! Всегда выдаётся сообщение браузером.
        //if(this.props.userProfile){
        //    if(this.saveProfile())
        //    {
        //        return "Выход?"
        //    }
        //}
        return null;
    }

    // Setup the `beforeunload` event listener
    setupBeforeUnloadListener = () => {
        window.addEventListener("beforeunload", (ev) => {
            ev.preventDefault();
            const result: any = this.onBeforeUnload();
            if(result){
                (ev || window.event).returnValue = result;
                return result;
            }
        });
    };

    onChangePage = (location: any, action: string) => {
        if (action === 'PUSH'){
            this.props.changeLastPage(location.pathname);
            this.props.saveAllUserProfile(() => {
                if (this.props.userProfile?.needReload) {
                    this.props.setIsLoading(true, 500);
                    this.props.requestAllUserProfile(false, (isLogged: boolean, profile: BaseProfile, menu: MenuItemDesc[], defaultPoint: Point) => {
                        this.props.setIsLoading(false);
                    },
                        () => {
                            this.props.setIsLoading(false);
                            message.error("Системная ошибка. Обратитесь к администратору.");
                        });
                }
            });
            const menuItem = this.props.userProfile?.menu.find(i => i.path === location.pathname);
            if (this.props.userProfile && menuItem && menuItem.right && !this.props.userProfile.rights.includes(menuItem.right)){
                //this.setState({showPrompt:true});
                //accessDenied(menuItem.name, this.props.history.goBack);
            }
        }
        window.call_execute({Source: null, Name: 'ChangePage'});
        if(location.pathname === '/admin'){
            this.props.setNeedReloadProfile();  
        }
        else if(this.props.userProfile?.needReload){
            this.props.setIsLoading(true, 500);
            this.props.requestAllUserProfile(false, (isLogged: boolean, profile: BaseProfile, menu: MenuItemDesc[], defaultPoint: Point) => {
                this.props.setIsLoading(false);
            },
            ()=>{
                this.props.setIsLoading(false);
                message.error("Системная ошибка. Обратитесь к администратору.");
            });
        }
    }

    checkBlockPage = (location: any, action: any) =>{
        let block = false;
        this.props.page.beforeLeavePageFunctions.forEach(f=>{
            if(!this._ignoreBlockFunctions.includes(f) && f(location.pathname, ()=>{
                //Выполнить переход на страницу.
                this._ignoreBlockFunctions.push(f);
                this.props.history.push(location.pathname);
            }) === false) block = true;
        })
        if(!block){
            const menuItem = this.props.userProfile?.menu.find(i => i.path === location.pathname);
            if (this.props.userProfile && menuItem && menuItem.right && !this.props.userProfile.rights.includes(menuItem.right)){
                //this.setState({showPrompt:true});
                accessDenied(menuItem.name, null);
                block = true;;
            }
        }
        if(block) {
            return false;
        }
        else{
            this.props.clearBeforeLeavePageFunctions();
            this._ignoreBlockFunctions = [];
        }
    }

    gotoPage =(url:any) => {
        Modal.destroyAll();
        this.props.history.push(url);
    }

    onProfileTimer = () => {
        this.saveProfile();
    }

    saveProfile = () => {
        let result = false;
        if (this.props.userProfile) {
            const isLogged: boolean = this.props.userProfile.user?.isLogged ?? false;
            const isChanged: boolean = this.props.userProfile.isChanged;

            if(isLogged && isChanged){
                this.props.saveAllUserProfile(null);
                //console.log('PROFILE SAVED');
                result = true;
            }
        }
        return result;
    }

    onCollapse = (collapsed:boolean) => {
        //console.log(collapsed);
        //this.setState({ collapsed });
    };

    handleCancel = () => {
        this.props.showModal(null);
    };

    public render() {
        let collapsed: boolean = false;

        if (this.props.siderMenu !== undefined) {
            collapsed = this.props.siderMenu.collapsed;
        }

        const firstColWidth: string = collapsed ? "120px" : "234px";
        const page: PageStore.PageState = this.props.page;

        const modal = page.activeModal;
        //Флаг наличия пользователя.
        let isLogged: boolean = false;
        if (this.props.userProfile) {
            isLogged = this.props.userProfile.user?.isLogged ?? false;
        }

        //Режим заставки.
        const waitMode: string = page.windowHeight < 800 ? "small" : "normal";

        //Флаг готовности NETI.
        const isNetiReady: boolean = page.isNetiReady;
        if(!this._isReady){
            if(window.startSiteError)
            {
                return (
                    <div>
                        <WaitBlock message={window.startSiteError} mode = {waitMode} isActive={true}/>
                    </div>
                );
            }
            else{
                return (
                    <div>
                        <WaitBlock message={page.loadingMessage} mode = {waitMode} isActive={page.isLoading}/>
                    </div>
                );
                }
        }
        else{
            const collapsed = this.props.siderMenu?.collapsed;
            return (
                <React.Fragment>

                    <DraggableModalProvider>
                        <WaitBlock message={page.loadingMessage}  mode = {waitMode} isActive={page.isLoading}/>
                    <Layout>
                        <Header className={styles.header}>
                            <PageHeader firstColWidth={firstColWidth} collapsed={collapsed}/>
                        </Header>
                        {isNetiReady &&
                            <Layout className={styles.content}>
                                <Sider collapsible collapsed={collapsed} onCollapse={this.onCollapse} trigger={null}>
                                    <Layout className={styles.leftSider}>
                                        <SiderMenu />
                                        <InfoBlock />
                                    </Layout>
                                </Sider>
                                <Content>
                                    {this.props.children}
                                </Content>
                            </Layout>
                        }
                        <Footer>
                            {getPageFooter(this.props.setIsLoading)}
                        </Footer>
                        </Layout>
                        {this.props.page.editPoint && <ModalPointEditor />}
                        {modal}
                        {
                            <OnlineLogForm
                                onHide={() => {
                                    this.props.setActiveLogTask(undefined, 0);
                                }}
                                queryItemId={this.props.tasks.activeLogQueryItemId}
                                taskId={this.props.tasks.activeLogTaskId}
                                zIndex={this.state.onlineLogFormZIndex}
                            />
                        }
                        {
                            this.props.page.pointLogs.map(p => (
                                <div>
                                    <PointPollingLogForm
                                        point={p}
                                        zIndex={3000}
                                        queryItemTypes={this.props.adminStore?.queryItemTypes}
                                        activeTasks={this.props.tasks ? this.props.tasks.activeTasks : []}
                                        getActiveTasks={this.props.getActiveTasks}
                                        onHide={(point: Point | undefined) => { if (point) this.props.removePointLog(point) }}
                                    >
                                    </PointPollingLogForm>
                                </div>
                            ))
                        }
                    </DraggableModalProvider>
                </React.Fragment>
            );
        }
    }
};

export default connect(
    (state: ApplicationState) => state,
    actions
)(withRouter(Page) as any);



