import * as React from 'react';

//import styles from '../resources/Page.module.less';
import { Button, Modal, Select, Input, Row, Col, InputNumber, Checkbox } from 'antd';

import {MODAL_WRAP_CLASSNAME } from '../../utils/Const';

import pageStyles from '../../resources/Page.module.less';

import { LoadingOutlined } from '@ant-design/icons';
import { QueryItemTypes, SQueryItemDTO } from '../../store/AdminStore';
const { Option } = Select;

type Props = {
    onHide(): void,
    onOk(): void,
    changeQueryItem(val: SQueryItemDTO): void,
    queryItem: SQueryItemDTO | null,
    queryItemTypes: QueryItemTypes | undefined,
    zIndex: number
}

type State = {
}

class QueryItemEditForm extends React.Component<Props, State> {
    constructor(props: Props, state: State) {
        super(props);

    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps: Props) {

    }

    private onHide = () => {
        this.setState({queryItemCurrent: undefined})
        this.props.onHide();
    }

    private QItemFieldChange = (field: keyof SQueryItemDTO, value: any) => {
        if (this.props.queryItem != null) {
            let tmp: SQueryItemDTO = {...this.props.queryItem, [field]: value};
            this.props.changeQueryItem(tmp);
        }
    }

    //=============================================================================
    render() {
        return (
            this.props.queryItem == undefined ? null :
            <Modal
                maskClosable={false}
                //bodyStyle={{height: 550, overflow: 'auto'}}
                title={'Задание'}
                wrapClassName={MODAL_WRAP_CLASSNAME}
                zIndex={this.props.zIndex}
                open={this.props.queryItem !== null}
                footer={[
                    <div key={1} style={{ display: 'flex', alignItems: 'baseline' }}>
                        <Button key="1" type='primary' onClick={() => this.props.onOk()} style={{marginLeft: 'Auto'}}>OK</Button>
                        <Button key="2" type='primary' onClick={this.onHide}>Отмена</Button>
                    </div>
                ]}
                onCancel={() => { this.props.onHide() }}
                width={550}
                style={{ top: 20 }}
            >
                {
                    this.props.queryItemTypes === undefined ? <LoadingOutlined/> :
                    <Input.Group>
                        <Row className={pageStyles.FormRowWrapper}>
                            <Col span={8}>
                                Расписание
                            </Col>
                            <Col span={16}>
                                <Select 
                                    value={this.props.queryItem.shedules_Id} 
                                    onChange={(val) => this.QItemFieldChange('shedules_Id', val)} 
                                    size="small" className={pageStyles.Width100}
                                >
                                    {
                                        this.props.queryItemTypes?.scheduleTypes.map(item => (
                                            <Option key={item.id} value={item.id}>{item.name}</Option>
                                        ))
                                    }
                                </Select>
                            </Col>
                        </Row>
                        <Row className={pageStyles.FormRowWrapper}>
                            <Col span={8}>
                                Имя
                            </Col>
                            <Col span={16}>
                                <Input size='small' value={this.props.queryItem.name} onChange={(val) => this.QItemFieldChange('name', val.target.value)} />
                            </Col>
                        </Row>
                        <Row className={pageStyles.FormRowWrapper}>
                            <Col span={8}>
                                Приоритет
                            </Col>
                            <Col span={16}>
                                <InputNumber size='small' value={this.props.queryItem.priority} onChange={(val) => this.QItemFieldChange('priority', val)} className={pageStyles.Width100} />
                            </Col>
                        </Row>
                        <Row className={pageStyles.FormRowWrapper}>
                            <Col span={8}>
                                Задание
                            </Col>
                            <Col span={16}>
                                <Select 
                                    value={this.props.queryItem.queryTypes_Id} 
                                    onChange={(val) => this.QItemFieldChange('queryTypes_Id', val)}
                                    size="small" className={pageStyles.Width100}
                                >
                                    {
                                        this.props.queryItemTypes?.queryTypes.map(item => (
                                            <Option key={item.id} value={item.id}>{item.description}</Option>
                                        ))
                                    }
                                </Select>
                            </Col>
                        </Row>
                        <Row className={pageStyles.FormRowWrapper}>
                            <Col span={8}>
                                Активно
                            </Col>
                            <Col span={16}>
                                <Checkbox checked={this.props.queryItem.enabled} onChange={(val) => {this.QItemFieldChange('enabled', val.target.checked)}} />
                            </Col>
                        </Row>
                    </Input.Group>
                }
            </Modal>
        );
    }
}

export default QueryItemEditForm;