import * as React from 'react';
import { Table, Input, Col, Row, Space, Tree, Button, Modal } from 'antd';

import { Point, STCPConnectDTO } from "../../decl";
import { removeValue } from "../../utils/TreeUtils";
import {message, netiExecute, toFullTimeString } from '../../utils/Common';
import * as Const from '../../utils/Const';
import TextArea from 'antd/lib/input/TextArea';
import { sendRequestToBackend } from '../../utils/AuthUtils';
import ReactDOM from 'react-dom';
import styles from '../../resources/Page.module.less';

const { Column } = Table;

const ALL_ROLES_ID = "allroles";

export interface Props {
    visible: boolean,
    zIndex: number,
    onHide() : void,
    clientId: string | null,
}

interface State {
    connects: STCPConnectDTO[]
}


//Дерево точек учёта.
export class TCPLogTable extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            connects: []
        }

    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps.visible === false && this.props.visible === true) {
            this.init();
        }
    }

    init = () => {
        if(this.props.clientId){
        const clientId: number = parseInt(this.props.clientId, 10);
        sendRequestToBackend(
            clientId,
            'admin/getconnhistory',
            (response: any) => {
                if (response != null) {
                    this.setState({connects: response});
                } 
            },
            null,
            (error: any) => {
                message.warning("Ошибка при получении истории подключений.");
                console.log(error);
            }
        );
        }
    }


    render() {
        const getConnects = () => {
            let id: number = 0;
            return Object.values(this.state.connects).map(item =>
                ({
                    key: ++id,
                    modemId: item.modemId,
                    connectionTime: item.connectionTime ? toFullTimeString(new Date(item.connectionTime)) : '',
                    disconnectTime: item.disconnectTime ? toFullTimeString(new Date(item.disconnectTime)) : '',
                    removeIP: item.remoteIP,
                })
            );
        }    

        return <Modal
            title= {"Журнал подключений"}
            open={this.props.visible}
            //onOk={this.onOk}
            okButtonProps={{ style: { display: 'none' } }}
            onCancel={this.props.onHide}
            cancelText={'Закрыть'}
            wrapClassName={Const.MODAL_WRAP_CLASSNAME}
            zIndex={this.props.zIndex}
            centered
            width={'60vw'}
        >
            <div className={styles.FormWrapper}>
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Row>
                <Col span = {8}>
                    {'Идентификатор: ' + this.props.clientId}
                </Col>
                <Col span = {2}/>
                <Col span = {6}>
                </Col>
                <Col span = {2}/>
                <Col span= {6}>
                </Col>
            </Row>
                <Table
                    dataSource={getConnects()}
                    pagination={false}
                    scroll={{y:400}}
                    bordered
                    onRow={(record, rowIndex) => {
                        return {
                        onClick: (event) => {}, // click row
                        onDoubleClick: (event) => {}, // double click row
                        onContextMenu: (event) => {}, // right button click row
                        onMouseEnter: (event) => {}, // mouse enter row
                        onMouseLeave: (event) => {}, // mouse leave row
                        };
                    }}
                >
                    <Column 
                        title="Модем или порт"
                        width={100}
                        dataIndex="modemId"
                        key="delete"
                    />
                    <Column 
                        title={<span style={{wordWrap: 'normal'}}>Время<br />подключения</span>}
                        dataIndex="connectionTime" 
                        key="connectionTime" 
                    />
                    <Column 
                        title={<span style={{wordWrap: 'normal'}}>Время<br/>отключения</span>}
                        dataIndex="disconnectTime" 
                        key="disconnectTime" 
                    />
                    <Column 
                        title="Адрес"
                        dataIndex="removeIP" 
                        key="removeIP"
                        render={(value, record: any) => (
                            <span style={{wordWrap: 'normal'}}>{value}</span>
                        )}
                    />
                </Table>
            </Space>
            </div>
    </Modal>
    }
}
