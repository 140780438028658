import * as React from 'react';
import { Input, Select, Tooltip } from 'antd';
import { connect } from 'react-redux';

import Login from './Login2';
import { ApplicationState } from '../store';
import * as PageStore from '../store/Page';
import * as UserProfileStore from '../store/UserProfile';
import * as ReportsStore from '../store/Reports';
import { RouteComponentProps } from 'react-router';
import styles from '../resources/Page.module.less';
import * as SiteLogStore from '../store/SiteLogStore';
import * as BillingStore from '../store/BillingStore';

const { Option } = Select;
const { Search } = Input;

import {
    SyncOutlined,
    TableOutlined,
    FileZipOutlined} from '@ant-design/icons';
import { MiddleContentMessageBlock } from './HeaderForm';
import RangePickerLimit from './Helper/RangePickerLimit';
import CollapsedButton from './Helper/CollapsedButton';
import { BACKEND_URL, getHeaders, sendRequestToBackend } from '../utils/AuthUtils';
import { convertBase64ToBinary, makeTime, message, saveFile } from '../utils/Common';

const actionCreators = { ...PageStore.actionCreators, ...UserProfileStore.actionCreators, ...ReportsStore.actionCreators, ...BillingStore.actionCreators  };

type HeaderProps =
    { userProfile: UserProfileStore.UserProfileState, reports: ReportsStore.IReportsState, windowWidth: number, isMenuCollapsed: boolean, billingStore: BillingStore.IBillingState,} &
    typeof actionCreators &
    RouteComponentProps<{}>

interface HeaderState {
    textFilter: string,
    userActivityReportLoading: boolean,
}

class BillingHeaderForm extends React.PureComponent<HeaderProps, HeaderState>{
    constructor(props: HeaderProps) {
        super(props);

        this.state ={
            textFilter: '',
            userActivityReportLoading: false,
        }
    }

    componentDidUpdate(prevProps: HeaderProps) {

    }

    componentDidMount() {

    }

    render() {
        const isColapsed = false;
        const short = this.props.windowWidth < 1750;
        return (
            <div className={styles.flexcontainer}>
                <div className={styles.buttonsblock}>
                    <CollapsedButton isCollapsed={isColapsed} onClick={() => {if (this.props.billingStore.companies == undefined) this.props.requestCompanies(() => {}); this.props.setShowCompaniesForm(true);}} icon={<TableOutlined/>} type="primary">{short ? 'Контрагенты' : 'Справочник контрагентов'}</CollapsedButton>
                    <CollapsedButton isCollapsed={isColapsed} onClick={() => {this.props.setShowTariffsForm(true)}} icon={<TableOutlined/>} type="primary">{short ? 'Тарифы' : 'Справочник тарифов'}</CollapsedButton>
                    <CollapsedButton isCollapsed={isColapsed} onClick={() => {this.props.setShowStatisticsForm(true)}} icon={<TableOutlined/>} type="primary">Статистика</CollapsedButton>
                    <CollapsedButton isCollapsed={isColapsed} onClick={() => {this.props.setShowParsePaymentsForm(true)}} icon={<TableOutlined/>} type="primary">{short ? 'Загр. платежи' : 'Загрузить платежи'}</CollapsedButton>
                    <CollapsedButton isCollapsed={isColapsed} onClick={() => {this.props.setShowPromisedPaymentForm(true)}} icon={<TableOutlined/>} type="primary">{short ? 'Обещ. платеж' : 'Обещанный платеж'}</CollapsedButton>
                    <CollapsedButton isCollapsed={isColapsed} onClick={() => {this.props.setShowActiveContractsForm(true)}} icon={<TableOutlined/>} type="primary">Активен</CollapsedButton>
                </div>
                
                <div className={styles.mbitem}>
                    <MiddleContentMessageBlock windowSize={this.props.windowWidth} maxWindowSize={this.props.isMenuCollapsed? 1380:1460} onlyIcon={this.props.windowWidth < 2200} hideEmail={false} isSplit={false}/>
                </div>
                <div className={styles.flexuseritem}>
                    <Login />
                </div>
            </div>
        )
    }
}



export default connect(
    (state: ApplicationState) => {
        return {
            userProfile: state.userProfile,
            windowWidth: state.page.windowWidth,
            isMenuCollapsed: state.siderMenu?.collapsed,
            billingStore: state.billingStore,
        }
    },
    actionCreators
)(BillingHeaderForm as any);
