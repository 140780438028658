import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';

import { ApplicationState } from '../store';
import * as PageStore from '../store/Page';
import * as TasksStore from '../store/Tasks';
import * as UserProfileStore from '../store/UserProfile';
import * as OperationsStore from '../store/OperationsStore';
import { BaseProfile, PeriodsEnum, SPointsFilter, USER_RIGHTS } from '../decl';
import styles from '../resources/Page.module.less';
import { Button, Input, Modal, Space, Table, Tag } from 'antd';
import { DeleteTwoTone, ExclamationCircleOutlined, FastForwardFilled, InfoCircleOutlined, LoadingOutlined, ScheduleTwoTone, SearchOutlined } from '@ant-design/icons';
import AbonentOperationForm from '../components/AbonentOperationForm';
import { AbonentOperationDTO } from '../store/OperationsStore';
import moment from 'moment';
import ReactDOM from 'react-dom';
import { getModalZIndex, netiExecute } from '../utils/Common';
import * as Const from "../utils/Const";
import { getModalState } from 'ant-design-draggable-modal/dist/draggableModalReducer';
const { confirm } = Modal;

const actionCreators = { ...PageStore.actionCreators, ...UserProfileStore.actionCreators, ...OperationsStore.actionCreators, ...TasksStore.actionCreators };

type OperationsProps =
    {
        page: PageStore.PageState,
        userProfile: UserProfileStore.UserProfileState,
        operations: OperationsStore.IOperationsState,
    } &
    typeof actionCreators &
    RouteComponentProps<{}>

interface IOperationsState {
    editingOperation: OperationsStore.AbonentOperationDTO | undefined,
    showOperationForm: boolean,
    modalZIndex: number,
    conditionsTableRowStyle: string,
    searchText: string,
    searchedColumn: string,
}

const sorter = (a: any, b: any) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b);

class Operations extends React.PureComponent<OperationsProps, IOperationsState> {
    searchInput: any;
    // getColumnSearchProps = (dataIndex: any) => ({
    //     filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
    //         <div style={{ padding: 8 }}>
    //             <Input
    //                 ref={node => {
    //                     this.searchInput = node;
    //                 }}
    //                 placeholder={`Найти ${dataIndex}`}
    //                 value={selectedKeys[0]}
    //                 onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
    //                 onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
    //                 style={{ marginBottom: 8, display: 'block' }}
    //             />
    //             <Space>
    //                 <Button
    //                     type="primary"
    //                     onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
    //                     icon={<SearchOutlined />}
    //                     size="small"
    //                     style={{ width: 90 }}
    //                 >
    //                     Найти
    //                 </Button>
    //                 <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
    //                     Сброс
    //                 </Button>
    //                 <Button
    //                     type="link"
    //                     size="small"
    //                     onClick={() => {
    //                         confirm({ closeDropdown: false });
    //                         this.setState({
    //                             searchText: selectedKeys[0],
    //                             searchedColumn: dataIndex,
    //                         });
    //                     }}
    //                 >
    //                     Фильтр
    //                 </Button>
    //             </Space>
    //         </div>
    //     ),
    //     filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    //     onFilter: (value: any, record: any) =>
    //         record[dataIndex]
    //             ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    //             : '',
    //     onFilterDropdownVisibleChange: (visible: any) => {
    //         if (visible) {
    //             setTimeout(() => this.searchInput.select(), 100);
    //         }
    //     },
    //     // render: text =>
    //     //     this.state.searchedColumn === dataIndex ? (
    //     //         <Highlighter
    //     //             highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
    //     //             searchWords={[this.state.searchText]}
    //     //             autoEscape
    //     //             textToHighlight={text ? text.toString() : ''}
    //     //         />
    //     //     ) : (
    //     //         text
    //     //     ),
    //     render: (text: any) => text,
    // });
    hasEditRight = () => {
        let result = false;
        if(this.props.userProfile){
            result = this.props.userProfile.rights.includes(USER_RIGHTS.EditAbonentOperations);
        }
        return result;
    }
    columns2 = [
        {
            title: 'Инфо',
            dataIndex: 'info',
            key: 'info',
            align: 'center' as 'center',
            render: (val: any) => <Button onClick={() => this.showPointInfo(val.id, val.number)} size="middle" shape="circle" icon={<ScheduleTwoTone twoToneColor={Const.COLORS.SvgIconColor} />} />

        },
        {
            title: 'Номер',
            dataIndex: 'number',
            key: 'number',
            sorter: (a: any, b: any) => sorter(a.number, b.number),
            //sortDirections: ['descend', 'ascend'],
            //...this.getColumnSearchProps('number'),
        },
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
            sorter: (a: any, b: any) => sorter(a.name, b.name),
            //sortDirections: ['descend','ascend'],
            //...this.getColumnSearchProps('name'),
        },
        {
            title: 'Адрес',
            dataIndex: 'address',
            key: 'address',
            sorter: (a: any, b: any) => sorter(a.address, b.address),
            //sortDirections: ['descend', 'ascend'],
            //...this.getColumnSearchProps('address'),
        },
        {
            title: 'Ресурс',
            dataIndex: 'resource',
            key: 'resource',
            render: (val: any) => <Tag key={'k1'} style={{ margin: '3px' }} color='blue'>{val}</Tag>
        },
        {
            title: 'Начало',
            dataIndex: 'start',
            key: 'start',
            render: (val: any) => moment(val).format("DD.MM.YYYY"),
        },
        {
            title: 'Окончание',
            dataIndex: 'end',
            key: 'end',
            render: (val: any) => moment(val).format("DD.MM.YYYY"),
        },
        {
            title: 'Описание',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Примечание',
            dataIndex: 'notice',
            key: 'notice',
        },
        {
            title: '',
            key: 'download',
            dataIndex: 'download',
            render: (val: any, record: any) => (
                <Space size="middle">
                    <Button onClick={() => this.props.formReport(record.info.id, moment(record.start).add(-5, 'days').toDate(), moment(record.end).add(5, 'days').toDate(), PeriodsEnum.day)} size="small">Ведомость</Button>
                </Space>
            ),
        },
        {
            title:
                <Space size="middle">
                    <Button disabled={!this.hasEditRight()} onClick={() => this.removeAllOperationsConfirm()} shape="circle" icon={<DeleteTwoTone twoToneColor={Const.COLORS.SvgIconColor} />} />
                </Space>,
            dataIndex: 'delete',
            key: 'delete',
            align: 'center' as 'center',
            width: 25,
            render: (val: any) => (
                <Space size="middle">
                    <Button disabled={!this.hasEditRight()} onClick={() => this.removeOperationConfirm(val)} shape="circle" icon={<DeleteTwoTone twoToneColor={Const.COLORS.SvgIconColor} />} />
                </Space>
            ),
        },

    ];

    constructor(props: OperationsProps) {
        super(props);

        this.state = {
            editingOperation: undefined,
            showOperationForm: false,
            modalZIndex: 0,
            conditionsTableRowStyle: '',
            searchText: '',
            searchedColumn: '',
        }
    }

    // handleReset = (clearFilters: any) => {
    //     clearFilters();
    //     this.setState({ searchText: '' });
    // };

    // handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    //     confirm();
    //     this.setState({
    //         searchText: selectedKeys[0],
    //         searchedColumn: dataIndex,
    //     });
    // };

    removeOperationConfirm = (id: number) => {
        //console.log('removeOperationConfirm', this.props.operations);
        confirm({
            title: 'Подтверждение',
            icon: <ExclamationCircleOutlined />,
            content: 'Вы действительно хотите удалить данную запись?',
            onOk: () => {
                this.props.requestAbonentsOperationsDelete([id]);
            }
        });
    }

    removeAllOperationsConfirm = () => {
        this.setState({ conditionsTableRowStyle: styles.TableRowRed });
        let operations = this.props.operations.abonentsOperations ?? [];

        confirm({
            title: 'Подтверждение',
            icon: <ExclamationCircleOutlined />,
            content: <span>Вы действительно хотите удалить <span style={{ fontWeight: 'bold', color: 'red' }}>{operations.length}</span> графиков отключений?</span>,
            onOk: () => {
                this.setState({ conditionsTableRowStyle: '' });
                this.props.requestAbonentsOperationsDelete(operations.map(item => item.id));
            },
            onCancel: () => {
                this.setState({ conditionsTableRowStyle: '' });
            }
        });
    }

    showPointInfo = (pointId: string, pointNumber: string) => {
        const source: any = new (window.NetiSource as any)(this.execute);
        const parent: any = ReactDOM?.findDOMNode(this)?.parentNode;
        this.props.showPointInfo(pointId, pointNumber, source, parent);
    }

    execute = (cmd: any) => {
        netiExecute(cmd, this.props.userProfile, this.props.changeProfile, this.props.setIsLoading, this.props.setActiveQueries, null, this.props.showModal);
    }

    componentDidUpdate(prevProps: OperationsProps) {
        if (prevProps.operations.showDialogAddNewAbonentOperationFlag !== this.props.operations.showDialogAddNewAbonentOperationFlag
            && this.props.operations.showDialogAddNewAbonentOperationFlag === true) {
            this.props.showDialogAddNewAbonentOperation(false);
            this.setState({ editingOperation: undefined, showOperationForm: true, modalZIndex: getModalZIndex() });
        }
    }

    public render() {
        let hideClass = '';
        if (this.props.operations === undefined || this.props.operations.abonentOperationTypes === undefined || this.props.operations.abonentsOperations === undefined
            || this.props.operations.isLoadingAbonentsOperations === true) {
            hideClass = styles.HideDiv;
        } else {
            hideClass = '';
        }
        return (
            <div style={{ overflow: 'auto' }} className={styles.container}>
                {hideClass !== '' ? <LoadingOutlined /> : null}
                <Table
                    className={hideClass}
                    columns={this.columns2}
                    pagination={{ showSizeChanger: true, pageSizeOptions: ["5", "10", "20", "50", "100"], defaultPageSize: 5, locale: { items_per_page: " / страницу" } }}
                    dataSource={this.props.operations.abonentsOperations?.map(item => {
                        return ({
                            key: item.id,
                            info: { number: item.abonentNumber, id: item.abonentId },
                            number: item.abonentNumber,
                            name: item.abonentName,
                            address: item.abonentAddress,
                            resource: item.resource?.shortName,
                            start: item.beginDate,
                            end: item.endDate,
                            description: this.props.operations?.abonentOperationTypes?.[item.operationTypeId].description,
                            notice: item.comment,
                            //download
                            delete: item.id,
                        })
                    })}
                    bordered
                    title={() => 'График отключений'}
                    rowClassName={this.state.conditionsTableRowStyle}
                />

                {
                    this.props.operations.abonentOperationTypes != undefined ?
                        <AbonentOperationForm
                            types={Object.values(this.props.operations.abonentOperationTypes)}
                            operation={this.state.editingOperation}
                            onHide={() => { this.setState({ showOperationForm: false, editingOperation: undefined }) }}
                            visible={this.state.showOperationForm}
                            zIndex={this.state.modalZIndex}
                            writeNewOperations={(filter: SPointsFilter, template: AbonentOperationDTO) => { this.props.requestAbonentsOperationsAdd(filter, template), this.setState({ editingOperation: undefined }) }}
                            writeOperation={(operation: AbonentOperationDTO) => {/*this.props.req(condition), this.setState({editingCondition: undefined})*/ }}

                            groupPoints={(this.props.userProfile) ? this.props.userProfile.groupPoints : {}}
                            requestGroupPoints={this.props.requestGroupPoints}
                            setIsLoading={this.props.setIsLoading}
                            userGroups={(this.props.userProfile) ? this.props.userProfile.userGroups : []}
                            groups={(this.props.userProfile) ? this.props.userProfile.userGroups : []}
                        />
                        : null
                }
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            page: state.page,
            userProfile: state.userProfile,
            operations: state.operations,
        }
    },
    actionCreators
)(Operations as any);
