import * as React from 'react';

import styles from '../../resources/Page.module.less';
import { Button, Modal, Select, Input, Row, Col, Table, Tag, DatePicker, Tabs, Tooltip, Space } from 'antd';

import { arrayToString, deepCopyObj, getModalZIndex, message } from '../../utils/Common';
import { ALL_GROUP_ID, MODAL_WRAP_CLASSNAME } from '../../utils/Const';
import { SBilContactDTO, SBillingCompanyInfoDTO, SBillingStatisticsDTO, SBillingTariffDTO } from './BillingDecls';
import moment from 'moment';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import * as Const from '../../utils/Const';
import Search from 'antd/lib/input/Search';
import CompanyEditForm from './CompanyEditForm';
import { sendRequestToBackend } from '../../utils/AuthUtils';
import TariffEditForm from './TariffEditForm';

const { Option } = Select;

type Props = {
    onHide(): void,
    visible: boolean,
    tariffs: SBillingTariffDTO[],
    setIsLoading: any,
}

type State = {
    statistics: SBillingStatisticsDTO | undefined,
}

export default class StatisticsForm extends React.Component<Props, State> {
    constructor(props: Props, state: State) {
        super(props);

        this.state = {
            statistics: undefined,
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.visible == false && this.props.visible == true) {
            sendRequestToBackend(
                null,
                'billing/billingstatistics',
                (response: any) => {
                    if (response != null) {
                        this.setState({statistics: response});
                        this.makeDatasource(response);
                    } else {
                        message.error('Запрос завершился неудачей');
                    }
                },
                this.props.setIsLoading,
                (error: any) => {
                    console.log(error);
                    message.error(error);
                }
            );
        }
    }

    onOk = () => {

    };

    private dataSource1: any = undefined;
    private dataSource2: any = undefined;
    private dataSource3: any = undefined;

    makeDatasource = (s: SBillingStatisticsDTO | undefined) => {
        if (s == undefined) {
            this.dataSource1 = undefined;
            this.dataSource2 = undefined;
            this.dataSource3 = undefined;
        } else {
            this.dataSource1 = [
                {
                    col1: 'Договоры',
                    col2: s.contractsCount.toString(),
                },
                {
                    col1: 'Абоненты',
                    col2: s.pointsCount.toString(),
                }
            ];

            this.dataSource2 = Object.keys(s.tariffsCount).map(x => {
                const tariff = this.props.tariffs.find(y => y.id === Number(x));
                return {
                    col1: tariff?.name,
                    col2: s.tariffsCount[Number(x)],
                }
            });

            this.dataSource3 = [
                {
                    col1: 'Доход (руб/мес)',
                    col2: s.income.toString(),
                },
            ];
        }
    }

    private columns1 = [
        {
            dataIndex: "col1",
            title: "Договоры/Абоненты",
        },
        {
            dataIndex: "col2",
            title: "Кол-во",
        },

    ];

    private columns2 = [
        {
            dataIndex: "col1",
            title: "Тарифы",
        },
        {
            dataIndex: "col2",
            title: "Кол-во",
        },
    ];

    private columns3 = [
        {
            dataIndex: 'col1',
            title: ' ',
        },
        {
            dataIndex: "col2",
            title: ' ',
        },
    ];

    render() {
        return (
            <Modal
                title={ 'Статистика' }
                centered={true}
                wrapClassName={MODAL_WRAP_CLASSNAME}
                width={400}
                //zIndex={this.props.zIndex}
                open={this.props.visible}
                cancelText='Закрыть'
                okText='Выбрать'
                okButtonProps={{ style: { display: 'none' } }}
                // footer={[
                //     <div style={{ display: 'flex', alignItems: 'baseline' }}>
                //         <Button key="1" type='primary' onClick={this.props.onHide} style={{marginLeft: 'Auto'}}>Отмена</Button>
                //         <Button key="2" type='primary' onClick={this.onOk}>{this.props.operation === undefined ? "Добавить" : "Изменить"}</Button>
                //     </div>
                // ]}
                onCancel={() => { this.props.onHide() }}
            >
                <div className={styles.FormWrapper}>
                    <div style={{ overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ padding: '4px'}}>
                            <Space direction='vertical'>
                                <Table 
                                    columns={this.columns1} dataSource={this.dataSource1} showSorterTooltip={false}
                                    scroll={{ y: 'auto' }} bordered size='small'
                                    pagination={false}
                                />
                                <Table 
                                    columns={this.columns2} dataSource={this.dataSource2} showSorterTooltip={false}
                                    scroll={{ y: 'auto' }} bordered size='small'
                                    pagination={false}
                                />
                                <Table 
                                    columns={this.columns3} dataSource={this.dataSource3} showSorterTooltip={false}
                                    scroll={{ y: 'auto' }} bordered size='small'
                                    pagination={false}
                                />
                            </Space>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}