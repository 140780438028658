import * as React from 'react';
import {Dropdown, Table, Menu, Typography, Tooltip, Button, Row, Col, Input, Space, Tag, Modal } from 'antd';
import {
    EditTwoTone,
    DeleteTwoTone,
    DownloadOutlined,
    QuestionCircleTwoTone,
    ExclamationCircleOutlined,
    LoadingOutlined,
    PlusCircleTwoTone,
} from '@ant-design/icons';

import {UserContact, EventType, SendingReport} from '../../store/SettingsStore';
import ContactForm from '../ContactForm';
import * as Const from '../../utils/Const';
import { BACKEND_URL, getHeaders, sendRequestToBackend } from '../../utils/AuthUtils';
import { convertBase64ToBinary, message, saveFile } from '../../utils/Common';

import styles from '../../resources/Settings.module.less';
import pageStyles from '../../resources/Page.module.less';

const { Column } = Table;
const { confirm } = Modal;


export type ContactsTableProps = {
    eventTypes: EventType[] | undefined,
    contacts: UserContact[],
    hasEditRight: boolean,
    sendingReports:  {[userContactId: string] : SendingReport[]} | undefined,
    isSendingReportsLoading: boolean,

    addContact: any,
    editContact: any,
    editSendingReport: any,
    deleteSendingReport: any,
    deleteContact: any,
    closeSession: any,
}
export type ContactsTableState = {
    contactXlsDownloaded: string[],
    showContactForm: boolean,
    editingContactId: string | undefined,
    showSendingReportForm: boolean,
    editingSendingReport: SendingReport | undefined,
    editingSendingReportContactId: string,
}

export class ContactsTable extends React.PureComponent <ContactsTableProps, ContactsTableState > {
    constructor(props: ContactsTableProps){
      super(props);
  
    
      this.state = {
        contactXlsDownloaded: [],
        showContactForm: false,
        editingContactId: undefined,
        showSendingReportForm: false,
        editingSendingReport: undefined,
        editingSendingReportContactId: '',
    }
    }
 
    deleteSendingReportConfirm(sr: SendingReport) {
        let that = this;
        confirm({
            title: 'Подтверждение',
            icon: <ExclamationCircleOutlined />,
            content: 'Вы действительно хотите удалить данное оповещение?',
            onOk() {
                that.props.deleteSendingReport(sr);
            },
        });
    }
    
    extractAllEventsTypesNames = (messageSendTypesAbonents: {[eventId: number]: string[] | null}) : string[] => {
        let ets: EventType[] = this.props.eventTypes ?? [];
        let res: string[] = [];

        for (const et of ets) {
            if (messageSendTypesAbonents[et.id] !== undefined) {
                res.push(et.name);
            }
        }

        return res;
    }

    downloadContactXls = (id: string) => {
        if (!this.state.contactXlsDownloaded.includes(id)) {
            this.setState({contactXlsDownloaded: [...this.state.contactXlsDownloaded, id]});

            sendRequestToBackend(
                id,
                'Settings/export_contact_events',
                (response: any) => {
                    saveFile(id + '.csv', convertBase64ToBinary(response));
                    let tmp = [...this.state.contactXlsDownloaded];
                    if (tmp.indexOf(id) !== -1) {
                        tmp.splice(tmp.indexOf(id), 1);
                    }
                    this.setState({contactXlsDownloaded: tmp});
                },
                null,
                (error: any) => {
                    let tmp = [...this.state.contactXlsDownloaded];
                    if (tmp.indexOf(id) !== -1) {
                        tmp.splice(tmp.indexOf(id), 1);
                    }
                    this.setState({contactXlsDownloaded: tmp});
                    message.error('Ошибка:' + error);
                },
                this.props.closeSession
            );
        }
    }

    render(){
return(
    <>
    <Table 
        dataSource={
            Object.values(this.props.contacts ?? {}).map(item =>
                ({
                    key: item.id,
                    edit: item,
                    delete: item,
                    xls: item.id,
                    messages: item.count,
                    email: item.clientId,
                    sendingReports: this.props.sendingReports === undefined ? undefined : (this.props.sendingReports[item.id] ?? []),
                    tags: this.extractAllEventsTypesNames(item.messageSendTypesAbonents ?? {}),

                    sendTypeId: item.sendTypeId
                })
            )
        }
        pagination={false}
    >
        <Column 
            title=""
            width={65}
            dataIndex="edit" 
            key="edit"
            render={(contact, record) => (
                <Button
                    disabled={!this.props.hasEditRight}
                    shape="circle"
                    icon={<EditTwoTone twoToneColor={Const.COLORS.SvgIconColor}/>}
                    onClick={() => this.props.editContact(contact)}
                />
            )}
            
        />
        <Column 
            title=""
            width={65}
            dataIndex="delete"
            key="delete"
            render={(value, record) => (
                <Button 
                    disabled={!this.props.hasEditRight}
                    shape="circle" 
                    icon={<DeleteTwoTone twoToneColor={Const.COLORS.SvgIconColor}/>}
                    onClick={() => {this.props.deleteContact(value)}}
                />
            )}
        />
        <Column 
            title="e-mail/telegram"
            width={250}
            dataIndex="email" 
            key="email" 
        />
        <Column
            title={<div>Рассылаемые отчеты</div>}
            dataIndex="sendingReports"
            key="sendingReports"
            render={(tags: SendingReport[], record: any) => (
                <>
                    {tags === undefined || this.props.sendingReports === undefined ? <LoadingOutlined/> :
                        tags.map(item => (
                            <Tag 
                                color="cyan" 
                                closable 
                                onClose={(e: any) => {e.preventDefault(); this.deleteSendingReportConfirm(item)}}
                                onClick={() => this.props.editSendingReport(record.edit, item)}
                                key={item.id}
                                className={pageStyles.TagMargin}
                            >
                                {item.description}
                            </Tag>
                        ))}

                    {
                        record?.sendTypeId === Const.SEND_TYPE_ID_EMAIL ?
                        <Button shape="circle"
                            disabled={!this.props.hasEditRight}
                            onClick={() => this.props.editSendingReport(record.edit, undefined)}
                            icon={<PlusCircleTwoTone twoToneColor="#52c41a" />}
                        /> : null
                    }
                </>
            )}
        />
        <Column
            title="События"
            dataIndex="tags"
            key="tags"
            render={(tags: any[]) => (
                <>
                    {tags.map(tag => (
                        <Tag color="blue" key={tag} className={pageStyles.TagMargin}>
                            {tag}
                        </Tag>
                    ))}
                </>
            )}
        />
        <Column 
            title={
                <Space>
                    Подряд
                    <Tooltip placement="top" title={'Количество сообщений, приходящих на почту/мобильный телефон при срабатывании события'}>
                        <QuestionCircleTwoTone  className={styles.Question}/>
                    </Tooltip>
                </Space>
            }
            dataIndex="messages"
            key="messages" 
        />
        <Column
            title="Экспорт"
            dataIndex="xls"
            key="xls"
            render={(value, record: any) => (
                <Space size="middle">
                    <Button disabled={!this.props.hasEditRight} onClick={() => this.downloadContactXls(record.key)} loading={this.state.contactXlsDownloaded.includes(record.key)} type="primary" shape="circle" icon={<DownloadOutlined />} />
                </Space>
            )}
        />
    </Table>
    <Space className={pageStyles.TopMargin}>                
        <Button disabled={!this.props.hasEditRight} type="primary" onClick={this.props.addContact}>
            Добавить контакт
        </Button>
    </Space>
    </>);
    }
}

