import { CommentOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { Components } from 'antd/lib/date-picker/generatePicker';
import internal from 'assert';
import moment from 'moment';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { PeriodsEnum, ResponseStatusesEnum, SPointsFilter, SResponseDTO } from '../decl';
import { BACKEND_URL, getHeaders } from '../utils/AuthUtils';
import { message, convertBase64ToBinary, saveFile } from '../utils/Common';
import { CloseSessionAction } from './Page';

export interface IMonthReportsState {
    listReportItems: {title: string, code: string}[] | undefined;
    isLoadingListReportItems: boolean;
    isLoadingListReportFile: boolean;
    isMonthlyPacketLoading: boolean;
    isMonthlyPacketFileLoading: boolean,
    monthlyPackets: SMonthlyPacketDTO[] | undefined;
    currentYear: number,
}

export interface SListReportRequest
{
    reportCode: string,
    filter: SPointsFilter,
    beginDate: Date,
    endDate: Date,
    period: PeriodsEnum | null,
    parameters: {[key: string] : string},
}
export interface SMonthlyPacketDTO
{
    id: number,
    monthId: number,
    packetTypeId: number,
    createdTime: Date,
    lastDay?: number,
    month: number,
    year: number,
    monthlyPacketTypeName: string,
    monthDate: Date,
}

interface SetState { type: 'SET_MONTH_REPORTS_STATE'; value: Partial<IMonthReportsState> };
interface ReceiveListReportItems { type: 'RECEIVE_LIST_REPORT_ITEMS', items: any};
interface ReceiveMonthlyPackets { type: 'RECEIVE_MONTHLY_PACKETS', packets: any};


export type KnownAction = SetState | CloseSessionAction | ReceiveListReportItems | ReceiveMonthlyPackets;

export const actionCreators = {
    requestListReportItems: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({type: 'SET_MONTH_REPORTS_STATE', value: {isLoadingListReportItems: true}});
        
        if (appState) {
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
                //body: JSON.stringify()
            };
            fetch(BACKEND_URL + 'Reports/listreportitems', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        dispatch({type: 'RECEIVE_LIST_REPORT_ITEMS', items: data.body })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                    }
                    else {
                        message.error('Ответ не получен.');
                    }
                    dispatch({type: 'SET_MONTH_REPORTS_STATE', value: {isLoadingListReportItems: false}});
                })
                .catch(error => {
                    message.error('Ошибка:' + error);
                    console.log(error);
                    dispatch({type: 'SET_MONTH_REPORTS_STATE', value: {isLoadingListReportItems: false}});
                });   
        }
    },

    requestMakeListReport: (request: SListReportRequest): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({type: 'SET_MONTH_REPORTS_STATE', value: {isLoadingListReportFile: true}});
        
        if (appState) {
            message.success("Запрос на формирование ведомости отправлен.");
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify(request)
            };
            fetch(BACKEND_URL + 'Reports/makelistreport', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        dispatch({ type: 'SET_MONTH_REPORTS_STATE', value: {isLoadingListReportFile: false} })
                        saveFile(data.body.fileName, convertBase64ToBinary(data.body.base64data))
                        message.success("Ведомость сформирована.")
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        notification['error']({message: 'Ошибка.',  description: data.message, duration: 0});
                    }
                    else {
                        notification['error']({message: 'Ошибка.',  description: 'Ответ не получен.', duration: 0});
                    }
                    dispatch({type: 'SET_MONTH_REPORTS_STATE', value: {isLoadingListReportFile: false}});
                })
                .catch(error => {
                    notification['error']({message: 'Ошибка.',  description: error, duration: 0});
                    console.log(error);
                    dispatch({type: 'SET_MONTH_REPORTS_STATE', value: {isLoadingListReportFile: false}});
                });
            
        }
    },

    requestMonthlyPackets: (year: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({type: 'SET_MONTH_REPORTS_STATE', value: {monthlyPackets: undefined, isMonthlyPacketLoading: true, currentYear: year}});
        
        if (appState) {
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify(year)
            };
            fetch(BACKEND_URL + 'Reports/monthlypackets', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        dispatch({type: 'RECEIVE_MONTHLY_PACKETS', packets: data.body })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                    }
                    else {
                        message.error('Ответ не получен.');
                    }
                    dispatch({type: 'SET_MONTH_REPORTS_STATE', value: {isMonthlyPacketLoading: false}});
                })
                .catch(error => {
                    message.error('Ошибка:' + error);
                    console.log(error);
                    dispatch({type: 'SET_MONTH_REPORTS_STATE', value: {isMonthlyPacketLoading: false}});
                });
            
        }
    },

    requestMonthlyPacketFile: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({type: 'SET_MONTH_REPORTS_STATE', value: {isMonthlyPacketFileLoading: true}});
        
        if (appState) {
            message.success("Запрос на получение пакета ведомостей отправлен.");
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify(id)
            };
            fetch(BACKEND_URL + 'Reports/monthlypacketfile', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        dispatch({ type: 'SET_MONTH_REPORTS_STATE', value: {isMonthlyPacketFileLoading: false} })
                        saveFile(data.body.fileName, convertBase64ToBinary(data.body.base64data))
                        message.success("Пакет ведомостей получен.")
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                    }
                    else {
                        message.error('Ответ не получен.');
                    }
                    dispatch({type: 'SET_MONTH_REPORTS_STATE', value: {isMonthlyPacketFileLoading: false}});
                })
                .catch(error => {
                    message.error('Ошибка:' + error);
                    console.log(error);
                    dispatch({type: 'SET_MONTH_REPORTS_STATE', value: {isMonthlyPacketFileLoading: false}});
                });
            
        }
    },

};

export const reducer: Reducer<IMonthReportsState> = (state: IMonthReportsState | undefined, incomingAction: Action): IMonthReportsState => {
    if (state === undefined) {
        return { isLoadingListReportItems: false, isLoadingListReportFile: false, listReportItems: undefined, 
                 monthlyPackets: undefined, isMonthlyPacketLoading: false, currentYear: moment().year(), isMonthlyPacketFileLoading: false};
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "SET_MONTH_REPORTS_STATE":
            return {
                ...state,
                ...action.value
            };
        case 'RECEIVE_LIST_REPORT_ITEMS':
            return {...state, listReportItems: action.items };
        case 'RECEIVE_MONTHLY_PACKETS':
            return {...state, monthlyPackets: action.packets };
        default:
            return state;
    }
};
