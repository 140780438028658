import { EditTwoTone, MenuOutlined, DragOutlined } from '@ant-design/icons';
import { Button, Checkbox, Modal, Table } from 'antd';
import Column from 'antd/lib/table/Column';
import * as React from 'react';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
//import { SortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { AlignType } from 'rc-table/lib/interface';

import { MenuItemDesc } from '../decl';
import * as Const from '../utils/Const';
import { getModalZIndex } from '../utils/Common';
import styles from '../resources/Settings.module.less'

const arrayMove= (array: any, fromIndex: number, toIndex: number):[] =>{
    const newArray: any = [...array];
    const startIndex = fromIndex < 0 ? newArray.length + fromIndex : fromIndex;

    if (startIndex >= 0 && startIndex < newArray.length) {
        const endIndex = toIndex < 0 ? newArray.length + toIndex : toIndex;

        const [item] = newArray.splice(fromIndex, 1);
        newArray.splice(endIndex, 0, item);
    }
    return newArray;
}

const orderedArrayCreate = (array: any, selectedIds: number[]):any[] => {
    const newArray: any[] = [];

    //Все выбранные элементы в заданном порядке.
    selectedIds.forEach((id: number) => {
        const menuItem = array.find((item: any) => item.key === id);
        if (menuItem) newArray.push(menuItem);
    });

    //Все остальные элементы.
    array.forEach((item:any)=>{
        if(item.key !== 0 && !selectedIds.includes(item.key)){
            newArray.push(item)
        }
    });
    return newArray;
}

//Модальное окно настройки пунктов меню

type Props = {
    disabled: boolean,
    menu: MenuItemDesc[],
    displayMenuItems: number[]
    changeMenuItems: (displayMenuItems: string | null) => void,
    windowHeight: number
}

type State = {
    visible: boolean;
    zIndex: number,
    displayMenuItems: number[];
    initialized: boolean
}

const SortableItem = SortableElement((props: any) => <tr {...props} />);
const SortableComponent = SortableContainer((props: any) => <tbody {...props} />);
const DragHandle = SortableHandle(() => (
    <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
  ));

type SortableTableProps = {
    originalData: {
        key: number,
        name: string,
        checkBox: boolean
    }[],
    displayMenuItems: number[],
    scrollY: number,
    initialized: boolean,
    onInitialize: any,
    onChangeMenuItems: any
}

type TableRecordType = {
    key: number,
    name: string,
    checkBox: boolean
}

type SortableTableState = {
    dataSource: TableRecordType[],
}

//Класс сортируемой таблицы с пунктами меню.
class SortableTable extends React.Component<SortableTableProps, SortableTableState> {
    state = { 
        dataSource: [] as TableRecordType[],
     };

     componentDidMount=()=>{
        this.setState( { 
            dataSource: orderedArrayCreate(this.props.originalData, this.props.displayMenuItems),
         });
     }

     componentDidUpdate = (prev: SortableTableProps) =>{
        if(!this.props.initialized){
            this.setState( { 
                dataSource: orderedArrayCreate(this.props.originalData, this.props.displayMenuItems),
             });
             this.props.onInitialize();
        }
     }

    onSortEnd = ( {oldIndex, newIndex}:any) => {
      if (oldIndex !== newIndex) {
        const newData: [] = arrayMove(this.state.dataSource, oldIndex, newIndex);
        this.setState({ dataSource: newData });
        const displayMenuItems = this.state.dataSource.filter(
            item=>(item.key !== 0 && this.props.displayMenuItems.includes(item.key)) ).map(item=>item.key);
        this.props.onChangeMenuItems(displayMenuItems);
        }
    };
  


    DraggableContainer = (props: any) => (
      <SortableComponent
        useDragHandle
        disableAutoscroll
        helperClass={styles.MenuRowDragging}
        onSortEnd={this.onSortEnd}
        {...props}
      />
    );
  
    DraggableBodyRow = ({ className, style, ...restProps }: any) => {
      const { dataSource } = this.state;
      const index = dataSource.findIndex((x:any) => (x && (x.key === restProps['data-row-key'])));
      return <SortableItem index={index} {...restProps} />;
    };

    onAllChange = (e:any) => {
        const displayMenuItems = e.target.checked ?
            this.state.dataSource.map(item=>item.key) :
            this.state.dataSource.filter(item=>!item.checkBox).map(item=>item.key);
        this.props.onChangeMenuItems(displayMenuItems);
    }

    //Изменение чекбокса выбора меню.
    onChange = (e: any, id: number) => {
        const  displayMenuItems = (e.target.checked) ?
            this.state.dataSource.filter(
                item=>(item.key !== 0 && this.props.displayMenuItems.includes(item.key)) || item.key===id).map(item=>item.key):
            this.state.dataSource.filter(
                item=>(item.key !== 0 && this.props.displayMenuItems.includes(item.key)) && item.key!==id).map(item=>item.key);

        this.props.onChangeMenuItems(displayMenuItems);
    }


    render() {
      const dataSource: any = this.state.dataSource;
 
      const allChecked: boolean = (this.props.displayMenuItems.length === this.state.dataSource.length);

      const columnsMenu = [
        {
            title: ()=>{
                return <DragOutlined style={{ fontSize: "22px" }} twoToneColor={Const.COLORS.SvgIconColor}/>;
            },
            dataIndex: 'sort',
            width: 54,
            align: 'center' as AlignType,
            render: () => <DragHandle />,
        },
        {
            title: 'Разделы',
            dataIndex: 'name',
        },
        {
            title: () => { 
                return <Checkbox checked={allChecked} onChange={this.onAllChange}/>;
              },
             width: 54,
            align: 'center' as AlignType,
            key: "checkBox", 
            dataIndex: "checkBox",
            render: (value: boolean, record: TableRecordType) => 
                <Checkbox 
                    disabled= {value === false}
                    checked={value === true ?
                                 ((record.key === 0 && allChecked) ||
                                     this.props.displayMenuItems.indexOf(record.key) !== -1) :
                                  true}
                    onChange={(e: any) => this.onChange(e, record.key)}
                />
            

         },
      ];      
      return (
        <Table
            pagination={false} 
            showHeader={true} 
            bordered={true}
            dataSource={dataSource}
            columns={columnsMenu}
            rowKey="key"
            components={{
            body: {
              wrapper: this.DraggableContainer,
              row: this.DraggableBodyRow,
            },
          }}
          scroll={{ y: this.props.scrollY }}
        />
      );
    }
  }
  

export default class ChangeMenuForm extends React.Component<Props, State> {
    state = { visible: false, zIndex: 0, displayMenuItems: this.props.displayMenuItems, initialized: false };

    showModal = () => {
        this.setState({
            visible: true,
            zIndex: getModalZIndex(),
            displayMenuItems: this.props.displayMenuItems
        });
    };

    hideModal = () => {
        this.setState({
            visible: false,
            initialized: false
        });
    };

    onOk = () => {
        let res = this.state.displayMenuItems.join(',');
        this.props.changeMenuItems(res);

        this.setState({
            visible: false,
            initialized: false
        });
    };

    //Наполнение окна редактирования отображения пунктов меню
    render() {
        const data: TableRecordType[]  = this.props.menu.map((item) => {return {key:item.id, name:item.name, checkBox: item.editable } as TableRecordType});

        const scrollY = this.props.windowHeight < 700 ? 350 : this.props.windowHeight - 450;
        const modalStyles = this.props.windowHeight < 800 ? { top: '20px' } : {}
        return (
            <>
                <Button shape="circle"
                    disabled={this.props.disabled}
                    onClick={this.showModal}
                    icon={<EditTwoTone twoToneColor={Const.COLORS.SvgIconColor}/>}
                />
                <Modal
                    style={modalStyles}
                    title="Настройка меню"
                    wrapClassName={Const.MODAL_WRAP_CLASSNAME}
                    zIndex={this.state.zIndex}
                    open={this.state.visible}
                    onOk={this.onOk}
                    onCancel={this.hideModal}
                    okText="Применить"
                    cancelText="Отмена"
                    width= {350}
                >
                    <SortableTable 
                        originalData={data}
                        displayMenuItems= {this.state.displayMenuItems}
                        scrollY={scrollY}
                        initialized={this.state.initialized}
                        onInitialize={()=>this.setState({initialized:true})}
                        onChangeMenuItems={(displayMenuItems: number[])=>{
                            this.setState({displayMenuItems:displayMenuItems});
                        }}
                     />
                </Modal>
            </>
        );
    }
}
