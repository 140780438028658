import * as React from 'react';
import { Modal, message, Button, Input, Tooltip } from 'antd';
import { connect } from 'react-redux';

import Login from '../Login2';

import { ApplicationState } from '../../store';
import * as PageStore from '../../store/Page';
import * as UserProfileStore from '../../store/UserProfile';
import { RouteComponentProps } from 'react-router';
import styles from '../../resources/Page.module.less';
import { SPointsFilter, Point, ICommand, MenuItemDesc, USER_RIGHTS } from "../../decl"
import { MiddleContentMessageBlock } from "../HeaderForm";

import {
    QuestionCircleTwoTone
} from '@ant-design/icons';


const actionCreators = { ...PageStore.actionCreators, ...UserProfileStore.actionCreators };

type HeaderProps =
    { userProfile: UserProfileStore.UserProfileState, windowWidth: number, isMenuCollapsed: boolean } &
    typeof actionCreators &
    RouteComponentProps<{}>

interface HeaderState {
}

class AdminHeaderForm extends React.PureComponent<HeaderProps, HeaderState>{
    constructor(props: HeaderProps) {

        super(props);
    }

    onSaveProfile = (e: any) => {
        if (this.props.userProfile) {
            this.props.saveAllUserProfile(() => {
                message.success('Профиль пользователя сохранён на сервере.');
            });
        }
    }

    onDeleteProfile = (e: any) => {
        if (this.props.userProfile) {
            this.props.requestAllUserProfile(true, (isLogged: boolean, profile: any, menu: MenuItemDesc[], defaultPoint: Point) => {
                if (defaultPoint) {
                    this.props.setPoint(defaultPoint.id, defaultPoint.number);
                }
                message.success('Все настройки профиля пользователя установлены в значения по умолчанию.');
                this.gotoPage('/');
                this.gotoPage('/settings');
            });
        }
    }
    gotoPage = (url: any) => {
        Modal.destroyAll();
        this.props.history.push(url);
    }

    render() {
        const messageAsIcons = (!this.props.isMenuCollapsed && this.props.windowWidth < 1510) ||
            (this.props.isMenuCollapsed && this.props.windowWidth < 1470);
        const hideEmail = false;
        const isSplit = this.props.windowWidth >= 1420;

        return (
            <div className={styles.flexcontainer}>
                <div className={styles.mbitem}>
                    <MiddleContentMessageBlock windowSize={this.props.windowWidth} maxWindowSize={600} onlyIcon={messageAsIcons} hideEmail={hideEmail} isSplit={isSplit} />
                </div>
                <div className={styles.flexitemb5}>
                    <Tooltip placement="top" title={'Руководство администратора'}>
                        <a href="Documents/Руководство администратора ИС Теплоинформ.pdf" target="_blank">
                            <QuestionCircleTwoTone style={{ fontSize: 30, margin: 5 }} />
                            <span style={{ textDecoration: 'underline', verticalAlign: 'super', marginLeft:5 }}>Руководство администратора</span>
                        </a>
                    </Tooltip>
                </div>
                <div className={styles.flexuseritem}>
                    <Login />
                </div>
            </div>
        );
    }
}


export default connect(
    (state: ApplicationState) => {
        return {
            userProfile: state.userProfile,
            windowWidth: state.page.windowWidth,
            isMenuCollapsed: state.siderMenu?.collapsed
        }
    },
    actionCreators
)(AdminHeaderForm as any);
