import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { Layout } from 'antd';

import { ApplicationState } from '../store';
import * as PageStore from '../store/Page';
import * as TasksStore from '../store/Tasks';
import * as UserProfileStore from '../store/UserProfile';
import * as ReportsStore from '../store/Reports';
import { BaseProfile, RequestContext, ICommand } from '../decl';
import NetiContainer from '../components/NetiContainer';
import * as Const from '../utils/Const';
import { message, isActiveTasksChanged } from '../utils/Common';
import { showColumnsSelector } from '../components/Page/ColumnsSelector';

const { Header, Content } = Layout;


const actionCreators = { ...PageStore.actionCreators, ...UserProfileStore.actionCreators, ...ReportsStore.actionCreators, ...TasksStore.actionCreators };

type ReportsProps =
    { page: PageStore.PageState, tasks: TasksStore.TasksState, userProfile: UserProfileStore.UserProfileState, reports: ReportsStore.IReportsState } &
    typeof actionCreators &
    RouteComponentProps<{}>

interface IReportsState {

}

class Reports extends React.PureComponent<ReportsProps, IReportsState> {
    constructor(props: ReportsProps) {
        super(props);
        this.onGridReset = this.onGridReset.bind(this);
        this.onGridCommand = this.onGridCommand.bind(this);
        this.onExternalCommand = this.onExternalCommand.bind(this);
    }

    componentDidMount() {
        window.call_page_mount('/reports');
    }

    componentWillUnmount() {
        window.call_page_unmount('/reports');
    }
    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
        if (isActiveTasksChanged(this.props.tasks.activeTasks, prevProps.page.activeTasks)) {
            this.invalidateGrid();
        } 
    }

    onGridReset(control: any){
        if(control != null){
            const columnsLayout = (this.props?.userProfile?.profile.Reports?.ColumnsLayout) ?? '';
            const resources: string | null = (this.props?.page.resources) ?? (this.props?.userProfile?.profile?.Common?.Resources) ?? null;
            const paymentTypes: string | null = (this.props?.page.paymentTypes) ?? null;
            const paymentPeriods: string | null = (this.props?.page.paymentPeriods) ?? null;
            const filter = {
                __type: 'SGridPointsFilter:#SCTeploinformSite3',
                Groups: (this.props?.userProfile?.profile?.Common?.Groups) ?? null,
                Points: this.props?.userProfile?.profile?.Common?.Points,
                Resources: resources,
                SystemNumbers: null,
                Text: this.props.reports.textFilter,
                ColumnsLayout: columnsLayout,
                SelectedRowId: this.props?.page?.pointId,
                OnlyWithContract: this.props?.page.onlyWithContract ?? false,
                PaymentTypes: paymentTypes,
                PaymentPeriods: paymentPeriods
            };
            this.props.setNeedRefresh(false);

            const showOnePoint: boolean = (this.props?.userProfile?.profile.Reports?.ShowOnePoint) ?? false;
            if(!showOnePoint){
                this.props.setIsLoading(true);
                window.call_refreshgrid(
                    control,
                    filter,
                    'refreshGrid',
                     ()=>{
                        this.props.setIsLoading(false, 500);
                    }
                );
            }
            else{
                const cmd: ICommand = {
                    Source: null,
                    Name: 'refreshTabs',
                    Params: {control: control},
                    Callback: null,
                    ExceptionCallback: null
                };
                window.call_execute(cmd);
            }
        }
    }

    onGridCommand(cmd: any) {
        if (cmd.Name === Const.DATA_RESET_DONE_COMMAND){
            this.props.setIsLoading(false, 100);
            if(!cmd.Params || cmd.Params !== 4){
                const pointsCount = cmd.Source.getDataLength();
                this.props.setSelectedPointsCount(pointsCount);
            }
            else if (cmd.Params === 4){
                this.props.closeSession("Сессия закрыта");
            }
        }
        else if(cmd.Name === Const.ON_COLUMNS_REORDER_COMMAND){
            const value: string = JSON.stringify(cmd.Params);
            this.props.changeProfile(Const.PROFILE_REPORTS, {ColumnsLayout: value});
        }
        else if (cmd.Name === Const.ON_COLUMNS_SELECT_COMMAND) {
            showColumnsSelector(cmd.Params.columns, cmd.Params.type === 2, 7, this.props.showModal, cmd.Callback);
        }
        else if(cmd.Name === Const.SET_RESOURCES_COMMAND){
            const value: string | null = cmd.Params.Value;
            if (value !== this.props.page.resources) {
                this.props.setResources(value);
                this.props.setNeedRefresh(true);

                const messageText = value === null ?
                    'Дополнительный фильтр по ресурсам отменён.' :
                    'Применён дополнительный фильтр по ресурсам: ' + cmd.Params.Caption;
                const messageKey = 9001;
                message.success({ content: messageText, duration: 5, key: messageKey, onClick: () => message.destroy(messageKey) });
            }
        }
        else if(cmd.Name === Const.SET_PAYMENTTYPES_COMMAND){
            const value: any = cmd.Params;
            const changeMode = value.onlyWithContract !== this.props.page.onlyWithContract;
            if (changeMode ||
                value.paymentTypes !== this.props.page.paymentTypes ||
                value.paymentPeriods !== this.props.page.paymentPeriods) {

                this.props.setPaymentTypes(value.onlyWithContract, value.paymentTypes, value.paymentPeriods);
                this.props.setNeedRefresh(true);

                if (changeMode) {
                    const messageText = value.onlyWithContract ?
                        'Применён дополнительный фильтр по типам оплаты.' :
                        'Дополнительный фильтр по типам оплаты отменён.';
                    const messageKey = 9001;
                    message.success({ content: messageText, duration: 5, key: messageKey, onClick: () => message.destroy(messageKey) });
                }
            }
        }
        else if(cmd.Name === Const.SET_PACKET_MODE_COMMAND){
            this.props.setPacketMode(cmd.Params);
        }
        else{
            if(cmd.Name === Const.POINT_SELECT_COMMAND){
                this.props.setPoint(cmd.Params.id, cmd.Params.number);
            }
            window.call_execute(cmd);
        }
    }

    onExternalCommand(cmd: ICommand) {
        this.props.setCommandToExecute(null);
        if (cmd.Name === Const.INVALIDATE_COMMAND) {
            cmd.Callback(cmd);
        }
        else {
            window.call_execute(cmd);
        }
    }

    invalidateGrid = () => {
            const cmd: ICommand = {
                Source: null,
                Name: Const.INVALIDATE_COMMAND,
                Params: null,
                Callback: null,
                ExceptionCallback: null
            };
            this.props.setCommandToExecute(cmd);
    }

    public render() {
        const showOnePoint: boolean = (this.props?.userProfile?.profile.Reports?.ShowOnePoint) ?? false;
        const controlId: number = showOnePoint ? 109 : 4;
        const columnsLayout = (this.props?.userProfile?.profile.Reports?.ColumnsLayout) ?? '';
        const context: RequestContext = { LocalPath: '/reports', Properties: [{ Key: 'ColumnsLayout', Value: columnsLayout }] };
        return (
            <Layout>
                <NetiContainer
                    controlId={controlId}
                    needRefresh={this.props.reports.needRefresh}
                    commandToExecute={this.props.reports.commandToExecute}
                    onReset={this.onGridReset}
                    onCommand={this.onGridCommand}
                    onExternalCommand={this.onExternalCommand}
                    context={context}
                    userProfile={this.props.userProfile}
                    setProfile={this.props.changeProfile}
                    setIsLoading={this.props.setIsLoading}
                    setActiveQueries={this.props.setActiveQueries}
                    isNetiReady={this.props.page.isNetiReady}>
                </NetiContainer>
            </Layout>
        );
    }    
}

export default connect(
    (state: ApplicationState) => {
        return {
            page: state.page,
            tasks: state.tasks,
            userProfile: state.userProfile,
            reports: state.reports
        };
    },
    actionCreators
)(Reports as any);

