import * as React from 'react';
import { connect } from 'react-redux';

import * as UserProfile from '../store/UserProfile';
import * as SettingsStore from '../store/SettingsStore';
import * as PageStore from '../store/Page';

import * as Const from '../utils/Const';

import styles from '../resources/Settings.module.less';
import pageStyles from '../resources/Page.module.less';
import { Tabs } from 'antd';
import { Table, Tag, Space } from 'antd';
import { Checkbox, Button, Radio, Tooltip, Modal, Select, InputNumber } from 'antd';
import {
    EditTwoTone,
    DeleteTwoTone,
    DownloadOutlined,
    QuestionCircleTwoTone,
    ExclamationCircleOutlined,
    LoadingOutlined,
    PlusCircleTwoTone,
} from '@ant-design/icons';

import { ApplicationState } from '../store';
import { EventType } from '../store/SettingsStore';
import ContactForm from '../components/ContactForm';
import SendingReportForm from '../components/SendingReportForm'
import SelectForm from '../components/Admin/SelectForm';
import { Point, USER_RIGHTS } from '../decl';
import ChangeMenuForm from '../components/ChangeMenuForm';
import moment from 'moment';
import { BACKEND_URL, getHeaders, sendRequestToBackend } from '../utils/AuthUtils';
import { convertBase64ToBinary, message, saveFile } from '../utils/Common';
import 'moment/locale/ru' 
import ChangePasswordForm from '../components/ChangePasswordForm';
import ChangeEmailForm from '../components/ChangeEmailForm';
import { getModalZIndex } from '../utils/Common';

import { getModalState } from 'ant-design-draggable-modal/dist/draggableModalReducer';

const { TabPane } = Tabs;
const { Column } = Table;
const { confirm } = Modal;
const { Option } = Select;

type Props =
    {userProfile: UserProfile.UserProfileState, settingsStore: SettingsStore.ISettingsState, page: PageStore.PageState} &
    typeof UserProfile.actionCreators &
    typeof SettingsStore.actionCreators &
    typeof PageStore.actionCreators;


type State = {
    showContactForm: boolean,
    editingContactId: string | undefined,

    showSendingReportForm: boolean,
    editingSendingReport: SettingsStore.SendingReport | undefined,
    editingSendingReportContactId: string,

    showAbonentsTree: boolean,
    modalZIndex: number,
    selectedAbonentsIds: string[] | undefined,

    contactXlsDownloaded: string[],
}

class Settings extends React.Component<Props, State> {
    constructor(props: Props, state: State) {
        super(props);
        this.state = {
            showContactForm: false,
            editingContactId: undefined,
            showSendingReportForm: false,
            editingSendingReport: undefined,
            selectedAbonentsIds: undefined,
            showAbonentsTree: false,
            modalZIndex: 0,
            editingSendingReportContactId: '',
            contactXlsDownloaded: []
        }
    }

    deleteSendingReportConfirm(sr: SettingsStore.SendingReport) {
        let that = this;
        confirm({
            title: 'Подтверждение',
            icon: <ExclamationCircleOutlined />,
            content: 'Вы действительно хотите удалить данное оповещение?',
            onOk() {
                that.props.deleteSendingReport(sr);
            },
        });
    }

    deleteContactConfirm = (userContactId: string) => {
        let that = this;
        confirm({
            title: 'Подтверждение',
            icon: <ExclamationCircleOutlined />,
            content: 'Вы действительно хотите удалить данный контакт?',
            onOk() {
                if (that.props.settingsStore?.userContacts?.[userContactId] !== undefined) {
                    that.props.deleteContact(that.props.settingsStore?.userContacts?.[userContactId]);
                }
            },
        });
    }

    componentDidMount() {
        this.props.requestSettingsTypes();
        this.props.requestContacts(this.props.userProfile.user?.user ?? '');
        this.props.requestSendingReports(this.props.userProfile.user?.user ?? '');
    }

    componentDidUpdate(prevProps: Props){
        if(this.props.settingsStore?.isUserContactsLoading != prevProps.settingsStore?.isUserContactsLoading){
            this.props.setIsLoading(this.props.settingsStore?.isUserContactsLoading);
        }

    }
    extractAllEventsTypesNames = (messageSendTypesAbonents: {[eventId: number]: string[] | null}) : string[] => {
        let ets: EventType[] = this.props.settingsStore.eventTypes ?? [];
        let res: string[] = [];

        for (const et of ets) {
            if (messageSendTypesAbonents[et.id] !== undefined) {
                res.push(et.name);
            }
        }

        return res;
    }

    downloadContactXls = (id: string) => {
        if (!this.state.contactXlsDownloaded.includes(id)) {
            this.setState({contactXlsDownloaded: [...this.state.contactXlsDownloaded, id]});
            sendRequestToBackend(
                id,
                'Settings/export_contact_events',
                (response: any) => {
                    saveFile(id + '.csv', convertBase64ToBinary(response));
                    let tmp = [...this.state.contactXlsDownloaded];
                    if (tmp.indexOf(id) !== -1) {
                        tmp.splice(tmp.indexOf(id), 1);
                    }
                    this.setState({contactXlsDownloaded: tmp});
                },
                null,
                (error: any) => {
                    let tmp = [...this.state.contactXlsDownloaded];
                    if (tmp.indexOf(id) !== -1) {
                        tmp.splice(tmp.indexOf(id), 1);
                    }
                    this.setState({contactXlsDownloaded: tmp});
                    message.error('Ошибка:' + error);
                },
                this.props.closeSession
            );
        }
    }

    changeMenuItems = (displayMenuItems: string | null) => {

        if (displayMenuItems) {
            let denyItems = '';
            let accessibleIds = '';

            const itemIds = displayMenuItems.split(',');
            itemIds.forEach(id => {
                const menuItem = this.props.userProfile?.menu.find(i => i.id.toString() === id);
                if (this.props.userProfile && menuItem && menuItem.right && !this.props.userProfile.rights.includes(menuItem.right)) {
                    if (denyItems !== '') denyItems += ', ';
                    denyItems += "'" + menuItem.name + "'";
                }
                else {
                    if (accessibleIds !== '') accessibleIds += ',';
                    accessibleIds += id;
                }
            })

            if (denyItems != '') {
                Modal.confirm({
                    title: 'Выбор недоступных страниц.',
                    content: 'Вы выбрали недоступные для Вашего тарифа страницы: ' + denyItems + '. Оставить их в меню?',
                    okText: "Да",
                    cancelText: "Нет",
                    onOk: () => {
                        this.props.changeProfileValue(Const.PROFILE_COMMON, 'DisplayMenuItems', displayMenuItems)
                    },
                    onCancel: () => {
                        this.props.changeProfileValue(Const.PROFILE_COMMON, 'DisplayMenuItems', accessibleIds)
                    }
                });
            }
            else {
                this.props.changeProfileValue(Const.PROFILE_COMMON, 'DisplayMenuItems', displayMenuItems)
            }
        }
        else {
            this.props.changeProfileValue(Const.PROFILE_COMMON, 'DisplayMenuItems', displayMenuItems)
        }
    }

    bothNoFalse = () => {
        return this.props.userProfile.profile.Reports?.PacketToOneExcelBook === this.props.userProfile.profile.Reports?.PDF === false
    }

    render() {
        let passDescr = '';
        if (this.props.userProfile.passwordDate !== '') {
            moment.locale('ru');
            let dt = new Date(this.props.userProfile.passwordDate);
            
            passDescr = moment(dt).fromNow();
        }
        const hasEditRight: boolean = (this.props?.userProfile?.rights.includes(USER_RIGHTS.CanEditUserSettings)) ?? false;
        const scrollVisibilityTable = this.props.page.windowHeight > 800 ? undefined : {y:this.props.page.windowHeight - 200};
        return (
            <div className={styles.container}><div className={styles.SetingsContainer + ' card-container'}>
            <Tabs type="card" tabBarStyle={{marginBottom: '0px'}} style={{height: '100%', overflow: 'auto'}}>
                <TabPane tab="Оповещения" key="1" className={styles.TabBorder}>
                    {this.props.settingsStore?.userContacts === undefined ? null :
                    <>
                    <Table 
                        dataSource={
                            Object.values(this.props.settingsStore?.userContacts ?? {}).map(item =>
                                ({
                                    key: item.id,
                                    edit: item.id,
                                    delete: item.id,
                                    xls: item.id,
                                    messages: item.count,
                                    email: item.clientId,
                                    sendingReports: this.props.settingsStore.sendingReports === undefined ? undefined : (this.props.settingsStore.sendingReports[item.id] ?? []),
                                    tags: this.extractAllEventsTypesNames(item.messageSendTypesAbonents ?? {}),

                                    sendTypeId: item.sendTypeId
                                })
                            )
                        }
                        pagination={false}
                    >
                        <Column 
                            title=""
                            width={65}
                            dataIndex="edit" 
                            key="edit"
                            render={(text, record) => (
                                <Button
                                    disabled={!hasEditRight}
                                    shape="circle"
                                    icon={<EditTwoTone twoToneColor={Const.COLORS.SvgIconColor}/>}
                                    onClick={() => this.setState({showContactForm: true, editingContactId: text})}
                                />
                            )}
                            
                        />
                        <Column 
                            title=""
                            width={65}
                            dataIndex="delete"
                            key="delete"
                            render={(value, record) => (
                                <Button 
                                    disabled={!hasEditRight}
                                    shape="circle" 
                                    icon={<DeleteTwoTone twoToneColor={Const.COLORS.SvgIconColor}/>}
                                    onClick={() => {this.deleteContactConfirm(value)}}
                                />
                            )}
                        />
                        <Column 
                            title="e-mail/telegram"
                            width={250}
                            dataIndex="email" 
                            key="email" 
                        />
                        <Column
                            title={<div>Рассылаемые отчеты</div>}
                            dataIndex="sendingReports"
                            key="sendingReports"
                            render={(tags: SettingsStore.SendingReport[], record: any) => (
                                <>
                                    {tags === undefined || this.props.settingsStore?.isSendingReportsLoading === true ? <LoadingOutlined/> :
                                        tags.map(item => (
                                            <Tag 
                                                color="cyan" 
                                                closable 
                                                onClose={(e: any) => {e.preventDefault(); this.deleteSendingReportConfirm(item)}}
                                                onClick={() => this.setState({showSendingReportForm: true, modalZIndex: getModalZIndex(), editingSendingReport: item})}
                                                key={item.id}
                                                className={pageStyles.TagMargin}
                                            >
                                                {item.description}
                                            </Tag>
                                        ))}

                                    {
                                        record?.sendTypeId === Const.SEND_TYPE_ID_EMAIL ?
                                        <Button shape="circle"
                                            disabled={!hasEditRight}
                                            onClick={() => this.setState({showSendingReportForm: true, editingSendingReport: undefined, editingSendingReportContactId: record.edit})}
                                            icon={<PlusCircleTwoTone twoToneColor="#52c41a" />}
                                        /> : null
                                    }
                                </>
                            )}
                        />
                        <Column
                            title="События"
                            dataIndex="tags"
                            key="tags"
                            render={(tags: any[]) => (
                                <>
                                    {tags.map(tag => (
                                        <Tag color="blue" key={tag} className={pageStyles.TagMargin}>
                                            {tag}
                                        </Tag>
                                    ))}
                                </>
                            )}
                        />
                        <Column 
                            title={
                                <Space>
                                    Подряд
                                    <Tooltip placement="top" title={'Количество сообщений, приходящих на почту/мобильный телефон при срабатывании события'}>
                                        <QuestionCircleTwoTone  className={styles.Question}/>
                                    </Tooltip>
                                </Space>
                            }
                            dataIndex="messages"
                            key="messages" 
                        />
                        <Column
                            title="Экспорт"
                            dataIndex="xls"
                            key="xls"
                            render={(value, record: any) => (
                                <Space size="middle">
                                    <Button disabled={!hasEditRight} onClick={() => this.downloadContactXls(record.key)} loading={this.state.contactXlsDownloaded.includes(record.key)} type="primary" shape="circle" icon={<DownloadOutlined />} />
                                </Space>
                            )}
                        />
                    </Table>
                    <Space className={styles.TopMargin}>                
                        <Button disabled={!hasEditRight} type="primary" onClick={() => this.setState({showContactForm: true, editingContactId: undefined })}>
                            Добавить контакт
                        </Button>
                    </Space>
                    </>
                    }
                </TabPane>
                <TabPane tab="Ведомости" key="4" className={styles.TabBorder}>
                    <Table 
                        dataSource={[
                            {
                                key: '9',
                                info: '',
                                descr: 'Формировать ведомости в формате PDF',
                                action: <Checkbox
                                            disabled={!hasEditRight || (this.props.userProfile.profile.Reports?.PacketToOneExcelBook && this.bothNoFalse())}
                                            checked={this.props.userProfile.profile.Reports?.PDF} 
                                            onChange={(e) => {this.props.changeProfileValue(Const.PROFILE_REPORTS, 'PDF', e.target.checked)}} 
                                        >
                                            {this.props.userProfile.profile.Reports?.PacketToOneExcelBook === true ? "Недоступно при формировании ведомости в одной книге EXCEL" : ""}
                                        </Checkbox>,
                            },
                            {
                                key: '10',
                                info: '',
                                descr: 'Формировать ведомости в одной книге EXCEL',
                                action: <Checkbox 
                                            disabled={!hasEditRight || (this.props.userProfile.profile.Reports?.PDF && this.bothNoFalse())}
                                            checked={this.props.userProfile.profile.Reports?.PacketToOneExcelBook} 
                                            onChange={(e) => {this.props.changeProfileValue(Const.PROFILE_REPORTS, 'PacketToOneExcelBook', e.target.checked)}} 
                                        >
                                            {this.props.userProfile.profile.Reports?.PDF === true ? "Недоступно при выбранном формате PDF" : ""}
                                        </Checkbox>,
                            },
                            {
                                key: '11',
                                info: '',
                                descr: 'Выполнять проверку данных перед формированием XML по э/э по форме 80020',
                                action: <Checkbox
                                    disabled={!hasEditRight}
                                    checked={this.props.userProfile.profile.Reports?.XMLElectroCheckData}
                                    onChange={(e) => { this.props.changeProfileValue(Const.PROFILE_REPORTS, 'XMLElectroCheckData', e.target.checked) }}
                                />,
                            },
                                {
                                    key: '12',
                                    info: '',
                                    descr: 'Формировать отчёты в кодировке Winows 1251, если не задано - UTF-8',
                                    action: <Checkbox
                                        disabled={!hasEditRight}
                                        checked={this.props.userProfile.profile.Reports?.Windows1251}
                                        onChange={(e) => { this.props.changeProfileValue(Const.PROFILE_REPORTS, 'Windows1251', e.target.checked) }}
                                    />,
                                },
                        ]}
                        pagination={false} 
                        showHeader={false}
                        bordered={true}
                    >
                        <Column width={'50%'} title="" dataIndex="descr" key="descr" />
                        <Column title="" dataIndex="action" key="action" />
                    </Table>
                </TabPane>
                <TabPane tab="Отображение на сайте" key="2" className={styles.TabBorder}>
                <Table 
                    dataSource={[
                        {
                            key: '1',
                            info: 'Меню сайта',
                            descr: 'Настроить отображение пунктов меню',
                            action: <ChangeMenuForm 
                                        disabled={false}
                                        menu={this.props.userProfile.menu} 
                                        displayMenuItems={this.props.userProfile.profile.Common?.DisplayMenuItems?.split(',').map(item => Number(item)) ?? this.props.userProfile.menu.map(item => item.id)} 
                                        changeMenuItems={this.changeMenuItems}
                                        windowHeight={this.props.page.windowHeight}
                                    />
                        },
                        {
                            key: '2',
                            info: 'Личный кабинет',
                            descr: 'Показывать сообщения о новых событиях при входе в личный кабинет',
                            action: <Checkbox 
                                        disabled={!hasEditRight}
                                        checked={this.props.userProfile.profile.Common?.ShowUnreadMessages} 
                                        onChange={(e) => {this.props.changeProfileValue(Const.PROFILE_COMMON, 'ShowUnreadMessages', e.target.checked)}} 
                                    />,
                        },
                        {
                            key: '3',
                            //info: '',
                            descr: 'Показывать предупреждение об отсутствии оплаты следующего периода',
                            action: <Checkbox 
                                        disabled={!hasEditRight}
                                        checked={!this.props.userProfile.profile.Common?.IgnoreLockWarning} 
                                        onChange={(e) => {this.props.changeProfileValue(Const.PROFILE_COMMON, 'IgnoreLockWarning', !e.target.checked)}} 
                                    />,
                        },
                        {
                            key: '4',
                            //info: '',
                            descr: 'Страница входа',
                            action:
                                <Select
                                    disabled={!hasEditRight}
                                    style={{width: '180px'}}
                                    size='small'
                                    value={this.props.userProfile.menu.find((item) => this.props.userProfile.profile.Common?.EnterPage === item.path)?.name}
                                    onChange={(value: any) => this.props.changeEnterPage(value)}
                                >
                                    {this.props.userProfile.menu.map(item => <Option key={item.id} value={item.path}>{item.name}</Option>)}
                                </Select>
                        },
                        {
                            key: '5',
                            info: 'Отчёт по связи',
                            descr: 'Показывать адрес вместо наименования',
                            action: <Checkbox 
                                        disabled={!hasEditRight}
                                        onChange={(e: any) => this.props.changeProfileValue(Const.PROFILE_REPORTS, 'ShowAddressInPlaceName', e.target.checked)}
                                        checked={this.props.userProfile.profile.Reports?.ShowAddressInPlaceName}
                                    />,
                        },
                        {
                            key: '6',
                            //info: '',
                            descr: 'Показывать статистику по группам',
                            action: <Checkbox
                                        disabled={!hasEditRight} 
                                        checked={this.props.userProfile.profile.MonthlyReports?.ShowGroupsStatistics} 
                                        onChange={(e) => {this.props.changeProfileValue(Const.PROFILE_MONTHLY_REPORTS, 'ShowGroupsStatistics', e.target.checked)}} 
                                    />,
                        },
                        {
                            key: '7',
                            info: 'Отображение ТУ',
                            descr: 'Отображать точки учёта по одной',
                            action: <Checkbox
                                disabled={!hasEditRight}
                                checked={this.props.userProfile.profile.Reports?.ShowOnePoint}
                                onChange={(e) => { this.props.changeProfileValue(Const.PROFILE_REPORTS, 'ShowOnePoint', e.target.checked) }}
                            />,
                        },
                        {
                            key: '8',
                            info: '',
                            descr: 'Сортировка групп и ТУ',
                            action: 
                                <Radio.Group 
                                    disabled={!hasEditRight}
                                    name="radiogroup"
                                    value={this.props.userProfile.profile.Reports?.AbonentsAndGroupsSortBy}
                                    onChange={
                                        (e) => {
                                            this.props.changeProfileValue(Const.PROFILE_REPORTS, 'AbonentsAndGroupsSortBy', e.target.value);
                                            this.props.setNeedReloadProfile();
                                        }
                                    }
                                >
                                    <Radio value={'SortByNumber'}>По алфавиту</Radio>
                                    <Radio value={'SortInOrder'}>B заданном порядке</Radio>
                                </Radio.Group>,
                        },
                        {
                            key: '9',
                            info: 'Погода',
                            descr: 'Отображать данные о погоде',
                            action: <Checkbox
                                disabled={!hasEditRight}
                                checked={this.props.userProfile.profile.Common?.ShowWeather}
                                onChange={(e) => { this.props.changeProfileValue(Const.PROFILE_COMMON, 'ShowWeather', e.target.checked) }}
                            />,
                        },
                        {
                            key: '10',
                            info: 'Контроль',
                            descr: 'Периодический контроль новых событий включен',
                            action: <Checkbox
                                disabled={!hasEditRight}
                                checked={(this.props.userProfile.profile.Common?.ConditionEventsCheckEnabled ?? false) == true}
                                onChange={(e) => { this.props.changeProfileValue(Const.PROFILE_COMMON, 'ConditionEventsCheckEnabled', e.target.checked) }}
                            />,
                        },
                        {
                            key: '11',
                            info: '',
                            descr: 'Период получения новых событий в минутах',
                            action: <InputNumber disabled={!hasEditRight} min={1} max={30} defaultValue={(this.props.userProfile.profile.Common?.ConditionEventsReadPeriod ?? 120)/60} onChange={
                                (value) => this.props.changeProfileValue(Const.PROFILE_COMMON, 'ConditionEventsReadPeriod', (value == null ? 10 : value)*60)
                            } />
                        },
                        {
                            key: '12',
                            info: '',
                            descr: 'Период получения активных задач опроса в секундах',
                            action: <InputNumber disabled={!hasEditRight} min={1} max={30} defaultValue={(this.props.userProfile.profile.Common?.PollingCheckPeriod ?? 2)} onChange={
                                (value) => this.props.changeProfileValue(Const.PROFILE_COMMON, 'PollingCheckPeriod', (value == null ? 2 : value))
                            } />
                        },
                        {
                            key: '13',
                            info: '',
                            descr: 'Отображать фигуру загрузки данных',
                            action: <Checkbox
                                disabled={!hasEditRight}
                                checked={this.props.userProfile.profile.Common?.ShowDataLoading}
                                onChange={(e) => { this.props.changeProfileValue(Const.PROFILE_COMMON, 'ShowDataLoading', e.target.checked) }}
                            />,
                        }
                    ]}

                    showHeader={false} 
                    bordered={true} 
                    pagination={false}
                    scroll = {scrollVisibilityTable}
                >
                    
                    <Column 
                        title=""
                        key="info"
                        dataIndex="info"
                        render={(value, record, index) => {
                            const obj = {
                                children: value,
                                props: {} as any,
                            };
                            if (index === 1) {
                                obj.props.rowSpan = 3;
                            }
                            if (index === 4) {
                                obj.props.rowSpan = 2;
                            }
                            if (index === 6) {
                                obj.props.rowSpan = 2;
                            }
                            if (index === 9) {
                                obj.props.rowSpan = 3;
                            }
                            if (index === 2 || index === 3 || index === 5 || index === 7 || index === 10 || index === 11) {
                                obj.props.rowSpan = 0;
                            }
                            return obj;
                        }}
                    />
                    <Column 
                        title="Настройка отображения на сайте" 
                        dataIndex="descr" 
                        key="descr" 
                        
                    />
                    <Column title="" dataIndex="action" key="action" />
                </Table>
                </TabPane>
                <TabPane tab="Общее" key="3" className={styles.TabBorder}>
                    <Table 
                        dataSource={[
                            {
                                key: '1',
                                info: 'Пароль',
                                descr: passDescr,
                                action: 'password'
                            },
                            {
                                key: '2',
                                info: 
                                    <Space>
                                        Электронная почта
                                        <Tooltip placement="top" title={'Рекомендуем ввести адрес Вашей электронной почты. Это позволит получить пароль в письме в случае его утраты'}>
                                            <QuestionCircleTwoTone  className={styles.Question} />
                                        </Tooltip>
                                    </Space>,
                                descr: this.props.userProfile.email,
                                action: 'email'
                            }
                        ]}
                        pagination={false} 
                        showHeader={false}
                        bordered={true}
                    >
                        <Column title="" key="info" dataIndex="info" />
                        <Column title="Общие настройки" dataIndex="descr" key="descr" />
                        <Column
                            title=""
                            dataIndex="action"
                            key="action"
                            render={(text, record) => {
                                if (text === 'password') {
                                    return (
                                        <Space size="middle">
                                            <ChangePasswordForm disabled={!hasEditRight} changePassword={this.props.changePassword} />
                                        </Space>
                                    )
                                } else {
                                    return (
                                        <Space size="middle">
                                            <ChangeEmailForm disabled={!hasEditRight} changeEmail={this.props.changeEmail} />
                                        </Space>
                                    )
                                }
                                
                            }}
                        />
                    </Table>
                </TabPane>
            </Tabs>
        
            <ContactForm 
                visible={this.state.showContactForm} 
                zIndex = {this.state.modalZIndex}
                contact={this.props.settingsStore.userContacts?.[this.state.editingContactId ?? '-1']} 
                eventTypes={this.props.settingsStore.eventTypes ?? []}
                messageSendTypes={this.props.settingsStore.messageSendTypes ?? {}}
                onClose={() => this.setState({editingContactId: undefined, showContactForm: false})}
                userName={this.props.userProfile.user?.user ?? ''}
                writeContact={this.props.writeContact} 
                groupPoints={(this.props.userProfile) ? this.props.userProfile.groupPoints : {}}
                requestGroupPoints={this.props.requestGroupPoints}
                requestMultipleGroupPoints={this.props.requestMultipleGroupPoints}
                setIsLoading={this.props.setIsLoading}
                userGroups={(this.props.userProfile) ? this.props.userProfile.userGroups : []}
                smallWindow={this.props.page.windowHeight < 850}
                resources={(this.props.userProfile) ? this.props.userProfile.resources : []}
                userAllPoints={Object.keys(this.props.userProfile.allPoints)}
            />

            <SendingReportForm
                visible={this.state.showSendingReportForm}
                zIndex={this.state.modalZIndex}
                fileNameTypes={this.props.settingsStore.fileNameTypes ?? {}}
                messageSendTypes={this.props.settingsStore.messageSendTypes ?? {}}
                periodTypes={this.props.settingsStore.periodTypes ?? {}}
                reportingPeriodTypes={this.props.settingsStore.reportingPeriodTypes ?? {}}
                reportsTypes={this.props.settingsStore.reportsTypes ?? {}}
                schedulesTypes={this.props.settingsStore.schedulesTypes ?? {}}
                sendingReport={this.state.editingSendingReport}
                writeSendingReport={this.props.writeSendingReport}
                onHide={() => this.setState({showSendingReportForm: false})}
                allPoints={this.props.userProfile.allPoints}
                requestPoints={this.props.requestPoints}
                groupPoints={(this.props.userProfile) ? this.props.userProfile.groupPoints : {}}
                requestGroupPoints={this.props.requestGroupPoints}
                setIsLoading={this.props.setIsLoading}
                userGroups={(this.props.userProfile) ? this.props.userProfile.userGroups : []}
                userContactId={this.state.editingSendingReportContactId}
                windowHeight={this.props.page.windowHeight}
            />

            <SelectForm 
                visible={this.state.showAbonentsTree}
                zIndex={this.state.modalZIndex}
                groups={(this.props.userProfile) ? this.props.userProfile.userGroups : []}
                groupPoints={(this.props.userProfile) ? this.props.userProfile.groupPoints : {}}
                selectedGroupIds={[]}
                selectedPointIds={this.state.selectedAbonentsIds ?? []}
                getPoints={this.props.requestGroupPoints}
                getPointsParentGroups={null}
                onChange={this.onValueChange}
                onChangePoints={this.onPointsChange}
                setIsLoading={this.props.setIsLoading}
                onClose={() => this.setState({showAbonentsTree: false})}
                onlyTree = {true}
            />  

            </div></div>
        )
    }

    onValueChange = (groups: any, points: string[]) => {
        //('onValueChange', groups, points)
        this.setState({selectedAbonentsIds: points})
    };
    onPointsChange = (points: Point[]) => {
        this.setState({selectedAbonentsIds: points.map(item => item.id)})
    };

}

export default connect(
    (state: ApplicationState) => {
        return {
            userProfile: state.userProfile,
            settingsStore: state.settingsStore,
            page: state.page
        }
    },
    {...UserProfile.actionCreators, ...SettingsStore.actionCreators, ...PageStore.actionCreators}
)(Settings as any);