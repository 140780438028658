import * as React from 'react';

//import styles from '../resources/Page.module.less';
import { Button, Modal, Select, Table, Space, Checkbox } from 'antd';
import { MODAL_WRAP_CLASSNAME } from '../../utils/Const';

import * as AdminStore from '../../store/AdminStore';

const { Option } = Select;

type Props = {
    onHide(): void,
    table: string[][] | null,
    stats: string[] | null,
    zIndex: number
}

type State = {
    
}

class ProtokolTaskStatisticsForm extends React.Component<Props, State> {
    constructor(props: Props, state: State) {
        super(props);
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps.table == null && this.props.table != null) {
            this.itemsDataSource = this.props.table.map(x => ({
                polling: x[0] ?? '',
                period: x[1] ?? '',
                time: x[2] ?? '',
                event: x[3] ?? '',
                info: x[4] ?? '',
            }))
        }
    }

    initTable = (response: AdminStore.SAbonentConnectionStatisticDTO[]) => {
        if (this.props.table != null && this.props.stats != null) {
            
        }
    }

    private onHide = () => {
        //this.setState({queryItemCurrent: undefined})
        this.props.onHide();
    }

    private columns: any[] = [
        {
            dataIndex: 'polling',
            title: 'Опрос',
            align: 'center',
            render: (val: any) => <Checkbox checked={val === 'True'} />
        },
        {
            dataIndex: 'period',
            title: <>Период<br />(сек)</>,
        },
        {
            dataIndex: 'time',
            title: 'Дата - Время',
        },
        {
            dataIndex: 'event',
            title: <>Событие опроса<br />прибора</>
        },
        {
            dataIndex: 'info',
            title: 'Данные, сообщения',
        },
    ];
    private itemsDataSource: any = undefined;

    //=============================================================================
    render() {
        return (
            <Modal
                maskClosable={false}
                bodyStyle={{paddingTop: '10px', height: '400px !important', overflow: 'auto'}}
                title={'События выполнения задания на опрос прибора (' + this.props.table?.length + ')'}
                wrapClassName={MODAL_WRAP_CLASSNAME}
                zIndex={this.props.zIndex}
                open={this.props.table != null}
                footer={[
                    <div key={1} style={{ display: 'flex', alignItems: 'baseline' }}>
                        <Button key="1" type='primary' onClick={() => this.props.onHide()} style={{marginLeft: 'Auto'}}>Закрыть</Button>
                    </div>
                ]}
                onCancel={() => { this.props.onHide() }}
                width={'fit-content'}
                style={{ top: 40 }}
            >
                {
                    //this.state.queryItemTypes === undefined ? <LoadingOutlined/> :
                    <>  
                        <Space style={{paddingBottom: '10px'}} direction='vertical'>
                            <div style={{overflow: 'auto', maxHeight: '500px', width: '1000px'}}>
                                <Table
                                    bordered={true}
                                    size={'small'}
                                    rowKey="id"
                                    columns={this.columns}
                                    dataSource={this.itemsDataSource}
                                    pagination={false}
                                />
                            </div>
                            <span>{this.props.stats?.[0] ?? ''}</span>
                            <span>{this.props.stats?.[1] ?? ''}</span>
                            <span>{this.props.stats?.[2] ?? ''}</span>
                        </Space>
                    </>
                }
            </Modal>
        );
    }
}

export default ProtokolTaskStatisticsForm;