import * as React from 'react';
import { Link, withRouter } from "react-router-dom";

import { Menu} from 'antd';

import { MenuFoldOutlined, MenuUnfoldOutlined, FolderOutlined } from '@ant-design/icons';
//import Icon from '@ant-design/icons';

import styles from '../resources/SiderMenu.module.less';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as SiderMenuStore from '../store/SiderMenu';
import * as PageStore from '../store/Page';
import IconSelector from './IconSelector';
import { stringToNumberArray } from "../utils/Common";
import {layoutLeftSide} from '../utils/Common';

import { MenuItemDesc } from "../decl"

const { SubMenu } = Menu;

interface Router {
    router: any;
}

type SiderMenuProps =
    {
        page: PageStore.PageState,
        menu: MenuItemDesc[],
        displayItems: string | null,
        siderMenu: SiderMenuStore.SiderMenuState,
        showWeather: boolean | undefined,
        allPoints: any
    } &
    Router &
    typeof SiderMenuStore.actionCreators;


class SiderMenu extends React.PureComponent<SiderMenuProps> {
    handleClick = (e:any) => {
    };

    toggle = (e: any) => {
        this.props.toggleCollapsed();
    };

    menuMap = (menu: MenuItemDesc[]) =>
         menu.map(
            (item) => {
                return (
                    <Menu.Item key={item.path}>
                        <IconSelector type={item.iconName}/>
                        <span>{item.name}</span>
                        <Link to={item.path} />
                    </Menu.Item>);
            })

    // createMenu = (menu: MenuItemDesc[], displayItems: number[] | null, maxItems: number) => {
    //     const visibleItems = menu.filter(item => (!item.editable || displayItems === null || displayItems.indexOf(item.id) >=0));

    //     let items: MenuItemDesc[];
    //     const subItems: MenuItemDesc[] = [];
    //     if(visibleItems.length <= maxItems + 1){
    //         items = visibleItems;
    //     }
    //     else
    //     {
    //         items = [];
    //         for(let i = 0; i < visibleItems.length; i++){
    //             const item: any = visibleItems[i];
    //             if( i >= maxItems){
    //                 subItems.push(item);
    //             }
    //             else{
    //                 items.push(item);
    //             }
    //         }
    //     }
    //     const result: JSX.Element[] = this.menuMap(items);
    //     if(subItems.length > 0){
    //         result.push(
    //             <SubMenu key="subAdd" icon={<FolderOutlined/>} title="Дополнительно">
    //                 {this.menuMap(subItems)}
    //             </SubMenu>
    //         )
    //     }

    //     return result;
    // }

    createMenu = (menu: MenuItemDesc[], displayItems: number[] | null, maxItems: number) => {
        let visibleItems: (MenuItemDesc)[];
        if(displayItems === null){
            visibleItems = [...menu];
        }
        else{
            visibleItems = [];
            displayItems.forEach(
                id=>{
                    const item = menu.find(item=> item.id===id);
                     if(item)visibleItems.push(item)
                    });

            //Добавить обязательные пункты меню, если их нет.                    
            menu.filter(item => (!item.editable)).forEach(item=>{if(!visibleItems.find(vitem=>vitem.id===item.id)){
                visibleItems.push(item);
            }});

        }

        //const visibleItems = menu.filter(item => (!item.editable || displayItems === null || displayItems.indexOf(item.id) >=0));

        let items: MenuItemDesc[];
        const subItems: MenuItemDesc[] = [];
        if(visibleItems.length <= maxItems + 1){
            items = visibleItems;
        }
        else
        {
            items = [];
            for(let i = 0; i < visibleItems.length; i++){
                const item: any = visibleItems[i];
                if( i >= maxItems){
                    subItems.push(item);
                }
                else{
                    items.push(item);
                }
            }
        }
        const result: JSX.Element[] = this.menuMap(items);
        if(subItems.length > 0){
            result.push(
                <SubMenu key="subAdd" icon={<FolderOutlined/>} title="Дополнительно">
                    {this.menuMap(subItems)}
                </SubMenu>
            )
        }

        return result;
    }
 
    render() {
        let collapsed: boolean = false;
        let menuItems: any = [];
        if (this.props.siderMenu !== undefined) {
            collapsed = this.props.siderMenu.collapsed;
        }
        
        const displayItems: number[] | null = this.props.displayItems ? stringToNumberArray(this.props.displayItems) : null;

        let pathname: string = this.props.router.location.pathname;
        const allPointCount = (Object.keys(this.props.allPoints))?.length ?? 0
        if (this.props.menu !== null) {
            //Планирование высоты левой части.
            const leftSide = layoutLeftSide(this.props.page.windowHeight, this.props.showWeather, allPointCount, pathname); 

            menuItems = this.createMenu(this.props.menu, displayItems, leftSide.maxItems);
        }

        //console.log(pathname);
        const icon = collapsed ?
            <MenuUnfoldOutlined className={styles.toogleButton} onClick={this.toggle} /> :
            <MenuFoldOutlined className={styles.toogleButton} onClick={this.toggle} />;

      return (
          <React.Fragment>
              {icon}
              <Menu
                  className={styles.menu}
                  onClick={this.handleClick}
                  mode="vertical"
                  selectedKeys={[pathname]}
              >
                  {menuItems}
              </Menu>
        </React.Fragment>
    );
  }
};

export default connect(
    //(state: ApplicationState & Router) => state,
    (state: ApplicationState & Router) => {
        return {
            page: state.page,
            siderMenu: state.siderMenu,
            menu: state.userProfile?.menu,
            displayItems: state.userProfile?.profile?.Common?.DisplayMenuItems ?? null,
            showWeather: state.userProfile?.profile?.Common?.ShowWeather,
            allPoints: state.userProfile?.allPoints,
            router: state.router
        }
    },
    SiderMenuStore.actionCreators
)(SiderMenu as any);


