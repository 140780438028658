import { message } from '../utils/Common';
import internal from 'assert';
import { getPackedSettings } from 'http2';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { Resource, ResponseStatusesEnum, SParameterDTO, SParameterTypesEnum, SParameterValTypes as SParameterValTypes, SPointsFilter, SResponseDTO } from '../decl';
import { BACKEND_URL, getHeaders } from '../utils/AuthUtils';
import { CloseSessionAction } from './Page';

//Состояние хранилища для страницы Reports.
export interface INsiState {
    isLoadingNsi: boolean,
    pages: SNsiDTO | undefined,
    channelTypes: SChannelTypeDTO[] | undefined,
    channels: SChannelBaseDTO[] | undefined,
    editEnabled: boolean,
    requestChangeFlag: boolean,
}

export interface SFieldChangeRequest
{
    /// <summary>
    /// Код канала, не задан для общих параметров.
    /// </summary>
    channelCode: string;

    /// <summary>
    /// Код группы параметров.
    /// </summary>
    groupCode: string;

    /// <summary>
    /// Код параметра.
    /// </summary>
    parameterCode: string;

    /// <summary>
    /// Новое значение параметра.
    /// </summary>
    newValue: SParameterValTypes;
}

export interface SNsiDTO
{
    pointId: string;
    pointName: string;
    blocks: SNsiAreasBlockDTO[];
}

export interface SChannelTypeDTO
{ 
    id: number;
    index: string;
    name: string;
}

export interface SChannelBaseDTO
{
    id: number,
    sign: string,
    number: number,
    systemNumber: number,
    channelTypeId: number,
    fixedType: boolean,
    description: string,
    isRemoved: boolean,
}

export interface SNsiAreasBlockDTO {
    channelCode: string;
    title: string;
    areas: SNsiAreaDTO[];
}

export interface SNsiAreaDTO {
    title: string;
    groupCode: string;
    position: number;
    fields: SParameterDTO[];
}

const prepareFieldVal = (f: SParameterDTO): any => {
    let res: any = undefined;

    if (f.value == null) {
        res = undefined;
    } else if (f.valueType === SParameterTypesEnum.Boolean) {
        if (typeof(f.value) === 'boolean') {
            res = f.value;
        } else {
            message.error('Неверное значение boolean');
            console.log('Неверное значение boolean', f);
        }
    } else if ((f.valueType === SParameterTypesEnum.Date || f.valueType === SParameterTypesEnum.DateTime)) {
        if (typeof(f.value) === 'string') {
            res = new Date(f.value);
        } else {
            message.error('Неверная дата');
            console.log('Неверная дата', f);
        }
    } else if (f.valueType === SParameterTypesEnum.None) {
        res = String(f.value)
    } else if (f.valueType === SParameterTypesEnum.Number) {
        if (typeof(f.value) === 'number') {
            res = f.value;
        } else {
            message.error('Неверное значение number');
            console.log('Неверное значение number', f);
        }
    } else if (f.valueType === SParameterTypesEnum.String || f.valueType === SParameterTypesEnum.Text) {
        if (typeof(f.value) === 'string') {
            res = f.value;
        }
    }
    
    return res;
}

interface SetState { type: 'SET_NSI_STATE'; value: Partial<INsiState> };
interface ReceiveNsi { type: 'RECEIVE_NSI'; value: {nsi: SNsiDTO, channelTypes: SChannelTypeDTO[], channels: SChannelBaseDTO[]} | undefined }

export type KnownAction = CloseSessionAction | SetState | ReceiveNsi;

export const actionCreators = {
    requestNsi: (pointId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({type: 'SET_NSI_STATE', value: {isLoadingNsi: true, editEnabled: false}});
        
        if (appState) {
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify(pointId)
            };
            fetch(BACKEND_URL + 'points/nsiget', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        let res: {nsi: SNsiDTO, channelTypes: SChannelTypeDTO[], channels: SChannelBaseDTO[]} = data.body;
                        res.nsi.blocks.forEach(b => b.areas.forEach(a => a.fields.forEach((f, index) => a.fields[index].value = prepareFieldVal(f))));
                        res.nsi.blocks.forEach(b => b.areas.sort((a, b) => a.position - b.position));
                        dispatch({type: 'RECEIVE_NSI', value: res})
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                    }
                    else {
                        message.error('Ответ не получен.');
                    }
                    dispatch({type: 'SET_NSI_STATE', value: {isLoadingNsi: false}});
                })
                .catch(error => {
                    message.error('Ошибка:' + error);
                    console.log(error);
                    dispatch({type: 'SET_NSI_STATE', value: {isLoadingNsi: false}});
                });
            
        }
    },

    requestChangeNsi: (pointId: string, fields: SFieldChangeRequest[], channels: SChannelBaseDTO[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState) {
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify({pointId, fields, channels})
            };
            fetch(BACKEND_URL + 'points/nsichange', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        dispatch({type: 'SET_NSI_STATE', value: {editEnabled: false, requestChangeFlag: false, pages: undefined}});
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                    }
                    else {
                        message.error('Ответ не получен.');
                    }
                    dispatch({type: 'SET_NSI_STATE', value: {requestChangeFlag: false}});
                })
                .catch(error => {
                    message.error('Ошибка:' + error);
                    console.log(error);
                    dispatch({type: 'SET_NSI_STATE', value: {requestChangeFlag: false}});
                });
            
        }
    },

    requestNsiReport: (pointId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({type: 'SET_NSI_STATE', value: {isLoadingNsi: true, editEnabled: false}});
        
        if (appState) {
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify(pointId)
            };
            fetch(BACKEND_URL + 'points/nsiget', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        console.log('points/nsiget', data);
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                    }
                    else {
                        message.error('Ответ не получен.');
                    }
                    dispatch({type: 'SET_NSI_STATE', value: {isLoadingNsi: false}});
                })
                .catch(error => {
                    message.error('Ошибка:' + error);
                    console.log(error);
                    dispatch({type: 'SET_NSI_STATE', value: {isLoadingNsi: false}});
                });
            
        }
    },

    setNsiEditEnabled: (flag: boolean) => ({ type: 'SET_NSI_STATE', value: {editEnabled: flag} } as SetState),
    setRequestChangeFlag: (flag: boolean) => ({ type: 'SET_NSI_STATE', value: {requestChangeFlag: flag} } as SetState),
}

export const reducer: Reducer<INsiState> = (state: INsiState | undefined, incomingAction: Action): INsiState => {
    if (state === undefined) {
        return { isLoadingNsi: false, pages: undefined, editEnabled: false, requestChangeFlag: false, channelTypes: undefined, channels: undefined};
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "SET_NSI_STATE":
            return {
                ...state,
                ...action.value
            };
        case "RECEIVE_NSI":
            return {
                ...state,
                pages: action.value?.nsi,
                channelTypes: action.value?.channelTypes,
                channels: action.value?.channels,
            };
        default:
            return state;
    }
};
