import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Tree, Dropdown, Menu } from 'antd';
import { HomeOutlined, GroupOutlined, LoadingOutlined } from '@ant-design/icons';

import 'ant-design-draggable-modal/dist/index.css'

import { Group, Point, POINT_SERIALNUMBER, Command } from "../decl";
import { createTree, changeTreeValue, getGroupsByPoints, getAllSelectedGroups, removeValue, getPointGroups, NodeTypesEnum, SelectedNodeInfo } from "../utils/TreeUtils";
import * as Const from '../utils/Const';
import { ContactSupportOutlined } from '@material-ui/icons';

//!!!!! В коде остались эксперименты заставить дерево раскрыть выбранные по фильтру узлы - не удачно.

interface IPointsTreeProps{
    height: number,
    groups: Group[];
    groupPoints: {[groupId: string] : Point[]};
    selectedGroupIds: string[];
    selectedPointIds: string[];
    getPoints: any;
    getMultipleGroupPoints?: any, 
    getPointsParentGroups: any;
    setIsLoading: any;
    onlyGroupsSelect? : boolean;
    onChange: any;
    commands?: {[nodeType in NodeTypesEnum] : Command[]};    //Описание команд контекстного меню.
    onClose: any,
    isAllowedCheck?:  ((ob:Point | Group)=>boolean),
    additionalTitle?: ((ob:Point | Group | null)=>string),
    fullLoading?: boolean;
}

interface IPointsTreeState{
    isGroupsLoading: boolean;
    expandedKeys: string[];
    selected: SelectedNodeInfo;
}

//Дерево точек учёта.
export default class PointsTree extends React.PureComponent<IPointsTreeProps, IPointsTreeState> {
    _isMounted: boolean;
    _ids: string[];
    _expandedKeys: string[];
    _groupsByPoints: any;
    _waitPointIds: string[];
    _waitGroupIds: string[];

    constructor(props: IPointsTreeProps) {
        super(props);

        this.state = {
            isGroupsLoading: false,
            expandedKeys: [],
            selected: {parentOb: null, selectedOb: null, nodeType: NodeTypesEnum.Root}
        }

        this.onCheck = this.onCheck.bind(this);
        this.setGroupsToState = this.setGroupsToState.bind(this);
        this.onLoadData = this.onLoadData.bind(this);

        this._isMounted = false;
        this._ids = [];
        this._expandedKeys = [];
        this._groupsByPoints =  {};
        this._waitPointIds = [];
        this._waitGroupIds = [];
    }
    componentDidMount() {
        this._isMounted = true;
        this._groupsByPoints =  {};
        this._waitPointIds = [];
        this.loadTree([]);
    }
    // shouldComponentUpdate(nextProps: IPointsTreeProps, nextState: IPointsTreeState){
    //     return !(this.state.isGroupsLoading && nextState.isGroupsLoading);
    // }

    componentDidUpdate() {
        this.loadTree([]);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadTree = (openGroups: Group[])=>{
        //Нужно обеспечить предварительную подгрузку групп с выбранными точками учёта и верхней единственной группы.
        if(!this.state.isGroupsLoading){
            const groupIds: string[] = this.props.fullLoading ? getAllSelectedGroups(this.props.groups, [Const.ALL_GROUP_ID]).map(g=>g.id) : [];
            const pointIds: string[] = [];
            const _this = this;

            const getGroupPoints = (groupId: string) => {
                if(_this._waitGroupIds.indexOf(groupId) < 0){
                    _this._waitGroupIds.push(groupId);

                    _this.props.getPoints(groupId, (result: any)=>{
                        if(result && _this._isMounted){
                            removeValue(_this._waitGroupIds, groupId);

                            if(_this._waitGroupIds.length === 0){
                                //_this.createTree();
                                _this.setState({ isGroupsLoading: false });
                            }
                        }
                    });
                }
            }

            const getMultipleGroupPoints = (groupIds: string[]) => {
                const actualGroupIds: string[] = groupIds.filter(id=>_this._waitGroupIds.indexOf(id) < 0);
                if(actualGroupIds.length > 0)
                {
                    _this._waitGroupIds.push(...actualGroupIds);
                    _this.props.getMultipleGroupPoints(actualGroupIds, (ids: string[])=>{
                        if(ids &&  _this._isMounted){
                            ids.forEach(id=>removeValue(_this._waitGroupIds, id));
                            if(_this._waitGroupIds.length === 0){
                                _this.setState({ isGroupsLoading: false });
                            }
                        }
                    });
                }
            }

            
            //Проверка наличия единственной корневой группы.
            if(this.props.groups.length === 1){
                //В корне одна группа, которая раскрывается при создании дерева.
                const groupId = this.props.groups[0].id; 
                if(!groupIds.find(id=>id === groupId)){
                    groupIds.push(groupId);
                }                
            }

            //Взять идентификаторы открытых групп.
            for(let openGroup of openGroups){
                if(openGroup && !groupIds.find(id=>id===openGroup.id)){
                    groupIds.push(openGroup.id);
                }
            }
            // console.log('sel: ', this.props.selectedPointIds);
            // //Проверка наличия выбранной точки учёта.
            // for (let pointId of this.props.selectedPointIds){

            //     const pointGroups = getPointGroups(
            //         this.props.groups,          //Корневые группы пользователя, содержащие дочерние группы.
            //         this.props.groupPoints,     //Точки учёта по группам {[groupId: string] : Point[]}.
            //         pointId);                   //Идентификатор точки учёта.

            //     //Не найдено ни одной группы. !!!!! М.б не все группы подгружены.
            //     if(pointGroups.length === 0){
            //         //В системе нет данных о группах, в которых находится данная точка учёта.
            //         if (pointIds.indexOf(pointId) < 0) {
            //             pointIds.push(pointId);
            //         }
            //     }
            //     else{
            //         //Проверить необходимость загрузки точек для найденных групп.
            //         for(let group of pointGroups){
            //             if(groupIds.indexOf(group.id) < 0){
            //                 groupIds.push(group.id);
            //             }
            //         }
            //     }

            //     //Проверить наличие точек учёта с неизвестными группами.
            //     if(pointIds.length > 0){
            //         //Получить группы по точкам учёта.
            //         getGroupsByPoints(pointIds, (groupsByPoints: any) => {
            //             if (groupsByPoints && _this._isMounted) {
            //                 const pointIds = Object.keys(groupsByPoints);
            //                 for (let pointId of pointIds) {
            //                     const loadedPointGroups: Group[] = groupsByPoints[pointId];
            //                     for(let group of loadedPointGroups){
            //                         getGroupPoints(group.id);
            //                     }
            //                 }
            //             }
            //         });
            //     }
            // }

            if(groupIds.length > 0 || pointIds.length > 0){
                this.setState({ isGroupsLoading: true });
                if(groupIds.length > 1 && this.props.getMultipleGroupPoints){
                    getMultipleGroupPoints(groupIds);
                }
                else{
                    for(let groupId of groupIds){
                        getGroupPoints(groupId);
                    }
                }
            }
        }
    }

    onCheck = (checkedKeys: any, e: any) => {
        //console.log('onCheck', this.props.selectedGroupIds, this.props.selectedPointIds);
        let groupIds: string[] =  this.props.selectedGroupIds.slice();
        let pointIds: string[] = this.props.selectedPointIds.slice();
        const group = e.node.group;
        const point = e.node.point;
        //console.log('BEFORE chnage: ', groupIds, pointIds);
        changeTreeValue(groupIds, pointIds, this.props.groups, this.props.groupPoints, group, point, e.checked); 
        //console.log('OnChange', groupIds, pointIds);
        this.props.onChange(groupIds, pointIds);
    };

    onLoadData = (node: any):any => {
        const group = node.group;
        const getGroupPoints = this.props.getPoints;
        return new Promise<void>((resolve) => {
            if(group && group.numberOfPoints > 0){
                getGroupPoints(group.id,  resolve);
            }
            else{ 
                resolve();
                return;
            }
        });
    };


    setGroupsToState = (groupsByPoints: any) => {
        if (this._isMounted) {
            //let statePoints: any = this.state.points;

            const pointIds = Object.keys(groupsByPoints);
            for (let pointId of pointIds) {
                removeValue(this._waitPointIds, pointId);
                const groups: Group[] = groupsByPoints[pointId];
                this._groupsByPoints[pointId] = groups;

//                for (let group of groups) {
//                    if (!statePoints[group.id]) {
//                        this.getGroupPoints(group.id, null);
//                    }
//                }
            }
            if(this._waitPointIds.length > 0){
                //console.log('WAIT POINTS' + this._waitPointIds.length);
            }
            this.setState({ isGroupsLoading: false });
        }
    }
    
    getExpandedGroupIds = ()=>{
        const result: string[] = [];
            const pointIds: string[] = Object.keys(this._groupsByPoints);
            for (let pointId of pointIds) {
                const groups: Group[] = this._groupsByPoints[pointId];
                for (let group of groups) {
                    if(result.indexOf(group.id) < 0){
                        result.push(group.id);
                    }         
                }
            }
        return result;
    }

    onRightClick =  (params: any)=>{
        this.selectNode(params.node);
    }
    onDoubleClick = (params: any, node: any) => {
        const selected: SelectedNodeInfo = this.selectNode(node);
        if(this.props.commands){
            const commands: any = this.props.commands[selected.nodeType];
            if(commands.length > 0){
                commands[0].action(selected.selectedOb, selected.parentOb);
            }
        }
    }
    selectNode = (node: any) => {
        const group: Group = node.group;
        const point: Point = node.point;
        const parent: Group = node.parent;
        
        const selected: SelectedNodeInfo = {parentOb: null, selectedOb: null, nodeType: NodeTypesEnum.Root};
        if(point){
            selected.nodeType = NodeTypesEnum.Point;
            selected.selectedOb = point;
            selected.parentOb = group;
        }
        else if(group){
            selected.nodeType = NodeTypesEnum.Group;
            selected.selectedOb = group;
            selected.parentOb = parent;
        }

        let changed = (this.state.selected.nodeType !== selected.nodeType) ||
            (this.state.selected.selectedOb != null && selected.selectedOb == null) ||
            (this.state.selected.selectedOb == null && selected.selectedOb != null) ||
                 (this.state.selected.selectedOb != null &&  selected.selectedOb != null &&  selected.selectedOb.id !== this.state.selected.selectedOb.id) ||
                 (this.state.selected.parentOb?.treeId != selected.parentOb?.treeId);
                 
        if(changed){
            this.setState({selected: selected});
        }
        return selected;
    } 


    menuItemsCreate = (commands: any) => {
        if(commands){
            const result = commands.map( (c:Command)=>({label: c.caption, key: c.key}));
            return result;
        }
    }

    getContextMenu = () => {
        let result = null;
        if(this.props.commands){
            const items: any = this.menuItemsCreate(this.props.commands[this.state.selected.nodeType]);
            if(items && items.length > 0){
                result = (
                    <Menu items = {items}  onClick = {this.onMenuClick}> </Menu>
                );
            }
        }
        return result;
    }
    onMenuClick = (info: any) => {
        if(this.props.commands){
            const commands: any = this.props.commands[this.state.selected.nodeType];
            const command = commands.find((c:Command)=>c.key===info.key);
            if(command){
                command.action(this.state.selected.selectedOb, this.state.selected.parentOb);
            }
        }
    }

    render() {
        let isReady: boolean = !this.state.isGroupsLoading;
        //console.log('render selectedGroupIds', this.props.selectedGroupIds);
            if (isReady) {
                let selectedIds: string[] = [];
                selectedIds = selectedIds.concat(this.props.selectedGroupIds);
                selectedIds = selectedIds.concat(this.props.selectedPointIds);
        
                let value: string[] = [];
                //const expandedKeys: string[] = [];
                this._ids = [];
                this._expandedKeys = [];
        
                const onlyGroupsSelect = this.props.onlyGroupsSelect??false;
                const onlyPointsSelect = false;
                const treeData = createTree(
                    this.props.groups,
                    this.props.groupPoints,
                    selectedIds,
                    this.getExpandedGroupIds(),
                    value,
                    this._ids,
                    this._expandedKeys,// expandedKeys,
                    5,
                    (props: any)=>{
                        if (props.value.length > 0 && props.value[0] === 'g'){
                            return <GroupOutlined/>
                        }
                        else{
                            if(props.point && props.point.persistProperties[POINT_SERIALNUMBER] && props.point.persistProperties[POINT_SERIALNUMBER].length > 0){
                                return <HomeOutlined  style={{ color: '#0f6' }}/>;
                            }
                            else{
                                return <HomeOutlined style={{ color: '#20f' }}/>;
                            }
                        }
                    }, 
                    onlyPointsSelect,
                    onlyGroupsSelect,
                    null,
                    this.props.isAllowedCheck,
                    this.props.additionalTitle);

                    //const [myVariable, setMyVariable] = React.useState({ expandedKeys: ([] as string[]) });
                    //myVariable.expandedKeys = this._expandedKeys; //update without rendering
                    //setMyVariable({ expandedKeys: this._expandedKeys}); //update with rendering

                const tProps = {
                    height: this.props.height,
                    treeData,
                    checkedKeys: value,
                    defaultExpandedKeys: this._expandedKeys,// expandedKeys,
                    onCheck: this.onCheck,
                    checkable: true,
                    showIcon: true,
                    showLine: {showLeafIcon: false},
                    zIndex: 1,
                    loadData: this.onLoadData,
                    onExpand: (expandedKeys: any, ob: any)=>{
                        this._expandedKeys = expandedKeys;
                    },
                    onRightClick: this.onRightClick as any,
                    onDoubleClick: this.onDoubleClick as any
                };
                const menu = this.getContextMenu();
                if(menu === null){
                    return  <Tree {...tProps} />
                }
                else {
                    return  <Dropdown overlay={menu} trigger={['contextMenu']}>
                        <Tree {...tProps} />
                    </Dropdown>
                }
            } else {
                return <div><LoadingOutlined/> <span>Ожидание загрузки всех узлов дерева групп</span></div>;
            }
    }
}
