import * as React from 'react';
import ReactDOM from 'react-dom';

//import styles from '../resources/Page.module.less';
import { Button, Modal, Select, Input, Row, Col, Table, Tag, DatePicker, Tabs, Space, InputNumber, Checkbox, Switch, Divider, notification, Tooltip } from 'antd';
import { EyeTwoTone, EyeInvisibleTwoTone, StopTwoTone, EditTwoTone, DeleteTwoTone,PlayCircleTwoTone } from '@ant-design/icons';
import * as Const from '../../utils/Const';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';

import { arrayToString, compareObj, deepCopyObj, getModalZIndex, message, netiExecute } from '../../utils/Common';
import {CONNECTIONS_TCPCLIENT, CONNECTIONS_TCPIP, CONNECTIONS_TCPIP_BYPORT, MODAL_WRAP_CLASSNAME } from '../../utils/Const';
//import { SPointsFilter } from '../decl';

//import Groups from './Groups';
//import { AbonentOperationDTO, AbonentOperationTypeDTO } from '../store/OperationsStore';
//import RangePickerLimit from './RangePickerLimit';

import pageStyles from '../../resources/Page.module.less';
import SParametersEdit from './SParametersEdit';

import * as AdminStore from '../../store/AdminStore';
import { Resource, SParameterValTypes, SPointTaskDTO } from '../../decl';
import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { IPointDictionaries, PointAllInfoDTO, QueryItemTypes, SQueryItemDTO } from '../../store/AdminStore';
import { validateLocaleAndSetLanguage } from 'typescript';
import modal from 'antd/lib/modal';
import { sendRequestToBackend } from '../../utils/AuthUtils';
import BaseMap from '../Map/BaseMap';
import SimpleMap from '../Map/SimpleMap';
import {DriveEtaOutlined, Message } from '@material-ui/icons';
import { PingForm } from './PingForm';
import { TCPLogTable } from './TCPLogTable';
import { ImageLoader, SPictureDTO, getPointPictures, savePointPictures } from '../Helper/ImageLoader';
import { debug } from 'console';
import QueryItemEditForm from './QueryItemEditForm';
import { startQueryItem, stopQueryItem, requestQueryPointData } from './QueryItemsForm'
import { USER_RIGHTS } from '../../decl';

const { Option } = Select;

type Props = {
    visible: boolean,
    zIndex: number,
    windowHeight: number,
    onHide(): void,
    onWrite(close: boolean, finish?: (success: boolean, point: PointAllInfoDTO) => void): void,

    //admin: AdminStore.IAdminState,
    pointAllInfo: PointAllInfoDTO | undefined,
    pointDicts: IPointDictionaries | undefined,
    queryItemTypes: QueryItemTypes | undefined,
    queryItem: SQueryItemDTO | undefined,

    isConnParsLoading: boolean,
    isPointAllInfoLoading: boolean,
    isQueryItemTypesLoading: boolean,

    allResources: Resource[],

    changePoint(point: PointAllInfoDTO): void,
    changeQueryItem(val: SQueryItemDTO): void,
    requestConnPars(connId: string, connTypeId: number): void,
    readSettings(pointId: string | undefined | null, success?: () => void): void,
    requestPointAllInfo: (success: () => void) => void,

    setIsLoading: any,
    baseMapLayer: string | undefined,
    changeBaseMapLayer: any,

    showPointInfo: any,
    showPolling?: ()=>void,
    userProfile: any,
    changeProfile: any,
    setActiveQueries: any,
    showModal: any,

    activeTasks: SPointTaskDTO[],
    setActiveLogTask: any,
    getActiveTasks: any,
    readData: any,
    showPointLog(pointId: string) : void
}

type State = {
    //operation: AbonentOperationDTO,
    selectedAbonents: string[],
    selectedGroups: string[],
    //Задание которое можно создать вместе с созданием ТУ
    addQueryItem: boolean,

    curTabKey: string,
    showClosePointCheckbox: boolean,
    connParsChanged: boolean,
    showPingerForm: boolean,
    zModal: number,
    pingData: {address: string, pointId: string, pointNumber: string} | null,
    connectionStatus: number
    showTCPLogForm: boolean,
    TCPLogZModal: number,
    TCPLogClientID: string | null,
    disabled: boolean,

    pointOriginal: PointAllInfoDTO | null,

    pictures: SPictureDTO[],
    picturesRemovedIds: number[],
    picturesIsDirty: boolean,
    picturesOriginal: SPictureDTO[],

    //Списак заданий при редактировании ТУ
    queryItems: QueryItemEx[] | null,
    queryItemsOriginal: SQueryItemDTO[] | null,
    showQueryItemEdit: SQueryItemDTO | null,
}

class QueryItemEx {
    item: SQueryItemDTO;
    isChange: boolean;
    delete: boolean;
    new: boolean;

    constructor(qitem: SQueryItemDTO) {
        this.item = qitem;
        this.isChange = false;
        this.delete = false;
        this.new = false;
    }
}

const tabNames = {
    point: '0', dev: '1', props: '2', conn: '3', map: '4', vars: '5', pictures: '6', schedules: '7',
}
//Периодичность получения состояния входящего TCP соединения (мсек).
const TIMER_INTERVAL: number = 2000;

class PointEditForm extends React.Component<Props, State> {
    _timer: any;
    _waitTimeOperation: boolean;

    constructor(props: Props, state: State) {
        super(props);

        this.props.allResources.forEach(x => Object.assign(this.resourceTypes, {[x.id]: x.name}));

        this.state = {
            selectedAbonents: [],
            selectedGroups: [],
            addQueryItem: false,
            curTabKey: tabNames.point,
            showClosePointCheckbox: true,
            connParsChanged: false,
            showPingerForm: false,
            zModal: 0,
            pingData: null,
            connectionStatus: 0,
            showTCPLogForm: false,
            TCPLogZModal: 0,
            TCPLogClientID: null,
            pointOriginal: null,
            disabled: true,
            pictures: [],
            picturesIsDirty: false,
            picturesRemovedIds: [],
            picturesOriginal: [],
            queryItems: null,
            queryItemsOriginal: null,
            showQueryItemEdit: null,
        }
        this._timer = undefined;
        this._waitTimeOperation = false;
    }

    componentDidMount() {
        if (this.props.pointAllInfo !== undefined) {
            this.init();
        }
    }

    componentWillUnmount(): void {
        if (this._timer) {
            clearInterval(this._timer);
            this._timer = undefined;
        }
    }

    componentDidUpdate(prevProps: Props) {
        if ((prevProps.visible === false && this.props.visible === true)
            || (this.props.pointAllInfo != undefined && prevProps.pointAllInfo == undefined)) 
        {
            this.setState({
                connParsChanged: false, 
                pointOriginal: deepCopyObj(this.props.pointAllInfo) ?? null,
                disabled: this.props.pointAllInfo != undefined && this.props.pointAllInfo.pointInfo.id != null ? true : false,
            });
        }

        if (prevProps.pointAllInfo === undefined && this.props.pointAllInfo !== undefined) 
        {
            if (this.props.pointAllInfo.pointInfo.id != null) {
                this.loadQueryItems(this.props.pointAllInfo.pointInfo.id);
            }
        }

        //Момент инициализациия ТУ и словаря типов
        if ((prevProps.pointAllInfo === undefined && this.props.pointAllInfo !== undefined && this.props.pointDicts !== undefined)
            || (prevProps.pointDicts === undefined && this.props.pointDicts !== undefined && this.props.pointAllInfo !== undefined)) 
        {
            this.init();
        }

        if (prevProps.queryItemTypes === undefined && this.props.queryItemTypes !== undefined && this.props.visible === true) {
            let q: SQueryItemDTO = {
                pointId: '',
                enabled: false,
                id: null,
                name: '',
                priority: 1,
                queryTypes_Id: this.props.queryItemTypes?.queryTypes[0].id,
                retriesMax: 0,
                shedules_Id: this.props.queryItemTypes.scheduleTypes[0].id,
            }
            this.props.changeQueryItem(q);
        }
    }

    init = () => {
        if (this.props.pointAllInfo !== undefined && this.props.visible === true) {
            this.setState({
                showClosePointCheckbox: this.props.pointAllInfo.deviceInfo.closeDate === null, 
                curTabKey: tabNames.point,
                pictures: [],
                picturesIsDirty: false,
                picturesRemovedIds: [],
            });

            if (this.props.pointAllInfo.pointInfo.id === null) {
                let p = {...this.props.pointAllInfo};
                p.pointInfo.heatCompanyId = this.initId(p.pointInfo.heatCompanyId, this.props.pointDicts?.organizations);
                p.pointInfo.commDevTypeId = this.initId(p.pointInfo.commDevTypeId, this.props.pointDicts?.commDevTypes);
                p.deviceInfo.hmMontageId = this.initId(p.deviceInfo.hmMontageId, this.props.pointDicts?.montageCompanies);
                p.deviceInfo.raMontageId = this.initId(p.deviceInfo.raMontageId, this.props.pointDicts?.montageCompanies);
                this.props.changePoint(p);
            }
        }

        if (this.props.pointAllInfo !== undefined && this.props.pointAllInfo.pointInfo.id != null && this.props.visible === true) {
            getPointPictures(this.props.pointAllInfo.pointInfo.id, this.props.setIsLoading, (pictures: SPictureDTO[]) => {
                this.setState({ pictures: pictures, picturesOriginal: deepCopyObj(pictures), picturesRemovedIds: [], picturesIsDirty: false });
            });
        }

        if (!this._timer) {
            this._timer = setInterval(this.getConnectionStatus, TIMER_INTERVAL);
        }
    }

    onPictureAdd = (picture: SPictureDTO) => {
        const newPictures = [...this.state.pictures, picture];
        this.setState({ pictures: newPictures, picturesIsDirty: true })
    }

    onPictureRemove = (pictureName: string, pictureId: number) => {
        //Добавить в массив удаляемых идентификаторов, если нужно.
        const removedIds = [...this.state.picturesRemovedIds];
        if (pictureId !== 0) {
            removedIds.push(pictureId);
        }

        //Удалить из состава картинок.
        const newPictures = this.state.pictures.filter(p => p.name !== pictureName);

        this.setState({ pictures: newPictures, picturesRemovedIds: removedIds, picturesIsDirty: true });
    }

    onWriteAll(close: boolean, finish: (success: boolean) => void) {
        this.props.onWrite(close, (s: boolean, point: PointAllInfoDTO) => {
            if (s === true) {
                this.setState({pointOriginal: deepCopyObj(point)});
                this.onWritePictures((b: boolean) => {
                    if (b === false) {
                        finish(false);
                    } else {
                        if (this.state.queryItems != null) {
                            const arr = this.state.queryItems.filter(x => x.isChange);
                            this.writeQueryItemAll(arr, (s1: boolean) => {
                                finish(s1);
                            });
                        }
                    }
                })
            } else {
                finish(s);
            }
        });
    }

    onWritePictures(finish: (success: boolean) => void) {
        if (this.state.picturesIsDirty && this.props.pointAllInfo && this.props.pointAllInfo.pointInfo.id != null) {
            savePointPictures(this.props.pointAllInfo.pointInfo.id, this.state.pictures, this.state.picturesRemovedIds, this.props.setIsLoading, (b: boolean) => {
                if (b === true) {
                    this.setState({picturesOriginal: deepCopyObj(this.state.pictures), picturesIsDirty: false, picturesRemovedIds: []});
                } else {
                    message.error('Не удалось сохранить изображения.');
                    console.log('Не удалось сохранить изображения.', this.state.pictures);
                    this.setState({curTabKey: tabNames.pictures});
                }
                finish(b);
            });
        } else {
            finish(true);
        }
    }

    readSettings = (pointId: string | undefined | null) => {
        if (this.props.pointAllInfo != undefined) {
            message.success('Сбор данных.');
            this.setState({ disabled: true })
            this.onWriteAll(false, (s: boolean) => {
                if (s === true) {
                    this.props.readSettings(pointId, () => {
                        this.props.requestPointAllInfo(() => {
                            this.setState({ curTabKey: tabNames.vars, disabled: false })
                        });
                    });
                }
                else {
                    this.setState({ disabled: false })
                }
            });
        }
    }

    fillCoordinatesByAddress = () => {
        if (this.props.pointDicts?.useGeoServices) {
            if (this.props.pointAllInfo?.pointInfo.address !== '') {
                sendRequestToBackend(
                    this.props.pointAllInfo?.pointInfo.address,
                    'admin/coordinatesbyaddress',
                    (response: any) => {
                        if (response != null) {
                            if (this.props.pointAllInfo?.pointInfo != undefined) {
                                let res = {...this.props.pointAllInfo}
                                res.pointInfo = {...this.props.pointAllInfo.pointInfo}
                                res.pointInfo.latitude = response[0];
                                res.pointInfo.longitude = response[1];
                                this.props.changePoint(res);
                                message.success('Координаты по заданному адресу успешно получены');
                            }
                        } else {
                            message.error('Запрос координаты по заданному адресу завершился неудачей');
                        }
                    },
                    this.props.setIsLoading,
                    (error: any) => {
                        console.log(error);
                        message.error(error);
                    }
                );
            } else {
                message.warning('Не задан адрес точки учёта');
            }
        }
    }

    fillAddressByCoordinates = () => {
        if (this.props.pointDicts?.useGeoServices) {
            if (this.props.pointAllInfo?.pointInfo.latitude != undefined && this.props.pointAllInfo?.pointInfo.longitude != undefined) {
                sendRequestToBackend(
                    [this.props.pointAllInfo?.pointInfo.latitude, this.props.pointAllInfo?.pointInfo.longitude],
                    'admin/addressbycoordinates',
                    (response: any) => {
                        if (response != null) {
                            const r: {address: string, houseId: string, regionId: number} = response;

                            if (this.props.pointAllInfo?.pointInfo != undefined) {
                                let res = {...this.props.pointAllInfo}
                                res.pointInfo = {...this.props.pointAllInfo.pointInfo}
                                res.pointInfo.address = r.address;
                                res.pointInfo.regionId = r.regionId;
                                res.pointInfo.fiasHouseId = r.houseId;
                                this.props.changePoint(res);
                                message.success('Адрес по координатам успешно получен');
                            }
                        } else {
                            message.error('Запрос адреса по координатам завершился неудачей');
                        }
                    },
                    this.props.setIsLoading,
                    (error: any) => {
                        console.log(error);
                        message.error(error);
                    }
                );
            } else {
                message.warning('Не задан адрес точки учёта');
            }
        }
    }

    private onApply = (success?: () => void) => {
        if (this.props.pointAllInfo?.pointInfo.id != null && this.state.disabled === true) {
            this.setState({disabled: false});
        } else {
            if (this.props.isConnParsLoading) {
                message.warning('Необходимо дождаться загрузки параметров подключения');
            } else if (this.state.connParsChanged == true && this.props.pointAllInfo?.pointInfo.id != null) {
                sendRequestToBackend(
                    {pointInfoId: this.props.pointAllInfo.pointInfo.id, connectionTypeId: this.props.pointAllInfo.connectionInfo.typeId, parameters: this.props.pointAllInfo.connectionInfo.vParameters},
                    'admin/checkconnpars',
                    (response: any) => {
                        const t = (response as AdminStore.SValudationResultDTO);
                        if (t.result === AdminStore.ValidationResultEnum.warning) {
                            modal.confirm({
                                title: 'Внимание',
                                icon: <ExclamationCircleOutlined />,
                                width: 'fit-content',
                                content: this.replaceEndLineToBR(t.message),
                                onOk: () => { 
                                    this.onWriteAll(false, (b: boolean) => {
                                        if (b) if (success) success();
                                    });
                                },
                                zIndex: getModalZIndex(),
                            });
                        } else if (t.result === AdminStore.ValidationResultEnum.error) {
                            modal.warning({
                                title: 'Ошибка',
                                icon: <ExclamationCircleOutlined />,
                                content: t.message,
                                zIndex: getModalZIndex(),
                            });
                        } else if (t.result === AdminStore.ValidationResultEnum.ok) {
                            this.onWriteAll(false, (b: boolean) => {
                                if (b) if (success) success();
                            });
                        }
                    },
                    this.props.setIsLoading,
                    (error: any) => {
                        console.log(error);
                        message.error(error);
                    }
                );
            } else if (this.props.pointAllInfo != undefined) {
                if (this.props.pointAllInfo.pointInfo.id == null) {
                    this.setState({curTabKey: tabNames.point});
                }
                this.onWriteAll(false, (b: boolean) => {
                    if (b) if (success) success();
                });
            }
        }
    }

    private onHide = () => {
        if (this.props.pointAllInfo !== undefined && (compareObj(this.state.pointOriginal, this.props.pointAllInfo) === false || this.state.picturesIsDirty === true)) {
            modal.confirm({
                title: 'Внимание',
                icon: <ExclamationCircleOutlined />,
                width: 'fit-content',
                content: this.replaceEndLineToBR("Данные были изменены, хотите выйти потеряв изменения?"),
                onOk: () => {
                    this.props.onHide();
                },
                zIndex: getModalZIndex(),
            });
        } else {
            this.props.onHide();
        }
    }

    //Если словарь пустой возвращает -1 если нет то берет первое значение. Если currentId встречается в словаре возвращает его.
    private initId = (currentId: number | undefined | null, dict: {[id: number]: string} | undefined) => {
        let res = -1;
        
        const k = Object.keys(dict ?? {});
        if (k.length > 0) {
            res = Number(k[0]);
        }

        Object.keys(dict ?? {}).forEach(x => Number(x) == currentId ? res = Number(x) : null )
        
        return res;
    }

    private makeDevTypes = (): React.ReactElement[] => {
        let dic: {[parDevType: number]: number[]} = {};
        for (let pk in this.props.pointDicts?.deviceParentTypes) {
            dic[Number(pk)] = [];
            for (let k of this.props.pointDicts?.deviceTypes ?? []) {
                if (k.deviceParentTypeId === Number(pk)) {
                    dic[Number(pk)].push(k.id);
                }
            }
        }

        return Object.keys(dic).map(par => (
            <Select.OptGroup key={par} label={this.props.pointDicts?.deviceParentTypes[Number(par)]}>
                {
                    this.props.pointDicts?.deviceTypes
                        .filter(item => dic[Number(par)].includes(item.id))
                        .map(item1 => (
                            <Option key={item1.id} value={item1.id}>{item1.name}</Option>
                        ))
                }
            </Select.OptGroup>
        ))
    }

    private onMDescrChange = (id: number, obj: Partial<AdminStore.SMeasurementDescriptionDTO>) => {
        if (this.props.pointAllInfo != undefined) {
            let res = {...this.props.pointAllInfo};
            res.deviceInfo.measurementDescriptions = [...this.props.pointAllInfo.deviceInfo.measurementDescriptions];
            let i = res.deviceInfo.measurementDescriptions.findIndex(x => x.id == id);
            if (i !== -1) {
                res.deviceInfo.measurementDescriptions[i] = {...res.deviceInfo.measurementDescriptions[i], ...obj};
                this.props.changePoint(res);
            }
        }
    }

    replaceEndLineToBR = (str: string): React.ReactNode => {
        let arr = str.split("\r\n");
        if (arr.length < 2) {
            arr = str.split("\n");
        }
    
        let res = <>{arr.map(x => <>{x}<br /></>)}</>
        return res;
    }

    private onNextAction = () => {
        let skip = false;
        let checkNum = false;

        if (this.state.curTabKey === tabNames.point) {
            let messages: string[] = [];

            if (this.props.pointAllInfo?.pointInfo.number === '') {
                messages.push('Необходимо заполнить поле номер точки учета');
                skip = true;
            } else {
                checkNum = true;
            }
            if (this.props.pointAllInfo?.pointInfo.resourceTypeId === -1) {
                messages.push('Необходимо выбрать тип ресурса');
                skip = true;
            }

            if (checkNum === true) {
                skip = true;
                sendRequestToBackend(
                    this.props.pointAllInfo?.pointInfo.number,
                    'admin/checkpointnumber',
                    (response: any) => {
                        if (response !== '') {
                            message.warning({content: this.replaceEndLineToBR(response)});
                        } else {
                            if (messages.length === 0) {
                                this.nextPage();
                            }
                        }

                        for (const i of messages) {
                            message.warning(i);
                        }
                    },
                    this.props.setIsLoading,
                    (error: any) => {
                        console.log(error);
                        message.error(error);
                    }
                );
            } else {
                for (const i of messages) {
                    message.warning(i);
                }
            } 
        }
        if (this.state.curTabKey === tabNames.conn) {
            if (this.props.pointAllInfo?.connectionInfo.typeId === -1) {
                message.warning('Необходимо выбрать тип подключения');
                skip = true;
            }
            if (this.props.isConnParsLoading === true) {
                message.warning('Необходимо дождаться загрузки параметров');
                skip = true;
            }

            if (skip !== true && this.props.pointAllInfo != undefined) {
                skip = true;

                sendRequestToBackend(
                    {pointInfoId: "", connectionTypeId: this.props.pointAllInfo.connectionInfo.typeId, parameters: this.props.pointAllInfo.connectionInfo.vParameters},
                    'admin/checkconnpars',
                    (response: any) => {
                        const t = (response as AdminStore.SValudationResultDTO);
                        if (t.result === AdminStore.ValidationResultEnum.warning) {
                            modal.confirm({
                                title: 'Внимание',
                                icon: <ExclamationCircleOutlined />,
                                width: 'fit-content',
                                content: this.replaceEndLineToBR(t.message),
                                onOk: () => { this.nextPage() },
                                wrapClassName: MODAL_WRAP_CLASSNAME,
                                zIndex: getModalZIndex(),

                            });
                        } else if (t.result === AdminStore.ValidationResultEnum.error) {
                            modal.warning({
                                title: 'Ошибка',
                                icon: <ExclamationCircleOutlined />,
                                content: t.message,
                                wrapClassName: MODAL_WRAP_CLASSNAME,
                                zIndex: getModalZIndex(),
                            });
                        } else if (t.result === AdminStore.ValidationResultEnum.ok) {
                            this.nextPage();
                        }
                    },
                    this.props.setIsLoading,
                    (error: any) => {
                        console.log(error);
                        message.error(error);
                    }
                );
            }
        }
        if (this.state.curTabKey === tabNames.dev) {
            if (this.props.pointAllInfo?.deviceInfo.typeId === -1) {
                message.warning('Необходимо выбрать тип прибора');
                skip = true;
            }
        }

        if (!skip) {
            this.nextPage();
        }
    }

    private nextPage = () => {
        if (this.state.curTabKey != tabNames.schedules) {
            if (this.state.curTabKey === tabNames.map) {
                this.setState({curTabKey: String(Number(this.state.curTabKey) + 2)})
            } else {
                this.setState({curTabKey: String(Number(this.state.curTabKey) + 1)})
            }
        }
    }

    private onPrevAction = () => {
        if (this.state.curTabKey != tabNames.point) {
            if (this.state.curTabKey === tabNames.pictures) {
                this.setState({curTabKey: String(Number(this.state.curTabKey) - 2)})
            } else {
                this.setState({curTabKey: String(Number(this.state.curTabKey) - 1)})
            }
        }
    }

    private PInfoFieldChange = (field: keyof AdminStore.PointInfo, value: any) => {
        if (this.props.pointAllInfo !== undefined) {
            let point: PointAllInfoDTO = {...this.props.pointAllInfo};

            point.pointInfo = {...point.pointInfo, [field]: value};

            this.props.changePoint(point);
        }
    }

    private PDevFieldChange = (field: keyof AdminStore.PointDeviceInfo, value: any) => {
        if (this.props.pointAllInfo !== undefined) {
            let point: PointAllInfoDTO = {...this.props.pointAllInfo};

            point.deviceInfo = {...point.deviceInfo, [field]: value};

            this.props.changePoint(point);
        }
    }

    private PConnFieldChange = (field: keyof AdminStore.PointConnectionInfo, value: any) => {
        if (this.props.pointAllInfo !== undefined) {
            let point: PointAllInfoDTO = {...this.props.pointAllInfo};

            point.connectionInfo = {...point.connectionInfo, [field]: value};

            this.props.changePoint(point);
        }
    }

    private QItemFieldChange = (field: keyof SQueryItemDTO, value: any) => {
        if (this.props.queryItem !== undefined) {
            let query = {...this.props.queryItem, [field]: value};
            this.props.changeQueryItem(query);
        }
    }

    private onDevTypeChange = (id: number) => {
        if (this.props.pointAllInfo !== undefined) {
            let point: PointAllInfoDTO = {...this.props.pointAllInfo};

            point.deviceInfo.typeId = id;

            const p = this.props.pointDicts?.deviceTypes.find(x => x.id === id);
            point.deviceInfo.vParameters = p?.devicePrameters?.map(x => ({...x})) ?? [];
            this.props.changePoint(point);
        }
    }

    private onConnTypeChange = (id: number) => {
        if (this.props.pointAllInfo !== undefined) {
            let point: PointAllInfoDTO = {...this.props.pointAllInfo};
            point.connectionInfo.typeId = id;
            this.props.changePoint(point);
            this.props.requestConnPars(point.connectionInfo.id ?? '', point.connectionInfo.typeId);
        }
    }

    private onDevParChange = (parCode: string, val: SParameterValTypes) => {
        if (this.props.pointAllInfo !== undefined) {
            let point: PointAllInfoDTO = {...this.props.pointAllInfo};
            point.deviceInfo.vParameters = [...this.props.pointAllInfo.deviceInfo.vParameters];

            const i = this.props.pointAllInfo.deviceInfo.vParameters.findIndex(x => x.parameterCode === parCode);
            point.deviceInfo.vParameters[i].value = val;
            this.props.changePoint(point);
        }
    }

    private onConParChange = (parCode: string, val: SParameterValTypes) => {
        if (this.props.pointAllInfo != undefined) {
            let res = {...this.props.pointAllInfo};
            res.connectionInfo = {...this.props.pointAllInfo.connectionInfo}

            const i = this.props.pointAllInfo.connectionInfo.vParameters.findIndex(x => x.parameterCode === parCode);
            res.connectionInfo.vParameters[i] = {...res.connectionInfo.vParameters[i]}
            res.connectionInfo.vParameters[i].value = val;
            this.props.changePoint(res);

            this.setState({connParsChanged: true});
        }
    }

    private onConnParEditFinish = (parCode: string) => {
        if (this.props.pointAllInfo != undefined) {
            let res = {...this.props.pointAllInfo};
            res.connectionInfo = {...this.props.pointAllInfo.connectionInfo}

            sendRequestToBackend(
                {connection: res.connectionInfo, changedParameterName: parCode},
                'admin/dynchangeconnpars',
                (response: any) => {
                    //console.log(response);
                    if (this.props.pointAllInfo !== undefined) {
                        res.connectionInfo.vParameters = response;
                        this.props.changePoint(res);
                    }
                },
                this.props.setIsLoading,
                (error: any) => {
                    console.log(error);
                    message.error(error);
                }
            );
        }
    }

    private onTabChange = (k : string) => {
        if (this.props.pointAllInfo?.pointInfo.id !== null && compareObj(this.state.pointOriginal, this.props.pointAllInfo) === false) {
            modal.confirm({
                title: 'Внимание',
                icon: <ExclamationCircleOutlined />,
                width: 'fit-content',
                content: this.replaceEndLineToBR("Данные были изменены, сохранить изменения?"),
                okText: 'Да',
                cancelText: 'Нет',
                onOk: () => {
                    this.onApply(() => this.setState({curTabKey: k, disabled: true}));
                },
                onCancel: () => {
                    this.props.changePoint(deepCopyObj(this.state.pointOriginal));
                    this.setState({pictures: deepCopyObj(this.state.picturesOriginal), picturesIsDirty: false, picturesRemovedIds: []});
                },
                zIndex: getModalZIndex(),
            });
        } else if (this.props.pointAllInfo?.pointInfo.id !== null && this.state.picturesIsDirty === true) {
            modal.confirm({
                title: 'Внимание',
                icon: <ExclamationCircleOutlined />,
                width: 'fit-content',
                content: this.replaceEndLineToBR("Изображения были изменены, сохранить изменения?"),
                okText: 'Да',
                cancelText: 'Нет',
                onOk: () => {
                    this.onApply(() => this.setState({curTabKey: k, disabled: true}));
                },
                onCancel: () => {
                    this.props.changePoint(deepCopyObj(this.state.pointOriginal));
                    this.setState({pictures: deepCopyObj(this.state.picturesOriginal), picturesIsDirty: false, picturesRemovedIds: []});
                },
                zIndex: getModalZIndex(),
            });
        } else if (this.state.queryItems?.find(x => x.isChange === true) !== undefined) {
            modal.confirm({
                title: 'Внимание',
                icon: <ExclamationCircleOutlined />,
                width: 'fit-content',
                content: this.replaceEndLineToBR("Задания на опрос были изменены, сохранить изменения?"),
                okText: 'Да',
                cancelText: 'Нет',
                onOk: () => {
                    this.onApply(() => this.setState({curTabKey: k, disabled: true}));
                },
                onCancel: () => {
                    this.setState({queryItems: deepCopyObj(this.state?.queryItemsOriginal?.map(x => new QueryItemEx(x)) ?? null)});
                },
                zIndex: getModalZIndex(),
            });
        }else if (this.props.pointAllInfo?.pointInfo.id !== null) {
            this.setState({curTabKey: k});
        }
    }

    private resourceTypes: {[id: number]: string} = {};

    private closePoint = (e: any) => {
        if (this.props.pointAllInfo?.pointInfo.id !== null) {
            if (e.target.checked === true) {
                modal.confirm({
                    title: 'ВНИМАНИЕ!',
                    wrapClassName: MODAL_WRAP_CLASSNAME,
                    zIndex: getModalZIndex(),
                    icon: <ExclamationCircleOutlined />,
                    content: 'Вы собираетесь закрыть точку учёта. Открыть точку учёта после этого невозможно.',
                    onOk: () => {
                        this.PDevFieldChange('closeDate', new Date());
                    },
                });
            } else {
                this.PDevFieldChange('closeDate', null);
            }
        }
    }

    private changeCoords = (latlon: [number, number]) => {
        if (this.props.pointAllInfo != undefined) {
            let res = {...this.props.pointAllInfo};
            res.pointInfo = {...this.props.pointAllInfo?.pointInfo}
            res.pointInfo.latitude = latlon[0];
            res.pointInfo.longitude = latlon[1];

            this.props.changePoint(res);
            //console.log("changeCoords");
        }
    }

    openPingForm = (address: string | undefined) => {
        if (this.props.pointAllInfo != undefined && address) {
            this.setState({
                showPingerForm: true,
                zModal: getModalZIndex(), 
                pingData: {
                    address: address, 
                    pointId: this.props.pointAllInfo.pointInfo.id ?? '',
                    pointNumber: this.props.pointAllInfo.pointInfo.number,
                }
            });
        }
        else {
            message("Адрес для пинга не найден");
        }

    }


    showPingerForm = () => {
        if (this.props.pointAllInfo != undefined) {
            const p1 = this.props.pointAllInfo.connectionInfo.vParameters.filter(x => x.parameterCode === 'Address')?.[0];
            const p2 = this.props.pointAllInfo.connectionInfo.vParameters.filter(x => x.parameterCode === 'Client_ID')?.[0];

            if (p1 != undefined && p1.value != '') {
                this.openPingForm(p1.value?.toString());
            }
            else if (p2 != undefined) {
                sendRequestToBackend(
                    p2.value,
                    'admin/getlastconn',
                    (response: any) => {
                        if (!response) {
                            message.warning('Не было ни одного подключения модема.');
                        }
                        else if (response.remoteIP) {
                            this.openPingForm(response.remoteIP.split(':')[0]);
                        }
                    },
                    this.props.setIsLoading,
                    (error: any) => {
                        console.log(error);
                        message.error(error);
                    }
                );

            }
        }
    }

    showTCPLogForm = () => {
        if (this.props.pointAllInfo != undefined) {
            const p1 = this.props.pointAllInfo.connectionInfo.vParameters.filter(x => x.parameterCode === 'Client_ID')?.[0];
            if (p1 != undefined) {
                this.setState({
                    showTCPLogForm: true,
                    TCPLogZModal: getModalZIndex(), 
                    TCPLogClientID:  p1.value?.toString() ?? null
                });
            } else {
                message("Адрес для пинга не найден");
            }
        }
    }
    resetConnection = () => {
        if (this.props.pointAllInfo != undefined) {
            const p = this.props.pointAllInfo.connectionInfo.vParameters.filter(x => x.parameterCode === 'Client_ID')?.[0];
            if (p != undefined) {
                sendRequestToBackend(
                    p.value,
                    'admin/resetconnection',
                    (response: any) => {
                        if (response.success) {
                            message.success(response.message);
                        }
                        else{
                            message.warning(response.message);
                        }
                    },
                    this.props.setIsLoading,
                    (error: any) => {
                        console.log(error);
                        message.error(error);
                    }
                );
    
            } else {
                message("Клиент для подключения не определён.");
            }
        }
    }

    getConnectionStatus = () => {
        if (!this._waitTimeOperation && this.props.pointAllInfo != undefined && this.props.visible) {
            const p = this.props.pointAllInfo.connectionInfo.vParameters.filter(x => x.parameterCode === 'Client_ID')?.[0];
            if (p != undefined) {
                this._waitTimeOperation = true;
                sendRequestToBackend(
                    p.value,
                    'admin/getconnstatus',
                    (response: any) => {
                        const status: number = response.status;
                        if(this.state.connectionStatus !== status){
                            this.setState({connectionStatus: status})
                        }
                        this._waitTimeOperation = false;
                    },
                    null,
                    (error: any) => {
                        if (this._timer) {
                            clearInterval(this._timer);
                            this._timer = undefined;
                        }
                                        
                        console.log(error);
                        message.error(error);
                        this._waitTimeOperation = false;
                    }
                );
            }
        }
    }

    showPointInfo = (ev: any) => {
        if(this.props.pointAllInfo?.pointInfo && this.props.pointAllInfo.pointInfo.id)
        {
            const pointId: string = this.props.pointAllInfo.pointInfo.id;
            const pointNumber: string = this.props.pointAllInfo.pointInfo.number;
            const source: any = new (window.NetiSource as any)(this.execute);
            const parent: any = ReactDOM?.findDOMNode(this)?.parentNode;
            this.props.showPointInfo(pointId, pointNumber, source);
        }
    }

    showPointLog = () => {
        if (this.props.pointAllInfo?.pointInfo && this.props.pointAllInfo.pointInfo.id) {
            const pointId: string = this.props.pointAllInfo.pointInfo.id;
            this.props.showPointLog(pointId);
        }
    }

    execute = (cmd:any) => {
        netiExecute(cmd, this.props.userProfile, this.props.changeProfile, this.props.setIsLoading, this.props.setActiveQueries, null, this.props.showModal);
    }

    loadQueryItems = (pointId: string) => {
        this.setState({queryItems: [], queryItemsOriginal: []});
        sendRequestToBackend(
            pointId,
            'admin/queryitems',
            (response: any) => {
                if (response != null) {
                    this.setState({ queryItems: (response as SQueryItemDTO[]).map(x => new QueryItemEx(x)), queryItemsOriginal: deepCopyObj(response) });
                } else {
                    message.warning("Не удалось получить список заданий на опрос");
                }
            },
            this.props.setIsLoading,
            (error: any) => {
                message.warning("Ошибка при получении заданий на опрос");
                console.log(error);
            }
        );
    }

    private writeQueryItemAll(items: QueryItemEx[], finish: (s: boolean) => void) {
        let itms: QueryItemEx[] = deepCopyObj(items);
        let arr = itms.filter(x => x.delete === false);
        arr.forEach(x => {if (x.new) x.item.id = null});//Убираем временные идентификаторы для новых заданий
        let delarr = itms.filter(x => x.delete === true).map(x => x.item);
        
        sendRequestToBackend(
            {queryItems: arr.map(x => x.item), deleteQueryItems: delarr},
            'admin/queryitemwriteall',
            (response: any) => {
                if (response === true) {
                    if (this.props.pointAllInfo?.pointInfo.id != undefined) {
                        this.loadQueryItems(this.props.pointAllInfo?.pointInfo.id);
                    }
                    finish(true);
                } else {
                    message.warning("Не удалось изменить задание");
                    finish(false);
                }
            },
            this.props.setIsLoading,
            (error: any) => {
                message.warning("Ошибка при изменении задания");
                console.log(error);
                message.error(error);
                finish(false);
            }
        );
    }

    onQueryItemDelete(id: any): void {
        const qi = this.state?.queryItems?.findIndex(x => x.item.id == id)
        if (qi != undefined && qi != -1) {
            modal.confirm({
                title: 'Подтверждение.',
                wrapClassName: MODAL_WRAP_CLASSNAME,
                zIndex: getModalZIndex(),
                icon: <ExclamationCircleOutlined />,
                content: 'Вы действительно хотите удалить это задание на опрос?',
                onOk: () => {
                    const qs = [...(this.state?.queryItems ?? [])];
                    if (qs[qi].new == false) {
                        qs[qi].delete = true;
                        qs[qi].isChange = true;
                    } else {
                        qs.splice(qi, 1);
                    }
                    this.setState({queryItems: qs});
                },
            });
        }
    }

    onAddQueryItem = () => {
        if (this.props.queryItemTypes != undefined && this.state.queryItems != null && this.props.pointAllInfo?.pointInfo.id != null) {
            let q: SQueryItemDTO = {
                pointId: this.props.pointAllInfo.pointInfo.id,
                enabled: false,
                id: this.state.queryItems.length.toString() + (Math.floor(Math.random() * 100)).toString(),
                name: '',
                priority: 1,
                queryTypes_Id: this.props.queryItemTypes?.queryTypes[0].id,
                retriesMax: 0,
                shedules_Id: this.props.queryItemTypes.scheduleTypes[0].id,
            }

            this.setState({showQueryItemEdit: q, zModal: getModalZIndex()});
        }
    }

    //=============================================================================
    render() {
        const canEditPoint: boolean = this.props.userProfile.rights.indexOf(USER_RIGHTS.Admin) >= 0 ||
            this.props.userProfile.rights.indexOf(USER_RIGHTS.CanEditAbonents) >= 0 ||
            this.props.userProfile.rights.indexOf(USER_RIGHTS.CanAddAbonents) >= 0;

        const itemsColumns = [
            {
                dataIndex: "name",
                title: "Переменная"
            },
            {
                dataIndex: "unitCaption",
                title: "Размерность"
            },
            {
                dataIndex: "unitName",
                title: ""
            },
            {
                dataIndex: "channelSign",
                title: "Трубопровод"
            },
            {
                dataIndex: "sign",
                title: "Наименование",
                render: (text: any, record: any, index: any) => (
                    <Input size={'small'} value={text} onChange={val => this.onMDescrChange(record.id, {sign: val.target.value})} disabled={this.state.disabled} />
                )
            },
            {
                dataIndex: "min",
                title: "Min",
                render: (text: any, record: any, index: any) => (
                    <InputNumber size={'small'} value={text} onChange={val => this.onMDescrChange(record.id, {minValue: val})} disabled={this.state.disabled} />
                )
            },
            {
                dataIndex: "max",
                title: "Max",
                render: (text: any, record: any, index: any) => (
                    <InputNumber size={'small'} value={text} onChange={val => this.onMDescrChange(record.id, {maxValue: val})} disabled={this.state.disabled} />
                )
            },
        ];

        const point = this.props.pointAllInfo;

        const itemsDataSource = this.props.pointAllInfo?.deviceInfo.measurementDescriptions.map(x => {
            return ({
                id: x.id,
                name: x.name,
                unitCaption: x.caption,
                unitName: x.unitName,
                channelSign: x.channelSign,
                sign: x.sign,
                min: x.minValue,
                max: x.maxValue,
            })
        })

        const tabProps: object = {
            height: this.props.windowHeight * 0.67,
            minHeight: '450px',
            width: '100%',
        }

        const queryColumns = [
            {
                dataIndex: "edit",
                title: "",
                align: 'center' as 'center',
                render: (text: any, record: any, index: any) => (
                    <Tooltip placement="top" title={'Редактировать'}>
                        <Button disabled={this.state.disabled} shape="circle" onClick={() => {this.setState({zModal: getModalZIndex(), showQueryItemEdit: this.state?.queryItems?.find(x => x.item.id == record.id)?.item ?? null})}} icon={<EditTwoTone twoToneColor={Const.COLORS.SvgIconColor}/>} />
                    </Tooltip>
                )
            },
            {
                dataIndex: "delete",
                title: "",
                align: 'center' as 'center',
                render: (text: any, record: any, index: any) => (
                    <Tooltip placement="top" title={'Удалить'}>
                        <Button disabled={this.state.disabled} shape="circle" onClick={() => this.onQueryItemDelete(record.id)} icon={<DeleteTwoTone twoToneColor={Const.COLORS.SvgIconColor}/>} />
                    </Tooltip>
                )
            },
            {
                dataIndex: "enabled",
                title: "Активность",
                align: 'center' as 'center',
                render: (val: any, record: any, index: any) => (
                    <Tooltip placement="top" title={'Задание разрешено'}>
                        <Switch disabled={this.state.disabled} checked={val} onChange={(c) => {
                            let qi = this.state.queryItems?.findIndex(x => x.item.id == record.id);
                            if (qi != undefined && qi != -1 && this.state?.queryItems != undefined) {
                                const arr = [...this.state?.queryItems];
                                arr[qi].item.enabled = c;
                                arr[qi].isChange = true;
                                this.setState({queryItems: arr});
                            }
                        }} />
                    </Tooltip>
                )
            },
            {
                dataIndex: "start",
                title: "",
                render: (text: any, record: any, index: any) => {
                    const isActive: boolean = this.props.activeTasks.find(t => t.queryId === record.id) !== undefined;
                    const isEnabled = record.schedule && (record.schedule.shdType_Id === AdminStore.IS_DAY_SCHEDULE_ID || record.schedule.shdType_Id === AdminStore.IS_REQUEST_SCHEDULE_ID) &&
                        record.type && (record.type.id !== AdminStore.QueryTypesEnum.configurator &&
                            record.type.id !== AdminStore.QueryTypesEnum.connection_test &&
                            record.type.id !== AdminStore.QueryTypesEnum.calculate_archive_from_current);
                    if (isActive) {
                        return <Tooltip placement="top" title={'Запрос остановки задания'}>
                            <Button shape="circle"
                                onClick={() => stopQueryItem(
                                this.state.queryItemsOriginal ?
                                    this.state.queryItemsOriginal.find(x => x.id === record.id) : undefined,
                                this.props.setIsLoading,
                                this.props.getActiveTasks)}
                                icon={<StopTwoTone twoToneColor={Const.COLORS.SvgIconColor} />} />
                        </Tooltip>;
                    }
                    else {
                        return <Tooltip placement="top" title={'Запрос запуска задания'} >
                            <Button shape="circle"
                                onClick={
                                    (record.schedule && (record.schedule.shdType_Id === AdminStore.IS_REQUEST_SCHEDULE_ID)) ?
                                        () => requestQueryPointData(this.props.pointAllInfo?.pointInfo.id ?? undefined, record.id, record.type, this.props.readData, this.props.setActiveLogTask) :
                                        () => startQueryItem(
                                this.state.queryItemsOriginal ?
                                    this.state.queryItemsOriginal.find(x => x.id === record.id) : undefined,
                                    this.props.setIsLoading,
                                    this.props.setActiveLogTask,
                                    this.props.getActiveTasks)}
                                icon={<PlayCircleTwoTone twoToneColor={Const.COLORS.SvgIconColor} />} />
                        </Tooltip >;
                    }
                }
            },
            {
                dataIndex: "schedule",
                title: "Расписание",
                render: (text: any, record: any, index: any) => {
                    return record.schedule?.name;
                }
            },
            {
                dataIndex: "name",
                title: "Название",
            },
            {
                dataIndex: "priority",
                title: "Приоритет",
            },
            {
                dataIndex: "type",
                title: "Тип задания",
                render: (text: any, record: any, index: any) => {
                    return record.type?.description;
                }
            },
        ];

        const queryData = () => {
            return (this.state.queryItems) ?
                this.state.queryItems.filter(x => x.delete === false).map(x => ({
                    key: x.item.id,
                    id: x.item.id,
                    enabled: x.item.enabled,
                    schedule: this.props.queryItemTypes?.scheduleTypes.find(y => y.id === x.item.shedules_Id),
                    name: x.item.name,
                    priority: x.item.priority,
                    type: this.props.queryItemTypes?.queryTypes.find(y => y.id === x.item.queryTypes_Id),
                })) 
                :
                [];
        }

        return (
            this.props.pointAllInfo == undefined || this.props.isPointAllInfoLoading
                || (this.props.pointAllInfo.pointInfo.id === null && this.props.isQueryItemTypesLoading) ? null :
            <>
            <Modal
                maskClosable={false}
                //bodyStyle={{height: 550, overflow: 'auto'}}
                title={ this.props.pointAllInfo?.pointInfo.id === null ? 'Добавление новой ТУ' : "Редактирование " + (this.props.pointAllInfo?.pointInfo.number ?? '') }
                wrapClassName={MODAL_WRAP_CLASSNAME}
                zIndex={this.props.zIndex}
                open={this.props.visible}
                footer={[
                    <div key={1} style={{ display: 'flex', alignItems: 'baseline' }}>
                        {
                            this.props.pointAllInfo?.pointInfo.id != null ?
                            <>
                                <Button key="info" type='primary' onClick={this.showPointInfo}>Информация</Button>
                                {
                                    this.props.showPolling &&
                                    <Button key="monitoring" type='primary' onClick={this.props.showPolling}>Мониторинг</Button>
                                }
                                {
                                    this.state?.curTabKey === tabNames.conn &&
                                    ((this.props.pointDicts?.connectionTypes?.[this.props.pointAllInfo?.connectionInfo.typeId ?? -1]?.extensionTable_Con === CONNECTIONS_TCPCLIENT) ||
                                    (this.props.pointDicts?.connectionTypes?.[this.props.pointAllInfo?.connectionInfo.typeId ?? -1]?.extensionTable_Con === CONNECTIONS_TCPIP) ||
                                     (this.props.pointDicts?.connectionTypes?.[this.props.pointAllInfo?.connectionInfo.typeId ?? -1]?.extensionTable_Con === CONNECTIONS_TCPIP_BYPORT)) ?
                                    <Button key="ping" type='primary' onClick={this.showPingerForm}>Пингование</Button>
                                    : null
                                }
                                {
                                        this.state?.curTabKey === tabNames.conn ?
                                            <Button key="pointLog" type='primary' onClick={this.showPointLog}>Лог</Button>
                                        : null
                                }
                                {
                                    this.state?.curTabKey === tabNames.conn && 
                                    ((this.props.pointDicts?.connectionTypes?.[this.props.pointAllInfo?.connectionInfo.typeId ?? -1]?.extensionTable_Con === CONNECTIONS_TCPIP) ||
                                        (this.props.pointDicts?.connectionTypes?.[this.props.pointAllInfo?.connectionInfo.typeId ?? -1]?.extensionTable_Con === CONNECTIONS_TCPIP_BYPORT)) ?
                                    <Button key="tcpipcontrol" type='primary' onClick={this.resetConnection}
                                     icon={ this.state.connectionStatus === 1 ? <EyeTwoTone twoToneColor={'#00FF00'} /> : <EyeInvisibleTwoTone twoToneColor={'#FF0000'} />}>Сброс TCP IP</Button>
                                    : null
                                }
                                {
                                    this.state?.curTabKey === tabNames.conn && 
                                    ((this.props.pointDicts?.connectionTypes?.[this.props.pointAllInfo?.connectionInfo.typeId ?? -1]?.extensionTable_Con === CONNECTIONS_TCPIP) ||
                                        (this.props.pointDicts?.connectionTypes?.[this.props.pointAllInfo?.connectionInfo.typeId ?? -1]?.extensionTable_Con === CONNECTIONS_TCPIP_BYPORT)) ?
                                    <Button key="tcpipstate" type='primary' onClick={this.showTCPLogForm}>Состояние TCP</Button>
                                    : null
                                }
                            </> : null
                        }
                        <Button key="cancel" type='primary' onClick={this.onHide} style={{marginLeft: 'Auto'}}>Отмена</Button>
                        {
                        this.props.pointAllInfo?.pointInfo.id === null ?
                            <>
                                <Button key="prev" type='primary' disabled={this.state.curTabKey === tabNames.point} onClick={this.onPrevAction}>Назад</Button>
                                <Button key="next" type='primary' disabled={this.state.curTabKey === tabNames.schedules} onClick={this.onNextAction}>Далее</Button>
                            </> : null
                        }
                        <Tooltip title={canEditPoint ? 'Включение режима редактирования.' : 'У Вас нет прав для изменения точки учёта.'}>
                        <Button key="ok" type='primary' 
                            onClick={() => {this.onApply(() => this.setState({disabled: true}))}}
                            disabled={(this.props.pointAllInfo?.pointInfo.id === null && this.state.curTabKey !== tabNames.schedules) || !canEditPoint}
                        >
                            {this.props.pointAllInfo?.pointInfo.id === null ? "Создать" : (this.state.disabled === true ? "Изменить" : "Применить")}
                        </Button>
                        </Tooltip>
                    </div>
                ]}
                onCancel={() => { this.onHide() }}
                width={850}
                centered
            >
                <div style={{}} className={pageStyles.FormWrapper}>
                    {
                        <Tabs type="card" tabBarStyle={{marginBottom: '0px'}} activeKey={this.state.curTabKey} onChange={this.onTabChange}>
                            <Tabs.TabPane tab="Точка учета" key={tabNames.point} disabled={point?.pointInfo.id === null && this.state.curTabKey !== tabNames.point} className={pageStyles.TabBorder}>
                                <div style={{...tabProps}}>
                                    <Input.Group>
                                        <Row className={pageStyles.FormRowWrapper}>
                                            <Col span={8}>
                                                Номер точки учета
                                            </Col>
                                            <Col span={16}>
                                                <Input size='small' value={point?.pointInfo.number} onChange={val => this.PInfoFieldChange('number', val.target.value)} disabled={this.state.disabled}/>
                                            </Col>
                                        </Row>
                                        <Row className={pageStyles.FormRowWrapper}>
                                            <Col span={8}>
                                                Наименование
                                            </Col>
                                            <Col span={16} flex={1}>
                                                <Input size='small' value={point?.pointInfo.name} onChange={val => this.PInfoFieldChange('name', val.target.value)} disabled={this.state.disabled}/>
                                            </Col>
                                        </Row>
                                        <Row className={pageStyles.FormRowWrapper}>
                                            <Col span={8}>
                                                Ведомости сдаются
                                            </Col>
                                            <Col span={16}>
                                                {/* {_mdo.CompanyReports.GetAllOrganizations()} */}
                                                <Select value={point?.pointInfo.heatCompanyId} onChange={val => this.PInfoFieldChange('heatCompanyId', val)} size="small" className={pageStyles.Width100} disabled={this.state.disabled}>
                                                    {
                                                        Object.keys(this.props.pointDicts?.organizations ?? {}).map(item => (
                                                            <Option key={item} value={Number(item)}>{this.props.pointDicts?.organizations?.[Number(item)]}</Option>
                                                        ))
                                                    }
                                                </Select>
                                            </Col>
                                        </Row>
                                        <Row className={pageStyles.FormRowWrapper}>
                                            <Col span={8}>
                                                Примечание
                                            </Col>
                                            <Col span={16}>
                                                <Input value={point?.pointInfo.comment} onChange={val => this.PInfoFieldChange('comment', val.target.value)} className={pageStyles.Width100} size='small' disabled={this.state.disabled} />
                                            </Col>
                                        </Row>
                                        <Row className={pageStyles.FormRowWrapper}>
                                            <Col span={8}>
                                                Контактное лицо
                                            </Col>
                                            <Col span={16}>
                                                <Input value={point?.pointInfo.contactPerson} onChange={val => this.PInfoFieldChange('contactPerson', val.target.value)} className={pageStyles.Width100} size='small' disabled={this.state.disabled} />
                                            </Col>
                                        </Row>
                                        <Row className={pageStyles.FormRowWrapper}>
                                            <Col span={8}>
                                                Субъект РФ
                                            </Col>
                                            <Col span={16}>
                                                {/* {regionComboBox.Items.AddRange(_mdo.Regions.GetAllRegions());} */}
                                                <Select value={point?.pointInfo.regionId ?? -1} onChange={val => this.PInfoFieldChange('regionId', val === -1 ? null : val)} size="small" className={pageStyles.Width100} disabled={this.state.disabled}>
                                                    <>
                                                        <Option key={-1} value={-1}>-</Option>
                                                        {
                                                            Object.keys(this.props.pointDicts?.regions ?? {}).map(item => (
                                                                <Option key={item} value={Number(item)}>{this.props.pointDicts?.regions?.[Number(item)]}</Option>
                                                            ))
                                                        }
                                                    </>
                                                </Select>
                                            </Col>
                                        </Row>
                                        <Row className={pageStyles.FormRowWrapper}>
                                            <Col span={8}>
                                                Адрес
                                            </Col>
                                            <Col span={16}>
                                                <Input value={point?.pointInfo.address} onChange={val => this.PInfoFieldChange('address', val.target.value)} size='small' disabled={this.state.disabled} />
                                            </Col>
                                        </Row>
                                        <Row className={pageStyles.FormRowWrapper}>
                                            <Col span={8}>
                                                Метеостанция
                                            </Col>
                                            <Col span={16}>
                                                {/* weatherStationComboBox.Items.AddRange(_mdo.Regions.GetAllWeatherStations()); */}
                                                <Select value={point?.pointInfo.weatherStationId ?? -1}  onChange={val => this.PInfoFieldChange('weatherStationId', val === -1 ? null : val)} size="small" className={pageStyles.Width100} disabled={this.state.disabled}>
                                                    <>
                                                        <Option key={-1} value={-1}>-</Option>
                                                        {
                                                            Object.keys(this.props.pointDicts?.weatherStations ?? {}).map(item => (
                                                                <Option key={item} value={Number(item)}>{this.props.pointDicts?.weatherStations?.[Number(item)]}</Option>
                                                            ))
                                                        }
                                                    </>
                                                </Select>
                                            </Col>
                                        </Row>
                                        <Row className={pageStyles.FormRowWrapper}>
                                            <Col span={8}>
                                                Ведомость
                                            </Col>
                                            <Col span={16}>
                                                {/* _mdo.Reports.ReadAll(true, false) */}
                                                <Select value={point?.pointInfo.reportId} onChange={val => this.PInfoFieldChange('reportId', val)} size="small" className={pageStyles.Width100} disabled={this.state.disabled}>
                                                    <>
                                                        <Option key={-1} value={0}>По умолчанию</Option>
                                                        {
                                                            Object.keys(this.props.pointDicts?.reports ?? {}).map(item => (
                                                                <Option key={item} value={Number(item)}>{this.props.pointDicts?.reports?.[Number(item)]}</Option>
                                                            ))
                                                        }
                                                    </>
                                                </Select>
                                            </Col>
                                        </Row>
                                        <Row className={pageStyles.FormRowWrapper}>
                                            <Col span={8}>
                                                Ведомость по текущим
                                            </Col>
                                            <Col span={16}>
                                                {/* _mdo.Reports.ReadAll(true, false, PeriodsEnum.current) */}
                                                <Select value={point?.pointInfo.currentsReportId ?? -1} onChange={val => this.PInfoFieldChange('currentsReportId', val === -1 ? null : val)} size="small" className={pageStyles.Width100} disabled={this.state.disabled}>
                                                    <>
                                                        <Option key={-1} value={-1}>-</Option>
                                                        {
                                                            Object.keys(this.props.pointDicts?.reportsCurrent ?? {}).map(item => (
                                                                <Option key={item} value={Number(item)}>{this.props.pointDicts?.reportsCurrent?.[Number(item)]}</Option>
                                                            ))
                                                        }
                                                    </>
                                                </Select>
                                            </Col>
                                        </Row>
                                        <Row className={pageStyles.FormRowWrapper}>
                                            <Col span={8}>
                                                Тип ресурса
                                            </Col>
                                            <Col span={16}>
                                                <Select value={point?.pointInfo.resourceTypeId} onChange={val => this.PInfoFieldChange('resourceTypeId', val)} size="small" className={pageStyles.Width100} disabled={this.state.disabled}>
                                                    <>
                                                        { point?.pointInfo.id === null ? <Option key={-1} value={-1}>-</Option> : null }
                                                        {
                                                            Object.keys(this.resourceTypes).map(item => (
                                                                <Option key={item} value={Number(item)}>{this.resourceTypes[Number(item)]}</Option>
                                                            ))
                                                        }
                                                    </>
                                                </Select>
                                            </Col>
                                        </Row>
                                        <Row className={pageStyles.FormRowWrapper}>
                                            <Col span={8}>
                                                Ответственный
                                            </Col>
                                            <Col span={16}>
                                                {/* 
                                                    Performer[] aclsPerformers = _mdo.DeviceEventsWebService.AllPerformersGet().ToArray();
                                                    // Фейковый исполнитель. Необходим, если для точки учета отсутствует исполнитель
                                                    Performer clsNULLPerformer = new Performer(-1, "<Не задано>", null, null, 0);
                                                    */}
                                                <Select value={point?.pointInfo.performerId ?? -1} onChange={val => this.PInfoFieldChange('performerId', val === -1 ? null : val)} size="small" className={pageStyles.Width100} disabled={this.state.disabled}>
                                                    <>
                                                        <Option key={-1} value={-1}>-</Option>
                                                        {
                                                            Object.keys(this.props.pointDicts?.performers ?? {}).map(item => (
                                                                <Option key={item} value={Number(item)}>{this.props.pointDicts?.performers?.[Number(item)]}</Option>
                                                            ))
                                                        }
                                                    </>
                                                </Select>
                                            </Col>
                                        </Row>
                                        <Row className={pageStyles.FormRowWrapper}>
                                            <Col span={8}>
                                                Дата подключения УД
                                            </Col>
                                            <Col span={16}>
                                                <DatePicker disabled={true} value={point?.deviceInfo.raInstallDate == null ? undefined : moment(point?.deviceInfo.raInstallDate)} size={'small'} />
                                            </Col>
                                        </Row>
                                        {
                                            point?.pointInfo.id === null ? null :
                                            <Row className={pageStyles.FormRowWrapper}>
                                                <Col span={8}>
                                                    { this.state.showClosePointCheckbox === true ? 'Закрыть ТУ' : 'Дата закрытия УД' }
                                                </Col>
                                                <Col span={16}>
                                                {
                                                    this.state.showClosePointCheckbox === true ? <Checkbox disabled={this.state.disabled} checked={point?.deviceInfo.closeDate !== null} onChange={this.closePoint}></Checkbox> : 
                                                    <DatePicker disabled={true} value={moment(point?.deviceInfo.closeDate)} size='small' />
                                                }
                                                </Col>
                                            </Row>
                                        }
                                    </Input.Group>
                                </div>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Прибор" key={tabNames.dev} disabled={point?.pointInfo.id === null && this.state.curTabKey !== tabNames.dev} className={pageStyles.TabBorder}>
                                <div style={{...tabProps}}>
                                    <Input.Group>
                                        <Row className={pageStyles.FormRowWrapper}>
                                            <Col span={8}>
                                                Тип прибора
                                            </Col>
                                            <Col span={16}>
                                                {/* 
                                                    _mdo.DeviceTypes.GetAllDeviceParentTypes()
                                                    _mdo.DeviceTypes.GetAllDeviceTypes(dpt) 
                                                */}
                                                <Select value={point?.deviceInfo.typeId == -1 ? undefined : point?.deviceInfo.typeId} onChange={this.onDevTypeChange} size="small" className={pageStyles.Width100}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => String(option?.children ?? '').toLowerCase().includes(input.toLowerCase())}
                                                    disabled={this.state.disabled}
                                                >
                                                {
                                                    this.makeDevTypes()
                                                }
                                                </Select>
                                            </Col>
                                        </Row>
                                        <Row className={pageStyles.FormRowWrapper}>
                                            <Col span={8}>
                                                Серийный номер
                                            </Col>
                                            <Col span={16}>
                                                <Input value={point?.deviceInfo.serialNumber} onChange={val => this.PDevFieldChange('serialNumber', val.target.value)} size='small' disabled={this.state.disabled} />
                                            </Col>
                                        </Row>
                                        <Row className={pageStyles.FormRowWrapper}>
                                            <Col span={8}>
                                                Тип
                                            </Col>
                                            <Col span={16}>
                                                <Input value={point?.deviceInfo.deviceType} onChange={val => this.PDevFieldChange('deviceType', val.target.value)} size='small' disabled={this.state.disabled} />
                                            </Col>
                                        </Row>
                                        <Row className={pageStyles.FormRowWrapper}>
                                            <Col span={8}>
                                                Версия ПО
                                            </Col>
                                            <Col span={16}>
                                                <Input value={point?.deviceInfo.softwareVersion} onChange={val => this.PDevFieldChange('softwareVersion', val.target.value)} size='small' disabled={this.state.disabled} />
                                            </Col>
                                        </Row>
                                        <Row className={pageStyles.FormRowWrapper}>
                                            <Col span={8}>
                                                Описание
                                            </Col>
                                            <Col span={16}>
                                                <Input value={point?.deviceInfo.description} onChange={val => this.PDevFieldChange('description', val.target.value)} size='small' disabled={this.state.disabled} />
                                            </Col>
                                        </Row>
                                        <Row className={pageStyles.FormRowWrapper}>
                                            <Col span={8}>
                                                Тип системы
                                            </Col>
                                            <Col span={16}>
                                                {/* 
                                                    SystemTypecomboBox.Items.Add(new NamedIdItem((int)SystemTypesEnum.None, "Не указано"));
                                                    SystemTypecomboBox.Items.Add(new NamedIdItem((int)SystemTypesEnum.Open, "Открытая"));
                                                    SystemTypecomboBox.Items.Add(new NamedIdItem((int)SystemTypesEnum.Closed, "Закрытая"));
                                                    */}
                                                <Select value={point?.deviceInfo.systemType} onChange={val => this.PDevFieldChange('systemType', val === -1 ? null : val)} size="small" className={pageStyles.Width100} disabled={this.state.disabled}>
                                                    <>
                                                        <Option key={-1} value={-1}>-</Option>
                                                        {
                                                            Object.keys(this.props.pointDicts?.systemTypes ?? {}).map(item => (
                                                                <Option key={item} value={Number(item)}>{this.props.pointDicts?.systemTypes?.[Number(item)]}</Option>
                                                            ))
                                                        }
                                                    </>
                                                </Select>
                                            </Col>
                                        </Row>
                                        <Row className={pageStyles.FormRowWrapper}>
                                            <Col span={8}>
                                                Вид теплоснабжения
                                            </Col>
                                            <Col span={16}>
                                                {/* 
                                                    TypeOfHeatSupplycomboBox.Items.Add(new NamedIdItem((int)TypeOfHeatSupplyEnum.None, "Не указано"));
                                                    TypeOfHeatSupplycomboBox.Items.Add(new NamedIdItem((int)TypeOfHeatSupplyEnum.Dependent, "Зависимая"));
                                                    TypeOfHeatSupplycomboBox.Items.Add(new NamedIdItem((int)TypeOfHeatSupplyEnum.Independent, "Независимая"));
                                                */}
                                                <Select value={point?.deviceInfo.typeOfHeatSupply ?? -1} onChange={val => this.PDevFieldChange('typeOfHeatSupply', val === -1 ? null : val)} size="small" className={pageStyles.Width100} disabled={this.state.disabled}>
                                                    <>
                                                        <Option key={-1} value={-1}>-</Option>
                                                        {
                                                            Object.keys(this.props.pointDicts?.typesOfHeatSupply ?? {}).map(item => (
                                                                <Option key={item} value={Number(item)}>{this.props.pointDicts?.typesOfHeatSupply?.[Number(item)]}</Option>
                                                            ))
                                                        }
                                                    </>
                                                </Select>
                                            </Col>
                                        </Row>
                                        <Row className={pageStyles.FormRowWrapper}>
                                            <Col span={8}>
                                                Монтаж
                                            </Col>
                                            <Col span={16}>
                                                {/* _mdo.CompanyReports.GetMontageCompanies(); */}
                                                <Select value={point?.deviceInfo.hmMontageId} onChange={val => this.PDevFieldChange('hmMontageId', val)} size="small" className={pageStyles.Width100} disabled={this.state.disabled}>
                                                    {
                                                        Object.keys(this.props.pointDicts?.montageCompanies ?? {}).map(item => (
                                                            <Option key={item} value={Number(item)}>{this.props.pointDicts?.montageCompanies?.[Number(item)]}</Option>
                                                        ))
                                                    }
                                                </Select>
                                            </Col>
                                        </Row>
                                        <Row className={pageStyles.FormRowWrapper}>
                                            <Col span={8}>
                                                Монтаж уд. доступа
                                            </Col>
                                            <Col span={16}>
                                                {/* _mdo.CompanyReports.GetMontageCompanies(); */}
                                                <Select value={point?.deviceInfo.raMontageId} onChange={val => this.PDevFieldChange('raMontageId', val)} size="small" className={pageStyles.Width100} disabled={this.state.disabled}>
                                                    {
                                                        Object.keys(this.props.pointDicts?.montageCompanies ?? {}).map(item => (
                                                            <Option key={item} value={Number(item)}>{this.props.pointDicts?.montageCompanies?.[Number(item)]}</Option>
                                                        ))
                                                    }
                                                </Select>
                                            </Col>
                                        </Row>
                                        <Row className={pageStyles.FormRowWrapper}>
                                            <Col span={8}>
                                                Температурный гр-к
                                            </Col>
                                            <Col span={16}>
                                                {/* _mdo.TemperatureCharts.GetTypes() */}
                                                <Select value={point?.pointInfo.temperatureChartId ?? -1} onChange={val => this.PInfoFieldChange('temperatureChartId', val === -1 ? null : val)} size="small" className={pageStyles.Width100} disabled={this.state.disabled}>
                                                    <>
                                                        <Option key={-1} value={-1}>-</Option>
                                                        {
                                                            Object.keys(this.props.pointDicts?.temperatureCharts ?? {}).map(item => (
                                                                <Option key={item} value={Number(item)}>{this.props.pointDicts?.temperatureCharts?.[Number(item)].name}</Option>
                                                            ))
                                                        }
                                                    </>
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Input.Group>
                                    
                                    { this.props.pointAllInfo.pointInfo.id !== null ?
                                        <Space direction='vertical'>
                                            <Button type='primary' onClick={()=>this.readSettings(this.props.pointAllInfo?.pointInfo.id)} disabled={this.state.disabled}>Чтение настроек</Button>
                                            <SParametersEdit pars={point?.deviceInfo.vParameters?.filter(x => x.readOnly === true) ?? []} onChangeField={this.onConParChange} disabled={this.state.disabled}/>
                                        </Space>
                                        :
                                        <SParametersEdit pars={point?.deviceInfo.vParameters?.filter(x => x.readOnly === true) ?? []} onChangeField={this.onConParChange} disabled={this.state.disabled}/>
                                    }
                                </div>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Свойства" key={tabNames.props} disabled={point?.pointInfo.id === null && this.state.curTabKey !== tabNames.props} className={pageStyles.TabBorder}>
                                <div style={{...tabProps}}>
                                    <SParametersEdit pars={point?.deviceInfo.vParameters?.filter(x => x.readOnly === false) ?? []} onChangeField={this.onDevParChange} disabled={this.state.disabled}/>
                                </div>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Подключение" key={tabNames.conn} disabled={point?.pointInfo.id === null && this.state.curTabKey !== tabNames.conn} className={pageStyles.TabBorder}>
                                <div style={{...tabProps}}>
                                    <Input.Group>
                                        <Row className={pageStyles.FormRowWrapper}>
                                            <Col span={8}>
                                                Тип подключения
                                            </Col>
                                            <Col span={16}>
                                                {/* _mdo.ConnectionDevices.GetConnectionTypes() */}
                                                <Select value={point?.connectionInfo.typeId == -1 ? undefined : point?.connectionInfo.typeId} onChange={this.onConnTypeChange} size="small" className={pageStyles.Width100} disabled={this.state.disabled}>
                                                    {
                                                        Object.keys(this.props.pointDicts?.connectionTypes ?? {}).map(item => (
                                                            <Option key={item} value={Number(item)}>{this.props.pointDicts?.connectionTypes?.[Number(item)].description}</Option>
                                                        ))
                                                    }
                                                </Select>
                                            </Col>
                                        </Row>
                                        <Row className={pageStyles.FormRowWrapper}>
                                            <Col span={8}>
                                                Тип устройства связи
                                            </Col>
                                            <Col span={16}>
                                                {/* _mdo.ConnectionDevices.GetConnDevTypes() */}
                                                <Select value={point?.pointInfo.commDevTypeId} onChange={val => this.PInfoFieldChange('commDevTypeId', val)} size="small" className={pageStyles.Width100} disabled={this.state.disabled}>
                                                    {
                                                        Object.keys(this.props.pointDicts?.commDevTypes ?? {}).map(item => (
                                                            <Option key={item} value={Number(item)}>{this.props.pointDicts?.commDevTypes?.[Number(item)]}</Option>
                                                        ))
                                                    }
                                                </Select>
                                            </Col>
                                        </Row>
                                        <Row className={pageStyles.FormRowWrapper}>
                                            <Col span={8}>
                                                Уровень сигнала
                                            </Col>
                                            <Col span={16}>
                                                <Input value={point?.pointInfo.signalLevel} onChange={val => this.PInfoFieldChange('signalLevel', val.target.value)} size='small' disabled={this.state.disabled} />
                                            </Col>
                                        </Row>
                                        <Row className={pageStyles.FormRowWrapper}>
                                            <Col span={8}>
                                                Попытки соединений
                                            </Col>
                                            <Col span={16}>
                                                <Space>
                                                    <InputNumber value={point?.connectionInfo.retrySession} onChange={val => this.PConnFieldChange('retrySession', val)} size="small" defaultValue={3} disabled={this.state.disabled} />
                                                    <Checkbox checked={point?.connectionInfo.retrySessionOrder} onChange={val => this.PConnFieldChange('retrySessionOrder', val.target.checked)} disabled={this.state.disabled}>Все соединения подряд</Checkbox>
                                                </Space>
                                            </Col>
                                        </Row>
                                        <Row className={pageStyles.FormRowWrapper}>
                                            <Col span={8}>
                                                Время соединения
                                            </Col>
                                            <Col span={16}>
                                                <InputNumber value={point?.connectionInfo.sessionTimeout} onChange={val => this.PConnFieldChange('sessionTimeout', val)} size="small" defaultValue={3} disabled={this.state.disabled} />
                                            </Col>
                                        </Row>
                                        <Row className={pageStyles.FormRowWrapper}>
                                            <Col span={8}>
                                                Попытки запросов
                                            </Col>
                                            <Col span={16}>
                                                <InputNumber value={point?.connectionInfo.retryQuery} onChange={val => this.PConnFieldChange('retryQuery', val)} size="small" defaultValue={3} disabled={this.state.disabled} />
                                            </Col>
                                        </Row>
                                        <Row className={pageStyles.FormRowWrapper}>
                                            <Col span={8}>
                                                Время ответа
                                            </Col>
                                            <Col span={16}>
                                                <InputNumber value={point?.connectionInfo.responseTimeout} onChange={val => this.PConnFieldChange('responseTimeout', val)} size="small" defaultValue={3} disabled={this.state.disabled} />
                                            </Col>
                                        </Row>
                                        <Row className={pageStyles.FormRowWrapper}>
                                            <Col span={8}>
                                                Группа блокировки
                                            </Col>
                                            <Col span={16}>
                                                <Input value={point?.connectionInfo.lockName} onChange={val => this.PConnFieldChange('lockName', val.target.value)} disabled={true} size='small' />
                                            </Col>
                                        </Row>
                                        <Row className={pageStyles.FormRowWrapper}>
                                            <Col span={8}>
                                                Инициализация
                                            </Col>
                                            <Col span={16}>
                                                <Input value={point?.connectionInfo.extraParameters} onChange={val => this.PConnFieldChange('extraParameters', val.target.value)} size='small' disabled={this.state.disabled} />
                                            </Col>
                                        </Row>
                                    </Input.Group>
                                    <SParametersEdit onEditFinish={this.onConnParEditFinish} pars={point?.connectionInfo.vParameters ?? []} isLoading={this.props.isConnParsLoading} onChangeField={this.onConParChange} disabled={this.state.disabled}/>
                                </div>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Карта" key={tabNames.map} disabled={point?.pointInfo.id === null && this.state.curTabKey !== tabNames.map} className={pageStyles.TabBorder}>
                                <div style={{...tabProps}}>
                                    <div style={{display: 'flex', height: '100%', flexDirection: 'column'}}>
                                        <div style={{flexGrow: 1}}>
                                            <SimpleMap
                                                baseLayer={this.props.baseMapLayer}
                                                changeBaseLayer={this.props.changeBaseMapLayer}
                                                latitude={point?.pointInfo.latitude ?? null}
                                                longitude={point?.pointInfo.longitude ?? null}
                                                changeCoords={this.changeCoords}
                                                disabled={this.state.disabled}
                                            />
                                        </div>
                                        <Row className={pageStyles.FormRowWrapper}>
                                            <Col span={12}>
                                                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'baseline'}}>
                                                    Широта
                                                    <InputNumber size='small' onChange={(val) => this.PInfoFieldChange('latitude', val)} value={point?.pointInfo.latitude ?? undefined} style={{width: '60%', marginRight: '40px'}}  disabled={this.state.disabled}/>
                                                </div>
                                            </Col>
                                            {
                                                this.props.pointDicts?.useGeoServices ?
                                                <Col span={12}>
                                                    <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}><Button size='small' onClick={this.fillCoordinatesByAddress} type='primary' disabled={this.state.disabled}>Заполнить координаты по адресу</Button></div>
                                                </Col> : null
                                            }
                                        </Row>
                                        <Row className={pageStyles.FormRowWrapper}>
                                            <Col span={12}>
                                                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'baseline'}}>
                                                    Долгота
                                                    <InputNumber size='small' onChange={(val) => this.PInfoFieldChange('longitude', val)} value={point?.pointInfo.longitude ?? undefined} style={{width: '60%', marginRight: '40px'}} disabled={this.state.disabled} />
                                                </div>
                                            </Col>
                                            {
                                                this.props.pointDicts?.useGeoServices ?
                                                <Col span={12}>
                                                    <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}><Button size='small' onClick={this.fillAddressByCoordinates} type='primary' disabled={this.state.disabled}>Заполнить адрес по координатам</Button></div>
                                                </Col> : null
                                            }
                                        </Row>
                                    </div>
                                </div>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Переменные" key={tabNames.vars} disabled={point?.pointInfo.id === null && this.state.curTabKey !== tabNames.vars} className={pageStyles.TabBorder}>
                                <div style={{...tabProps}}>
                                    <Table
                                        bordered={true}
                                        size={'small'}
                                        rowKey="id"
                                        columns={itemsColumns}
                                        dataSource={itemsDataSource}
                                        pagination={false}
                                    />
                                </div>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Картинки" key={tabNames.pictures} disabled={point?.pointInfo.id === null && this.state.curTabKey !== tabNames.pictures} className={pageStyles.TabBorder}>
                                <div style={{...tabProps}}>
                                <ImageLoader
                                    pictures={this.state.pictures}
                                    onAdd={this.onPictureAdd}
                                    onRemove={this.onPictureRemove}
                                    disabled={this.state.disabled}
                                />
                                </div>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={this.props.pointAllInfo?.pointInfo.id == null ? "Задание" : "Задания"} key={tabNames.schedules} disabled={point?.pointInfo.id === null && this.state.curTabKey !== tabNames.schedules} className={pageStyles.TabBorder}>
                                <div style={{...tabProps}}>
                                {
                                    this.props.pointAllInfo.pointInfo.id == null ?
                                    <Input.Group>
                                        <Row className={pageStyles.FormRowWrapper}>
                                            <Col span={8}>
                                            <Checkbox checked={this.state.addQueryItem} disabled={this.state.disabled} onChange={() => {
                                                    this.setState({addQueryItem: !this.state.addQueryItem});
                                                }}>
                                                Добавить задание
                                            </Checkbox>
                                            </Col>
                                        </Row>
                                        <Row className={pageStyles.FormRowWrapper}>
                                            <Col span={8}>
                                                Расписание
                                            </Col>
                                            <Col span={16}>
                                                <Select 
                                                    value={this.props.queryItem?.shedules_Id} 
                                                    onChange={(val) => this.QItemFieldChange('shedules_Id', val)} 
                                                    size="small" className={pageStyles.Width100}
                                                    disabled={(!this.state.addQueryItem) || this.state.disabled}
                                                >
                                                    {
                                                        this.props.queryItemTypes?.scheduleTypes.map(item => (
                                                            <Option key={item.id} value={item.id}>{item.name}</Option>
                                                        ))
                                                    }
                                                </Select>
                                            </Col>
                                        </Row>
                                        <Row className={pageStyles.FormRowWrapper}>
                                            <Col span={8}>
                                                Имя
                                            </Col>
                                            <Col span={16}>
                                                <Input size='small' disabled={!this.state.addQueryItem || this.state.disabled} value={this.props.queryItem?.name} onChange={(val) => this.QItemFieldChange('name', val.target.value)} />
                                            </Col>
                                        </Row>
                                        <Row className={pageStyles.FormRowWrapper}>
                                            <Col span={8}>
                                                Приоритет
                                            </Col>
                                            <Col span={16}>
                                                <InputNumber size='small' disabled={!this.state.addQueryItem || this.state.disabled} value={this.props.queryItem?.priority} onChange={(val) => this.QItemFieldChange('priority', val)} className={pageStyles.Width100} />
                                            </Col>
                                        </Row>
                                        <Row className={pageStyles.FormRowWrapper}>
                                            <Col span={8}>
                                                Задание
                                            </Col>
                                            <Col span={16}>
                                                <Select 
                                                    value={this.props.queryItem?.queryTypes_Id} 
                                                    onChange={(val) => this.QItemFieldChange('queryTypes_Id', val)}
                                                    size="small" className={pageStyles.Width100}
                                                    disabled={!this.state.addQueryItem || this.state.disabled}
                                                >
                                                    {
                                                        this.props.queryItemTypes?.queryTypes.map(item => (
                                                            <Option key={item.id} value={item.id}>{item.description}</Option>
                                                        ))
                                                    }
                                                </Select>
                                            </Col>
                                        </Row>
                                        <Row className={pageStyles.FormRowWrapper}>
                                            <Col span={8}>
                                                Активно
                                            </Col>
                                            <Col span={16}>
                                                <Checkbox disabled={!this.state.addQueryItem || this.state.disabled} checked={this.props.queryItem?.enabled} onChange={(val) => {this.QItemFieldChange('enabled', val.target.checked)}} />
                                            </Col>
                                        </Row>
                                    </Input.Group>
                                    :
                                    <>
                                    <Table
                                        columns={queryColumns}
                                        dataSource={queryData()}
                                        size={'small'}
                                        pagination={false}
                                        bordered={true}
                                    />
                                    <Button disabled={this.state.disabled} style={{margin: '5px 0px'}} key="1" type='primary' onClick={this.onAddQueryItem}>Добавить задание</Button>
                                    </>
                                }
                                </div>
                            </Tabs.TabPane>
                        </Tabs>
                    }
                </div>
                
            </Modal>
            <PingForm
                visible={this.state?.showPingerForm ?? false}
                zIndex={this.state.zModal}
                onHide={() => this.setState({showPingerForm: false, pingData: null})}
                address={this.state.pingData?.address ?? ''}
                showPointInfo={this.showPointInfo}
            />
            <TCPLogTable
                visible={this.state?.showTCPLogForm ?? false}
                zIndex ={this.state.TCPLogZModal}
                clientId={this.state.TCPLogClientID}
                onHide={() => this.setState({showTCPLogForm: false, TCPLogClientID: null})}
            />
            <QueryItemEditForm 
                queryItem={this.state.showQueryItemEdit}
                onHide={() => this.setState({showQueryItemEdit: null})}
                queryItemTypes={this.props.queryItemTypes}
                changeQueryItem={(val: SQueryItemDTO) => this.setState({showQueryItemEdit: val})}
                onOk={() => {
                    if (this.state.queryItems != null) {
                    let i = this.state.queryItems.findIndex(x => x.item.id == this.state.showQueryItemEdit?.id);
                        if (i != -1) {
                            if (this.state?.queryItems != null && this.state.showQueryItemEdit != null) {
                                let arr = [...this.state.queryItems];
                                arr[i] = new QueryItemEx(this.state.showQueryItemEdit);
                                arr[i].isChange = true;
                                this.setState({queryItems: arr, showQueryItemEdit: null});
                            }
                        } else { //Если не найдено в массиве значит это новый
                            if (this.state?.queryItems != null && this.state.showQueryItemEdit != null) {
                                let arr = [...this.state.queryItems];
                                let qie = new QueryItemEx(this.state.showQueryItemEdit);
                                qie.isChange = true;
                                qie.new = true;
                                arr.push(qie);
                                this.setState({queryItems: arr, showQueryItemEdit: null});
                            }
                        }
                    }
                }}
                zIndex={this.state.zModal}
            />
            </>

        );
    }
}

export default PointEditForm;