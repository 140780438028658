import React, { useEffect, useRef, useState } from 'react';
import { Table } from 'antd';
import type { TableProps} from 'antd';
import classNames from 'classnames';
import ResizeObserver from 'rc-resize-observer';

import { VariableSizeGrid as Grid } from 'react-window';

//export type VirtalTableProps = TableProps<RecordType> &
//{

//};

export const VirtualTable = <RecordType extends object>(props: TableProps<RecordType> &
{
    selectedId: string | undefined,
    onSelect: (row: any) => void,
    onAction: (row: any) => void
}) => {
    const rowHight = 40;
    const { columns, scroll } = props;
    const [tableWidth, setTableWidth] = useState(0);

    const widthColumnCount = columns!.filter(({ width }) => !width).length;
    const mergedColumns = columns!.map(column => {
        if (column.width) {
            return column;
        }

        return {
            ...column,
            width: Math.floor(tableWidth / widthColumnCount),
        };
    });

    const gridRef = useRef<any>();
    const [connectObject] = useState<any>(() => {
        const obj = {};
        Object.defineProperty(obj, 'scrollLeft', {
            get: () => {
                if (gridRef.current) {
                    return gridRef.current?.state?.scrollLeft;
                }
                return null;
            },
            set: (scrollLeft: number) => {
                if (gridRef.current) {
                    gridRef.current.scrollTo({ scrollLeft });
                }
            },
        });

        return obj;
    });

    const resetVirtualGrid = () => {
        gridRef.current?.resetAfterIndices({
            columnIndex: 0,
            shouldForceUpdate: true,
        });
    };

    useEffect(() => resetVirtualGrid, [tableWidth]);

    const renderVirtualList = (rawData: readonly RecordType[], { scrollbarSize, ref, onScroll }: any) => {
        ref.current = connectObject;
        const totalHeight = rawData.length * rowHight;

        return (
            <Grid
                ref= { gridRef }
                className = "virtual-grid"
                columnCount = { mergedColumns.length }
                columnWidth = {(index: number) => {
                    const { width } = mergedColumns[index];
                return totalHeight > ((scroll!.y!) as number) && index === mergedColumns.length - 1
                    ? (width as number) - scrollbarSize - 1
                    : (width as number);
                }}
                height = { scroll!.y as number }
                rowCount={rawData.length}
                rowHeight={() => rowHight}
                width = { tableWidth }
                onScroll = {({ scrollLeft }: { scrollLeft: number }) => {
                    onScroll({ scrollLeft });
                }}
            >
                {({
                    columnIndex,
                    rowIndex,
                    style,
                }: {
                    columnIndex: number;
                    rowIndex: number;
                    style: React.CSSProperties;
                }) => (
                    <div
                        className={
                            classNames('virtual-table-cell', {
                                'virtual-table-cell-last': columnIndex === mergedColumns.length - 1,
                                'virtual-table-cell-selected': (rawData[rowIndex] as any).key === props.selectedId
                                ,
                            })}
                        style={style}
                        onClick={() => { props.onSelect(rawData[rowIndex]) }}
                        onDoubleClick={() => { props.onAction(rawData[rowIndex]) }}
                        title={(mergedColumns as any)[columnIndex].key === 'nn' ? (rowIndex + 1) + '.' : (rawData[rowIndex] as any)[(mergedColumns as any)[columnIndex].dataIndex]}
                    >
                        {(mergedColumns as any)[columnIndex].key === 'nn' ? (rowIndex+1) + '.' : (rawData[rowIndex] as any)[(mergedColumns as any)[columnIndex].dataIndex]}
                    </div>
            )}
            </Grid>
            );
        };

            return (
                <ResizeObserver
                  onResize= {({ width }) => {
                    setTableWidth(width);
                    }}
                >
                <Table
                    { ...props }
                    className = "virtual-table"
                    columns = { mergedColumns }
                    tableLayout={'fixed'}
                    pagination={false}
                    components = {{
                        body: renderVirtualList,
                    }}
                />
                </ResizeObserver>
              );
            };

