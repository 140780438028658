import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { Table } from 'antd';
import moment from 'moment';

import { ApplicationState } from '../store';
import * as PageStore from '../store/Page';
import * as UserProfileStore from '../store/UserProfile';
import * as WeatherStore from '../store/WeatherStore';
import { BaseProfile } from '../decl';
import styles from '../resources/Page.module.less';
import { sendRequestToBackend } from '../utils/AuthUtils';
import { message } from '../utils/Common';

const actionCreators = { ...PageStore.actionCreators, ...UserProfileStore.actionCreators, ...WeatherStore.actionCreators };


const K_MB_TO_MM = 0.750063755419211;

type SWeatherItemDTO = {
    id: number,
    dateTime:Date,
    pressure: number
    temperature: number,
    humidity: number,
    windDirection: string,
    windVelocity: number,
    cloudiness: number
}

const columns = [
    {
        title: 'Время',
        dataIndex: 'dateTime',
        key: 'time',
    },
    {
        title: 'Температура',
        dataIndex: 'temperature',
        key: 'temperature',
    },
    {
        title: 'Давление',
        dataIndex: 'pressure',
        key: 'pressure',
    },
    {
        title: 'Влажность',
        dataIndex: 'humidity',
        key: 'humidity',
    },
    {
        title: 'Облачность',
        dataIndex: 'cloudiness',
        key: 'cloudiness',
    },
    {
        title: 'Ветер',
        dataIndex: 'windDirection',
        key: 'windDirection',
    },
    {
        title: 'Сила ветра',
        dataIndex: 'windVelocity',
        key: 'windVelocity',
    },
];



type WeatherProps =
    {
        page: PageStore.PageState,
        profile: BaseProfile,
        weather: WeatherStore.IWeatherState
    } &
    typeof actionCreators &
    RouteComponentProps<{}>

interface IWeatherState {
    weather: SWeatherItemDTO[] | null
}

class Weather extends React.PureComponent <WeatherProps, IWeatherState > {

    constructor(props: WeatherProps){
        super(props);
        
        this.state = {
            weather: null
        }
    }

    componentDidMount = () => {
        this.getWeather();
    }

    componentDidUpdate = (prevProps: WeatherProps) => {
        if (!prevProps.weather.needRefresh && this.props.weather.needRefresh) {
            this.getWeather();
        }
    }

    getWeather = () => {
        sendRequestToBackend(
            {
                weatherStationId: this.props.weather.currentWeatherStationId,
                from: this.props.weather.dateFrom,
                to: this.props.weather.dateTo
            },
            'common/weather',
            (response: any) => {
                this.setState({ weather: response });
                this.props.setNeedRefresh(false);
            },
            this.props.setIsLoading,
            (error: any) => {
                console.log(error);
                message.error(error);
                this.setState({ weather: null });
                this.props.setNeedRefresh(false);
            },
            this.props.closeSession);
    }


    public render() {

        let key: number = 0;
        const dataSource: any = this.state.weather ? this.state.weather.map((item) => {
            key++;
            return {
                key: key.toString(),
                dateTime: moment(new Date(item.dateTime)).format('DD.MM.YYYY HH:mm'),
                temperature: item.temperature,
                pressure: Math.round(item.pressure * K_MB_TO_MM),
                humidity: item.humidity,
                cloudiness: item.cloudiness,
                windDirection: item.windDirection,
                windVelocity: item.windVelocity,
            }
        }) : undefined;

        return (
                <div className={styles.container}>
                <div style={{ overflow: 'auto', height: '100%' }}>
                    {
                        this.state.weather &&
                        <Table
                            columns={columns}
                            dataSource={dataSource}
                            bordered
                            pagination={{ showSizeChanger: true, pageSizeOptions: ["5", "10", "20", "50", "100"], defaultPageSize: 10, locale: { items_per_page: " / страницу" } }}
                        />
                    }
                </div>
                </div>
        );
    }    
}

export default connect(
    (state: ApplicationState) => {
        return {
            page: state.page,
            profile: state.userProfile?.profile,
            weather: state.weather
        }
    },
    actionCreators
)(Weather as any);
