import * as React from 'react';
import { Space, Tag, Button } from 'antd';
import {PlusCircleTwoTone} from '@ant-design/icons';

import SelectForm from './SelectForm';
import { Group, Point, Resource } from "../../decl";
import styles from '../../resources/Page.module.less';
import { getModalZIndex } from '../../utils/Common';

export interface IGroupTagsProps {
    groups: Group[] | undefined,
    groupsTree: Group[],
    groupPoints: {[groupId: string] : Point[]};
    resources?: Resource[],

    getPoints: any,
    setIsLoading: any,

    changeGroups: any,
    removeGroup: any,
    isOpened : boolean,
    onClose : any
}

interface IGroupTagsState{
    selectFormVisible: boolean,
    modalZIndex: number,
    selectedGroupIds: string[]
}

export class GroupTags extends React.PureComponent<IGroupTagsProps, IGroupTagsState> {
    constructor(props: IGroupTagsProps) {
        super(props);

        this.state = {
            selectFormVisible: false,
            modalZIndex: 0,
            selectedGroupIds: []
        }
       
    }

    componentDidUpdate(prevProps: IGroupTagsProps){
        if(this.props.isOpened && !prevProps.isOpened){
            this.showSelectForm();
        }

    }
    showSelectForm = () => {
        this.setState({selectFormVisible: true, selectedGroupIds: this.props.groups ? this.props.groups.map(g=>g.id) : [], modalZIndex:getModalZIndex()});
    }

    onChangeTree = (groups: string[], points: string[]) =>{

        this.setState({selectedGroupIds: groups});
    }

    onGroupsSelect = (groups: Group[]) => {
        this.props.changeGroups(groups);
        this.setState({selectFormVisible: false, selectedGroupIds: [], modalZIndex:getModalZIndex()});
    }

    render(): React.ReactNode {
        return <>
            <Space size={[0, 8]} wrap>
            {
                this.props.groups &&
                this.props.groups.map(item => (
                    <Tag 
                        color="cyan" 
                        closable 
                        onClose={(e: any) => {e.preventDefault(); this.props.removeGroup(item);}}
                        key={item.id}
                        className={styles.TagMargin}
                    >
                        {item.name}
                    </Tag>)
                )
            }

            {
                <Button shape="circle"
                    onClick={() => this.showSelectForm()}
                    icon={<PlusCircleTwoTone twoToneColor="#52c41a" />}
                />
            }
    </Space>
    {
        <SelectForm
            title={"Выбор групп"}
            visible={this.state.selectFormVisible}
            zIndex={this.state.modalZIndex}
            groups={this.props.groupsTree}
            groupPoints={this.props.groupPoints}
            selectedGroupIds={this.state.selectedGroupIds}
            selectedPointIds={[]}
            getPoints={this.props.getPoints}
            getPointsParentGroups={null}
            onChange={  this.onChangeTree}
            onChangeGroups={this.onGroupsSelect}
            setIsLoading={this.props.setIsLoading}
            onlyGroupsSelect={true}
            onClose={() => {
                this.setState({selectFormVisible:false});
                this.props.onClose();
            }
        }
            resources = {this.props.resources}
        />
    }                    

        </>
    }
}