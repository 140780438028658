import { message } from '../utils/Common';
import internal from 'assert';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { Resource, ResponseStatusesEnum, SPointsFilter, SResponseDTO } from '../decl';
import { BACKEND_URL, getHeaders } from '../utils/AuthUtils';
import { CloseSessionAction } from './Page';

//Состояние хранилища для страницы Reports.
export interface IOperationsState {
    isLoadingAbonentsOperations: boolean,
    abonentsOperations: AbonentOperationDTO[] | undefined,
    abonentOperationTypes: {[id: number] : AbonentOperationTypeDTO} | undefined,
    showDialogAddNewAbonentOperationFlag: boolean,
}

export interface AbonentOperationDTO
{
    id: number,
    abonentId: string,
    abonentNumber: string | undefined,
    abonentName: string | undefined,
    abonentAddress: string | undefined,
    resource: Resource | undefined,
    beginDate: Date,
    endDate: Date,
    operationTypeId: number,
    comment: string,
}

export interface AbonentOperationTypeDTO
{
    id: number;
    description: string;
}

interface SetState { type: 'SET_OPERATIONS_STATE'; value: Partial<IOperationsState> };
interface ReceiveAbonentsOperations { type: 'RECEIVE_ABONENTS_OPERATIONS', abonentsOperations: AbonentOperationDTO[] };
interface ReceiveAbonentOperationTypes { type: 'RECEIVE_ABONENT_OPERATION_TYPES', types: AbonentOperationTypeDTO[]}

export type KnownAction = CloseSessionAction | SetState | ReceiveAbonentsOperations | ReceiveAbonentOperationTypes;

export const actionCreators = {
    requestAbonentsOperations: (filter: SPointsFilter, fromDate: Date, toDate: Date): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({type: 'SET_OPERATIONS_STATE', value: {isLoadingAbonentsOperations: true}});
        
        if (appState) {
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify({filter, fromDate, toDate})
            };
            fetch(BACKEND_URL + 'AbonentOperation/abonentsoperations', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        dispatch({type: 'RECEIVE_ABONENTS_OPERATIONS', abonentsOperations: data.body})
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                    }
                    else {
                        message.error('Ответ не получен.');
                    }
                    dispatch({type: 'SET_OPERATIONS_STATE', value: {isLoadingAbonentsOperations: false}});
                })
                .catch(error => {
                    message.error('Ошибка:' + error);
                    console.log(error);
                    dispatch({type: 'SET_OPERATIONS_STATE', value: {isLoadingAbonentsOperations: false}});
                });
            
        }
    },

    requestAbonentsOperationsAdd: (filter: SPointsFilter, template: AbonentOperationDTO): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({type: 'SET_OPERATIONS_STATE', value: {isLoadingAbonentsOperations: true}});
        
        if (appState) {
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify({filter, template})
            };
            
            fetch(BACKEND_URL + 'AbonentOperation/addabonentsoperations', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        dispatch({type: 'RECEIVE_ABONENTS_OPERATIONS', abonentsOperations: [...data.body, ...(appState.operations?.abonentsOperations ?? [])]})
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                    }
                    else {
                        message.error('Ответ не получен.');
                    }
                    dispatch({type: 'SET_OPERATIONS_STATE', value: {isLoadingAbonentsOperations: false}});
                })
                .catch(error => {
                    message.error('Ошибка:' + error);
                    console.log(error);
                    dispatch({type: 'SET_OPERATIONS_STATE', value: {isLoadingAbonentsOperations: false}});
                });
            
        }
    },
    
    requestAbonentOperationTypes: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({type: 'SET_OPERATIONS_STATE', value: {isLoadingAbonentsOperations: true}});
        
        if (appState) {
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
                //body: JSON.stringify({filter, template})
            };
            
            fetch(BACKEND_URL + 'AbonentOperation/types', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        dispatch({type: 'RECEIVE_ABONENT_OPERATION_TYPES', types: data.body})
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                    }
                    else {
                        message.error('Ответ не получен.');
                    }
                    dispatch({type: 'SET_OPERATIONS_STATE', value: {isLoadingAbonentsOperations: false}});
                })
                .catch(error => {
                    message.error('Ошибка:' + error);
                    console.log(error);
                    dispatch({type: 'SET_OPERATIONS_STATE', value: {isLoadingAbonentsOperations: false}});
                });
            
        }
    },

    requestAbonentsOperationsDelete: (ids: number[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({type: 'SET_OPERATIONS_STATE', value: {isLoadingAbonentsOperations: true}});
        
        if (appState) {
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify(ids)
            };
            
            fetch(BACKEND_URL + 'AbonentOperation/deleteabonentsoperations', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        dispatch({type: 'RECEIVE_ABONENTS_OPERATIONS', abonentsOperations: appState.operations?.abonentsOperations?.filter(item => !ids.includes(item.id)) ?? []})
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                    }
                    else {
                        message.error('Ответ не получен.');
                    }
                    dispatch({type: 'SET_OPERATIONS_STATE', value: {isLoadingAbonentsOperations: false}});
                })
                .catch(error => {
                    message.error('Ошибка:' + error);
                    console.log(error);
                    dispatch({type: 'SET_OPERATIONS_STATE', value: {isLoadingAbonentsOperations: false}});
                });
            
        }
    },

    showDialogAddNewAbonentOperation: (flag: boolean) => ({ type: 'SET_OPERATIONS_STATE', value: {showDialogAddNewAbonentOperationFlag: flag} } as SetState),
}

export const reducer: Reducer<IOperationsState> = (state: IOperationsState | undefined, incomingAction: Action): IOperationsState => {
    if (state === undefined) {
        return { isLoadingAbonentsOperations: false, abonentsOperations: undefined, abonentOperationTypes: undefined, showDialogAddNewAbonentOperationFlag: false};
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'RECEIVE_ABONENTS_OPERATIONS':
            return {...state,  abonentsOperations: action.abonentsOperations};
        case "SET_OPERATIONS_STATE":
            return {
                ...state,
                ...action.value
            };
        case 'RECEIVE_ABONENT_OPERATION_TYPES':
            return {...state,  abonentOperationTypes: Object.assign({}, ...action.types.map(item => ({[item.id]: item})))};
        default:
            return state;
    }
};
