import { EventTypesEnum, PeriodsEnum, Resource, ResponseStatusesEnum, SourceEventTypesEnum, SPointsFilter, SResponseDTO, SPointEventDTO, SPointWithEventsDTO } from "../decl";
import { Action, Reducer } from 'redux';
import { AppThunkAction } from ".";
import { BACKEND_URL, getHeaders } from "../utils/AuthUtils";
import { CloseSessionAction } from "./Page";
import { message } from "../utils/Common";
import { isTemplateMiddle } from "typescript";
import Item from "antd/lib/list/Item";


export interface SConditionsAddRequest
{
    filter: SPointsFilter,
    conditionTemplate: SConditionDTO,
    periods: PeriodsEnum[],
}

export interface SConditionDTO
{
    id: string,
    forGroup: boolean,
    targetId: string,
    targetName: string,
    conditionText: string,
    eventType: EventTypesEnum,
    messageText: string,
    description: string,
    period: PeriodsEnum,
    resources: Resource[],

}

//Состояние хранилища для страницы Reports.
export interface IControlState {
    //events: SPointEventDTO[] | undefined;
    //isLoadingEvents: boolean;
    points: SPointWithEventsDTO[] | undefined;
    allEventsCount: number;
    lastEventId: number;
    isLoadingPoints: boolean;
    isEventsAcceptRequest: boolean;
    conditions: SConditionDTO[] | undefined;
    isLoadingConditions: boolean;
}

interface SetState { type: 'SET_CONTROL_STATE'; value: Partial<IControlState> };
//interface ReceiveEventsAction { type: 'RECEIVE_POINTS_EVENTS_CONTROL', events: SPointEventDTO[] };
interface ReceivePointsAction { type: 'RECEIVE_POINTS_CONTROL', allEventsCount: number, lastEventId: number, points: SPointWithEventsDTO[] };
interface ChangePointsAction { type: 'CHANGE_POINTS_CONTROL', points: SPointWithEventsDTO[] };
interface ReceiveConditionsAction { type: 'RECEIVE_CONDITIONS_CONTROL', conditions: SConditionDTO[] };

type KnownAction = SetState | CloseSessionAction | ReceivePointsAction | ReceiveConditionsAction | ChangePointsAction;


export const actionCreators = {
    // requestEventsAction: (filter: SPointsFilter, from: Date, to: Date, newEventsOnly: boolean ): AppThunkAction<KnownAction> => (dispatch, getState) => {
    //     const appState = getState();
    //     dispatch({type: 'SET_CONTROL_STATE', value: {isLoadingEvents: true}});

    //     if (appState) {
    //         const requestOptions = {
    //             method: 'POST',
    //             headers: getHeaders(),
    //             body: JSON.stringify({filter, from, to, onlyNewEvents: newEventsOnly})
    //         };

    //         fetch(BACKEND_URL + 'points/pointsevents', requestOptions)
    //             .then(response => response.json() as Promise<SResponseDTO>)
    //             .then(data => {
    //                 if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
    //                     dispatch({ type: 'CLOSE_SESSION',message: data.message })
    //                 }
    //                 else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
    //                     dispatch({type: 'RECEIVE_POINTS_EVENTS_CONTROL', events: data.body})
    //                 }
    //                 else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
    //                     message.error(data.message);
    //                 }
    //                 else {
    //                     message.error('Ответ не получен.');
    //                 }
    //                 dispatch({type: 'SET_CONTROL_STATE', value: {isLoadingEvents: false}});
    //             })
    //             .catch(error => {
    //                 message.error('Ошибка:' + error);
    //                 console.log(error);
    //                 dispatch({type: 'SET_CONTROL_STATE', value: {isLoadingEvents: false}});
    //             });
            
    //     }
    // },

    requestPointsWithEvents: (filter: SPointsFilter, from: Date, to: Date, newEventsOnly: boolean ): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({type: 'SET_CONTROL_STATE', value: {isLoadingPoints: true}});
        
        if (appState) {
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify({filter, from, to, onlyNewEvents: newEventsOnly})
            };
            
            fetch(BACKEND_URL + 'points/pointseventscounts', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        const response: any = data.body;
                        dispatch({type: 'RECEIVE_POINTS_CONTROL', allEventsCount: response.allCount, lastEventId: response.lastEventId,  points: response.pointEvents})
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                    }
                    else {
                        message.error('Ответ не получен.');
                    }
                    dispatch({type: 'SET_CONTROL_STATE', value: {isLoadingPoints: false}});
                })
                .catch(error => {
                    message.error('Ошибка:' + error);
                    console.log(error);
                    dispatch({type: 'SET_CONTROL_STATE', value: {isLoadingPoints: false}});
                });
            
        }
    },

    requestAcceptEvents: (eventIds: number[], onDone: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({type: 'SET_CONTROL_STATE', value: {isEventsAcceptRequest: true}});
        
        function calculateEventsCountForPoint (pointId: string, eventsArr: SPointEventDTO[]) {
            let res = 0;
            eventsArr.forEach(item => item.pointId === pointId ? res += 1 : null)
            return res;
        }

        if (appState) {
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify({eventIds})
            };
            
            fetch(BACKEND_URL + 'points/neweventsaccept3', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        if (data.body === true) {
                            message.success('События квитированы.');
                            onDone(eventIds);
                            //const evs = appState.control?.events?.filter(item => !eventIds.includes(item.eventId)) ?? [];
                            //let pns = [...appState.control?.points ?? []];
                            //pns.forEach(item => item.eventsCount = calculateEventsCountForPoint(item.id, evs));
                            //pns = pns.filter(x => x.eventsCount > 0);
                            // dispatch({
                            //     type: 'RECEIVE_POINTS_EVENTS_CONTROL',
                            //     events: evs
                            // });
                            
                            // dispatch({
                            //     type: 'RECEIVE_POINTS_CONTROL',
                            //     points: pns,
                            // });
                        } else {
                            message.error('Вызов points/neweventsaccept3 завершился неудачей.');
                        }
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                    }
                    else {
                        message.error('Ответ не получен.');
                    }
                    dispatch({type: 'SET_CONTROL_STATE', value: {isEventsAcceptRequest: false}});
                })
                .catch(error => {
                    message.error('Ошибка:' + error);
                    console.log(error);
                    dispatch({type: 'SET_CONTROL_STATE', value: {isEventsAcceptRequest: false}});
                });
        }
    },

    requestAcceptEventsByPoints: (pointIds: string[], from: Date, to: Date, onDone: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({type: 'SET_CONTROL_STATE', value: {isEventsAcceptRequest: true}});
        
        if (appState) {
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify({pointIds, from, to})
            };
            
            fetch(BACKEND_URL + 'points/neweventsaccept2', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        if (data.body === true) {
                            message.success('События квитированы.');
                            let eventsCount = 0;
                            (appState.control?.points?.filter(item => pointIds.includes(item.id)) ?? []).forEach(p=>eventsCount+=p.eventsCount);
                            // dispatch({
                            //     type: 'RECEIVE_POINTS_EVENTS_CONTROL',
                            //     events: (appState.control?.events?.filter(item => !pointIds.includes(item.pointId)) ?? [])
                            // });
                            dispatch({
                                type: 'CHANGE_POINTS_CONTROL',
                                points: (appState.control?.points?.filter(item => !pointIds.includes(item.id)) ?? [])
                            });
                            onDone(pointIds, eventsCount);
                        } else {
                            message.error('Вызов points/neweventsaccept2 завершился неудачей.');
                        }
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                    }
                    else {
                        message.error('Ответ не получен.');
                    }
                    dispatch({type: 'SET_CONTROL_STATE', value: {isEventsAcceptRequest: false}});
                })
                .catch(error => {
                    message.error('Ошибка:' + error);
                    console.log(error);
                    dispatch({type: 'SET_CONTROL_STATE', value: {isEventsAcceptRequest: false}});
                });
        }
    },

    requestConditionsAction: (filter: SPointsFilter): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({type: 'SET_CONTROL_STATE', value: {isLoadingConditions: true}});
        
        if (appState) {
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify({...filter})
            };
            
            fetch(BACKEND_URL + 'points/pointsconditions', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        dispatch({type: 'RECEIVE_CONDITIONS_CONTROL', conditions: data.body})
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                    }
                    else {
                        message.error('Ответ не получен.');
                    }
                    dispatch({type: 'SET_CONTROL_STATE', value: {isLoadingConditions: false}});
                })
                .catch(error => {
                    message.error('Ошибка:' + error);
                    console.log(error);
                    dispatch({type: 'SET_CONTROL_STATE', value: {isLoadingConditions: false}});
                });
            
        }
    },

    requestWriteConditionAction: (condition: SConditionDTO): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({type: 'SET_CONTROL_STATE', value: {isLoadingConditions: true}});
        
        if (appState) {
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify(condition)
            };
            
            fetch(BACKEND_URL + 'points/conditionwrite', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        message.success('Условия изменено.');
                        let cond: SConditionDTO = data.body;
                        let conds: SConditionDTO[] = appState.control?.conditions?.filter(item => !(item.id === cond.id && item.forGroup === cond.forGroup)) ?? [];
                        conds.push(cond);
                        dispatch({type: 'RECEIVE_CONDITIONS_CONTROL', conditions: conds});
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                    }
                    else {
                        message.error('Ответ не получен.');
                    }
                    dispatch({type: 'SET_CONTROL_STATE', value: {isLoadingConditions: false}});
                })
                .catch(error => {
                    message.error('Ошибка:' + error);
                    console.log(error);
                    dispatch({type: 'SET_CONTROL_STATE', value: {isLoadingConditions: false}});
                });
            
        }
    },

    requestRemoveConditionAction: (condition: SConditionDTO): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({type: 'SET_CONTROL_STATE', value: {isLoadingConditions: true}});
        
        if (appState) {
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify(condition)
            };
            
            fetch(BACKEND_URL + 'points/conditiondelete', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        message.success('Условие удалено.');
                        let conds: SConditionDTO[] = appState.control?.conditions?.filter(item => !(item.id === condition.id && item.forGroup === condition.forGroup)) ?? [];
                        dispatch({type: 'RECEIVE_CONDITIONS_CONTROL', conditions: conds});
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                    }
                    else {
                        message.error('Ответ не получен.');
                    }
                    dispatch({type: 'SET_CONTROL_STATE', value: {isLoadingConditions: false}});
                })
                .catch(error => {
                    message.error('Ошибка:' + error);
                    console.log(error);
                    dispatch({type: 'SET_CONTROL_STATE', value: {isLoadingConditions: false}});
                });
            
        }
    },

    requestRemoveConditionsAction: (conditions: SConditionDTO[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({type: 'SET_CONTROL_STATE', value: {isLoadingConditions: true}});
        
        if (appState) {
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify(conditions)
            };
            
            fetch(BACKEND_URL + 'points/conditionsdelete', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        message.success('Условия удалены.');
                        let conds: SConditionDTO[] = appState.control?.conditions?.filter(item => conditions.findIndex(x => x.id === item.id && x.forGroup === item.forGroup) === -1) ?? [];
                        dispatch({type: 'RECEIVE_CONDITIONS_CONTROL', conditions: conds});
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                    }
                    else {
                        message.error('Ответ не получен.');
                    }
                    dispatch({type: 'SET_CONTROL_STATE', value: {isLoadingConditions: false}});
                })
                .catch(error => {
                    message.error('Ошибка:' + error);
                    console.log(error);
                    dispatch({type: 'SET_CONTROL_STATE', value: {isLoadingConditions: false}});
                });
            
        }
    },

    requestAddConditionsAction: (request: SConditionsAddRequest): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({type: 'SET_CONTROL_STATE', value: {isLoadingConditions: true}});
        
        if (appState) {
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify(request)
            };
            
            fetch(BACKEND_URL + 'points/conditionsadd', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        message.success('Условия добавлены.');
                        dispatch({type: 'RECEIVE_CONDITIONS_CONTROL', conditions: [...(data.body as SConditionDTO[]), ...(appState.control?.conditions ?? [])]});
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                    }
                    else {
                        message.error('Ответ не получен.');
                    }
                    dispatch({type: 'SET_CONTROL_STATE', value: {isLoadingConditions: false}});
                })
                .catch(error => {
                    message.error('Ошибка:' + error);
                    console.log(error);
                    dispatch({type: 'SET_CONTROL_STATE', value: {isLoadingConditions: false}});
                });
            
        }
    },
    changePointsAction: (points: SPointWithEventsDTO[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        
        if (appState) {
            dispatch({type: 'SET_CONTROL_STATE', value: {isLoadingConditions: true}});
        }
    },
};

export const reducer: Reducer<IControlState> = (state: IControlState | undefined, incomingAction: Action): IControlState => {
    if (state === undefined) {
        return {points: undefined, isLoadingPoints: false, allEventsCount:0, lastEventId:0,  isEventsAcceptRequest: false,
            conditions: undefined, isLoadingConditions: false, };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "SET_CONTROL_STATE":
            return {
                ...state,
                ...action.value
            };
        // case 'RECEIVE_POINTS_EVENTS_CONTROL':
        //     return {...state, events: action.events };
        case 'RECEIVE_CONDITIONS_CONTROL':
            return {...state, conditions: action.conditions };
        case 'RECEIVE_POINTS_CONTROL':
            return {...state, allEventsCount: action.allEventsCount, lastEventId: action.lastEventId,  points: action.points };
        case 'CHANGE_POINTS_CONTROL':
            return {...state, points: action.points };
        default:
            return state;
    }
};
