import * as React from 'react';
import { Button, Input, Tooltip } from 'antd';
import { connect } from 'react-redux';

import Login from './Login2';
import Resources from './Resources';
import Groups from './Groups';

import { ApplicationState } from '../store';
import * as PageStore from '../store/Page';
import * as UserProfileStore from '../store/UserProfile';
import * as ReportsStore from '../store/Reports';
import { RouteComponentProps } from 'react-router';
import styles from '../resources/Page.module.less';
import { SPointsFilter, Point, ICommand } from "../decl"
import * as Const from '../utils/Const';
import * as Common from '../utils/Common';
import {MiddleContentMessageBlock} from "./HeaderForm";

const {Search} = Input;

import {
    SyncOutlined,
    TableOutlined,
    FileZipOutlined
} from '@ant-design/icons';

const actionCreators = { ...PageStore.actionCreators, ...UserProfileStore.actionCreators, ...ReportsStore.actionCreators };

type HeaderProps =
    { userProfile: UserProfileStore.UserProfileState, reports: ReportsStore.IReportsState, windowWidth: number, isMenuCollapsed: boolean, pointId: string } &
    typeof actionCreators &
    RouteComponentProps<{}>

interface HeaderState {
    filter: SPointsFilter;
}

class ReportsHeaderForm extends React.PureComponent<HeaderProps, HeaderState>{
    constructor(props:HeaderProps) {

        super(props);
        this.state = {
            filter: {
                groups: (props?.userProfile?.profile?.Common?.Groups) ?? null,
                points: (props?.userProfile?.profile?.Common?.Points) ?? '',
                resources: (props?.userProfile?.profile?.Common?.Resources) ?? null,
                systemNumbers: null,
                text: props.reports.textFilter
            }
        }
        this.onValueChange = this.onValueChange.bind(this);
        this.onSelectPoint = this.onSelectPoint.bind(this);
        this.onResourcesChange = this.onResourcesChange.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
        this.onExcel = this.onExcel.bind(this);
        this.onShowPacket = this.onShowPacket.bind(this);
        this.onShowReport = this.onShowReport.bind(this);
    }


    onRefresh = () => {
        if (this.props.userProfile) {
            const common: any = { 
                Groups: this.state.filter.groups,
                Points: this.state.filter.points,
                Resources: this.state.filter.resources
            };
            this.props.changeProfile(Const.PROFILE_COMMON, common);
        }
        this.props.setTextFilter(this.state.filter.text);
        this.props.setNeedRefresh(true);
    };

    onValueChange = (groups: any, points: any) => {
        this.setState({
            filter: {
                ...this.state.filter,
                groups: (groups.indexOf(Const.ALL_GROUP_ID) >= 0) ? null : Common.arrayToString(groups),
                points: Common.arrayToString(points)
            }
        });
    };
    onSelectPoint = (pointId: string) => {
        this.setState({
            filter: {
                ...this.state.filter,
                points: pointId
            }
        });
        if (this.props.userProfile) {
            this.props.changeProfile(Const.PROFILE_COMMON, {Points: pointId});
            const point: Point = this.props.userProfile.allPoints[pointId];
            this.props.setPoint(point.id, point.number);
        }

        this.props.setNeedRefresh(true);
    };

    onResourcesChange = (value: any) => {
        const selectAll = value.indexOf(Const.ALL_RESOURCE_ID) >= 0;
        this.setState({
            filter: {
                 ...this.state.filter,
                resources: selectAll ? null : Common.arrayToString(value)
             }
        });
    };

    onTextChange = (ev: any) => {
        const strValue = ev.target.value;
        this.setState({ filter: { ...this.state.filter, text: strValue } });
    }

    onExcel = () => {
        const cmd: ICommand = {
            Source: null,
            Name: 'showExcel',
            Params: 'excelform',
            Callback: null,
            ExceptionCallback: null
        };
        this.props.setCommandToExecute(cmd);
    };

    onShowPacket = () => {
        if (this.props.reports.packetMode) {
            window.call_execute({ Source: null, Name: 'CloseAllDialogs' })
        }
        else {
            const cmd: ICommand = {
                Source: null,
                Name: 'showPacket',
                Params: null,
                Callback: null,
                ExceptionCallback: null
            };
            this.props.setCommandToExecute(cmd);
            this.props.setPacketMode(true);
        }
    };
    onShowReport = () => {
        const cmd: ICommand = {
            Source: null,
            Name: 'showReport',
            Params: null,
            Callback: null,
            ExceptionCallback: null
        };
        this.props.setCommandToExecute(cmd);
    };

    render() {
        const showOnePoint: boolean = (this.props?.userProfile?.profile.Reports?.ShowOnePoint) ?? false;
        
        if(showOnePoint)
        {
            //Табулятор.
            const selectedPointIds =  this.props.pointId === '' ? [] : [this.props.pointId];
            //console.log('userProfile: ' + Object.keys(this.props.groupPoints));

            const GroupsProps2 = {
                groups: (this.props.userProfile) ? this.props.userProfile.userGroups : [],
                groupPoints: (this.props.userProfile) ? this.props.userProfile.groupPoints : {},
                selectedGroupIds: [],
                selectedPointIds: selectedPointIds,
                getPoints: this.props.requestGroupPoints,
                getPointsParentGroups: null,
                onSelectPoint: this.onSelectPoint,
                setIsLoading: this.props.setIsLoading,
                treeCheckable: false
            };
            //console.log('groupPoints: ' + Object.keys(GroupsProps2.groupPoints));
            //console.log('selectedPointIds: ', selectedPointIds);

            const buttonAsIcon = this.props.windowWidth < 800;
            if(buttonAsIcon){
                return (
                    <div className={styles.flexcontainer}>
                        <div className={styles.flexitem}>
                            <Groups {...GroupsProps2} />
                        </div>
                        <div className={styles.iconflexitem}>
                            <Tooltip placement="bottomRight" title={'Обновить данные'}>
                                <Button type="primary" shape="circle" icon={<SyncOutlined/>} onClick={this.onRefresh}/>
                            </Tooltip>
                        </div>
                        <div className={styles.iconflexitem}>
                            <Tooltip placement="bottomRight" title={'Фомирование ведомости'}>
                                <Button  type="primary" shape="circle" icon={<FileZipOutlined/>} onClick={this.onShowReport}/>
                            </Tooltip>
                        </div>
                        <div className={styles.mbitem}>
                            <MiddleContentMessageBlock windowSize={this.props.windowWidth} maxWindowSize={600} onlyIcon={true}/>
                        </div>
                        <div className={styles.flexuseritem}>
                            <Login/>
                        </div>
                    </div>
                );
            }
            else { //Табулятор и кнопки с надписями.
                const messageAsIcon = this.props.windowWidth < (this.props.isMenuCollapsed ? 1600 : 1715);// this.props.windowWidth < 1575;
                const hideEmail = false;//this.props.windowWidth < (this.props.isMenuCollapsed ? 1600 : 1715);
                const isSplit = this.props.windowWidth >= (this.props.isMenuCollapsed ? 1800 : 1915);
                return (
                    <div className={styles.flexcontainer}>
                        <div className={styles.flexbigitem}>
                            <Groups {...GroupsProps2} />
                        </div>
                        <div className = {styles.buttonsblock}>
                            <div className={styles.flexitem}>
                                <Tooltip placement="bottomRight" title={'Обновить данные'}>
                                    <Button type="primary" shape="round" className={styles.headerbutton} icon={<SyncOutlined/>} onClick={this.onRefresh}>Обновить</Button>
                                </Tooltip>
                            </div>
                            <div className={styles.flexitem}>
                                <Tooltip placement="bottomRight" title={'Фомирование ведомости'}>
                                    <Button  type="primary" shape="round" className={styles.headerbutton} icon={<FileZipOutlined/>} onClick={this.onShowReport}>Ведомость</Button>
                                </Tooltip>
                            </div>
                        </div>
                        <div className={styles.mbitem}>
                            <MiddleContentMessageBlock windowSize={this.props.windowWidth} maxWindowSize={600} onlyIcon={messageAsIcon} hideEmail={hideEmail} isSplit={isSplit}/>
                        </div>
                        <div className={styles.flexuseritem}>
                            <Login/>
                        </div>
                    </div>
                );
            }
        }
        else { //Таблица.
            const selectedGroupIds = (this.state.filter.groups != null) ?
                Common.stringToArray(this.state.filter.groups) :
                [Const.ALL_GROUP_ID];
            const selectedPointIds = (this.state.filter.points != null) ?
                Common.stringToArray(this.state.filter.points) :
                [];
            const selectedResourceIds = (this.state.filter.resources != null) ?
                Common.stringToArray(this.state.filter.resources) :
                [Const.ALL_RESOURCE_ID];

            const GroupsProps = {
                groups: (this.props.userProfile) ? this.props.userProfile.userGroups : [],
                groupPoints: (this.props.userProfile) ? this.props.userProfile.groupPoints : {},
                selectedGroupIds: selectedGroupIds,
                selectedPointIds: selectedPointIds,
                getPoints: this.props.requestGroupPoints,
                getPointsParentGroups: null,
                onChange: this.onValueChange,
                setIsLoading: this.props.setIsLoading,
                onHideDropDown: this.onRefresh,
                resources: selectedResourceIds
            };

            const ResourcesProps = {
                resources: (this.props.userProfile) ? this.props.userProfile.resources : [],
                selectedResourceIds: selectedResourceIds,
                onChange: this.onResourcesChange,
                onHideDropDown: this.onRefresh
            };

            const buttonAsIcon = this.props.windowWidth < 1500;
            if (buttonAsIcon) {//Таблица и кнопки с иконками
                return (
                    <div className={styles.flexcontainer}>
                        <div className={styles.comboblock}>
                            <div>
                                <Groups {...GroupsProps} />
                            </div>
                            <div className={styles.flexitem}>
                                <Resources {...ResourcesProps} />
                            </div>
                            <div className={styles.flexitem}>
                                <Tooltip placement="bottomRight" title={'Поиск точки учёта'}>
                                    <Search  className ={styles.searchText} value={this.state.filter.text} placeholder="Поиск" onChange={this.onTextChange} onSearch={this.onRefresh}/>
                                </Tooltip>
                            </div>
                        </div>
                        <div className ={styles.buttonsblock}>
                            <div className={styles.iconflexitem}>
                                <Tooltip placement="bottomRight" title={'Обновить данные'}>
                                    <Button type="primary" shape="circle" icon={<SyncOutlined/>} onClick={this.onRefresh}/>
                                </Tooltip>
                            </div>
                            <div className={styles.iconflexitem}>
                                <Tooltip placement="bottomRight" title={'Сформировать пакет ведомостей'}>
                                    <Button type="primary" shape="circle" icon={<FileZipOutlined/>} onClick={this.onShowPacket}/>
                                </Tooltip>
                            </div>
                            <div className={styles.iconflexitem}>
                                <Tooltip placement="bottomRight" title={'Выгрузить таблицу'}>
                                    <Button  type="primary" shape="circle" icon={<TableOutlined/>} onClick={this.onExcel}/>
                                </Tooltip>
                            </div>
                        </div>
                        <div className={styles.mbitem}>
                            <MiddleContentMessageBlock windowSize={this.props.windowWidth} maxWindowSize={600} onlyIcon={true}/>
                        </div>
                        <div className={styles.flexuseritem}>
                            <Login/>
                        </div>
                    </div>
                );
            }
            else { //Таблица и кнопки с надписями
                const maxWindowSize = (this.props.isMenuCollapsed ? 1520 : 1640);
                const messageAsIcon = this.props.windowWidth < (this.props.isMenuCollapsed ? 1970 : 2185);//this.props.windowWidth < 1921;
                const hideEmail = false;//this.props.windowWidth < (this.props.isMenuCollapsed ? 1970 : 2185);
                const isSplit = this.props.windowWidth >= (this.props.isMenuCollapsed ? 2200 : 2315);
                return (
                    <div className={styles.flexcontainer}>
                        <div className={styles.comboblock}>
                            <div>
                                <Groups {...GroupsProps} />
                            </div>
                            <div className={styles.flexitem}>
                                <Resources {...ResourcesProps} />
                            </div>
                            <div className={styles.flexitem}>
                                <Tooltip placement="bottomRight" title={'Поиск точки учёта'}>
                                    <Search className ={styles.searchText} value={this.state.filter.text} placeholder="Поиск" onChange={this.onTextChange} onSearch={this.onRefresh}/>
                                </Tooltip>
                            </div>
                        </div>
                        <div className ={styles.buttonsblock}>
                            <div className={styles.flexitem}>
                                <Tooltip placement="bottomRight" title={'Обновить данные'}>
                                    <Button type="primary" shape="round" icon={<SyncOutlined/>} onClick={this.onRefresh}>Обновить</Button>
                                </Tooltip>
                            </div>
                            <div className={styles.flexitem}>
                                <Tooltip placement="bottomRight" title={'Сформировать пакет ведомостей'}>
                                    <Button type="primary" shape="round" icon={<FileZipOutlined/>} onClick={this.onShowPacket}>Пакет ведомостей</Button>
                                </Tooltip>
                            </div>
                            <div className={styles.flexitem}>
                                <Tooltip placement="bottomRight" title={'Выгрузить таблицу'}>
                                    <Button  type="primary" shape="round" icon={<TableOutlined/>} onClick={this.onExcel}>Выгрузить таблицу</Button>
                                </Tooltip>
                            </div>
                        </div>
                        <div className={styles.mbitem}>
                            <MiddleContentMessageBlock windowSize={this.props.windowWidth} maxWindowSize={maxWindowSize} onlyIcon={messageAsIcon} hideEmail={hideEmail}  isSplit={isSplit}/>
                        </div>
                        <div className={styles.flexuseritem}>
                            <Login/>
                        </div>
                    </div>
                );
            }
        }
    }
}


export default connect(
    (state: ApplicationState) => {
        return {
            userProfile: state.userProfile,
            reports: state.reports,
            windowWidth: state.page.windowWidth,
            isMenuCollapsed: state.siderMenu?.collapsed,
            pointId: state.page.pointId        }
    },
    actionCreators
)(ReportsHeaderForm as any);
