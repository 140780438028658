import * as React from 'react';
import { connect } from 'react-redux';

import Login from './Login2';

import { ApplicationState } from '../store';
import * as PageStore from '../store/Page';
import * as UserProfileStore from '../store/UserProfile';
import { RouteComponentProps } from 'react-router';
import styles from '../resources/Page.module.less';
import { SPointsFilter, Point, ICommand } from "../decl"
import * as Const from '../utils/Const';
import * as Common from '../utils/Common';
import {MiddleContentMessageBlock} from "./HeaderForm";

import {
    SyncOutlined,
    TableOutlined,
    FileZipOutlined
} from '@ant-design/icons';


const actionCreators = { ...PageStore.actionCreators, ...UserProfileStore.actionCreators };

type HeaderProps =
    { userProfile: UserProfileStore.UserProfileState, windowWidth: number } &
    typeof actionCreators &
    RouteComponentProps<{}>

interface HeaderState {
}

class SensorsHeaderForm extends React.PureComponent<HeaderProps, HeaderState>{
    constructor(props:HeaderProps) {

        super(props);
    }

    render() {
        const messageAsIcon = this.props.windowWidth < 1200;
        return (
            <div className={styles.flexcontainer}>
                <div className={styles.mbitem}>
                    <MiddleContentMessageBlock windowSize={this.props.windowWidth} maxWindowSize={600} onlyIcon={messageAsIcon} isSplit={true}/>
                </div>
                <div className={styles.flexuseritem}>
                    <Login/>
                </div>
            </div>
        );
    }
}


export default connect(
    (state: ApplicationState) => {
        return {
            userProfile: state.userProfile,
            windowWidth: state.page.windowWidth
        }
    },
    actionCreators
)(SensorsHeaderForm as any);
