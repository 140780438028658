import { Action, Reducer } from 'redux';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface SiderMenuState {
    collapsed: boolean;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface ToggleCollapsedMenuAction { type: 'TOGGLE_COLLAPSED_MENU' }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = ToggleCollapsedMenuAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    toggleCollapsed: () => ({ type: 'TOGGLE_COLLAPSED_MENU' } as ToggleCollapsedMenuAction)
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<SiderMenuState> = (state: SiderMenuState | undefined, incomingAction: Action): SiderMenuState => {
    if (state === undefined) {
        return {
            collapsed: false
        };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'TOGGLE_COLLAPSED_MENU':
            return { ...state, collapsed: !state.collapsed };
        default:
            return state;
    }
};
