import * as React from 'react';
import { Input, Col, Row, Space, Tree } from 'antd';
import { DraggableModal, DraggableModalProvider } from 'ant-design-draggable-modal'

import 'ant-design-draggable-modal/dist/index.css'

import { SRoleInfoDTO } from "../../decl";
import { removeValue } from "../../utils/TreeUtils";
import * as Const from '../../utils/Const';


const ALL_ROLES_ID = "allroles";

export interface IRoleSelectFormProps{
    visible: boolean;
    zIndex: number,
    roles: SRoleInfoDTO[]
    selectedRoleIds: string[];
    setIsLoading: any;
    onChangeRoles: any;
    onClose: any
}

interface IRoleSelectFormState{
    filter: string,
    selectedIds: string[],
    fixCheckedIds: string[],
    treeData: any
}

export function createRolesTree(roles: SRoleInfoDTO[], selectedIds: string[], filter: string):any{
    const childNodes = createRoleNodes(roles, filter);
    const children: any[] = [];
    const fixedIds: string[] = [];
    for(let node of childNodes){
        if(node.isAllowed){
            children.push(node);
        }
        else if(selectedIds.includes(node.role.id)){
            fixedIds.push(node.role.id);
        }
    }
    const root = [{
        title: 'Все роли',
        shortTitle: 'Все роли',
        value: ALL_ROLES_ID,
        key: ALL_ROLES_ID,
        children: children
    }];
    return {treeData: root, fixedIds: fixedIds, filter: filter};
}

function createRoleNodes(roles: SRoleInfoDTO[], filter: string):any {
    return roles.map(role => {

        const childRights = createRightNodes(role, filter);
        const childsAllowed = childRights.find((r:any)=>r.isAllowed) != undefined;

        let isAllowed = childsAllowed || role.role.name.toLowerCase().includes(filter.toLowerCase());

        return {
            title: role.role.name,
            shortTitle: role.role.id,
            value: role.role.id,
            key: role.role.id,
            children: childRights,
            isLeaf: false,
            role: role.role,
            isAllowed: isAllowed
        }
    });
  
}

function createRightNodes(role: SRoleInfoDTO, filter: string):any {
    return role.rights.map(r=> {
        const id = role.role.id + '.' + r.userRightId;

        return {
            title: r.userRightName + " (" + r.userRightId + ")",
            shortTitle: r.userRightId,
            value: id,// r.userRightId,
            key: id,
            isLeaf: true,
            checkable: false,
            isAllowed: r.userRightName.toLowerCase().includes(filter.toLowerCase()) ||
                         r.userRightId.toLowerCase().includes(filter.toLowerCase())
        }
    });
}



//Дерево точек учёта.
export class RoleSelectForm extends React.PureComponent<IRoleSelectFormProps, IRoleSelectFormState> {
    constructor(props: IRoleSelectFormProps) {
        super(props);

        this.state = {
            filter: '',
            selectedIds: [],
            fixCheckedIds: [],
            treeData: []
        }

    }
    componentDidMount() {
        //console.log('SelectedForm: ', 'DidMount');
    }
    componentDidUpdate(prevProps: IRoleSelectFormProps) {
        //console.log('SelectedForm: ', 'DidUpdate');
        if(this.props.visible && !prevProps.visible){
            const selectedIds = [...this.props.selectedRoleIds];
            const tree = createRolesTree(this.props.roles, selectedIds, '');
            this.setState({selectedIds: selectedIds, treeData: tree.treeData, fixCheckedIds: tree.fixedIds, filter: tree.filter})

            // this.setState({selectedIds: [...this.props.selectedRoleIds]});
            // this.createTree('');
        }
    }

    componentWillUnmount() {
        //console.log('SelectedForm: ', 'DidUnmount');
    }


    onOk = () => {
            this.props.onChangeRoles(this.state.selectedIds);
            this.props.onClose();
        };
    onCancel = () => {
        this.props.onClose();
    };

    onFilterChange = (value: string) => {
        const tree = createRolesTree(this.props.roles, this.state.selectedIds, value);
        this.setState({treeData: tree.treeData, fixCheckedIds: tree.fixedIds, filter: tree.filter})

        // this.createTree(value);
    }

    onCheck = (checkedKeys: any, e: any)=>{
        const selectedIds = [...this.state.selectedIds];

        if(e.node.role){
            const id = e.node.role.id;
            if(e.checked && selectedIds.indexOf(id) < 0){
                selectedIds.push(id);
            }
            else{
                removeValue(selectedIds, id);
            }
        }
        else{
            //Корневой узел.
            if(checkedKeys.length === 0){
                for(let id of this.state.selectedIds){
                    if(!this.state.fixCheckedIds.includes(id)){
                        removeValue(selectedIds, id);
                    }
                }
            }
            else{
                for(let id of checkedKeys){
                    if(id !== ALL_ROLES_ID && selectedIds.indexOf(id) < 0){
                        selectedIds.push(id);
                    }
                }
            }
        }
        this.setState({selectedIds: selectedIds});
    }
    
    render() {
        //console.log(this.state.selectedIds);
        const tProps = {
            treeData: this.state.treeData,
            checkedKeys: this.state.selectedIds.filter(id=>!this.state.fixCheckedIds.includes(id)),
            defaultExpandedKeys: [ALL_ROLES_ID],
            onCheck: this.onCheck,
            checkable: true,
            showIcon: false,
            showLine: {showLeafIcon: false},
            zIndex: 1,
        };

        return <DraggableModal
            title= {"Выбор роли"}
            open={this.props.visible}
            onOk={this.onOk}
            onCancel={this.onCancel}
            initialWidth={500}
            initialHeight={600}
            wrapClassName ={Const.MODAL_WRAP_CLASSNAME}
            zIndex={this.props.zIndex}
            >
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Row>
                <Input 
                    placeholder= {"Фильтр для ролей и прав"} 
                    value={this.state.filter}  
                    onChange = {e=> this.onFilterChange(e.target.value)} 
                />
            </Row>
                <Tree {...tProps} />
            </Space>
         </DraggableModal> 
    }
}
