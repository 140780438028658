import {Modal, Form, Input, Radio} from 'antd';


export interface SetPasswordValues {
  password: string;
}

export interface SetPasswordFormProps {
  visible: boolean;
  zIndex: number,
  values?: SetPasswordValues,
  userName: string,  
  onSave: (values: SetPasswordValues) => void;
  onCancel: () => void;
}

export const SetPasswordForm: React.FC<SetPasswordFormProps> = ({
  visible,
  zIndex,
  values,
  userName,
  onSave,
  onCancel
}) => {

  const [form] = Form.useForm();
  form.resetFields();
  const title: string = "Установка нового пароля '" + userName + "'"
  return (
    <Modal
      open = {visible}
      zIndex={zIndex}
      title={title}
      okText="Установить пароль"
      cancelText="Отмена"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            form.resetFields();
            onSave(values);
          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="userform"
        initialValues={{ title: values ? values.password : ''}}
      >
        <Form.Item
          name="password"
          label="Новый пароль"
          rules={[{ required: true, message: 'Пароль не задан!' }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
