import * as React from 'react';

import styles from '../../resources/Page.module.less';
import { Modal, Select, Input, Row, Col } from 'antd';

import { deepCopyObj } from '../../utils/Common';
import { MODAL_WRAP_CLASSNAME } from '../../utils/Const';
import { SBilContactDTO } from './BillingDecls';

type Props = {
    contact: SBilContactDTO | null,
    onHide() : void,
    onOk(contact: SBilContactDTO) : void,
    zIndex: number,
}

type State = {
    contact: SBilContactDTO | null,
}

export default class ContactForm extends React.Component<Props, State> {
    constructor(props: Props, state: State) {
        super(props);

        this.state = {
            contact: null,
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.contact === null && this.props.contact !== null) {
            const c = deepCopyObj(this.props.contact);
            this.setState({contact: c});
        }
    }

    private onContactChange = (field: keyof SBilContactDTO, val: any) => {
        if (this.state.contact != undefined) {
            let res = {...this.state.contact, [field]: val};
            this.setState({contact: res});
        }
    }

    render() {
        return (
            <Modal
                title={ this.props !== undefined ? "Редактирование " + "" : 'Добавление записи' }
                wrapClassName={MODAL_WRAP_CLASSNAME}
                width={400}
                zIndex={this.props.zIndex}
                centered
                open={this.props.contact != null}
                // footer={[
                //     <div style={{ display: 'flex', alignItems: 'baseline' }}>
                //         <Button key="1" type='primary' onClick={this.props.onHide} style={{marginLeft: 'Auto'}}>Отмена</Button>
                //         <Button key="2" type='primary' onClick={this.onOk}>{this.props.operation === undefined ? "Добавить" : "Изменить"}</Button>
                //     </div>
                // ]}
                onCancel={() => { this.props.onHide() }}
                onOk={() => this.state?.contact != null ? this.props.onOk(this.state.contact) : null}
            >
                <div className={styles.FormWrapper}>
                    <Input.Group>
                        <Row key={'k2'} className={styles.FormRowWrapper}>
                            <Col key='c1' span={8}>
                                ФИО
                            </Col>
                            <Col span={16}>
                                <Input value={this.state.contact?.name} onChange={val => this.onContactChange('name', val.target.value)} size='small'/>
                            </Col>
                        </Row>
                        <Row className={styles.FormRowWrapper}>
                            <Col span={8}>
                                Должность
                            </Col>
                            <Col span={16}>
                                <Input value={this.state.contact?.rank} onChange={val => this.onContactChange('rank', val.target.value)} size='small'/>
                            </Col>
                        </Row>
                        <Row className={styles.FormRowWrapper}>
                            <Col span={8}>
                                Телефон
                            </Col>
                            <Col span={16}>
                                <Input value={this.state.contact?.phone} onChange={val => this.onContactChange('phone', val.target.value)} size='small'/>
                            </Col>
                        </Row>
                        <Row className={styles.FormRowWrapper}>
                            <Col span={8}>
                                e-mail
                            </Col>
                            <Col span={16}>
                                <Input value={this.state.contact?.email} onChange={val => this.onContactChange('email', val.target.value)} size='small'/>
                            </Col>
                        </Row>
                        <Row className={styles.FormRowWrapper}>
                            <Col span={8}>
                                Примечание
                            </Col>
                            <Col span={16}>
                                <Input.TextArea rows={5} value={this.state.contact?.description} onChange={val => this.onContactChange('description', val.target.value)} size='small'/>
                            </Col>
                        </Row>
                    </Input.Group>
                </div>
            </Modal>
        );
    }
}