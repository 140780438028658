import * as React from 'react';

import { Select, Row, Col, Space, InputNumber } from 'antd';

import { MODAL_WRAP_CLASSNAME } from '../../utils/Const';

import pageStyles from '../../resources/Page.module.less';
import { DraggableModal } from 'ant-design-draggable-modal';
import {message} from '../../utils/Common';

type Props = {
    onHide(): void,
    onOk(val: number | null, obj: any): void,
    caption?: string,
    number: number | null,
    visibleObj: any,
    title: string,
    width: number,
    height: number,
    allowNull: boolean,
    placeholder?: string,
    zIndex: number
}

type State = {
    number: number | null,
}

class EditNumberForm extends React.Component<Props, State> {
    constructor(props: Props, state: State) {
        super(props);

    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps.visibleObj == null && this.props.visibleObj != null) {
            this.setState({number: this.props.number});
        }
    }

    onOk = () => {
        if (this.state.number == null && this.props.allowNull === false) {
            message.info("Значение должно быть задано");
        } else {
            this.props.onOk(this.state.number, this.props.visibleObj);
        }
    }
    onChange = (num: number | null) => {
        //console.log('onChange num', num);
        this.setState({number: num});
    }

    //=============================================================================
    render() {
        return (
            <DraggableModal
                maskClosable={true}
                bodyStyle={{padding: '5px'}}
                title={this.props.title}
                wrapClassName={MODAL_WRAP_CLASSNAME}
                zIndex={this.props.zIndex}
                open={this.props.visibleObj != null}
                onCancel={this.props.onHide}
                initialWidth={this.props.width}
                initialHeight={this.props.height}
                onOk={this.onOk}
            >
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    <Row className={pageStyles.FormRowWrapper2_1}>
                        {
                            this.props.caption == undefined ?
                                <InputNumber
                                    className={pageStyles.Width100}
                                    size='small'
                                    placeholder={this.props.placeholder ?? ''} 
                                    value={this.state?.number}  
                                    onChange={this.onChange}
                                />
                            :
                            <>
                                <Col span={8}>
                                    { this.props.caption ?? '' }
                                </Col>
                                <Col span={16}>
                                <InputNumber
                                    className={pageStyles.Width100}
                                    size='small'
                                    placeholder={this.props.placeholder ?? ''} 
                                    value={this.state?.number}  
                                    onChange={(e) => this.onChange(e)}
                                />
                                </Col>
                            </>
                        }
                    </Row>
                </Space>
            </DraggableModal>
        );
    }
}

export default EditNumberForm;