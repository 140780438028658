import * as React from 'react';

import styles from '../../resources/Page.module.less';
import { Button, Modal, Select, Input, Row, Col, Table, Tag, DatePicker, Tabs, Tooltip, Space } from 'antd';

import { arrayToString, deepCopyObj, getModalZIndex, message } from '../../utils/Common';
import { ALL_GROUP_ID, MODAL_WRAP_CLASSNAME } from '../../utils/Const';
import { SBilContactDTO, SBillingCompanyInfoDTO, SBillingTariffDTO } from './BillingDecls';
import moment from 'moment';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import * as Const from '../../utils/Const';
import Search from 'antd/lib/input/Search';
import CompanyEditForm from './CompanyEditForm';
import { sendRequestToBackend } from '../../utils/AuthUtils';
import TariffEditForm from './TariffEditForm';

const { Option } = Select;

type Props = {
    onHide(): void,
    visible: boolean,
    tariffs: SBillingTariffDTO[],
    changeTariffs(tariffs: SBillingTariffDTO[]): void,
    setIsLoading: any,
}

type State = {
    tariffEdit: SBillingTariffDTO | null,
    zIndex: number,
}

export default class TariffsForm extends React.Component<Props, State> {
    constructor(props: Props, state: State) {
        super(props);

        this.state = {
            tariffEdit: null,
            zIndex: 0,
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.visible == false && this.props.visible == true) {
            this.makeDatasource(this.props.tariffs);
        }

        if (prevProps.tariffs != this.props.tariffs) {
            this.makeDatasource(this.props.tariffs);
        }
    }

    onOk = () => {

    };

    tariffEdit = (val: any) => {
        let tmp = this.props.tariffs.find(x => x.id === val);
        if (tmp != undefined) {
            this.setState({tariffEdit: tmp, zIndex: getModalZIndex()});
        }
    }

    tariffDel = (val: any) => {
        sendRequestToBackend(
            val,
            'billing/tariffdelete',
            (response: any) => {
                if (response != null) {
                    let arr = [...this.props.tariffs];
                    const i = arr.findIndex(x => x.id === val);
                    arr.splice(i, 1);
                    this.props.changeTariffs(arr);
                    this.makeDatasource(arr);
                } else {
                    message.error('Запрос завершился неудачей');
                }
            },
            this.props.setIsLoading,
            (error: any) => {
                console.log(error);
                message.error(error);
            }
        );
    }

    private dataSource: any = undefined;

    makeDatasource = (arr: SBillingTariffDTO[] | undefined) => {
        if (arr == undefined) {
            this.dataSource = undefined;
        } else {
            this.dataSource = arr.map((x, index) => {
                return ({
                    key: x.id,
                    num: index + 1,
                    edit: x.id,
                    delete: x.id,
                    tariff: x.name,
                    price: x.defaultPrice,
                })
            });
        }
    }

    private columns = [
        {
            dataIndex: "num",
            title: "№",
            render: (text: any, record: any, index: any) => text,
            width: 60,
        },
        {
            dataIndex: "edit",
            title: "",
            width: 60,
            align: 'center' as 'center',
            render: (val: any, record: any, index: any) => {
                return (
                    <Tooltip placement="top" title={'Редактировать'}>
                        <Button shape="circle" onClick={() => this.tariffEdit(val)} icon={<EditTwoTone twoToneColor={Const.COLORS.SvgIconColor}/>} />
                    </Tooltip>
                )
            },
        },
        {
            dataIndex: "delete",
            title: "",
            width: 60,
            align: 'center' as 'center',
            render: (val: any, record: any, index: any) => {
                return (
                    <Tooltip placement="top" title={'Удалить'}>
                        <Button shape="circle" onClick={() => this.tariffDel(val)} icon={<DeleteTwoTone twoToneColor={Const.COLORS.SvgIconColor}/>} />
                    </Tooltip>
                )
            },
        },
        {
            dataIndex: "tariff",
            title: "Тариф",
            render: (val: any, record: any, index: any) => val,
        },
        {
            dataIndex: "price",
            title: "Цена",
            width: 170,
        },
    ];

    onTariffEditOk = (tariff: SBillingTariffDTO) => {
        sendRequestToBackend(
            tariff,
            'billing/tariffwrite',
            (response: any) => {
                if (response != null) {
                    if (this.state.tariffEdit != null)
                    {
                        let arr = [...this.props.tariffs];
                        const i = arr.findIndex(x => x.id === tariff.id);
                        if (i < 0) {
                            arr.push(response);
                            this.props.changeTariffs(arr);
                        } else {
                            arr[i] = response;
                            this.props.changeTariffs(arr);
                        }
                        this.makeDatasource(arr);
                        this.setState({tariffEdit: null});
                    }
                } else {
                    message.error('Запрос завершился неудачей');
                }
            },
            this.props.setIsLoading,
            (error: any) => {
                console.log(error);
                message.error(error);
            }
        );
    }



    onNewTariff = () => {
        let tmp: SBillingTariffDTO = {
            defaultPrice: 0,
            id: -1,
            name: '',
        }

        this.setState({tariffEdit: tmp, zIndex: getModalZIndex()});
    }

    render() {
        return (
            <Modal
                title={ 'Тарифы' }
                centered={true}
                wrapClassName={MODAL_WRAP_CLASSNAME}
                width={600}
                //zIndex={this.props.zIndex}
                open={this.props.visible}
                cancelText='Закрыть'
                okText='Выбрать'
                okButtonProps={{ style: { display: 'none' } }}
                // footer={[
                //     <div style={{ display: 'flex', alignItems: 'baseline' }}>
                //         <Button key="1" type='primary' onClick={this.props.onHide} style={{marginLeft: 'Auto'}}>Отмена</Button>
                //         <Button key="2" type='primary' onClick={this.onOk}>{this.props.operation === undefined ? "Добавить" : "Изменить"}</Button>
                //     </div>
                // ]}
                onCancel={() => { this.props.onHide() }}
            >
                <div className={styles.FormWrapper}>
                    <div style={{ overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ padding: '4px'}}>
                            <Space style={{marginBottom: '6px'}}><Button type='primary' onClick={this.onNewTariff}>Добавить</Button> </Space>
                            <Table 
                                columns={this.columns} dataSource={this.dataSource} showSorterTooltip={false}
                                scroll={{ y: 'calc(100vh - 300px)' }} bordered size='small'
                                pagination={false}
                            />
                        </div>
                    </div>
                </div>
                {
                    <TariffEditForm 
                        onOk={this.onTariffEditOk}
                        onHide={() => this.setState({tariffEdit: null})}
                        tariff={this.state.tariffEdit}
                        zIndex={this.state.zIndex}
                    />
                }
            </Modal>
        );
    }
}