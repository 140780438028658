import * as React from 'react';
import { connect } from 'react-redux';

import * as UserProfile from '../store/UserProfile';
import * as ControlStore from '../store/ControlStore';

import styles from '../resources/Page.module.less';
import { Divider, Switch, Tabs } from 'antd';
import { Table, Tag, Space } from 'antd';
import { Checkbox, Button, Cascader, Radio, Tooltip, Modal, Popconfirm, Select, Input, Row, Col, InputNumber, Form } from 'antd';

import { ApplicationState } from '../store';
import { SConditionDTO, SConditionsAddRequest } from '../store/ControlStore';
import { EventTypesEnum, PeriodsEnum, Resource, SPointsFilter } from '../decl';
import { arrayToString, enumToArray, EventTypeToString, filterDictionary, PeriodEnumToString, stringToArray, message } from '../utils/Common';
import Resources from './Resources';
import Groups from './Groups';
import { ALL_GROUP_ID, ALL_RESOURCE_ID, MODAL_WRAP_CLASSNAME } from '../utils/Const';
import { Link } from 'react-router-dom';
import SimpleTreeSelect from './Helper/SimpleTreeSelect';

const { Option } = Select;

type Props = {
    visible: boolean,
    zIndex: number,
    condition: SConditionDTO | undefined,
    writeNewConditions(request: SConditionsAddRequest): any,
    writeCondition(condition: SConditionDTO): any,
    onHide() : void,
    allResources: Resource[],
    requestGroupPoints: any,
    groupPoints: any,
    setIsLoading: any,
    userGroups: any
    groups: any,
}

type State = {
    condition: SConditionDTO,
    selectedResourceIds: string,
    selectedAbonents: string[],
    selectedGroups: string[],
    simpleMode: boolean,
    simpleModeType: string,
    simpleModeValue: string,
    selectedPeriods: string[] | null,
}

class ConditionForm extends React.Component<Props, State> {
    constructor(props: Props, state: State) {
        super(props);

        this.state = {
            condition: {...this.newCondition},
            selectedResourceIds: '',
            selectedAbonents: [],
            selectedGroups: [],
            simpleMode: this.props.condition == undefined,
            simpleModeType: 'c1',
            simpleModeValue: '',
            selectedPeriods: [String(PeriodsEnum.current)],
        }
    }    

    newCondition = {
        conditionText: '',
        description: '',
        eventType: EventTypesEnum.Alarm,
        forGroup: false,
        id: '',
        messageText: '',
        period: PeriodsEnum.current,
        resources: [],
        targetId: '',
        targetName: ''
    }

    simpleModeTypes: {[k:string]: {descr: string, cond: string, unit: string, defVal: string, msg: string}} = {
        'c1': {descr: 'Утечка>', cond: 'm1-m2>', unit: 'кг', defVal: '1000', msg: 'Контроль утечки'},
        'c2': {descr: 'Подмес>', cond: 'm2-m1>', unit: 'кг', defVal: '1000', msg: 'Контроль подмеса'},
        'c3': {descr: 't1-t2<', cond: 't1-t2<', unit: '*C', defVal: '20', msg: 'Температурный контроль'},
        'c4': {descr: 't2<t1/2+', cond: 't2<t1/2+', unit: '*C', defVal: '3', msg: 'Температурный контроль'},
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.visible === false && this.props.visible === true) {
            this.setState({simpleMode: this.props.condition == undefined});
            if (this.props.condition == undefined) {
                this.setState({condition: {...this.newCondition}, selectedGroups: [], selectedAbonents: []})
            } else {
                if (this.props.condition.forGroup) {
                    this.setState({condition: {...this.props.condition}, selectedGroups: [this.props.condition.targetId], selectedResourceIds: arrayToString(this.props.condition.resources.map(item => item.id))})
                } else {
                    this.setState({condition: {...this.props.condition}, selectedAbonents: [this.props.condition.targetId], selectedResourceIds: arrayToString(this.props.condition.resources.map(item => item.id))})
                }
                
            }
        }
    }

    CondFieldChange = (field: keyof SConditionDTO, value: any) => {
        this.setState({condition: {...this.state.condition, [field]: value}})
    }

    onOk = () => {
        let cond = {...this.state.condition};
        if (this.state.selectedResourceIds === ALL_RESOURCE_ID) {
            cond.resources = this.props.allResources;
        } else {
            cond.resources = this.props.allResources.filter(item => stringToArray(this.state.selectedResourceIds).includes(String(item.id)));
        }

        if (this.props.condition == undefined) {
            if (this.state.simpleMode === true) {
                cond.conditionText = this.simpleModeTypes[this.state.simpleModeType].cond + this.state.simpleModeValue;
                cond.eventType = EventTypesEnum.Alarm;
                cond.messageText = this.simpleModeTypes[this.state.simpleModeType].msg;
                cond.description = this.simpleModeTypes[this.state.simpleModeType].msg;
            }

            if (cond.conditionText === '' || cond.description === '' || cond.messageText === '' || (this.state.selectedPeriods?.length ?? 4) === 0
                || (this.state.selectedGroups.length === 0 && this.state.selectedAbonents.length === 0) || (cond.forGroup === true && cond.resources.length === 0)) 
            {
                message.warning('Необходимо заполнить все поля.');
            } else {
                let filter: SPointsFilter = {
                    groups: arrayToString(this.state.selectedGroups.indexOf(ALL_GROUP_ID) >= 0 ? null : this.state.selectedGroups),
                    points: arrayToString(this.state.selectedAbonents),
                    resources: null,
                    systemNumbers: null,
                    text: '',
                };
                if (this.state.selectedPeriods != undefined) {
                    const req: SConditionsAddRequest = {
                        filter,
                        conditionTemplate: cond,
                        periods: this.state.selectedPeriods.map(x => Number(x)),
                    }
                    this.props.writeNewConditions(req);
                    this.props.onHide();
                }
            }
        } else {
            if (cond.conditionText === '' || cond.description === '' || cond.messageText === '' 
                || (this.state.selectedGroups.length === 0 && this.state.selectedAbonents.length === 0) || (cond.forGroup === true && cond.resources.length === 0)) {
                message.warning('Должны быть заполнены все поля.');
            } else {
                this.props.writeCondition(cond);
                this.props.onHide();
            }
        }
    };

    render() {
        return (
            <>
                <Modal
                    title={
                        this.props.condition !== undefined ? "Редактирование " + "" 
                        : <><Switch style={{marginRight: '5px'}} checkedChildren=  "Режим эксперта" unCheckedChildren="Простой режим" checked={!this.state.simpleMode} onChange={value => this.setState({simpleMode: !value})} />Добавление условий</>
                    }
                    wrapClassName={MODAL_WRAP_CLASSNAME}
                    zIndex={this.props.zIndex}
                    open={this.props.visible}
                    footer={[
                        <div key='a1' style={{display: 'flex', alignItems: 'baseline'}}>
                            <a key='a' href="Documents/Правила добавления условия контроля.pdf" style={{marginRight: 'Auto'}} target="_blank">Справка</a>
                            <Button key="1" type='primary' onClick={this.props.onHide}>Отмена</Button>
                            <Button key="2" type='primary' onClick={this.onOk}>{this.props.condition === undefined ? "Добавить" : "Изменить"}</Button>
                        </div>
                    ]}
                    onCancel={() => {this.props.onHide()}}
                >
                    <div className={styles.FormWrapper}>
                        <Input.Group>
                            {
                                this.state.simpleMode === false ? null :
                                <Row key={'k1'} className={styles.FormRowWrapper}>
                                    <Col key='c1' span={8}>
                                        Новое условие
                                    </Col> 
                                    <Col key='c2' span={16}>
                                        <Space>
                                            <Select key='k1' className={styles.Width100} value={this.state.simpleModeType} onChange={(value) => this.setState({simpleModeType: value})} size="small" >
                                                {Object.keys(this.simpleModeTypes).map((item: string) => <Option key={item} value={item}>{this.simpleModeTypes[item].descr}</Option>) }
                                            </Select>
                                            <Input key='k2' value={this.state.simpleModeValue} onChange={val => this.setState({simpleModeValue: val.target.value})} size='small'></Input>
                                            {this.simpleModeTypes[this.state.simpleModeType].unit}
                                        </Space>
                                    </Col>
                                </Row>
                            }
                            <Row key={'k2'} className={styles.FormRowWrapper}>
                                <Col key='c1' span={8}>
                                    Тип условия
                                </Col>
                                <Col span={16} flex={1}>
                                    <Select className={styles.Width100} disabled={this.props.condition != undefined} value={this.state.condition.forGroup ? 1 : 0} onChange={(value) => {this.CondFieldChange('forGroup', value === 1 ? true : false); this.setState({selectedAbonents: [], selectedGroups: []})}} size="small">
                                        <Option key={0} value={0}>Индивидуальное условие</Option>
                                        <Option key={1} value={1}>Групповое условие</Option>
                                    </Select>
                                </Col>
                            </Row>
                            <Row key={'k3'} className={styles.FormRowWrapper}>
                                <Col key='c1' span={8}>
                                    {this.props.condition === undefined ?
                                        this.state.condition.forGroup === true ? 'Группы' : 'Точки учета'
                                        : this.state.condition.forGroup === true ? 'Группа' : 'Точка учета'
                                    }
                                </Col>
                                <Col key='c2' span={16}>
                                    {this.props.condition != undefined ? <Tag key='k1'>{this.props.condition.targetName}</Tag> :
                                        <Groups
                                            className={styles.Width100}
                                            size='small'
                                            groups={this.props.groups}
                                            getPoints={this.props.requestGroupPoints}
                                            getPointsParentGroups={this.props.userGroups}
                                            groupPoints={this.props.groupPoints}
                                            selectedGroupIds={this.state.selectedGroups}
                                            selectedPointIds={this.state.selectedAbonents}
                                            setIsLoading={this.props.setIsLoading}
                                            onChange={(groups: string[], points: string[]) => this.setState({selectedGroups: groups, selectedAbonents: points})}
                                            onlyGroupsSelect={this.state.condition.forGroup}
                                        />
                                    }
                                </Col>
                            </Row>
                            {
                                this.state.simpleMode === true ? null :
                                <>
                                <Row key={'k4'} className={styles.FormRowWrapper}>
                                    <Col key='c1' span={8}>
                                        Условие
                                    </Col>
                                    <Col key='c2' span={16}>
                                        <Input value={this.state.condition.conditionText} onChange={(value) => this.CondFieldChange('conditionText', value.target.value)} placeholder={'Введите формулу условия'} size="small" />
                                    </Col>
                                </Row>
                                <Row key={'k5'} className={styles.FormRowWrapper}>
                                    <Col key='c1' span={8}>
                                        Описание
                                    </Col>
                                    <Col key='c2' span={16}>
                                        <Input value={this.state.condition.description} onChange={(value) => this.CondFieldChange('description', value.target.value)} placeholder={'Введите описание условия'} size="small" />
                                    </Col>
                                </Row>
                                <Row key={'k6'} className={styles.FormRowWrapper}>
                                    <Col key='c1' span={8}>
                                        Сообщение
                                    </Col>
                                    <Col key='c2' span={16}>
                                        <Input value={this.state.condition.messageText} onChange={(value) => this.CondFieldChange('messageText', value.target.value)} placeholder={'Введите сообщение'} size="small" />
                                    </Col>
                                </Row>
                                <Row key={'k7'} className={styles.FormRowWrapper}>
                                    <Col key='c1' span={8}>
                                        Тип события
                                    </Col> 
                                    <Col key='c2' span={16}>
                                        <Select className={styles.Width100} value={this.state.condition.eventType} onChange={(value) => this.CondFieldChange('eventType', value)} size="small">
                                            {
                                                enumToArray(EventTypesEnum).map(
                                                    k => <Option key={k} value={k}>{EventTypeToString(k)}</Option>
                                                )
                                            }
                                        </Select>
                                    </Col>
                                    
                                </Row>
                                </>
                            }
                            {                       
                                <Row key={'k8'} className={styles.FormRowWrapper}>
                                    <Col key='c1' span={8}>
                                        Период
                                    </Col> 
                                    <Col key='c2' span={16}>
                                    {   this.props.condition == undefined
                                        ? 
                                            <SimpleTreeSelect
                                                items={[PeriodsEnum.current, PeriodsEnum.hour, PeriodsEnum.day, PeriodsEnum.month].map(x => ({id: String(x), name: PeriodEnumToString(x) ?? '', shortName:PeriodEnumToString(x)}))}
                                                constAllSelected='all'
                                                rootItemShortTitle='Все периоды'
                                                rootItemTitle='Все периоды'
                                                selectedIds={this.state.selectedPeriods == null ? ['all'] : this.state.selectedPeriods}
                                                onChange={(value) => {
                                                    const ids = value.indexOf('all') >= 0 ? null : value;
                                                    this.setState({ selectedPeriods: ids });
                                                }}
                                                size='small'
                                                style={{width: '100%'}}
                                            />
                                        :
                                        <Select className={styles.Width100} value={this.state.condition.period} onChange={(value) => this.CondFieldChange('period', value)} size="small">
                                            <Option key={PeriodsEnum.current} value={PeriodsEnum.current}>{PeriodEnumToString(PeriodsEnum.current)}</Option>
                                            <Option key={PeriodsEnum.hour} value={PeriodsEnum.hour}>{PeriodEnumToString(PeriodsEnum.hour)}</Option>
                                            <Option key={PeriodsEnum.day} value={PeriodsEnum.day}>{PeriodEnumToString(PeriodsEnum.day)}</Option>
                                            <Option key={PeriodsEnum.month} value={PeriodsEnum.month}>{PeriodEnumToString(PeriodsEnum.month)}</Option>
                                        </Select>
                                    }
                                    </Col>
                                    
                                </Row>
                            }
                            {this.state.condition.forGroup === false ? null :
                                <Row key={'k9'} className={styles.FormRowWrapper}>
                                    <Col key='c1' span={8}>
                                        Тип ресурса
                                    </Col> 
                                    <Col key='c2' span={16}>
                                        <Resources
                                            className={styles.Width100}
                                            size='small'
                                            resources={this.props.allResources}
                                            selectedResourceIds={stringToArray(this.state.selectedResourceIds)}
                                            onChange={(value: any) => this.setState({selectedResourceIds: arrayToString(value)})}
                                        />
                                    </Col>
                                    
                                </Row>
                            }
                        </Input.Group>
                    </div>
                </Modal>
            </>
        );
    }
}

export default ConditionForm;