import * as React from 'react';
import { connect } from 'react-redux';

import * as UserProfile from '../../store/UserProfile';
import * as MapDataStore from '../../store/MapDataStore';
import { ClusteringFunctionTypeEnum, MeasurementTypesEnum } from '../../store/MapDataStore';
import { ApplicationState } from '../../store';

import { InputNumber, Select, Space, Button, Checkbox, Tree, DatePicker, Table, Collapse, Tabs, Menu, Dropdown } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { DownOutlined } from '@ant-design/icons';

import styles from '../../resources/Map.module.less';

import FixedDropdown from '../FixedDropdown';
import * as Const from '../../utils/Const';

import moment from 'moment';
import LayerView from './LayerView';

const { TabPane } = Tabs;
const { Option } = Select;

type PropsType = {userProfile: UserProfile.UserProfileState, mapDataStore: MapDataStore.IMapDataState } &
typeof UserProfile.actionCreators &
typeof MapDataStore.actionCreators;

interface StateType {
    checkedResourcesIds: number[],
    checkedChannels: number[],
    checkedQuantities: number[] | undefined,
    checkedDataTypes: number[],
}

class PopupSettings extends React.Component<PropsType, StateType> {
    static readonly CHANNELS = [1, 2, 3];
    
    static readonly INTEGRATOR_FILTER = -1;
    static readonly OTHER_FILTER = -2;
    static readonly DATA_TYPES: {key: number, description: string}[] = [
        {key: MeasurementTypesEnum.Current, description: 'Текущие'}, 
        {key: MeasurementTypesEnum.Archive, description: 'Архивные'}, 
        {key: PopupSettings.INTEGRATOR_FILTER, description: 'Интеграторы'}, 
        {key: PopupSettings.OTHER_FILTER, description: 'Прочее'}
    ];

    constructor(props: PropsType) {
        super(props);

        this.state = {
            checkedResourcesIds: this.props.userProfile.resources.map(item => item.id),
            checkedChannels: [...(PopupSettings.CHANNELS)],
            checkedQuantities: undefined,
            checkedDataTypes: PopupSettings.DATA_TYPES.map(item => item.key)
        }
    }

    componentDidMount() {
        this.componentDidUpdate();
    }

    componentDidUpdate() {
        if (this.props.mapDataStore.quantity !== undefined && this.state.checkedQuantities === undefined) {
            this.setState({checkedQuantities: Object.values(this.props.mapDataStore.quantity).map(item => item.id)});
        }
    }

    changeMapProfile = (name: string, val: any) => {
        this.props.changeProfileValue(Const.PROFILE_MAP, name, val);
    }

    //Фильтр отображаемых слоев для выбора
    itemFiltered = (layer: MapDataStore.SMapLayer): boolean => {
        let res: boolean = false;
        
        if (this.state.checkedResourcesIds.filter(item => this.props.mapDataStore.quantity?.[layer.phisicalQuantityID]?.resourceIds.indexOf(String(item)) != -1)?.length === 0)
            res = true;

        if (layer.channelNumber > 0 && !this.state.checkedChannels.includes(layer.channelNumber)) 
            res = true;

        if (this.state.checkedQuantities !== undefined) {
            if (!this.state.checkedQuantities.includes(layer.phisicalQuantityID)) {
                res = true;
            }
        }

        // Обработка флажков компонента Дата
        if (!this.state.checkedDataTypes.includes(MeasurementTypesEnum.Current)) {
            if (layer.measurementType === MeasurementTypesEnum.Current) {
                res = true;
            }
        }
        if (!this.state.checkedDataTypes.includes(MeasurementTypesEnum.Archive)) {
            if (layer.measurementType === MeasurementTypesEnum.Archive) {
                res = true;
            }
        }
        if (!this.state.checkedDataTypes.includes(MeasurementTypesEnum.Archive) && !this.state.checkedDataTypes.includes(MeasurementTypesEnum.Current)) {
            if (layer.measurementType === MeasurementTypesEnum.CurrentAndArchive) {
                res = true;
            }
        }
        if (!this.state.checkedDataTypes.includes(PopupSettings.INTEGRATOR_FILTER)) {
            if (layer.isIntegral === true) {
                res = true;
            }
        }
        if (layer.layerCode === LayerView.HASNS_LAYER_CODE || layer.layerCode === LayerView.STATE_LAYER_CODE) {
            if (this.state.checkedDataTypes.includes(PopupSettings.OTHER_FILTER)) {
                res = false;
            } else {
                res = true;
            } 
        }

        return res;
    }

    //Подготовка дерева слоев для отображения в компоненте Tree
    getLayersTree = (): {title: string, key: string, children: {title: string, key: string}[]}[] => {
        if (this.props.mapDataStore.layers === undefined || this.props.mapDataStore.quantity === undefined)
            return [];

        //console.log('quantity', this.props.mapDataStore.quantity);
        //console.log('layers', this.props.mapDataStore.layers);

        let dic: {[name: string]: {title: string, key: string, children: any[]}} = {};
        let title;

        let notViewedItems: string[] = [];

        for (const l of Object.values(this.props.mapDataStore.layers)) {
            if (l.isDataLayer === true)
            {
                if (this.itemFiltered(l) === true)
                    continue;

                if (dic[this.props.mapDataStore.quantity[l.phisicalQuantityID].name] === undefined) {
                    dic[this.props.mapDataStore.quantity[l.phisicalQuantityID].name] = {
                            title: this.props.mapDataStore.quantity[l.phisicalQuantityID].name, 
                            key: this.props.mapDataStore.quantity[l.phisicalQuantityID].name, 
                            children: []
                        }
                }
                title = l.layerDescription == '' ? l.layerName : l.layerName + ' (' + l.layerDescription + ')';
                dic[this.props.mapDataStore.quantity[l.phisicalQuantityID].name].children.push({title: title, key: l.layerCode});
            } else {
                if (this.itemFiltered(l) === true)
                    continue;

                if (dic['other'] === undefined) {
                    dic['other'] = {
                        title: 'Прочее', 
                        key: 'other', 
                        children: []
                    }
                }
                title = l.layerDescription == '' ? l.layerName : l.layerName + ' (' + l.layerDescription + ')';
                dic['other'].children.push({title: title, key: l.layerCode});
            }
        }

        const result = Object.keys(dic).map(key => ({...dic[key]}));
        return result;
    }

    onLayerCheck = (checked: React.Key[] | { checked: React.Key[]; halfChecked: React.Key[]; }, info: any): void => {
        if (this.props.userProfile.profile.Map !== undefined && this.props.mapDataStore?.layers !== undefined && (checked as React.Key[]).filter) {
            //Если галочки слоя нет в дереве то учитываем это и не убераем этот слой из списка

            let checked2 = (checked as React.Key[]).filter(item => this.props.mapDataStore?.layers?.[item] !== undefined).map(item => String(item))

            const lt = this.getLayersTree();
            let uncheckedLayers: string[] = [];
            for (const i of lt) {
                uncheckedLayers = uncheckedLayers.concat(i.children.map(item => item.key));
            }
            uncheckedLayers = uncheckedLayers.filter(item => !checked2.includes(item))

            let res = this.props.userProfile.profile.Map.SelectedLayers.split(',');
            res = res.concat(checked2.filter(item => !res.includes(item)));
            res = res.filter(item => !uncheckedLayers.includes(item))
            this.props.changeProfileValue('Map', 'SelectedLayers', res.join(','));
        }
    };

    allResourcesCheckboxesChange = (e: CheckboxChangeEvent) => {
        if (e.target.checked) {
            this.setState({checkedResourcesIds: this.props.userProfile.resources.map(item => item.id)});
        } else {
            this.setState({checkedResourcesIds: []});
        }
    }
    onResourceChange = (e: any) => {
        const checked = e.target.checked;
        const value = e.target.value;
        if(checked){
            const checkedResourcesIds = [...this.state.checkedResourcesIds, value];
            this.setState({checkedResourcesIds: checkedResourcesIds});
        }
        else{
            const checkedResourcesIds = this.state.checkedResourcesIds.filter(n=>n!==value);
            this.setState({checkedResourcesIds: checkedResourcesIds});
        }

    }

    allQuantitiesCheckboxesChange = (e: CheckboxChangeEvent) => {
        if (e.target.checked) {
            this.setState({checkedQuantities: this.props.mapDataStore.quantity === undefined ? [] : Object.values(this.props.mapDataStore.quantity).map(item => item.id)});
        } else {
            this.setState({checkedQuantities: []});
        }
    }
    onQuantitiesChange = (e: any) => {
        if(this.state.checkedQuantities){
            const checked = e.target.checked;
            const number = e.target.value;
            if(checked){
                const checkedQuantities = [...this.state.checkedQuantities, number];
                this.setState({checkedQuantities: checkedQuantities});
            }
            else{
                const checkedQuantities = this.state.checkedQuantities.filter(n=>n!==number);
                this.setState({checkedQuantities: checkedQuantities});
            }
        }
    }

    allChannelsCheckboxesChange = (e: CheckboxChangeEvent) => {
        if (e.target.checked) {
            this.setState({checkedChannels: PopupSettings.CHANNELS});
        } else {
            this.setState({checkedChannels: []});
        }
    }
    onChannelsChange = (e: any) => {
        const checked = e.target.checked;
        const number = e.target.value;
        if(checked){
            const checkedChannels = [...this.state.checkedChannels, number];
            this.setState({checkedChannels: checkedChannels});
        }
        else{
            const checkedChannels = this.state.checkedChannels.filter(n=>n!==number);
            this.setState({checkedChannels: checkedChannels});
        }
    }


    allDataTypesCheckboxesChange = (e: CheckboxChangeEvent) => {
        if (e.target.checked) {
            this.setState({checkedDataTypes: PopupSettings.DATA_TYPES.map(item => item.key)});
        } else {
            this.setState({checkedDataTypes: []});
        }
    }
    onDataTypesChange = (e: any) => {
        const checked = e.target.checked;
        const value = e.target.value;
        if(checked){
            const checkedDataTypes = [...this.state.checkedDataTypes, value];
            this.setState({checkedDataTypes: checkedDataTypes});
        }
        else{
            const checkedDataTypes = this.state.checkedDataTypes.filter(n=>n!==value);
            this.setState({checkedDataTypes: checkedDataTypes});
        }
    }

    render() {
        const variablesMenu0 = (
            <Space direction="vertical" className={styles.popupSettingsLayersVariablesFilterDroppedList}>
                <Checkbox 
                    key='k0'
                    checked={this.state.checkedQuantities?.length === Object.keys(this.props.mapDataStore.quantity === undefined ? {} : this.props.mapDataStore.quantity).length}
                    indeterminate={this.state.checkedQuantities?.length === 0 ? false : this.state.checkedQuantities?.length !== Object.keys(this.props.mapDataStore.quantity === undefined ? {} : this.props.mapDataStore.quantity).length}
                    onChange={this.allQuantitiesCheckboxesChange} 
                > 
                    Выделить все 
                </Checkbox>
                <Checkbox.Group key='k1' onChange={this.onQuantitiesChange} value={this.state.checkedQuantities}>
                    <Space direction='vertical'>
                        {
                            Object.values(this.props.mapDataStore.quantity === undefined ? {} : this.props.mapDataStore.quantity).map(item => {
                                    return <Checkbox key={item.id} value={item.id}> {item.name} </Checkbox>
                                }) 
                        }
                    </Space>
                </Checkbox.Group>
            </Space>
        );
        const variablesMenu = (
            <Menu style = {{maxHeight:300, overflowY:'scroll'}} items={[
                {
                    key: '0',
                    label:(
                        <div onClick={e => e.stopPropagation()}>
                            <Checkbox 
                                key='k0'
                                checked={this.state.checkedQuantities?.length === Object.keys(this.props.mapDataStore.quantity === undefined ? {} : this.props.mapDataStore.quantity).length}
                                indeterminate={this.state.checkedQuantities?.length === 0 ? false : this.state.checkedQuantities?.length !== Object.keys(this.props.mapDataStore.quantity === undefined ? {} : this.props.mapDataStore.quantity).length}
                                onChange={this.allQuantitiesCheckboxesChange} 
                            > 
                                Выделить все 
                            </Checkbox>
                        </div>
                        )
                },
                ...Object.values(this.props.mapDataStore.quantity === undefined ? {} : this.props.mapDataStore.quantity).map((item, index) => {
                    return {
                        key: (index+1).toString(),
                        label:(
                            <div onClick={e => e.stopPropagation()}>
                            <Checkbox 
                                key={item.id.toString()}
                                value={item.id}
                                checked={this.state.checkedQuantities?.includes(item.id)}
                                onChange={this.onQuantitiesChange}
                            >
                                     {item.name}
                             </Checkbox>
                             </div>)}
                })
            ]}>
            </Menu>
        );

        const channelsMenu = (
            <Menu items={[
                {
                    key: '0',
                    label:(
                        <div onClick={e => e.stopPropagation()}>
                        <Checkbox 
                            key='k0'
                            checked={this.state.checkedChannels.length === PopupSettings.CHANNELS.length}
                            indeterminate={this.state.checkedChannels.length === 0 ? false : this.state.checkedChannels.length !== PopupSettings.CHANNELS.length}
                            onChange={this.allChannelsCheckboxesChange} 
                            > 
                                Выделить все 
                        </Checkbox>
                        </div>
                        )
                },
                ...PopupSettings.CHANNELS.map((item, index) => {
                    return {
                        key: (index+1).toString(),
                        label:(
                            <div onClick={e => e.stopPropagation()}>
                            <Checkbox 
                                key={item}
                                value={item}
                                checked={this.state.checkedChannels.includes(item)}
                                onChange={this.onChannelsChange}
                            >
                                     {item}
                             </Checkbox>
                             </div>)}
                })
            ]}>
            </Menu>
        );

        const dataTypesMenu = (
            <Menu items={[
                {
                    key: '0',
                    label:(
                        <div onClick={e => e.stopPropagation()}>
                        <Checkbox 
                            key='k0'
                            checked={this.state.checkedDataTypes.length === PopupSettings.DATA_TYPES.length}
                            indeterminate={this.state.checkedDataTypes.length === 0 ? false : this.state.checkedDataTypes.length !== PopupSettings.DATA_TYPES.length}
                            onChange={this.allDataTypesCheckboxesChange} 
                        > 
                            Выделить все 
                        </Checkbox>
                        </div>
                        )
                },
                ...PopupSettings.DATA_TYPES.map((item, index) => {
                    return {
                        key: (index+1).toString(),
                        label:(
                            <div onClick={e => e.stopPropagation()}>
                            <Checkbox 
                                key={item.key.toString()}
                                value={item.key}
                                checked={this.state.checkedDataTypes.includes(item.key)}
                                onChange={this.onDataTypesChange}
                            >
                                     {item.description}
                             </Checkbox>
                             </div>)}
                })
            ]}>
            </Menu>
        );

        const resourcesMenu = (
            <Menu style={{width:230}} items={[
                {
                    key: '0',
                    label:(
                        <div onClick={e => e.stopPropagation()}>
                            <Checkbox 
                                key='k0'
                                checked={this.state.checkedResourcesIds.length === this.props.userProfile.resources.length}
                                indeterminate={this.state.checkedResourcesIds.length === 0 ? false : this.state.checkedResourcesIds.length !== this.props.userProfile.resources.length}
                                onChange={this.allResourcesCheckboxesChange} 
                            > 
                                Выделить все 
                            </Checkbox>
                        </div>
                        )
                },
                ...this.props.userProfile.resources.map((item, index) => {
                    return {
                        key: (index+1).toString(),
                        label:(
                            <div onClick={e => e.stopPropagation()}>
                            <Checkbox 
                                key={item.id.toString()}
                                value={item.id}
                                checked={this.state.checkedResourcesIds.includes(item.id)}
                                onChange={this.onResourceChange}
                            >
                                     {item.name}
                             </Checkbox>
                             </div>)}
                })
            ]}>
            </Menu>
        );

        const onCellRender1 = (text: any, row: any, index: any) => {
            if (index === 0)
                return <InputNumber value={this.props.userProfile.profile.Map?.CurrentRefreshPeriod} onChange={(val) => this.changeMapProfile('CurrentRefreshPeriod', val)} />
            if (index === 1)
                return <InputNumber value={this.props.userProfile.profile.Map?.HourRefreshPeriod} onChange={(val) => this.changeMapProfile('HourRefreshPeriod', val)} />
            if (index === 2)
                return <InputNumber value={this.props.userProfile.profile.Map?.DayRefreshPeriod} onChange={(val) => this.changeMapProfile('DayRefreshPeriod', val)} />
        }
        const columns1 = [{ title: 'Период обновления экрана (сек)', dataIndex: 'descriotion', key: 'descr' }, {title: '', key: 'x', render: onCellRender1, align: 'right' as 'right'}];
        const data1 = [{key: '1', descriotion: 'Текущие значения (сек)'},{key: '2', descriotion: 'Часовые значения (сек)'},{key: '3', descriotion: 'Суточные значения (сек)'}];

        const onCellRender2 = (text: any, row: any, index: any) => {
            if (index === 0)
                return <InputNumber value={this.props.userProfile.profile.Map?.CurrentDataDepth} onChange={(val) => this.changeMapProfile('CurrentDataDepth', val)} />
            if (index === 1)
                return <InputNumber value={this.props.userProfile.profile.Map?.HourDataDepth} onChange={(val) => this.changeMapProfile('HourDataDepth', val)} />
            if (index === 2)
                return <InputNumber value={this.props.userProfile.profile.Map?.DayDataDepth} onChange={(val) => this.changeMapProfile('DayDataDepth', val)} />
        }
        const columns2 = [{ title: 'Глубина получения данных', dataIndex: 'descriotion', key: 'descr' }, {key: 'x', render: onCellRender2, align: 'right' as 'right'}];
        const data2 = [{key: '1', descriotion: 'Текущие значения (мин)'},{key: '2', descriotion: 'Часовые значения (час)'},{key: '3', descriotion: 'Суточные значения (сут)'}];

        const onCellRender3 = (text: any, row: any, index: any) => {
            if (index === 0)
                return <InputNumber value={this.props.userProfile.profile.Map?.CurrentNormalDataDepth} onChange={(val) => this.changeMapProfile('CurrentNormalDataDepth', val)} />
            if (index === 1)
                return <InputNumber value={this.props.userProfile.profile.Map?.HourNormalDataDepth} onChange={(val) => this.changeMapProfile('HourNormalDataDepth', val)} />
            if (index === 2)
                return <InputNumber value={this.props.userProfile.profile.Map?.DayNormalDataDepth} onChange={(val) => this.changeMapProfile('DayNormalDataDepth', val)} />
        }
        const columns3 = [{ title: 'Сигнализация о отсутствии данных', dataIndex: 'descriotion', key: 'descr' }, {key: 'x', render: onCellRender3, align: 'right' as 'right'}];
        const data3 = [{key: '1', descriotion: 'Текущие нормальные значения (мин)'},{key: '2', descriotion: 'Часовые нормальные значения (час)'},{key: '3', descriotion: 'Суточные нормальные значения (сут)'}];

        const layersChildren = this.getLayersTree().map(item => {
            return (
                {title: item.title, key: item.key, children: item.children.map(i => ({...i})) }
            );
        });

        return (
                <Tabs className={styles.popupSettingsTabs} defaultActiveKey="1">
                    <TabPane tab="Слои даных" key="1">
                        <Space direction='vertical' className={styles.tabFrame}>
                            <span key='s1' className={styles.flexElement}>
                                <Dropdown key='s11' trigger={['click', 'hover']} overlay={variablesMenu}>
                                    <Button block>
                                        Переменные <DownOutlined />
                                    </Button>
                                </Dropdown>
                                <Dropdown key='s12' trigger={['click', 'hover']} overlay={channelsMenu}>
                                    <Button block>
                                        Каналы <DownOutlined /> 
                                    </Button>
                                </Dropdown>
                            </span>
                            <span key='s2' className={styles.flexElement}>
                                <Dropdown key='s21' trigger={['click', 'hover']} overlay={dataTypesMenu}>
                                    <Button block>
                                        Данные <DownOutlined /> 
                                    </Button>
                                </Dropdown>
                                <Dropdown key='s22' trigger={['click', 'hover']} placement="bottomRight" overlay={resourcesMenu}>
                                    <Button block>
                                        Ресурсы <DownOutlined /> 
                                    </Button>
                                </Dropdown>
                            </span>
                            <div key='s3' className={styles.popupSettingsLayersTree}>
                                {
                                    <Tree 
                                        autoExpandParent={true} 
                                        checkable
                                        defaultExpandAll={true}
                                        checkedKeys={this.props.userProfile.profile.Map?.SelectedLayers.split(',')}
                                        onCheck={this.onLayerCheck}
                                        treeData={[{ 
                                            title: 'Слои',
                                            key: 'root',
                                            children: layersChildren
                                        }]}
                                    />
                                    
                                }
                            </div>
                            
                        </Space>
                    </TabPane>
                    <TabPane tab="Настройки" key="2">
                        <Space direction='vertical' className={styles.tabFrame}>
                                <div key='s1' className={styles.flexElement}>
                                    <span>Отображение значения по группе</span>
                                    <Select 
                                        value={this.props.userProfile.profile.Map?.ClusteringFunctionType ?? ClusteringFunctionTypeEnum.max} 
                                        style={{ width: 150 }} 
                                        className={styles.marginLeftAuto}
                                        onChange={(value: string) => this.changeMapProfile('ClusteringFunctionType', value)}
                                    >
                                        <Option value={ClusteringFunctionTypeEnum.max}>Максимальное</Option>
                                        <Option value={ClusteringFunctionTypeEnum.min}>Минимальное</Option>
                                        <Option value={ClusteringFunctionTypeEnum.avg}>Среднее</Option>
                                    </Select>
                                </div>
                                <div key='s2' className={styles.flexElement}>
                                        <Checkbox 
                                            checked={this.props.userProfile.profile.Map?.AutoSwitchLayers ?? false}
                                            onChange={(e) => this.changeMapProfile('AutoSwitchLayers', e.target.checked)}
                                        > 
                                            Период переключения между слоями (сек)
                                        </Checkbox>

                                        <span className={styles.marginLeftAuto}>
                                            <InputNumber 
                                                value={this.props.userProfile.profile.Map?.AutoSwitchLayersTime} 
                                                onChange={(val: any) => this.changeMapProfile('AutoSwitchLayersTime', val)} 
                                                style={{ width: 150 }} 
                                            />
                                        </span>
                                </div>
                                <div key='s3' className={styles.flexElement}>
                                    <Checkbox 
                                        onChange={(e) => e.target.checked ? this.props.SetSearchDataFromTime(new Date) : this.props.SetSearchDataFromTime(null)}
                                    >
                                        Начинать поиск данных с даты
                                    </Checkbox>
                                    <DatePicker 
                                        disabled={this.props.mapDataStore.searchDataFromTime === null} 
                                        value={moment(this.props.mapDataStore.searchDataFromTime ?? new Date())} 
                                        onChange={(date, dateString) => this.props.SetSearchDataFromTime(date?.toDate() ?? null)} style={{ width: 150 }} 
                                        className={styles.marginLeftAuto} 
                                    />
                                </div>
                        </Space>
                    </TabPane>
                    <TabPane tab="Обновление" key="3">
                        <Space direction='vertical' className={styles.tabFrame}>
                            <div key='k1'>
                                <Checkbox 
                                    onChange={(e: CheckboxChangeEvent) => this.changeMapProfile('AutoRefresh', e.target.checked)}
                                    checked={this.props.userProfile.profile.Map?.AutoRefresh}
                                >
                                    Автоматическое обновление экрана
                                </Checkbox>
                            </div>
                            <div key='k2'>
                                <Table pagination={false} columns={columns1} dataSource={data1} size="small" />
                            </div>
                            <div key='k3'>
                                <Table pagination={false} columns={columns2} dataSource={data2} size="small" />
                            </div>
                            <div key='k4'>
                                <Table pagination={false} columns={columns3} dataSource={data3} size="small" />
                            </div>
                        </Space>
                    </TabPane>
                    
                </Tabs>
        )
    }
};

export default connect(
    (state: ApplicationState) => {
        return {
            userProfile: state.userProfile,
            mapDataStore: state.mapDataStore
        }
    },
    {...UserProfile.actionCreators, ...MapDataStore.actionCreators}
) (PopupSettings as any);