import { Action, Reducer } from 'redux';

//Состояние хранилища для страницы Кадр.
export interface IKadrState {
    textFilter: string;
    needRefresh: boolean;
    commandToExecute: any;
    pointIds: string[];
}

export interface SetTextFilterAction { type: 'SET_KADR_TEXT_FILTER', textFilter: string };
export interface SetNeedRefreshAction { type: 'SET_NEED_REFRESH', needRefresh: boolean };
export interface SetCommandToExecuteAction { type: 'SET_COMMAND_TO_EXECUTE', commandToExecute: any };
export interface ShowAdditionalDialogAction { type: 'SHOW_ADDITIONAL_DIALOG'};
export interface SetPointsAction { type: 'SET_POINTS', pointIds: string[]};

export type KnownAction = SetTextFilterAction | SetNeedRefreshAction | SetCommandToExecuteAction | ShowAdditionalDialogAction | SetPointsAction;


export const actionCreators = {
    setTextFilter: (textFilter: string) => ({ type: 'SET_KADR_TEXT_FILTER', textFilter: textFilter } as SetTextFilterAction),
    setNeedRefresh: (needRefresh: boolean) => ({ type: 'SET_NEED_REFRESH', needRefresh: needRefresh } as SetNeedRefreshAction),
    setCommandToExecute: (commandToExecute: any) => ({ type: 'SET_COMMAND_TO_EXECUTE', commandToExecute: commandToExecute } as SetCommandToExecuteAction),
    showAdditionalDialog: (source: any, callback: any, exceptionCallback: any) => {
        window.call_execute_withcreate(source, "AdditionalDialogShow", {}, callback, exceptionCallback);
        return{ type: 'SHOW_ADDITIONAL_DIALOG'} as ShowAdditionalDialogAction;
    },
    setPoints: (pointIds: string[]) => ({ type: 'SET_POINTS', pointIds: pointIds } as SetPointsAction),
};

export const reducer: Reducer<IKadrState> = (state: IKadrState | undefined, incomingAction: Action): IKadrState => {
    if (state === undefined) {
        return { textFilter: '', needRefresh: false, commandToExecute: null, pointIds: [] };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SET_KADR_TEXT_FILTER':
            return { ...state, textFilter: action.textFilter };
        case 'SET_NEED_REFRESH':
            return { ...state, needRefresh: action.needRefresh };
        case 'SET_COMMAND_TO_EXECUTE':
            return { ...state, commandToExecute: action.commandToExecute };
        case 'SET_POINTS':
            return { ...state, pointIds: action.pointIds };
        default:
            return state;
    }
};
