import * as React from 'react';

import styles from '../../resources/Page.module.less';
import { Button, Modal, Select, Input, Row, Col, Table, Checkbox, Tooltip, InputNumber } from 'antd';

import { deepCopyObj, getModalZIndex } from '../../utils/Common';
import { MODAL_WRAP_CLASSNAME } from '../../utils/Const';
import { SBilContactDTO, SContractPointDTO as SBilContractPointDTO, SBillingCompanyInfoDTO } from './BillingDecls';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import * as Const from '../../utils/Const';
import ContactEditForm from './ContactEditForm';

const { Option } = Select;

type Props = {
    contacts: SBilContactDTO[] | null,
    onChangeContacts(contacts: SBilContactDTO[]) : void,
    onDeleteContact(contactId: number): void,
    companyId: number,
    scrollY: string,
}

type State = {
    zIndex: number,
    editContact: SBilContactDTO | null,
}

export default class ContactsTable extends React.Component<Props, State> {
    constructor(props: Props, state: State) {
        super(props);

        this.state = {
            zIndex: 0,
            editContact: null,
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.contacts == null && this.props.contacts != null) {
        }
    }
    onWriteContact = (contact: SBilContactDTO, close: boolean) => {
        this.setState({editContact: contact});
    }
    onEditContactOk = (contact: SBilContactDTO) => {
        if (this.props.contacts != null) {
            let tmp = [...this.props.contacts];
            let i = tmp.findIndex(x => x.id === contact.id);
            if (i != -1) {
                tmp[i] = contact;
                this.props.onChangeContacts(tmp);
            } else {
                tmp = [contact, ...tmp];
                this.props.onChangeContacts(tmp);
            }
            this.setState({editContact: null});
        }
    }
    onClickEditContact = (val: any): void => {
        this.setState({editContact: this.props.contacts?.find(x => x.id === val) ?? null, zIndex: getModalZIndex()});
    }

    sorter = (a: any, b: any) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b);

    onDeleteContact = (id: any) => {
        Modal.confirm({
            title: 'Удалить контакт',
            wrapClassName: Const.MODAL_WRAP_CLASSNAME,
            zIndex: getModalZIndex(),
            content: 'Вы действительно хотите удалить контакт?',
            width: 500,
            okText: 'Да',
            cancelText: 'Нет',
            onOk: () => {
                if (this.props.contacts != null) {
                    let cs = [...this.props.contacts];
                    let t = cs.findIndex(x => x.id === id);
                    if (t != -1) {
                        cs.splice(t, 1);
                        this.props.onChangeContacts(cs);
                        if (id >= 0) {
                            this.props.onDeleteContact(id);
                        }
                    }
                }
            },
            onCancel: () => {
            }
        });  
    }

    onClickAddContact = (): void => {
        let minId = Math.min(...(this.props.contacts?.map(x => x.id) ?? [-1]));
        if (minId < 0) {
            minId -= 1;
        } else {
            minId = -1;
        }
        let cont: SBilContactDTO = {
            companyId: this.props.companyId,
            description: '',
            email: '',
            id: minId,
            name: '',
            phone: '',
            rank: '',
        }
        this.setState({editContact: cont, zIndex: getModalZIndex()});
    }

    private columns = [
        {
            dataIndex: "num",
            title: "№",
            render: (text: any, record: any, index: any) => (
                <>{index + 1}</>
            ),
            width: 40,
        },
        {
            dataIndex: "edit",
            title: "",
            width: 50,
            align: 'center' as 'center',
            render: (val: any, record: any, index: any) => {
                return (
                    <Tooltip placement="top" title={'Редактировать'}>
                        <Button shape="circle" onClick={() => this.onClickEditContact(val)} icon={<EditTwoTone twoToneColor={Const.COLORS.SvgIconColor}/>} />
                    </Tooltip>
                )
            },
        },
        {
            dataIndex: "delete",
            title: "",
            width: 50,
            align: 'center' as 'center',
            render: (val: any, record: any, index: any) => {
                return (
                    <Tooltip placement="top" title={'Удалить'}>
                        <Button shape="circle" onClick={() => this.onDeleteContact(val)} icon={<DeleteTwoTone twoToneColor={Const.COLORS.SvgIconColor}/>} />
                    </Tooltip>
                )
            },
        },
        {
            dataIndex: "rank",
            title: "Должность",
            sorter: (a: any, b: any) => this.sorter(a.rank, b.rank),
        },
        {
            dataIndex: "name",
            title: "ФИО",
            sorter: (a: any, b: any) => this.sorter(a.name, b.name),
        },
        {
            dataIndex: "phone",
            title: "Телефон",
            width: 150,
            sorter: (a: any, b: any) => this.sorter(a.phone, b.phone),
        },
        {
            dataIndex: "email",
            title: "e-mail",
            width: 200
        },
        {
            dataIndex: "description",
            title: "Примечание",
        },
    ];

    render() {
        return (
            <div style={{display: 'flex', width: '100%'}}>
                <div style={{ padding: '4px', width: '100%'}}>
                    <Button disabled={this.props.companyId === -1} style={{marginBottom: '5px'}} onClick={this.onClickAddContact} type='primary'>Добавить контакт</Button>
                    <Table 
                        scroll={{ y: this.props.scrollY }} size='small' showSorterTooltip={false}
                        columns={this.columns}
                        dataSource={this.props.contacts?.map((x, index) => {
                            return {
                                num: index,
                                edit: x.id,
                                delete: x.id,
                                rank: x.rank,
                                name: x.name,
                                phone: x.phone,
                                email: x.email,
                                description: x.description,
                            }
                        })}
                        pagination={false}
                    />
                </div>
                <ContactEditForm 
                    contact={this.state.editContact}
                    onHide={() => this.setState({editContact: null})}
                    onOk={this.onEditContactOk}
                    zIndex={this.state.zIndex}
                />
            </div>
        );
    }
}