import React, {useState} from 'react';
import { Table, Button, Tag, Tooltip, Space, Checkbox, Modal } from 'antd';
import { AlertOutlined, CheckCircleTwoTone, CheckOutlined, DeleteTwoTone, EditTwoTone, ExclamationCircleOutlined, FieldTimeOutlined, FireOutlined, FireTwoTone, FolderOutlined, InfoCircleTwoTone, InteractionOutlined, LineChartOutlined, LoadingOutlined, ProfileOutlined, QuestionCircleTwoTone, ScheduleOutlined, ScheduleTwoTone, SettingOutlined, SettingTwoTone, SnippetsOutlined, StopTwoTone, TeamOutlined, ToolOutlined, UserOutlined, WarningTwoTone, WifiOutlined } from '@ant-design/icons';
import moment from 'moment';
import {Point, EventTypesEnum, PeriodsEnum, SourceEventTypesEnum, SConnectionStatisticDTO, SConnectionProblemDTO} from "../../decl";
import {stringSorter, daysCaption} from "../../utils/Common";
import * as Const from "../../utils/Const";
import styles from '../../resources/Page.module.less';
import { AlignType } from 'rc-table/lib/interface';
export function PointsTable(props: any) {
    //console.log('points:', props.points);
    const columns = [
        {
          key: 'number',
          title: '№',
          render:  (value:any, item:any, index:any) => index + 1,
            width: '5%',
          align: 'center' as AlignType 
          },
        {
          key: 'info',
          title: 'Инфо',
          render: (text: any, record: any) => 
              <Button onClick={() => props.showPointInfo(record.key, record.pointNumber)} size="middle" shape="circle" icon={<ScheduleTwoTone twoToneColor={Const.COLORS.SvgIconColor} />} />,
          width: '5%',
          align: 'center' as AlignType 
        },
        { 
          key: 'pointNumber',
          title: 'Номер',
          dataIndex: 'pointNumber',
          width: '25%',
          sorter: (a: any, b: any) => stringSorter(a.pointNumber, b.pointNumber), 
        },
        { 
          key: 'pointName',
          title: 'Название',
          dataIndex: 'pointName',
          width: '25%',
          //hasFiler: true, hasSorter: true,
          sorter: (a: any, b: any) => stringSorter(a.pointName, b.pointName), 
        },
        { 
          key: 'address',
          title: 'Адрес',
          dataIndex: 'address',
          width: '31%'
        },
        { 
          key: 'resource',
          title: 'Ресурс',
          dataIndex: 'resource',
          width: '9%',
          sorter: (a: any, b: any) => stringSorter(a.resourceId, b.resourceId),
        }
    ];
    const data: any = [];
    props.points.map((p:any)=>{
        const resource: String = p.persistProperties['resourceshortname'];
        const name: String = p.persistProperties['name'];
        data.push({
          key: p.id,
          pointNumber: p.number,
          pointName: name,
          address: p.persistProperties['address'],
          resourceId: resource,
          resource: <Tag style={{margin: '3px'}} color='blue'>{resource}</Tag>
        });
    });
  return (
    <>
      <Table
        showSorterTooltip = {false}
        columns={columns}
        dataSource={data}
        pagination={false}
        bordered
      />
    </>
  );
}

export function BlockingPointsTable(props: any) {
  //console.log('points:', props.points);
  const columns = [
      {
        key: 'number',
        title: '№',
        render:  (value:any, item:any, index:any) => index + 1,
        width: '6%',
        },
      { 
        key: 'pointNumber',
        title: 'Точка учёта',
        width: '34%',
        render: (text: any, record: any) =>
          <div> 
            <div>{record.pointNumber}</div>
            <div>{record.address}</div>
          </div>,
        sorter: (a: any, b: any) => stringSorter(a.pointNumber, b.pointNumber), 
      },
      { 
        key: 'resource',
        title: 'Ресурс',
        dataIndex: 'resource',
        width: '12%',
        align: 'center' as AlignType ,
        sorter: (a: any, b: any) => stringSorter(a.resourceId, b.resourceId),
      },
      { 
        key: 'state',
        title: 'Состояние',
        width: '48%',
        render: (text: any, record: any) =>
          <div> 
            <span>{record.isLocked ? 'Период использования Интернет-службы "Теплоинформ" истёк. Если Вы хотите продолжить работу с системой, ' :
                  'До блокировки точки учёта осталось ' +  daysCaption( record.daysToLock) + ', '} </span>
            <a onClick={props.gotoConracts}>свяжитесь с нами.</a>
          </div>
}
  ];
  const data: any  = props.points.map((p:any)=>{
      const resource: String = p.persistProperties['resourceshortname'];
      const name: String = p.persistProperties['name'];
      return {
        key: p.id,
        pointNumber: p.number,
        address: p.persistProperties['address'],
        resourceId: resource,
        resource: <Tag color='blue'>{resource}</Tag>,
        isLocked: p.isLocked,
        daysToLock: p.daysToLock
      };
  });

  const scroll: any = props.points.length > 5 ? { y: 280 } : {}
  const disabled = false;
  const [checked, setChecked] = useState(false);
  let ignoreWarning: boolean = props.ignoreWarning;
  const onChange = (e:any)=>{
    setChecked(e.target.checked);
     props.setIgnoreWarning(e.target.checked);
  }
return (
  <>
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      scroll={scroll}
      bordered
    />
    {!props.isBlocking &&
      <div className={styles.ignoreCheck}>
        <Checkbox  checked={checked} disabled={disabled} onChange={onChange}>Больше не показывать предупреждение об окончании оплаченного периода.</Checkbox>
      </div>}
    </>
);
}


export function EventsTable(props: any) {
  const columns: any = [
    {
      key: 'number',
      title: '№',
      width: '7%',
      align: 'center' as AlignType ,
      render:  (value:any, item:any, index:any) => index + 1,
     },
    {
      key: 'info',
      title: 'Инфо',
      width: '8%',
      align: 'center' as AlignType,
      render: (text: any, record: any) => 
          <Button onClick={() => props.showPointInfo(record.pointId, record.object)} size="middle" shape="circle" icon={<ScheduleTwoTone twoToneColor={Const.COLORS.SvgIconColor} />} />,
    },
    { 
        title: 'Время', 
        dataIndex: 'time', 
        key: 'time', 
        width: '10%',
        sorter: (a: any, b: any) => stringSorter(a.time, b.time), 
        render: (item: any, record: any) => <div style={{minHeight: '32px', display: 'inline-flex', justifyContent: 'center', flexDirection: 'column'}}>{moment(item).format("DD.MM.YYYY HH:mm:ss")}</div>,
    },
    {
        title: 'Точка учёта',
        dataIndex: 'object',
        key: 'object',
        width: '20%',
        sorter: (a: any, b: any) => stringSorter(a.object, b.object)
       },
    { 
        title: 'Событие', 
        dataIndex: 'event', 
        key: 'event', 
        width: '29%',
        render: (item: any, record: any) => <a onClick={() => props.formReport(record.pointId, moment(record.eventTime).set({ hour:0, minute:0, second:0, millisecond:0 }).toDate(), moment(record.eventTime).set({ hour:0, minute:0, second:0, millisecond:0 }).add(1, 'days').toDate(), PeriodsEnum.hour)}>{item}</a>, 
        sorter: (a: any, b: any) => stringSorter(a.event, b.event), 
    },
    {
        title: 
            <Tooltip placement="top" title={'Цветовое обозначение события для привлечения внимания'}>
                <span style={{marginRight: '3px'}}>Статус</span>
            </Tooltip>,
        dataIndex: 'status',
        width: '8%',
        sorter: (a: any, b: any) => stringSorter(a.status, b.status),
        align: 'center' as 'center',
        filters: [
            { text: <Space><WarningTwoTone twoToneColor="#eb2f96" style={{ fontSize: '20px'}} />Авария</Space>, value: EventTypesEnum.Alarm },
            { text: <Space><InfoCircleTwoTone twoToneColor="#fadb14" style={{ fontSize: '20px'}} />Сообщение</Space>, value: EventTypesEnum.Message },
            { text: <Space><CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: '20px'}} />Поверка</Space>, value: EventTypesEnum.Checking },
            { text: <Space><StopTwoTone twoToneColor="#eb2f96" style={{ fontSize: '20px'}} />Предупреждение</Space>, value: EventTypesEnum.Warning },
            { text: <Space><FireTwoTone twoToneColor="#ff7a45" style={{ fontSize: '20px'}} />НС</Space>, value: EventTypesEnum.Unstate },
            { text: <Space><SettingTwoTone style={{ fontSize: '20px'}} />ТО</Space>, value: EventTypesEnum.Work },
        ],
        onFilter: (value: any, record: any) => value === record['status'],
        render: (item: any) => {
            const et = item as EventTypesEnum;
            let res = <></>;
            if (et === EventTypesEnum.Work)
                res = <Tooltip placement="top" title={'ТО'}><SettingTwoTone style={{ fontSize: '20px'}} /></Tooltip>;
            else if (et === EventTypesEnum.Alarm)
                res = <Tooltip placement="top" title={'Авария'}><WarningTwoTone twoToneColor="#eb2f96" style={{ fontSize: '20px' }} /></Tooltip>;
            else if (et === EventTypesEnum.Checking)
                res = <Tooltip placement="top" title={'Поверка'}><CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: '20px' }} /></Tooltip>;
            else if (et === EventTypesEnum.Message)
                res = <Tooltip placement="top" title={'Сообщение'}><InfoCircleTwoTone twoToneColor="#fadb14" style={{ fontSize: '20px' }} /></Tooltip>;
            else if (et === EventTypesEnum.Unstate)
                res = <Tooltip placement="top" title={'НС'}><FireTwoTone twoToneColor="#ff7a45" style={{ fontSize: '20px' }} /></Tooltip>;
            else if (et === EventTypesEnum.Warning)
                res = <Tooltip placement="top" title={'Предупреждение'}><StopTwoTone twoToneColor="#eb2f96" style={{ fontSize: '20px' }} /></Tooltip>;
            return res;
        },
    },
    {
        title: 'Тип',
        dataIndex: 'sourceType',
        key: 'sourceType',
        align: 'center' as 'center',
        width: '8%',
        render: (item: any) => {
            const et = item as SourceEventTypesEnum;
            let res = <></>;

            if (et === SourceEventTypesEnum.ConnectionEvents)
                res = <Tooltip placement="top" title={'Отсутствие связи с прибором'}><WifiOutlined style={{ fontSize: '20px' }}/></Tooltip>;
            else if (et === SourceEventTypesEnum.DeviceParametersChanged)
                res = <Tooltip placement="top" title={'Изменение параметров прибора'}><InteractionOutlined style={{ fontSize: '20px' }}/></Tooltip>;
            else if (et === SourceEventTypesEnum.DeviceTimeIncorrect)
                res = <Tooltip placement="top" title={'Неверное время прибора'}><FieldTimeOutlined style={{ fontSize: '20px' }}/></Tooltip>;
            else if (et === SourceEventTypesEnum.DeviceVerificationMessage)
                res = <Tooltip placement="top" title={'Поверка'}><ToolOutlined style={{ fontSize: '20px' }}/></Tooltip>;
            else if (et === SourceEventTypesEnum.DriverEvents)
                res = <Tooltip placement="top" title={'Условия прибора'}><ProfileOutlined style={{ fontSize: '20px' }}/></Tooltip>;
            else if (et === SourceEventTypesEnum.GroupCondition)
                res = <Tooltip placement="top" title={'Групповое условие'}><TeamOutlined style={{ fontSize: '20px' }}/></Tooltip>;
            else if (et === SourceEventTypesEnum.MinMaxCheck)
                res = <Tooltip placement="top" title={'ПДЗ'}><LineChartOutlined style={{ fontSize: '20px' }}/></Tooltip>;
            else if (et === SourceEventTypesEnum.MonthReport)
                res = <Tooltip placement="top" title={'Месячный отчёт'}><SnippetsOutlined style={{ fontSize: '20px' }}/></Tooltip>;
            else if (et === SourceEventTypesEnum.PointCondition)
                res = <Tooltip placement="top" title={'Индивидуальное условие'}><UserOutlined style={{ fontSize: '20px' }}/></Tooltip>;
            else if (et === SourceEventTypesEnum.SensorEvents)
                res = <Tooltip placement="top" title={'Срабатывание датчиков'}><AlertOutlined style={{ fontSize: '20px' }}/></Tooltip>;
            return res;
        },
        sorter: true,
        onFilter: (value: any, record: any) => value === record['sourceType'],
        filters: [
            { text: <Space><WifiOutlined style={{ fontSize: '20px'}}/>Отсутствие связи с прибором</Space>, value: SourceEventTypesEnum.ConnectionEvents },
            { text: <Space><InteractionOutlined style={{ fontSize: '20px'}}/>Изменение параметров прибора</Space>, value: SourceEventTypesEnum.DeviceParametersChanged },
            { text: <Space><FieldTimeOutlined style={{ fontSize: '20px'}}/>Неверное время прибора</Space>, value: SourceEventTypesEnum.DeviceTimeIncorrect },
            { text: <Space><ToolOutlined style={{ fontSize: '20px'}}/>Сообщения о поверке</Space>, value: SourceEventTypesEnum.DeviceVerificationMessage },
            { text: <Space><ProfileOutlined style={{ fontSize: '20px'}}/>Условия прибора</Space>, value: SourceEventTypesEnum.DriverEvents },
            { text: <Space><TeamOutlined style={{ fontSize: '20px'}}/>Групповые условия</Space>, value: SourceEventTypesEnum.GroupCondition },
            { text: <Space><LineChartOutlined style={{ fontSize: '20px'}}/>ПДЗ</Space>, value: SourceEventTypesEnum.MinMaxCheck },
            { text: <Space><SnippetsOutlined style={{ fontSize: '20px'}}/>Месячные отчеты</Space>, value: SourceEventTypesEnum.MonthReport },
            { text: <Space><UserOutlined style={{ fontSize: '20px'}}/>Индивидуальное условие</Space>, value: SourceEventTypesEnum.PointCondition },
            { text: <Space><AlertOutlined style={{ fontSize: '20px'}}/>Срабатывания датчиков</Space>, value: SourceEventTypesEnum.SensorEvents },
        ],
    },
];

const eventsDataSourceGet = (): any[] => {
  return (
      props.events.map( (item: any) => {
          return ({
              key: item.eventId,
              time: item.eventTime,
              object: item.pointNumber,
              event: item.message,
              status: item.eventType,
              sourceType: item.sourceEventType,
              eventTime: item.eventTime,
              pointId: item.pointId
          })
      })
  )
}

return (
  <>
    <Table
        style={{marginLeft: '10px'}}
        scroll={{y:300}}
        pagination={false}
        columns={columns}
        showSorterTooltip={false}
        dataSource={ eventsDataSourceGet() }
        bordered
    />
  </>
);
}

export interface IConnnectionStatisticsProps {
  statistics: SConnectionStatisticDTO, //Статистика опроса.
  acceptAllUserEvents?: (onOk: any)=> void,
  showPointInfo: any,                  //Метод открытия окна с информацией о точке учёта.
  gotoPage: any,                        //Метод перехода на страницу SPA.
  scrollY: number                   //Размер вертикального скрола таблицы.
}

export function ConnectionStatistics(props: IConnnectionStatisticsProps) {

  const columns = [
    {
      title: 'Событие',
      dataIndex: 'caption',
      key: 'caption',
      width: '20%',
    },
    {
      title: 'Рекомендации',
      dataIndex: 'description',
      key: 'description',
      width: '45%',
    },
    {
      title: 'Количество',
      dataIndex: 'count',
      width: '15%',
      key: 'count',
    },
    {
      title: '',
      width: '20%',
      key: 'expand',
      render: (text: any, record: any) => 
        <a>показать</a>,
},
  ];

  const data: any = [];
  const [isAccepted, accept] = useState(false);

  props.statistics.connectionProblems.map((p:any)=>{
    let description;
    if(p.link != null){
      description = <a href={p.link}  target="_blank">{p.description}</a>;
    }
    else if(p.locallink != null){
      description = <a onClick={()=>{props.gotoPage(p.locallink)}}>{p.description}</a>;
    }
    else{
      description = <span>{p.description}</span>;

    }
      data.push({
      key: p.status,
      caption: p.caption,
      description: description,
      count: p.points.length,
      points: p.points
    });
});

  const alarmMessage =  "Внимание! У Вас есть непросмотренные сообщения (" + props.statistics.newEventsCount + ") на странице Контроль.";
  
  return (
    <>
      {
        props.statistics.newEventsCount > 0 && !isAccepted &&
        <>
          <a style={{color:"red", marginRight: 20}} onClick={()=>{props.gotoPage('/Control')}}>{alarmMessage}</a>
          {
            props.statistics.connectionProblems.length > 0 &&
            <Tooltip placement="bottomRight" title={'Квитировать все сообщения без просмотра!'}>
              <Button type="primary"  className={styles.headerbutton} icon={<CheckOutlined/>} onClick={()=>{
                if(props.acceptAllUserEvents){
                  props.acceptAllUserEvents(()=>{accept(true);});
                } 
              }
                }>Квитировать</Button>
            </Tooltip>
          }
        </>
      }
      {
        props.statistics.connectionProblems.length > 0 &&
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{ y: props.scrollY }}
          expandRowByClick = {true}
          expandable={{

            expandedRowRender: record => 
              <PointsTable
                showPointInfo = {props.showPointInfo}
                points={record.points}
              />,
            rowExpandable: (record) => record.points.length,
            // expandedRowKeys: expandedRows
            }}
            bordered
        />
      }
    </>
  );
}

//export const ConnectionStatistics = () => <TreeData />
