import { PureComponent, createRef } from "react"
import { DraggableModal } from 'ant-design-draggable-modal';
import { Divider, Layout, Row, Col, Table, Modal,Input, Select, Button } from 'antd';
import ReactResizeDetector from 'react-resize-detector';
import { VirtualTable } from "./../VirtualTable"
import { AlignType, FixedType } from 'rc-table/lib/interface';
import type { SelectProps } from 'antd';
import moment from 'moment';

import { Point, Resource,
    POINT_NAME, POINT_NUMBER, POINT_ADDRESS, POINT_COMMENT, POINT_RESOURCENAME,
    POINT_CONNECTIONTYPEID, POINT_CONNECTIONTYPE, POINT_IPADDRESS, POINT_TCPPORT,
    POINT_DEVICETYPE, POINT_DEVICETYPEID, POINT_SERIALNUMBER, POINT_SIGNALLEVEL, POINT_COMMDEVTYPEID, POINT_REMOTEACCESSDATE
} from "../../decl";
import { message } from '../../utils/Common';
import Resources from '../Resources';
import * as Const from '../../utils/Const';
import { sendRequestToBackend } from '../../utils/AuthUtils';
import pageStyles from '../../resources/Page.module.less';
import RangePickerLimit from '../Helper/RangePickerLimit';
import styles from '../../resources/Page.module.less';
import { SConnectionTypeDTO, SDeviceTypeDTO } from "../../store/AdminStore";
import { Add } from "@material-ui/icons";

const { Option } = Select;


type PointSelectTableProps = {
    visible: boolean,
    deviceTypes?: SDeviceTypeDTO[]
    connectionTypes?: { [key: number]: SConnectionTypeDTO }
    commDevTypes?: { [key: number]: string }
    onHide: () => void,
    onSelect: (point: Point | undefined)=>void
    onEdit: (point: Point) => void
}
type PointSelectTableState = {
    point: Point | undefined,
    scrollTableWidth: number,
    scrollTableHeight: number,
    allPoints: Point[]
    isReadyTable: boolean,
    filter: string,
    startDate: Date,
    endDate: Date,
    deviceTypeIds: string[],
    connectionTypeIds: string[],
    commDevTypeIds: string[],
}

export default class PointSelectTable extends PureComponent<PointSelectTableProps, PointSelectTableState> {

    constructor(props: PointSelectTableProps) {
        super(props);

        this.state = {
            point: undefined,
            scrollTableWidth: 500,
            scrollTableHeight: 400,
            allPoints: [],
            isReadyTable: false,
            filter: '',
            startDate: new Date(2007, 0, 1),// moment().add(-6400, 'days').toDate(),
            endDate: new Date(),
            deviceTypeIds: [],
            connectionTypeIds: [],
            commDevTypeIds: [],
        };

    }

    componentDidUpdate(prevProps: PointSelectTableProps) {
        if (this.props.visible && !prevProps.visible) {
            this.reloadCache();
        }
    }

    reloadCache = () => {
        this.setState({ allPoints: [], isReadyTable: false });
        sendRequestToBackend(
            {},
            'points/allextpoints',
            (response: any) => {
                if (response != null) {
                    this.setState({ allPoints: response });
                } else {
                    message.warning("Не удалить получить все точки учёта!");
                }
            },
            (isLoading: boolean) => {
                this.setState({ isReadyTable: !isLoading })
            },
            (error: any) => {
                message.warning("Ошибка при получении всех точек учёта.");
                console.log(error);
            }
        );
    }

    createData = () => {
        let result: any;
        if (this.state.isReadyTable) {
            result = this.state.allPoints.filter(
                (p: Point) =>
                {
                    const name = p.persistProperties[POINT_NAME];
                    const address = p.persistProperties[POINT_ADDRESS];
                    const comment = p.persistProperties[POINT_COMMENT];
                    const serialNumber = p.persistProperties[POINT_SERIALNUMBER];

                    const filter = this.state.filter.toLowerCase();
                    const date: Date | undefined = p.persistProperties[POINT_REMOTEACCESSDATE] ? new Date(p.persistProperties[POINT_REMOTEACCESSDATE]) : undefined;
                    const result = (filter === '' ||
                        (p.number && p.number.toLowerCase().includes(filter)) ||
                        (name && name.toLowerCase().includes(filter)) ||
                        (address && address.toLowerCase().includes(filter)) ||
                        (comment && comment.toLowerCase().includes(filter)) ||
                        (serialNumber && serialNumber.toLowerCase().includes(filter)))
                        && (date === undefined || date >= this.state.startDate)
                        && (date === undefined || date <= this.state.endDate)
                        &&
                        (this.state.deviceTypeIds.length === 0 ||
                            p.persistProperties[POINT_DEVICETYPEID] === undefined ||
                            this.state.deviceTypeIds.includes(p.persistProperties[POINT_DEVICETYPEID].toString()))
                        &&
                        (this.state.connectionTypeIds.length === 0 ||
                            p.persistProperties[POINT_CONNECTIONTYPEID] === undefined ||
                            this.state.connectionTypeIds.includes(p.persistProperties[POINT_CONNECTIONTYPEID].toString()))
                        &&
                        (this.state.commDevTypeIds.length === 0 ||
                            p.persistProperties[POINT_COMMDEVTYPEID] === undefined ||
                            this.state.commDevTypeIds.includes(p.persistProperties[POINT_COMMDEVTYPEID].toString())); 
                    return result
                }).map(
                    (p: Point) => {
                return {
                    key: p.id,
                    point: p,
                    number: p.number,
                    name: p.persistProperties[POINT_NAME],
                    address: p.persistProperties[POINT_ADDRESS],
                    comment: p.persistProperties[POINT_COMMENT],
                    devicetype: p.persistProperties[POINT_DEVICETYPE],
                    connectiontype: (this.props.connectionTypes && p.persistProperties[POINT_CONNECTIONTYPEID]) ?
                        this.props.connectionTypes[Number(p.persistProperties[POINT_CONNECTIONTYPEID])].description :
                        '',
                    //connectiontype: p.persistProperties[POINT_DEVICETYPE],
                    serialnumber: p.persistProperties[POINT_SERIALNUMBER],
                    signallevel: p.persistProperties[POINT_SIGNALLEVEL],
                }
            });
        }
        else {
            result = [];
        }
        return result;
    }

    sorter = (a: any, b: any) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b);
    numberSorter = (a: any, b: any) => (isNaN(a) && isNaN(b) ? Number(a)-Number(b) : a - b);

    onResize = (width: number | undefined, height: number | undefined) => {
        if (width && height) {
            this.setState({ scrollTableHeight: height - 50 });
        }
    }

    onSelectPoint = (p: Point) => {
        this.setState({ point: p });
    }

    render = () => {
        const style: React.CSSProperties = { background: '#0092ff', padding: '8px 0' };

        const parentStyle: React.CSSProperties = {
            display: 'flex',
            flexDirection: 'column',
            height:'100%'
        };

        const headerStyle: React.CSSProperties = {
            textAlign: 'left',
            padding: 4,
            height: 80,
            lineHeight: '30px',
        };

        const contentStyle: React.CSSProperties = {
            textAlign: 'center',
            height:  '100%',// 'calc(100% - 64px)',
            minHeight: 120,
        };

        const dataSource: any = this.createData();

        const columns = [
            {
                title: 'N',
                key: 'nn',
                align: 'right' as AlignType,
                width: 50
            },
            {
                title: 'Номер ТУ',
                dataIndex: 'number',
                key: 'number',
                sorter: (a: any, b: any) => this.sorter(a.number, b.number),
                align: 'left' as AlignType,
                //fixed: 'left' as FixedType,
                width: 150
            },
            {
                title: 'Название',
                dataIndex: 'name',
                key: 'name',
                sorter: (a: any, b: any) => this.sorter(a.name, b.name),
                align: 'left' as AlignType,
                width: 150
            },
            {
                title: 'Адрес',
                dataIndex: 'address',
                key: 'address',
                sorter: (a: any, b: any) => this.sorter(a.address, b.address),
                width: 150
            },
            {
                title: 'Коммент.',
                dataIndex: 'comment',
                key: 'comment',
                sorter: (a: any, b: any) => this.sorter(a.comment, b.comment),
                ellipsis: true,
                align: 'left' as AlignType,
                width: 150
            },
            {
                title: 'Сигнал',
                dataIndex: 'signallevel',
                key: 'signallevel',
                sorter: (a: any, b: any) => this.numberSorter(a.signallevel, b.signallevel),
                width: 150
            },
            {
                title: 'Подключение',
                dataIndex: 'connectiontype',
                key: 'connectiontype',
                sorter: (a: any, b: any) => this.sorter(a.connectiontype, b.connectiontype),
                width: 150
            },
            {
                title: 'Прибор',
                dataIndex: 'devicetype',
                key: 'devicetype',
                sorter: (a: any, b: any) => this.sorter(a.devicetype, b.devicetype),
                width: 150
            },
            {
                title: 'Номер',
                dataIndex: 'serialnumber',
                key: 'serialnumber',
                sorter: (a: any, b: any) => this.sorter(a.serialnumber, b.serialnumber),
                width: 150
            },
        ];

        const options: SelectProps['options'] = [];
        for (let i = 10; i < 36; i++) {
            options.push({
                label: i.toString(36) + i,
                value: i.toString(36) + i,
            });
        }


        return <Modal
            open={this.props.visible}
            style={{ minWidth:1200 }}
            width={1300}
            centered
            onCancel={this.props.onHide}
            footer={[
                <Button key="close" onClick={this.props.onHide}>
                    Закрыть
                </Button>,
                <Button key="select" disabled={this.state.point === undefined} onClick={() => { this.props.onSelect(this.state.point); }}>
                    Выбрать
                </Button>,
                <Button key="edit" disabled={this.state.point === undefined} type="primary" onClick={() => {if(this.state.point) this.props.onEdit(this.state.point); }}>
                    Редактировать
                </Button>,
            ]}        >
            <div style={parentStyle }>
                <div style={headerStyle}>
                    <Row className={pageStyles.FormRowWrapper}>
                        <Col style={{ textAlign: 'right', paddingRight: 12 }} span={2}><span>Поиск</span>
                        </Col>
                        <Col span={4}>
                            <Input size='small' value={this.state.filter} onChange={(ev) => {
                                //console.log(ev.target.value);
                                this.setState({ filter: ev.target.value });
                            }} />
                        </Col>
                        <Col style={{ textAlign: 'right', paddingRight: 12 }} span={3}>
                            <span style={{ marginLeft: 4 }}>Подключен</span>
                        </Col>
                        <Col span={5}>
                            <RangePickerLimit
                                size="small"
                                value={[this.state.startDate, this.state.endDate]}
                                limitDays={5000}
                                onChange={(val) => { this.setState({ startDate: val?.[0] ?? new Date(), endDate: val?.[1] ?? new Date() }); }}
                            />
                        </Col>
                        <Col span={2} offset={1}>
                            <Button size='small' onClick={this.reloadCache} type='primary'> Обновить </Button>
                        </Col>
                        <Col style={{ textAlign: 'right', paddingRight: 12 }} span={2}><span>Выбрана ТУ: </span>
                        </Col>
                        <Col span={5}><span>{this.state.point ? this.state.point.number : ''} </span>
                        </Col>
                    </Row>
                    <Row className={pageStyles.FormRowWrapper}>
                        <Col style={{ textAlign: 'right', paddingRight: 12 }} span={2}>
                            <span>Тип прибора</span>
                        </Col>
                        <Col span={4}>
                        <Select
                            mode="multiple"
                                size="small"
                                style={{width:'100%'} }
                            allowClear
                            placeholder="Все типы приборов"
                                maxTagCount={1}
                                onChange={(value: string[]) => { this.setState({ deviceTypeIds: value }) }}
                        >
                                {
                                    this.props.deviceTypes &&
                                    this.props.deviceTypes.sort((a, b) => a.name.localeCompare(b.name)).map((item: SDeviceTypeDTO) => (
                                        <Option key={item.id.toString()} value={item.id.toString()}>{item.name}</Option>
                                ))
                            }
                        </Select>
                        </Col>
                        <Col style={{ textAlign: 'right', paddingRight: 12 }} span={3}>
                            <span style={{ marginLeft: 4 } }>Тип подключения</span>
                        </Col>
                        <Col span={5}>
                            <Select
                                mode="multiple"
                                size="small"
                                style={{ width: '100%' }}
                                allowClear
                                placeholder="Все типы подключения"
                                maxTagCount={1}
                                onChange={(value: string[]) => { this.setState({ connectionTypeIds: value }) }}
                            >
                            {
                                Object.keys(this.props.connectionTypes ?? {}).map(item => (
                                    <Option key={item} value={item}>{this.props.connectionTypes?.[Number(item)].description}</Option>
                                ))
                            }

                        </Select>
                        </Col>
                        <Col style={{ textAlign: 'right', paddingRight: 12 }} span={2}>
                            <span style={{ marginLeft: 4 }}>Тип модема</span>
                        </Col>
                        <Col span={5}>
                            <Select
                                mode="multiple"
                                size="small"
                                style={{ width: '100%' }}
                                allowClear
                                placeholder="Все модемы"
                                maxTagCount={1}
                                onChange={(value: string[]) => { this.setState({ commDevTypeIds: value }) }}
                            >
                                {
                                    Object.keys(this.props.commDevTypes ?? {}).map(item => (
                                        <Option key={item} value={item}>{this.props.commDevTypes?.[Number(item)]}</Option>
                                    ))
                                }
                            </Select>
                        </Col>
                        <Col  span={3}>
                        </Col>
                    </Row>
                </div>
                <div style={contentStyle}>
                    <VirtualTable
                        size={'small'}
                        dataSource={dataSource}
                        columns={columns}
                        scroll={{ y: this.state.scrollTableHeight, x: this.state.scrollTableWidth }}
                        bordered
                        selectedId={this.state.point?.id}
                        onSelect={(row: any) => this.onSelectPoint(row.point)}
                        onAction={(row: any) => {
                            this.onSelectPoint(row.point);
                            this.props.onEdit(row.point);
                        }}
                    />

                </div>
            </div>

        </Modal>
    }
}
