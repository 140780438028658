import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { message } from "../utils/Common";
import { SResponseDTO, ResponseStatusesEnum, MenuItemDesc, NotifyType, Resource, Group, Point, SUserProfileDTO, SUserDTO } from "../decl"
import { BaseProfile, CommonProfile, MapProfile, KadrProfile } from "../decl"
import { getHeaders, BACKEND_URL, sendRequestToBackend, fetchWithTimeout } from '../utils/AuthUtils';
import { getProperty } from '../utils/Common';
import { getGroupPath, getGroupsByPoints } from '../utils/TreeUtils';
import * as Const from '../utils/Const';
import { CloseSessionAction, OpenSessionAction } from './Page';
import * as PageStore from './Page';

const noUserName: string = '<НЕ ОПРЕДЕЛЁН>';


//-----------------------------------------------------
//STATE
export interface UserProfileState {
    //Флаг ожидания загрузки/сохранения профиля.
    isLoading: boolean,
    //Флаг изменения профиля.
    isChanged: boolean,
    //Пользователь.
    user: SUserDTO | undefined,
    // //Флаг аутентификации зарегистрированного пользователя.
    // isLogged: boolean,
    // //Идентификатор пользователя.
    // userKey: string | undefined,
    // //Имя пользователя.
    // userName: string,
    //Адрес электронной почты.
    email: string,
    //Дата изменения пароля.
    passwordDate: string,
    //Полное меню, доступное пользователю, может быть выбраны для использования не все.
    menu: MenuItemDesc[],
    //Все ресурсы, доступные пользователю, фильтры на страницах определяют часть из этого списка.
    resources: Resource[]
    //Все типы оповещений, доступные пользователю.
    notifyTypes: NotifyType[],
    //Права, доступные пользователю.
    rights: string[],
    //Дерево групп пользователя.
    userGroups: Group[],
    //Точки учёта по группам, подгружаются асинхронно.
    groupPoints: {[groupId: string] : Point[]},
    //Все точки учёта по идентификаторам.
    allPoints: {[pointId: string] : Point},
    //Главная точка учёта пользователя.
    defaultPoint: Point | null,
    //Профиль.
    profile: BaseProfile,
    //Флаг необходимости перезагрузки профиля с сервера.
    needReload: boolean
}

//--------------------------------------------------------------------------------------------------
// ACTIONS

interface RequestAllUserProfileAction {
    type: 'REQUEST_ALL_USER_PROFILE',
    reset: boolean,
    onLoad: any
}

interface ReceiveAllUserProfileAction {
    type: 'RECEIVE_ALL_USER_PROFILE',
    user: SUserDTO,
    email: string,
    passwordDate: string,
    menu: MenuItemDesc[],
    resources: Resource[]
    notifyTypes: NotifyType[],
    rights: string[],
    userGroups: Group[],
    groupPoints: {[groupId: string] : Point[]},
    allPoints: {[pointId: string] : Point},
    profile: BaseProfile;
}

interface SaveAllUserProfileAction {
    type: 'SAVE_ALL_USER_PROFILE'
}

interface SetNotIsLoggedAction {
    type: 'SET_NOT_IS_LOGGED'
}

interface RequestUserProfileAction {
    type: 'REQUEST_USER_PROFILE',
    groupName: string;
}

interface ReceiveUserProfileAction {
    type: 'RECEIVE_USER_PROFILE';
    groupName: string;
    properties: any;
}

interface SaveUserProfileAction {
    type: 'SAVE_USER_PROFILE',
    groupName: string;
}

interface ChangeProfileValueAction {
    type: 'CHANGE_PROFILE_VALUE',
    groupName: string,
    propertyName: string,
    value: any
}

interface ChangeProfileAction {
    type: 'CHANGE_PROFILE',
    groupName: string,
    properties: any,
    callback?: any,
    save?: boolean
}

interface RequestGroupPointsAction {
    type: 'REQUEST_GROUP_POINTS',
    groupId: string,
    resolve: any
}

interface ReceiveGroupPointsAction {
    type: 'RECEIVE_GROUP_POINTS',
    groupId: string,
    points: Point[]
}

interface RequestMultipleGroupPointsAction {
    type: 'REQUEST_MULTIPLE_GROUP_POINTS',
    groupIds: string[],
    resolve: any
}


interface ReceiveMultipleGroupPointsAction {
    type: 'RECEIVE_MULTIPLE_GROUP_POINTS',
    groupPoints: {[groupId: string] : Point[]}
}


interface RequestPointsAction {
    type: 'REQUEST_POINTS',
    pointIds: string[],
    resolve: any
}

interface ReceivePointsAction {
    type: 'RECEIVE_POINTS',
    points: Point[]
}

interface ChangePasswordAction {
    type: 'CHANGE_PASSWORD',
    passwordChangeDate: any
}

interface ChangeEmailAction {
    type: 'CHANGE_EMAIL',
    email: string
}

interface ChangeUserGroups {
    type: 'CHANGE_USERGROUPS',
    groups: Group[],
    reloadGroupIds?: (string | undefined)[]
}

interface SetNeedReloadProfile {
    type: 'SET_NEEDRELOAD_PROFILE'
}

type KnownAction = RequestAllUserProfileAction | ReceiveAllUserProfileAction | SaveAllUserProfileAction |
    RequestUserProfileAction | ReceiveUserProfileAction | SaveUserProfileAction | ChangeProfileValueAction | ChangeProfileAction | //ChangeEnterPageAction | ChangeLastPageAction |
    RequestGroupPointsAction | ReceiveGroupPointsAction | RequestMultipleGroupPointsAction | ReceiveMultipleGroupPointsAction | RequestPointsAction | ReceivePointsAction | ChangePasswordAction | ChangeEmailAction |CloseSessionAction |OpenSessionAction |SetNotIsLoggedAction |
    ChangeUserGroups | SetNeedReloadProfile;


const saveProfileGroup = (iGroupName: string, iGroupProperties: any, iCallback: any) => {
    const requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({ name: iGroupName, properties: iGroupProperties })
    };
    fetch(BACKEND_URL + 'profile/save', requestOptions)
        .then(response => response.json() as Promise<SResponseDTO>)
        .then(data => {
            if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                if(iCallback){
                    iCallback(true);
                }
            }
            else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                if(iCallback){
                    iCallback(false);
                }
                message.error(data.message);
            }
            else if(iCallback)
            {
                if(iCallback){
                    iCallback(false);
                }
            }
        })
        .catch(error => {
            if(iCallback){
                iCallback(false);
            }
        message.error('Ошибка:' + error);
        })
}


// ----------------
// ACTION CREATORS
export const actionCreators = {
    //Запрос профиля пользователя.
    requestAllUserProfile: (iReset: boolean, iOnLoad: any, iOnError?: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        //console.log('requestAllUserProfile');
        const appState = getState();
        if (appState && appState.userProfile) {
            sendRequestToBackend(
                {reset: iReset},
                 'profile/getall',
                 (response:  SUserProfileDTO)=>{
                    dispatch({
                        type: 'RECEIVE_ALL_USER_PROFILE',
                        user: response.user, email: response.email, passwordDate: response.passwordDate,
                        menu: response.menu, resources: response.resources, notifyTypes: response.notifyTypes, rights: response.rights, userGroups: response.userGroups, groupPoints: response.groupPoints, allPoints: response.allPoints, profile: response.profile
                    });
                    if(iOnLoad){
                        iOnLoad(response.user.isLogged, response.profile, response.menu, response.defaultPoint);
                    }
                    //if(response.isLogged){
                    //    dispatch({ type: 'OPEN_SESSION' })
                    //}
                 },
                PageStore.actionCreators.setIsLoading,
                iOnError,
                iOnError);
            dispatch({ type: 'REQUEST_ALL_USER_PROFILE', reset: iReset, onLoad: iOnLoad });
        }
    },
    
    saveAllUserProfile: (iOnDone: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.userProfile) {
            const profile = appState.userProfile.profile;
            const commonProfile: CommonProfile | undefined = appState.userProfile.profile.Common;
            if(commonProfile){
                commonProfile.CurrentPointId = appState.page.pointId;
            }
            const requestOptions = {
                method: 'POST',
                headers:  getHeaders(),
                body: JSON.stringify(appState.userProfile.profile)
            };
            fetch(BACKEND_URL + 'profile/saveall', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if(iOnDone) iOnDone();
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        //message.ok('Профиль сохранён!');
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        //message.error(data.message);
                    }
                    else {
                        //message.error('Ответ не получен.');
                    }
                })
                .catch(error => message.error('Ошибка:' + error));
            dispatch({ type: 'SAVE_ALL_USER_PROFILE' });
        }
    },

    requestUserProfile: (iGroupName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState && appState.userProfile) {
            let properties = Object.getOwnPropertyNames(appState.userProfile.profile);
            if (properties.indexOf(iGroupName) < 0) {
                message.error('Неверное название группы профиля: ' + iGroupName);
            }
            else {
                const requestOptions = {
                    method: 'POST',
                    headers:  getHeaders(),
                    body: JSON.stringify({ name: iGroupName })
                };
                fetch(BACKEND_URL + 'profile/get', requestOptions)
                    .then(response => response.json() as Promise<SResponseDTO>)
                    .then(data => {
                        if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                            dispatch({ type: 'CLOSE_SESSION', message: data.message })
                        }
                        else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                            dispatch({ type: 'RECEIVE_USER_PROFILE', groupName: iGroupName, properties: data.body });
                        }
                        else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                            message.error(data.message);
                        }
                        else {
                            message.error('Ответ не получен.');
                        }
                    })
                    .catch(error => {
                        message.error('Ошибка:' + error);
                        console.log(error);
                    });

                dispatch({ type: 'REQUEST_USER_PROFILE', groupName: iGroupName });
            }
        }
    },

    saveUserProfile: (iGroupName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.userProfile) {
            const groupProperties: any = appState.userProfile.profile[iGroupName as keyof BaseProfile];
            if (groupProperties != undefined) {
                const requestOptions = {
                    method: 'POST',
                    headers:  getHeaders(),
                    body: JSON.stringify({ name: iGroupName, properties: groupProperties })
                };
                fetch(BACKEND_URL + 'profile/save', requestOptions)
                    .then(response => response.json() as Promise<SResponseDTO>)
                    .then(data => {
                        if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                            dispatch({ type: 'CLOSE_SESSION', message: data.message })
                        }
                        else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                            //message.ok('Профиль сохранён!');
                        }
                        else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                            message.error(data.message);
                        }
                        else {
                            message.error('Ответ не получен.');
                        }
                    })
                    .catch(error => message.error('Ошибка:' + error));
            }
            dispatch({ type: 'SAVE_USER_PROFILE', groupName: iGroupName });
        }
    },

    setNotIsLogged: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.userProfile) {
            dispatch({ type: 'SET_NOT_IS_LOGGED'});
        }
    },

    changeProfileValue: (iGroupName: string, iPropertyName: string, iValue: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.userProfile) {
            let properties = Object.getOwnPropertyNames(appState.userProfile.profile);
            if (properties.indexOf(iGroupName) < 0) {
                message.error('Неверное название группы профиля: ' + iGroupName);
            }
            else {
                dispatch({ type: 'CHANGE_PROFILE_VALUE', groupName: iGroupName, propertyName: iPropertyName, value: iValue });
            }
        }
    },

    changeProfile: (iGroupName: string, iProperties: any, iCallback?: any, iSave?: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.userProfile) {
            let properties = Object.getOwnPropertyNames(appState.userProfile.profile);
            if (properties.indexOf(iGroupName) < 0) {
                message.error('Неверное название группы профиля: ' + iGroupName);
            }
            else {
                dispatch({ type: 'CHANGE_PROFILE', groupName: iGroupName, properties: iProperties, callback: iCallback, save: iSave });
            }
        }
    },

    changeLastPage: (iPage: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.userProfile && appState.userProfile.user?.isLogged &&
            appState.userProfile.profile && appState.userProfile.profile.Common) {
            dispatch({ type: 'CHANGE_PROFILE_VALUE', groupName: Const.PROFILE_COMMON, propertyName: 'LastPage', value: iPage });
        }
    },

    changeEnterPage: (iPage: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.userProfile && appState.userProfile.user?.isLogged &&
            appState.userProfile.profile && appState.userProfile.profile.Common) {
            dispatch({ type: 'CHANGE_PROFILE_VALUE', groupName: Const.PROFILE_COMMON, propertyName: 'EnterPage', value: iPage });
        }
    },

    setNeedReloadProfile: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.userProfile && appState.userProfile.user?.isLogged &&
            appState.userProfile.profile && appState.userProfile.profile.Common) {
            dispatch({ type: 'SET_NEEDRELOAD_PROFILE'});
        }
    },

    requestGroupPoints: (iGroupId: string, resolve: any, reset:Boolean | undefined): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState && appState.userProfile) {
            //console.log('requestGroupPoint', iGroupId);
            let properties = Object.getOwnPropertyNames(appState.userProfile.groupPoints);
            //console.log('requestGroupPoints', properties);
            if (reset || properties.indexOf(iGroupId) < 0) {
                //console.log('requestGroupPoints', iGroupId);
                const requestOptions = {
                    method: 'POST',
                    headers:  getHeaders(),
                    body: JSON.stringify(iGroupId)
                };
                fetch(BACKEND_URL + 'points/grouppoints', requestOptions)
                    .then(response => response.json() as Promise<SResponseDTO>)
                    .then(data => {
                        if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                            resolve();
                            dispatch({ type: 'CLOSE_SESSION', message: data.message })
                        }
                        else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                            dispatch({ type: 'RECEIVE_GROUP_POINTS', groupId: iGroupId, points: data.body });
                            dispatch({ type: 'RECEIVE_POINTS', points: data.body });
                            //console.log('resolve', data.body);
                            resolve(data.body);
                        }
                        else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                            message.error(data.message);
                            resolve();
                        }
                        else {
                            message.error('Ответ не получен.');
                            resolve();
                        }
                    })
                    .catch(error => {
                        message.error('Ошибка:' + error);
                        console.log(error);
                        resolve();
                    });

                dispatch({ type: 'REQUEST_GROUP_POINTS', groupId: iGroupId, resolve: resolve });
            }
            else{
                resolve(appState.userProfile.groupPoints[iGroupId]);
            }
        }
        else {
            resolve();
        }
    },

    requestMultipleGroupPoints: (iGroupIds: string[], resolve: any, reset:Boolean | undefined): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState && appState.userProfile) {
            const properties = Object.getOwnPropertyNames(appState.userProfile.groupPoints);
            const notExistIds: string[] = iGroupIds.filter(id=>reset || properties.indexOf(id) < 0);
            const existIds: string[] =  iGroupIds.filter(id=>!reset && properties.indexOf(id) >= 0);
            if (notExistIds.length > 0) {
                const requestOptions = {
                    method: 'POST',
                    headers:  getHeaders(),
                    body: JSON.stringify(notExistIds)
                };
                fetch(BACKEND_URL + 'points/xgrouppoints', requestOptions)
                    .then(response => response.json() as Promise<SResponseDTO>)
                    .then(data => {
                        if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                            resolve();
                            dispatch({ type: 'CLOSE_SESSION', message: data.message })
                        }
                        else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                            const groupPoints: {[groupId: string] : Point[]} = data.body
                            dispatch({ type: 'RECEIVE_MULTIPLE_GROUP_POINTS', groupPoints: groupPoints });
                            const allPoints: Point[] = [];
                            Object.values(groupPoints).forEach(points => {
                                allPoints.push(...points);
                            });
                            dispatch({ type: 'RECEIVE_POINTS', points: allPoints });
                            resolve(notExistIds);
                        }
                        else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                            message.error(data.message);
                            resolve();
                        }
                        else {
                            message.error('Ответ не получен.');
                            resolve();
                        }
                    })
                    .catch(error => {
                        message.error('Ошибка:' + error);
                        console.log(error);
                        resolve();
                    });

                dispatch({ type: 'REQUEST_MULTIPLE_GROUP_POINTS', groupIds: iGroupIds, resolve: resolve });
            }
            if(existIds.length > 0){
                resolve(existIds);
            }
        }
        else {
            resolve();
        }
    },

    requestPoints: (iPointIds: string[], resolve: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.userProfile) {
            const ids = Object.getOwnPropertyNames(appState.userProfile.allPoints);
            const needLoadingIds: string[] = iPointIds.filter(id=>ids.indexOf(id) < 0);

            if (needLoadingIds.length > 0) {
                const requestOptions = {
                    method: 'POST',
                    headers:  getHeaders(),
                    body: JSON.stringify(needLoadingIds)
                };
                fetch(BACKEND_URL + 'points/pointsbyids', requestOptions)
                    .then(response => response.json() as Promise<SResponseDTO>)
                    .then(data => {
                        if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                            if(resolve) resolve();
                            dispatch({ type: 'CLOSE_SESSION', message: data.message })
                        }
                        else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                            dispatch({ type: 'RECEIVE_POINTS', points: data.body });
                            if(resolve) resolve(data.body);
                        }
                        else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                            message.error(data.message);
                            if(resolve) resolve();
                        }
                        else {
                            message.error('Ответ не получен.');
                            if(resolve) resolve();
                        }
                    })
                    .catch(error => {
                        message.error('Ошибка:' + error);
                        console.log(error);
                        if(resolve) resolve();
                    });

                dispatch({ type: 'REQUEST_POINTS', pointIds: iPointIds, resolve: resolve });
            }
            else if(resolve){
                const points: Point[] = [];
                for(let id of iPointIds){
                    points.push(appState.userProfile.allPoints[id]);
                }
                resolve(points);
            }
        }
        else {
            resolve();
        }
    },
    
    changeEmail: (email: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.userProfile) {
            const requestOptions = {
                method: 'POST',
                headers:  getHeaders(),
                body: JSON.stringify(email)
            };
            fetch(BACKEND_URL + 'profile/email', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        //message.ok('Почта изменена!');
                        dispatch({ type: 'CHANGE_EMAIL', email: email });
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                    }
                    else {
                        message.error('Ошибка при изменении адреса почты.');
                    }
                })
                .catch(error => message.error('Ошибка:' + error));
        }
    },
    changePassword: (password: string, newPassword: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.userProfile) {
            const requestOptions = {
                method: 'POST',
                headers:  getHeaders(),
                body: JSON.stringify({user: '', password: password, newPassword: newPassword })
            };
            fetch(BACKEND_URL + 'users/password', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        //message.ok('Почта изменена!');
                        const date: any = data.body;
                        dispatch({ type: 'CHANGE_PASSWORD', passwordChangeDate: date });
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                    }
                    else {
                        message.error('Ошибка при изменении пароля.');
                    }
                })
                .catch(error => message.error('Ошибка:' + error));
        }
    },

};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: UserProfileState = {
    isLoading: false,
    isChanged: false,
    user: undefined,
    email: '',
    passwordDate: '',
    menu: [],
    resources: [],
    notifyTypes: [],
    userGroups: [],
    rights: [],
    groupPoints: {},
    allPoints: {},
    defaultPoint: null,
    profile: {
        Common: undefined,
        Map: undefined,
        Kadr: undefined,
        Mnemoschema: undefined,
        Reports: undefined,
        Chart: undefined,
        DeviceLog: undefined,
        Sensors: undefined,
        MonthlyReports: undefined,
        Admin: undefined
    },
    needReload: false
}

export const reducer: Reducer<UserProfileState> = (state: UserProfileState | undefined, incomingAction: Action): UserProfileState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    let newProfile: BaseProfile = { ...state.profile };
    switch (action.type) {
        case 'REQUEST_ALL_USER_PROFILE':
            return {
                ...state,
                isLoading: true
            };
        case 'RECEIVE_ALL_USER_PROFILE':
            //console.log('RECEIVE ALL USER PROFILE', action.groupPoints);
            return {
                ...state,
                isLoading: false,
                isChanged: false,
                user: action.user,
                email: action.email,
                passwordDate: action.passwordDate,
                menu: action.menu,
                resources: action.resources,
                notifyTypes: action.notifyTypes,
                rights: action.rights,
                userGroups: action.userGroups,
                groupPoints: action.groupPoints,
                allPoints: action.allPoints,
                profile: action.profile,
                needReload: false
            };
        case 'REQUEST_USER_PROFILE':
            return {
                ...state,
                isLoading: true
            };
        case 'RECEIVE_USER_PROFILE':
            newProfile[action.groupName as keyof BaseProfile] = action.properties;
            return {
                ...state,
                isLoading: false,
                profile:  newProfile
            };
        case 'CHANGE_PROFILE_VALUE':
            {
                let groupProperties: any = newProfile[action.groupName as keyof BaseProfile];
                const oldValue: any = groupProperties[action.propertyName as keyof any];
                let isChanged: boolean = state.isChanged;
                if(oldValue !== action.value){
                    const newGroupProperties = {...groupProperties};
                    newGroupProperties[action.propertyName as keyof any] = action.value;
                    newProfile[action.groupName as keyof BaseProfile] = newGroupProperties; 
                    isChanged = true;
                }
                return {
                    ...state,
                    isLoading: false,
                    isChanged: isChanged,
                    profile: newProfile
                };
            }
        case 'CHANGE_PROFILE':
            {
                const groupProperties: any = newProfile[action.groupName as keyof BaseProfile];
                const newGroupProperties = { ...groupProperties, ...action.properties };
                newProfile[action.groupName as keyof BaseProfile] = newGroupProperties;
                if (action.save) {
                    saveProfileGroup(action.groupName, newGroupProperties, action.callback);
                }
                return {
                    ...state,
                    isLoading: false,
                    isChanged: true,
                    profile: newProfile
                };
            }
        case 'SAVE_ALL_USER_PROFILE':
            return {
                ...state,
                isChanged: false
            };
        case 'SET_NOT_IS_LOGGED':
            return {
                ...state,
                user: { user: state.user?.user, userKey: state.user?.userKey, isLogged:  false}
            };
        case 'REQUEST_GROUP_POINTS':
            return {
                ...state,
                isLoading: true
            };
        case 'RECEIVE_GROUP_POINTS':
            {
                let newGroupPoints: {[groupId: string]:Point[]} = {...state.groupPoints};
                newGroupPoints[action.groupId] = action.points;
                //console.log('ReceiveGroupPoints', Object.keys(newGroupPoints));
                //console.log('receivegrouppoints', newGroupPoints);
                return {
                    ...state,
                    isLoading: false,
                    groupPoints:  newGroupPoints
                };
            }
        case 'REQUEST_MULTIPLE_GROUP_POINTS':
            return {
                ...state,
                isLoading: true
            };
        case 'RECEIVE_MULTIPLE_GROUP_POINTS':
            {
                let newGroupPoints: {[groupId: string]:Point[]} = {...state.groupPoints};
                Object.keys(action.groupPoints).forEach(id=>newGroupPoints[id] = action.groupPoints[id]);
                return {
                    ...state,
                    isLoading: false,
                    groupPoints:  newGroupPoints
                };
            }
        case 'REQUEST_POINTS':
        return {
            ...state,
            isLoading: true
        };
        case 'RECEIVE_POINTS':
            let newPoints: {[groupId: string]:Point} = {...state.allPoints};
            for(let point of action.points){
                newPoints[point.id] = point;
            }
            return {
                ...state,
                isLoading: false,
                allPoints:  newPoints
            };
        case 'CHANGE_USERGROUPS':
            {
                let groupPoints;
                if(action.reloadGroupIds){
                    groupPoints = {...state.groupPoints};
                    for(let id of action.reloadGroupIds){
                        if(id){
                            delete groupPoints[id];
                        }
                    }
                }
                else{
                    groupPoints = {};
                }
                return {
                    ...state,
                    userGroups:  action.groups,
                    groupPoints: groupPoints
                };
            }
        case 'CHANGE_PASSWORD':
        return {
            ...state,
            passwordDate: action.passwordChangeDate
        };
        case 'CHANGE_EMAIL':
            return {
                ...state,
                email: action.email
            };

        case 'SET_NEEDRELOAD_PROFILE':
            return {
                ...state,
                needReload: true
            };
        }

    return state;
};



