import * as React from "react";
import {Checkbox, Row} from "antd";
import moment from 'moment';
import {CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, TimeScale, TimeSeriesScale, Title, Tooltip} from 'chart.js';
import {Line} from 'react-chartjs-2';
import 'chartjs-adapter-moment';
import hexRgb from "hex-rgb";

import {PeriodsEnum, SChart, SChartParameter, SDataRecord, SPointDataRequest, SPointInfoRequest} from "../../decl";
import {downloadFile, sendRequestToBackend} from "../../utils/AuthUtils";
import { message } from "../../utils/Common";

import {arrayHash, datesActions, datesForServer, defaultOptions, deleteInArray, IChartDataPoint, IChartParameter, IChartScale, IChartsContainerProps, IChartsContainerState, IDatasetsItem, ILineData, logStyle, setScaleXminMax, ShowParams, TrendsRefreshTime} from "./ChartsUtils";

import chartsStyles from "../../resources/Charts.module.less";
import ChartsHeader from "./ChartsHeader";
import ChartsArea from "./ChartsArea";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  // TimeUnit,
  TimeSeriesScale
  // myPlugin
);


/**
 * Структура данных
 * http://62.213.110.98:8080/secure/attachment/25347/25347_%D0%93%D1%80%D0%B0%D1%84%D0%B8%D0%BA%D0%B8+BACKEND.txt
 *
 */

class ChartsContainer extends React.PureComponent <IChartsContainerProps, IChartsContainerState> {
  private trendsInterval: any;

  private ChartsContainerVersion = '22.05.24';

  constructor(props: IChartsContainerProps) {
	super(props);


	const {dates} = datesActions(this.props.period, moment());
	// console.log('constructor >>> ChartsContainer period', this.props.period);
	// console.dir(Object.keys(props));

	this.state = {
	  period: this.props.period,
	  pointId: this.props.pointId,

	  needRefresh: this.props.needRefresh,

	  fullscreen: false,
	  dates: dates,
	  // dates: [
	  // moment('2022-03-18 09:20:00'),
	  // moment('2022-03-18 18:20:00'),
	  // ],
	  showParams: [
		// ShowParams.PDZ,
		// ShowParams.TG,
		// ShowParams.TRENDS,
		ShowParams.ALL
	  ],
	  disabledShowParams: [],

	  data: null,
	  //все параметры графика - например t1 t2 G1 ...
	  parameters: [],
	  //выбранные (отмеченные галкой параметры)
	  selectedParameters: [],
	  lastSelectedParameter: '',
	  chartOptions: null,
	  parametersNext: [],
	  selectedParametersNext: [],
	  chartOptionsNext: null,

	  doExport: 0,

	  mainTitle: '',
	  redrawArea: 0,
	  trendsIsRunned: false,
	  getDataParams: {
		//должы совпадать с теми что выше
		period: this.props.period,
		pointId: this.props.pointId,
		dates: dates
	  }
	}
	// console.log('charts constructor', this.props.pointId);
	this.setFullscreen = this.setFullscreen.bind(this);
	this.setDates = this.setDates.bind(this);
	this.setShowParam = this.setShowParam.bind(this);


	this.getTuData = this.getTuData.bind(this);
	this.getData = this.getData.bind(this);
	this.doGetData = this.doGetData.bind(this);


	this.paramsBar = this.paramsBar.bind(this);
	this.hideCharts = this.hideCharts.bind(this);
	this.renderChartAllInOne = this.renderChartAllInOne.bind(this);
	this.exportCharts = this.exportCharts.bind(this);

	this.trendsInterval = null;
	this.stopTrends = this.stopTrends.bind(this);
	this.runTrends = this.runTrends.bind(this);
  }

  setFullscreen() {
	this.setState({
	  ...this.state,
	  fullscreen: !this.state.fullscreen
	});
  }

  setDates(dates: any, fromDatesSelect: boolean = false) {
	// console.log('charts setDates',
	//   dates.length ? dates[0].format('DD.MM.YYYY HH:mm') : 'empty',
	//   dates.length ? dates[1].format('DD.MM.YYYY HH:mm') : 'empty',
	//   'fromDatesSelect=' + fromDatesSelect
	// );
	this.props.setChartDates(dates);


	if (fromDatesSelect) {
	  // console.log('%cChange datyes and getdata', logStyle.h4, dates);
	  this.getData(0, null, null, dates);
	} else
	  this.setState({
		...this.state,
		dates: dates
	  });


  }

  setShowParam(params: ShowParams[], clickedParamId: ShowParams | null = null) {
	//console.log('charts setShowParam', params, clickedParamId);
	let trendsIsRunned = false;
	if (clickedParamId === ShowParams.TRENDS) {
	  //вкл. тренды
	  if (params.includes(ShowParams.TRENDS) && [PeriodsEnum.current, PeriodsEnum.hour].includes(this.state.period)) {
		trendsIsRunned = true;
		this.runTrends(this.state.period);
	  }
	  //выкл.
	  else {
		this.stopTrends();
	  }
	}

	this.setState({
	  ...this.state,
	  showParams: params,
	  redrawArea: Date.now(),
	  // trendsIsRunned: trendsIsRunned
	});
  }

  // setSelectedParams(id: string) {
  // // console.log('charts setSelectedParams', id);
  // this.setState({
  //   ...this.state,
  //   selectedParameters: params
  // });
  // }


  getTuData(pointId: string = '', period: PeriodsEnum = PeriodsEnum.current, onDataReceived: any = null) {
	// console.clear();
	// console.log('%cgetTuData start', logStyle.h2);
	// console.log('getTuData func params:', 'period=' + period, 'pointId=' + pointId);
	// console.log(this.state.period, this.state.pointId);
	// console.log(pointId, period);
	// console.dir(this.state.getDataParams.period,  this.state.getDataParams.pointId);

	if (pointId == '') {
	  // message.warn('Не выбрана ТУ', 1);
	  return false;
	}

	const chartRequest: SPointInfoRequest = {
	  pointId: pointId,
	  period: period
	};

	sendRequestToBackend(
	  chartRequest,
	  'points/pointchart',
	  (response: SChart) => {

		// console.log('%cRS-290 ресурсы ТУ', 'color: blue');
		// console.dir(response.parameters);
		/**/
		if (response) {
		  let optionsAll: any,
			optionsParams: any,
			paramsById: any,
			_selectedParams: string[],
			hasTempCharts: boolean,
			hasPdz: boolean;

		  let chartOptions: any = setChartOptions(
			this.state.showParams,
			this.state.period,
			this.state.dates,
			// this.state.getDataParams.period,
			// this.state.getDataParams.dates,
			response,
			this.state
		  );

		  // console.dir(chartOptions);

		  if (chartOptions === null) {
			message.warn('Нет параметров/единиц измерения для точки учета');
			return;
		  } else {

			if (chartOptions[0])
			  optionsAll = chartOptions[0];
			if (chartOptions[1])
			  optionsParams = chartOptions[1];
			if (chartOptions[2])
			  paramsById = chartOptions[2];

			_selectedParams = chartOptions[3] ?? [];
			hasTempCharts = chartOptions[4] ?? false;
			hasPdz = chartOptions[5] ?? false;

			//console.log('%cgetTuData chartRequest', logStyle.h3);
			//console.log('periodId=' + chartRequest.period, 'pointId=' + chartRequest.pointId);
			//console.log('%cgetTuData result params ids', logStyle.h4, Object.keys(optionsParams));

			// const paramsIds = Object.keys(optionsParams);

			let disabledShowParams: ShowParams[] = [];

			if (!hasTempCharts && !disabledShowParams.includes(ShowParams.TG))
			  disabledShowParams.push(ShowParams.TG);
			if (!hasPdz && !disabledShowParams.includes(ShowParams.PDZ))
			  disabledShowParams.push(ShowParams.PDZ);

			this.setState({
			  ...this.state,
			  parameters: paramsById,
			  disabledShowParams: disabledShowParams,
			  selectedParameters: _selectedParams,
			  chartOptions: {
				optionsAll: optionsAll,
				optionsParams: optionsParams
			  }
			});
			if (onDataReceived != null)
			  onDataReceived();
		  }
		} else
		  message.warn('Нет ответа от ТУ', 2);
		/**/
	  },
		null,//this.props.setIsLoading
		null,
	  this.props.closeSession);

  }

  /**
   * Если refreshTime== 0 то это при входе в раздел
   */
  getData(refreshTime: number, _period?: PeriodsEnum | null, _pointId?: string | null, _dates?: any[] | null) {
	// if (refreshTime)
	// console.clear();
	// const showThisLog : boolean = false;

	// console.log('%cget Charts Data at ' + moment().format('HH:mm:ss'), logStyle.h1, this.ChartsContainerVersion);
	// console.log('periodId=' + _period,
	//   'pointId=' + _pointId,
	//   'trendsInterval=' + this.trendsInterval);

	// clearInterval(this.trendsInterval);
	// this.trendsInterval = null;
	this.stopTrends();
	// console.log('trendsInterval after clear=' + this.trendsInterval);

	if (!this.state.dates || this.state.dates.length === 0) {
	  message.warn('Задайте диапазон дат', 2);
	  return;
	}

	if (this.state.pointId == '') {
	  message.warn('Выберите точку учета', 2);
	  return;
	}


	let period = _period ?? this.state.period;
	let pointId = _pointId ?? this.state.pointId;
	let dates = _dates ?? this.state.dates;

	let _showParams: ShowParams[] = [...this.state.showParams];

	_showParams = deleteInArray(_showParams, ShowParams.TG);

	if (period == PeriodsEnum.day) {
	//   console.log('getData  remove trends');
	  _showParams = deleteInArray(_showParams, ShowParams.TRENDS);
	}


	let newState: IChartsContainerState = {
	  ...this.state,
	  parameters: [],
	  pointId: pointId,
	  period: period,
	  getDataParams: {
		pointId: pointId,
		period: period,
		dates: dates
	  },
	  trendsIsRunned: false,
	  // redrawArea: Date.now(),
	  showParams: _showParams,
	  lastSelectedParameter: '',
	  selectedParameters: []
	};
	if (_dates)
	  newState.dates = _dates;


	// console.log('this.state.period >', this.state.period, _period);
	// await this.getTuData(this.state.pointId, this.state.period);
	// console.log(
	//   'newState.getDataParams.period=' + newState.getDataParams.period,
	//   'newState.getDataParams.pointId=' + newState.getDataParams.pointId
	// );


	const self = this;

	this.getTuData(
	  newState.getDataParams.pointId,
	  newState.getDataParams.period,
	  () => {

		//тут не объеденять в 1 условие
		if (newState.getDataParams.period !== PeriodsEnum.day
		  && _showParams.includes(ShowParams.TRENDS)
		  //&& refreshTime === 0
		) {
		  //console.log('%c can runTrends', logStyle.h3, newState.trendsIsRunned, this.trendsInterval);
		  if (newState.trendsIsRunned === false) {
			//console.log('%c before runTrends', logStyle.h3);
			this.runTrends(newState.getDataParams.period);
		  }
		} else
		  self.doGetData(true, refreshTime, newState.getDataParams.period, newState.getDataParams.pointId);
	  }
	);

	this.setState(newState);
  }

  stopTrends = () => {
	// console.log('%cStop trends', logStyle.h3);
    clearInterval(this.trendsInterval);
	this.trendsInterval = null;

	this.setState({
	  ...this.state,
	  trendsIsRunned: false
	});
  }

  runTrends(period: PeriodsEnum) {


    const setTrendsDates = () => {
	  const {dates} = datesActions(
		period,
		moment(),
		this.state.dates[0]
		//null//дата ОТ всегда считает от текущий минус 8ч this.state.getDataParams.dates[0]
	  );
	  //console.log(this.state.dates[0]);

	  this.setState({
		...this.state,
		trendsIsRunned: true,
		dates: dates,
		getDataParams: {
		  ...this.state.getDataParams,
		  dates: dates
		}
	  });
	}

	// console.clear();
	const runTrendsInterval: number = period == PeriodsEnum.current
	  ? TrendsRefreshTime.current
	  : (
	    period == PeriodsEnum.hour
		  ? TrendsRefreshTime.hour
		  : TrendsRefreshTime.day
	  );
	console.log('%c  runTrends init=' + moment().format('mm:ss')
	  + '  period=' + period
	  + '  interval=' + runTrendsInterval, 'background: #000;color: #fff', this.ChartsContainerVersion);
	console.log(' > trendsInterval =', this.trendsInterval);


	setTrendsDates();

	this.doGetData(false, 0);

	if (this.trendsInterval === null) {
	  this.trendsInterval = setInterval(() => {
		console.log('%c          runTrends inside=' + moment().format('mm:ss') + '  interval=' + runTrendsInterval, logStyle.h4);
		console.log(' > trendsInterval=', this.trendsInterval, 'period=', this.state.getDataParams.period);

		setTrendsDates();

		this.doGetData(false, 0);


	  }, runTrendsInterval);

	}

  }

  doGetData(showMessages: boolean = true, refreshTime: number = 0, _period?: PeriodsEnum | null, _pointId?: string | null) {
	// console.clear();
	//DONE сделать проверку изменения дат для трендов, и потом снести блоки ниже до dataRequest
	// const {dateFrom, dateTo} = datesForServer(
	//   _period ?? this.state.getDataParams.period,
	//   this.state.getDataParams.dates[0],
	//   this.state.getDataParams.dates[1]
	// );

	const dataRequest: SPointDataRequest = {
	  // pointId: this.state.pointId,
	  // period: this.state.period,
	  pointId: _pointId ?? this.state.getDataParams.pointId,
	  period: _period ?? this.state.getDataParams.period,
	  // from: dateFrom,//this.state.dates[0],
	  // to: dateTo//this.state.dates[1]
	  from: this.state.dates[0],
	  to: this.state.dates[1]
	};

	let mainTitle: string = this.props.allPoints[dataRequest.pointId]?.number ?? 'undef';

	//console.log('%cdoGetData', logStyle.h2);
	//console.log('period=' + dataRequest.period + '\_period=' + _period, 'pointId=' + dataRequest.pointId, 'tu name=' + mainTitle);
	// console.log(
	//   '%c > dates: ', logStyle.h4,
	//   dateFrom.format('DD.MM.YYYY HH:mm:ss'),
	//   ' - ',
	//   dateTo.format('DD.MM.YYYY HH:mm:ss')
	// );
	//console.log('%c > dates in state', logStyle.h4,
	//  this.state.dates[0].format('DD.MM.YYYY HH:mm:ss'),
	//  ' - ',
	//  this.state.dates[1].format('DD.MM.YYYY HH:mm:ss')
	//);

	// return;
	sendRequestToBackend(
	  dataRequest,
	  'points/pointchartdata',
	  (response: SDataRecord[]) => {

		// console.log('tu data response:', response);


		if (response.length === 0) {
		  message.warn('Нет данных', 2);
		  // let newState: IChartsContainerState = {
			// ...this.state,
			// mainTitle: mainTitle,
			// redrawArea: Date.now()
		  // };
		  // // if (dataRequest.period == PeriodsEnum.day)
		  // {
			// newState.trendsIsRunned = false;
			// newState.showParams = deleteInArray(this.state.showParams, ShowParams.TRENDS);
		  // }
		  //
		  // this.setState(newState);
		  //
		  //
		  // return;
		}

		let datasets = setDatasets(
		  response,
		  this.state
		);

		const timeLineData: ILineData = {
		  datasets: datasets
		};
		// console.dir(datasets);

		this.setState({
		  ...this.state,
		  data: timeLineData,
		  mainTitle: mainTitle,
		  redrawArea: Date.now()
		});
	  },
		showMessages ? this.props.setIsLoading : null,
		null,
	  this.props.closeSession);


  }

  async componentDidMount() {
	// console.log('componentDidMount');

	this.props.setChartDates(this.state.dates);

	this.getData(0);
  }


  componentDidUpdate(prevProps: Readonly<IChartsContainerProps>, prevState: Readonly<IChartsContainerState>, snapshot?: any) {
	// console.clear();
	// console.log('charts componentDidUpdate');

	//смена ТУ
	if (this.props.pointId !== '' && this.props.pointId != prevProps.pointId) {
	  this.setState({
		...this.state,
		pointId: this.props.pointId
	  });
	  this.getData(0, null, this.props.pointId);
	}

	//смена периода
	if (this.props.period != prevProps.period) {
	  const {dates} = datesActions(
		this.props.period,//тут вопрос, может быть  при включенных трендах
		// this.state.getDataParams.period,
		moment()
	  );

	  this.setState({
		...this.state,
		period: this.props.period,
		dates: dates
	  });
	  this.props.setChartDates(dates);
	  this.getData(0, this.props.period, null, dates);
	}

	if (this.props.doExport && this.props.doExport !== prevProps.doExport) {
	  this.exportCharts();
	}


	//переключение параметров что отображать
	if (arrayHash(this.state.showParams) != arrayHash(prevState.showParams)) {
	  //только для общего графика
	  if (this.state.showParams.includes(ShowParams.ALL)) {
		if (this.state.showParams.includes(ShowParams.TG)) {
		  let tempParams: string[] = [];
		  for (let id in this.state.parameters) {
			if (this.state.parameters[id].etalonId !== undefined)
			  tempParams.push(id);
		  }

		  if (tempParams.length)
			this.setState({
			  ...this.state,
			  selectedParameters: tempParams,
			  redrawArea: Date.now()
			});
		}

	  }
	  // console.log('set trends', this.state.showParams.includes(ShowParams.TRENDS), this.state.trendsIsRunned);
	  // if (this.state.showParams.includes(ShowParams.TRENDS) && !this.state.trendsIsRunned) {
	  //   console.dir(this.state);
	  //   // this.runTrends();
	  // }
	}

	if (this.props.needRefresh !== prevProps.needRefresh) {
	  // console.log('this.state.period >', this.state.period);
	  // this.getData(0);
	  // this.setState({
	  // ...this.state,
	  // redrawArea: Date.now()
	  // });
	}

  }

  componentWillUnmount() {
	// if (this.trendsInterval)
	clearInterval(this.trendsInterval);

  }

  render() {

	let wrapStyle = chartsStyles.chartsContainer;
	wrapStyle += this.state.fullscreen ? ' ' + chartsStyles.chartsFullscreen : '';


	let content: any = [];

	if (this.state.data !== null
	  && typeof this.state.data?.datasets !== 'undefined'
	  && this.state.chartOptions !== null
	) {

	  if (this.state.showParams.includes(ShowParams.ALL)) {

		content.push(this.renderChartAllInOne());

	  } else {
		content = content.concat(this.renderSeparateCharts());
	  }
	}
	else
	  content.push(<ChartsEmpty key={"charts-empty"}/>);



	return (
	  <div className={wrapStyle}>
		<ChartsHeader
		  fullscreen={this.state.fullscreen}
		  changeFullscreen={this.setFullscreen}
		  dates={this.state.dates}
		  setDates={this.setDates}
		  showParams={this.state.showParams}
		  setShowParams={this.setShowParam}
		  disabledShowParams={this.state.disabledShowParams}
		  period={this.state.period}
		  doRefresh={this.getData}
		/>


		<ChartsArea
		  showParams={this.state.showParams}
		  title={this.state.mainTitle}
		  paramsBar={this.paramsBar()}
		  content={content}
		  redrawArea={this.state.redrawArea}
		/>

	  </div>
	);
  }

  paramsBar() {

	//включена опция темп. графики
	const tempChartsOn = this.state.showParams.includes(ShowParams.TG);
	const pdzOn = this.state.showParams.includes(ShowParams.PDZ);
	const isAllChart = this.state.showParams.includes(ShowParams.ALL);
	const parametersIds = Object.keys(this.state.parameters);

	// обработчик выбора параметров отображаемых на графике
	const onParamChecked = (checked: boolean, id: string, disabled: boolean, selfParam: any) => {

	  let selectedParams: string[] = [...this.state.selectedParameters];

	  let hasEtalon = (selfParam && selfParam.etalonId !== undefined);//имеет темп. график


	  if (id === 'all')
		selectedParams = checked ? parametersIds : [];
	  else {
		if (checked && !selectedParams.includes(id))
		  selectedParams.push(id);
		else if (selectedParams.length > 1)
		  selectedParams = deleteInArray(selectedParams, id);
	  }

	  if (selectedParams.length === 0)
		selectedParams.push(
		  //задаем посл выбранны параметр, если он существует у ТУ
		  parametersIds.includes(this.state.lastSelectedParameter)
			? this.state.lastSelectedParameter
			: parametersIds[0]
		);

	  this.setState({
		...this.state,
		selectedParameters: selectedParams,
		lastSelectedParameter: id != 'all'
		  ? id
		  : this.state.lastSelectedParameter,
		redrawArea: Date.now()
	  });
	  // this.props.setChartCheckedParameters(selectedParams);
	};

	const parameters = Object.values(this.state.parameters);
	// let tmpIds: any[] = [];
	// parameters.map((param: any) => {tmpIds.push(param.id)});
	// console.log('paramsBar', tmpIds);


	let content: any = [];

	let allDisabled: boolean = false;
	// if (tempChartsOn || pdzOn)
	//   allDisabled = true;

	if (parameters.length)
	  content.push(
		<Checkbox
		  key={"all"}
		  className={"charts-param-checkbox"}
		  onChange={e => {
			onParamChecked(
			  e.target.checked,
			  'all',
			  allDisabled,
			  null
			)
		  }}
		  checked={!allDisabled && parameters.length === this.state.selectedParameters.length}
		  disabled={allDisabled}
		>
		  Все
		</Checkbox>
	  );

	// console.log(this.state.selectedParameters);

	parameters.map((param: any) => {
	  let disabled: boolean = false;
	  let checked: boolean = this.state.selectedParameters.includes(param.id);

	  //общие графики
	  if (isAllChart) {
		//включены температурные гр-ки
		if (tempChartsOn) {
		  if (param.etalonId == undefined) {
			disabled = true;
			checked = false;
		  }
		}
		//включены температурные гр-ки
		if (pdzOn) {
		  // console.dir(param);

		}
	  }
	  //графики раздельно
	  else {

	  }
	  //&& tempChartsOn
	  // if (!isAllChart && tempChartsOn && disabled)
	  // checked = false;
	  //
	  // //&& tempChartsOn
	  // if (isAllChart && tempChartsOn && (param.etalonId === null || param.etalonId === undefined))
	  // disabled = true;
	  //
	  // if (isAllChart && pdzOn && param.min === null && param.max === null)
	  // disabled = true;
	  //
	  // if (disabled)
	  // checked = false;

	  // console.log(param.name, 'checked', checked, 'disabled', disabled);

	  if (param.etalonForId === null)
		content.push(
		  <Checkbox
			key={param.id}
			className={"charts-param-checkbox"}
			style={{
			  borderColor: '#' + (disabled ? 'fff' : param.lineColor)
			}}
			onChange={e => {
			  onParamChecked(
				e.target.checked,
				param.id,
				disabled,
				param
			  )
			}}
			checked={checked}
			disabled={disabled}
		  >
			{param.name}
			{/*{CHARTS_DEBUG ? param.unitId : ''}*/}
		  </Checkbox>
		);
	});

	return (
	  <Row
		gutter={8}
		justify={"center"}
		className={chartsStyles.paramBar}
	  >
		{content}
	  </Row>
	)
  }


  hideCharts(data: ILineData, optionsInit: any, showParams: ShowParams[], selectedParameters: string[]) {
	let chartData: ILineData = {
	  datasets: []
	};
	let visibleScales: string[] = [];

	const tempChartsOn = showParams.includes(ShowParams.TG);
	const pdzOn = this.state.showParams.includes(ShowParams.PDZ);

	let scalesSuggested: any = {};

	let allDates: any = [];


	data.datasets.map((item: IDatasetsItem) => {
	  let originalParam: IChartParameter = this.state.parameters[item.id] ?? null;
	  let isParamSelected = selectedParameters.includes(item.id);

	  // console.dir(originalParam);

	  let axisId: string = item.yAxisID;

	  if (selectedParameters.includes(item.id) && item.etalonForId == null) {

		if (!visibleScales.includes(item.yAxisID)) {
		  if (!tempChartsOn || item.etalonId)
			visibleScales.push(item.yAxisID);
		}

		chartData.datasets.push(item);
	  }
	  // console.dir(item);

	  //добавляем темп графики, тут можно также добавить мин.макс как для ПДЗ чтобы убрать темп. графики от границ
	  if (tempChartsOn && item.etalonForId !== null && selectedParameters.includes(item.etalonForId)) {
		chartData.datasets.push(item);
		// console.dir(item);
		// console.log('charts hideCharts() TG', originalParam.min, originalParam.max);
	  }

	  //добавляем ПДЗ
	  if (pdzOn && isParamSelected && originalParam && (originalParam.min !== null || originalParam.max !== null)) {

		let pdzMinData: any = [];
		let pdzMaxData: any = [];

		const valuesArray: any = [];
		if (originalParam.min !== null)
		  valuesArray.push(originalParam.min);
		if (originalParam.max !== null)
		  valuesArray.push(originalParam.max);

		item.data.map((pd: any) => {
		  if (pd.y !== null)
			valuesArray.push(parseFloat(pd.y));

		  //данные для графиков ПДЗ
		  if (originalParam.min !== null)
			pdzMinData.push({
			  x: pd.x,
			  y: originalParam.min
			});
		  if (originalParam.max !== null)
			pdzMaxData.push({
			  x: pd.x,
			  y: originalParam.max
			});


		});
		// console.log(valuesArray);
		// console.log(axisId, Math.min(...valuesArray), Math.max(... valuesArray));

		const min = Math.min(...valuesArray);
		const max = Math.max(...valuesArray);
		// console.log(pdzMinData);
		// console.log(axisId, min, max);

		if (min !== 1000000) {
		  if (!(axisId in scalesSuggested))
			scalesSuggested[axisId] = {};
		  scalesSuggested[axisId].min = min - min * 0.05;
		}
		if (max !== -1000000) {
		  if (!(axisId in scalesSuggested))
			scalesSuggested[axisId] = {};
		  scalesSuggested[axisId].max = max + max * 0.05;
		}


		let rgba = hexRgb('#' + originalParam.lineColor);

		if (pdzMinData.length)
		  chartData.datasets.push({
			id: 'pdzMin',
			label: 'ПДЗ мин. ' + item.id,
			data: pdzMinData,
			borderColor: `rgba(${rgba.red}, ${rgba.green}, ${rgba.blue}, ${rgba.alpha})`,
			yAxisID: axisId,
			borderDash: [5, 5],
			etalonId: "",
			etalonForId: ""
		  });
		if (pdzMaxData.length)
		  chartData.datasets.push(
			{
			  id: 'pdzMax',
			  label: 'ПДЗ макс. ' + item.id,
			  data: pdzMaxData,
			  borderColor: `rgba(${rgba.red}, ${rgba.green}, ${rgba.blue}, ${rgba.alpha})`,
			  yAxisID: axisId,
			  borderDash: [5, 5],
			  etalonId: "",
			  etalonForId: ""
			}
		  );
	  }
	  /// END PDZ

	  //подсчитываем мин/макс дату для оси Х, исходя из данных
	  item.data.map((pd: any) => {
		//подсчитываем мин/макс дату для оси Х
		allDates.push(moment(pd.x));
	  });

	});

	// const {dateFrom, dateTo} = datesForServer(this.props.period, this.state.dates[0], this.state.dates[1]);
	const {dateFrom, dateTo} = datesForServer(
	  this.state.getDataParams.period,
	  this.state.getDataParams.dates[0],
	  this.state.getDataParams.dates[1]
	);
	// console.clear();
	// console.log('dateFrom', dateFrom.format('DD.MM HH:mm:ss'));
	// console.log('dateTo', dateTo.format('DD.MM HH:mm:ss'));

	let xMinDate, xMaxDate;
	{
	  xMinDate = dateFrom;
	  xMaxDate = dateTo;

	  xMinDate.startOf('minute');
	  xMaxDate.startOf('minute');
	}
	// console.log('>> dateTo=', xMaxDate.format('DD.MM HH:mm:ss'));

	const minMaxDiffHours = xMaxDate.diff(xMinDate, 'hours');
	const minMaxDiffToShowMinutes = 5;

	if (this.state.getDataParams.period !== PeriodsEnum.current || minMaxDiffHours > minMaxDiffToShowMinutes) {
	  xMinDate.startOf('hour');

	  // console.log(xMaxDate.minutes(), xMaxDate.seconds());
	  if (xMaxDate.minutes() > 0)
		xMaxDate.endOf('hour').add(1, 'seconds');
	  // console.log('here 1');
	}
	// else {
	//   xMinDate.startOf('minute');
	//   xMaxDate.startOf('minute');
	//   console.log('here 2');
	// }
	// console.log(allDates);
	//console.log('dateFrom=', xMinDate.format('DD.MM HH:mm:ss'),
	//  '/ dateTo=', xMaxDate.format('DD.MM HH:mm:ss'),
	//  '/ diff=', minMaxDiffHours
	//);


	let options = {...optionsInit};
	options.scales = {
	  x: optionsInit.scales.x
	};
	options.scales.x.time.tooltipFormat = this.state.period == PeriodsEnum.day ? 'DD.MM.YYYY' : 'DD.MM.YYYY HH:mm';

	//устанавливаем мин/макс оси Х
	options.scales.x.min = xMinDate;
	options.scales.x.max = xMaxDate;
	options.scales.x.time.stepSize = 1;

	if (minMaxDiffHours <= minMaxDiffToShowMinutes) {
	  options.scales.x.time.unit = 'minute';// minute second
	  options.scales.x.time.tooltipFormat = 'HH:mm';//'mm:ss';
	  options.scales.x.time.minUnit = 'minute';

	  let stepSize = 0.5;
	  if (minMaxDiffHours <= 1)
		stepSize = 0.07;
	  else if (minMaxDiffHours <= 2)
		stepSize = 0.25;

	  options.scales.x.time.stepSize = stepSize;

	}



	visibleScales.map((scaleId: string) => {
	  options.scales[scaleId] = optionsInit.scales[scaleId];

	  if (scaleId in scalesSuggested) {
		if ('min' in scalesSuggested[scaleId])
		  options.scales[scaleId].suggestedMin = scalesSuggested[scaleId].min;
		if ('max' in scalesSuggested[scaleId])
		  options.scales[scaleId].suggestedMax = scalesSuggested[scaleId].max;
	  }
	});

	return {
	  chartData: chartData,
	  options: options
	};
  }

  /**
   * Генерируем общий график
   */
  renderChartAllInOne() {

	if (this.state.chartOptions?.optionsAll !== null) {

	  if (this.state.data !== null) {

		let {chartData, options} = this.hideCharts(
		  this.state.data,
		  this.state.chartOptions?.optionsAll,
		  this.state.showParams,
		  this.state.selectedParameters
		);

		options = setScaleXminMax(options, this.state.period, this.state.dates);


		if (chartData.datasets.length === 0)
		  return <ChartsEmpty key={"charts-empty"}/>;

		return <Line
		  key={"charts-0"}
		  // @ts-ignore
		  options={options}
		  // @ts-ignore
		  // data={this.state.data}
		  data={chartData}
		/>;
	  }


	}

	return null;
  }

  renderSeparateCharts() {
	if (this.state.chartOptions?.optionsParams === null || this.state.data === null)
	  return null;
	// console.clear();

	let content: any = [];

	const tempChartsOn = this.state.showParams.includes(ShowParams.TG);
	const pdzOn = this.state.showParams.includes(ShowParams.PDZ);

	let {chartData, options} = this.hideCharts(
	  this.state.data,
	  this.state.chartOptions?.optionsAll,
	  this.state.showParams,
	  this.state.selectedParameters
	);
	console.dir(chartData.datasets);
	// console.dir(options);

	let chartsOrder: string[] = [];
	// Object.values(this.state.parameters).map((p:any) => {
	//   console.dir(p);
	// 	if (!chartsOrder.includes(p.yAxisID))
	// 	  chartsOrder.push(p.yAxisID);
	// });


	let charts: any = {};

	chartData.datasets.map((param: any) => {

	  let chartsKey = param.yAxisID;


	  let originalParam: IChartParameter = this.state.parameters[param.id];

	  // @ts-ignore
	  let opts: any = this.state.chartOptions?.optionsParams[param.id] ?? defaultOptions;
	  opts = setScaleXminMax(opts, this.state.period, this.state.dates);
	  // console.dir(opts);
	  if (chartsKey in options.scales)
		opts[chartsKey] = options.scales[chartsKey];
	  // console.log(opts);


	  if (!chartsOrder.includes(chartsKey))
		chartsOrder.push(chartsKey);

	  if (!(chartsKey in charts))
		charts[chartsKey] = {
		  datasets: [],
		  opts: opts,
		  title: [],
		  titlePrefix: originalParam.unitName
		};

	  if (param.etalonForId == null) {
		// console.log(`push ${param.id} to ${param.yAxisID}`);
		charts[chartsKey].datasets.push(param);
		charts[chartsKey].title.push(originalParam.name);
	  }

	  //темп. график
	  if (tempChartsOn && param.etalonForId) {
		charts[chartsKey].datasets.push(param);
	  }
	  //ПДЗ
	  if (pdzOn && ['pdzMin', 'pdzMax'].includes(param.id)) {
		charts[chartsKey].datasets.push(param);
	  }
	});

	// console.log(chartsOrder);
	// console.dir(charts);
	chartsOrder.map((id: string, i: number) => {
	  let chart = charts[id];

	  // console.log(id, chart);
	  if (chart)
		content.push(
		  <div
			className={chartsStyles.separateChart}
			key={"chart-" + i}
		  >
			<div className={chartsStyles.separateChartTitle}>
			  {
				chart.titlePrefix
				+ ': '
				+ chart.title.join(', ')
			  }
			</div>
			<Line
			  options={chart.opts}
			  data={{datasets: chart.datasets}}
			/>
		  </div>
		);
	});


	return content;
  }

  exportCharts() {
	// console.clear();
	// console.log('exportCharts');

	if (this.state.data === null || this.state.data.datasets == undefined || this.state.data.datasets.length === 0) {
	  // message.warning('Нет данных для экспорта');
	  return;
	}
	// console.dir(this.state.data.datasets);

	const columns: any = {};

	const rowsByTime: any = {};

	columns['timeStamp'] = 'Время';

	this.state.data.datasets.map((param: any) => {
	  let PRM: IChartParameter = this.state.parameters[param.id];

	  if (this.state.selectedParameters.includes(param.id)) {

		if (param.etalonForId === null) {
		  if (!(param.id in columns))
			columns[param.id] = PRM.name;

		  let row: any = {};

		  param.data.map((pd: any) => {

			if (!(pd.x in rowsByTime))
			  rowsByTime[pd.x] = {};

			let val = pd.y ?? '';
			rowsByTime[pd.x][param.id] = val.toString().replace(".", ",");
			//'"'+val.toString().replace(".", ",") + '"';//.replace(".", ",");
			// row['timeStamp'] = pd.x;
			// row[param.id] = pd.y;
		  });

		}
	  }
	});
	const rows: any[] = [];
	for (let ts in rowsByTime) {
	  let row: any = {};
	  row['timeStamp'] = ts.toString();

	  for (let id in rowsByTime[ts]) {
		row[id] = rowsByTime[ts][id];
	  }

	  rows.push(row);
	}
	// console.dir(columns);
	// console.dir(rowsByTime);
	// console.dir(rows);

	let fileName = this.props.pointName + '.csv';

	downloadFile(
	  {
		fileName: fileName,
		columns: columns,
		rows: rows
	  },
	  'common/exportgrid',
	  fileName,
	  null,
	  this.props.setIsLoading
	);

  }


  //для каждого графика по отдельности  - 1ый вариант
  // renderSeparateCharts() {
  // if (this.state.chartOptions?.optionsParams === null || this.state.data === null)
  //   return null;
  // console.clear();
  //
  // let content: any = [];
  //
  // // let itemData: ILineData;
  //
  // // console.dir(this.state.data?.datasets);
  // // console.dir(this.state.parameters);
  // // console.dir(this.state.chartOptions?.optionsParams);
  //
  // // //обработка темп. графиков
  // // let temperatureCharts: any = {};
  // // this.state.data?.datasets.map((param: any) => {
  // //   let PRM: IChartParameter = this.state.parameters[param.yAxisID];
  // //   if (PRM.etalonForId)
  // // 	temperatureCharts[PRM.etalonForId] = param;
  // // });
  // // console.dir(temperatureCharts);
  //
  // console.log(this.state.selectedParameters);
  //
  // // const isPDZ: boolean = this.state.showParams.includes(ShowParams.PDZ);
  //
  // let chartsOrder: string[] = [];
  // let charts: any = {};
  //
  //
  // this.state.data?.datasets.map((param: any) => {
  //   // console.dir(param);
  //
  //   let originalParam: IChartParameter = this.state.parameters[param.id];
  //
  //   if (this.state.selectedParameters.includes(param.id)) {
  //
  // 	if (!chartsOrder.includes(param.yAxisID))
  // 	  chartsOrder.push(param.yAxisID);
  //
  // 	//@ts-ignore
  // 	let opts: any = this.state.chartOptions?.optionsParams[param.id];
  // 	opts = setScaleXminMax(opts, this.state.period, this.state.dates);
  // 	// console.log(opts);
  //
  // 	//объединяем по единицам измерения
  // 	// if (!isPDZ) {
  // 	//
  // 	//
  // 	// }
  //
  // 	if (!(param.yAxisID in charts))
  // 	  charts[param.yAxisID] = {
  // 		datasets: [],
  // 		opts: opts,
  // 		title: [],
  // 		titlePrefix: originalParam.unitName
  // 	  };
  // 	//если сам параметр
  // 	if (param.etalonForId == null) {
  // 	  // console.log(`push ${param.id} to ${param.yAxisID}`);
  // 	  charts[param.yAxisID].datasets.push(param);
  // 	  charts[param.yAxisID].title.push(originalParam.name);
  // 	}
  //   }
  // });
  // // console.log(chartsOrder);
  // //
  // // console.dir(charts);
  //
  //
  // chartsOrder.map((id: string, i: number) => {
  //   let chart = charts[id];
  //   console.dir(chart);
  //   // chart.opts.plugins.title.text = chart.title.join(', ');
  //   // console.dir(chart.opts);
  //
  //   if (chart)
  // 	content.push(
  // 	  <div
  // 		className={chartsStyles.separateChart}
  // 		key={"chart-" + i}
  // 	  >
  // 		<div className={chartsStyles.separateChartTitle}>
  // 		  {
  // 			chart.titlePrefix
  // 			+ ': '
  // 			+ chart.title.join(', ')
  // 		  }
  // 		</div>
  // 		<Line
  // 		  options={chart.opts}
  // 		  data={{datasets: chart.datasets}}
  // 		/>
  // 	  </div>
  // 	);
  // });
  //
  //
  // return content;
  //
  // }


}

export default ChartsContainer;


const setChartOptions = function (showParams: ShowParams[], period: PeriodsEnum, dates: any[], data: SChart, chartsContainerState: IChartsContainerState): object | null {

  if (data.parameters === undefined || data.parameters === null || data.parameters.length === 0) {
	//предупреждалка вынесена в метод  getData
	// message.warn('Нет параметров для точки учета', 0.5);
	return null;
	// return [null, null, {}];
  }
  if (data.units === undefined || data.units === null || data.units.length === 0) {
	//предупреждалка вынесена в метод  getData
	message.warn('Нет единиц измерения для точки учета', 0.5);
	return null;
	// return [null, null, {}];
  }
  // console.log('setChartOptions', period);

  let options4All: any = null,
	option4Params: any = null,
	parametersIds: string[] = [],
	paramsById: any = {},
	hasTempCharts = false, //есть темп графики
	hasPdz = false;//есть ПДЗ

  let selectedParams: string[] = [];

  let units: any = {};
  data.units.map(unit => {
	units[unit.id] = unit;
  });

  let scales: any = {};

  let _defaultOptions = {...defaultOptions};

  if (period == PeriodsEnum.day)
	_defaultOptions.scales.x.time.tooltipFormat = 'DD.MM.YYYY';
  else
	_defaultOptions.scales.x.time.tooltipFormat = 'DD.MM.YYYY HH:mm';

  // console.dir(_defaultOptions.scales.x);
  //опции для общего графика
  {

	options4All = {..._defaultOptions};


	data.parameters.map((param: SChartParameter) => {

	  parametersIds.push(param.id);


	  let isEtalon: boolean = param.etalonForId !== null;
	  if (isEtalon)
		hasTempCharts = true;

	  if (param.min !== null || param.max !== null)
		hasPdz = true;

	  let scale: IChartScale = {
		axis: 'y',
		title: {
		  display: true,
		  text: units[param.unitId].text ?? 'text'
		}
	  };


	  let axisId: string = param.unitId;


	  scales[axisId] = scale;

	  paramsById[param.id] = {
		...param,
		unitName: units[param.unitId].description
	  }
	  return null;
	});

	data.parameters.map((param: SChartParameter) => {
	  if (param.etalonForId)
		paramsById[param.etalonForId].etalonId = param.id;
	});

	options4All.scales = {
	  ..._defaultOptions.scales,
	  ...scales
	};
  }
  /*
   * опции для каждого параметра
   */
  {
	option4Params = {};
	data.parameters.map((param: SChartParameter) => {
	  // console.log(param);
	  let scale: IChartScale = {
		axis: 'y',
		title: {
		  display: true,
		  text: units[param.unitId].text ?? ''
		}
	  };


	  option4Params[param.id] = {
		..._defaultOptions,
		maintainAspectRatio: false
	  };
	  // option4Params[param.id].plugins.title.display = true;

	  let axisId: string = param.unitId;
	  // if (showParams.includes(ShowParams.PDZ))
	  // axisId = param.id;

	  option4Params[param.id].scales = {
		..._defaultOptions.scales,
		[axisId]: scale
	  };
	});
  }
  // console.dir(options4All);


  // console.log(selectedParams);
  //для температурных графиков делаем по умолчанию включенными
  if (showParams.includes(ShowParams.TG)) {

	for (let id in paramsById) {
	  if (paramsById[id].etalonId !== undefined)
		selectedParams.push(id);
	}
  } else {


	selectedParams = chartsContainerState.selectedParameters.length > 1
	  ? chartsContainerState.selectedParameters
	  : parametersIds.includes(chartsContainerState.lastSelectedParameter)
		? [chartsContainerState.lastSelectedParameter]
		: parametersIds


  }


  // console.log(options4All);

  return [options4All, option4Params, paramsById, selectedParams, hasTempCharts, hasPdz];
}


const setDatasets = (dataFromServer: SDataRecord[], state: IChartsContainerState): IDatasetsItem[] => {


  const {
	parameters,
	//showParams,
	selectedParameters, getDataParams
  } = state;

  const {dates, period} = getDataParams;

  let datasets: IDatasetsItem[] = [];
  // let datasetsUnsorted: IDatasetsItem[] = [];


  let paramArray = Object.values(parameters);

  if (paramArray.length)
	paramArray.map((param: any) => {
	  let rgba;

	  if (param.lineColor === '' || param.lineColor === null || param.lineColor === undefined) {
		rgba = hexRgb('#000000');
		message.warn(`Для ресурса ${param.name} не задан цвет линии`, 5);
	  } else
		rgba = hexRgb('#' + param.lineColor);
	  let datasetsData: IChartDataPoint[] = setDataForParameter(dataFromServer, param.id, dates, period, selectedParameters);

	  let axisId: string = param.unitId;


	  if (datasetsData.length) {
		let dsItem: any = {
		  id: param.id,
		  label: param.name,
		  data: datasetsData,
		  borderColor: `rgba(${rgba.red}, ${rgba.green}, ${rgba.blue}, ${rgba.alpha})`,
		  yAxisID: axisId,
		  etalonId: param.etalonId,
		  etalonForId: param.etalonForId
		};

		//попытка закрасить области между графиками
		// if (param.etalonForId !== null) {
		//   dsItem.backgroundColor = `rgba(${rgba.red}, ${rgba.green}, ${rgba.blue}, 1)`;
		//   dsItem.fill = true;
		//   dsItem.tension = 0.1;
		//   // console.log(dsItem);
		// }
		// else {
		//   dsItem.backgroundColor = `rgba(${rgba.red}, ${rgba.green}, ${rgba.blue}, 1)`;
		//   dsItem.tension = 0.1;
		//   dsItem.fill = '+1';
		// }

		datasets.push(dsItem);
	  }
	});

  return datasets;
}
const setDataForParameter = (dataFromServer: SDataRecord[], paramId: string, dates: any[], period: PeriodsEnum, selectedParameters: string[]): IChartDataPoint[] => {
  // let result: IChartDataPoint[] = [];
  let resultUnsorted: IChartDataPoint[] = [];

  let timeFormat: string;
  // let datesAdd: any;
  switch (period) {
	case PeriodsEnum.current:
	case PeriodsEnum.hour:
	  timeFormat = 'YYYY-MM-DD HH:mm';
	  break;
	default:
	  timeFormat = 'YYYY-MM-DD';
	  // datesAdd = 'days';
	  break;
  }

  // console.log(paramId, selectedParameters.includes(paramId));

  dataFromServer.map(item => {

	//@ts-ignore
	let y = item.values[paramId] ?? null;


	resultUnsorted.push({
	  x: moment(item.timeStamp).format(timeFormat),
	  y: y
	});
  });

  let result: IChartDataPoint[] = resultUnsorted.sort((a: IChartDataPoint, b: IChartDataPoint) => {
	let res: number = 0;
	let ax = moment(a.x).unix();
	let bx = moment(b.x).unix();

	if (ax < bx)
	  return -1;
	else if (ax > bx)
	  return 1;

	return res;
  });
  // console.dir(dataFromServer);


  // console.dir(paramId, result);

  // for (let m = moment(dates[0]);
  //    m.isBefore(dates[1]);
  //    m.add(1, datesAdd)) {
  //
  // }

  return result;
}

class ChartsEmpty extends React.Component <any, any> {

  render() {
	return (
	  <div className={chartsStyles.emptyData}>
		Нет данных
	  </div>
	);
  }

}
