import * as React from 'react';
import * as L from 'leaflet';
import { MapContainer, TileLayer, ZoomControl, ScaleControl, MapConsumer } from 'react-leaflet';

interface IProps {
    map: string | undefined;
    doubleClickZoom?: boolean;
    zoom?: number;
    center?: [number, number];//center и bounds при изменении центрируют карту на соответствующем положении
    bounds?: Array<[number, number]>;
}



class BaseMap extends React.Component<IProps> {
    static readonly aviableMaps: Array<{name: string, code: string}> = [{name: 'OSM', code: 'osm'}, {name: 'GOOGLE', code: 'google'}, {name: 'Яндекс', code: 'yandex'}];
    
    state = {
    };

    private readonly defLatLon: [number, number] = [55.702868, 37.530865]

    componentDidUpdate(oldProps: IProps){
        if (this.map != undefined && this.props.bounds != undefined && oldProps.bounds != this.props.bounds && this.props.bounds.length > 0){
            this.map?.fitBounds(this.props.bounds);
            //console.log('fitBounds', this);
        }

        if (this.map != undefined && this.props.center != undefined && oldProps.center !== this.props.center && this.props.center.length > 0){
            this.map?.panTo(this.props.center);
            //console.log('fitBounds', this);
        }
    }

    baseLayerView = () => {
        let tileLayer = <h1> Указанная карта не поддерживается </h1>;
                //url='http://vec{s}.maps.yandex.net/tiles?l=map&v=4.55.2&z={z}&x={x}&y={y}&scale=2&lang=ru_RU'

        //Обезательно свойство key так как без него реакт подумает что дом элемент не изменился и не пересоздаст его
        //и поэтому свойство subdomains которое не может быть изменено динамически а только средством пересоздания класса - останется старым
        if (this.props.map == 'google') {
            //console.log('google!');
            tileLayer = <TileLayer
                key={2}
                attribution='Картографические данные © Google'
                url='http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
                maxZoom={20}
                subdomains={['mt0', 'mt1', 'mt2', 'mt3']} />
        } else if (this.props.map == 'yandex') {
            //console.log('ya!');
            tileLayer = <TileLayer
                key={3}
                attribution='© Яндекс'
                url='https://{s}.core-renderer-tiles.maps.yandex.net/tiles?l=map&v=4.55.2&z={z}&x={x}&y={y}&scale=2&lang=ru_RU'
                subdomains={['01', '02', '03', '04']}
                updateWhenIdle={false} />
        } else {
            //console.log('osm!');
            tileLayer = <TileLayer
            key={1}
            attribution='copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
        }

        return tileLayer;
    }

    map: L.Map | undefined = undefined;

    render() {
        let crs = L.CRS.EPSG3857;
        let key = 1;

        if (this.props.map == 'yandex') {
            crs = L.CRS.EPSG3395;
            key = 2;
        }

        return (
            <MapContainer 
                doubleClickZoom={this.props.doubleClickZoom ?? true}
                zoom={this.props.zoom ?? 3} 
                center={this.props.center ?? this.defLatLon}
                crs={crs} 
                key={key} 
                zoomControl={false} 
                whenCreated={map => {
                    setTimeout(() =>{ map.invalidateSize()}, 100);
                    this.map = map;
                }}
            >
                <ZoomControl position='bottomright' />
                <ScaleControl metric={true} imperial={false} />
                {this.baseLayerView()}
                {this.props.children}
                
            </MapContainer>
        );
    }
};

export default BaseMap;