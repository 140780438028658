import {useState} from 'react';
import {Modal, Form, Input, Radio, Select} from 'antd';
import {BaseProfile} from '../../decl';

export interface ProfileManagerValues {
  group: string;
  parameter: string;
  value: string;
  changeCondition: string;
  changeConditionValue: string;
}
export interface ProfileManagerFormProps {
  open: boolean;
  userProfile: BaseProfile;  
  onRun: (values: ProfileManagerValues) => void;
  onCancel: () => void;
}

export const ProfileManagerForm: React.FC<ProfileManagerFormProps> = ({
  open,
  userProfile,
  onRun,
  onCancel
}) => {
  const [selectedGroup, setSelectedGroup] = useState('');
  const [form] = Form.useForm();
  form.resetFields();

  const groupOptions = () =>{
    const groups = Object.getOwnPropertyNames(userProfile);
    return groups.map(g=>{return {value: g, label:g}});    
  }

  const onGroupChange = (value: string)=>{
     setSelectedGroup(value);
  }

  const parameterOptions = () => {
    if(selectedGroup === ''){
      return [];
    }
    else{
      const parameters = Object.getOwnPropertyNames(userProfile[selectedGroup  as keyof BaseProfile]);
      return parameters.map(p=>{return {value: p, label:p}});    
      }
  }

  return (
    <Modal
      open = {open}
      title="Массовое изменение профилей пользователя"
      okText="Выполнить"
      cancelText="Отмена"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            form.resetFields();
            onRun(values);
          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form form={form} layout="vertical" name="changeProfileForm">
        <Form.Item name="group" label="Группа" rules={[{ required: true, message: 'Нужно выбрать группу!' }]}>
          <Select options={groupOptions()}  onChange={onGroupChange}/>
        </Form.Item>
        <Form.Item name="parameter" label="Параметр" rules={[{ required: true, message: 'Нужно выбрать параметр!' }]}>
          <Select options={parameterOptions()}/>
        </Form.Item>
        <Form.Item name="value" label="Значение">
          <Input />
        </Form.Item>
        <Form.Item  name="changeCondition" label="Условие" >
          <Select options = {[
            {
              value: '0',
              label: 'Все'
            },
            {
              value: '1',
              label: 'Точек учёта больше'
            },
            {
              value: '2',
              label: 'Точек учёта меньше'
            },

          ]}/>
        </Form.Item>
        <Form.Item name="changeConditionValue" label="Параметр условия">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};