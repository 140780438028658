import * as React from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import ReactDOM from 'react-dom'

import * as UserProfile from '../../store/UserProfile';
import * as MapDataStore from '../../store/MapDataStore';
import * as PageStore from '../../store/Page';
import * as TasksStore from '../../store/Tasks';
import LayerView from './LayerView'
import BaseMap from './BaseMap'

import styles from '../../resources/Map.module.less';
import { ApplicationState } from '../../store';
import PopupSettings from "./PopupSettings";
import { Button, Col, Dropdown, Menu, Popover, Select, Space, Tag } from "antd";

import * as Const from '../../utils/Const';
import {netiExecute, filterDictionary} from '../../utils/Common';
import DataLayersPanel from "./DataLayersPanel";
import MarkerHelper from "./MarkerHelper";
import { PeriodsEnum, CommunicationMissedTimesEnum, SPointsFilter } from "../../decl";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import DataUpdater from "./DataUpdater";
import { useMap } from "react-leaflet";
import { ThreeSixtyOutlined } from "@material-ui/icons";

const { Option } = Select;
// указываем путь к файлам marker
//L.Icon.Default.imagePath = "https://unpkg.com/leaflet@1.5.0/dist/images/";

type Props =
    {userProfile: UserProfile.UserProfileState, mapDataStore: MapDataStore.IMapDataState } &
    typeof UserProfile.actionCreators &
    typeof MapDataStore.actionCreators &
    typeof PageStore.actionCreators &
    typeof TasksStore.actionCreators &
    RouteComponentProps<{}>;

type State = {
    activeLayerCode: string, 
    contextMenu: {position: [number, number], menu: Array<{title: string, action: () => void}>},
    bounds: Array<[number, number]>,
    tmpSelectedLayers: string | undefined,
    filteredPoints: {[pointId: string] : MapDataStore.SMapPoint};
}

class Map extends React.Component<Props, State> {
    componentRef: any;
    constructor(props: Props, state: State) {
        super(props);
        this.componentRef = React.createRef();
        this.state = { activeLayerCode: 'state', contextMenu: {position: [0, 0], menu: [] }, bounds: [], tmpSelectedLayers: undefined, filteredPoints: {} };
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.mapDataStore?.points != undefined && prevProps.mapDataStore?.points !== this.props.mapDataStore.points) {
            this.setState({bounds: Object.values(this.props.mapDataStore?.points).map(item => [item.latitude, item.longitude])})
        }

        if (prevProps.userProfile.profile.Map?.SelectedLayers === undefined && this.props.userProfile.profile.Map?.SelectedLayers !== undefined){
            this.setState({tmpSelectedLayers: this.props.userProfile.profile.Map?.SelectedLayers});
        }

        if (prevProps.mapDataStore.communicationMissedTimeFilter !== this.props.mapDataStore.communicationMissedTimeFilter
            || prevProps.mapDataStore.points !== this.props.mapDataStore.points
            || prevProps.mapDataStore.pointsData !== this.props.mapDataStore.pointsData) 
        {
            let filteredPoints: {[pointId: string] : MapDataStore.SMapPoint};
            filteredPoints = this.filterPoints(this.props.mapDataStore.communicationMissedTimeFilter);
            this.setState({filteredPoints: filteredPoints});
        }

    }

    showContextMenu = (contextMenu: {position: [number, number], menu: Array<{title: string, action: () => void}>}): void => {
        this.setState({contextMenu})
    }

    changeMapProfile = (name: string, val: any) => {
        this.props.changeProfileValue(Const.PROFILE_MAP, name, val);
    }


    execute = (cmd:any) => {
        netiExecute(cmd, this.props.userProfile, this.props.changeProfile, this.props.setIsLoading, this.props.setActiveQueries, null, this.props.showModal);
    }

    showPointInfo = (pointId: string, pointNumber: string, tabName?: string) => {
       const source: any = new (window.NetiSource as any)(this.execute);
       const parent: any = ReactDOM?.findDOMNode(this)?.parentNode;
       this.props.showPointInfo(pointId, pointNumber, source, parent, tabName);
    }
    showPointsData = (pointIds: [], period: number, normalDataDepth: number) => {
       const source: any = new (window.NetiSource as any)(this.execute);
       const parent: any = ReactDOM?.findDOMNode(this)?.parentNode;
       this.props.showPointsData(pointIds, period, normalDataDepth, source, parent);
    }
    showReportForm = (pointId: string, pointNumber: string) => {
       const source: any = new (window.NetiSource as any)(this.execute);
       const parent: any = ReactDOM?.findDOMNode(this)?.parentNode;
       this.props.showReportForm(pointId, pointNumber, source, parent);
    }
    gotoPage = (iPath: string) => {
        this.props.history.push(iPath);
    }

    filterPoints = (cf: CommunicationMissedTimesEnum): any => {
        let points: {[pointId: string] : MapDataStore.SMapPoint} = {};

        if (this.props.mapDataStore.points) {
            const keys: string[] = Object.keys(this.props.mapDataStore.points);

            keys.forEach((key: string) => {
                const lastPollingTime = this.props.mapDataStore.pointsData ? this.props.mapDataStore.pointsData[key]?.lastPollingTime : this.props.mapDataStore.points[key]?.lastPollingTime;
                if (lastPollingTime === undefined) {
                    console.log("lastPollingTime оказался равен undefined хотя такого произайти недолжно было, возможная причина что в массиве pointsData не оказалось нужной ТУ");
                }

                let result = false;
                switch(cf) {
                    case CommunicationMissedTimesEnum.All:
                        result = true;
                        break;
                    case CommunicationMissedTimesEnum.NormalLess1day:
                        result = lastPollingTime != null && lastPollingTime <= 24;
                        break;
                    case CommunicationMissedTimesEnum.MissedMore1Day:
                        result = lastPollingTime != null && lastPollingTime > 24;
                        break;
                    case CommunicationMissedTimesEnum.MissedMore1Week:
                        result = lastPollingTime != null && lastPollingTime > 24*7;
                        break;
                    case CommunicationMissedTimesEnum.MissedAll:
                        result = (lastPollingTime == null);
                        break;
                }

                if(result) {
                    points[key] = this.props.mapDataStore.points[key];
                }
            });
        }
        return points;
    }


    render() {
        const contextMenu = (
            <Menu>
                {
                    this.state.contextMenu.menu.map(item => <Menu.Item
                        onClick={
                            () => {
                                this.setState({ contextMenu: { position: [0, 0], menu: [] } });
                                item.action();
                            }

                        }
                        key={item.title}
                    >
                        {item.title}
                    </Menu.Item>
                    )
                }
            </Menu>
        );

        let normalDataDepth: number = 0;
        if (this.props.userProfile.profile.Common && this.props.userProfile.profile.Map) {
            if (this.props.userProfile.profile.Common.ArchivePeriod === PeriodsEnum.current)
                normalDataDepth = this.props.userProfile.profile.Map.CurrentNormalDataDepth;
            else if (this.props.userProfile.profile.Common.ArchivePeriod === PeriodsEnum.hour)
                normalDataDepth = this.props.userProfile.profile.Map.HourNormalDataDepth;
            else if (this.props.userProfile.profile.Common.ArchivePeriod === PeriodsEnum.day)
                normalDataDepth = this.props.userProfile.profile.Map.DayNormalDataDepth;
        }

        return (
            this.props.userProfile.profile.Map?.BaseLayer === undefined
                || this.props.userProfile.profile.Common === undefined ? <LoadingOutlined/> :
            <BaseMap bounds={this.state.bounds} map={this.props.userProfile.profile.Map?.BaseLayer} ref={this.componentRef}>
                <div className={styles.controlsСontainer}>
                    <div style={{height: '26px', paddingTop: '1px'}}>
                        <DataUpdater /> 
                        {/*<Button onClick={() => console.log(this)}> Debug </Button>*/}
                    </div>
                    <div>
                        <Space align='start'>
                            <Col key='1'>
                                <DataLayersPanel 
                                    periodType={this.props.userProfile.profile.Common?.ArchivePeriod ?? PeriodsEnum.day}
                                    layersColors={this.props.userProfile.profile.Map?.LayerColors}
                                    timerPeriodSec={this.props.userProfile.profile.Map?.AutoSwitchLayersTime ?? 60}
                                    timerWorking={this.props.userProfile.profile.Map?.AutoSwitchLayers ?? false}
                                    layers={this.props.mapDataStore.layers}
                                    activeLayerCode={this.state.activeLayerCode}
                                    selectedlayers={this.props.userProfile.profile.Map?.SelectedLayers == null ? [] : this.props.userProfile.profile.Map?.SelectedLayers.split(',')}
                                    onChangeCurrentLayer={(dataLayer: MapDataStore.SMapLayer) => {if(dataLayer)this.setState({activeLayerCode: dataLayer.layerCode})}}
                                />
                            </Col>
                            <Col key='2'>
                                <Select 
                                    style={{ width: 150 }} 
                                    value={this.props.userProfile.profile.Map?.BaseLayer ?? BaseMap.aviableMaps[0].code}
                                    onChange={(val: string) => this.changeMapProfile('BaseLayer', val)}
                                    dropdownClassName = "largeSelectDropDown"
                                >
                                    {BaseMap.aviableMaps.map(item => <Option key={item.code} value={item.code}>{item.name}</Option>)}
                                </Select>
                            </Col>
                            <Col key='3'>
                                <div >
                                    <Popover 
                                        onVisibleChange={(vis: boolean) => {
                                            if (vis === false){                                           
                                                if (this.props.userProfile.profile.Map?.SelectedLayers != this.state.tmpSelectedLayers) {
                                                    this.setState({tmpSelectedLayers: this.props.userProfile.profile.Map?.SelectedLayers})
                                                    this.props.setStartToUpdateAllDataFlag(true);
                                                }                                            
                                            } else {
                                                this.setState({tmpSelectedLayers: this.props.userProfile.profile.Map?.SelectedLayers})
                                            }
                                        }} 
                                        mouseLeaveDelay={0.4} mouseEnterDelay={0.4} overlayClassName={styles.popupSettings} 
                                        placement="bottomRight" 
                                        trigger={['click', 'hover']} 
                                        content={
                                            <PopupSettings />
                                        }
                                    >
                                        <Button className={styles.popupSettingsButton}>
                                            <img src='Images/layers.png' />
                                        </Button>
                                    </Popover>
                                </div>
                            </Col>
                        </Space>
                    </div>
                </div>

                <LayerView
                        clusteringFunctionType={this.props.userProfile.profile.Map?.ClusteringFunctionType as MapDataStore.ClusteringFunctionTypeEnum | undefined}
                        layersColors={this.props.userProfile.profile.Map?.LayerColors}
                        points={this.state.filteredPoints}
                        pointsData={this.props.mapDataStore.pointsData ?? null}
                        currentLayerCode={this.state.activeLayerCode}
                        showContextMenu={this.showContextMenu}
                        period={this.props.userProfile.profile.Common?.ArchivePeriod ?? PeriodsEnum.day}
                        normalDataDepth={normalDataDepth}
                        showPointInfo={this.showPointInfo}
                        showPointsData={this.showPointsData}
                        showReportForm={this.showReportForm}
                        allLayers={this.props.mapDataStore.layers}
                        gotoPage={this.gotoPage}
                />

                <div style={{display: 'none'}}>
                    {MarkerHelper.getAllImagesUrls().map(item => <img key={item} src={item} />)}
                </div>

                <div style={{position: 'absolute', left: this.state.contextMenu.position[0], top: this.state.contextMenu.position[1]}}>
                    <Dropdown 
                        overlay={contextMenu} 
                        trigger={['click']} 
                        onVisibleChange={(flag: boolean) => {if (!flag) this.setState({contextMenu: {position: [0, 0], menu: []}})}} 
                        visible={this.state.contextMenu.menu.length > 0}
                    >
                        <div></div>
                    </Dropdown>
                </div>
            </BaseMap>
            
        );

    }
};

export default connect(
    (state: ApplicationState) => {
        return {
            userProfile: state.userProfile,
            mapDataStore: state.mapDataStore
        }
    },
    {...UserProfile.actionCreators, ...MapDataStore.actionCreators, ...PageStore.actionCreators}
)(withRouter(Map) as any);