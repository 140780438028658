import * as React from 'react';
import styles from '../resources/PageFooter.module.less';
import { getOldSiteUrl, gotoASPSite } from '../utils/AuthUtils.js'

import teploLogoWhite from "../images/teploLogoWhite.png";
import mailIconWhite from "../images/mailIconWhite.png";
import phoneIconWhite from "../images/phoneIconWhite.png";

import {
    FilePdfOutlined
} from '@ant-design/icons';

interface PageFooterProps{
    setIsLoading: any
}

export default class PageFooter extends React.PureComponent<PageFooterProps>{
    gotoOldSite = () =>{
        this.props.setIsLoading(true);
        gotoASPSite(
            () => {
                this.props.setIsLoading(false);
            },
            (err: any) => {
                console.log(err);
                this.props.setIsLoading(false);
            }
        );
        //const url = getOldSiteUrl();
        //window.location.assign(url);
    }

  render() {

      const toOldSite: boolean = window.configTI.URL_ASPSITE && window.configTI.URL_ASPSITE.length > 0;
      const year = new Date().getFullYear();
      const copyRight = 'ООО «ТЕПЛОИНФОРМ» 2008-' + year + ' г.';

    return (
      <div className={styles.footer}>
        <div style={{ paddingLeft: "2.5vw" }}>
          <img src={teploLogoWhite} style={{ width: "4vw", height: "2.2vw" }} />
        </div>

        <div style={{ paddingLeft: "1.3vw", color: "#ffffffc7" }}>
                <span>{copyRight}</span>
          <br />
          <span>111524, г.Москва, ул.Электродная, дом 10, офис 67</span>
        </div>
        <div style={{ flex: "1" }} />
        <div style={{ paddingRight: toOldSite ? "7vw" : "15vw" }}>
          <table style={{ margin: "0 auto" }}>
            <tbody>
              <tr>
                <td
                  style={{
                    paddingLeft: "0.4em",
                  }}
                >
            <FilePdfOutlined/>            
          <a
            href="Documents/Руководство по эксплуатации АСУТ-601М2.pdf?v2"
            target="_blank"
            style={{
              textDecoration: "underline",
              paddingLeft: "0.4em",
              color: "white"
            }}
          >
                Скачать руководство ИС "Теплоинформ"
        </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {toOldSite &&
        <div style={{ paddingRight: "6vw" }}>
          <a
            onClick={this.gotoOldSite}
            target="_blank"
            style={{
              textDecoration: "underline",
              paddingLeft: "2em",
              color: "blue",
            }}
          >
                Старая версия сайта
        </a>
        </div>
        }

        <div style={{ paddingRight: "5.3vw" }}>
          <img src={mailIconWhite} style={{ width: "1.2vw", height: "1.2vw" }} />
          <a
            href="mailto:info@teplo-inform.ru?Subject=General"
            target="_top"
            style={{
              textDecoration: "underline",
              paddingLeft: "0.4em",
              color: "white"
            }}
          >
            info@teplo-inform.ru
          </a>
        </div>
        <div style={{ paddingRight: "2.5vw" }}>
          <table style={{ margin: "0 auto" }}>
            <tbody>
              <tr>
                <td rowSpan={2}>
                  <img
                    src={phoneIconWhite}
                    style={{ width: "1.2vw", height: "1.2vw" }}
                    alt="phone icon"
                  />
                </td>
                <td
                  style={{
                    paddingLeft: "0.4em",
                    fontSize: "1vw"
                  }}
                >
                  +7(925)365-59-14
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    paddingLeft: "0.4em",
                    fontSize: "1vw"
                  }}
                >
                  +7(495)672-72-13
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}