import * as React from 'react';
import { Button, DatePicker, Input, Select, Space, Tooltip } from 'antd';
import { connect } from 'react-redux';

import Login from './Login2';
import Resources from './Resources';
import Groups from './Groups';
import * as OperationsStore from '../store/OperationsStore';

import { ApplicationState } from '../store';
import * as PageStore from '../store/Page';
import * as UserProfileStore from '../store/UserProfile';
import * as ReportsStore from '../store/Reports';
import { RouteComponentProps } from 'react-router';
import styles from '../resources/Page.module.less';
import { SPointsFilter, Point, ICommand, USER_RIGHTS } from "../decl"
import * as Const from '../utils/Const';
import * as Common from '../utils/Common';
const { Option } = Select;
const { Search } = Input;

import {
    SyncOutlined,
    TableOutlined,
    FileZipOutlined,
    PlusCircleOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { MiddleContentMessageBlock } from './HeaderForm';
import RangePickerLimit from './Helper/RangePickerLimit';

const actionCreators = { ...PageStore.actionCreators, ...UserProfileStore.actionCreators, ...ReportsStore.actionCreators, ...OperationsStore.actionCreators };

type HeaderProps =
    { userProfile: UserProfileStore.UserProfileState, reports: ReportsStore.IReportsState, operations: OperationsStore.IOperationsState, windowWidth: number,} &
    typeof actionCreators &
    RouteComponentProps<{}>

interface HeaderState {
    filter: SPointsFilter;
    dateFrom: Date;
    dateTo: Date;
}

class OperationsHeaderForm extends React.PureComponent<HeaderProps, HeaderState>{
    constructor(props: HeaderProps) {
        super(props);
        this.state = {
            dateFrom: moment(new Date()).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
            dateTo: moment(new Date()).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).add(30, 'days').toDate(),
            filter: {
                groups: (props?.userProfile?.profile?.Common?.Groups) ?? null,
                points: (props?.userProfile?.profile?.Common?.Points) ?? '',
                resources: (props?.userProfile?.profile?.Common?.Resources) ?? null,
                systemNumbers: null,
                text: ''
            }
        }
    }

    componentDidMount() {
        this.props.requestAbonentOperationTypes();
        this.props.requestAbonentsOperations(this.state.filter, this.state.dateFrom, this.state.dateTo);
    }

    onRefresh = () => {
        if (this.props.userProfile) {
            const common: any = {
                ...this.props.userProfile.profile.Common,
                Groups: this.state.filter.groups,
                Points: this.state.filter.points,
                Resources: this.state.filter.resources
            };
            this.props.changeProfile(Const.PROFILE_COMMON, common);
        }
        this.props.requestAbonentsOperations(this.state.filter, this.state.dateFrom, this.state.dateTo);
    };
    onValueChange = (groups: any, points: any) => {
        this.setState({
            filter: {
                ...this.state.filter,
                groups: (groups.indexOf(Const.ALL_GROUP_ID) >= 0) ? null : Common.arrayToString(groups),
                points: Common.arrayToString(points)
            }
        });
    };
    onSelectPoint = (pointId: string) => {
        this.setState({
            filter: {
                ...this.state.filter,
                points: pointId
            }
        });
        if (this.props.userProfile) {
            this.props.changeProfile(Const.PROFILE_COMMON, { Points: pointId });
            const point: Point = this.props.userProfile.allPoints[pointId];
            this.props.setPoint(point.id, point.number);
        }

        this.props.setNeedRefresh(true);
    };

    render() {
        const hasEditRight: boolean = (this.props?.userProfile?.rights.includes(USER_RIGHTS.EditAbonentOperations)) ?? false;
        const selectedGroupIds = (this.state.filter.groups != null) ?
            Common.stringToArray(this.state.filter.groups) :
            [Const.ALL_GROUP_ID];
        const selectedPointIds = (this.state.filter.points != null) ?
            Common.stringToArray(this.state.filter.points) :
            [];
        const GroupsProps2 = {
            groups: (this.props.userProfile) ? this.props.userProfile.userGroups : [],
            groupPoints: (this.props.userProfile) ? this.props.userProfile.groupPoints : {},
            selectedGroupIds: selectedGroupIds,
            selectedPointIds: selectedPointIds,
            getPoints: this.props.requestGroupPoints,
            getPointsParentGroups: null,
            onSelectPoint: this.onSelectPoint,
            onChange: this.onValueChange,
            setIsLoading: this.props.setIsLoading,
            treeCheckable: true,
        };

        return (
            this.props.operations === undefined || this.props.operations.abonentOperationTypes === undefined || this.props.operations.abonentsOperations === undefined
                || this.props.operations.isLoadingAbonentsOperations === true ? null :
            <div className={styles.flexcontainer}>
                <div className={styles.buttonsblock}>
                    <div className={styles.flexitem}><Groups {...GroupsProps2} /></div>
                    <div className={styles.flexitem}>
                        <RangePickerLimit
                            style={{ minWidth: '280px' }}
                            value={[this.state.dateFrom, this.state.dateTo]}
                            allowClear={false}
                            onChange={(val) => { this.setState({ dateFrom: val?.[0] ?? new Date(), dateTo: val?.[1] ?? new Date() }) }}
                        />
                    </div>

                    <Button onClick={this.onRefresh} className={styles.flexitem} icon={<SyncOutlined />} type="primary" shape="round">Обновить</Button>

                    <Button disabled={!hasEditRight} onClick={() => this.props.showDialogAddNewAbonentOperation(true)} className={styles.flexitem} icon={<PlusCircleOutlined />} type="primary" shape="round">Добавить запись</Button>
                </div>
                <div className={styles.mbitem}>
                    <MiddleContentMessageBlock windowSize={this.props.windowWidth} maxWindowSize={1460} onlyIcon={this.props.windowWidth < 1890} hideEmail={false} isSplit={false}/>
                </div>
                <div className={styles.flexuseritem}>
                    <Login />
                </div>
            </div>
        )
    }
}



export default connect(
    (state: ApplicationState) => {
        return {
            userProfile: state.userProfile,
            operations: state.operations,
            windowWidth: state.page.windowWidth,
        }
    },
    actionCreators
)(OperationsHeaderForm as any);
