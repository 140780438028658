import { message, makeTime } from '../utils/Common';
import moment from 'moment';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { ResponseStatusesEnum, SResponseDTO } from '../decl';
import { BACKEND_URL, getHeaders } from '../utils/AuthUtils';
import { CloseSessionAction } from './Page';

//Состояние хранилища для страницы Weather.
export interface IWeatherState {
    isLoading: boolean,
    dateFrom: Date,
    dateTo: Date,
    currentWeatherStationId: number | undefined,
    weatherStations: SWeatherStationDTO[] | undefined,
    needRefresh: boolean
}


export interface SWeatherStationDTO {
    id: number,
    name: string,
    lat: number,
    lon: number,
    siteDataPath: string,
    timeZone: number

}

interface SetState { type: 'SET_WEATHER_STATE'; value: Partial<IWeatherState> };
interface ReceiveWeatherStations { type: 'RECEIVE_WEATHER_STATIONS'; value: SWeatherStationDTO[] | undefined }

export type KnownAction = CloseSessionAction | SetState | ReceiveWeatherStations;

export const actionCreators = {
    requestWeatherStations: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({ type: 'SET_WEATHER_STATE', value: { isLoading: true } });

        if (appState) {
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
            };
            fetch(BACKEND_URL + 'common/weatherstations', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        dispatch({ type: 'RECEIVE_WEATHER_STATIONS', value: data.body })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                    }
                    else {
                        message.error('Ответ не получен.');
                    }
                    dispatch({ type: 'SET_WEATHER_STATE', value: { isLoading: false } });
                })
                .catch(error => {
                    message.error('Ошибка:' + error);
                    console.log(error);
                    dispatch({ type: 'SET_WEATHER_STATE', value: { isLoading: false } });
                });

        }
    },

    setWeatherDates: (dateFrom: Date, dateto: Date) => ({ type: 'SET_WEATHER_STATE', value: { dateFrom: dateFrom, dateTo: dateto, needRefresh: true } } as SetState),
    setCurrentWeatherStationId: (id: number) => ({ type: 'SET_WEATHER_STATE', value: { currentWeatherStationId: id, needRefresh: true } } as SetState),
    setNeedRefresh: (needRefresh: boolean) => ({ type: 'SET_WEATHER_STATE', value: { needRefresh: needRefresh } } as SetState),
}

export const reducer: Reducer<IWeatherState> = (state: IWeatherState | undefined, incomingAction: Action): IWeatherState => {
    if (state === undefined) {
        return {
            isLoading: false, dateFrom: moment(makeTime(new Date(), 0, 0, 0)).add(-35, 'days').toDate(), dateTo: makeTime(new Date(), 23, 59, 59),
            currentWeatherStationId: undefined, weatherStations: undefined, needRefresh: false
        };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "SET_WEATHER_STATE":
            return {
                ...state,
                ...action.value
            };
        case "RECEIVE_WEATHER_STATIONS":
            return {
                ...state,
                weatherStations: action.value,
                currentWeatherStationId: action.value?.length ? (action.value.length > 0 ? action.value[0].id : undefined) : undefined
            };
        default:
            return state;
    }
};
