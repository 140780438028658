import {Action, Reducer} from 'redux';
import {PeriodsEnum, SChartParameter} from "../decl";

//Состояние хранилища для страницы Графики
export interface IChartsState {
  needRefresh: number;//когда надо обновить при  смене параметров формы: ТУ, период, даты
  pointId: string;
  period: PeriodsEnum;
  dates: any;
  checkedParameters?: string[];//выбранные параметры
  doExport: number;
}

export interface SetNeedRefreshAction {
  type: 'SET_CHART_NEED_REFRESH',
  needRefresh: number
};

export interface SetPointAction {
  type: 'SET_CHART_POINT',
  pointId: string
};
export interface SetPeriodAction {
  type: 'SET_CHART_PERIOD',
  period: PeriodsEnum
};
export interface SetDatesAction {
  type: 'SET_CHART_DATES',
  dates: any
};
// export interface SetParamsAction {
//   type: 'SET_CHART_PARAMS';
//   paramIds: string[];
// };

export interface ChartsExportAction {
  type: 'CHARTS_EXPORT';
  doExport: number;
};

export type KnownAction =
  SetNeedRefreshAction
  | SetPointAction
  | SetPeriodAction
  | SetDatesAction
  // | SetParamsAction
  | ChartsExportAction;

export const actionCreators = {
  setChartNeedRefresh: () => ({type: 'SET_CHART_NEED_REFRESH', needRefresh: Date.now()} as SetNeedRefreshAction),
  setChartPoint: (pointId: string) => ({type: 'SET_CHART_POINT', pointId: pointId} as SetPointAction),
  setChartPeriod: ( period: PeriodsEnum) => ({type: 'SET_CHART_PERIOD', period: period} as SetPeriodAction),
  setChartDates: (dates: any ) => ({type: 'SET_CHART_DATES', dates: dates} as SetDatesAction),
  // setChartCheckedParameters: (paramIds: string[]) => (
  //   {type: 'SET_CHART_PARAMS', paramIds: paramIds} as SetParamsAction
  // ),
  chartsExport: (doExport: number ) => ({type: 'CHARTS_EXPORT', doExport: doExport} as ChartsExportAction),
};
export const reducer: Reducer<IChartsState> = (state: IChartsState | undefined, incomingAction: Action): IChartsState => {
  if (state === undefined)
	return {
	  needRefresh: 0,
	  pointId: '',
	  period: PeriodsEnum.current,
	  dates: null,
	  checkedParameters: [],
	  doExport: 0,
	};

  const action = incomingAction as KnownAction;


  switch (action.type) {
	case 'SET_CHART_NEED_REFRESH':
	  return {...state, needRefresh: action.needRefresh};
	case 'SET_CHART_POINT':
	  return {...state, pointId: action.pointId};
	case 'SET_CHART_PERIOD':
	  return { ...state, period: action.period };
	case 'SET_CHART_DATES':
	  return { ...state, dates: action.dates };
	// case 'SET_CHART_PARAMS':
	//   return { ...state, checkedParameters: action.paramIds};
	case 'CHARTS_EXPORT':
	  return { ...state, doExport: action.doExport};
	default:
	  return state;
  }
};
