import * as React from 'react';
import { connect } from 'react-redux';

import { Row, Col, Modal, Typography, Tabs, Button, Table, Input, Tooltip, Switch, Divider } from 'antd';
import {
    EditTwoTone,
    DeleteTwoTone,
    DownloadOutlined,
    QuestionCircleTwoTone,
    ExclamationCircleOutlined,
    LoadingOutlined,
    PlusCircleTwoTone,
    ScheduleTwoTone,
    PlayCircleTwoTone,
    StopTwoTone
} from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';

import { ApplicationState } from '../../store';
import * as PageStore from '../../store/Page';
import * as TasksStore from '../../store/Tasks';
import * as UserProfileStore from '../../store/UserProfile';
import * as SettingsStore from '../../store/SettingsStore';
import * as AdminStore from '../../store/AdminStore';
import { BaseProfile, Group, Point, Resource, SUserProfileDTO, USER_RIGHTS, SUserDTO, Command, PeriodsEnum, MoveOperationsEnum } from '../../decl';
import * as Const from '../../utils/Const';
import {getModalZIndex, message } from '../../utils/Common';
import styles from '../../resources/Page.module.less';
import PointsControlTree, { NodeTypesEnum } from '../PointsControlTree';
import SelectForm from './SelectForm';
import {GroupForm, GroupFormProps, GroupValues} from './GroupForm';
import PointEditForm from './PointEditForm'
import QueryItemsForm, { startQueryItem, stopQueryItem } from './QueryItemsForm';
import { requestQueryPointData } from './QueryItemsForm';
import PointSelectTable from './PointSelectTable';

import { sendRequestToBackend } from '../../utils/AuthUtils';
import PollingForm from './PollingForm';
import { getAllSelectedPoints } from '../../utils/TreeUtils';
import { PointAllInfoDTO, SQueryItemDTO, PointDeviceInfo, PointConnectionInfo } from '../../store/AdminStore';
import { Description } from '@material-ui/icons';

const actionCreators = { ...PageStore.actionCreators, ...UserProfileStore.actionCreators, ...AdminStore.actionCreators, ...TasksStore.actionCreators };


type PointsManagerProps =
    {
        page: PageStore.PageState,
        tasks: TasksStore.TasksState,
        settings: SettingsStore.ISettingsState,
        profile: BaseProfile,
        userProfile: UserProfileStore.UserProfileState,
        pointId: string,
        adminStore: AdminStore.IAdminState,
        isMenuCollapsed: boolean,
        windowWidth: number,
        windowHeight: number,
    } &
    typeof actionCreators

type SelectObjectsModalType = {
    visible: boolean,
    zIndex: number,
    group: Group | null,
    onlyGroupsSelect: boolean,
    selectedPointsIds: string[],
    selectedGroupsIds: string[]
}

interface IPointsManagerState {
    nodeType: NodeTypesEnum,
    selectedOb: Point | Group | null,
    showPointEditForm: boolean,
    modalZIndex: number,
    pointGroup: Group | null,
    pointAllInfo: PointAllInfoDTO | undefined,

    queryItems: SQueryItemDTO[] | undefined,

    groupFormIsOpen: boolean,
    isNewGroup: boolean,
    groupValues: GroupValues | undefined,
    group: Group | null,

    selectObjectsModal: SelectObjectsModalType
    showQueryItemsFormPoint: Point | null,

    pollingAbonents: Point[] | null,
    pollingGroupName: string | null
    filter: string,
    fullLoading: boolean,
    isQueryItemsChanged: boolean,
    pointSelectVisible: boolean,
}

interface DataType {
    key: React.ReactNode;
    name: string;
    age: number;
    address: string;
    children?: DataType[];
};

const data: any = [];

class PointsManager extends React.PureComponent <PointsManagerProps, IPointsManagerState > {

    constructor(props: PointsManagerProps){
        super(props);
        
        this.state = {
            nodeType: NodeTypesEnum.Root,
            selectedOb: null,
            showPointEditForm: false,
            modalZIndex: 0,
            pointGroup: null,
            pointAllInfo: undefined,
            queryItems: undefined,

            groupFormIsOpen: false,
            isNewGroup: false,
            groupValues: undefined,
            group: null,

            selectObjectsModal: {visible: false, zIndex: 0, group: null, onlyGroupsSelect:false, selectedPointsIds: [], selectedGroupsIds: []},
            showQueryItemsFormPoint: null,

            pollingAbonents: null,
            pollingGroupName: null,
            filter: '',
            fullLoading: false,
            isQueryItemsChanged: false,
            pointSelectVisible: false,

        }
    }
    componentDidMount() {
        this.setPoint();
        this.props.requestPointDicts();
        this.props.requestQueryItemTypes();
    }

    componentDidUpdate(prevProps: PointsManagerProps, prevState: IPointsManagerState) {
        //console.log("didupdate", this.props, this.state);
        if (prevProps.adminStore.isPointWriteComplete === false &&
            this.props.adminStore.isPointWriteComplete === true &&
            this.props.adminStore.pointAllInfo !== undefined && 
            this.props.adminStore.pointAllInfo.pointInfo.id) {
            this.loadUserProfile();
        }
        else if (this.props.pointId !== prevProps.pointId) {
            this.setPoint();
        }

    }

    setPoint = ()=>{
        if (this.props.pointId) {
            const point: Point | null = this.props.userProfile.allPoints[this.props.pointId];
            this.setState({ selectedOb: point, nodeType: NodeTypesEnum.Point });
        }
        else {
            this.setState({ selectedOb: null, nodeType: NodeTypesEnum.Root });
        }
    }

     selectPoint = (point: Point) => {
         if (this.props.userProfile) {
             const userPoint: Point = this.props.userProfile.allPoints[point.id];
             if (userPoint) {
                 this.props.changeProfile(Const.PROFILE_COMMON, { Points: userPoint.id });
                 this.props.setPoint(userPoint.id, userPoint.number);
             }
         }
     };

    loadUserProfile = () =>{
        this.props.requestAllUserProfile(
            false,
            () => {
                if (this.props.adminStore.pointAllInfo && this.props.adminStore.pointAllInfo.pointInfo.id) {
                    const userPoint: Point = this.props.userProfile.allPoints[this.props.adminStore.pointAllInfo.pointInfo.id];
                    if (userPoint) {
                        this.props.setPoint(userPoint.id, userPoint.number);
                    }
                }
                this.setState({ fullLoading: true });
            },
            () => {
                message.warning("Не удалось загрузить профиль пользователя.");
            }
        );
    }

    onSelect = (ob: Point | Group | null, nodeType: NodeTypesEnum) => {
        if (nodeType === NodeTypesEnum.Point && ob != null) {
            this.selectPoint(ob as Point);
            this.loadQueryItems(ob as Point);
            this.props.requestPointAllInfo(ob.id, false, () => {
                this.setState({ pointAllInfo: this.props.adminStore.pointAllInfo });
            });

        }
        else {
        }
        this.setState({ selectedOb: ob, nodeType: nodeType, queryItems: undefined });
    };

    createGroup = (parent: Group | null) => {
        this.setState({group: parent, groupFormIsOpen: true, isNewGroup:true, groupValues:{title: '', description: ''}});
    }

    editGroup = (group: Group) => {
        this.setState({group: group, groupFormIsOpen: true, isNewGroup: false, groupValues:{title: group.name, description: ''}});
    }

    removeGroup = (group: Group, parent: Group) => {
        Modal.confirm({
            title: parent ? 'Удаление группы из группы' : 'Удаление корневой группы',
            wrapClassName: Const.MODAL_WRAP_CLASSNAME,
            zIndex: getModalZIndex(),
                content: <div>Вы собираетесь удалить группу '{group.name}' {parent ? " из группы '" + parent.name + "'": " из корневых групп пользователя"}.
                Группа останется в системе, но будет <span style = {{color:'red'}}>НЕ ДОСТУПНА</span> здесь.
                Если она нигде больше не представлена, то пользователь не будет иметь доступа к точкам учёта из неё.</div>,
            width: 550,
            okText: 'Удалить',
            cancelText: 'Отмена',
            onOk: () => {
                const fromGroup = parent;
                this.moveGroup(group, parent, undefined, MoveOperationsEnum.Remove);
            },
            onCancel: () => {
            }
        });
    }

    deleteGroup = (group: Group) => {
        Modal.confirm({
            title: 'Удаление группы из системы',
            wrapClassName: Const.MODAL_WRAP_CLASSNAME,
            zIndex: getModalZIndex(),
            content: <div style = {{color:'red'}}>Вы собираетесь удалить группу '{group.name}'.
            Точки учёта из этой группы останутся в системе, но будут доступны только из других групп. 
            Вы уверены, что хотите УДАЛИТЬ группу?</div>,
            width: 400,
            okText: 'Удалить',
            cancelText: 'Отмена',
            onOk: () => {
                this.deleteGroupAccept(group);
            },
            onCancel: () => {
            }
        });
    }

    removePoint = (point: Point, group: Group) => {
        Modal.confirm({
            title: 'Удаление точки учёта из группы',
            wrapClassName: Const.MODAL_WRAP_CLASSNAME,
            zIndex: getModalZIndex(),
                content: <div>Вы собираетесь убрать точку учёта '{point.number}' из группы '{group.name}'. Точка учёта останется в системе, но будет не доступна для этой группы.</div>,
            width: 400,
            okText: 'Убрать',
            cancelText: 'Отмена',
            onOk: () => {
                const fromGroup = group;
                this.movePoint(point, group, undefined, MoveOperationsEnum.Remove);
                if(fromGroup)this.props.requestGroupPoints(fromGroup.id, ()=>{
                }, true);
            },
            onCancel: () => {
            }
        });
    }

    addGroups = (group: Group | null, parent: Group | null) => {
        this.setState({selectObjectsModal:{
            visible:true,
            zIndex: getModalZIndex(),
            group: group,
            onlyGroupsSelect: true,
            selectedGroupsIds: (group === null ? (this.props.userProfile.userGroups ? this.props.userProfile.userGroups.map(g => g.id) : []) : []),
            selectedPointsIds:[]}});
    }

    addPoints = (group: Group) => {
        this.setState({selectObjectsModal:{visible:true, zIndex: getModalZIndex(), group: group, onlyGroupsSelect: false, selectedGroupsIds:[],selectedPointsIds:[]}});
    }

    changePointPosition = (group:Group, point:Point, afterPoint: Point| undefined) => {
        let lowerId;
        if(afterPoint == null){
            const points: Point[] = this.props.userProfile.groupPoints[group.id];
            if(points.length > 0){
                lowerId = points[0].id;
            }
        } 

        sendRequestToBackend(
            { userId: this.props.userProfile.user, parentId: group.id, id: point.id, upperId: afterPoint?.id, lowerId: lowerId },
            'points/changeposition',
            (points: Point[]) => {
                this.loadUserProfile();
            },
            this.props.setIsLoading,
            ()=>{

            },
            ()=>{
                
            },
            500
        );
    }

    changeGroupPosition = (parent:Group, group:Group, afterGroup: Group| undefined) => {
        let lowerId;
        if(afterGroup == null){
            if(parent.childGroups.length > 0){
                lowerId = parent.childGroups[0].id;
            }
        }

        sendRequestToBackend(
            { userId: this.props.userProfile.user, parentId: parent?.id, id: group.id, upperId: afterGroup?.id, lowerId: lowerId},
            'points/changegroupposition',
            () => {
                this.loadUserProfile();
            },
            this.props.setIsLoading,
            ()=>{

            },
            ()=>{
                
            },
            500
        );
    }

    createGroupAccept = (parent:Group | null, groupName:string, groupDescription: string) =>{
        const userId = this.props.userProfile.user?.userKey;
        sendRequestToBackend(
            {userId: userId, parentId: parent ? parent.id : null, groupName: groupName, groupDescription: groupDescription},
            'points/creategroup',
            () => {
                message.success('Группа создана!');
                this.loadUserProfile();
            },
            this.props.setIsLoading,
            (error: string)=>{
                message.warning(error);
            },
            ()=>{
                
            },
            500
        );
    }

    changeGroupAccept = (group:Group, groupName:string, groupDescription: string) => {
        sendRequestToBackend(
            {groupId: group.id, groupName: groupName, groupDescription: groupDescription},
            'points/changegroup',
            () => {
                message.success('Группа изменена!');
                this.loadUserProfile();
            },
            this.props.setIsLoading,
            (error: string)=>{
                message.warning(error);
            },
            ()=>{
                
            },
            500
        );
    }
    
    deleteGroupAccept = (group:Group) =>{
        sendRequestToBackend(
            group.id,
            'points/deletegroup',
            () => {
                message.success('Группа удалена!');
                this.loadUserProfile();
            },
            this.props.setIsLoading,
            (error: string)=>{
                message.warning(error);
            },
            ()=>{
                message.error('Ошибка при удалении группы.');
            },
            500
        );
    }

    movePoint = (point: Point, fromGroup: Group | undefined, toGroup: Group | undefined, operation: MoveOperationsEnum) => {

        sendRequestToBackend(
            { id: point.id, fromId: fromGroup?.id, toId: toGroup?.id, operation: operation },
            'points/movepoint',
            () => {
                this.loadUserProfile();
            },
            this.props.setIsLoading,
            (error: string)=>{
                message.warning(error);
            },
            ()=>{
                message.error('Ошибка при перемещении точки учёта.');
            },
            500
        );
    }

    moveGroup = (group: Group, fromGroup: Group | undefined, toGroup: Group | undefined, operation: MoveOperationsEnum) => {
        this.setState({fullLoading: false});
        sendRequestToBackend(
            { userId: this.props.userProfile.user?.userKey, id: group.id, fromId: fromGroup?.id, toId: toGroup?.id, operation: operation },
            'points/movegroup',
            () => {
                message.success(toGroup ? 'Подгруппа перемещена.' : 'Подгруппа исключена из группы.');
                this.loadUserProfile();
            },
            this.props.setIsLoading,
            (error: string)=>{
                message.warning(error);
            },
            ()=>{
                message.error('Ошибка при перемещении группы.');
            },
            500
        );
    }

    addGroupPoints =  (group: Group, pointIds: string[]) => {
        sendRequestToBackend(
            {groupId: group.id, childIds: pointIds},
            'points/addpoints',
            () => {
                message.success('Точки учёта добавлены!');
                this.loadUserProfile();
            },
            this.props.setIsLoading,
            (error: string)=>{
                message.warning(error);
            },
            ()=>{
                message.error('Ошибка при добавлении точек учёта в группу.');
            },
            500
        );
    }

    addGroupGroups = (group: Group, groupIds: string[]) => {
        sendRequestToBackend(
            {groupId: group.id, childIds: groupIds},
            'points/addgroups',
            () => {
                message.success('Группы добавлены!');
                this.loadUserProfile();
            },
            this.props.setIsLoading,
            (error: string)=>{
                message.warning(error);
            },
            ()=>{
                message.error('Ошибка при добавлении групп в группу.');
            },
            500
        );
    }

    setUserGroups = (user: SUserDTO | undefined, groupIds: string[]) => {
        if(groupIds.includes(Const.ALL_GROUP_ID)){
            groupIds = this.props.userProfile.userGroups.map(g=>g.id);
        }

        sendRequestToBackend(
            {userId: user?.userKey, childIds: groupIds},
            'points/setusergroups',
            () => {
                message.success('Группы для пользователя установлены!');
                //this.loadUserProfile();
                this.props.requestAllUserProfile(false, ()=> {});
            },
            this.props.setIsLoading,
            (error: string)=>{
                message.warning(error);
            },
            ()=>{
                message.error('Ошибка при добавлении групп в группу.');
            },
            500
        );
    }
    
    onGroupsSelect = (groups: Group[]) => {
        const groupIds: string[] = groups.map(g=>g.id);
        const group = this.state.selectObjectsModal.group;
        if(group){
            const addIds: string[] = [];
            const existGroups: Group[] = [];

            for(let addGroupId of groupIds){
                const existGroup = group.childGroups.find(g=>g.id === addGroupId);
                if(existGroup){
                    existGroups.push(existGroup);
                }
                else{
                    addIds.push(addGroupId);
                }
            }
            Modal.confirm({
                title: 'Добавление групп в группу',
                wrapClassName: Const.MODAL_WRAP_CLASSNAME,
                zIndex: getModalZIndex(),
                    content: <div>Вы собираетесь добавить группы в группу '{group.name}'.  <br/>
                        Выбрано: {groupIds.length}. Из них уже есть: {existGroups.length}. Будет добавлено: {addIds.length}. </div>,
                width: 600,
                okText: 'Добавить',
                cancelText: 'Отмена',
                onOk: () => {
                    this.addGroupGroups(group, addIds);
                },
                onCancel: () => {
                }
            });
            }
        else{
            Modal.confirm({
                title: 'Установка групп для пользователя',
                wrapClassName: Const.MODAL_WRAP_CLASSNAME,
                zIndex: getModalZIndex(),
                content: <div>Вы собираетесь установить группы для пользователя '{this.props.userProfile?.user}'. </div>,
                width: 600,
                okText: 'Установить',
                cancelText: 'Отмена',
                onOk: () => {
                    this.setUserGroups(this.props.userProfile.user, groupIds);
                },
                onCancel: () => {
                }
            });
    
        }

        this.setState({selectObjectsModal:{visible:false, zIndex: 0, group: null, onlyGroupsSelect: true, selectedGroupsIds:[],selectedPointsIds:[]}});
    };

    //Добавление точек учёта в группу.
    onPointsSelect = (points: Point[]) => {
        const pointIds: string[] = points.map(p=>p.id);
        if(this.state.selectObjectsModal.group != null)
        {
            const group: Group = this.state.selectObjectsModal.group;
            this.props.requestGroupPoints(
                group.id,
                (groupPoints: Point[])=>{
                    const addIds: string[] = [];
                    const existPoints: Point[] = [];
        
                    for(let addPointId of pointIds){
                        const existPoint = groupPoints.find(p=>p.id === addPointId);
                        if(existPoint){
                            existPoints.push(existPoint);
                        }
                        else{
                            addIds.push(addPointId);
                        }
                    }

                    Modal.confirm({
                        title: 'Добавление точек учёта в группу',
                        wrapClassName: Const.MODAL_WRAP_CLASSNAME,
                        zIndex: getModalZIndex(),
                            content: <div>Вы собираетесь добавить точки учёта в группу '{group.name}'.  <br/>
                             Выбрано: {pointIds.length}. {existPoints.length ==- 0 ? '' : 'Из них уже есть: ' + existPoints.length + '. Будет добавлено: ' + addIds.length + '.'} </div>,
                        width: 600,
                        okText: 'Добавить',
                        cancelText: 'Отмена',
                        onOk: () => {
                            this.addGroupPoints(group, addIds);
                        },
                        onCancel: () => {
                        }
                    });
                },
                false);

                this.setState({selectObjectsModal:{visible:false, zIndex: 0, group: null, onlyGroupsSelect: false, selectedGroupsIds:[],selectedPointsIds:[]}});
            }

    };

    onPointWrite = (close: boolean, finish: (success: boolean, point: PointAllInfoDTO) => void) => {
        if (this.props.adminStore.pointAllInfo !== undefined) {
            if (this.props.adminStore.pointAllInfo.pointInfo.id === null) {
                this.props.requestPointCreate(this.props.adminStore.pointAllInfo, this.state.pointGroup?.id, false, this.props.adminStore.queryItem, (point: PointAllInfoDTO) => {
                    finish ? finish(true, point) : null;
                    if (close) {
                        this.setState({ showPointEditForm: false });
                    }
                });
            } else {
                this.props.requestPointWrite(this.props.adminStore.pointAllInfo, (s: boolean, point: PointAllInfoDTO) => {
                    finish ? finish(s, point) : null;
                    if (s === true && close) {
                        this.setState({showPointEditForm: false});
                    }
                });
            }
        }
    }
 
    readPointSettings = (pointId: string | undefined | null, success?: () => void) => {
        if(pointId){
            //this.props.setIsLoading(true, 200, "Сбор данных с прибора, это может занять несколько минут.");
            const point: Point = this.props.userProfile.allPoints[pointId];
            this.props.addPointLog(point);

            this.props.readData(
                0,
                [pointId],
                100,
                true,
                (code: number, ids: any) => {
                    if (code === 0) {
                        message.success('Запрос на получение данных из приборов отправлен.');
                    }
                    if (code === 2) {
                        //this.props.setIsLoading(false);
                        message.success('Данные из приборов получены.');
                        success ? success() : null;
                    }
                    if (code === 4) {
                        message.warning('Не все данные из приборов удалось получить.'   );
                        //this.props.setIsLoading(false);
                        success ? success() : null;
                    }
                },
                (error: string) => {
                    //this.props.setIsLoading(false);

                    message.error(error);
                });
        }
        else{
            message.error('Точка учёта для сбора настроек не определена');
        }
    }

    //Создание точки учёта.
    createPoint = (parent: Group | null) => {
        this.setState({showPointEditForm: true, modalZIndex: getModalZIndex(), pointGroup: parent});
        this.props.requestPointDicts();
        this.props.requestQueryItemTypes();
        this.props.requestPointAllInfo(null, false);
    }

    //Редактирование точки учёта.
    editPoint = (point: Point) => {
        this.setState({showPointEditForm: true, modalZIndex: getModalZIndex()});
        this.props.requestPointDicts();
        let id = point.id;
        this.props.requestPointAllInfo(id, false);
    }

    //Удаление точки учёта.
    deletePoint = (point: Point) => {
        Modal.confirm({
            title: 'Удаление точки учёта',
            wrapClassName: Const.MODAL_WRAP_CLASSNAME,
            zIndex: getModalZIndex(),
                content: <div style = {{color:'red'}}>Вы собираетесь удалить точку учёта '{point.number}'.
                 По этой команде точка учёта и все её архивы будут безвозвратно удалены из системы. 
                 Вы уверены, что хотите УДАЛИТЬ точку учёта?</div>,
            width: 400,
            okText: 'Удалить',
            cancelText: 'Отмена',
            onOk: () => {
                sendRequestToBackend(
                    point.id,
                    'admin/pointdelete',
                    (response: any) => {
                        if (response != null && response === true) {
                            message.success("Точка учёта '" + point.number + "' удалена!");
                            this.props.requestPointAllInfo(null, false);
                            this.loadUserProfile();
                        } else {
                            message.warning("Точку учёта '" + point.number + "' удалить не удалось!");
                        }
                    },
                    this.props.setIsLoading,
                    (error: any) => {
                        message.warning("Точку учёта '" + point.number + "' удалить не удалось! Исключение при выполнении.");
                        console.log(error);
                        message.error(error);
                    }
                );
            },
            onCancel: () => {

            }
        });
    }

    //Копирование точки учёта.
    copyPoint = (point: Point, group: Group) => {
        this.setState({showPointEditForm: true, modalZIndex: getModalZIndex(), pointGroup: group});
        this.props.requestPointDicts();
        this.props.requestQueryItemTypes();
        let id = point.id;
        this.props.requestPointAllInfo(id, true);
    }

    queryItems = (point: Point) => {
        this.props.requestQueryItemTypes();
        this.setState({showQueryItemsFormPoint: point, modalZIndex: getModalZIndex()});
    }

    polling = (point: Point) => {
        this.setState({pollingAbonents: [point], pollingGroupName: null, modalZIndex: getModalZIndex()});
    }

    pollingGroup = (parent: Group | null) => {
        if (parent != null && this.props.userProfile.userGroups) {
            this.props.setIsLoading(true, 500);
            getAllSelectedPoints(this.props.userProfile.userGroups, [parent.id], [], this.props.requestGroupPoints)
            .then(allPoints => {
                this.props.setIsLoading(false);
                this.setState({pollingAbonents: allPoints, pollingGroupName: parent.name, modalZIndex: getModalZIndex()});
            });
        }
    }

    loadQueryItems = (point: Point) => {
        sendRequestToBackend(
            point.id,
            'admin/queryitems',
            (response: any) => {
                if (response != null) {
                    this.setState({ queryItems: response });
                } else {
                    message.warning("Не удалось получить список заданий на опрос");
                }
            },
            this.props.setIsLoading,
            (error: any) => {
                message.warning("Ошибка при получении заданий на опрос");
                console.log(error);
            }
        );

    }

    writeQueryItem = (item: SQueryItemDTO) =>{
        sendRequestToBackend(
            item,
            'admin/queryitemwrite',
            (response: any) => {
                if (response != null) {
                    this.loadQueryItems(this.state.selectedOb as Point);
                } else {
                    message.warning("Не удалось изменить задание");
                }
            },
            this.props.setIsLoading,
            (error: any) => {
                message.warning("Ошибка при изменении задания");
                console.log(error);
            }
        );
    }

    public render() {
        const isAdmin: boolean = this.props.userProfile.rights.indexOf(USER_RIGHTS.Admin) >= 0;
        const canViewPoint: boolean = this.props.userProfile.rights.indexOf(USER_RIGHTS.AbonentDetails) >= 0;
        const canEditPoint: boolean = this.props.userProfile.rights.indexOf(USER_RIGHTS.CanEditAbonents) >= 0;
        const canAddPoints: boolean = this.props.userProfile.rights.indexOf(USER_RIGHTS.CanAddAbonents) >= 0;

        let cancelDropMessage: string | undefined;
        if (this.props.userProfile.profile.Reports?.AbonentsAndGroupsSortBy === "SortByNumber") {
            cancelDropMessage = 'У Вас выбрана опция сортировки "По алфавиту" в разделе "Настройки" вкладка "Отображение на сайте". Смените данную настройку на "В заданном порядке" для использования опции перемещения объектов в дереве.';
        }

        const rootCommands: Command[] = (isAdmin || canAddPoints) ? [
            { key: 'addGroup', caption: "Выбрать группы", action: this.addGroups },
            { key: 'createGroup', caption: "Создать группу", action: this.createGroup },
            { key: 'createPoint', caption: "Создать точку учёта", action: this.createPoint }
        ] : [];

        const groupCommands: Command[] = (isAdmin || canAddPoints) ? [
            { key: 'editGroup', caption: "Изменить группу", action: this.editGroup },
            { key: 'createGroup', caption: "Создать группу", action: this.createGroup },
            { key: 'addGroup', caption: "Добавить группы", action: this.addGroups },
            { key: 'removeGroup', caption: "Убрать группу из группы", action: this.removeGroup },
            { key: 'deleteGroup', caption: "Удалить группу из системы", action: this.deleteGroup },
            { key: 'addPoint', caption: "Добавить точки учёта", action: this.addPoints },
            { key: 'createPoint', caption: "Создать точку учёта", action: this.createPoint },
            { key: 'pollingGroup', caption: "Мониторинг опроса", action: this.pollingGroup },
        ] : [];

        let pointCommands: Command[] = [];
        if (isAdmin || canAddPoints) {
            pointCommands = [
                { key: 'editPoint', caption: "Изменить точку учёта", action: this.editPoint },
                { key: 'copyPoint', caption: "Скопировать точку учёта", action: this.copyPoint },
                { key: 'deletePoint', caption: "Удалить точку учёта", action: this.deletePoint },
                { key: 'removePoint', caption: "Убрать точку учёта из группы", action: this.removePoint },
                { key: 'queryItems', caption: "Задания", action: this.queryItems },
                { key: 'polling', caption: "Мониторинг опроса", action: this.polling },
            ];
        }
        else if (canEditPoint) {
            pointCommands = [
                { key: 'editPoint', caption: "Изменить точку учёта", action: this.editPoint },
                { key: 'queryItems', caption: "Задания", action: this.queryItems },
            ];
        }
        else if (canViewPoint) {
            pointCommands = [
                { key: 'editPoint', caption: "Параметры точки учёта", action: this.editPoint },
                { key: 'queryItems', caption: "Задания", action: this.queryItems },
            ];
        }


        const commands: any = {};
        commands[NodeTypesEnum.Root] = rootCommands;
        commands[NodeTypesEnum.Group] = groupCommands;
        commands[NodeTypesEnum.Point] = pointCommands;

        const groupColumns: ColumnsType<DataType> = [
            {
                title: 'Название',
                dataIndex: 'name',
                key: 'name',
                width: '50%'
            },
            {
                title: 'Значение',
                dataIndex: 'value',
                key: 'value',
                width: '50%'
            },
        ];

        
        const queryColumns = [
            {
                dataIndex: "enabled",
                title: "Активность",
                render: (val: any, record: any, index: any) => (
                    <Tooltip placement="top" title={'Задание разрешено'}>
                        <Switch checked={val} onChange={() => onChangeEnabled(record.id)}></Switch>
                    </Tooltip>
                )
            },
            {
                dataIndex: "start",
                title: "",
                render: (text: any, record: any, index: any) => {
                    const isActive: boolean = this.props.tasks.activeTasks.find(t => t.queryId === record.id) !== undefined;
                    const isEnabled = record.schedule && (record.schedule.shdType_Id === AdminStore.IS_DAY_SCHEDULE_ID || record.schedule.shdType_Id === AdminStore.IS_REQUEST_SCHEDULE_ID) &&
                        record.type && (record.type.id !== AdminStore.QueryTypesEnum.configurator &&
                            record.type.id !== AdminStore.QueryTypesEnum.connection_test &&
                            record.type.id !== AdminStore.QueryTypesEnum.calculate_archive_from_current);
                    if (isActive) {
                        return <Tooltip placement="top" title={'Запрос остановки задания'}>
                            <Button disabled={!isEnabled} shape="circle"
                                onClick={()=>stopQueryItem(this.state.queryItems?.find(x => x.id === record.id), this.props.setIsLoading, this.props.getActiveTasks)}
                                icon={<StopTwoTone twoToneColor={Const.COLORS.SvgIconColor} disabled />} />
                        </Tooltip>;
                    }
                    else {
                        return <Tooltip placement="top" title={'Запрос запуска задания'} >
                            <Button disabled={!isEnabled} shape="circle"
                                onClick={
                                    (record.schedule && (record.schedule.shdType_Id === AdminStore.IS_REQUEST_SCHEDULE_ID)) ?
                                        () => requestQueryPointData((this.state.selectedOb as Point)?.id, record.id, record.type, this.props.readData, this.props.setActiveLogTask) :
                                        () => startQueryItem(this.state.queryItems?.find(x => x.id === record.id), this.props.setIsLoading, this.props.setActiveLogTask, this.props.getActiveTasks)
                                }
                                icon={<PlayCircleTwoTone twoToneColor={Const.COLORS.SvgIconColor} />} />
                        </Tooltip >;
                    }
                }
            },
            {
                dataIndex: "schedule",
                title: "Расписание",
                render: (text: any, record: any, index: any) => {
                    return record.schedule?.name;
                }
            },
            {
                dataIndex: "name",
                title: "Название",
            },
            {
                dataIndex: "priority",
                title: "Приоритет",
            },
            {
                dataIndex: "type",
                title: "Тип задания",
                render: (text: any, record: any, index: any) => {
                    return record.type?.description;
                }
            },
        ];

        const pointColumns: ColumnsType<DataType> = [
            {
                title: 'Название',
                dataIndex: 'name',
                key: 'name',
                width: '35%'
            },
            {
                title: 'Значение',
                dataIndex: 'value',
                key: 'value',
                width: '65%'
            },
        ];

        const parametersColumns = [
            {
                dataIndex: "name",
                title: "Параметр"
            },
            {
                dataIndex: "value",
                title: "Значение",
            },
            {
                dataIndex: "description",
                title: "Описание",
                ellipsis: true,
            }
        ];

        const itemsColumns = [
            {
                dataIndex: "name",
                title: "Переменная"
            },
            {
                dataIndex: "unitCaption",
                title: "Размерность"
            },
            {
                dataIndex: "channelSign",
                title: "Трубопровод"
            },
            {
                dataIndex: "sign",
                title: "Наименование",
            },
            {
                dataIndex: "min",
                title: "Min",
            },
            {
                dataIndex: "max",
                title: "Max",
            },
        ];


        const onChangeEnabled = (id: any) => {
            let item = this.state.queryItems?.find(x => x.id === id);
            if (item != undefined) {
                item = { ...item };
                item.enabled = !item.enabled;

                this.writeQueryItem(item);
            }
        }

        const clientHeight = this.props.page.windowHeight - 232;
        const leftRight = clientHeight - 38;
        const topRightHeight = clientHeight * 2 / 5;
        const bottomRightHeight = clientHeight  * 3 / 5;
        const scrollVisibilityTable1 = { y: topRightHeight - 80 };
        const scrollVisibilityTable2_1 = { y: bottomRightHeight - 110 };
        const scrollVisibilityTable2_2 = { y: bottomRightHeight - 150 };

        let treeSelectedPoint: Point | undefined = undefined;
        if (this.state.nodeType === NodeTypesEnum.Point) {
            const point = this.state.selectedOb as Point;
            if (this.props.userProfile.allPoints[point.id]) {
                treeSelectedPoint = point;
            }
        }
        const pointsControlTreeProps = {
            height: leftRight,
            groups: this.props.userProfile.userGroups,
            groupPoints: this.props.userProfile.groupPoints,
            selectedPoint: treeSelectedPoint,
            getPoints: this.props.requestGroupPoints,
            onSelect: this.onSelect,
            commands: commands,
            cancelDropMessage: cancelDropMessage,
            draggable: isAdmin,
            changePointPosition: this.changePointPosition,
            changeGroupPosition: this.changeGroupPosition,
            movePoint: this.movePoint,
            moveGroup: this.moveGroup,
            needReloadGroupIds: [],
            setIsLoading: this.props.setIsLoading,
            adminMode: isAdmin,
            filter: this.state.filter,
            fullLoading: this.state.fullLoading,
            containerStyle: { borderStyle: 'solid', borderWidth: 1, borderColor: '#f0f0f0', minHeight: leftRight },
        };

        const groupFormProps: GroupFormProps = {
            open: this.state.groupFormIsOpen,
            values: this.state.groupValues,
            isNewGroup: this.state.isNewGroup,
            onSave: (values: GroupValues, isNewGroup: boolean) => {
                if (isNewGroup) {
                    this.createGroupAccept(this.state.group, values.title, values.description);
                }
                else if (this.state.group) {
                    this.changeGroupAccept(this.state.group, values.title, values.description);
                }
                this.setState({ groupFormIsOpen: false });
            },
            onCancel: () => {
                this.setState({ groupFormIsOpen: false });
            },
        }
        //Флаг запрета отображения списка. Для администратора добавить себе точки можно только через список.
        const onlyTree = !isAdmin &&
            (this.state.selectObjectsModal.onlyGroupsSelect ?
                Object.keys(this.props.userProfile.groupPoints).length < 10 :
                Object.keys(this.props.userProfile.allPoints).length < 10);

        let selectedTitle = '';
        if (this.state.nodeType == NodeTypesEnum.Point) {
            selectedTitle = (this.state.selectedOb as Point).number;
        }
        else if (this.state.nodeType == NodeTypesEnum.Group) {
            selectedTitle = (this.state.selectedOb as Group).name;
        }
       

        const getGroupData = () => {
            let result: any = [];
            if (this.state.nodeType == NodeTypesEnum.Group) {
                const group = this.state.selectedOb as Group;
                result = [
                    {
                        key: 'description',
                        name: 'Описание',
                        value: group.name
                    },
                    {
                        key: 'numberofpoints',
                        name: 'Общее число точек учёта',
                        value: group.numberOfPoints
                    },
                    {
                        key: 'numberofsubgroups',
                        name: 'Число подгрупп',
                        value: group.childGroups.length
                    },
                ];
            }
            return result;
        }

        const queryData = () => {
            return (this.state.queryItems) ?
                this.state.queryItems.map(x => ({
                    key: x.id,
                    id: x.id,
                    enabled: x.enabled,
                    schedule: this.props.adminStore.queryItemTypes?.scheduleTypes.find(y => y.id === x.shedules_Id),
                    name: x.name,
                    priority: x.priority,
                    type: this.props.adminStore.queryItemTypes?.queryTypes.find(y => y.id === x.queryTypes_Id),
                })) :
                [];

        }

        const getCommonPointData = () => {
            let result: any = [];
            if (this.state.nodeType == NodeTypesEnum.Point &&
                this.state.pointAllInfo &&
                this.props.adminStore.pointDicts) {
                const pointAllInfo: PointAllInfoDTO | undefined = this.state.pointAllInfo;
                const dicts: AdminStore.IPointDictionaries = this.props.adminStore.pointDicts;
                const resource: Resource | undefined = this.props.userProfile.resources.find(r => r.id === pointAllInfo.pointInfo.resourceTypeId);
                result = [
                    {
                        key: 'number',
                        name: 'Номер точки учёта',
                        value: pointAllInfo.pointInfo.number
                    },
                    {
                        key: 'name',
                        name: 'Название',
                        value: pointAllInfo.pointInfo.name
                    },
                    {
                        key: 'reptarget',
                        name: 'Ведомости здаются',
                        value: dicts.organizations[pointAllInfo.pointInfo.heatCompanyId]
                    },
                    {
                        key: 'comment',
                        name: 'Примечание',
                        value: pointAllInfo.pointInfo.comment
                    },
                    {
                        key: 'contact',
                        name: 'Контактное лицо',
                        value: pointAllInfo.pointInfo.contactPerson
                    },
                    {
                        key: 'region',
                        name: 'Субъект РФ',
                        value: pointAllInfo.pointInfo.regionId ? dicts.regions[pointAllInfo.pointInfo.regionId] : ''
                    },
                    {
                        key: 'address',
                        name: 'Адрес',
                        value: pointAllInfo.pointInfo.address
                    },
                    {
                        key: 'meteostation',
                        name: 'Метеостанция',
                        value: pointAllInfo.pointInfo.weatherStationId ? dicts.weatherStations[pointAllInfo.pointInfo.weatherStationId] : ''
                    },
                    {
                        key: 'report',
                        name: 'Ведомость',
                        value: dicts.reports[pointAllInfo.pointInfo.reportId]
                    },
                    {
                        key: 'curreport',
                        name: 'Ведомость по текущим',
                        value: dicts.reportsCurrent[pointAllInfo.pointInfo.reportId]
                    },
                    {
                        key: 'resource',
                        name: 'Тип ресурса',
                        value: resource ? resource.name : ''
                    },
                    {
                        key: 'responsible',
                        name: 'Ответственный',
                        value: pointAllInfo.pointInfo.performerId ? dicts.performers[pointAllInfo.pointInfo.performerId] : ''
                    },
                ];
            }
            return result;

        }

        const getDeviceData = () => {
            let result: any = [];
            if (this.state.nodeType == NodeTypesEnum.Point &&
                this.state.pointAllInfo &&
                this.props.adminStore.pointDicts) {
                const pointDeviceInfo: PointDeviceInfo | undefined = this.state.pointAllInfo?.deviceInfo;
                const dicts: AdminStore.IPointDictionaries = this.props.adminStore.pointDicts;
                result = [
                    {
                        key: 'devicetype',
                        name: 'Тип прибора',
                        value: pointDeviceInfo.deviceType// dicts.deviceTypes[pointDeviceInfo.typeId].name
                    },
                    {
                        key: 'serialnumber',
                        name: 'Серийный номер',
                        value: pointDeviceInfo.serialNumber
                    },
                    {
                        key: 'devicesubtype',
                        name: 'Тип',
                        value: pointDeviceInfo.deviceType
                    },
                    {
                        key: 'versionpo',
                        name: 'Версия ПО',
                        value: pointDeviceInfo.softwareVersion
                    },
                    {
                        key: 'desc',
                        name: 'Описание',
                        value: pointDeviceInfo.description
                    },
                    {
                        key: 'systemtype',
                        name: 'Тип системы',
                        value: pointDeviceInfo.systemType ? dicts.systemTypes[pointDeviceInfo.systemType] : ''
                    },
                    {
                        key: 'heattype',
                        name: 'Вид теплоснабжения',
                        value: pointDeviceInfo.typeOfHeatSupply ? dicts.typesOfHeatSupply[pointDeviceInfo.typeOfHeatSupply] : ''
                    },
                    {
                        key: 'montagedate',
                        name: 'Монтаж',
                        value: pointDeviceInfo.installDate
                    },
                    {
                        key: 'connectdate',
                        name: 'Монтаж  уд. доступа',
                        value: pointDeviceInfo.raInstallDate
                    },
                ];
            }
            return result;

        }

        const getParametersData = () => {
            let result: any = [];
            if (this.state.nodeType == NodeTypesEnum.Point &&
                this.state.pointAllInfo) {
                const pointDeviceInfo: PointDeviceInfo | undefined = this.state.pointAllInfo?.deviceInfo;
                result = pointDeviceInfo.vParameters.map(p => { return { key: p.id, name: p.parameterCode, value: p.value, description: p.description } });
            }

            return result;
        }

        const getConnectionData = () => {
            let result: any = [];
            if (this.state.nodeType == NodeTypesEnum.Point &&
                this.state.pointAllInfo &&
                this.props.adminStore.pointDicts) {
                const pointConnectionInfo: PointConnectionInfo | undefined = this.state.pointAllInfo?.connectionInfo;
                const dicts: AdminStore.IPointDictionaries = this.props.adminStore.pointDicts;
                const main = [
                    {
                        key: 'type',
                        name: 'Тип подключения',
                        value: dicts.connectionTypes[pointConnectionInfo.typeId].description
                    },

                    {
                        key: 'devicetype',
                        name: 'Тип устройства связи',
                        value: dicts.commDevTypes[this.state.pointAllInfo.pointInfo.commDevTypeId]
                    },

                    {
                        key: 'signallevel',
                        name: 'Уровень сигнала',
                        value: this.state.pointAllInfo.pointInfo.signalLevel
                    }
                ];
                const other = [
                    {
                        key: 'retrysession',
                        name: 'Попытки соединений',
                        value: pointConnectionInfo.retrySession
                    },

                    {
                        key: 'sessiontime',
                        name: 'Время соединения',
                        value: pointConnectionInfo.sessionTimeout
                    },

                    {
                        key: 'retryquery',
                        name: 'Попытки запросов',
                        value: pointConnectionInfo.retryQuery
                    },

                    {
                        key: 'responsetime',
                        name: 'Время ответа',
                        value: pointConnectionInfo.responseTimeout
                    },

                    {
                        key: 'lockgroup',
                        name: 'Группа блокировки',
                        value: pointConnectionInfo.lockName
                    },

                    {
                        key: 'init',
                        name: 'Инициализация',
                        value: pointConnectionInfo.extraParameters
                    }                ];
                result = result.concat(main);
                result = result.concat(
                    pointConnectionInfo.vParameters.map(p => { return { key: p.parameterCode, name: p.parameterCode, value: p.value, description: p.description } }));
                result = result.concat(other);
            }

            return result;
        }
        const getItemsData = () => {
            let result: any = [];
            if (this.state.nodeType == NodeTypesEnum.Point &&
                this.state.pointAllInfo &&
                this.props.adminStore.pointDicts) {
                const pointDeviceInfo: PointDeviceInfo | undefined = this.state.pointAllInfo?.deviceInfo;

                result = pointDeviceInfo.measurementDescriptions.map(x => {
                    return ({
                        key: x.id,
                        id: x.id,
                        name: x.name,
                        unitCaption: x.unitCaption,
                        channelSign: x.channelSign,
                        sign: x.sign,
                        min: x.minValue,
                        max: x.maxValue,
                    });
                });
            }
            return result;
        }

        const pointDicts: AdminStore.IPointDictionaries | undefined = this.props.adminStore.pointDicts;
        return (
            <>
            <Row gutter={16}>
                    <Col span={8}>
                        <div style={{ height: clientHeight, padding: 2, backgroundColor: '#ffffff' }}>
                            <div className={styles.flexcontainer} style={{ marginBottom: 6 }}>
                                <Input
                                    placeholder={"Фильтр для точек учёта"}
                                    style={{ width: 200, marginRight:10 }}
                                    value={this.state.filter}
                                    onChange={(e) => {
                                        this.setState({ filter: e.target.value, fullLoading: true });
                                    }}
                                />
                                <Tooltip title={"Расширенный выбор точки учёта."}>
                                    <Button
                                        style={{ width: 200, marginRight: 10 }}
                                        shape="round"
                                        icon={<DownloadOutlined />}
                                        onClick={() => {
                                            this.setState({ pointSelectVisible: true });
                                        }}
                                    >
                                        Выбор ТУ
                                    </Button>
                                </Tooltip>
                            </div>
                            {
                                <PointsControlTree {...pointsControlTreeProps} />
                            }
                        </div>
                </Col>
                    <Col span={16}>
                        {this.state.nodeType === NodeTypesEnum.Point &&
                            <>
                                <div style={{ height: topRightHeight, padding: 2, backgroundColor: '#ffffff' }}>
                                    <div className={styles.flexcontainer} style={{ marginBottom: 6 }}>
                                        {
                                            <Button
                                                shape="round"
                                                icon={<EditTwoTone twoToneColor={Const.COLORS.SvgIconColor} />}
                                                onClick={() => {
                                                    if (this.state.nodeType === NodeTypesEnum.Point) {
                                                        this.editPoint(this.state.selectedOb as Point);
                                                    }
                                                }}
                                            >
                                                Изменить
                                            </Button>
                                        }
                                        <Typography.Title level={5} style={{ marginLeft: 20, marginRight: 20}}>{selectedTitle}
                                        </Typography.Title>
                                </div>
                                {this.state.queryItems &&
                                    <Table
                                        columns={queryColumns}
                                        dataSource={queryData()}
                                        pagination={false}
                                        scroll={scrollVisibilityTable1}
                                        bordered={true}
                                        size={"small"}
                                    />
                                }
                            </div>
                            <Divider plain orientation="left" ><h5>Параметры точки учёта</h5></Divider>
                            <div style={{ height: bottomRightHeight, backgroundColor: '#ffffff' }}>
                                    <Tabs type="card">
                                        <Tabs.TabPane tab="Общее" key="common">
                                        <Table
                                            columns={pointColumns}
                                            dataSource={getCommonPointData()}
                                            pagination={false}
                                            scroll={scrollVisibilityTable2_1}
                                            bordered={true}
                                            showHeader={false}
                                            size={"small" }
                                            />
                                        </Tabs.TabPane>
                                    <Tabs.TabPane tab="Прибор" key="device">
                                        <Table
                                            columns={pointColumns}
                                            dataSource={getDeviceData()}
                                            pagination={false}
                                            scroll={scrollVisibilityTable2_1}
                                            bordered={true}
                                            showHeader={false}
                                            size={"small"}
                                        />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="Свойства" key="properties">
                                        <Table
                                            columns={parametersColumns}
                                            dataSource={getParametersData()}
                                            pagination={false}
                                            scroll={scrollVisibilityTable2_2}
                                            bordered={true}
                                            size={"small"}
                                        />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="Подключение" key="connection">
                                        <Table
                                            columns={parametersColumns}
                                            dataSource={getConnectionData()}
                                            pagination={false}
                                            scroll={scrollVisibilityTable2_2}
                                            bordered={true}
                                            size={"small"}
                                        />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="Переменные" key="items">
                                        <Table
                                            columns={itemsColumns}
                                            dataSource={getItemsData()}
                                            pagination={false}
                                            scroll={scrollVisibilityTable2_2}
                                            bordered={true}
                                            size={"small"}
                                        />
                                    </Tabs.TabPane>
                                    </Tabs>
                                </div>
                            </>
                        }
                        {this.state.nodeType === NodeTypesEnum.Group &&
                            <div style={{ height: clientHeight, backgroundColor: '#ffffff' }}>
                                <div className={styles.flexcontainer} style={{ marginLeft: 6 }}>
                                    <Typography.Title level={5} style={{ marginLeft: 20, marginRight: 20, marginTop: 4 }}>{selectedTitle}
                                    </Typography.Title>
                                    {
                                        <Button
                                            shape="circle"
                                            icon={<EditTwoTone twoToneColor={Const.COLORS.SvgIconColor} />}
                                            onClick={() => {
                                                if (this.state.nodeType === NodeTypesEnum.Point) {
                                                    this.editPoint(this.state.selectedOb as Point);
                                                }
                                                else if ((this.state.nodeType === NodeTypesEnum.Group)) {
                                                    this.editGroup(this.state.selectedOb as Group)
                                                }
                                            }}
                                        />
                                    }
                                </div>
                                <Table
                                    columns={groupColumns}
                                    dataSource={getGroupData()}
                                    pagination={false}
                                    bordered={true}
                                    showHeader={false }
                                />
                            </div>
                        }
                        {this.state.nodeType === NodeTypesEnum.Root &&
                            <div style={{ height: clientHeight, padding: 2, backgroundColor: '#ffffff' }}>
                            </div>
                        }
                </Col>
            </Row>
                {
                    this.props.adminStore == undefined ? null :
                    <PointEditForm
                            visible={this.state.showPointEditForm}
                            onHide={() => {
                                this.setState({ showPointEditForm: false, pointAllInfo: this.props.adminStore.pointAllInfo });
                                this.props.setPointAllInfo(undefined)
                            }}
                            onWrite={this.onPointWrite}
                            changeQueryItem={this.props.setQueryItem}

                            isConnParsLoading={this.props.adminStore.isConnParsLoading}
                            isPointAllInfoLoading={this.props.adminStore.isPointAllInfoLoading}
                            isQueryItemTypesLoading={this.props.adminStore.isQueryItemTypesLoading}
                            pointAllInfo={this.props.adminStore.pointAllInfo}
                            pointDicts={this.props.adminStore.pointDicts}
                            queryItemTypes={this.props.adminStore.queryItemTypes}
                            queryItem={this.props.adminStore.queryItem}

                            allResources={this.props.userProfile.resources}
                            changePoint={this.props.setPointAllInfo}
                            requestConnPars={this.props.requestConnPars}
                            setIsLoading={this.props.setIsLoading}
                            baseMapLayer={this.props.profile.Map?.BaseLayer}
                            changeBaseMapLayer={(val: string) => this.props.changeProfileValue(Const.PROFILE_MAP, 'BaseLayer', val)}
                            readSettings={this.readPointSettings}
                            requestPointAllInfo={(success: () => void) => this.props.requestPointAllInfo(this.props.adminStore.pointAllInfo?.pointInfo.id ?? null, false, success)}

                            changeProfile={this.props.changeProfile}
                            setActiveQueries={this.props.setActiveQueries}
                            showModal={this.props.showModal}
                            showPointInfo={this.props.showPointInfo}
                            showPolling={() => { this.polling(this.state.selectedOb as Point) }}
                            userProfile={this.props.userProfile}
                            zIndex={this.state.modalZIndex}
                            windowHeight={this.props.windowHeight}
                            activeTasks={this.props.tasks.activeTasks}
                            setActiveLogTask={this.props.setActiveLogTask}
                            getActiveTasks={this.props.getActiveTasks}
                            readData={this.props.readData}
                            showPointLog={
                                (pointId: string) => {
                                    this.props.addPointLog(this.state.selectedOb as Point);
                                }
                            }
                    />
                }
                {
                    this.state.groupFormIsOpen ? <GroupForm {...groupFormProps}/> : null
                }
                {
                    <SelectForm
                        title={this.state.selectObjectsModal.onlyGroupsSelect ? "Выбор групп" : "Выбор точек учёта"}
                        visible={this.state.selectObjectsModal.visible}
                        zIndex={this.state.selectObjectsModal.zIndex}
                        clientHeight={clientHeight}
                        groups={this.props.userProfile.userGroups}
                        groupPoints={this.props.userProfile.groupPoints}
                        selectedGroupIds={this.state.selectObjectsModal.selectedGroupsIds}
                        selectedPointIds={this.state.selectObjectsModal.selectedPointsIds}
                        getPoints={this.props.requestGroupPoints}
                        getMultipleGroupPoints={this.props.requestMultipleGroupPoints}
                        getPointsParentGroups={null}
                        onChange={  (groups: string[], points: string[]) => this.setState(
                            {selectObjectsModal: {...this.state.selectObjectsModal, selectedGroupsIds: groups, selectedPointsIds: points}}
                        )}
                        onChangePoints={this.onPointsSelect}
                        onChangeGroups={this.onGroupsSelect}
                        setIsLoading={this.props.setIsLoading}
                        onlyGroupsSelect={this.state.selectObjectsModal.onlyGroupsSelect}
                        onClose={() => this.setState(
                            {selectObjectsModal: {...this.state.selectObjectsModal, visible:false}}
                        )}
                        resources = {this.props.userProfile.resources}
                        onlyTree ={onlyTree}
                    />
                }                    
                {
                    <QueryItemsForm
                        onChange={() => { this.setState({ isQueryItemsChanged: true }) }}
                        onHide={() => {
                            if (this.state.isQueryItemsChanged) this.loadQueryItems(this.state.selectedOb as Point);
                            this.setState({ showQueryItemsFormPoint: null, isQueryItemsChanged: false })
                        }}
                        point={this.state.showQueryItemsFormPoint}
                        setIsLoading={this.props.setIsLoading}
                        queryItemTypes={this.props.adminStore.queryItemTypes}
                        zIndex={this.state.modalZIndex}
                        activeTasks={this.props.tasks.activeTasks}
                        readData={this.props.readData}
                        setActiveLogTask={this.props.setActiveLogTask}
                        getActiveTasks={this.props.getActiveTasks}
                    />
                }
                {
                    <PollingForm
                        points={this.state?.pollingAbonents ?? null}
                        zIndex={this.state.modalZIndex}
                        onHide={() => this.setState({ pollingAbonents: null })}
                        setIsLoading={this.props.setIsLoading}
                        groupName={this.state?.pollingGroupName ?? null}
                        changeProfile={this.props.changeProfile}
                        setActiveQueries={this.props.setActiveQueries}
                        showModal={this.props.showModal}
                        showPointInfo={this.props.showPointInfo}
                        userProfile={this.props.userProfile}
                        windowWidth={this.props.windowWidth}
                        setActiveLogTask={this.props.setActiveLogTask}
                    />
                }
                {
                    <PointSelectTable
                        visible={this.state.pointSelectVisible}
                        deviceTypes={pointDicts?.deviceTypes}
                        connectionTypes={pointDicts?.connectionTypes}
                        commDevTypes={pointDicts?.commDevTypes}
                        onHide={() => this.setState({ pointSelectVisible: false })}
                        onSelect={
                            (point: Point | undefined) => {
                                this.setState({ pointSelectVisible: false });
                                if (point) {
                                    this.onSelect(point, NodeTypesEnum.Point);
                                    this.setState({ filter: point.number });
                                }
                            }}
                        onEdit={
                            (point: Point) => {
                                this.onSelect(point, NodeTypesEnum.Point);
                                this.editPoint(point); 
                            }}
                    />
                }
            </>
        );
    }
}


export default connect(
    (state: ApplicationState) => {
        return {
            adminStore: state.adminStore,
            page: state.page,
            tasks: state.tasks,
            settings: state.settingsStore,
            profile: state.userProfile?.profile,
            userProfile: state.userProfile,
            pointId: state.page.pointId,
            isMenuCollapsed: state.siderMenu?.collapsed,
            windowWidth: state.page.windowWidth,
            windowHeight: state.page.windowHeight,
        }
    },
    actionCreators,

)(PointsManager as any);
