import React, {useState, useEffect} from 'react';
import {Modal, Table, Tree, Checkbox, Divider, Space } from 'antd';
import { SettingTwoTone } from '@ant-design/icons';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import * as Const from '../../utils/Const';
import { getModalZIndex, message } from '../../utils/Common';
import styles from '../../resources/Page.module.less';

const getLoginModalSettings = (props: any) => {
    return (
        {
            title: "Вход в личный кабинет",
            footer: null,
            maxWidth: 300,
            content: <div/>
        });
};

export type TColumn = 
{
    Name: string,
    Caption: string,
    Width: number,
    IsHidden: boolean
}

export interface IColumnDef{
    group: string | undefined,
    code: string,
    caption: string,
    checked: boolean,
    disabled: boolean
}

export interface IColumnsSelectorProps{
    selectInTree: boolean,
    columns: IColumnDef[],
    maxRows: number,
    onCancel: any,
    onSave: any
}

export const showColumnsSelector = (columns: IColumnDef[], selectInTree: boolean, maxRows: number, showModal: any, callback: any) =>{
    showModal ( <ColumnsSelector selectInTree={selectInTree} columns={columns} maxRows={maxRows}
    onCancel={() => {
        showModal(null);
        callback(null);
    }}
    onSave={(visibility: any) => {
        const result = callback(visibility);
        if (result.ok) {
            message.success(result.message);
            showModal(null);
        }
        else {
            message.warning(result.message);
        }
    }}
    />);
}

export class ColumnsSelector extends React.PureComponent<IColumnsSelectorProps, any>{
    //Число колонок в таблице выбора колонок.
    _nColumns: number = 0; 
    constructor(props:IColumnsSelectorProps) {
        super(props);
        this.state = {
            allChecked: !this.props.columns.find(c=>!c.checked),
            columnStates: this.props.columns.map(c=>c.checked)
        };
    }

    //Колонки из таблицы выбора колонок.
    columnsCreate = (columnsCount: number) => {
        const result: any = [];
        for(let i = 0; i < columnsCount; i++){
            result.push({dataIndex: 'd' + i, key: 'key' + i});
        }

        return result;
    }

     dataSourceCreate = (tableColumns: any) => {
        const rows: any = [];

        let row: any = {};
        let columnNumber = 0;
        let rowNumber = 0;
        for(let i = 0; i < this.props.columns.length; i++){
            const column = this.props.columns[i];
            const checked = this.state.columnStates[i];
            row[tableColumns[columnNumber].dataIndex] = <Checkbox checked = {checked} disabled={column.disabled} value={i} onChange={this.onChange}>{column.caption}</Checkbox>;
            columnNumber++;
            if(columnNumber >= tableColumns.length){
                row['key'] = rowNumber.toString();
                rowNumber++;
                columnNumber = 0;
                rows.push(row);
                row = {};
            } 
        }
        if(columnNumber > 0){
            row['key'] = rowNumber.toString();
            rows.push(row);
        }

        return rows;
    }
    //Подготовка дерева слоев для отображения в компоненте Tree
    getLayersTree = (): {title: string, key: string, children: {title: string, key: string}[]}[] => {
        let dic: {[name: string]: {title: string, key: string, children: any[]}} = {};

        for (const l of this.props.columns) {
            const title = l.caption;
            if (l.group)
            {
                if (dic[l.group] === undefined) {
                    dic[l.group] = {
                            title: l.group, 
                            key: l.group, 
                            children: []
                        }
                }
                dic[l.group].children.push({title: l.caption, key: l.code});
            } else {
                if (dic['other'] === undefined) {
                    dic['other'] = {
                        title: 'Общие', 
                        key: 'other', 
                        children: []
                    }
                }
                dic['other'].children.push({title: title, key: l.code});
            }
        }

        const result = Object.keys(dic).map(key => ({...dic[key]}));
        return result;
    }

    onAllChange = (e: CheckboxChangeEvent) => {
        const checked = e.target.checked;
        const columnStates = this.props.columns.map(c=> c.disabled ? c.checked : checked);
        this.setState({allChecked: checked, columnStates: columnStates});
    }

    onChange = (e: CheckboxChangeEvent) => {
        const columnStates = [...this.state.columnStates];
        columnStates[e.target.value] = e.target.checked;
        this.setState({columnStates: columnStates});
    }
    onNodeChange = (checked: React.Key[] | { checked: React.Key[]; halfChecked: React.Key[]; }, info: any): void => {
        const columnStates = [...this.state.columnStates];
        for (let i = 0; i < this.props.columns.length; i++) {
            const column = this.props.columns[i];
            if((checked as React.Key[]).includes(column.code)){
                columnStates[i] = true;
            }
            else{
                columnStates[i] = false;
            }
        }
        this.setState({ columnStates: columnStates });
    };
  
    onOk = () => {
        const result: any = {};
        for (let i = 0; i < this.props.columns.length; i++) {
            const column = this.props.columns[i];
            const checked = this.state.columnStates[i];
            result[column.code] = checked;
        }

        this.props.onSave(result);
    }

    render(){
        const title = <Space><SettingTwoTone twoToneColor={Const.COLORS.SvgIconColor}/><span>Выбор колонок для отображения</span></Space>;
        if(this.props.selectInTree){
            const layersChildren = this.getLayersTree().map(item => {
                return (
                    {title: item.title, key: item.key, children: item.children.map(i => ({...i})) }
                );
            });
            const checkedKeys = this.props.columns.filter((c,i)=>this.state.columnStates[i]).map(c=>c.code);
            return(
                <Modal
                    title={title }
                    style={{ maxWidth: 800, top: '3px' }} 
                open={true}
                wrapClassName = {Const.MODAL_WRAP_CLASSNAME}
                zIndex={getModalZIndex()}
                onCancel= {this.props.onCancel}
                onOk = {this.onOk}
                > 
                    <div className={styles.columnSelectorTreeContainer }>
                        <Tree 
                        autoExpandParent={true} 
                        checkable
                        defaultExpandAll={true}
                        checkedKeys={checkedKeys}
                        onCheck={this.onNodeChange}
                        treeData={[{ 
                            title: 'Колонки',
                            key: 'root',
                            children: layersChildren
                        }]}
                    />
                        </div>
            </Modal>
            );

        }
        else{
            const columnsCount = Math.floor((this.props.columns.length) / this.props.maxRows) + 1;
            const tableColumns = this.columnsCreate(columnsCount);
            const data = this.dataSourceCreate(tableColumns);
            return (
            <Modal
                    title={title}
                    style={{ maxWidth: 800, top: '3px' }} 
                    open={true}
                    onCancel= {this.props.onCancel}
                    onOk = {this.onOk}
                > 
                    <Checkbox checked = {this.state.allChecked} onChange={this.onAllChange}>{this.state.allChecked ? 'Скрыть все' : 'Отобразить все'}</Checkbox>
                    <Divider/>
                    <Table showHeader={false} dataSource={data} columns={tableColumns} pagination={false} />
                </Modal>
                );
        }
    }

} 
