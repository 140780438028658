import * as React from 'react';
import { Button, DatePicker, Input, Select, Space, Tooltip } from 'antd';
import { connect } from 'react-redux';

import Login from './Login2';
import Resources from './Resources';
import Groups from './Groups';
import * as NsiStore from '../store/NsiStore';

import { ApplicationState } from '../store';
import * as PageStore from '../store/Page';
import * as UserProfileStore from '../store/UserProfile';
import * as ReportsStore from '../store/Reports';
import { RouteComponentProps } from 'react-router';
import styles from '../resources/Page.module.less';
import { SPointsFilter, Point, USER_RIGHTS } from "../decl"

import {
    SyncOutlined,
    EditOutlined,
    FileExcelOutlined,
    SaveOutlined,
    StopOutlined
} from '@ant-design/icons';
import { MiddleContentMessageBlock } from './HeaderForm';
import { sendRequestToBackend } from '../utils/AuthUtils';
import { convertBase64ToBinary, message, saveFile } from '../utils/Common';

const actionCreators = { ...PageStore.actionCreators, ...UserProfileStore.actionCreators, ...ReportsStore.actionCreators, ...NsiStore.actionCreators };

type HeaderProps =
    { userProfile: UserProfileStore.UserProfileState, nsi: NsiStore.INsiState, windowWidth: number, pointId: string, pointNumber: string, } &
    typeof actionCreators &
    RouteComponentProps<{}>

interface HeaderState {
    filter: SPointsFilter;
}

class NsiHeaderForm extends React.PureComponent<HeaderProps, HeaderState>{
    constructor(props: HeaderProps) {
        super(props);
        this.state = {
            filter: {
                groups: (props?.userProfile?.profile?.Common?.Groups) ?? null,
                points: (props?.userProfile?.profile?.Common?.Points) ?? '',
                resources: (props?.userProfile?.profile?.Common?.Resources) ?? null,
                systemNumbers: null,
                text: ''
            },
        }
    }

    onRefresh = () => {
        if (this.props.pointId != '') {
            this.props.requestNsi(this.props.pointId);
        } else {
            message.error('Сначала необходимо выбрать точку учета');
            //console.log('Сначала необходимо выбрать точку учета');
        }
    };

    onSelectPoint = (pointId: string) => {
        const point: Point | undefined = this.props.userProfile.allPoints[pointId];
        if (point == undefined) {
            const error = 'Не найдена ТУ в кеше.';
            message.error(error);
            console.log(error);
        } else {
            this.props.setPoint(point.id, point.number);
        }
    };
    requestNsiReport = () => {
        sendRequestToBackend(
            this.props.pointId,
            'points/nsiexport',
            (response: any) => {
                const fileName = 'Отчет НСИ ' + this.props.pointNumber + '.xls';
                message.success('Файл с НСИ получен');
                saveFile(fileName, convertBase64ToBinary(response.base64data));
            },
            null,
            (error: any) => {
                message.error('Ошибка:' + error);
                console.log(error);
            },
            this.props.closeSession
        );
    }

    render() {
        const GroupsProps2 = {
            groups: (this.props.userProfile) ? this.props.userProfile.userGroups : [],
            groupPoints: (this.props.userProfile) ? this.props.userProfile.groupPoints : {},
            selectedGroupIds: [],
            selectedPointIds: [this.props.pointId],
            getPoints: this.props.requestGroupPoints,
            getPointsParentGroups: null,
            onSelectPoint: this.onSelectPoint,
            setIsLoading: this.props.setIsLoading,
            treeCheckable: false,
        };

        const editAllRight: boolean = this.props.userProfile.rights.indexOf(USER_RIGHTS.EditAllNSI) > 0;
        const editRight: boolean = this.props.userProfile.rights.indexOf(USER_RIGHTS.EditNSI) > 0;
        return (
            this.props.nsi === undefined  ? null :
            <div className={styles.flexcontainer}>
                <div className={styles.buttonsblock}>
                    <div className={styles.flexitem}>

                    <Groups {...GroupsProps2} /></div>

                    <Tooltip placement="bottomRight" title={''}>
                        <Button onClick={this.onRefresh} disabled={this.props.nsi.editEnabled} className={styles.flexitem} icon={<SyncOutlined />} type="primary" shape="round">Обновить</Button>
                    </Tooltip>
                    {
                        this.props.nsi.editEnabled === true ?
                        <>
                            <Tooltip placement="bottomRight" title={''}>
                                <Button onClick={() => this.props.setRequestChangeFlag(true)} className={styles.flexitem} icon={<SaveOutlined />} type="primary" shape="round">Сохранить</Button>
                            </Tooltip>
                            <Tooltip placement="bottomRight" title={''}>
                                <Button onClick={() => this.props.setNsiEditEnabled(false)} className={styles.flexitem} icon={<StopOutlined />} type="primary" shape="round">Отмена</Button>
                            </Tooltip>
                                </>
                                : <Tooltip placement="bottomRight" title={''}>
                                    <Button disabled={editAllRight === false && editRight === false} onClick={() => this.props.setNsiEditEnabled(true)} className={styles.flexitem} icon={<EditOutlined />} type="primary" shape="round">Редактировать</Button>
                                </Tooltip>
                    }
                    <Tooltip placement="bottomRight" title={''}>
                        <Button onClick={() => {this.requestNsiReport()}} disabled={this.props.nsi.editEnabled} className={styles.flexitem} icon={<FileExcelOutlined />} type="primary" shape="round">Excel</Button>
                    </Tooltip>
                </div>
                <div className={styles.mbitem}>
                    <MiddleContentMessageBlock windowSize={this.props.windowWidth} maxWindowSize={this.props.windowWidth} onlyIcon={this.props.windowWidth < 1720 } hideEmail={false} isSplit={false}/>
                </div>
                <div className={styles.flexuseritem}>
                    <Login />
                </div>
            </div>
        )
    }
}



export default connect(
    (state: ApplicationState) => {
        return {
            userProfile: state.userProfile,
            nsi: state.nsi,
            windowWidth: state.page.windowWidth,
            pointId: state.page.pointId,
            pointNumber: state.page.pointNumber,
        }
    },
    actionCreators
)(NsiHeaderForm as any);
