import * as React from 'react';

import { Modal } from 'antd';
import { DraggableModal } from 'ant-design-draggable-modal';

import { getModalZIndex, message } from '../../utils/Common';

import { MODAL_WRAP_CLASSNAME } from '../../utils/Const';
import { Point } from '../../decl';
import { ImageLoader, SPictureDTO, getPointPictures, savePointPictures } from '../Helper/ImageLoader';

type ModalProps = {
    point: Point,
    setIsLoading: any,
    onChange: any,
    onCancel: any
}

type ModalState = {
    pictures: SPictureDTO[],
    removedIds: number[],
    isDirty: boolean
}

export const showImageLoader = (showModal: any, setIsLoading: any, callback: any, point: Point) => {
    const onCancel = () => {
        showModal(null);
        callback(null);
    }

    const onOk = (newPctures: SPictureDTO[]) => {
        showModal(null);
        callback(null);
    }

    showModal(
        <ModalImageLoader
            setIsLoading={setIsLoading}
            point={point}
            onChange={onOk}
            onCancel={onCancel}
        />
    );

}

export class ModalImageLoader extends React.Component<ModalProps, ModalState> {
    constructor(props: ModalProps, state: ModalState) {
        super(props);
        this.state = { pictures: [], removedIds: [], isDirty: false }
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps: ModalProps, prevState: ModalState) {
        if (prevProps.point.id !== this.props.point.id) {
            this.load();
        }
    }

    load = () => {
        getPointPictures(this.props.point.id, this.props.setIsLoading, (pictures: SPictureDTO[]) => {
            this.setState({ pictures: pictures, removedIds: [], isDirty: false });
        });
    }

    onAdd = (picture: SPictureDTO) => {
        const newPictures = [...this.state.pictures, picture];
        this.setState({ pictures: newPictures, isDirty: true })
    }

    onRemove = (pictureName: string, pictureId: number) => {
        //Добавить в массив удаляемых идентификаторов, если нужно.
        const removedIds = [...this.state.removedIds];
        if (pictureId !== 0) {
            removedIds.push(pictureId);
        }

        //Удалить из состава картинок.
        const newPictures = this.state.pictures.filter(p => p.name !== pictureName);

        this.setState({ pictures: newPictures, removedIds: removedIds, isDirty: true });
    }

    onOk = () => {
        if (this.state.isDirty) {
            savePointPictures(this.props.point.id, this.state.pictures, this.state.removedIds, this.props.setIsLoading, this.props.onChange);
        }
    }

    onCancel = () => {
        if (this.state.isDirty) {
            Modal.confirm({
                title: 'Отмена изменений',
                wrapClassName: MODAL_WRAP_CLASSNAME,
                zIndex: getModalZIndex(),
                content: <div>Вы подтверждаете отмену всех изменений?</div>,
                width: 550,
                okText: 'Да',
                cancelText: 'Отмена',
                onOk: () => {
                    this.props.onCancel()
                },
                onCancel: () => {
                }
            });
        }
        else {
            this.props.onCancel();
        }
    }

    render() {
        return (
            <DraggableModal
                maskClosable={true}
                bodyStyle={{ padding: '5px' }}
                title={"Картинки для точки учёта '" + this.props.point.number + "'"}
                wrapClassName={MODAL_WRAP_CLASSNAME}
                zIndex={getModalZIndex()}
                open={true}
                onCancel={this.onCancel}
                initialWidth={800}
                initialHeight={500}
                onOk={this.onOk}
                okButtonProps={{ disabled: !this.state.isDirty, content: 'Сохранить' }}
            >
                <ImageLoader
                    pictures={this.state.pictures}
                    onAdd={this.onAdd}
                    onRemove={this.onRemove}
                />
            </DraggableModal>
        );
    }
}


