import React from 'react';
import {Table, Button, Space} from 'antd';
import moment from 'moment';
import anStyles from '../../resources/Analysis.module.less';
import {IAnalysisState} from "../../pages/Analysis";
import {
  SAnalysisPoint, PeriodsEnum,
  // BaseProfile, ResponseStatusesEnum, SAnalysisIntervalDTO, SAnalysisTypeDTO, SResponseDTO
} from '../../decl';
import {AppThunkAction} from "../../store";
import {KnownAction} from "../../store/Page";
import {logStyle} from "../Charts/ChartsUtils";


interface ITablePoint extends SAnalysisPoint {
  key: any
}

interface IAnalysisResultsProps extends IAnalysisState {
  exportToFile(conditionCode: string, intervalCaption: string, points: any[]): void,

  vedomostiAction(pointId: string, from: Date, to: Date, period: PeriodsEnum): AppThunkAction<KnownAction>
}

// type IAnalysisResultsProps = {
//
// }

const AnalysisResults: React.FC<IAnalysisResultsProps> = (props: IAnalysisResultsProps) => {
  const {
	dates,
	showTuNames = false,
	resultData = [],
	conditions = [],
	condition = "",
	exportToFile,
	vedomostiAction
  } = props;


  let title = 'Результат анализа учетных данных';
  if (dates[0] !== undefined && dates[1] != undefined) {
	//console.log('%c AnalysisResults dates', logStyle.h2, dates);
	title += ' c ';
	title += dates[0].format('DD.MM.YYYY HH:ss');
	title += ' по ';
	title += dates[1].format('DD.MM.YYYY HH:ss');
  }

  let conditionTitle: string = '';

  // const selectedCondition : number = parseInt(condition);
  conditions.map(cond => {
	if (cond.code == condition.toString())
	  conditionTitle = cond.valueHeader;
  });
  // console.log(conditionTitle);


  // const doExportToFile = (points) => {
  // exportToFile(condition, 'UNKNOWN', points);
  // };

  /*
   * Как скрыть показать по кнопке
   * https://stackoverflow.com/questions/47977632/how-to-expand-antd-table-row-by-click-a-button
   */
  const columnsGeneral: object[] = [
	{
	  title: conditionTitle,
	  dataIndex: 'name',
	  key: 'name',
	  width: '50%'
	},
	{
	  title: 'Количество ТУ',
	  dataIndex: 'tuCount',
	  key: 'tuCount',
	  align: 'center',
	  width: '10%'
	},
	{
	  title: '',
	  key: 'show',
	  align: 'center',
	  width: '20%',
	  // render: (text, record, index) => <a onClick={() => clickShow(record)}>Показать</a>,
	},
	{
	  title: '',
	  key: 'actions',
	  align: 'center',
	  width: '20%',
	  //см в Settings.tsx downloadContactXls
	  render: (record: any) => {
		// console.log(record);
		if (!record.points.length)
		  return null;

		return <Button
		  size="small"
		  onClick={() => exportToFile(condition, record.name, record.points)}
		>
		  Сохранить
		</Button>
	  }
	},
  ];

  const dataGeneral: any = [];

  // console.dir(resultData);

  if (resultData.length) {
	resultData.map(({caption, points}, i) => {

	  // console.log(points);
	  let _points: ITablePoint[] = [];
	  points.map(point => {
		_points.push({
		  ...point,
		  key: point.id
		})
	  });

	  dataGeneral.push({
		key: condition + '-' + i,
		name: caption,
		show: '',
		actions: '',
		tuCount: _points.length,
		points: _points
	  })
	});
  } else
	return null;


  return (
	<div className={anStyles.analysisResultsWrap}>
	  <div className={anStyles.analysisResultsTitle}>
		{title}
	  </div>
	  <div
		// className={anStyles.analysisTableWrap}
	  >
		<Table
		  columns={columnsGeneral}
		  dataSource={dataGeneral}
		  pagination={false}

		  expandable={{

			expandedRowRender: record => record.points.length
			  ? <AnalasysSubTable
				dates={dates}
				points={record.points}
				showTuNames={showTuNames}
				title={conditionTitle}
				vedomostiAction={vedomostiAction}
			  />
			  : null,
			rowExpandable: (record) => record.points.length,
			// expandedRowKeys: expandedRows
		  }}
		/>
	  </div>
	</div>
  );
}

export default AnalysisResults;

interface IAnalasysSubTableProps {
  dates: [any, any],
  points: any [],
  showTuNames: boolean,
  title: string,
  vedomostiAction: any
}

const AnalasysSubTable: React.FC<IAnalasysSubTableProps> = (props: IAnalasysSubTableProps) => {

  const {
	dates = [],
	points = [],
	showTuNames = false,
	title = '',
	vedomostiAction
  } = props;
  const columns: object[] = [
	{
	  title: 'Номер',
	  dataIndex: 'number',
	  key: 'number',
	  width: !showTuNames ? '60%' : '30%',
	  textWrap: 'word-break'
	}
  ];

  if (showTuNames)
	columns.push({
	  title: 'Наименование',
	  dataIndex: 'name',
	  key: 'name',
	  width: '30%',
	  textWrap: 'word-break'
	});

  columns.push({
	title: title,
	dataIndex: 'value',
	key: 'value',
	width: '20%',
	align: 'center'
  });

  /*
  По кнопке Ведомость вызвать метод
  IPageStore.formReport: (pointId: string, from: Date, to: Date, period: PeriodsEnum)
  (props.formReport из redux storage)
   */
  columns.push({
	title: '',
	dataIndex: 'actions',
	key: 'actions',
	align: 'center',
	width: '20%',
	render: (text: any, record: any) => {
	  //console.dir(dates);
	  return <Space size="middle">
		<Button
		  size="small"
		  onClick={() => {
			vedomostiAction(
			  record.id,
			  moment(dates[0]).set({hour: 0, minute: 0, second: 0, millisecond: 0}).toDate(),
			  moment(dates[1]).set({hour: 0, minute: 0, second: 0, millisecond: 0}).toDate(),
			  PeriodsEnum.day
			);
		  }}
		>
		  Ведомость
		</Button>
	  </Space>

	}
  });

  return (
	<div
	  className={""}
	  style={{backgroundColor: '#ccc'}}
	>
	  <Table
		columns={columns}
		dataSource={points}
		pagination={false}
		style={{width: '100%'}}
		// bordered
	  />
	</div>
  )
}

