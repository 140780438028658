import * as React from 'react';

import styles from '../../resources/Page.module.less';
import { Button, Modal, Select, Input, Row, Col, Table, Tag, DatePicker, Tabs, Tooltip, Space } from 'antd';

import { arrayToString, deepCopyObj, getModalZIndex, message } from '../../utils/Common';
import { ALL_GROUP_ID, MODAL_WRAP_CLASSNAME } from '../../utils/Const';
import { SBilContactDTO, SBillingCompanyInfoDTO } from './BillingDecls';
import moment from 'moment';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import * as Const from '../../utils/Const';
import Search from 'antd/lib/input/Search';
import CompanyEditForm from './CompanyEditForm';
import { sendRequestToBackend } from '../../utils/AuthUtils';

const { Option } = Select;

type Props = {
    onHide(): void,
    visible: boolean,

    companies: SBillingCompanyInfoDTO[] | undefined,
    changeCompanies: (companies: SBillingCompanyInfoDTO[]) => void,
    
    contacts: SBilContactDTO[] | undefined,
    requestContacts: (id: number, success: () => void) => void,

    //Режим только выбора компании
    selectMode: boolean,
    selectedCompanyId: number,
    onSelect(copanyId: number): void,
    setIsLoading: any,
}

type State = {
    companyEdit: SBillingCompanyInfoDTO | null,
    selectedCompanyId: number | undefined,
    filterText: string,
}

export default class CompaniesForm extends React.Component<Props, State> {
    constructor(props: Props, state: State) {
        super(props);

        this.state = {
            companyEdit: null,
            selectedCompanyId: undefined,
            filterText: '',
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.visible === false && this.props.visible === true) {
            this.setState({selectedCompanyId: this.props.selectedCompanyId});
            this.setColumns(this.props.selectMode);
            this.makeCompaniesDatasource(this.props.companies, '');
            this.forceUpdate();
        }
        
        if (prevProps.companies !== this.props.companies) {
            this.makeCompaniesDatasource(this.props.companies, '');
            this.forceUpdate();
        }
    }

    onOk = () => {
        if (this.state.selectedCompanyId != undefined && this.props.selectMode === true) {
            this.props.onSelect(this.state.selectedCompanyId);
        }
    };

    private dataSource: any = undefined;

    sorter = (a: any, b: any) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b);

    makeCompaniesDatasource = (arr: SBillingCompanyInfoDTO[] | undefined, filter: string) => {
        if (arr == undefined) {
            this.dataSource = undefined;
        } else {
            this.dataSource = arr.filter(x => (x.fullName.toLowerCase().includes(filter.toLocaleLowerCase()) 
                                                || x.inn.toString().toLowerCase().includes(filter.toLocaleLowerCase())
                                                || x.name.toString().toLowerCase().includes(filter.toLocaleLowerCase())
            )).map((x, index) => {
                return ({
                    key: x.id,
                    id: x.id,
                    num: index + 1,
                    edit: x.id,
                    delete: x.id,
                    name: x.name,
                    inn: x.inn,
                })
            });
        }
    }

    companyEdit = (id: number) => {
        this.props.requestContacts(id, () => {
            this.setState({companyEdit: this.props.companies?.find(x => x.id === id) ?? null});
        });
    }

    private columns: any[] = [];

    onDeleteCompany = (id: number) => {
        Modal.confirm({
            title: 'Удалить контакт',
            wrapClassName: Const.MODAL_WRAP_CLASSNAME,
            zIndex: getModalZIndex(),
            content: 'Вы действительно хотите удалить контрагента?',
            width: 500,
            okText: 'Да',
            cancelText: 'Нет',
            onOk: () => {
                sendRequestToBackend(
                    id,
                    'billing/companydelete',
                    (response: any) => {
                        if (response != null) {
                            if (this.props.companies != undefined) {
                                let arr = [...this.props.companies];
                                let i = arr.findIndex(x => x.id === id);
                                if (i != -1) {
                                    arr.splice(i, 1);
                                    this.props.changeCompanies(arr);
                                    this.makeCompaniesDatasource(arr, this.state.filterText);
                                }
                            }
                        } else {
                            message.error('Запрос завершился неудачей');
                        }
                    },
                    this.props.setIsLoading,
                    (error: any) => {
                        console.log(error);
                        message.error(error);
                    }
                );
            },
            onCancel: () => {
            }
        });
    }

    setColumns = (selectMode: boolean) => {
        this.columns = [
            {
                dataIndex: "num",
                title: "№",
                render: (text: any, record: any, index: any) => text,
                width: 60,
            },
            {
                dataIndex: "edit",
                title: "",
                width: 60,
                align: 'center' as 'center',
                render: (val: any, record: any, index: any) => {
                    return (
                        <Tooltip placement="top" title={'Редактировать'}>
                            <Button shape="circle" onClick={() => this.companyEdit(val)} icon={<EditTwoTone twoToneColor={Const.COLORS.SvgIconColor}/>} />
                        </Tooltip>
                    )
                },
            },
            {
                dataIndex: "delete",
                title: "",
                width: 60,
                align: 'center' as 'center',
                render: (val: any, record: any, index: any) => {
                    return (
                        <Tooltip placement="top" title={'Удалить'}>
                            <Button shape="circle" onClick={() => this.onDeleteCompany(val)} icon={<DeleteTwoTone twoToneColor={Const.COLORS.SvgIconColor}/>} />
                        </Tooltip>
                    )
                },
            },
            {
                dataIndex: "name",
                title: "Контрагент",
                render: (val: any, record: any, index: any) => val,
                sorter: (a: any, b: any) => this.sorter(a.name, b.name),
            },
            {
                dataIndex: "inn",
                title: "ИНН",
                width: 170,
                sorter: (a: any, b: any) => this.sorter(a.inn, b.inn),
            },
        ];

        if (selectMode === true) {
            this.columns.splice(1, 2);
        }
    }

    onWriteCompany = (company: SBillingCompanyInfoDTO, contacts: SBilContactDTO[], delContacts: number[]) : void => {
        sendRequestToBackend(
            { company, changedContacts: contacts, delContacts },
            'billing/companywrite',
            (response: SBillingCompanyInfoDTO) => {
                if (response != null) {
                    if (this.props.companies != undefined) {
                        let arr = [...this.props.companies];
                        let i = arr.findIndex(x => x.id === response.id);
                        if (i != -1) {
                            arr[i] = response;
                            this.props.changeCompanies(arr);
                            
                        } else {
                            arr = [response, ...arr];
                            this.props.changeCompanies(arr);
                        }
                        this.makeCompaniesDatasource(arr, this.state.filterText);
                        this.setState({companyEdit: null});
                    }
                } else {
                    message.error('Запрос завершился неудачей');
                }
            },
            this.props.setIsLoading,
            (error: any) => {
                console.log(error);
                message.error(error);
            }
        );

    }

    onAddCompanyClick = () => {
        let company: SBillingCompanyInfoDTO = {
            address: '',
            comment: '',
            contactPerson: '',
            email: '',
            fax: '',
            fullName: '',
            id: -1,
            inn: '',
            mobile: '',
            name: '',
            phone: '',
            postAddress: '',
        }

        this.setState({companyEdit: company});
    }

    render() {
        let pr: any = {};
        if (this.props.selectMode === true) {
            
        }

        return (
            <Modal
                title={ 'Контрагенты' }
                centered={true}
                wrapClassName={MODAL_WRAP_CLASSNAME}
                width={900}
                //zIndex={this.props.zIndex}
                open={this.props.visible}
                onCancel={() => { this.props.onHide() }}
                onOk={this.onOk}
                cancelText={'Закрыть'}
                okText={this.props.selectMode === true ? 'Выбрать' : ''}
                okButtonProps={this.props.selectMode === false ? { style: { display: 'none' } } : {}}
            >
                <div className={styles.FormWrapper}>
                    <div style={{ overflow: 'auto', display: 'flex', flexDirection: 'column', padding: '4px'}}>
                            <Space style={{marginBottom: '6px'}}>
                            { this.props.selectMode === false ? <Button onClick={this.onAddCompanyClick} type='primary'>Добавить контрагента</Button> : null }
                            <Search onChange={(val) => { this.setState({filterText: val.target.value}); this.makeCompaniesDatasource(this.props.companies, val.target.value) }} />
                            </Space>
                            <Table 
                                columns={this.columns} dataSource={this.dataSource} showSorterTooltip={false}
                                scroll={{ y: '60vh' }} bordered size='small'
                                pagination={{ showSizeChanger: true, pageSizeOptions: ["5", "10", "100", "500", "1000"], defaultPageSize: 100, locale: { items_per_page: " / страницу" }}}
                                rowSelection={ this.props.selectMode === false ? undefined : {
                                    type: "radio",
                                    selectedRowKeys: this.state?.selectedCompanyId != undefined ? [this.state.selectedCompanyId] : undefined,
                                    onChange: (selectedRowKeys: any[], selectedRows: any[]) => {
                                        this.setState({selectedCompanyId: selectedRowKeys[0]});
                                    },
                                }}
                                onRow={(record, rowIndex) => {
                                    return {
                                        onClick: event => { if (record.id !== this.state.selectedCompanyId) { this.setState({selectedCompanyId: record.id}) } }, // click row
                                        onDoubleClick: this.onOk,
                                        style: this.props.selectMode === true ? { cursor: 'pointer' } : undefined,
                                    };
                                }}
                            />

                    </div>
                </div>
                {
                    <CompanyEditForm 
                        company={this.state.companyEdit}
                        contacts={this.props.contacts}
                        onHide={() => {this.setState({companyEdit: null})}}
                        onWrite={this.onWriteCompany}
                    />
                }
            </Modal>
        );
    }
}