import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Select, DatePicker, Col, Row} from 'antd';
import moment from 'moment';

const {Option} = Select;
const {RangePicker} = DatePicker;

export const PERIOD_TODAY: string = 'today';
export const PERIOD_THIS_MONTH: string = 'thisMonth';
export const PERIOD_MONTH_YEAR: string = 'monthYear';
export const PERIOD_FROM_TO: string = 'fromTo';

interface IPeriodList {
  id: string,
  name: string
}

interface IDateSelectProps {
  /*periodList: {
	id: string,
	name: string
  }[],*/
  defaultPeriod?: string,
  gutter?: number
  placeholder?: string | null
  onChange: (value: any) => void
}

interface IDateSelectState {
  period: string
}


const PeriodList: IPeriodList[] = [
  {
	id: PERIOD_TODAY,
	name: 'Сегодня'
  },
  {
	id: PERIOD_THIS_MONTH,
	name: 'Текущий месяц'
  },
  {
	id: PERIOD_MONTH_YEAR,
	name: 'Расчетный период'
  },
  {
	id: PERIOD_FROM_TO,
	name: 'Произвольный период'
  },
];


const DateSelect: React.FC<IDateSelectProps> = (props: IDateSelectProps) => {

  const {
	defaultPeriod = '',
	gutter = 0,
	placeholder = null,
	onChange = null
  } = props;

  // console.log('DateSelect render', placeholder, defaultPeriod);

  const [period, setPeriod] = useState<string>(defaultPeriod);

  const doChange = (start: any , end: any): void => {
	if (onChange)
	  onChange([start, end]);
  }


  let datesContent = null;
  let periodWidth: number = 24;
  if (period == PERIOD_MONTH_YEAR) {
	periodWidth = 12;
	datesContent = <DatePicker
	  style={{width: '100%'}}
	  picker="month"
	  format={"MM.YYYY"}
	  onChange={(time, timeString) => {
		let from:any = time?.clone();
		let to:any = time?.clone();
		from.startOf('month');
		to.endOf('month');//.startOf('day');//add('1', 'days')
		//console.log(from.format('DD.MM.YYYY HH:ss'), to.format('DD.MM.YYYY HH:ss'));
		doChange(from, to);
	  }}
	/>;
  }
  else if (period == PERIOD_FROM_TO) {
	periodWidth = 12;
	datesContent = <RangePicker
	  style={{width: '100%'}}
	  format={"DD.MM.YYYY"}
	  // defaultValue={[moment(this.props.tables.from), moment(this.props.tables.to)]}
	  onChange={(time, timeString) => {
		// console.log(time);
		if(time){
			doChange(time[0], time[1]);
		}
	  }}
	/>;
  }




  return (
	<div className={"date-select"}>
	  <Row
		justify={"space-between"}
		gutter={gutter}
	  >
		<Col span={periodWidth}>
		  <Select
			placeholder={placeholder}
			style={{width: '100%'}}
			//defaultValue={period != '' ? period:  undefined}
			dropdownMatchSelectWidth={false}
			onSelect={(value: any) => {

			  setPeriod(value);
			  if (value == PERIOD_TODAY)
				doChange(
				  moment(),
				  moment()
				  );
			  else if (value == PERIOD_THIS_MONTH) {
				doChange(
				  moment().startOf('month'),
				  moment().endOf('month')
				);
			  }
			  else {
				doChange(
				  null,
				  null
				);
			  }
			}}

		  >
			{
			  PeriodList.map(itm =>
				<Option
				  key={itm.id}
				  value={itm.id}
				>{itm.name}</Option>
			  )
			}
		  </Select>
		</Col>
		{
		  datesContent
			? <Col span={24 - periodWidth}>
			  {datesContent}
			</Col>
			: null
		}

	  </Row>
	</div>
  );
}

export default DateSelect;
