import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { TreeSelect, Tooltip, Spin } from 'antd';
import { LoadingOutlined, HomeOutlined, GroupOutlined } from '@ant-design/icons';

import { Group, Point, SResponseDTO, ResponseStatusesEnum } from "../decl";
import { createTree, getId, getGroupsByPoints, removeValue, getPointGroups } from "../utils/TreeUtils";
import {message} from '../utils/Common';
import styles from '../resources/PageHeader.module.less';

const { SHOW_PARENT } = TreeSelect;

export interface IGroupsProps{
    groups: Group[];
    groupPoints: {[groupId: string] : Point[]};
    selectedGroupIds: string[];
    selectedPointIds: string[];
    getPoints: any;
    getPointsParentGroups: any;
    onChange?(groups: string[], points: string[]): void;
    onSelectPoint?(id: string): void;
    onHideDropDown?(): void;
    setIsLoading: any;
    treeCheckable?: boolean;
    onlyGroupsSelect? : boolean;
    size?: 'small' | 'middle' | 'large' | undefined;
    className?: string;
    disabled?: boolean | undefined;
    resources?: string[] | null;
}

interface IGroupsState{
    isGroupsLoading: boolean,
    isDropDownInProgress: boolean,
    searchValue: string
}


export default class Groups extends React.PureComponent<IGroupsProps, IGroupsState> {
    _isMounted: boolean;
    _ids: string[];
    _waitGroupIds: string[];
    _groupsByPoints: any;
    _waitPointIds: string[];
    _tree: any; //!!!!!Эксперименты (не обновляется текстовое при инициализации.)
    _changed: boolean;
    constructor(props: IGroupsProps) {
        super(props);

        this.state = {
            isGroupsLoading: false,
            isDropDownInProgress: false,
            searchValue: ''
        }

        this._isMounted = false;
        this._ids = [];
        this._waitGroupIds = [];
        this._groupsByPoints =  {};
        this._waitPointIds = [];
        this._tree = React.createRef();
        this._changed = false;
    }
    componentDidMount() {
        this._isMounted = true;
        this._waitGroupIds = [];
        this._groupsByPoints =  {};
        this._waitPointIds = [];
        this.loadTree();
    }

    componentDidUpdate() {
        this.loadTree();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    loadTree = ()=>{
        //Нужно обеспечить предварительную подгрузку групп с выбранными точками учёта и верхней единственной группы.
        if(!this.state.isGroupsLoading){
            const groupIds: string[] = [];
            const pointIds: string[] = [];
            const _this = this;

            const getGroupPoints = (groupId: string) => {
                if(_this._waitGroupIds.indexOf(groupId) < 0){
                    _this._waitGroupIds.push(groupId);

                    _this.props.getPoints(groupId, ()=>{
                        removeValue(_this._waitGroupIds, groupId);

                        if(_this._waitGroupIds.length === 0){
                            _this.setState({ isGroupsLoading: false });
                        }
                    });
                }
            }

            //Проверка наличия единственной корневой группы.
            if(this.props.groups.length === 1){
                //В корне одна группа, которая раскрывается при создании дерева.
                const groupId = this.props.groups[0].id; 
                groupIds.push(groupId);
            }

            if( this.props.selectedPointIds && this.props.selectedPointIds.length > 0) {
                for (let pointId of this.props.selectedPointIds) {
                    const pointGroups = getPointGroups(
                        this.props.groups,          //Корневые группы пользователя, содержащие дочерние группы.
                        this.props.groupPoints,     //Точки учёта по группам {[groupId: string] : Point[]}.
                        pointId);                   //Идентификатор точки учёта.
    
                    //Не найдено ни одной группы. !!!!! М.б не все группы подгружены.
                    if(pointGroups.length === 0){
                        //В системе нет данных о группах, в которых находится данная точка учёта.
                        if (pointIds.indexOf(pointId) < 0) {
                            pointIds.push(pointId);
                        }
                    }
                    else{
                        //Проверить необходимость загрузки точек для найденных групп.
                        for(let group of pointGroups){
                            if(groupIds.indexOf(group.id) < 0){
                                groupIds.push(group.id);
                            }
                        }
                    }
    
                    //Проверить наличие точек учёта с неизвестными группами.
                    if(pointIds.length > 0){
                        //Получить группы по точкам учёта.
                        getGroupsByPoints(pointIds, (groupsByPoints: any) => {
                            if (groupsByPoints && _this._isMounted) {
                                const pointIds = Object.keys(groupsByPoints);
                                for (let pointId of pointIds) {
                                    const loadedPointGroups: Group[] = groupsByPoints[pointId];
                                    for(let group of loadedPointGroups){
                                        getGroupPoints(group.id);
                                    }
                                }
                            }
                        });
                    }
                }

                if(groupIds.length > 0 || pointIds.length > 0){
                    this.setState({ isGroupsLoading: true });

                    for(let groupId of groupIds){
                        getGroupPoints(groupId);
                    }
                }
            }
        }
    }

    //value: ValueType, labelList: React.ReactNode[], extra: ChangeEventExtra
    onChange = (value: any, labelList: any, extra: any) => {
        const treeCheckable = this.props.treeCheckable === undefined ? true : this.props.treeCheckable;
        if (treeCheckable && this.props.onChange) {
            const triggerTreeId = extra.triggerValue;
            let triggerIsChecked = extra.checked;
            const groups: string[] = [];
            const points: string[] = [];

            //Изменение.
            for (let treeId of value) {
                const id = getId(treeId, this._ids);
                if (treeId[0] == 'p') {
                    if(points.indexOf(id) < 0){
                        points.push(id);
                    }
                }
                else {
                    if(groups.indexOf(id) < 0){
                        groups.push(id);
                    }
                }
            }


            const triggerId = getId(triggerTreeId, this._ids);
            if(!triggerIsChecked){
                if (triggerTreeId[0] == 'p') {
                    const pointGroups = getPointGroups(this.props.groups, this.props.groupPoints, triggerId);
                    for(let group of pointGroups){
                        removeValue(groups, group.id);
                    }
                    removeValue(points, triggerId);
                }
                else {
                    removeValue(groups, triggerId);
                }
            }
            else{
                if (triggerTreeId[0] == 'p') {
                    const pointGroups = getPointGroups(this.props.groups, this.props.groupPoints, triggerId);
                    //Найти группы в которых все узлы выбраны.
                    for(let group of pointGroups){
                        const groupPoints = this.props.groupPoints[group.id];
                        let isChecked = true;
                        for(let point of groupPoints){
                            if(points.indexOf(point.id) < 0){
                                isChecked = false;
                                break;
                            }
                        }
                        if(isChecked){
                            if(groups.indexOf(group.id) < 0){
                                groups.push(group.id);
                            }
                            for(let point of groupPoints){
                                removeValue(points, point.id);
                            }
                        }
                    }
                }
            }

            this.props.onChange(groups, points);
        }
        else if (this.props.onSelectPoint && typeof value === 'string' && value[0] == 'p') {
            const id = getId(value, this._ids);
            const isLocked = extra.triggerNode.props.point.isLocked;
            if (!isLocked) {
                this.props.onSelectPoint(id);
            }
            else {
                message.warning('Точка учёта заблокирована - нет оплаты.');
            }
        }
        this._changed = true;
    };

    onLoadData = (node: any):any => {
        //console.log('load' + node);
        const group = node.group;
        const getGroupPoints = this.props.getPoints;
        return new Promise<void>((resolve) => {
            if(group && group.numberOfPoints > 0){
                getGroupPoints(group.id, () => {
                    resolve();
                });
            }
            else{ 
                resolve();
                return;
            }
        });
    };

    onDropDownOpen = (open: boolean) => {
        if(open){
            this._changed = false;
        }
        else if(this._changed && this.props.onHideDropDown){
            this.props.onHideDropDown();
            this._changed = false;
        }
    };
    
    getExpandedGroupIds = ()=>{
        const result: string[] = [];
            const pointIds: string[] = Object.keys(this._groupsByPoints);
            for (let pointId of pointIds) {
                const groups: Group[] = this._groupsByPoints[pointId];
                for (let group of groups) {
                    if(result.indexOf(group.id) < 0){
                        result.push(group.id);
                    }         
                }
            }
        return result;
    }

    render() {
        let plHolder = '';
        if (this.props.treeCheckable === false) {
            if (this.props.onlyGroupsSelect === true) {
                plHolder = 'группу';
            } else {
                plHolder = 'точку учета';
            }
        } else {
            if (this.props.onlyGroupsSelect === true) {
                plHolder = 'группы';
            } else {
                plHolder = 'точки учета';
            }
        }
        let isReady: boolean = !this.state.isGroupsLoading;
        if(isReady){
            const treeCheckable = this.props.treeCheckable === undefined ? true : this.props.treeCheckable;
            const onlyPointsSelect = !treeCheckable;
            const onlyGroupsSelect = this.props.onlyGroupsSelect === undefined ? false : this.props.onlyGroupsSelect;

            let checkValue: string[] = [];
            const expandedKeys: string[] = [];
            this._ids = [];

            let selectedIds: string[] = [];
            selectedIds = selectedIds.concat(this.props.selectedGroupIds);
            selectedIds = selectedIds.concat(this.props.selectedPointIds);
    
            const maxTagLength = treeCheckable ? 10 : 30;
            const treeData = createTree(
                this.props.groups,
                this.props.groupPoints,
                selectedIds,
                this.getExpandedGroupIds(),
                checkValue,
                this._ids,
                expandedKeys,
                maxTagLength,
                (props: any)=>{
                    if (props.value.length > 0 && props.value[0] === 'g')
                        return <GroupOutlined/>;
                    else
                        return <HomeOutlined/>;
                }, 
                onlyPointsSelect,
                onlyGroupsSelect,
                this.props.resources);

            const maxTagCount = this.state.searchValue.length > 0 ? 0 : 1;
            const tProps = {
                size: this.props.size,
                //virtual: false,
                treeData,
                //defau,ltValue: value,
                value: treeCheckable ? checkValue : checkValue[0],
                treeDefaultExpandedKeys: expandedKeys,
                //treeExpandedKeys: [],
                onChange: this.onChange,
                onSearch: (value: string) => {
                    this.setState({ searchValue: value });
                },
                treeCheckable: treeCheckable,
                treeLine: {showLeafIcon: false},
                treeIcon: true,
                showSearch: true,
                searchValue: this.state.searchValue,
                showCheckedStrategy: SHOW_PARENT,
                placeholder: 'Выберите ' + plHolder,
                //size: "small",
                maxTagCount: maxTagCount,
                maxTagPlaceholder: (prop: any)=>{
                    return <div>...</div>;
                },
                treeNodeLabelProp: 'shortTitle',
                listHeight: 500,
                dropdownMatchSelectWidth: 350,
                filterTreeNode: (search:string, item:any) => {
                        let text = '';
                        if(item.point) text = item.point.number;
                        else if(item.group) text = item.group.name;
                        else if(typeof item.title === 'string') text = item.title;
                        return text.toLowerCase().indexOf(search.toLowerCase()) >= 0;
                      },
                //onTreeExpand: this.onExpand,
                loadData: this.onLoadData,
                onDropdownVisibleChange: this.onDropDownOpen
            };
            return (
                <TreeSelect  disabled={this.props.disabled} className={this.props.className == undefined ? styles.groupsTreeSelect : this.props.className} {...tProps} ref={this._tree} />
            );
        }
        else{
            return <div className ={styles.groupsStub}>
                <LoadingOutlined />
            </div>;
        }
    }
}


