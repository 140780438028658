import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';

import { ApplicationState } from '../store';
import * as PageStore from '../store/Page';
import * as UserProfileStore from '../store/UserProfile';
import * as MnemoschemaStore from '../store/MnemoschemaStore';
import { BaseProfile, Point, SPointsFilter } from '../decl';
import styles from '../resources/Page.module.less';
import stylesMneno from '../resources/Mnemoschema.module.less';
import { Space, Tabs, Tag } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';

const { TabPane } = Tabs;
// const { Column } = Table;
// const { confirm } = Modal;
// const { Option } = Select;
// const { RangePicker } = DatePicker;

const actionCreators = { ...PageStore.actionCreators, ...UserProfileStore.actionCreators, ...MnemoschemaStore.actionCreators };

type MnemoschemaProps =
    {
        page: PageStore.PageState,
        profile: BaseProfile,
        mnemoschemas: MnemoschemaStore.IMnemoschemasState,
        userProfile: UserProfileStore.UserProfileState,
        pointId: string,
    } &
    typeof actionCreators &
    RouteComponentProps<{}>

interface IMnemoschemaState {
    imgHeight: number,
    imgWidth: number,
}

class Mnemoschema extends React.PureComponent <MnemoschemaProps, IMnemoschemaState > {
    constructor(props: MnemoschemaProps){
        super(props);
        this.state = {
            imgHeight: 0,
            imgWidth: 0,
        }
    }

    componentDidUpdate(prevProps: MnemoschemaProps) {
        if (prevProps.mnemoschemas?.picture !== this.props.mnemoschemas.picture) {
            this.updateImgSize();
        }
    }

    componentDidMount() {
        this.updateImgSize();
    }

    updateImgSize = () => {
        if (this.props.mnemoschemas?.picture != undefined) {
            const img = new Image();
            img.onload = () => {
                this.setState({imgHeight: img.height, imgWidth: img.width});
            }
            img.src = `data:image;base64,${this.props.mnemoschemas.picture}`;
        } else {
            this.setState({imgHeight: 0, imgWidth: 0});
        }
    }

    public render() {
        //console.log('mnemoschemas ', this.props.mnemoschemas);
        let res: string | JSX.Element = '-';
        if (this.props.mnemoschemas.isLoadingDataError === true) {
            if (this.props.mnemoschemas.lastUpdateData !== null) {
                res = <span style={{color: 'red'}}>{moment(this.props.mnemoschemas.lastUpdateData).format('YYYY.MM.DD HH:mm:ss')} </span>
            }
        } else if (this.props.mnemoschemas.isLoadingData === true) {
            res = <>{this.props.mnemoschemas.lastUpdateData !== null ? moment(this.props.mnemoschemas.lastUpdateData).format('YYYY.MM.DD HH:mm:ss') : null} <LoadingOutlined /></>;
        } else if (this.props.mnemoschemas.lastUpdateData !== null) {
            res = moment(this.props.mnemoschemas.lastUpdateData).format('DD.MM.YYYY HH:mm:ss');
        }

        return (
            <div className={styles.container}>
                {
                    !(this.props.mnemoschemas != undefined && this.props.mnemoschemas.mnemoschemas != undefined && this.props.mnemoschemas.isLoading != true
                        && this.props.mnemoschemas.picture != undefined) 
                    ? this.props.mnemoschemas.isLoading === true || this.props.mnemoschemas.isLoadingData === true ? <LoadingOutlined /> : null :
                    !this.props.mnemoschemas.currentMnenoschemaId ? null :  
                    <>
                        <Tag style={{margin: '3px'}}>
                            <span>Последнее обновление: </span> {res} 
                        </Tag>

                        {
                            Object.keys(this.props.mnemoschemas.abonents ?? {}).map(item => 
                                <Tag color="cyan" key={item} style={{margin: '3px'}}>
                                    {this.props.mnemoschemas.abonents?.[item]}
                                </Tag>
                            )
                        }
                        <div style={{display: 'flex', position: 'relative', height: 'calc(100% - 50px)', marginTop: '20px', width: '98%', overflow: 'auto'}}>
                            <div style={{display: this.state.imgHeight > 0 ? 'inherit' : 'none', position: 'relative', margin: 'auto', width: this.state.imgWidth, height: this.state.imgHeight}}>
                                <img style={{position: 'absolute'}} src={`data:image;base64,${this.props.mnemoschemas.picture}`} />
                                {
                                    this.props.mnemoschemas.styledFields == undefined ? null
                                    : this.props.mnemoschemas.styledFields.filter(item => item.fieldValue !== '' && item.widthVal > 0 && !item.fieldValue.startsWith('State:')).map(item => {
                                        return (
                                            <div 
                                                key={'' + item.xval + item.yval + item.widthVal + item.heightVal + item.fieldValue}
                                                className={stylesMneno.field} 
                                                style={{
                                                    backgroundColor: item.backGroundColor[1], 
                                                    marginLeft: item.xval - 2, 
                                                    marginTop: item.yval - 2, 
                                                    width: item.widthVal + 4, 
                                                    height: item.heightVal + 4, 
                                                    //fontFamily: item.fontFamilyVal,
                                                    //fontSize: item.fontSizeVal + 'px',
                                                    fontStyle: item.fontItalicVal ? 'italic' : 'inherit',
                                                    fontWeight: item.fontBoldVal ? 'bold' : 'inherit',
                                                    color: '#' + item.fontColorVal[2],
                                                    border: '2px solid rgba(255, 255, 255, 0.2)',
                                                }}
                                            >
                                                {item.fieldValue}
                                            </div>
                                        )
                                    })
                                }
                                {
                                    this.props.mnemoschemas.styledFields == undefined ? null
                                    : this.props.mnemoschemas.styledFields.filter(item => item.fieldValue.startsWith('State:')).map(item => {
                                        let arr = item.fieldValue.split(':');
                                        if (arr.length === 3) {
                                            const field = this.props.mnemoschemas.fields?.find(item => item.id === Number(arr[1]));
                                            const sp = field?.statePictures[Number(arr[2])];
                                            if (sp != undefined) {
                                                return (
                                                    <img 
                                                        key={'' + field?.id + sp.state}
                                                        src={`data:image;base64,${sp.image}`}
                                                        style={{
                                                            position: 'absolute',
                                                            marginLeft: item.xval, 
                                                            marginTop: item.yval, 
                                                        }}
                                                    />
                                                )
                                            }
                                        }
                                    })
                                }
                            </div>
                        </div>
                    </>
                }
            </div>
        );
    }    
}

export default connect(
    (state: ApplicationState) => {
        return {
            userProfile: state.userProfile,
            mnemoschemas: state.mnemoschemas,
            pointId: state.page.pointId,
        }
    },
    actionCreators
)(Mnemoschema as any);
