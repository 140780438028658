import { DivIcon, DivIconOptions, Icon } from 'leaflet';
import L from 'leaflet'
import './Marker.css'

class MarkerHelper {
    static getDataMarker(color: 'red' | 'yellow' | 'green' | 'gray', value: string, valueColor: string): DivIcon {
        let marker;
        const defTextColor = 'black';
        
        if (color === 'red') {
            marker = L.divIcon({
                ...MarkerHelper.redDataMarker,
                html: (MarkerHelper.redDataMarker.html as string).replace('MarkerInsideValue', value).replace('MarkerTextColor', valueColor ?? defTextColor)
            });
        } else if (color === 'yellow') {
            marker = L.divIcon({
                ...MarkerHelper.yellowDataMarker,
                html: (MarkerHelper.yellowDataMarker.html as string).replace('MarkerInsideValue', value).replace('MarkerTextColor', valueColor ?? defTextColor)
            });
        } else if (color === 'green') {
            marker = L.divIcon({
                ...MarkerHelper.greenDataMarker,
                html: (MarkerHelper.greenDataMarker.html as string).replace('MarkerInsideValue', value).replace('MarkerTextColor', valueColor ?? defTextColor)
            });
        } else {
            marker = L.divIcon({
                ...MarkerHelper.grayDataMarker,
                html: (MarkerHelper.grayDataMarker.html as string).replace('MarkerInsideValue', value).replace('MarkerTextColor', valueColor ?? defTextColor)
            });
        }

        if (value.length > 2) {
            marker.options.html = (marker.options.html as string).replace('marker-text', 'marker-text-small')
        }

        return marker;
    }

    static getAllImagesUrls(): string[] {
        let res: string[] = [];
        res.push(this.shadowImg.image);
        res.push(this.dataMarkerImgGray.image);
        res.push(this.dataMarkerImgGreen.image);
        res.push(this.dataMarkerImgRed.image);
        res.push(this.dataMarkerImgYellow.image);
        res.push(this.redMarker.options.iconUrl);
        res.push(this.yellowMarker.options.iconUrl);
        res.push(this.grayMarker.options.iconUrl);
        res.push(this.greenMarker.options.iconUrl);

        return res;
    }

    static getMarker(color: 'red' | 'yellow' | 'green' | 'gray'): Icon {
        let marker;
        if (color === 'red') {
            marker = MarkerHelper.redMarker;
        } else if (color === 'yellow') {
            marker = MarkerHelper.yellowMarker;
        } else if (color === 'green') {
            marker = MarkerHelper.greenMarker;
        } else {
            marker = MarkerHelper.grayMarker
        }

        return marker;
    }
    

    static getClusterMarker(size: number, value: string, textColor: string, borderColorClass: string = ''): DivIcon {
        // let size = 'LargeXL';
        // //const count = cluster.getChildCount();
        // const count = 0;
        // if (count < 10) {
        //     size = 'Small';
        // }
        // else if (count >= 10 && count < 100) {
        //     size = 'Medium';
        // }
        // else if (count >= 100 && count < 500) {
        //     size = 'Large';
        // }
        // const options = {
        //     cluster: `markerCluster${size}`,
        // };

        let spanStyle;
        if(size <= 27) spanStyle = "top:2px; font-size:1em;";
        else if(size <= 34) spanStyle = "top:4px; font-size:1.1em;";
        else if(size <= 41) spanStyle = "top:6px; font-size:1.2em;";
        else if(size <= 48) spanStyle = "top:8px; font-size:1.3em;";
        else spanStyle = "top:10px; font-size:1.4em;";

        spanStyle += "color:" + textColor + ";";

        return L.divIcon({
            html: `<div><span style="` + spanStyle + `">${value}</span></div>`,
            //html: `<div><span class="markerClusterLabel">${max}</span></div>`,
            className: 'marker-cluster-custom ' + borderColorClass,
            //className: `${options.cluster}`
            iconSize: L.point(size, size, true),
        });
    }

    static getNsMarker(size: number, text: string, isActive:boolean): DivIcon {
        let spanStyle;
        if(size <= 27) spanStyle = "top:4px; font-size:1em;";
        else if(size <= 34) spanStyle = "top:6px; font-size:1.1em;";
        else if(size <= 41) spanStyle = "top:8px; font-size:1.2em;";
        else if(size <= 48) spanStyle = "top:10px; font-size:1.3em;";
        else spanStyle = "top:12px; font-size:1.4em;";

        return L.divIcon({
            html: `<div><span style="` + spanStyle + `">${text}</span></div>`,
            className: isActive ? 'marker-ns-active' : 'marker-ns-unactive',
            //className: `${options.cluster}`
            iconSize: L.point(size, size, true),
        });
    }

    static readonly shadowImg = {
        bindPoint: [13, 40],
        size: [41, 41],
        image: 'Images/leaf-shadow.png'
    }

    static readonly dataMarkerImgGreen = {
        bindPoint: [14, 42],
        size: [30, 43],
        image: 'Images/DataMarkerGreen.png'
    }

    static readonly dataMarkerImgRed = {
        bindPoint: [14, 42],
        size: [30, 43],
        image: 'Images/DataMarkerRed.png'
    }

    static readonly dataMarkerImgGray = {
        bindPoint: [14, 42],
        size: [30, 43],
        image: 'Images/DataMarkerGray.png'
    }

    static readonly dataMarkerImgYellow = {
        bindPoint: [14, 42],
        size: [30, 43],
        image: 'Images/DataMarkerYellow.png'
    }

    static greenDataMarker: DivIconOptions = MarkerHelper.makeDataMarker(MarkerHelper.dataMarkerImgGreen, MarkerHelper.shadowImg);
    static redDataMarker: DivIconOptions = MarkerHelper.makeDataMarker(MarkerHelper.dataMarkerImgRed, MarkerHelper.shadowImg);
    static grayDataMarker: DivIconOptions = MarkerHelper.makeDataMarker(MarkerHelper.dataMarkerImgGray, MarkerHelper.shadowImg);
    static yellowDataMarker: DivIconOptions = MarkerHelper.makeDataMarker(MarkerHelper.dataMarkerImgYellow, MarkerHelper.shadowImg);


    static readonly redMarker = L.icon({
            iconUrl: 'Images/marker_red.png',
            shadowUrl: 'Images/leaf-shadow.png',
            iconSize:     [24, 38],
            shadowSize:   [41, 41],
            iconAnchor:   [11, 37],
            shadowAnchor: [13, 40],
            popupAnchor:  [1, -30]
        });

    static readonly yellowMarker = L.icon({
        iconUrl: 'Images/marker_yellow.png',
        shadowUrl: 'Images/leaf-shadow.png',
        iconSize:     [24, 38],
        shadowSize:   [41, 41],
        iconAnchor:   [11, 37],
        shadowAnchor: [13, 40],
        popupAnchor:  [1, -30]
    });

    static readonly greenMarker = L.icon({
        iconUrl: 'Images/marker_green.png',
        shadowUrl: 'Images/leaf-shadow.png',
        iconSize:     [24, 38],
        shadowSize:   [41, 41],
        iconAnchor:   [11, 37],
        shadowAnchor: [13, 40],
        popupAnchor:  [1, -30]
        });

    static readonly grayMarker = L.icon({
            iconUrl: 'Images/marker_gray.png',
            shadowUrl: 'Images/leaf-shadow.png',
            iconSize:     [24, 38],
            shadowSize:   [41, 41],
            iconAnchor:   [11, 37],
            shadowAnchor: [13, 40],
            popupAnchor:  [1, -30]
        });



    private static makeDataMarker(markerImg: typeof MarkerHelper.dataMarkerImgGreen, shadowImg: typeof MarkerHelper.shadowImg): DivIconOptions {
        let htmlImg = `<img src="${markerImg.image}" style="margin-left: ${shadowImg.bindPoint[0] - markerImg.bindPoint[0]}px; margin-top: ${shadowImg.bindPoint[1] - markerImg.bindPoint[1]}px; width: ${markerImg.size[0]}px; height: ${markerImg.size[1]}px;"></img>`
        let htmlText = `<div class="marker-text" style="color:MarkerTextColor; text-align: center; position: relative; width: ${markerImg.size[0]}px; height: ${markerImg.size[1]}px">MarkerInsideValue</div>`
        
        let icon: DivIconOptions = {        
            html: `<div style="position: absolute; background-image: url(${shadowImg.image}); width: ${shadowImg.size[0]}px; height: ${shadowImg.size[1]}px">${htmlImg}</div>${htmlText}`,
            className: '',
            iconAnchor:   [13, 40], // point of the icon which will correspond to marker's location
            popupAnchor:  [1, -33] // point from which the popup should open relative to the iconAnchor
        };

        return icon;
    }
}

export default MarkerHelper;
