import * as React from 'react';
import { Input, Select, Tooltip } from 'antd';
import { connect } from 'react-redux';

import Login from './Login2';
import { ApplicationState } from '../store';
import * as PageStore from '../store/Page';
import * as UserProfileStore from '../store/UserProfile';
import * as ReportsStore from '../store/Reports';
import { RouteComponentProps } from 'react-router';
import styles from '../resources/Page.module.less';
import * as SiteLogStore from '../store/SiteLogStore';

const { Option } = Select;
const { Search } = Input;

import {
    SyncOutlined,
    TableOutlined,
    FileZipOutlined} from '@ant-design/icons';
import { MiddleContentMessageBlock } from './HeaderForm';
import RangePickerLimit from './Helper/RangePickerLimit';
import CollapsedButton from './Helper/CollapsedButton';
import { BACKEND_URL, getHeaders, sendRequestToBackend } from '../utils/AuthUtils';
import { convertBase64ToBinary, makeTime, message, saveFile } from '../utils/Common';

const actionCreators = { ...PageStore.actionCreators, ...UserProfileStore.actionCreators, ...ReportsStore.actionCreators, ...SiteLogStore.actionCreators };

type HeaderProps =
    { userProfile: UserProfileStore.UserProfileState, reports: ReportsStore.IReportsState, siteLog: SiteLogStore.ISiteLogState, windowWidth: number, isMenuCollapsed: boolean} &
    typeof actionCreators &
    RouteComponentProps<{}>

interface HeaderState {
    textFilter: string,
    userActivityReportLoading: boolean,
}

class SiteLogHeaderForm extends React.PureComponent<HeaderProps, HeaderState>{
    constructor(props: HeaderProps) {
        super(props);

        this.state ={
            textFilter: '',
            userActivityReportLoading: false,
        }
    }

    componentDidUpdate(prevProps: HeaderProps) {
        if (this.props.siteLog.userEventsTypes === undefined && this.props.siteLog.isLoadingTypes === false) {
            this.props.requestSiteLogTypes();
        }

        if (prevProps.siteLog.currentUserEventId !== this.props.siteLog.currentUserEventId) {
            this.onRefresh();
        }
    }

    componentDidMount() {
        if (this.props.siteLog != undefined) {
            this.onRefresh();
        }
    }
    onRefresh = () => {
        this.props.requestSiteLogs(this.props.siteLog.dateFrom, this.props.siteLog.dateTo, this.state.textFilter, this.props.siteLog.currentUserEventId)
    };

    makeUserActivityReport = () => {
        //DateTime  beginDate = new DateTime(DateTime.Today.Year, DateTime.Today.Month, 1).AddMonths(-1);
        //DateTime endDate = new DateTime(DateTime.Today.Year, DateTime.Today.Month, 1).AddDays(-1);
        // let date = moment(new Date(this.props.siteLog.dateFrom).setHours(0, 0, 0, 0)).set({'date': 1});
        // const beginDate = date.add(-1, 'month').toDate();
        // const endDate = date.add(-1, 'day').toDate();

        this.setState({userActivityReportLoading: true});

        sendRequestToBackend(
            {timeStart: this.props.siteLog.dateFrom, timeEnd: this.props.siteLog.dateTo},
            'Log/useractivityreport',
            (response: any) => {
                const fileName = 'Отчет по использованию сайта' + '.xls';
            
                message.success('Отчет получен');
                this.setState({userActivityReportLoading: false});
                saveFile(fileName, convertBase64ToBinary(response.base64data));
            },
            null,
            (error: any) => {
                message.error('Ошибка:' + error);
                console.log(error);
                this.setState({userActivityReportLoading: false});
            },
            this.props.closeSession
        );
    }

    render() {
        const isColapsed = (!this.props.isMenuCollapsed && this.props.windowWidth < 1920) ||
                            (this.props.isMenuCollapsed && this.props.windowWidth < 1680);
        return (
            this.props.siteLog == undefined || this.props.siteLog.isLoading === true || this.props.siteLog.isLoadingTypes === true ? null :
            <div className={styles.flexcontainer}>
                <div className={styles.comboblock}>
                    <div className={styles.flexitem}>
                        <Tooltip placement="bottomRight" title={'Выбрать период'}>
                            <RangePickerLimit
                                style={{ minWidth: '250px' }}
                                value={[this.props.siteLog.dateFrom, this.props.siteLog.dateTo]}
                                allowClear={false}
                                onChange={(val) => {
                                        this.props.setSiteLogDates(val?.[0] ?? makeTime(new Date(), 0, 0, 0), val?.[1] ?? makeTime(new Date(), 23, 59, 59));
                                        //console.log('RangePickerLimit', val);
                                    }
                                }
                                limitDays={40}
                            />
                        </Tooltip>
                    </div>

                    <div className={styles.flexitem}>
                        <Search onSearch={this.onRefresh} onPressEnter={this.onRefresh} placeholder="Поиск" style={{minWidth: '150px'}} value={this.state.textFilter} onChange={(value) => this.setState({textFilter: value.target.value})}/>
                    </div>

                    <div className={styles.flexitem}>
                        <Tooltip placement="bottomRight" title={'Выбор типа события'}>
                            <Select disabled={false} value={this.props.siteLog.currentUserEventId} onChange={val => {this.props.setCurrentUserEventId(val)}} dropdownMatchSelectWidth={false} style={{ width: '180px' }}>
                            {
                                <>
                                    <Option key={-1} value={-1}>{'Все'}</Option>
                                    {
                                        this.props.siteLog.userEventsTypes?.map(item => { return (
                                            <Option key={item.id} value={item.id}>{item.name}</Option>
                                        )})
                                    }
                                </>
                                
                            }
                            </Select>
                        </Tooltip>
                    </div>
                </div>
                <div className={styles.buttonsblock}>
                    <CollapsedButton isCollapsed={isColapsed} onClick={this.onRefresh} icon={<SyncOutlined />} type="primary">Обновить</CollapsedButton>
                    <CollapsedButton isCollapsed={isColapsed} onClick={() => this.props.setSiteLogMakeCSV(true)} icon={<TableOutlined/>} type="primary">Выгрузить в таблицу</CollapsedButton>
                    <CollapsedButton loading={this.state.userActivityReportLoading} isCollapsed={isColapsed} onClick={this.makeUserActivityReport} icon={<FileZipOutlined />} type="primary">Отчет по сайту</CollapsedButton>
                </div>
                
                <div className={styles.mbitem}>
                    <MiddleContentMessageBlock windowSize={this.props.windowWidth} maxWindowSize={this.props.isMenuCollapsed? 1380:1460} onlyIcon={this.props.windowWidth < 2350} hideEmail={false} isSplit={false}/>
                </div>
                <div className={styles.flexuseritem}>
                    <Login />
                </div>
            </div>
        )
    }
}



export default connect(
    (state: ApplicationState) => {
        return {
            userProfile: state.userProfile,
            siteLog: state.siteLog,
            windowWidth: state.page.windowWidth,
            isMenuCollapsed: state.siderMenu?.collapsed
        }
    },
    actionCreators
)(SiteLogHeaderForm as any);
