import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';

import { ApplicationState } from '../store';
import * as PageStore from '../store/Page';
import * as UserProfileStore from '../store/UserProfile';
import { BaseProfile } from '../decl';
import {getPage} from '../utils/Common';
import styles from '../resources/Page.module.less';

const actionCreators = { ...PageStore.actionCreators, ...UserProfileStore.actionCreators };

type SupportProps =
    {
        page: PageStore.PageState,
        profile: BaseProfile,
    } &
    typeof actionCreators &
    RouteComponentProps<{}>

interface ISupportState {
    code: string,
    caption: string,
    content: string
}

class Support extends React.PureComponent <SupportProps, ISupportState > {
    myRef: any;
	_isMounted: boolean;

    constructor(props: SupportProps){
        super(props);
        this.myRef = React.createRef();
        
        this.state = {
            code: 'techsupport',
            caption: '',
            content: ''
        }
		this._isMounted = false;
    }
    componentDidMount(){
		this._isMounted = true;
        getPage(this.state.code, (page: any) => {
            //console.log("SUPPORT page receive", this._isMounted);
            if(this._isMounted && page){
                this.setState({caption: page.caption, content: page.content});
            }
        });
    }
	componentWillUnmount() {
		this._isMounted = false;
	}

    public render() {
        const html: string = this.state.content;
        
        return (
                <div className={styles.container}>
                    <div className={styles.centerBlock}>
                        <div dangerouslySetInnerHTML={{ __html: html }} ref={this.myRef} />
                    </div>
                </div>
        );
    }    
}

export default connect(
    (state: ApplicationState) => {
        return {
            page: state.page,
            profile: state.userProfile?.profile
        }
    },
    actionCreators
)(Support as any);
