import * as React from 'react';

import { Button, Modal, Table, Switch, Tooltip } from 'antd';

import * as AdminStore from '../../store/AdminStore';

import { getModalZIndex, message } from '../../utils/Common';
import { MODAL_WRAP_CLASSNAME } from '../../utils/Const';

import { PeriodsEnum, Point, SPointTaskDTO } from '../../decl';
import { DeleteTwoTone, EditTwoTone, ExclamationCircleOutlined, LoadingOutlined, PlayCircleTwoTone, StopTwoTone, SyncOutlined } from '@ant-design/icons';
import { QueryItemTypes, SQueryItemDTO, SQueryType, QueryTypesEnum } from '../../store/AdminStore';
import { sendRequestToBackend } from '../../utils/AuthUtils';
import * as Const from '../../utils/Const';
import QueryItemEditForm from './QueryItemEditForm';

type Props = {
    onHide(): void,
    onChange?: () => void,
    onQueryItemsStarted?: (id: string, taskIds: number[]) => void,
    setIsLoading: any,
    //Наличие идентификатора автоматически открывает форму, если null то закрывает
    point: Point | null,
    queryItemTypes: QueryItemTypes | undefined,
    zIndex: number,
    activeTasks: SPointTaskDTO[],
    readData: any,
    setActiveLogTask: any,
    getActiveTasks: any
}

type State = {
    queryItems: SQueryItemDTO[] | undefined,
    //не null открвает окно редактирвания задания
    queryItemEdit: SQueryItemDTO | null,
    modalZIndex: number
}

export const loadQueryItems = (pointId: string, setIsLoading: any, onOk: any) => {
    sendRequestToBackend(
        pointId,
        'admin/queryitems',
        (response: any) => {
            if (response != null) {
                onOk(response);
            } else {
                message.warning("Не удалось получить список заданий на опрос");
            }
        },
        setIsLoading,
        (error: any) => {
            message.warning("Ошибка при получении заданий на опрос");
        }
    );
}

export const requestQueryPointData = (pointId: string | undefined, queryId: string | undefined, queryType: SQueryType, readData: any, setActiveLogTask: any) => {

    let period: PeriodsEnum = PeriodsEnum.current;
    let isSettings = false;
    switch (queryType.id) {
        case QueryTypesEnum.current:
            period = PeriodsEnum.current;
            break;
        case QueryTypesEnum.current_settings:
            period = PeriodsEnum.current;
            isSettings = true;
            break;
        case QueryTypesEnum.archive_hour:
            period = PeriodsEnum.hour;
            break;
        case QueryTypesEnum.archive:
            period = PeriodsEnum.day;
            break;
    }

    if (pointId) {
        readData(
            period,
            [pointId],
            100,
            isSettings,
            (code: number, ids: any) => {
                if (code === 0) {
                    message.success('Запрос на получение данных из приборов отправлен.');
                    setActiveLogTask(queryId, 0);
                }
                if (code === 2) {
                    message.success('Данные из приборов получены.');
                }
                if (code === 4) {
                    message.warning('Не все данные из приборов удалось получить.');
                }
            },
            (error: string) => {
                message.error(error);
            });
    }
    else {
        message.error('Точка учёта для сбора настроек не определена');
    }

}

export const startQueryItem = (
    queryItem: SQueryItemDTO | undefined,
    setIsLoading: any,
    setActiveLogTask: any,
    getActiveTasks: any) => {

    if (queryItem != undefined) {
        sendRequestToBackend(
            queryItem,
            'admin/queryitemstart',
            (response: number[]) => {
                if (response != null && response.length > 0) {
                    message.success("Запрос на запуск задания прошел успешно");
                    setActiveLogTask(queryItem.id, response[0]);
                    //this.setState({ isQueryItemsChanged: true });
                    getActiveTasks();
                } else {
                    message.warning("Запрос на запуск задания закончился неудачей");
                }
            },
            setIsLoading,
            (error: any) => {
                message.warning("Ошибка при запросе на старт задания");
                console.log(error);
                message.error(error);
            }
        );
    }
}

export const stopQueryItem = (
    queryItem: SQueryItemDTO | undefined,
    setIsLoading: any,
    getActiveTasks: any) => {

    if (queryItem != undefined) {
        sendRequestToBackend(
            queryItem,
            'admin/queryitemstop',
            (response: any) => {
                if (response != null && response === true) {
                    message.success("Запрос на остановку задания прошел успешно");
                    //this.setState({ isQueryItemsChanged: true });
                    getActiveTasks();
                } else {
                    message.warning("Запрос на остановку задания закончился неудачей");
                }
            },
            setIsLoading,
            (error: any) => {
                message.warning("Ошибка при запросе на оставновку задания");
                console.log(error);
                message.error(error);
            }
        );
    }
}

export const getQueryTitle = (queryItem: SQueryItemDTO | undefined, queryItemTypes: QueryItemTypes | undefined) => {
    let result = '';
    if (queryItem && queryItemTypes) {
        const type = queryItemTypes.queryTypes.find(y => y.id === queryItem.queryTypes_Id);
        const shedule = queryItemTypes.scheduleTypes.find(y => y.id === queryItem.shedules_Id);
        if (type) result += (type.description);
        if (shedule) result += (". " + shedule.name);
    }
    return result;
}

class QueryItemsForm extends React.Component<Props, State> {
    constructor(props: Props, state: State) {
        super(props);
        this.state={
            queryItems: [],
            queryItemEdit: null,
            modalZIndex: 0
        }
    }

    componentDidMount() {
        
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps.point === null && this.props.point !== null) {
            this.setState({queryItems: undefined});
            sendRequestToBackend(
                this.props.point.id,
                'admin/queryitems',
                (response: any) => {
                    if (response != null) {
                        this.setState({queryItems: response});
                        this.makeDataSource(response);
                    } else {
                        message.warning("Не удалось получить список заданий на опрос");
                    }
                },
                this.props.setIsLoading,
                (error: any) => {
                    message.warning("Ошибка при получении заданий на опрос");
                    console.log(error);
                    message.error(error);
                }
            );
        }

        if  (prevState != undefined && prevState.queryItems !== this.state.queryItems && this.state.queryItems != undefined) {
            this.makeDataSource(this.state.queryItems);
        }
    }

    //onStart = (id: string) => {
    //    let tmp = this.state.queryItems?.find(x => x.id === id);

    //    if (tmp != undefined) {
    //        sendRequestToBackend(
    //            tmp,
    //            'admin/queryitemstart',
    //            (response: number[]) => {
    //                if (response != null && response.length > 0) {
    //                    message.success("Запрос на запуск задания прошел успешно");
    //                    if (this.props.onQueryItemsStarted) {
    //                        this.props.onQueryItemsStarted(id, response);
    //                    }
    //                } else {
    //                    message.warning("Запрос на запуск задания закончился неудачей");
    //                }
    //            },
    //            this.props.setIsLoading,
    //            (error: any) => {
    //                message.warning("Ошибка при запросе на старт задания");
    //                console.log(error);
    //                message.error(error);
    //            }
    //        );
    //        if (this.props.onChange) this.props.onChange();
    //    }
    //}

    onStop = (id: string) => {
        let tmp = this.state.queryItems?.find(x => x.id === id);

        if (tmp != undefined) {
            sendRequestToBackend(
                tmp,
                'admin/queryitemstop',
                (response: any) => {
                    if (response != null && response === true) {
                        message.success("Запрос на остановку задания прошел успешно");
                    } else {
                        message.warning("Запрос на остановку задания закончился неудачей");
                    }
                },
                this.props.setIsLoading,
                (error: any) => {
                    message.warning("Ошибка при запросе на оставновку задания");
                    console.log(error);
                    message.error(error);
                }
            );
            if (this.props.onChange) this.props.onChange();
        }
    }

    onDelete = (id: string) => {
        Modal.confirm({
            title: 'Удаление задания',
            wrapClassName: Const.MODAL_WRAP_CLASSNAME,
            zIndex: getModalZIndex(),
            content: <div>Вы уверены что хотите удалить это задание?</div>,
            width: 400,
            okText: 'Удалить',
            cancelText: 'Отмена',
            onOk: () => {
                let tmp = this.state.queryItems?.find(x => x.id === id);

                if (tmp != undefined) {
                    sendRequestToBackend(
                        tmp,
                        'admin/queryitemdelete',
                        (response: any) => {
                            if (response != null && response === true) {
                                this.setState({queryItems: this.state.queryItems?.filter(x => x.id !== id)});
                            } else {
                                message.warning("Запрос на удаление задания закончился неудачей");
                            }
                        },
                        this.props.setIsLoading,
                        (error: any) => {
                            message.warning("Ошибка при удалении задания");
                            console.log(error);
                            message.error(error);
                        }
                    );
                    if (this.props.onChange) this.props.onChange();
                }
            },
            onCancel: () => {
            }
        });
    }

    onChangeEnabled = (id: string) => {
        let item = this.state.queryItems?.find(x => x.id === id);
        if (item != undefined) {
            item = {...item};
            item.enabled = !item.enabled;

            this.writeQueryItem(item);
        }
    }

    private writeQueryItem(item: SQueryItemDTO) {
        sendRequestToBackend(
            item,
            'admin/queryitemwrite',
            (response: any) => {
                if (response != null) {
                    if (this.state.queryItems != undefined) {
                        let i = this.state.queryItems?.findIndex(x => x.id === item.id);
                        let res = [...this.state.queryItems];
                        if (i !== -1) { 
                            res[i] = item;
                            this.setState({queryItems: res});
                        } else {
                            item.id = response;
                            res.push(item);
                            this.setState({queryItems: res, queryItemEdit: null});
                        }
                    }
                } else {
                    message.warning("Не удалось изменить задание");
                }
            },
            this.props.setIsLoading,
            (error: any) => {
                message.warning("Ошибка при изменении задания");
                console.log(error);
                message.error(error);
            }
        );
        if (this.props.onChange) this.props.onChange();
    }

    makeDataSource = (items: SQueryItemDTO[]) => {
        this.itemsDataSource = items.map(x => ({
            id: x.id,
            enabled: x.enabled,
            schedule: this.props.queryItemTypes?.scheduleTypes.find(y => y.id === x.shedules_Id),
            name: x.name,
            priority: x.priority,
            type: this.props.queryItemTypes?.queryTypes.find(y => y.id === x.queryTypes_Id),
        }))
    }

    private onEdit = (id: string) => {
        let tmp = this.state.queryItems?.find(x => x.id === id);
        console.log('onEdit', id, tmp);
        if (tmp != null) {
            this.setState({queryItemEdit: {...tmp}, modalZIndex: getModalZIndex()});
        }
    }

    private itemsColumns = [
        {
            dataIndex: "enabled",
            title: "",
            render: (val: any, record: any, index: any) => (
                <Tooltip placement="top" title={'Задание разрешено'}>
                    <Switch checked={val} onChange={() => this.onChangeEnabled(record.id)}></Switch>
                </Tooltip>
            )
        },
        {
            dataIndex: "edit",
            title: "",
            render: (text: any, record: any, index: any) => (
                <Tooltip placement="top" title={'Редактировать'}>
                    <Button shape="circle" onClick={() => {this.onEdit(record.id)}} icon={<EditTwoTone twoToneColor={Const.COLORS.SvgIconColor}/>} />
                </Tooltip>
            )
        },
        {
            dataIndex: "delete",
            title: "",
            render: (text: any, record: any, index: any) => (
                <Tooltip placement="top" title={'Удалить'}>
                    <Button shape="circle" onClick={() => this.onDelete(record.id)} icon={<DeleteTwoTone twoToneColor={Const.COLORS.SvgIconColor}/>} />
                </Tooltip>
            )
        },
        {
            dataIndex: "start",
            title: "",
            //    render: (text: any, record: any, index: any) => {
            //    const isActive: boolean = this.props.activeTasks.find(t => t.queryId === record.id) !== undefined;
            //    if (isActive) {
            //        return <Tooltip placement="top" title={'Запрос остановики задания'}>
            //            <Button shape="circle" onClick={() => this.onStop(record.id)} icon={<StopTwoTone twoToneColor={Const.COLORS.SvgIconColor} />} />
            //        </Tooltip>;
            //    }
            //    else {
            //        return <Tooltip placement="top" title={'Запрос запуска задания'} >
            //            <Button shape="circle"
            //                onClick = {
            //                    (record.schedule && (record.schedule.shdType_Id === AdminStore.IS_REQUEST_SCHEDULE_ID)) ?
            //                        () => requestQueryPointData(this.props.point?.id, record.id, record.type, this.props.readData, this.props.setActiveLogTask) :
            //                    () => startQueryItem(this.state.queryItems?.find(x => x.id === record.id), this.props.setIsLoading, this.props.setActiveLogTask, this.props.getActiveTasks)
            //            } icon={<PlayCircleTwoTone twoToneColor={Const.COLORS.SvgIconColor} />} />
            //        </Tooltip >;
            //    }
            //}
            render: (text: any, record: any, index: any) => {
                const isActive: boolean = this.props.activeTasks.find(t => t.queryId === record.id) !== undefined;
                const isEnabled = record.schedule && (record.schedule.shdType_Id === AdminStore.IS_DAY_SCHEDULE_ID || record.schedule.shdType_Id === AdminStore.IS_REQUEST_SCHEDULE_ID) &&
                    record.type && (record.type.id !== AdminStore.QueryTypesEnum.configurator &&
                        record.type.id !== AdminStore.QueryTypesEnum.connection_test &&
                        record.type.id !== AdminStore.QueryTypesEnum.calculate_archive_from_current);
                if (isActive) {
                    return <Tooltip placement="top" title={'Запрос остановки задания'}>
                        <Button disabled={!isEnabled} shape="circle"
                            onClick={() => stopQueryItem(this.state.queryItems?.find(x => x.id === record.id), this.props.setIsLoading, this.props.getActiveTasks)}
                            icon={<StopTwoTone twoToneColor={Const.COLORS.SvgIconColor} disabled />} />
                    </Tooltip>;
                }
                else {
                    return <Tooltip placement="top" title={'Запрос запуска задания'} >
                        <Button disabled={!isEnabled} shape="circle"
                            onClick={
                                (record.schedule && (record.schedule.shdType_Id === AdminStore.IS_REQUEST_SCHEDULE_ID)) ?
                                    () => requestQueryPointData(this.props.point?.id, record.id, record.type, this.props.readData, this.props.setActiveLogTask) :
                                    () => startQueryItem(this.state.queryItems?.find(x => x.id === record.id), this.props.setIsLoading, this.props.setActiveLogTask, this.props.getActiveTasks)
                            }
                            icon={<PlayCircleTwoTone twoToneColor={Const.COLORS.SvgIconColor} />} />
                    </Tooltip >;
                }
            }
        },
        {
            dataIndex: "schedule",
            title: "Расписание",
            render: (text: any, record: any, index: any) => {
                return record.schedule?.name;
            }
        },
        {
            dataIndex: "name",
            title: "Название",
        },
        {
            dataIndex: "priority",
            title: "Приоритет",
        },
        {
            dataIndex: "type",
            title: "Тип задания",
            render: (text: any, record: any, index: any) => {
                return record.type?.description;
            }
        },
    ];

    private itemsDataSource: any = undefined;

    private initNewQueryItem = () => {
        if (this.props.queryItemTypes != undefined && this.props.point != null) {
            let res: SQueryItemDTO = {
                enabled: false,
                id: null,
                name: '',
                pointId: this.props.point.id,
                priority: 1,
                queryTypes_Id: this.props.queryItemTypes.queryTypes[0].id,
                retriesMax: 0,
                shedules_Id: this.props.queryItemTypes?.scheduleTypes[0].id,
            }

            this.setState({queryItemEdit: res, modalZIndex: getModalZIndex()});
        }
    }

    onQueryItemEditOk = () => {
        if (this.state.queryItemEdit != undefined) {
            this.writeQueryItem(this.state.queryItemEdit);
        }
        this.setState({queryItemEdit: null});
    }

    //=============================================================================
    render() {
        return (
            <Modal
                maskClosable={false}
                //bodyStyle={{height: 550, overflow: 'auto'}}
                title={this.props.point?.number}
                wrapClassName={MODAL_WRAP_CLASSNAME}
                zIndex={this.props.zIndex}
                open={this.props.point !== null}
                footer={[
                    <div key={1} style={{ display: 'flex', alignItems: 'baseline' }}>
                        <Button key="1" type='primary' onClick={this.initNewQueryItem} style={{marginLeft: 'Auto'}}>Добавить</Button>
                        <Button key="2" type='primary' onClick={this.props.onHide}>Закрыть</Button>
                    </div>
                ]}
                onCancel={() => { this.props.onHide() }}
                width={800}
                style={{ top: 20 }}
            >
                {this.props.queryItemTypes === undefined || this.state == undefined ? <LoadingOutlined/> :
                    <Table
                        loading={this.state.queryItems == undefined}
                        bordered={true}
                        size={'small'}
                        rowKey="id"
                        columns={this.itemsColumns}
                        dataSource={this.itemsDataSource}
                        pagination={false}
                    />
                }
                {
                    <QueryItemEditForm 
                        onHide={() => this.setState({queryItemEdit: null})}
                        onOk={this.onQueryItemEditOk}
                        queryItem={this.state?.queryItemEdit}
                        queryItemTypes={this.props.queryItemTypes}
                        changeQueryItem={(val: SQueryItemDTO) => this.setState({queryItemEdit: val})}
                        zIndex={this.state.modalZIndex}
                    />
                }
            </Modal>
        );
    }
}

export default QueryItemsForm;