import * as React from 'react';
import { Input, Col, Row, Space, TreeSelect, Tooltip, Modal } from 'antd';
import { DraggableModal, DraggableModalProvider } from 'ant-design-draggable-modal'
import { SettingTwoTone, QuestionCircleTwoTone} from '@ant-design/icons';

import 'ant-design-draggable-modal/dist/index.css'

import Groups from '../Groups';
import { createRolesTree } from './RoleSelectForm';
import {GroupTags} from './GroupTags';

import * as Common from '../../utils/Common';
import { message } from '../../utils/Common';
import { MODAL_WRAP_CLASSNAME } from '../../utils/Const';

import { Group, Point, Resource, SRoleInfoDTO } from "../../decl";
import { removeValue, findGroupByName } from "../../utils/TreeUtils";
import Resources from '../Resources';
import * as Const from '../../utils/Const';
import pageStyles from '../../resources/Page.module.less';
import adminStyles from '../../resources/AdminPage.module.less';
import settingsStyles from '../../resources/Settings.module.less';

const ALL_ROLES_ID = "allroles";

export interface UserValues {
  name: string;
  groups: Group[] | undefined,
  selectedRoleIds: string[],
  contacts: string[]
}

export interface UserFormProps {
  open: boolean;
  zIndex: number,
  isNewUser: boolean;
  values?: UserValues  

  allGroups: Group[],
  groupPoints: {[groupId: string] : Point[]},
  allRoles: SRoleInfoDTO[],
  allResources: Resource[],
  requestGroupPoints: any,
  setIsLoading: any

  onSave: (values: UserValues, isNew: boolean) => void;
  onCancel: () => void;
}

interface IUserFormState{
  values: UserValues,
  groupsIsOpened: boolean
}

//Дерево точек учёта.
export class UserForm extends React.PureComponent<UserFormProps, IUserFormState> {
    constructor(props: UserFormProps) {
        super(props);

        this.state = {
          values: {name:'', groups: [], selectedRoleIds: [], contacts: []},
          groupsIsOpened: false
        }
    }
    componentDidMount() {
    }
    
    componentDidUpdate(prevProps: UserFormProps) {
        if(this.props.open && !prevProps.open){
          this.setState( {
            values: this.props.values ?
             {...this.props.values} :
              {name:'', groups: [], selectedRoleIds: [], contacts: []},
          });
        }
        if(this.props.open && this.props.values && this.props.values.groups !== undefined && prevProps.values?.groups === undefined){
          this.setState( {values: {...this.props.values}});
        }
    }

    componentWillUnmount() {
    }


    onOk = () => {
      const fSave = ()=> {
        const realContacts: string[] = [];
        this.state.values.contacts.forEach(c=>{
          const contact = c.trim();
          if(contact.length > 0){
            realContacts.push(contact)
          }
        })
        const values = {...this.state.values, contacts: realContacts};
        this.props.onSave(values, this.props.isNewUser);
      }

      if(this.state.values){
        const validateMessage = this.valuesValidate();
        if(validateMessage.length > 0){
          message.warning(validateMessage);
        }
        else if(this.props.isNewUser  && this.state.values.groups && this.state.values.groups.length === 0) {
            Modal.confirm({
              title: 'Не выбрано ни одной группы',
              wrapClassName: Const.MODAL_WRAP_CLASSNAME,
              zIndex: this.props.zIndex,
                  content: <div>Вы не выбрали ни одной группы для пользователя. Вы сможете добавить группы позднее, либо сейчас.</div>,
              width: 550,
              okText: 'Продолжить',
              cancelText: 'Добавить группы',
              onOk: () => {
                fSave();
              },
              onCancel: () => {
                this.setState({groupsIsOpened: true});
              }
          });
        }
        else{
          fSave();
        }
      }
      else{
        this.props.onCancel();
      }
    };

    valuesValidate = () => {
      let message = '';
      if(this.state.values.name.length === 0) message += 'Имя пользователя не задано.\n';
      if(this.props.isNewUser && this.state.values.selectedRoleIds.length === 0) message += 'Не выбрано ни одной роли.\n';
      if(this.props.isNewUser && this.state.values.contacts.length > 0){
        this.state.values.contacts.forEach(contact => {
          if(contact.trim().length > 0 && !Common.emailValidate(contact.trim())){
            message += "Контакт '" + contact +  "' не правильный email .\n";
          }
        });
      }
      if(message !== '' && this.props.isNewUser  && this.state.values.groups && this.state.values.groups.length === 0) message += 'Не выбрано ни одной группы.\n';
      return message;
    }

    onCancel = () => {
        this.props.onCancel();
    };

    onGroupsChange = (groups: any) => {
      const values: UserValues = {...this.state.values, groups: groups};
      this.setState({values: values});
    };

  onRolesChange = (roleIds: any, points: any) => {
    const values: UserValues = {...this.state.values, selectedRoleIds: roleIds};
    this.setState({values: values});
  };

  removeGroup = (group: Group) => {
    const groups: Group[] = this.state.values.groups ? this.state.values.groups.filter(g => g.id !== group.id) : [];
    const values: UserValues = {...this.state.values, groups: groups};
    this.setState({values: values});
}

    changeGroups = (groups: Group[]) => {
      this.setState({values: {...this.state.values, groups: groups}});      
    }
 
    render() {
      const tree = createRolesTree(this.props.allRoles, [], '');

      const groupTagsProps = {
        groupsTree: this.props.allGroups,
        groupPoints: this.props.groupPoints,

        groups: this.state.values.groups,

        removeGroup: this.removeGroup,
        changeGroups: this.changeGroups,
        getPoints: this.props.requestGroupPoints,
        setIsLoading: this.props.setIsLoading,
        isOpened: this.state.groupsIsOpened,
        onClose: ()=>{
          this.setState({groupsIsOpened: false})
        }
      }

      const rolesProps = {
        //size: this.props.size,
        treeData: tree.treeData,
        value:this.state.values.selectedRoleIds,
        onChange: this.onRolesChange,
        treeCheckable: true,
        treeLine: {showLeafIcon: false},
        treeIcon: true,
        showSearch: true,
        placeholder: 'Выберите роли',
        maxTagCount: 2,
        maxTagPlaceholder: (prop: any)=>{
            return <div>...</div>;
        },
    };

        return <DraggableModal
            title= {<span><SettingTwoTone twoToneColor={Const.COLORS.SvgIconColor} /><span style={{marginLeft:10}}>{this.props.isNewUser ? "Новый пользователь" : "Пользователь"}</span></span>}
            open={this.props.open}
            onOk={this.onOk}
            onCancel={this.onCancel}
            initialWidth={500}
            initialHeight={500}
            zIndex={this.props.zIndex }
            wrapClassName={MODAL_WRAP_CLASSNAME}>
            
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Row className={pageStyles.FormRowWrapper2}>
                <Col span = {8}>
                  Имя
                </Col>
                <Col span = {16}>
                  <Input 
                        placeholder= {"Имя пользователя"} 
                        value={this.state.values.name}  
                        onChange = {(e)=>{
                          const name = e.target.value;
                          const group = this.props.isNewUser ? findGroupByName(this.props.allGroups, name) : undefined;

                          const values: UserValues = {
                            ...this.state.values,
                             name: name,
                             groups: (group !== undefined ? [group] : this.state.values.groups)
                          };

                          this.setState({values: values});
                        }} 
                    />
                </Col>
            </Row>
            <Row className={pageStyles.FormRowWrapper2}>
                <Col span = {8}>
                  Группы
                </Col>
                <Col span = {16}>
                  <GroupTags {...groupTagsProps}></GroupTags>
                </Col>
            </Row>
            <Row className={pageStyles.FormRowWrapper2}>
                <Col span = {8}>
                  Роли
                </Col>
                <Col span = {16}>
                  <TreeSelect className={adminStyles.treeSelect} {...rolesProps} />
                </Col>
            </Row>
            <Row className={pageStyles.FormRowWrapper2}>
                <Col span = {8}>
                  Контакты
                  <Tooltip placement="top" title={'Контакт(ы) - eMail через запятую, если нужно давать оповещения нескольким контактам одновременно.'}>
                      <QuestionCircleTwoTone  className={settingsStyles.Question}/>
                  </Tooltip>
                </Col>
                <Col span = {16}>
                  <Input 
                        disabled = {!this.props.isNewUser}
                        placeholder= {"Контакты"} 
                        value={this.state.values.contacts.join(',')}  
                        onChange = {(e)=>{
                          const contacts = e.target.value.length > 0 ? e.target.value.split(',') : [];
                          const values: UserValues = {...this.state.values, contacts: contacts};
                          this.setState({values: values});
                        }} 
                    />
                </Col>
            </Row>
            </Space>
         </DraggableModal> 
    }
}
