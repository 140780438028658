import * as React from 'react';

import styles from '../../resources/AdminPage.module.less';
import { Button, Modal, Select, Input, Row, Col, Table, Tag, DatePicker, Tabs, Space, InputNumber, Checkbox, Switch, Divider, Tooltip } from 'antd';
import moment from 'moment';

import { arrayToString, getModalZIndex, message } from '../../utils/Common';
import { MODAL_WRAP_CLASSNAME } from '../../utils/Const';
import redIcon from "../../images/indicator_red.png";
import greyIcon from "../../images/indicator_grey.png";
import greenIcon from "../../images/indicator_green.png";
import brownIcon from "../../images/indicator_brown.png";
import yellowIcon from "../../images/indicator_yellow.png";
import * as AdminStore from '../../store/AdminStore';
import { Group, Point, Resource, SParameterValTypes } from '../../decl';
import { DeleteTwoTone, EditTwoTone, ExclamationCircleOutlined, LoadingOutlined, PlayCircleTwoTone, StopTwoTone, SyncOutlined } from '@ant-design/icons';
import modal from 'antd/lib/modal';
import { sendRequestToBackend } from '../../utils/AuthUtils';
import BaseMap from '../Map/BaseMap';
import SimpleMap from '../Map/SimpleMap';
import ShowTextForm from '../Helper/ShowTextForm';
import ProtokolTaskStatisticsForm from './ProtokolTaskStatisticsForm';
import ProtokolDetailedArchive from './ProtokolDetailedArchive';
import OnlineLogForm from './OnlineLogForm';

type Props = {
    onHide(): void,
    setIsLoading: any,
    point: Point | null,
    taskStartTime: Date | undefined,
    dicts: AdminStore.IPollingDicts | undefined,
    commStatus: string,
    devTypeId: number,
    windowWidth: number,
    zIndex: number,
    setActiveLogTask: (queryItemId: string | undefined, taskId: number) => void
}

type State = {
    tasks: AdminStore.STaskDTO[] | undefined,
    selectedTaskId: number | undefined,
    taskAttempts: AdminStore.STaskAttemptDTO[] | undefined,
    showText: string | null,
    modalZIndex: number,
    title: string,
    isConvertHexaDecimal: boolean,
    protokol4: {table: string[][], stats: string[]} | null,
    protokol5: string[][] | null,
}

export enum TaskStatusesEnum
{
    None = 1,
    Processing = 4,
    Completed = 5,
    Error = 7,
    Canceled = 8,
    NotDefined = 9
}

class TasksForm extends React.Component<Props, State> {
    constructor(props: Props, state: State) {
        super(props);
        this.state = {
            isConvertHexaDecimal: false,
            protokol4: null,
            protokol5: null,
            selectedTaskId: undefined,
            showText: null,
            modalZIndex: 0,
            taskAttempts: undefined,
            tasks: undefined,
            title: '',
        }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps.point == null && this.props.point != null && this.props.taskStartTime) {
            this.setAttemptsDataSource(undefined);
            this.setState({selectedTaskId: undefined, taskAttempts: undefined});

            const t = moment(this.props.taskStartTime).set({hour: 23, minute: 59, second: 59, millisecond: 999}).toDate();
            sendRequestToBackend(
                {pointId: this.props.point.id, start: this.props.taskStartTime, end: t},
                'admin/tasks',
                (response: any) => {
                    if (response != null) {
                        this.setTasksDatasource(response);
                        this.setState({tasks: response});
                        if (response.length > 0) {
                            this.setState({selectedTaskId: response[0].id});
                            this.getAttempts(response[0].id);
                        }
                    } else {
                        message.warning("Не удалось получить задания");
                    }
                },
                this.props.setIsLoading,
                (error: any) => {
                    message.warning("Ошибка при получении заданий");
                    console.log(error);
                    message.error(error);
                }
            );
        }

        if (prevProps.windowWidth !== this.props.windowWidth) {
            let arr: string[] = [];
            if (this.props.windowWidth < (1920 - 90)) {
                arr.push('node');
            } 
            if (this.props.windowWidth < (1920 - 90 * 2)) {
                arr.push('thread');
            } 
            if (this.props.windowWidth < (1920 - 90 * 3)) {
                arr.push('dev');
            } 
            if (this.props.windowWidth < (1920 - 90 * 4)) {
                arr.push('event');
            } 
            if (this.props.windowWidth < (1920 - 90 * 5)) {
                arr.push('data');
            } 
            if (this.props.windowWidth < (1920 - 90 * 6)) {
                arr.push('mesg');
            }
            this.attemptionsColumns = this.attemptionsColumnsBase.filter((x: any) => !arr.includes(x.dataIndex));
            this.forceUpdate();
        }
    }

    makeComm = (taskStatusId: number, clarifyStatusDay: string) => {
        let comm: string = '';

        if (taskStatusId == TaskStatusesEnum.Completed) { 
            comm = "Есть"; 
        }

        if ((taskStatusId == TaskStatusesEnum.None) || (taskStatusId == TaskStatusesEnum.Error)) {
            if (clarifyStatusDay == "-")
            {
                comm = "Нет";
            }
            else
            {
                comm = "Есть";
            }
        }
        else if (taskStatusId == TaskStatusesEnum.NotDefined)
        {
            comm = "Есть";
        }

        return comm;
    }

    setTasksDatasource = (tasks: AdminStore.STaskDTO[] | undefined) => {
        if (tasks != undefined) {
            this.tasksDataSource = tasks.map(x => ({
                id: x.id,
                type: this.props.dicts?.queryTypes[x.queryTypeId],
                status: this.props.dicts?.taskStatuses[x.taskStatusId],
                comm: this.makeComm(x.taskStatusId, this.props.commStatus),
                start: x.startTime,
                end: x.stopTime,
                last: x.lastRetryTime,
                count: x.retriesCount,
            }))
        } else {
            this.tasksDataSource = undefined;
        }
    }

    private onHide = () => {
        //this.setState({queryItemCurrent: undefined})
        this.props.onHide();
    }

    private tasksColumns = [
        {
            dataIndex: "type",
            title: "Тип задания",
        },
        {
            dataIndex: "status",
            title: "Статус",
        },
        {
            dataIndex: "comm",
            title: "Наличие связи",
        },
        {
            dataIndex: "start",
            title: "Начало расписания",
            render: (val: any) => val == null ? '-' : moment(val).format('DD.MM.YYYY HH:mm:ss'),
        },
        {
            dataIndex: "end",
            title: "Завершение расписания",
            render: (val: any) => val == null ? '-' : moment(val).format('DD.MM.YYYY HH:mm:ss'),
        },
        {
            dataIndex: "last",
            title: "Время последней попытки",
            render: (val: any) => val != null ? moment(val).format('DD.MM.YYYY HH:mm:ss') : '',
        },
        {
            dataIndex: "count",
            title: "Попыток",
        },
    ];

    selectIcon = (val: any): string => {
        let res: string = greyIcon;
        if (val === 0) res = greenIcon
        else if (val === 1) res = greyIcon
        else if (val === 2) res = redIcon

        return res;
    }

    private tasksDataSource: any = undefined;

    private attemptionsDataSource: any = undefined;

    private attemptionsColumnsBase: any = [
        {
            dataIndex: "num",
            title: "N",
        },
        {
            dataIndex: "connstart",
            title: "Начало соединения",
            render: (val: any) => val == null ? '-' : moment(val).format('DD.MM.YYYY HH:mm:ss'),
        },
        {
            dataIndex: "connend",
            title: "Конец соединения",
            render: (val: any) => val == null ? '-' : moment(val).format('DD.MM.YYYY HH:mm:ss'),
        },
        {
            dataIndex: "connres",
            title: <>Результат<br />связи</>,
            render: (val: any) => <Tooltip title={this.props.dicts?.connectionResult?.[val]}> <img src={this.selectIcon(val)} /> </Tooltip>,
            align: 'center',
        },
        {
            dataIndex: "pollstart",
            title: "Начало опроса",
            render: (val: any) => val == null ? '-' : moment(val).format('DD.MM.YYYY HH:mm:ss'),
        },
        {
            dataIndex: "pollend",
            title: "Конец опроса",
            render: (val: any) => val == null ? '-' : moment(val).format('DD.MM.YYYY HH:mm:ss'),
        },
        {
            dataIndex: "pollres",
            title: <>Результат<br />опроса</>,
            render: (val: any) => this.props.dicts?.pollingResult?.[val],
        },
        {
            dataIndex: "button",
            title: "",
            render: (val: any, record: any) => <Button type='primary' onClick={() => this.showLog(val, record.num)}>Лог</Button>,
            align: 'center',
        },
        {
            dataIndex: "time",
            title: "Время в журнале",
            render: (val: any) => val == null ? '-' : moment(val).format('DD.MM.YYYY HH:mm:ss'),
        },
        {
            dataIndex: "node",
            title: "Узел",
        },
        {
            dataIndex: "thread",
            title: "Поток",
        },
        {
            dataIndex: "dev",
            title: "Устройство",
        },
        {
            dataIndex: "event",
            title: "Событие",
        },
        {
            dataIndex: "source",
            title: "Источник",
        },
        {
            dataIndex: "data",
            title: "Данные",
        },
        {
            dataIndex: "mesg",
            title: "Сообщение",
        },
    ]

    private attemptionsColumns = this.attemptionsColumnsBase;

    setAttemptsDataSource = (attempts: AdminStore.STaskAttemptDTO[] | undefined) => {
        if (attempts != undefined) {
            this.attemptionsDataSource = attempts.map(x => ({
                key: String(x.taskId) + String(x.attemptNumber),
                taskId: x.taskId,
                num: x.attemptNumber,
                connstart: x.connectionBeginTime,
                connend: x.connectionEndTime,
                connres: x.connectionResultId,
                pollstart: x.pollingBeginTime,
                pollend: x.pollingEndTime,
                pollres: x.pollingResultId,
                button: x.taskId,
                time: x.logTime,
                node: x.systemNode,
                thread: x.systemNode,
                dev: x.device,
                event: x.eventString,
                source: x.source,
                data: x.data,
                mesg: x.message,
            }));
        } else {
            this.attemptionsDataSource = undefined;
        }
    }

    getAttempts(taskId: number) {
        sendRequestToBackend(
            taskId,
            'admin/taskattempts',
            (response: any) => {
                if (response != null) {
                    this.setAttemptsDataSource(response);
                    this.setState({taskAttempts: response});
                } else {
                    message.warning("Не удалось получить детали задания");
                }
            },
            this.props.setIsLoading,
            (error: any) => {
                message.warning("Ошибка при получении деталей задания");
                console.log(error);
                message.error(error);
            }
        );
    }

    showLog = (taskId: number, attemptNumber: number) => {
        function selectDate(dates: Array<Date | null>, selectLower: boolean): Date | undefined {
            //Функция выбирает самую наименьшую или наибольшую дату из массива
            let res: Date | undefined = undefined;

            const arr: number[] = [...(dates.filter(x => x != null).map(y => y?.valueOf() ?? 0 ))];
            if (arr.length > 0) {
                if (selectLower === true) {
                    res = new Date(Math.min(...arr));
                } else {
                    res = new Date(Math.max(...arr));
                }
            } else {
                res = undefined;
            }

            return res;
        }

        const task = this.state.tasks?.find(x => x.id == taskId);

        const ta = this.state.taskAttempts?.find(x => x.taskId == taskId && x.attemptNumber === attemptNumber);

        if (task && task.taskStatusId === 4) {
            this.props.setActiveLogTask(undefined, taskId);
        }
        else if (ta != undefined) {
            let start: Date | undefined = selectDate([new Date(ta.connectionBeginTime), ta.pollingBeginTime != null ? new Date(ta.pollingBeginTime) : null, ta.connectionEndTime != null ? new Date(ta.connectionEndTime) : null, ta.pollingEndTime != null ? new Date(ta.pollingEndTime) : null, ta.logTime != null ? new Date(ta.logTime) : null], true);
            let end: Date | undefined = selectDate([new Date(ta.connectionBeginTime), ta.pollingBeginTime != null ? new Date(ta.pollingBeginTime) : null, ta.connectionEndTime != null ? new Date(ta.connectionEndTime) : null, ta.pollingEndTime != null ? new Date(ta.pollingEndTime) : null, ta.logTime != null ? new Date(ta.logTime) : null], false);
            
            if (start != undefined) {
                if (end?.valueOf() == start.valueOf()) {
                    end = moment(start).add(5, 'second').toDate();
                }
                sendRequestToBackend(
                    { taskId, start, end },
                    'admin/pollinglog',
                    (response: any) => {
                        if (response != null) {
                            this.setState({ showText: response, title: 'Протокол попытки', modalZIndex: getModalZIndex() })
                        } else {
                            message.warning("Не удалось получить лог опроса");
                        }
                    },
                    this.props.setIsLoading,
                    (error: any) => {
                        message.warning("Ошибка при получении лога опроса");
                        console.log(error);
                        message.error(error);
                    }
                );
            }
        }
        
    }

    makeProtokol1 = (arr: AdminStore.STaskAttemptInfoDTO[]): string => {
        const typicalNodes: string[] = ["DialStart", "DialFinish", "Error", "HangUpStart", "HangUpFinish", "PollingBlock", 'StartTask'];

        let res: string = '';
        let arr2: string[] = [];
        for (let item of arr) {
            if (typicalNodes.includes(item.eventString)) {
                arr2.push('[' + item.eventString + '] ' + item.data.replace('\r\n', ''));
            } else if (item.eventString == 'AttemptStart') {
                arr2.push('=====[' + item.eventString + ']====== ' + item.data.replace('\r\n', '') + '      ' + item.node + ',  ' + item.device);
            }
        }
        res = arr2.join('\r\n')
        return res;
    }

    protokol = (val: string) => {
        if (val === '1') {
            sendRequestToBackend(
                this.state.selectedTaskId,
                'admin/taskinfoload',
                (response: any) => {
                    if (response != null) {
                        const res: AdminStore.STaskAttemptInfoDTO[] = response;
                        this.setState({showText: this.makeProtokol1(res), title: 'Протокол задания (' + this.state.selectedTaskId + ')', modalZIndex:getModalZIndex()});
                    } else {
                        message.warning("Не удалось ");
                    }
                },
                this.props.setIsLoading,
                (error: any) => {
                    message.warning("Ошибка ");
                    console.log(error);
                    message.error(error);
                }
            );
        } else if (val == '3') {
            if (this.state.tasks != undefined) {
                sendRequestToBackend(
                    this.state.tasks,
                    'admin/protokolattemptsstatistic',
                    (response: any) => {
                        if (response != null) {
                            this.setState({showText: response, title: 'Статистика всех попыток', modalZIndex: getModalZIndex()});
                        } else {
                            message.warning("Не удалось ");
                        }
                    },
                    this.props.setIsLoading,
                    (error: any) => {
                        message.warning("Ошибка ");
                        console.log(error);
                        message.error(error);
                    }
                );
            }
        } else if (val == '4') {
            if (this.state.tasks != undefined) {
                sendRequestToBackend(
                    { taskId: this.state.selectedTaskId, devTypeId: this.props.point?.persistProperties['devicetypeid'], isConvertHexaDecimal: this.state.isConvertHexaDecimal },
                    'admin/protokoltaskstatistics',
                    (response: any) => {
                        if (response != null) {
                            this.setState({protokol4: response, modalZIndex: getModalZIndex()});
                        } else {
                            message.warning("Не удалось ");
                        }
                    },
                    this.props.setIsLoading,
                    (error: any) => {
                        message.warning("Ошибка ");
                        console.log(error);
                        message.error(error);
                    }
                );
            }
        } else if (val == '5') {
            if (this.state.tasks != undefined) {
                sendRequestToBackend(
                    { pointId: this.props.point?.id, date: this.props.taskStartTime },
                    'admin/protokoldetailedarchive',
                    (response: any) => {
                        if (response != null) {
                            this.setState({protokol5: response, modalZIndex: getModalZIndex()});
                        } else {
                            message.warning("Не удалось ");
                        }
                    },
                    this.props.setIsLoading,
                    (error: any) => {
                        message.warning("Ошибка ");
                        console.log(error);
                        message.error(error);
                    }
                );
            }
        }
    }

    attemptsTableRowStyle = (record: any) => {
        let res = '';
        if (record?.connres === 1) {
            res = styles.attemptsTableRedRow;
        }
        if (record?.pollres === 0) {
            res = styles.attemptsTableGreenRow;
        }
        if (record?.connres === 0 && record?.pollres === 3) {
            res = styles.attemptsTableRedRow;
        }
        return res;
    }

    //=============================================================================
    render() {
        return (
            <Modal
                maskClosable={false}
                bodyStyle={{paddingTop: '10px'}}
                title={this.props.point?.number + ', ' +  moment(this.props.taskStartTime).format('DD.MM.YYYY')}
                wrapClassName={MODAL_WRAP_CLASSNAME}
                zIndex={this.props.zIndex}
                open={this.props.point != null}
                footer={[
                    <div key={1} style={{ display: 'flex', alignItems: 'baseline' }}>
                        <Button key="1" type='primary' onClick={() => this.props.onHide()} style={{marginLeft: 'Auto'}}>Закрыть</Button>
                    </div>
                ]}
                onCancel={() => { this.props.onHide() }}
                width={'fit-content'}
                style={{ top: 20 }}
            >
                {
                    //this.state.queryItemTypes === undefined ? <LoadingOutlined/> :
                    <Space direction='vertical' size={20}>
                        <Space direction='horizontal'>
                            <Select
                                value='Протоколы'
                                style={{ width: 210 }}
                                dropdownMatchSelectWidth={false}
                                onChange={(val) => this.protokol(val)}
                                options={[
                                    { value: '1', label: 'Протокол задания' },
                                    { value: '3', label: 'Статистика попыток'},
                                    { value: '4', label: 'Статистика задания' },
                                    { value: '5', label: 'Анализ архива за день' },
                                ]}
                            />
                            <Checkbox checked={!this.state?.isConvertHexaDecimal} onChange={(val) => this.setState({isConvertHexaDecimal: !val.target.checked})}>Отображать протоколы опроса в шестнадцатиричном виде</Checkbox>
                        </Space>
                        <div style={{overflow: 'auto', maxHeight: '200px', maxWidth: '90vw'}}>
                            <Table
                                //loading={this.state.queryItems == undefined}
                                bordered={true}
                                size={'small'}
                                rowKey="id"
                                columns={this.tasksColumns}
                                dataSource={this.tasksDataSource}
                                pagination={false}
                                onRow={(record, rowIndex) => {
                                    return {
                                        onClick: event => { if (record.id !== this.state.selectedTaskId) { this.setState({selectedTaskId: record.id}); this.getAttempts(record.id); } }, // click row
                                    };
                                }}
                                rowSelection={{
                                    type: "radio",
                                    selectedRowKeys: this.state?.selectedTaskId != undefined ? [this.state.selectedTaskId] : undefined,
                                    onChange: (selectedRowKeys: any[], selectedRows: any[]) => {
                                        this.setState({selectedTaskId: selectedRowKeys[0]});
                                        this.getAttempts(selectedRowKeys[0]);
                                    }
                                }}
                            />
                        </div>
                        <div style={{maxWidth: '90vw'}}>
                            <Table
                                //loading={this.state.queryItems == undefined}
                                bordered={true}
                                size={'small'}
                                rowKey="key"
                                columns={this.attemptionsColumns}
                                dataSource={this.attemptionsDataSource}
                                pagination={false}
                                onRow={(record, rowIndex) => {
                                    return {
                                        //onDoubleClick: event => this.showLog(record.taskId), // click row
                                    };
                                }}
                                rowClassName={(record,index)=>(this.attemptsTableRowStyle(record))}
                                scroll={{y: '30vh'}}
                            />
                        </div>
                    </Space>
                }
                {
                    <ShowTextForm
                        onHide={() => this.setState({showText: null})}
                        text={this.state?.showText ?? null}
                        zIndex={this.state.modalZIndex}
                        title={this.state.title}
                        width={800}
                    />
                }
                {
                    <ProtokolTaskStatisticsForm
                        onHide={() => this.setState({protokol4: null})}
                        stats={this.state?.protokol4?.stats ?? null}
                        table={this.state?.protokol4?.table ?? null}
                        zIndex={this.state.modalZIndex}
                    />
                }
                {
                    <ProtokolDetailedArchive
                        onHide={() => this.setState({protokol5: null})}
                        table={this.state?.protokol5 ?? null}
                        time={this.props.taskStartTime}
                        zIndex={this.state.modalZIndex}
                    />
                }
            </Modal>
        );
    }
}

export default TasksForm;