import * as React from 'react';
import { Route } from 'react-router';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { Row, Col, Tooltip } from 'antd';
import { ApplicationState } from '../store';
import * as SiderMenuStore from '../store/SiderMenu';
import * as PageStore from '../store/Page';
import * as UserProfileStore from '../store/UserProfile';
import styles from '../resources/Page.module.less';
import WeatherInformer from "./WeatherInformer";
import {layoutLeftSide} from '../utils/Common';

interface Router {
    router: any;
}

type InfoBlockProps =
    { page: PageStore.PageState, siderMenu: SiderMenuStore.SiderMenuState, userProfile: UserProfileStore.UserProfileState } &
    Router;

type SimpleInfoBlockProps = {
    allCount: number,
    collapsed: boolean;
    selectedCount: number,
};


function SimpleInfoBlock(prop:SimpleInfoBlockProps) {
    const info1 = (prop.collapsed ? "" : "Всего ТУ: ") + prop.allCount.toString();
    if(prop.allCount <= 1){
        return null;
    }
    else if(prop.selectedCount > 0){
        const info2 = (prop.collapsed ? "" : "Выбрано ТУ: ") + prop.selectedCount.toString();
        return(
            <div className ={styles.bottomSection}>
                <Tooltip placement="right" title={"Выбрано точек учёта"}>
                    <div className ={styles.subSection}>
                        {info2}
                    </div>
                </Tooltip>
                <Tooltip placement="right" title={"Всего точек учёта у пользователя"}>
                    <div className ={styles.subSection}>
                        {info1}
                    </div>
                </Tooltip>
            </div>
        );
    }
    else{
        return(
            <div className ={styles.bottomSection}>
                <Tooltip placement="right" title={"Всего точек учёта у пользователя"}>
                    <div className ={styles.subSection}>
                        {info1}
                    </div>
                </Tooltip>
            </div>
        );
    }
}


class InfoBlock extends React.PureComponent<InfoBlockProps> {
    render() {
        let collapsed: boolean = false;
        if (this.props.siderMenu !== undefined) {
            collapsed = this.props.siderMenu.collapsed;
        }
        const showWeather = this.props.userProfile.profile.Common?.ShowWeather;
        const allCount: number = Object.keys(this.props.userProfile.allPoints).length;
        const selectedCount: number = this.props.page.selectedPointsCount;
        let pathname: string = this.props.router.location.pathname;

        //Планирование высоты левой части.
        const leftSide = layoutLeftSide(this.props.page.windowHeight, showWeather, allCount, pathname); 

        if(showWeather || leftSide.showSelected || leftSide.showAll){
            const showSelectedCount = leftSide.showSelected ? selectedCount : 0;
            return( 
                <div className ={styles.infoBlock}>
                    {
                        showWeather && 
                        <div className ={styles.topSection}>
                            <WeatherInformer latitude="55.776279" longitude="37.474776" shortInfo={collapsed}/>
                        </div>
                    }
                    {leftSide.showAll &&
                        <SimpleInfoBlock allCount={allCount} collapsed={collapsed} selectedCount={showSelectedCount} />
                    }
                </div>);
        }
        else{
            return (<div/>);
        }
  }
};

export default connect(
    //(state: ApplicationState & Router) => state,
    (state: ApplicationState & Router) => {
        return {
            page: state.page,
            userProfile: state.userProfile,
            siderMenu: state.siderMenu,
            router: state.router
        }
    }
)(InfoBlock as any);


