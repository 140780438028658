import { message } from '../utils/Common';
import { defaultPrefixCls } from 'antd/lib/config-provider';
import { request } from 'http';
import { getgid } from 'process';
import { Action, Reducer } from 'redux';
import { ResponseStatusesEnum, SResponseDTO, SPointsFilter, PeriodsEnum, CommunicationMissedTimesEnum } from '../decl';
import { AppThunkAction } from './';
import { CloseSessionAction, SetSelectedPointsCount } from './Page';
import { getHeaders, BACKEND_URL } from '../utils/AuthUtils';

//Главный класс
export interface IMapDataState {
    isPointsLoading: boolean,
    isPointsValuesLoading: boolean,
    isPointsValuesLoadingError: boolean,
    lastValuesUpdateTime: Date | null,
    needToStartToUpdateAllDataFlag: boolean,
    points: {[pointId: string] : SMapPoint};
    pointsData: {[pointId: string] : SPointValues} | null;
    quantity: {[id: number]: SPhysicalQuantityDTO} | undefined;
    layers: {[code: string]: SMapLayer} | undefined;
    activeLayer: string | undefined;
    textFilter: string;
    communicationMissedTimeFilter: CommunicationMissedTimesEnum;
    searchDataFromTime: Date | null;

    isLayersLoading: boolean;
    isQuantityLoading: boolean;
};

export enum ClusteringFunctionTypeEnum {
    max = 'max', 
    min = 'min', 
    avg = 'avg'
}


/// <summary>
/// Тип измерения.
/// </summary>
export enum MeasurementTypesEnum
{
    /// <summary>
    /// Не определено.
    /// </summary>
    None = 0,

    /// <summary>
    /// Текущие значения.
    /// </summary>
    Current = 1,

    /// <summary>
    /// Архивное значения.
    /// </summary>
    Archive = 2,

    /// <summary>
    /// Текущие и архивные значения.
    /// </summary>
    CurrentAndArchive = 3
}

export interface SMapLayer {
    layerName: string;
    layerCode: string;
    isDataLayer: boolean;
    isIntegral: boolean;
    parameterUnit: string;
    measurementType: MeasurementTypesEnum;
    phisicalQuantityID: number;
    channelNumber: number;
    layerDescription: string;
    deviceTypeIds: string;
}

//Значения для точки учета
export interface SPointValues {
    pointId: string,
    lastPollingTime: number | null,
    newEventsCount: number,
    dynamicProperties: {[key: string]: any},
    values: {[key: string]: SPointValueDTO},
}

//Описание значения для ТУ
export interface SPointValueDTO {
    name: string,
    value: number,
    status: ValueStatusesEnum
}

export enum ValueStatusesEnum {
    None = 0,
    Good = 1,
    MoreMax = 2,
    LessMin = 3,
    Error = 4
}

//Описание точки учета
export interface SMapPoint {
    id: string;
    latitude: number;
    longitude: number;
    lastPollingTime: number | null;
    persistProperties: {[key: string]: string};
}

export interface SValuesRequest {
    filter: SPointsFilter;
    period: PeriodsEnum;
    dataDepth: Number;
    activeLayers: string;
    lastTime: string;//DateTime;
}

export interface SPhysicalQuantityDTO {
    id: number;
    name: string;
    sign: string;
    defaultUnit: string;
    resourceIds: string;
}

//============================================ Функции ============================================
//Получение ТУ
interface ReceivePoints {
    type: 'RECEIVE_MAP_POINTS';
    mapPoints: SMapPoint[];
}

interface SetTextFilter {
    type: 'SET_MAP_TEXT_FILTER',
    textFilter: string;
}

interface SetStartToUpdateAllDataFlag {
    type: 'SET_START_TO_UPDATE_ALL_DATA_FLAG',
    flag: boolean;
}

interface SetCommunicationMissedTimeFilter {
    type: 'SET_COMMUNICATION_MISSED_TIME_FILTER',
    filter: CommunicationMissedTimesEnum
}

interface ReceiveValues {
    type: 'RECEIVE_MAP_VALUES';
    pointsValues: SPointValues[];
}

interface ReceiveLayers {
    type: 'RECEIVE_LAYERS';
    layers: SMapLayer[];
}

interface ReceiveQuantity {
    type: 'RECEIVE_QUANTITY';
    quantity: SPhysicalQuantityDTO[];
}

interface SetSearchDataFromTime {
    type: 'SET_SEARCH_DATA_FROM_TIME';
    time: Date | null;
}

interface SetState {
    type: 'SET_MAP_STATE';
    value: Partial<IMapDataState>;
}
export interface ClearMapCacheAction {
    type: 'CLEAR_MAP_CACHE';
}

type KnownAction = ReceivePoints | ReceiveValues | ReceiveLayers
    | ReceiveQuantity | SetCommunicationMissedTimeFilter | SetTextFilter | SetSearchDataFromTime
    | SetState | SetStartToUpdateAllDataFlag | CloseSessionAction | ClearMapCacheAction | SetSelectedPointsCount;

export const actionCreators = {
    SetTextFilter: (textFilter: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            dispatch({type: 'SET_MAP_STATE', value: {textFilter: textFilter}})
        }
    },
    setStartToUpdateAllDataFlag: (flag: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            dispatch({type: 'SET_MAP_STATE', value: {needToStartToUpdateAllDataFlag: flag}})
        }
    },
    setCommunicationMissedTimeFilter: (filter:CommunicationMissedTimesEnum): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            dispatch({type: 'SET_MAP_STATE', value: {communicationMissedTimeFilter: filter}})
        }
    },

    SetSearchDataFromTime: (time: Date | null): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            dispatch({type: 'SET_SEARCH_DATA_FROM_TIME', time: time})
        }
    },

    requestMapPoints: (filter: SPointsFilter, clear?: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (clear === true) {
            dispatch({type: 'SET_MAP_STATE', value: {isPointsLoading: true}});
        } else {
            dispatch({type: 'SET_MAP_STATE', value: {isPointsLoading: true, points: undefined}});    
        }
        if (appState) {
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify(filter)
            };
            fetch(BACKEND_URL + 'map/points', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        dispatch({type: 'RECEIVE_MAP_POINTS', mapPoints: data.body})
                        dispatch({type: 'SET_SELECTED_POINTS_COUNT', count: data.body.length});
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                    }
                    else {
                        message.error('Ответ не получен.');
                    }
                    dispatch({type: 'SET_MAP_STATE', value: {isPointsLoading: false}});
                })
                .catch(error => {
                    message.error('Ошибка:' + error);
                    console.log(error);
                    dispatch({type: 'SET_MAP_STATE', value: {isPointsLoading: false}});
                });
            
        }
    },

    requestPointsValues: (args: {filter: SPointsFilter, period: PeriodsEnum, dataDepth: number, activeLayers: string, lastTime: Date | null}, clear?: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (clear === true) {
            dispatch({type: 'SET_MAP_STATE', value: {isPointsValuesLoading: true, pointsData: undefined}});
        } else {
            dispatch({type: 'SET_MAP_STATE', value: {isPointsValuesLoading: true}});
        }
        
        if (appState) {
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify({...args})
            };
            //console.log('map/values', JSON.stringify({...args}));
            fetch(BACKEND_URL + 'map/values', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'SET_MAP_STATE', value: { isPointsValuesLoadingError: true, needToStartToUpdateAllDataFlag: false }})
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus === ResponseStatusesEnum.Ok) {
                        //console.log('map/values2', data.body);
                        dispatch({type: 'RECEIVE_MAP_VALUES', pointsValues: data.body})
                        dispatch({type: 'SET_MAP_STATE', value: {isPointsValuesLoadingError: false, needToStartToUpdateAllDataFlag: false}})
                        dispatch({type: 'SET_SELECTED_POINTS_COUNT', count: data.body.length});
                    }
                    else if (data.bodyStatus && data.bodyStatus === ResponseStatusesEnum.Error) {
                        console.log('Ошибка2:', data);
                        dispatch({type: 'SET_MAP_STATE', value: {isPointsValuesLoadingError: true, needToStartToUpdateAllDataFlag: false}})
                    }
                    else {
                        message.error('Ответ не получен.');
                        dispatch({type: 'SET_MAP_STATE', value: {isPointsValuesLoadingError: true, needToStartToUpdateAllDataFlag: false}})
                    }
                    dispatch({type: 'SET_MAP_STATE', value: {isPointsValuesLoading: false}});
                })
                .catch(error => {
                    console.log('Ошибка:', error); 
                    dispatch({type: 'SET_MAP_STATE', value: {isPointsValuesLoadingError: true, isPointsLoading: false, needToStartToUpdateAllDataFlag: false}});
                });
        }
    },

    requestLayers: (req: SPointsFilter): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({ type: 'SET_MAP_STATE', value: {layers: undefined, isLayersLoading: true}});
        if (appState) {
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify(req)
            };
            fetch(BACKEND_URL + 'map/layers', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        dispatch({type: 'RECEIVE_LAYERS', layers: data.body})
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                    }
                    else {
                        message.error('Ответ не получен.');
                    }
                    dispatch({ type: 'SET_MAP_STATE', value: {isLayersLoading: false}});
                })
                .catch(error => {
                    message.error('Ошибка:' + error);
                    console.log(error);
                    dispatch({ type: 'SET_MAP_STATE', value: {isLayersLoading: false}});
                });
            
        }
    },

    requestQuantity: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({ type: 'SET_MAP_STATE', value: {isQuantityLoading: true, quantity: undefined}});
        if (appState) {
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
                //body: JSON.stringify({})
            };
            fetch(BACKEND_URL + 'map/quantity', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        dispatch({type: 'RECEIVE_QUANTITY', quantity: data.body});
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                    }
                    else {
                        message.error('Ответ не получен.');
                    }

                    dispatch({ type: 'SET_MAP_STATE', value: {isQuantityLoading: false}});
                })
                .catch(error => {
                    message.error('Ошибка:' + error);
                    console.log(error);
                    dispatch({ type: 'SET_MAP_STATE', value: {isQuantityLoading: false}});
                });
            
        }
    },

};

const unloadedState: IMapDataState = {
    isPointsLoading: false,
    lastValuesUpdateTime: null,
    layers: undefined,
    points: {},
    pointsData: null,
    quantity: undefined,
    activeLayer: undefined,
    textFilter: '',
    searchDataFromTime: null,
    communicationMissedTimeFilter: CommunicationMissedTimesEnum.All,
    isPointsValuesLoading: false,
    isPointsValuesLoadingError: false,
    needToStartToUpdateAllDataFlag: false,
    isLayersLoading: false,
    isQuantityLoading: false,
};

export const reducer: Reducer<IMapDataState> = (state: IMapDataState | undefined, incomingAction: Action): IMapDataState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'RECEIVE_MAP_POINTS':
            return {
                ...state,
                points: Object.assign({}, ...action.mapPoints.map( item => ({[item.id]: item}))),
            };
        case 'RECEIVE_MAP_VALUES':
            return {
                ...state,
                pointsData: Object.assign({}, ...action.pointsValues.map( item => ({[item.pointId]: item}))),
                lastValuesUpdateTime: new Date(),
            };
        case 'RECEIVE_LAYERS':
            return {
                ...state,
                layers: Object.assign({}, ...action.layers.map( item => ({[item.layerCode]: item})))
            };
        case 'RECEIVE_QUANTITY':
            return {
                ...state,
                quantity: Object.assign({}, ...action.quantity.map( item => ({[item.id]: item})))
            };
        case 'SET_MAP_TEXT_FILTER':
            return {
                ...state,
                textFilter: action.textFilter
            };
        case 'SET_COMMUNICATION_MISSED_TIME_FILTER':
            return {
                ...state,
                communicationMissedTimeFilter: action.filter
            };
        case 'SET_SEARCH_DATA_FROM_TIME':
            return {
                ...state,
                searchDataFromTime: action.time
            };
        case "SET_MAP_STATE":
            return {
                ...state,
                ...action.value
            };
        case "CLEAR_MAP_CACHE":
            return unloadedState;
        default:
            return state;
    }
};
