import * as React from 'react';
import {RouteComponentProps} from 'react-router';
import {connect} from 'react-redux';

import {ApplicationState} from '../store';
import * as PageStore from '../store/Page';
import * as UserProfileStore from '../store/UserProfile';
import {BaseProfile, PeriodsEnum} from '../decl';
import styles from '../resources/Page.module.less';
import {IChartsState} from "../store/Charts";
import ChartsContainer from "../components/Charts/ChartsContainer";
import * as ChartsStore from "../store/Charts";

const actionCreators = {
  ...PageStore.actionCreators,
  ...UserProfileStore.actionCreators,
  ...ChartsStore.actionCreators
};

type ChartsProps =
  {
	page: PageStore.PageState,
	userProfile: UserProfileStore.UserProfileState,
	profile: BaseProfile,
	charts: IChartsState
  } &
  typeof actionCreators &
  RouteComponentProps<{}>

interface IChartsPageState extends IChartsState {
}

class Charts extends React.PureComponent <ChartsProps, IChartsPageState> {

  constructor(props: ChartsProps) {
	super(props);

	// console.dir(this.props?.profile?.Common);

	// let pointIdInit = this.props?.profile?.Common?.CurrentPointId ?? (this.props.page?.pointId ?? '');
	let pointIdInit = this.props.page?.pointId ?? '';

	let period: PeriodsEnum = (this.props?.profile?.Common?.ArchivePeriod ?? PeriodsEnum.day) as PeriodsEnum;
	// console.log('Charts page period', this.props?.charts?.period, this.props?.profile?.Chart?.Period, period);


	this.state = {
	  needRefresh: this.props?.charts?.needRefresh ?? 0,
	  period: period,
	  pointId: pointIdInit,
	  dates:  this.props?.charts?.dates && null,
	  doExport: 0
	}

  }


  componentDidUpdate(prevProps: ChartsProps) {

	this.setState({
	  ...this.state,
	  pointId: this.props.page?.pointId ?? '',
	  period:  this.props?.profile?.Common?.ArchivePeriod ?? PeriodsEnum.day,
	  needRefresh: this.props?.charts?.needRefresh ?? 0,
	  doExport: this.props?.charts?.doExport ?? 0,
	});
  }


  public render() {
	// console.log(this.props.userProfile);

	// console.log(this.state.pointId);
	let pointName: string = '';
	if (this.props.userProfile && this.props.userProfile.allPoints && this.props.userProfile.allPoints[ this.state.pointId ]) {
	  // let _point = this.props.userProfile.allPoints[ this.state.pointId ];
	  // console.dir(_point);
	  pointName = this.props.userProfile.allPoints[this.state.pointId].number ?? '';
	}

	// console.log('charts page render',this.state.period);

	return (
	   <div className={styles.container}>

		<ChartsContainer
		  allPoints={this.props.userProfile.allPoints}
		  pointId={this.state.pointId}
		  period={this.state.period}
		  dates={this.state.dates}
		  needRefresh={this.state.needRefresh}
		  pointName={pointName}
				setIsLoading={this.props.setIsLoading}
				closeSession={this.props.closeSession}
		  setChartDates={this.props.setChartDates}
		  doExport={this.state.doExport}
		  // setChartCheckedParameters={this.props.setChartCheckedParameters}
		/>
	   </div>
	);
  }
}

// interface ChartsPageState extends ApplicationState {
//   period: number,
//   pointId: string
// }

export default connect(
  (state: ApplicationState) => {
	return {
	  page: state.page,
	  userProfile: state.userProfile,
	  profile: state.userProfile?.profile,
	  charts: state.charts
	}
  },
  actionCreators
)(Charts as any);
