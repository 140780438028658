import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';

import * as Const from '../utils/Const';
import * as Common from '../utils/Common';

import { ApplicationState } from '../store';
import * as PageStore from '../store/Page';
import * as UserProfileStore from '../store/UserProfile';
import * as MonthReportsStore from '../store/MonthReportsStore'
import { BaseProfile, SConnectionStatisticDTO, SPointsFilter } from '../decl';
import styles from '../resources/Page.module.less';
import { Button, Modal, Select, Space, Table, Tabs, Tooltip } from 'antd';
import Groups from '../components/Groups';
import Resources from '../components/Resources';
import { LoadingOutlined } from '@ant-design/icons';
import { BACKEND_URL, getHeaders, sendRequestToBackend } from '../utils/AuthUtils';
import { message, getModalZIndex } from '../utils/Common';
import moment from 'moment';
import { ConnectionStatistics } from '../components/Page/ConnectionStatistics';
import PointsTable from "../components/Tables/PointsTable";
const { TabPane } = Tabs;
const { Option } = Select;

const actionCreators = { ...PageStore.actionCreators, ...UserProfileStore.actionCreators, ...MonthReportsStore.actionCreators };

type MonthReportsProps =
    {
        page: PageStore.PageState,
        userProfile: UserProfileStore.UserProfileState,
        monthReportStore: MonthReportsStore.IMonthReportsState,
    } &
    typeof actionCreators &
    RouteComponentProps<{}>

interface IMonthReportsState {
    selectedListReportCode: string | undefined,
    reportLoading: boolean,
    connectionStatisticResponce: any,
    filter: SPointsFilter,
    needTableRefresh: boolean
}

class MonthReports extends React.PureComponent <MonthReportsProps, IMonthReportsState > {

    constructor(props: MonthReportsProps){
        super(props);
        
        this.state = {
            selectedListReportCode: undefined,
            reportLoading: false,
            connectionStatisticResponce: undefined,
            filter: {
                groups: (props?.userProfile?.profile?.Common?.Groups) ?? null,
                points: (props?.userProfile?.profile?.Common?.Points) ?? '',
                resources: (props?.userProfile?.profile?.Common?.Resources) ?? null,
                systemNumbers: null,
                text: ''
            },
            needTableRefresh: false
        }
    }

    componentDidMount() {
        if (this.props.monthReportStore.listReportItems == undefined && this.props.monthReportStore.isLoadingListReportItems === false) {
            this.props.requestListReportItems();
        }
    }

    componentDidUpdate(prevProps: MonthReportsProps, prevState: IMonthReportsState) {
        if (prevProps.monthReportStore.listReportItems !== this.props.monthReportStore.listReportItems &&
            this.props.monthReportStore.listReportItems != undefined && this.props.monthReportStore.listReportItems?.length > 0) 
        {
            this.setState({selectedListReportCode: this.props.monthReportStore.listReportItems[0].code});
        }
        if(this.props.monthReportStore.isLoadingListReportFile !== prevProps.monthReportStore.isLoadingListReportFile){
            this.props.setIsLoading(this.props.monthReportStore.isLoadingListReportFile, 200);
        }
        if (this.state.filter.points === '' && this.state.filter.groups === '' &&
            (prevState.filter.points !== '' || prevState.filter.groups !== '')) {
                this.onRefresh();
        }
    }

    onValueChange = (groups: any, points: any) => {
        this.setState({
            filter: {
                ...this.state.filter,
                groups: (groups.indexOf(Const.ALL_GROUP_ID) >= 0) ? null : Common.arrayToString(groups),
                points: Common.arrayToString(points)
            }
        });
        //this.props.changeProfile(Const.PROFILE_COMMON, {Groups: (groups.indexOf(Const.ALL_GROUP_ID) >= 0) ? null : Common.arrayToString(groups), Points: Common.arrayToString(points)});
    };

    onResourcesChange = (value: any) => {

        const selectAll = value.indexOf(Const.ALL_RESOURCE_ID) >= 0;
        this.setState({
            filter: {
                 ...this.state.filter,
                resources: selectAll ? null : Common.arrayToString(value)
             }
        });

        //this.props.changeProfile(Const.PROFILE_COMMON, {Resources: (value.indexOf(Const.ALL_RESOURCE_ID) >= 0) ? null : Common.arrayToString(value)});
    };

    requestMonthlyPacketFile = (id: number) => {
        this.props.requestMonthlyPacketFile(id);
    }

    makeListReport = (filter: SPointsFilter) => {
        if (this.state.selectedListReportCode != undefined) {
            let parameters: any = {};
            if (this.state.selectedListReportCode === 'PollingStatisticReport') {
                parameters['ShowGroupsStatistics'] = (this.props.userProfile.profile.MonthlyReports?.ShowGroupsStatistics ?? true) ? '1' : '0';
            }
            this.props.requestMakeListReport({beginDate: new Date(), endDate: new Date(), filter, parameters, period: null, reportCode: this.state.selectedListReportCode })
        }
    }

    columns1 = [
        { title: 'Месяц', dataIndex: 'month', key: 'month' }, 
        { title: 'Файл', dataIndex: 'file', key: 'file', render: (val: any, record: any) => <a onClick={() => { this.requestMonthlyPacketFile(record.key) }}>{val}</a> }, 
        { title: 'Дата', dataIndex: 'date', key: 'date', render: (val: any) => moment(val).format('DD.MM.YY') }
    ];

    gotoPage = (url:any) => {
        Modal.destroyAll();
        this.props.history.push(url);
    }

    getConnectionStatistics = () => {
        sendRequestToBackend(
            {},
            'points/connectionstatistic',
            (response: any) => {
                //console.log(response);
                this.setState({connectionStatisticResponce: response});
            },
            this.props.setIsLoading,
            (error: any) => {
                console.log(error);
                message.error(error);
                this.setState({connectionStatisticResponce: null});
            },
            this.props.closeSession);
    }

    pollingStatistics(statistics: SConnectionStatisticDTO, showPointInfo: any, gotoPage: any) {
        let res: any = null;
        if (statistics != null) {
            if(statistics.newEventsCount > 0 || statistics.connectionProblems.length > 0) {
                const fAcceptAllUserEvents = (onOk: any) =>{
                    const messageText: string = (statistics.newEventsCount === 1) ?
                    "Отметить 1 сообщение просмотренным?" :
                    'Отметить все ' + statistics.newEventsCount.toString() + ' сообщения просмотренными?';
                    Modal.confirm({
                        title: 'Квитирование всех сообщений',
                        wrapClassName: Const.MODAL_WRAP_CLASSNAME,
                        zIndex: getModalZIndex(),
                            content: messageText,
                        width: 400,
                        okText: 'Квитировать',
                        cancelText: 'Отмена',
                        onOk: () => {
                            sendRequestToBackend(
                                {eventIds: null},
                                'points/neweventsaccept3',
                                (response: any) => {
                                    message.success('События квитированы.');
                                    if(onOk)onOk();
                                },
                                this.props.setIsLoading,
                                (error: any) => {
                                    console.log(error);
                                },
                                this.props.closeSession
                            );
                        },
                        onCancel: () => {
                        }
                    });

                }

                const width = statistics.connectionProblems.length > 0 ? 1000 : 400;
                const scrollY = this.props.page.windowHeight - 350;
                res = <ConnectionStatistics statistics = {statistics}  acceptAllUserEvents={fAcceptAllUserEvents} showPointInfo = {showPointInfo} gotoPage={gotoPage} scrollY={scrollY}/>
            }
        }
        return res;
    }

    onChangeTab = (tabKey: string) => {
        if (tabKey === '2' && this.props.monthReportStore.monthlyPackets == undefined) {
            this.props.requestMonthlyPackets(this.props.monthReportStore.currentYear);
        } else if (tabKey === '3') {
            if (this.state.connectionStatisticResponce == undefined) {
                this.getConnectionStatistics();
            }
        }
    }

    onRefresh = () => {
        if (this.props.userProfile) {
            const common: any = { 
                Groups: this.state.filter.groups,
                Points: this.state.filter.points,
                Resources: this.state.filter.resources
            };
            this.props.changeProfile(Const.PROFILE_COMMON, common);
        }
        //this.setState({needTableRefresh: true});

        setTimeout(()=>{
            this.setState({needTableRefresh: true});
        }, 200);
    };

    public render() {
        const selectedGroupIds = (this.state.filter.groups != null) ?
            Common.stringToArray(this.state.filter.groups) :
            [Const.ALL_GROUP_ID];
        const selectedPointIds = (this.state.filter.points != null) ?
            Common.stringToArray(this.state.filter.points) :
            [];
        const selectedResourceIds = (this.state.filter.resources != null) ?
            Common.stringToArray(this.state.filter.resources) :
            [Const.ALL_RESOURCE_ID];

        const filter = {
            groups: this.props.userProfile.profile.Common?.Groups ?? null,
            points: this.props.userProfile.profile.Common?.Points ?? "", 
            resources: this.props.userProfile.profile.Common?.Resources ?? null,
            systemNumbers: null,
            text: "",
        }

        const GroupsProps = {
            groups: (this.props.userProfile) ? this.props.userProfile.userGroups : [],
            groupPoints: (this.props.userProfile) ? this.props.userProfile.groupPoints : {},
            selectedGroupIds: selectedGroupIds,
            selectedPointIds: selectedPointIds,
            getPoints: this.props.requestGroupPoints,
            getPointsParentGroups: null,
            onChange: this.onValueChange,
            setIsLoading: this.props.setIsLoading,
            onHideDropDown: this.onRefresh,
        };

        const ResourcesProps = {
            resources: (this.props.userProfile) ? this.props.userProfile.resources : [],
            selectedResourceIds: selectedResourceIds,
            onChange: this.onResourcesChange,
            onHideDropDown: this.onRefresh,
        };

        return (
                <div className={styles.container}>
                    {
                    this.props.monthReportStore == undefined ? <LoadingOutlined/> :
                    <Tabs type="card" tabBarStyle={{marginBottom: '0px'}} style={{height: '100%', overflow: 'auto'}} onChange={this.onChangeTab}>
                        <TabPane tab="Месячные отчеты" key="1" className={styles.TabBorder}>
                            <div className={styles.pageHeader}>
                                {
                                <Space direction="horizontal">
                                    <Groups {...GroupsProps} />
                                    <Resources {...ResourcesProps} />
                                    <Select style={{minWidth: '190px'}} placeholder={'Выберите отчёт'} value={this.state.selectedListReportCode} onChange={(val) => this.setState({selectedListReportCode: val})} className={styles.Width100}>
                                        {this.props.monthReportStore.listReportItems?.map(i => 
                                            <Option value={i.code} key={i.code}>{i.title}</Option>
                                        )}
                                    </Select>
                                    <Tooltip title={!this.state.selectedListReportCode ? 'Нужно выбрать отчёт!' : 'Запрос на формирование отчёта'}>
                                         <Button
                                                    disabled={
                                                        this.props.monthReportStore.isLoadingListReportFile ||
                                                        !this.state.selectedListReportCode ||
                                                        (this.state.filter.points === '' && this.state.filter.groups === '')}
                                                    onClick={() => this.makeListReport(filter)}
                                                    type='primary'
                                                >
                                                    Сформировать
                                         </Button>
                                    </Tooltip>
                                </Space>
                                }
                            </div>
                            <div className={styles.pageEmpty}>
                                <PointsTable
                                    filter={filter}
                                    needRefresh={this.state.needTableRefresh}
                                    className = {styles.fullSizeTable}
                                    titleClassName={styles.fullSizeTableTitle}
                                    scrollHeight={this.props.page.windowHeight -430}
                                    showPointInfo={this.props.showPointInfo}
                                    setIsLoading ={(isLoading: boolean)=>{
                                        if(!isLoading){
                                            this.setState({needTableRefresh: false})
                                        }
                                        this.props.setIsLoading(isLoading);
                                        }}/>
                            </div>
                            </TabPane>
                        <TabPane tab="Отчеты за год" key="2" className={styles.TabBorder}>
                            {
                            this.props.monthReportStore.isMonthlyPacketLoading === true || this.props.monthReportStore.isMonthlyPacketFileLoading === true ? <LoadingOutlined/> :
                            this.props.monthReportStore.monthlyPackets == undefined && this.props.monthReportStore.isMonthlyPacketLoading === false ? null :
                            <Space direction='vertical'>
                                <Space direction='horizontal'>
                                    <Select value={this.props.monthReportStore.currentYear} onChange={(val) => { this.props.requestMonthlyPackets(val) }} className={styles.FieldsMinWidth}>
                                        {
                                            Array(10).fill(moment().year()).map((x, y) => x - y).map(y => {
                                                return <Option key={y} value={y}>{y}</Option>
                                            })
                                        }
                                    </Select>
                                    <Button onClick={() => this.props.requestMonthlyPackets(this.props.monthReportStore.currentYear)} type='primary'>Обновить</Button>
                                </Space>
                                <Table style={{minWidth: '300px'}} bordered key='k2' pagination={false} columns={this.columns1} size="small" dataSource={
                                    this.props.monthReportStore.monthlyPackets?.map(p => {
                                        return ({
                                            key: p.id,
                                            month: p.month,
                                            file: p.monthlyPacketTypeName,
                                            date: p.monthDate,
                                        })
                                    })
                                }
                                />
                            </Space>
                            }
                        </TabPane>
                        <TabPane tab='Мониторинг опроса' key={'3'} className={styles.TabBorder} >
                            {
                            this.state.connectionStatisticResponce === undefined ? <LoadingOutlined/> :
                                <div style={{display: 'flex', alignItems: 'flex-start', flexDirection: 'column', gap: '8px'}}>
                                    <Button onClick={() => this.getConnectionStatistics()} type='primary'>Обновить</Button>
                                    { this.pollingStatistics(this.state.connectionStatisticResponce, this.props.showPointInfo, this.gotoPage) }
                                </div>
                            }
                        </TabPane>
                    </Tabs>
                    }
                </div>
        );
    }    
}

export default connect(
    (state: ApplicationState) => {
        return {
            page: state.page,
            userProfile: state.userProfile,
            monthReportStore: state.monthReports,
        }
    },
    actionCreators
)(MonthReports as any);
