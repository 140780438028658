import React from 'react';
import { Select, SelectProps } from 'antd';


type SelectPeriodsProps = {
    periods: number,
    onChange: (periods: number) => void,
}

const current: string = 'Текущие';
const hour: string = 'Час';
const day: string = 'Сутки';
const month: string = 'Месяц';

export const SelectPeriods: React.FC<SelectPeriodsProps> = (props: SelectPeriodsProps) => {
    const options: SelectProps['options'] = [];

    options.push({ value: current, label: current });
    options.push({ value: hour, label: hour });
    options.push({ value: day, label: day });
    options.push({ value: month, label: month });

    const getValue = () => {
        const result: string[] = [];
        if ((props.periods & 1) !== 0) result.push(current);
        if ((props.periods & 2) !== 0) result.push(hour);
        if ((props.periods & 4) !== 0) result.push(day);
        if ((props.periods & 8) !== 0) result.push(month);
        return result;
    } 


    const handleChange = (value: string | string[]) => {
        let periods: number = 0;
        if (value.includes(current)) periods |= 1;
        if (value.includes(hour)) periods |= 2;
        if (value.includes(day)) periods |= 4;
        if (value.includes(month)) periods |= 8;

        props.onChange(periods);
    };


    return (
        <Select
            mode="multiple"
            size="small"
            placeholder="Выберите периоды"
            defaultValue={[]}
            options={options}
            onChange={handleChange}
            style={{ width: '100%' }}
            value={getValue()}
            maxTagCount={2}
        />
    );
}