import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';

import { IActiveQuery, SPointTaskDTO } from '../decl';
import { sendRequestToBackend } from '../utils/AuthUtils';
import { CloseSessionAction } from './Page';
import { compareObj } from "../utils/Common";

export interface TasksState {
    activeQueries: IActiveQuery[];

    //Выполняющиеся задачи опроса.
    activeTasks: SPointTaskDTO[];

    activeLogQueryItemId: string | undefined,
    activeLogTaskId: number,
}

const InitState: TasksState =
{
    activeQueries: [],
    activeTasks: [],
    activeLogQueryItemId: undefined,
    activeLogTaskId: 0,
}

export interface SetActiveQueriesAction { type: 'SET_ACTIVE_QUERIES', activeQueries: IActiveQuery[] }
export interface ClearQueriesCacheAction { type: 'CLEAR_QUERIES_CACHE' }
export interface SetActiveTasks { type: 'SET_ACTIVE_TASKS', tasks: SPointTaskDTO[] }
export interface SetActiveLogTask { type: 'SET_ACTIVE_LOG_TASK', activeLogQueryItemId: string | undefined, activeLogTaskId: number }

export type KnownAction = CloseSessionAction | SetActiveQueriesAction | ClearQueriesCacheAction |
    SetActiveTasks | SetActiveLogTask;

let _waitActiveTasks: boolean = false;

export const actionCreators = {
    setActiveQueries: (activeQueries: IActiveQuery[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            dispatch({ type: 'SET_ACTIVE_QUERIES', activeQueries: activeQueries });
        }
    },
    //Получение активных задач.
    getActiveTasks: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        //console.log('requestActiveTasks');
        const appState = getState();
        if (appState && !_waitActiveTasks) {
            _waitActiveTasks = true;
            sendRequestToBackend(
                null,
                'admin/getactivetasks',
                (response: SPointTaskDTO[]) => {
                    _waitActiveTasks = false;
                    if (!compareObj(appState.tasks.activeTasks, response)) {
                        //console.log("setActiveTasks");
                        dispatch({
                            type: 'SET_ACTIVE_TASKS',
                            tasks: response
                        });
                    }
                },
                null,
                () => {
                    _waitActiveTasks = false;
                },
                (message: string | null) => {
                    _waitActiveTasks = false;
                    dispatch({ type: 'CLOSE_SESSION', message: message })
                },
                undefined,
                true
            );
        }
    },
    //Установка задачи для открытия онлайн лога.
    setActiveLogTask: (queryItemId: string | undefined, taskId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            dispatch({ type: 'SET_ACTIVE_LOG_TASK', activeLogQueryItemId: queryItemId, activeLogTaskId: taskId });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<TasksState> = (state: TasksState | undefined, incomingAction: Action): TasksState => {
    if (state === undefined) {
        return InitState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SET_ACTIVE_QUERIES':
            return {
                ...state,
                activeQueries: action.activeQueries
            };
        case 'CLEAR_QUERIES_CACHE':
            return {
                ...state,
                activeQueries: []
            };
        case 'SET_ACTIVE_TASKS':
            {
                return {
                    ...state,
                    activeTasks: action.tasks,
                };
            }
        case 'SET_ACTIVE_LOG_TASK':
            {
                return {
                    ...state,
                    activeLogQueryItemId: action.activeLogQueryItemId,
                    activeLogTaskId: action.activeLogTaskId
                };
            }
        default:
            return state;
    }
};
