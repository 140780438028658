import * as React from 'react';
import { Input, Select, Tooltip } from 'antd';
import { connect } from 'react-redux';

import Login from './Login2';
import { ApplicationState } from '../store';
import * as PageStore from '../store/Page';
import * as UserProfileStore from '../store/UserProfile';
import * as ReportsStore from '../store/Reports';
import { RouteComponentProps } from 'react-router';
import styles from '../resources/Page.module.less';
import * as WeatherStore from '../store/WeatherStore';

const { Option } = Select;
const { Search } = Input;

import {
    SyncOutlined,
} from '@ant-design/icons';
import { MiddleContentMessageBlock } from './HeaderForm';
import RangePickerLimit from './Helper/RangePickerLimit';
import CollapsedButton from './Helper/CollapsedButton';
import { makeTime, message} from '../utils/Common';

const actionCreators = { ...PageStore.actionCreators, ...UserProfileStore.actionCreators, ...ReportsStore.actionCreators, ...WeatherStore.actionCreators };

type HeaderProps =
    { userProfile: UserProfileStore.UserProfileState, reports: ReportsStore.IReportsState, weather: WeatherStore.IWeatherState, windowWidth: number, isMenuCollapsed: boolean } &
    typeof actionCreators &
    RouteComponentProps<{}>

interface HeaderState {
}

class WeatherHeaderForm extends React.PureComponent<HeaderProps, HeaderState>{
    constructor(props: HeaderProps) {
        super(props);

        this.state = {
        }
    }

    componentDidUpdate(prevProps: HeaderProps) {
        if (this.props.weather.weatherStations === undefined && this.props.weather.isLoading === false) {
            this.props.requestWeatherStations();
        }

        if (prevProps.weather.currentWeatherStationId !== this.props.weather.currentWeatherStationId) {
            this.onRefresh();
        }
    }

    componentDidMount() {
        if (this.props.weather != undefined) {
            this.onRefresh();
        }
    }
    onRefresh = () => {
        this.props.setNeedRefresh(true);
    };


    render() {
        const isColapsed = (!this.props.isMenuCollapsed && this.props.windowWidth < 1920) ||
            (this.props.isMenuCollapsed && this.props.windowWidth < 1680);
        return (
            this.props.weather == undefined || this.props.weather.isLoading === true ? null :
                <div className={styles.flexcontainer}>
                    <div className={styles.comboblock}>
                        <div className={styles.flexitem}>
                            <Tooltip placement="bottomRight" title={'Выбрать период'}>
                                <RangePickerLimit
                                    style={{ minWidth: '250px' }}
                                    value={[this.props.weather.dateFrom, this.props.weather.dateTo]}
                                    allowClear={false}
                                    onChange={(val) => {
                                        this.props.setWeatherDates(val?.[0] ?? makeTime(new Date(), 0, 0, 0), val?.[1] ?? makeTime(new Date(), 23, 59, 59));
                                    }
                                    }
                                    limitDays={40}
                                />
                            </Tooltip>
                        </div>

                        <div className={styles.flexitem}>
                            <Tooltip placement="bottomRight" title={'Выбор метеостанции'}>
                                <Select
                                    disabled={false}
                                    value={this.props.weather.currentWeatherStationId}
                                    onChange={val => { this.props.setCurrentWeatherStationId(val) }}
                                    dropdownMatchSelectWidth={false} style={{ width: '300px' }}
                                    placeholder={'Выберите метеостанцию' }
                                >
                                    {
                                        <>
                                            {
                                                this.props.weather.weatherStations?.map(item => {
                                                    return (
                                                        <Option key={item.id} value={item.id}>{item.name}</Option>
                                                    )
                                                })
                                            }
                                        </>

                                    }
                                </Select>
                            </Tooltip>
                        </div>
                    </div>
                    <div className={styles.buttonsblock}>
                        <CollapsedButton isCollapsed={isColapsed} onClick={this.onRefresh} icon={<SyncOutlined />} type="primary">Обновить</CollapsedButton>
                    </div>

                    <div className={styles.mbitem}>
                        <MiddleContentMessageBlock windowSize={this.props.windowWidth} maxWindowSize={this.props.isMenuCollapsed ? 1380 : 1460} onlyIcon={this.props.windowWidth < 2200} hideEmail={false} isSplit={false} />
                    </div>
                    <div className={styles.flexuseritem}>
                        <Login />
                    </div>
                </div>
        )
    }
}



export default connect(
    (state: ApplicationState) => {
        return {
            userProfile: state.userProfile,
            weather: state.weather,
            windowWidth: state.page.windowWidth,
            isMenuCollapsed: state.siderMenu?.collapsed
        }
    },
    actionCreators
)(WeatherHeaderForm as any);
