import * as React from 'react';

import styles from '../../resources/Page.module.less';
import { Button, Modal, Select, Input, Row, Col, Table, Tag, DatePicker, Tabs, Tooltip, Space, Checkbox, Switch } from 'antd';

import { arrayToString, convertBase64ToBinary, deepCopyObj, getModalZIndex, message, saveFile, toDate } from '../../utils/Common';
import { ALL_GROUP_ID, MODAL_WRAP_CLASSNAME } from '../../utils/Const';
import { SBilContactDTO, SBilContractDTO, SBillingCompanyInfoDTO, SBillingTariffDTO, SContractPointDTO } from './BillingDecls';
import moment from 'moment';
import { CheckCircleTwoTone, DeleteTwoTone, EditTwoTone, StopTwoTone } from '@ant-design/icons';
import * as Const from '../../utils/Const';
import Search from 'antd/lib/input/Search';
import CompanyEditForm from './CompanyEditForm';
import { sendRequestToBackend } from '../../utils/AuthUtils';
import TariffEditForm from './TariffEditForm';
import { Point, Resource } from '../../decl';
import SimpleTreeSelect from '../Helper/SimpleTreeSelect';
import { debug } from 'console';

const { Option } = Select;

type Props = {
    onHide(needRefresh: boolean): void,
    visible: boolean,
    setIsLoading: any,

    tariffs: SBillingTariffDTO[],
    contracts: SBilContractDTO[],
    companies: SBillingCompanyInfoDTO[],
    allPoints: {[pointId: string] : Point},
    paymentTypes: {[id: number]: string},
    resources: Resource[],
}

type State = {
    contractsPoints: SContractPointDTO[] | undefined,
    selectedPoints: SContractPointDTO[],
    filterPaymentTypes: string[] | null,
    filterPeriods: string[] | null,
    filterText: string,
    filterResourceIds: string[] | null,

    paymentAllPeriods: number[] | undefined,

    beginDate: Date,
    endDate: Date,
    saveToBase: boolean,
}

export default class PromisedPaymentForm extends React.Component<Props, State> {
    constructor(props: Props, state: State) {
        super(props);

        this.state = deepCopyObj(this.defState);
    }

    defState: State = {
        contractsPoints: undefined,
        selectedPoints: [],
        filterPaymentTypes: null,
        paymentAllPeriods: undefined,
        filterPeriods: null,
        filterText: '',
        beginDate: toDate(new Date()),
        endDate: toDate(new Date()),
        saveToBase: false,
        filterResourceIds: null,
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps.visible === false && this.props.visible === true) {
            sendRequestToBackend(
                null,
                'billing/allcontractpoints',
                (response: SContractPointDTO[]) => {
                    if (response != null) {
                        this.setState({contractsPoints: response});
                        this.makeDatasource(response, this.state.selectedPoints);
                    } else {
                        message.error('Запрос завершился неудачей');
                    }
                },
                this.props.setIsLoading,
                (error: any) => {
                    console.log(error);
                    message.error(error);
                }
            );

            this.setState(deepCopyObj(this.defState));
            this.makePaymentAllPeriods();
        }

        if (prevProps.visible === true && this.props.visible === false) {
            this.setState({paymentAllPeriods: undefined});
        }

        if (this.props.visible === true && this.props.contracts != undefined && prevProps.contracts !== this.props.contracts) {
            this.makePaymentAllPeriods();
        }
    }

    makePaymentAllPeriods = () => {
        let periods: number[] = [];
            for (const c of this.props.contracts) {
                if (!periods.includes(c.paymentPeriod)) {
                    periods.push(c.paymentPeriod);
                }
            }

            this.setState({paymentAllPeriods: periods});
    }

    sorter = (a: any, b: any) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b);

    private dataSource: any = undefined;

    makeDatasource = (arr: SContractPointDTO[] | undefined, selectedPoints: SContractPointDTO[]) => {
        if (arr == undefined) {
            this.dataSource = undefined;
        } else {
            this.dataSource = arr.map((x, index) => {
                const contract = this.props.contracts.find(y => y.id === x.contractId);
                const company = this.props.companies.find(y => y.id === contract?.companyId);

                return ({
                    key: x.contractId.toString() + '_' + x.pointId.toString(),
                    num: index + 1,
                    checkbox: selectedPoints.findIndex(y => y.pointId === x.pointId && y.contractId === x.contractId) != -1,
                    contractNumber: contract?.number,
                    contractDate: contract?.date != undefined ? moment(contract.date).format('DD.MM.YYYY') : null,
                    user: contract?.userName,
                    company: company?.name,
                    pointNumber: this.props.allPoints[x.pointId].number,
                    PointAddress: this.props.allPoints[x.pointId].persistProperties['address'],
                    price: x.price,
                    active: x.isActive,
                    pointId: x.pointId,
                    contractId: x.contractId,
                })
            });
        }

        this.forceUpdate();
    }

    onChangeCheckedPoints = (checked: boolean, contractId: number, pointId: string) => {
        let arr = [...this.state.selectedPoints];
        if (checked === true) {
            let p = this.state.contractsPoints?.find(x => x.contractId === contractId && x.pointId === pointId);
            if (p != undefined) {
                arr.push(p);
            }
        } else {
            arr = arr.filter(x => !(x.contractId === contractId && x.pointId === pointId));
        }

        this.setState({selectedPoints: arr});
        this.applyFilter(this.state.filterText, this.state.filterPaymentTypes, this.state.filterPeriods, this.state.filterResourceIds, arr);
    }

    setCheckedPoints = (checked: {contractId: number, pointId: string}[]) => {
        let arr: SContractPointDTO[] = [];

        for(const c of checked) {
            let p = this.state.contractsPoints?.find(x => x.contractId === c.contractId && x.pointId === c.pointId);
            if (p != undefined) {
                arr.push(p);
            }
        }

        this.setState({selectedPoints: arr});
        this.applyFilter(this.state.filterText, this.state.filterPaymentTypes, this.state.filterPeriods, this.state.filterResourceIds, arr);
    }

    titleCheckBox = () => {
        return (
            <Checkbox 
                checked={this.dataSource?.length === this.dataSource?.filter((x: any) => x.checkbox === true).length} 
                onChange={(val) =>{
                    if (this.dataSource?.length !== this.dataSource.filter((x: any) => x.checkbox === true).length)
                    {
                        let all: {contractId: number, pointId: string}[] = [];

                        for(const d of this.dataSource) {
                            all.push({contractId: d.contractId, pointId: d.pointId});
                        }

                        this.setCheckedPoints(all);
                    } else {
                        this.setCheckedPoints([]);
                    }
                }}
            />
        )
    }

    applyFilter = (text: string, filterPaymentTypes: string[] | null, filterPaymentPeriods: string[] | null, filterResourcesIds: string[] | null, selectedPoints: SContractPointDTO[]) => {
        text = text.toLowerCase();
        let arr: SContractPointDTO[] = [...this.state?.contractsPoints ?? []];
        if (text !== '') {
            arr = arr.filter(x => {
                const contract = this.props.contracts.find(y => y.id === x.contractId);
                const company = this.props.companies.find(y => y.id === contract?.companyId);

                return contract?.number.toLowerCase().indexOf(text) !== -1 
                        || contract?.userName.toLowerCase().indexOf(text) !== -1 
                        || company?.name.toLowerCase().indexOf(text) !== -1
                        || this.props.allPoints[x.pointId].number.toLowerCase().indexOf(text) !== -1 
                        || this.props.allPoints[x.pointId].persistProperties['address'].toLowerCase().indexOf(text) !== -1 
                        || x.price.toString().toLowerCase().indexOf(text) !== -1 
                        || company?.name.toLowerCase().indexOf(text) !== -1;
            })
        }

        if (filterPaymentTypes != null) {
            arr = arr.filter(x => {
                const contract = this.props.contracts.find(y => y.id === x.contractId);
                return contract != undefined && filterPaymentTypes?.includes(contract.paymentTypeId.toString());
            })
        }

        if (filterPaymentPeriods != null) {
            arr = arr.filter(x => {
                const contract = this.props.contracts.find(y => y.id === x.contractId);
                return contract != undefined && filterPaymentPeriods?.includes(contract.paymentPeriod.toString());
            })
        }

        if (filterResourcesIds != null) {
            arr = arr.filter(x => {
                return filterResourcesIds.includes(this.props.allPoints[x.pointId].resourceTypeId.toString());
            })
        }

        //Отсеиваем ТУ которых нет в фильтре
        //selectedPoints = selectedPoints.filter(x => arr.findIndex(y => y.contractId === x.contractId && y.pointId === x.pointId) !== -1);
        
        arr = arr.filter(x => selectedPoints.findIndex(y => x.contractId === y.contractId && x.pointId === y.pointId) === -1);
        arr = [...selectedPoints, ...arr];

        this.setState({selectedPoints});

        this.makeDatasource(arr, selectedPoints);
        return arr;
    }

    onOk = () => {
        let flag = false;
        for (const t of this.state.selectedPoints) {
            if (this.state.selectedPoints.findIndex(x => x.pointId === t.pointId && x.contractId !== t.contractId) !== -1) {
                flag = true;
                message.error('Для выбранных записей обнаружено наличие одной ТУ в разных договорах: ' + this.props.allPoints[t.pointId].number);
                break;
            }
        }

        if (flag === false) {
            if (this.state.selectedPoints.length > 0) {
                sendRequestToBackend(
                    {points: this.state.selectedPoints, beginDate: this.state.beginDate, endDate: this.state.endDate, saveToBase: this.state.saveToBase},
                    'billing/promisedpaymentscreate',
                    (response: string) => {
                        if (response != null) {
                            const fileName = this.state.saveToBase === true ? 'Добавление обещанного платежа.csv' : 'Проверка добавления обещанного платежа.csv';
                            saveFile(fileName, convertBase64ToBinary(response));
                            this.props.onHide(true);
                        } else {
                            message.error('Запрос завершился неудачей');
                        }
                    },
                    this.props.setIsLoading,
                    (error: any) => {
                        console.log(error);
                        message.error(error);
                    }
                );
            } else {
                message.error('Необходимо выбрать хотя бы одну запись');
            }
        }
    }

    render() {

        let columns = [
            {
                dataIndex: "num",
                title: "№",
                render: (text: any, record: any, index: any) => text,
                width: 60,
            },
            {
                dataIndex: "checkbox",
                title: this.titleCheckBox,
                width: 60,
                align: 'center' as 'center',
                render: (val: any, record: any, index: any) => <Checkbox checked={val} onChange={(e) => this.onChangeCheckedPoints(e.target.checked, record.contractId, record.pointId)} />
            },
            {
                dataIndex: "contractNumber",
                title: "Номер договора",
                sorter: (a: any, b: any) => this.sorter(a.contractNumber, b.contractNumber),
            },
            {
                dataIndex: "contractDate",
                title: "Дата",
                width: 90,
                align: 'center' as 'center',
                sorter: (a: any, b: any) => this.sorter(a.contractDate, b.contractDate),
            },
            {
                dataIndex: "user",
                title: "Пользователь",
                sorter: (a: any, b: any) => this.sorter(a.user, b.user),
            },
            {
                dataIndex: "company",
                title: "Контрагент",
                sorter: (a: any, b: any) => this.sorter(a.company, b.company),
            },
            {
                dataIndex: "pointNumber",
                title: "Аб. номер",
                sorter: (a: any, b: any) => this.sorter(a.pointNumber, b.pointNumber),
            },
            {
                dataIndex: "PointAddress",
                title: "Наименавание",
                sorter: (a: any, b: any) => this.sorter(a.PointAddress, b.PointAddress),
            },
            {
                dataIndex: "price",
                title: "Аб. плата",
                width: 100,
                sorter: (a: any, b: any) => this.sorter(a.price, b.price),
            },
            {
                dataIndex: "active",
                title: "Активен",
                width: 80,
                align: 'center' as 'center',
                render: (val: any, record: any, index: any) => {
                    if (val === true) {
                        return <CheckCircleTwoTone style={{ fontSize: '20px'}} twoToneColor={Const.COLORS.SvgIconColor}/>
                    } else {
                        return <StopTwoTone style={{ fontSize: '20px'}} twoToneColor="#8c8c8c" />
                    }
                },
                sorter: (a: any, b: any) => this.sorter(a.active, b.active),
            },
        ];

        return (
            <Modal
                title={ 'Обещанные платеж' }
                wrapClassName={MODAL_WRAP_CLASSNAME}
                width={1300}
                //zIndex={this.props.zIndex}
                open={this.props.visible}
                cancelText='Закрыть'
                okText='OK'
                style={{top: '80px'}}
                // footer={[
                //     <div style={{ display: 'flex', alignItems: 'baseline' }}>
                //         <Button key="1" type='primary' onClick={this.props.onHide} style={{marginLeft: 'Auto'}}>Отмена</Button>
                //         <Button key="2" type='primary' onClick={this.onOk}>{this.props.operation === undefined ? "Добавить" : "Изменить"}</Button>
                //     </div>
                // ]}
                onCancel={() => this.props.onHide(false)}
                onOk={() => this.onOk()}
            >
                <div className={styles.FormWrapper}>
                    <div style={{ overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ padding: '4px'}}>
                            <Space style={{marginBottom: '6px'}}>
                                <SimpleTreeSelect
                                    key={'st1'}
                                    items={Object.keys(this.props.paymentTypes).map(x => ({id: x, name: this.props.paymentTypes[Number(x)], shortName: this.props.paymentTypes[Number(x)]}))}
                                    selectedIds={this.state.filterPaymentTypes == undefined ? ['all'] : this.state.filterPaymentTypes}
                                    onChange={(value) => {
                                        const ids = value.indexOf('all') >= 0 ? null : value;
                                        this.applyFilter(this.state.filterText, ids, this.state.filterPeriods, this.state.filterResourceIds, this.state.selectedPoints); 
                                        this.setState({ filterPaymentTypes: ids });
                                    }}
                                    constAllSelected={'all'}
                                    rootItemShortTitle='Все типы'
                                    rootItemTitle='Все типы'
                                />

                                {this.state?.paymentAllPeriods == undefined ? null :
                                    <SimpleTreeSelect
                                        key={'st2'}
                                        items={this.state.paymentAllPeriods.map(x => ({id: x.toString(), name: x.toString(), shortName: x.toString()}))}
                                        selectedIds={this.state.filterPeriods == undefined ? ['all'] : this.state.filterPeriods}
                                        onChange={(value) => {
                                            const ids = value.indexOf('all') >= 0 ? null : value;
                                            this.applyFilter(this.state.filterText, this.state.filterPaymentTypes, ids, this.state.filterResourceIds, this.state.selectedPoints);
                                            this.setState({ filterPeriods: ids });
                                        }}
                                        constAllSelected={'all'}
                                        rootItemShortTitle='Все периоды'
                                        rootItemTitle='Все периоды'
                                    />
                                }
                                <SimpleTreeSelect
                                    key={'st3'}
                                    items={this.props.resources.map(x => ({name: x.name, shortName: x.shortName, id: x.id.toString()}))}
                                    selectedIds={this.state.filterResourceIds == null ? [Const.ALL_RESOURCE_ID] : this.state.filterResourceIds}
                                    onChange={(value) => {
                                        const selected = value.indexOf(Const.ALL_RESOURCE_ID) >= 0 ? null : value;
                                        this.applyFilter(this.state.filterText, this.state.filterPaymentTypes, this.state.filterPeriods, selected, this.state.selectedPoints);
                                        this.setState({ filterResourceIds: selected });
                                    }}
                                    constAllSelected={Const.ALL_RESOURCE_ID}
                                    rootItemShortTitle='Все ресурсы'
                                    rootItemTitle='Все ресурсы'
                                />

                                <Search style={{width: '130px'}} onChange={(val) => { this.applyFilter(val.target.value, this.state.filterPaymentTypes, this.state.filterPeriods, this.state.filterResourceIds, this.state.selectedPoints); this.setState({filterText: val.target.value}) }} />
                                <span style={{paddingLeft: '10px'}}>Нач. дата <DatePicker style={{width: '120px'}} allowClear={false} value={moment(this.state?.beginDate)} onChange={val => this.setState({beginDate: val?.toDate() ?? new Date()})} format={['DD.MM.YYYY', 'DDMMYYYY']} /> </span>
                                <span style={{paddingLeft: '10px'}}>Кон. дата <DatePicker style={{width: '120px'}} allowClear={false} value={moment(this.state?.endDate)} onChange={val => this.setState({endDate: val?.toDate() ?? new Date()})} format={['DD.MM.YYYY', 'DDMMYYYY']} /> </span>
                                <span style={{paddingLeft: '10px'}}></span>Записать в БД 
                                <Switch checked={this.state?.saveToBase} onChange={val => this.setState({saveToBase: val})} /> 
                            </Space>
                            <Table 
                                columns={columns} dataSource={this.dataSource} showSorterTooltip={false}
                                scroll={{ y: 'calc(100vh - 430px)' }} bordered size='small'
                                pagination={{ showSizeChanger: true, pageSizeOptions: ["7", "50", "100", "500", "1000"], defaultPageSize: 7, locale: { items_per_page: " / страницу" }}}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}