import * as React from 'react';
import {Table, Button} from 'antd';
import {ScheduleTwoTone } from '@ant-design/icons';

import { AlignType } from 'rc-table/lib/interface';

import { SResponseDTO, ResponseStatusesEnum, SPointsFilter, Point, ICommand } from "../../decl"
import { getHeaders, BACKEND_URL } from '../../utils/AuthUtils';
import {message, stringSorter} from "../../utils/Common";
import * as Const from "../../utils/Const";
import { ColorLensOutlined } from '@material-ui/icons';

interface IPointsTableProps {
    filter: SPointsFilter;
    needRefresh: boolean;
    className: string;
    titleClassName: string;
    scrollHeight: number;
    showPointInfo: any;
    setIsLoading: any;
}

interface IPointsTableState{
    data: any;
}

//Колонки для тестовых данных.
const testColumns = [
    { title: 'Name',  dataIndex: 'name',  key: 'name'  },
    { title: 'Age',   dataIndex: 'age',   key: 'age'   },
    { title: 'Address', dataIndex: 'address', key: 'address' }
];

//Тестовые данные.
const testData = [
    { key: '1',  name: 'Mike', age: 32, address: '10 Downing Street' },
    { key: '2',  name: 'John', age: 42, address: '10 Downing Street' }
];


//Функция создания много тестовых данных.
const createTestData = (n: number)=>{
    const result = [];
    for(var i = 0; i < n; i++){
        result.push({key: i+1, name: 'Name'+ i, age: i +10, address: 'Address' + i});
    }
    return result;
}

const getDataFromBase = (request: any, url:string, setData: any, setIsLoading: any) => {
        const requestOptions = {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(request)
        };
        setIsLoading(true);

        fetch(BACKEND_URL + url, requestOptions)
            .then(response => response.json() as Promise<SResponseDTO>)
            .then(data => {
                if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                    setData(data.body);
                    //console.log(data.body);
                }
                else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                    message.error(data.message);
                }
                else {
                    message.error('Ответ не получен.');
                }
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                message.error('Ошибка:' + error);
                console.log(error);
            });

    }

export default class PointsTable extends React.Component<IPointsTableProps, IPointsTableState>{
    _isMounted: boolean;

    constructor(props: IPointsTableProps) {
        super(props);
        this.state = {
            data: []
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.getData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    shouldComponentUpdate(nextProps: IPointsTableProps, nextState: IPointsTableState) {
        if(this.props.needRefresh === nextProps.needRefresh &&
            this.state.data === nextState.data &&
            this.props.scrollHeight === nextProps.scrollHeight){
                return false;
        }

        return true;
    }

    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
        if(this.props.needRefresh && !prevProps.needRefresh){
            this.getData();
        }
    }

    setDataToState = (data: any) => {
        if(this._isMounted){
            const tableData: any = data.map(
                (p: Point)=>{ 
                    const resource: String = p.persistProperties['resourceshortname'];
                    const name: String = p.persistProperties['name'];
                    const address: String = p.persistProperties['address'];
                    return {
                        key: p.id,
                        pointId: p.id,
                        pointNumber:p.number,
                        name: name,
                        address: address,
                        resource: resource
                    }
                });
            this.setState({data: tableData});
        }
    }


    getColumns = () => {
        return testColumns;
    }

    getDataSource = () => {
        return this.state.data;
    }

    getData = () =>{
        getDataFromBase(this.props.filter, 'points/pointsbyfilter', this.setDataToState, this.props.setIsLoading);
    }
   
    render(){
        const columns: any = [
            {
              key: 'rownumber',
              title: '№',
              width: '7%',
              align: 'center' as AlignType ,
              render:  (value:any, item:any, index:any) => index + 1,
             },
            {
              key: 'info',
              title: 'Инфо',
              width: '8%',
              align: 'center' as AlignType,
              render: (text: any, record: any) => 
                  <Button onClick={() => this.props.showPointInfo(record.pointId, record.pointNumber)} size="middle" shape="circle" icon={<ScheduleTwoTone twoToneColor={Const.COLORS.SvgIconColor} />} />,
            },
            {
                title: 'Точка учёта',
                dataIndex: 'pointNumber',
                key: 'pointNumber',
                width: '20%',
                sorter: (a: any, b: any) => stringSorter(a.pointNumber, b.pointNumber)
            },
            {
                title: 'Название',
                dataIndex: 'name',
                key: 'name',
                width: '25%',
                sorter: (a: any, b: any) => stringSorter(a.name, b.name)
            },
            {
                title: 'Адрес',
                dataIndex: 'address',
                key: 'address',
                width: '30%',
                sorter: (a: any, b: any) => stringSorter(a.address, b.address)
            },
            {
                title: 'Ресурс',
                dataIndex: 'resource',
                key: 'resource',
                width: '10%',
                sorter: (a: any, b: any) => stringSorter(a.resource, b.resource)
            }

        ];
  
        const dataSource = this.getDataSource();
        const title = (data:any) =>  <span className={this.props.titleClassName}> {data.length > 0 ?  'Выбранo точек учёта: ' + data.length.toString()  : 'Точки учёта не выбраны'}</span>;
        return (
            <Table 
                pagination={false}
                scroll={{y: this.props.scrollHeight }}
                title= {title}
                className = {this.props.className}
                columns={columns}
                dataSource={dataSource}
            />);
    }
}

