import * as WeatherForecasts from './WeatherForecasts';
import * as Counter from './Counter';
import * as SiderMenu from './SiderMenu';
import * as Page from './Page';
//import * as User from './User';
import * as Neti from './Neti';
import * as Header from './Header';
import * as UserProfile from './UserProfile';
import * as MapDataStore from './MapDataStore';
import * as SettingsStore from './SettingsStore'
import * as Reports from './Reports';
import * as Kadr from './Kadr';
import * as Tables from './Tables';
import * as Control from './ControlStore';
import * as Operations from './OperationsStore';
import * as ChartsStore from './Charts';
import * as Mnemaschemas from './MnemoschemaStore';
import * as Nsi from './NsiStore';
import * as SiteLog from './SiteLogStore';
import * as MonthReports from './MonthReportsStore'
import * as AdminStore from './AdminStore'
import * as BillingStore from './BillingStore'
import * as WeatherStore from './WeatherStore'
import * as Tasks from './Tasks';

// The top-level state object
export interface ApplicationState {
    counter: Counter.CounterState | undefined;
    weatherForecasts: WeatherForecasts.WeatherForecastsState | undefined;
    siderMenu: SiderMenu.SiderMenuState | undefined;
    page: Page.PageState;
    //user: User.UserState | undefined;
    neti: Neti.NetiState | undefined;
    header: Header.HeaderState | undefined;
    userProfile: UserProfile.UserProfileState | undefined;
    mapDataStore: MapDataStore.IMapDataState | undefined;
    settingsStore: SettingsStore.ISettingsState | undefined;
    reports: Reports.IReportsState | undefined;
    kadr: Kadr.IKadrState | undefined;
    control: Control.IControlState | undefined;
    tables: Tables.ITablesState | undefined;
    operations: Operations.IOperationsState | undefined;
    charts: ChartsStore.IChartsState | undefined;
    mnemoschemas: Mnemaschemas.IMnemoschemasState | undefined;
    nsi: Nsi.INsiState | undefined;
    siteLog: SiteLog.ISiteLogState | undefined;
    monthReports: MonthReports.IMonthReportsState | undefined;
    adminStore: AdminStore.IAdminState | undefined;
    billingStore: BillingStore.IBillingState | undefined;
    weather: WeatherStore.IWeatherState | undefined;
    tasks: Tasks.TasksState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    counter: Counter.reducer,
    weatherForecasts: WeatherForecasts.reducer,
    siderMenu: SiderMenu.reducer,
    page: Page.reducer,
    //user: User.reducer,
    neti: Neti.reducer,
    header: Header.reducer,
    userProfile: UserProfile.reducer,
    mapDataStore: MapDataStore.reducer,
    settingsStore: SettingsStore.reducer,
    reports: Reports.reducer,
    kadr: Kadr.reducer,
    tables: Tables.reducer,
    control: Control.reducer,
    operations: Operations.reducer,
    charts: ChartsStore.reducer,
    mnemoschemas: Mnemaschemas.reducer,
    nsi: Nsi.reducer,
    siteLog: SiteLog.reducer,
    monthReports: MonthReports.reducer,
    adminStore: AdminStore.reducer,
    billingStore: BillingStore.reducer,
    weather: WeatherStore.reducer,
    tasks: Tasks.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
