import * as React from 'react';

import styles from '../resources/Page.module.less';
import { Button, Modal, Select, Input, Row, Col, Table, Tag, DatePicker, Tabs } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';

import { arrayToString, message } from '../utils/Common';
import { ALL_GROUP_ID, MODAL_WRAP_CLASSNAME } from '../utils/Const';
import { SPointsFilter } from '../decl';

import Groups from './Groups';
import { AbonentOperationDTO, AbonentOperationTypeDTO } from '../store/OperationsStore';
import RangePickerLimit from './Helper/RangePickerLimit';

const { Option } = Select;

type Props = {
    visible: boolean,
    zIndex: number,
    types: AbonentOperationTypeDTO[],
    operation: AbonentOperationDTO | undefined,
    writeNewOperations(filter: SPointsFilter, template: AbonentOperationDTO): any,
    writeOperation(condition: AbonentOperationDTO): any,
    onHide(): void,
    requestGroupPoints: any,
    groupPoints: any,
    setIsLoading: any,
    userGroups: any
    groups: any,
}

type State = {
    operation: AbonentOperationDTO,
    selectedAbonents: string[],
    selectedGroups: string[],
}

class AbonentOperationForm extends React.Component<Props, State> {
    constructor(props: Props, state: State) {
        super(props);

        this.state = {
            operation: { ...this.newOperation },
            selectedAbonents: [],
            selectedGroups: [],
        }
    }

    newOperation: AbonentOperationDTO = {
        abonentAddress: undefined,
        abonentId: '',
        abonentName: undefined,
        abonentNumber: undefined,
        beginDate: moment(new Date()).set({ hour:0, minute:0, second:0, millisecond:0 }).toDate(),
        comment: '',
        endDate: moment(new Date()).set({ hour:0, minute:0, second:0, millisecond:0 }).toDate(),
        id: -1,
        operationTypeId: this.props.types[0].id,
        resource: undefined,
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.visible === false && this.props.visible === true) {
            if (this.props.operation == undefined) {
                this.setState({ operation: { ...this.newOperation }, selectedGroups: [], selectedAbonents: [] })
            } else {
                this.setState({ operation: { ...this.props.operation }, selectedAbonents: [this.props.operation.abonentId] })
            }
        }
    }

    OperFieldChange = (field: keyof AbonentOperationDTO, value: any) => {
        this.setState({ operation: { ...this.state.operation, [field]: value } })
    }

    onOk = () => {
        let oper = { ...this.state.operation };

        if (this.props.operation == undefined) {
            if (this.state.selectedAbonents.length > 0 || this.state.selectedGroups.length > 0) {
                let filter: SPointsFilter = {
                    groups: arrayToString(this.state.selectedGroups.indexOf(ALL_GROUP_ID) >= 0 ? null : this.state.selectedGroups),
                    points: arrayToString(this.state.selectedAbonents),
                    resources: null,
                    systemNumbers: null,
                    text: '',
                };

                this.props.writeNewOperations(filter, oper);
                this.props.onHide();
            } else {
                message.warning('Необходимо выбрать точки учета.');
            }
        } else {
            this.props.writeOperation(oper);
            this.props.onHide();
        }
    };

    render() {
        return (
            <Modal
                title={ this.props.operation !== undefined ? "Редактирование " + "" : 'Добавление записи' }
                wrapClassName={MODAL_WRAP_CLASSNAME}
                bodyStyle={{height: '1000px !important'}}
                zIndex={this.props.zIndex}
                open={this.props.visible}
                footer={[
                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                        <Button key="1" type='primary' onClick={this.props.onHide} style={{marginLeft: 'Auto'}}>Отмена</Button>
                        <Button key="2" type='primary' onClick={this.onOk}>{this.props.operation === undefined ? "Добавить" : "Изменить"}</Button>
                    </div>
                ]}
                onCancel={() => { this.props.onHide() }}
            >
                <div className={styles.FormWrapper}>
                    <Input.Group>
                        <Row className={styles.FormRowWrapper}>
                            <Col span={8}>
                                Точки учета
                            </Col>
                            <Col span={16}>
                            {
                                this.props.operation != undefined ? <Tag>{this.props.operation.abonentName}</Tag> :
                                <Groups
                                    className={styles.Width100}
                                    size='small'
                                    groups={this.props.groups}
                                    getPoints={this.props.requestGroupPoints}
                                    getPointsParentGroups={this.props.userGroups}
                                    groupPoints={this.props.groupPoints}
                                    selectedGroupIds={this.state.selectedGroups}
                                    selectedPointIds={this.state.selectedAbonents}
                                    setIsLoading={this.props.setIsLoading}
                                    onChange={(groups: string[], points: string[]) => this.setState({selectedGroups: groups, selectedAbonents: points})}
                                    onlyGroupsSelect={false}
                                />
                            }
                            </Col>
                        </Row>
                        <Row className={styles.FormRowWrapper}>
                            <Col span={8}>
                                Диапазон дат
                            </Col>
                            <Col span={16} flex={1}>
                                <RangePickerLimit
                                    allowClear={false}
                                    size='small'
                                    className={styles.Width100}
                                    style={{ minWidth: '280px' }}
                                    value={[this.state.operation.beginDate, this.state.operation.endDate]} 
                                    onChange={(val) => {this.setState({
                                        operation: {...this.state.operation, beginDate: val?.[0] ?? new Date(), endDate: val?.[1] ?? new Date()}}
                                    )}}
                                />
                            </Col>
                        </Row>
                        <Row className={styles.FormRowWrapper}>
                            <Col span={8}>
                                Описание
                            </Col>
                            <Col span={16}>
                            <Select value={this.state.operation.operationTypeId} onChange={(val) => this.OperFieldChange('operationTypeId', val)} size="small" className={styles.Width100}>
                                <Option value={2}>Плановое отключение</Option>
                                <Option value={1}>Ремонтные работы</Option>
                                <Option value={0}>Опрессовка трубопроводов</Option>
                                <Option value={3}>Другое</Option>
                            </Select>
                            </Col>
                        </Row>
                        <Row className={styles.FormRowWrapper}>
                            <Col span={8}>
                                Примечание
                            </Col>
                            <Col span={16}>
                                <TextArea value={this.state.operation.comment} onChange={(e) => this.OperFieldChange('comment', e.target.value)} rows={3} className={styles.Width100} size='small' />
                            </Col>
                        </Row>
                    </Input.Group>
                </div>
            </Modal>
        );
    }
}

export default AbonentOperationForm;