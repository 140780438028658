import * as React from 'react';
import {connect} from 'react-redux';
import {Button, Tooltip, Select, Row, Col, Form, Checkbox} from 'antd';
import Resources from '../Resources';
import Groups from '../Groups';

import {ApplicationState} from '../../store';
import * as PageStore from '../../store/Page';
import * as UserProfileStore from '../../store/UserProfile';
import * as TablesStore from '../../store/Tables';
import {RouteComponentProps} from 'react-router';
import anStyles from '../../resources/Analysis.module.less';
import {
  SPointsFilter, Point,
  // ICommand, MenuItemDesc
} from "../../decl"
import * as Const from '../../utils/Const';
import * as Common from '../../utils/Common';
import { message } from '../../utils/Common';
import DateSelect, {
  PERIOD_TODAY,
  PERIOD_MONTH_YEAR,
  PERIOD_FROM_TO
} from '../Admin/DateSelect';

const {Option} = Select;

const actionCreators = {...PageStore.actionCreators, ...UserProfileStore.actionCreators, ...TablesStore.actionCreators};

type AnalysisHeaderProps =
  {
	userProfile: UserProfileStore.UserProfileState,
	tables: TablesStore.ITablesState,
	windowWidth: number,
	isMenuCollapsed: boolean,
	pointId: string,
	setFormState(param: string, value: any) : void,
	// sendForm() : any,
	defaultPeriod?: string
  }
  &
  typeof actionCreators &
  RouteComponentProps<{}>



interface HeaderState {
  filter: SPointsFilter
}



export const BUTTON_START_ANALIZE_TEXT: string = 'Провести анализ';


class AnalysisHeaderForm extends React.PureComponent<AnalysisHeaderProps, HeaderState> {
  constructor(props: AnalysisHeaderProps) {
	super(props);
	this.state = {
	  filter: {
		groups: (props?.userProfile?.profile?.Common?.Groups) ?? null,
		points: (props?.userProfile?.profile?.Common?.Points) ?? '',
		resources: (props?.userProfile?.profile?.Common?.Resources) ?? null,
		systemNumbers: null,
		text: ''
	  }
	}


	this.onValueChange = this.onValueChange.bind(this);
	this.onSelectPoint = this.onSelectPoint.bind(this);
	this.onResourcesChange = this.onResourcesChange.bind(this);
	this.onRefresh = this.onRefresh.bind(this);
	this.onAccept = this.onAccept.bind(this);
	//this.onDateRangeChange = this.onDateRangeChange.bind(this);
  }

  componentDidMount() {
	window.addEventListener('error', e => {
	  if (e.message === 'ResizeObserver loop completed with undelivered notifications.' ||
		e.message === 'ResizeObserver loop limit exceeded') {
		e.stopImmediatePropagation();
	  }
	})
  }

  onRefresh = () => {
	if (this.props.userProfile) {
	  const common: any = {
		Groups: this.state.filter.groups,
		Points: this.state.filter.points,
		Resources: this.state.filter.resources
	  };
	  this.props.changeProfile(Const.PROFILE_COMMON, common);
	}
	this.props.setNeedRefresh(true);
  };

  onAccept = () => {
	this.props.acceptEvents((ok: boolean) => {
	  if (ok) {
		message.success('Выполнено квитирование выбранных сообщений.');
	  } else {
		message.error('Ошибка на сервере при квитировании выбранных сообщений.');
	  }
	});
  };

  onValueChange = (groups: any, points: any) => {

	let groupsStr = groups.indexOf(Const.ALL_GROUP_ID) >= 0
	  ? ""
	  : Common.arrayToString(groups);
	let pointsStr = Common.arrayToString(points);

	this.setState({
	  filter: {
		...this.state.filter,
		groups: groupsStr,
		points: pointsStr
	  }
	});
	// this.props.setFormState('groups', groupsStr);
	// this.props.setFormState('points', pointsStr);
  };
  onSelectPoint = (pointId: string) => {
	this.setState({
	  filter: {
		...this.state.filter,
		points: pointId
	  }
	});
	if (this.props.userProfile) {
	  this.props.changeProfile(Const.PROFILE_COMMON, {Points: pointId});
	  const point: Point = this.props.userProfile.allPoints[pointId];
	  this.props.setPoint(point.id, point.number);
	}

	this.props.setNeedRefresh(true);
  };

  onResourcesChange = (value: any) => {
    let resourcesStr = (value.indexOf(Const.ALL_RESOURCE_ID) >= 0)
	  ? ""
	  : Common.arrayToString(value);
	this.setState({
	  filter: {
		...this.state.filter,
		resources: resourcesStr
	  }
	});
	// console.log('onResourcesChange', value);

	// this.props.setFormState('resources', resourcesStr);
  };

//   onDateRangeChange = (dates: [moment, moment], dateStrings: [string, string]) => {

// 	this.props.setDateRange(dates[0], dates[1]);
//   }

  // onTableSelect = (tableType: number) =>{
  // this.props.setTableType(tableType);
  // this.props.setNeedRefresh(true);
  // }

  render() {
	// console.log('RENDER');
	const maxWindowSize = (this.props.isMenuCollapsed ? 1800 : 1920);
	const messageAsIcon = this.props.windowWidth < (this.props.isMenuCollapsed ? 1970 : 2185);
	const hideEmail = false;//this.props.windowWidth < (this.props.isMenuCollapsed ? 1970 : 2185);
	const isSplit = this.props.windowWidth >= (this.props.isMenuCollapsed ? 2200 : 2315);

	const selectedGroupIds = (this.state.filter.groups != null) ?
	  Common.stringToArray(this.state.filter.groups) :
	  [Const.ALL_GROUP_ID];
	const selectedPointIds = (this.state.filter.points != null) ?
	  Common.stringToArray(this.state.filter.points) :
	  [];
	const selectedResourceIds = (this.state.filter.resources != null) ?
	  Common.stringToArray(this.state.filter.resources) :
	  [Const.ALL_RESOURCE_ID];

	const GroupsProps = {
	  groups: (this.props.userProfile) ? this.props.userProfile.userGroups : [],
	  groupPoints: (this.props.userProfile) ? this.props.userProfile.groupPoints : {},
	  selectedGroupIds: selectedGroupIds,
	  selectedPointIds: selectedPointIds,
	  getPoints: this.props.requestGroupPoints,
	  getPointsParentGroups: null,
	  onChange: this.onValueChange,
	  setIsLoading: this.props.setIsLoading,
	  onHideDropDown: this.onRefresh
	};

	const ResourcesProps = {
	  resources: (this.props.userProfile) ? this.props.userProfile.resources : [],
	  selectedResourceIds: selectedResourceIds,
	  onChange: this.onResourcesChange,
	  onHideDropDown: this.onRefresh
	};


	const columnSizes = {
	  groups: 4,
	  resources: 4,
	  condition: 6,
	  dates: 10
	};
	// console.log(this.props?.defaultPeriod);

	// const onDatesChange = (value)

	const form = <Form
	  layout="vertical"
	  className={anStyles.analysisForm}
	>
	  <Row justify={"space-between"}>
		<Col
		  sm={{span: 24}}
		  md={{span: 20}}
		>
		  <Row
			gutter={8}
		  >
			<Col span={columnSizes.groups}>
			  <Form.Item
				// label={"Группы"}
			  >
				<Groups {...GroupsProps} />
			  </Form.Item>
			</Col>
			<Col span={columnSizes.resources}>
			  <Form.Item
				// label={"Ресурсы"}
			  >
				<Resources {...ResourcesProps} />
			  </Form.Item>
			</Col>
			<Col span={columnSizes.condition}>
			  <Form.Item
				//label={"Условия анализа"}
			  >
				<Tooltip placement="right" title={'Выбор точек учёта'}>
				  <ConditionSelect
					onSelect={(value: number) => {
					  // console.log('выбрано условие', value);
					  // this.props.setFormState('condition', value);
					}}
				  />
				</Tooltip>
			  </Form.Item>
			</Col>
			<Col span={columnSizes.dates}>
			  <Form.Item
				// label={"Период анализа"}
			  >
				<DateSelect
				  defaultPeriod={this.props?.defaultPeriod ?? ''}
				  gutter={8}
				  placeholder={"Период анализа"}
				  // onChange= {(value) => {return {}}}//
				  onChange = {(value: any) => {
					// this.props.setFormState('dates', value);
				  }}
				/>
			  </Form.Item>
			</Col>
		  </Row>
		</Col>
		<Col
		  sm={{span: 24}}
		  md={{span: 4}}
		  style={{textAlign: 'center'}}

		>
		  <Form.Item
			// label={" "}
		  >
			<Button
			  type="primary"
			  shape="round"
			  // onClick={() => this.props.sendForm()}>
			  onClick={() => null}>
			  {BUTTON_START_ANALIZE_TEXT}
			</Button>
		  </Form.Item>
		</Col>
	  </Row>
	  <Row>
		<Col
		  span={24}
		>
		  <Checkbox onChange={e => {
			// this.props.setFormState('showTuNames', e.target.checked);
		  }}>
			Показывать наименование точек учёта
		  </Checkbox>
		</Col>
	  </Row>

	</Form>;

	return form;
	// return (
	//   <Row>
	// 	<Col span={20}>
	// 	  {form}
	// 	</Col>
	// 	<Col span={4}>
	// 	  <Login/>
	// 	</Col>
	//   </Row>
	//
	// );

  }
}


export default connect(
  (state: ApplicationState) => {
	return {
	  userProfile: state.userProfile,
	  tables: state.tables,
	  windowWidth: state.page.windowWidth,
	  isMenuCollapsed: state.siderMenu?.collapsed,
	  pointId: state.page.pointId
	}
  },
  actionCreators
)(AnalysisHeaderForm as any);


interface AnalysisCondition {
  id: number;
  name: string
}

let analysisConditions = new Set<AnalysisCondition>();
analysisConditions.add({
  id: 0,
  name: 'Потребление тепла за период'
});
analysisConditions.add({
  id: 1,
  name: 'Время t1-t2<3 град С за период'
});
analysisConditions.add({
  id: 2,
  name: 'Разница температур t1-t2 средняя за период'
});
analysisConditions.add({
  id: 3,
  name: 'Температура, подающая t1 за сутки'
});
analysisConditions.add({
  id: 4,
  name: 'Температура обратная t2 за сутки'
});
analysisConditions.add({
  id: 5,
  name: 'Утечка M1-M2 за период'
});
analysisConditions.add({
  id: 6,
  name: 'Утечка относительная (M1-M2)/M1*100 за период'
});
analysisConditions.add({
  id: 7,
  name: 'Подмес относительный (M2-M1)/M2*100 за период'
});
analysisConditions.add({
  id: 8,
  name: 'Время наработки за период'
});
analysisConditions.add({
  id: 9,
  name: 'Время отказа за период'
});

const ConditionSelect = (props: any) => {
  let options = [];
  let defaultValue = 0;
  // console.log('ConditionSelect render');
  for (let cond of analysisConditions) {
	options.push(
	  <Option
		key={cond.id}
		value={cond.id}
	  >{cond.name}</Option>
	)
  }
  return (
	<Select
	  //defaultValue={defaultValue}
	  // style={{ width: 200 }}
	  placeholder="Условия анализа"
	  optionFilterProp="children"
	  filterOption={(input, option: any) =>
		option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
	  }
	  filterSort={(optionA, optionB) =>
		optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
	  }
	  onSelect={(value: any) =>
		props.onSelect(value)
	  }
	  dropdownMatchSelectWidth={false}
	  listHeight={350}
	>
	  {options}
	</Select>
  );
};
